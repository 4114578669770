import axios from 'axios';
import { getToken } from '../../helpers';

export const defaultParams = () => ({
  headers: { 
    Authorization: `${getToken()}`,
  },
});


export default axios;

