import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import ContentWrapper from "../../../../common/components/ContentWrapper";
import { Grid } from "@mui/material";

import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe("pk_test_51PU6XUEPaXJEMk59YVcIGmCWJU6wxkvmTPZAgOhex6Ff32k3seg8ZYQDoC6dpQN4A06HJ08g33QUUArfjVvi7Wny00jLVTR6aF");

const MainLayout = () => {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch("http://localhost:3001/create-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <Grid container style={{ width: "100%", margin: 0, display: "flex" }}>
      <Grid item xs={3} md={3} lg={3}></Grid>
      <Grid
        item
        xs={10}
        md={6}
        lg={6}
        className="box-contianer">
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        )}
      </Grid>
    </Grid>
  );
}

const PaymentPage = () => {
  return (
    <ContentWrapper
      title={"Payment"}
      gobackUrl={"/my-subscription"}
      goback={true}
      contenview={<MainLayout />}
      img={false}
    />
  );
};

export default PaymentPage;