import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";
import CloseIcon from "../../../../assets/icons/modal-close-icon.svg";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { setSecurityPin,sendSecurityPinOtp,isVerifySecurityPinOtp } from "../redux/actions";
import { bindActionCreators } from "redux";
import {connect} from "react-redux";
import * as formatters from "../../../../utils/util"


const ResetSecurityPin = ({setSecurityPin,sendSecurityPinOtp,VerifyPinResponseData,isVerifySecurityPinOtp,pinData}) => {
  const navigate = useNavigate();
  const [code, setCode] = useState(null);
  const [newSecurityPin, SetNewSecurityPin] = useState(null);
  const [confirmNewSecurityPin, setConfirmNewSecurityPin] = useState(null);
  const [codeErr, setCodeErr] = useState("");
  const [newSecurityPinErr, SetnewSecurityPinErr] = useState("");
  const [confirmNewSecurityPinErr, setConfirmNewSecurityPinErr] =
    useState("");

  const user = window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem('userInfo')) : null;
  const [showSecurityPin, setShowSecurityPin] = useState(false);
  const [showConfirmSecurityPin, setShowConfirmSecurityPin] = useState(false);
  const [otpVerifed, setOtpVerifed] = useState(false);
  const [openModal, setOpenModal] = useState(true);

  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);
  const[queryObject, setQueryObject] = useState(null)

  const[isQueryParams, setIsQueryParams] = useState(false);
  const urlString = window.location.href;
  useEffect(() => {
    const parts = urlString.split("?");
    if (parts.length > 1) {
      const queryString = parts[1];
      const data = queryString.split("&");
      console.log("queryParams",data);
      const paramsObj = {
        uuid: data[0].split('uuid=')[1],
        otp: data[1].split('otpCode=')[1]
      };
  
      setQueryObject(paramsObj);
      setCode(paramsObj.otp)
      setIsQueryParams(true);
  
      console.log("paramsObj", paramsObj);
    } 
  }, [urlString]);

  useEffect(() => {
    if (isQueryParams && queryObject) {
      const timer = setTimeout(() => {
        VerifySecurityPinOtp();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isQueryParams, queryObject]);


  const VerifySecurityPinOtp = () => {
    const userDetails = window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem('userInfo')) : null
    const payload = {
      "userId": userDetails ? userDetails?.id : null,
      "otpCode": queryObject ? parseInt(queryObject.otp) : parseInt(code),
      "userIdEncrypted": queryObject ? queryObject.uuid : null
    };
    isVerifySecurityPinOtp(payload)
  }



  const resendSecurityPinOTP = () => {
    const userDetails = window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem('userInfo')) : null
    const payload = {
      userId : userDetails?.id
    }
    sendSecurityPinOtp(payload)
    console.log(payload)
  }


  
  useEffect(()=>{
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
  
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(interval);
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, 1000);
  
      return () => {
        clearInterval(interval);
      };
  },[ seconds])

  useEffect(() =>{
    if(VerifyPinResponseData && VerifyPinResponseData.status === 200 && VerifyPinResponseData.message === "Otp is not valid"){
      setCodeErr('Invaild OTP');
      console.log(otpVerifed);
    }else if(VerifyPinResponseData && VerifyPinResponseData.status === 200){
      setOtpVerifed(true);
      console.log(otpVerifed);
    }
  },[VerifyPinResponseData])


  useEffect(()=>{
    if(pinData && pinData.status === 200){
      setOpenModal(false);
      navigate('/home');
    }else{
      // SetnewSecurityPinErr(pinData?.message);
    }
  },[pinData])

 

  useEffect(() => {
    
  }, [seconds]);


  const handleClickShowSecurityPin = () =>
    setShowSecurityPin(!showSecurityPin);
  const handleClickConfirmShowSecurityPin = () =>
    setShowConfirmSecurityPin(!showConfirmSecurityPin);


  const handleMouseDownSecurityPin = () =>
    setShowSecurityPin(!showSecurityPin);
  const handleMouseDownConfirmSecurityPin = () =>
    setShowConfirmSecurityPin(!showConfirmSecurityPin);

  const onChangeSecurityPin = (e, type) => {
    const code = formatters.formatNumber(e.target.value)
    if (type === "code") {
      setCode(code);
    } else if (type === "newSecurityPin") {
      SetNewSecurityPin(code);
    } else if (type === "confirmNewSecurityPin") {
      setConfirmNewSecurityPin(code);
    }
  };

  const resetErrorMessages = () =>{
    SetnewSecurityPinErr('');
    setConfirmNewSecurityPinErr('');
  }

  const handleSaveSecurityPin = () => {
    let isInValid = false;
    resetErrorMessages();

    if (!newSecurityPin?.trim().length) {
      SetnewSecurityPinErr("This is requried.");
      isInValid = true;
      return;
    } else if (newSecurityPin?.trim().length !== 4){
      setConfirmNewSecurityPinErr("Security Pin should contian 4 digits.")
      isInValid = true;
      return;
    }
     else {
      SetnewSecurityPinErr("");
    }

    if (!confirmNewSecurityPin?.trim().length) {
      setConfirmNewSecurityPinErr("This is requried");
      isInValid = true;
      return;
    } else if (newSecurityPin !== confirmNewSecurityPin) {
      setConfirmNewSecurityPinErr(
        "New Security Pin and confirm security Pin mismatch."
      );
      isInValid = true;
      return;
    } else {
      setConfirmNewSecurityPinErr("");
    }
    console.log(code);

    const payload ={
      securityPin : newSecurityPin,
      emailId: user?.emailId,
      id:user?.id,
      updatedBy: user?.id
    }
   console.log(payload);
   setSecurityPin(payload);
  
  };

 
  

  const handleVerifyOtp = () => {
    if (!code || code?.trim().length === 0) {
      setCodeErr("This is required.");
      return;
    } else if (code?.trim().length !== 4) {
      setCodeErr("Code should contian 4 digits.");
      return;
    } else {
      setCodeErr("");
    }
    //setOtpVerifed(true);
    VerifySecurityPinOtp()
  };

  const handleClose = () => {
    setOtpVerifed(false);
    navigate('/home')
  };
  const resendOTP = () => {
    setMinutes(1);
    setSeconds(30);
    resendSecurityPinOTP()
  };
//console.log(otpVerifed);
  return (
    <>
      <Dialog open={openModal}
      >
        <DialogTitle>
          {otpVerifed ? (
            <div>
              {/* <div style={{ float: "right" }}>
                <img
                  src={CloseIcon}
                  alt="closeIcon"
                  onClick={handleClose}
                  style={{ cursor: "pointer", marginLeft: "40px" }}
                />
              </div> */}
              <div style={{ float: "left" }}>
                <Typography
                  variant="body1"
                  className="primary-font-bold-heading1"
                  style={{ marginTop: "-20px" }}
                >
                  {" "}
                  Change Security Pin
                </Typography>
              </div>
            </div>
          ) : (
            <div>
              {/* <div style={{ float: "right" }}>
                <img
                  src={CloseIcon}
                  alt="closeIcon"
                  onClick={handleClose}
                  style={{ cursor: "pointer", marginLeft: "40px" }}
                />
              </div> */}
              <div style={{ float: "left" }}>
                <Typography
                  variant="body1"
                  className="primary-font-bold-heading1"
                  style={{ marginTop: "-20px" }}
                >
                  {" "}
                  Forgot Security Pin
                </Typography>
              </div>
            </div>
          )}
        </DialogTitle>
        <Divider />
        <DialogContent>
          {otpVerifed ? (
            <>
              <div>
                <Typography className="font-normal-small error-message">
                  {newSecurityPinErr}
                </Typography>
                <Typography className="font-normal-small profile-input-lable">
                  New Security Pin
                </Typography>
                <div className="input-holder">
                  <form autoComplete="off">
                  <input
                  autoComplete="off"
                  style={{marginBottom:0}}

                    className={`profile-input ${
                      newSecurityPinErr !== "" ? "err-input" : ""
                    }`}
                    aria-label="new-security lock"
                    type={showSecurityPin ? "text" : "password"}
                    maxLength={4}
                    onChange={(e) => {
                      onChangeSecurityPin(e, "newSecurityPin");
                    }}
                    value={newSecurityPin}
                  />
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="security-pin"
                    onClick={handleClickShowSecurityPin}
                    onMouseDown={handleMouseDownSecurityPin}
                    className="input-eye-icon pwd-icon1"
                  >
                    {showSecurityPin ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                  </form>
                </div>
              </div>
              <br />
              <div>
                <Typography className="font-normal-small error-message">
                  {confirmNewSecurityPinErr}
                </Typography>
                <Typography className="font-normal-small profile-input-lable">
                  Confirm New Security Pin
                </Typography>
                <div className="input-holder">
                  <form autoComplete="off">
                  <input
                  style={{marginBottom:0}}

                    className={`profile-input ${
                      confirmNewSecurityPinErr !== "" ? "err-input" : ""
                    }`}
                    aria-label=" confirm new-security Pin"
                    type={showConfirmSecurityPin ? "text" : "password"}
                    onChange={(e) => {
                      onChangeSecurityPin(e, "confirmNewSecurityPin");
                    }}
                    value={confirmNewSecurityPin}
                    maxLength={4}
                    autoComplete="off"
                  />
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="security-pin"
                    onClick={handleClickConfirmShowSecurityPin}
                    onMouseDown={handleMouseDownConfirmSecurityPin}
                    className="input-eye-icon pwd-icon1"
                  >
                    {showConfirmSecurityPin ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                  </form>
                </div>
              </div>
              <br />
              <div
                className="action-holder"
                style={{ marginTop: 15, marginBottom: 15 }}
              >
                <Button
                  variant="outlined"
                  style={{ width: "100%" }}
                  className="primary-font-bold-6 action-btn profile-save-btn"
                  onClick={handleSaveSecurityPin}
                >
                  Change Security pin
                </Button>
              </div>
            </>
          ) : (
            <>
             
              <Typography className="primary-font-normal-2" component="div" style={{marginBottom: 25}}>
                  We have sent a reset security Pin code to your email Id<b> {user?.emailId } .</b> Enter it below to reset your security Pin.
                </Typography>
              <br />
              <div>
                <Typography className="font-normal-small error-message">
                  {codeErr}
                </Typography>
                <Typography className="font-normal-small profile-input-label">
                  Code
                </Typography>
                <div className="input-holder">
                  <input
                    className={`profile-input ${
                      codeErr !== "" ? "err-input" : ""
                    }`}
                    aria-label="new-pin"
                    maxLength={4}
                    onChange={(e) => {
                      onChangeSecurityPin(e, "code");
                    }}
                    value={code}
                    disabled = {isQueryParams}
                    style={{backgroundColor: isQueryParams ? "#eee" : "#fff"}}
                  />
                </div>
              </div>
              {isQueryParams && <div
                className="action-holder"
                style={{ marginTop: 20, marginBottom: 0 }}
              >
                <Button
                  variant="outlined"
                  style={{ width: "100%",opacity:"0.5" }}
                  className="primary-font-bold-6 action-btn profile-save-btn"
                  // onClick={handleVerifyOtp}
                  disabled
                >
                  verifying...
                </Button>
              </div>}
              {!isQueryParams && <>
                <div
                className="action-holder"
                style={{ marginTop: 20, marginBottom: 0 }}
              >
                <Button
                  variant="outlined"
                  style={{ width: "100%" }}
                  className="primary-font-bold-6 action-btn profile-save-btn"
                  onClick={handleVerifyOtp}
                >
                  Verify OTP
                </Button>
              </div>
                <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {seconds > 0 || minutes > 0 ? (
                  <p>
                    Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </p>
                ) : (
                  <p>Didn't recieve code?</p>
                )}

                <button
                  disabled={seconds > 0 || minutes > 0}
                  style={{
                    color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630",
                    backgroundColor: "#fff",
                    border: "none",
                    fontWeight: 500,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={resendOTP}
                  // className="primary-font-bold-6 action-btn profile-save-btn"
                >
                  Resend OTP
                </button>
              </div></>}
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

ResetSecurityPin.defaultProps = {
  pinData:null,
  pinError: null,
  isVerifyingPin: null,
}

function mapStateToProps(state) {
  return{
    pinData: state.home.pinData,
    pinError: state.home.pinError,
    isVerifyingPin: state.home.isVerifyingPin,
    otpsent: state.home.sendSecurityPinOtp,
    VerifyPinResponseData: state.home.VerifyPinResponseData

  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setSecurityPin,
      sendSecurityPinOtp,
      isVerifySecurityPinOtp
    },
    dispatch
  )
}


export default connect(mapStateToProps,mapDispatchToProps)(ResetSecurityPin);

