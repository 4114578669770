import React from 'react'
import ContentWrapper from '../../../../../../common/components/ContentWrapper';
import MyFamilyMembers from '../index.js';

const FamilyMembersComponent = () => {
  return (
    <ContentWrapper title={"My Family"} gobackUrl={'/home'} goback={true} img={false} contenview={<MyFamilyMembers />} />
  )
}

export default FamilyMembersComponent;