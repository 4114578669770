const initialState = {
  data: null,
  isFetching: false,
  error: null,
  otpData: null,
  otpError: null,
  isOTPFetching: false,
  registerData : null,
  registerError: null,
  verificationData: null,
  verificationError: null,
  resendVerificationLoading: null,
  resendVerificationSuccess: false
};

export const signupReducer = (state = initialState, action) => {
  const {type, payload} = action;
  let currentState = state;
  switch(type) {
      case 'SIGNUP_DATA_REQUEST': 
      currentState = {
        ...state,
        isFetching: true,
        error: null,
      };
      break;
      case 'SIGNUP_DATA_SUCCESS': 
      currentState = {
        ...state,
        data: payload,
        isFetching: false,
        error: null,
      };
      break;
      case 'SIGNUP_DATA_FAILURE': 
      currentState = {
        ...state,
        isFetching: false,
        error: payload,
      };
      break;
      case 'OTP_DATA_REQUEST': 
      currentState = {
        ...state,
        isOTPFetching: true,
        otpError: null,
      };
      break;
      case 'OTP_DATA_SUCCESS': 
      currentState = {
        ...state,
        otpData: payload,
        isOTPFetching: false,
        otpError: null,
      };
      break;
      case 'OTP_DATA_FAILURE': 
      currentState = {
        ...state,
        isOTPFetching: false,
        otpError: payload,
      };
      break;
      case 'REGISTRATION_SUCCESS': 
      currentState = {
        ...state,
        registerData: payload,
        error: null,
      };
      break;
      case 'REGISTRATION_FAILED': 
      currentState = {
        ...state,
        registerError: payload,
      };
      break;
      case 'VERFICATION_SUCCESS': 
      currentState = {
        ...state,
        verificationData: payload,
        registerData: null,
        error: null,
      };
      break;
      case 'VERIFICATION_FAILED': 
      currentState = {
        ...state,
        verificationError: payload,
      };
      break;
      case 'VERFICATION_RESEND_REQUEST': 
      currentState = {
        ...state,
        resendVerificationLoading: payload,
        resendVerificationSuccess: false,
        error: null,
      };
      break;
      case 'VERFICATION_RESEND_SUCCESS': 
      currentState = {
        ...state,
        resendVerificationLoading: false,
        resendVerificationSuccess: payload,
      };
      break;
      case 'RESET_REGISTRATION': 
      currentState = {
        data: null,
        isFetching: false,
        error: null,
        otpData: null,
        otpError: null,
        isOTPFetching: false,
        registerData : null,
        registerError: null,
        verificationData: null,
        verificationError: null,
        resendVerificationLoading: null,
        resendVerificationSuccess: false
      };
      break;
      default:
        
  }
  return currentState;
}
