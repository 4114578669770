import React from "react";
import "../../../../index.css";
// import "../styles/home.css";
import { Grid } from "@mui/material";
import Family from "../../../../assets/images/settings/family-members.png";
import Disaster from '../../../../assets/images/settings/disaster-management.png';
import MeetingLocation from '../../../../assets/images/settings/meeting-locations.png';
import PredefinedMessages from '../../../../assets/images/settings/predefined-messages.png';
import systemBranding from '../../../../assets/images/settings/system_branding.svg';
import MainCard from "../../common/MainCard";
// import "../components/SettingPage.css";
import { useTranslation } from "react-i18next";

const SettingPage = ({ goback = false }) => {
  const { t } = useTranslation("common");
  const cardList =[
    {
      image: Family,
      redText: t("MY"),
      cardText: t("Family Members"),
      redirection:'/my-family-members',
      toRedirect: true
    },
    {
      image: Disaster,
      redText: null,
      cardText: t("Disaster Management"),
      redirection:'/disaster-management',
      toRedirect: true
    },
    {
      image: MeetingLocation,
      redText: null,
      cardText: t("Meeting Locations"),
      redirection:'/meeting-locations',
      toRedirect: true
    },
    {
      image: PredefinedMessages,
      redText: null,
      cardText:t("Manage Predefined Messages"),
      redirection:'/manage-predefined-messages',
      toRedirect: true
    },
    {
      image: systemBranding,
      redText: null,
      cardText:t("Customize Emergency Tile"),
      redirection:'/system-branding',
      toRedirect: true,
      inLineStyle:true
    }
  ]
  return (
    <Grid container>
      {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
      <div className="MyInfo-header-container">
      <Grid container
        item
        xs={12}
        md={12}
        lg={12}
        // className="setting-card-container"
        // style={{
        //   display: "grid",
        //   gridAutoRows: "auto",
        //   // gridGap: "0.2rem",
        //   gridTemplateColumns: "repeat(auto-fill, 330px)",
        //   justifyContent: "space-around",
        //   padding: "63px 0px 0px 0px"
        // }}
      >
        <MainCard cardList={cardList} />
      </Grid>
      </div>
      {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
    </Grid>
  );
};

export default SettingPage;
