const constants={
    BASE_URL:'https://stagingapi.myredfolder.kyybaapps.com/myredfolder/api/',
    // BASE_URL:'https://developmentapi.myredfolder.kyybaapps.com/myredfolder/api/',
    PAGINATION_LIMIT:12,
    PAGINATION_START:1,
    MODEL_LIMIT:100,
    DEALER_LIMIT:50,
    USER_INACTIVE_TIME: 1800000, // 30 minutes
    REDIRECTION_URL: 'https://www.myredfolder.com/',
    // COGNITO_ENVIRONMENT: "LOCAL",
    // COGNITO_ENVIRONMENT: "DEV",
    COGNITO_ENVIRONMENT: "STAGING",
    CONGNITO_LOCAL_SIGNIN:"http://localhost:3000/verification/",
    CONGNITO_DEV_SIGNIN:"https://www.test.myredfolder.kyybaapps.com/verification/",
    CONGNITO_STAGING_SIGNIN:"https://www.staging.myredfolder.kyybaapps.com/verification/",
    CONGNITO_LOCAL_SIGNOUT:"http://localhost:3000/logout/",
    CONGNITO_DEV_SIGNOUT:"https://www.test.myredfolder.kyybaapps.com/logout/",
    CONGNITO_STAGING_SIGNOUT:"https://www.staging.myredfolder.kyybaapps.com/logout/",
    endPointUrl: {
        "LOCAL":"http://localhost:3000",
        "DEV":"https://www.test.myredfolder.kyybaapps.com",
        "STAGING":"https://www.staging.myredfolder.kyybaapps.com",
    },
    COGNITO_ACCESS_KEY: "AKIAUEUAW5EQX4FVIN34",
    COGNITO_SECRET_KEY:"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",
    GOOGLE_MAPS_API_KEY: "AIzaSyAbQt87YmIQRyClukIDeyp9uFXEcZxaiJg",
    STRIPE_PUBLIC_KEY: 'pk_test_51PU6XUEPaXJEMk59YVcIGmCWJU6wxkvmTPZAgOhex6Ff32k3seg8ZYQDoC6dpQN4A06HJ08g33QUUArfjVvi7Wny00jLVTR6aF',
}
export default constants;
