import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useIdleTimer } from 'react-idle-timer'
import EnterSecurityLockDialog from "../../Pages/UserProfile/components/EnterSecurityLockDialog";
import constants from '../../../config/constants';
import { useLocation  } from "react-router-dom";

const ProtectedRoute = (props) => {
  const [state, setState] = useState('Active');
  const [remaining, setRemaining] = useState(0);
  const [count, setCount] = useState(0);
  const [isPopUp, setIsPopUp] = useState(false);
  const location = useLocation();
  const pathName = location.pathname;
  const resetURL = pathName.split('/')[1];


  const onIdle = () => {
    if(resetURL !== "reset-security-lock"){
      setState("Idle");
      window.localStorage.setItem('securityPopup', true)
      window.sessionStorage.setItem('isExistingTab', true)
      setIsPopUp(true);
    }
  }

  const onActive = () => {
    if(!setIsPopUp){
      window.localStorage.removeItem('securityPopup')
      window.sessionStorage.removeItem('isExistingTab')
      setState('Active')
    }
    
  }

  const onMessage = () => {
    setCount(count + 1)
  }

  const {
    getRemainingTime,
    getTabId,
    isLeader,
    isLastActiveTab,
    message
  } = useIdleTimer({
    onIdle,
    onActive,
    onMessage,
    timeout: constants.USER_INACTIVE_TIME,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200
  })

  useEffect(() => {
   const popup=  window.localStorage.getItem('securityPopup')
   if(popup){
   
    if(!window.sessionStorage.getItem('isExistingTab')){
      window.localStorage.clear()
      window.sessionStorage.clear()
    }else{
      if(resetURL !== "reset-security-lock"){
      onIdle();
      }else{
        console.log("resetURL--->",resetURL);
      }
    }
   }
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

        const user = window.localStorage.getItem('tokendetails');
        if (!user) {
            // user is not authenticated
            return <Navigate to="/login" />;
          }else{
            if(state === "Idle"){
              return <EnterSecurityLockDialog  setState={setState} from={"Route"} setIsPopUp={setIsPopUp}/>
            }else{
              return props.children
            }
        }
}

export default ProtectedRoute;