import {api} from '../../../../utils/apiClient';

export const activateEmergencyAlertAPI = (payload) => async(dispatch) => {
    const url = `v1/emergency/create`;
   try{
       dispatch({
           type: 'AEA_REQUEST',
           payload: null
       })
       const response =await api.postApi(url,payload);
       return dispatch({
           type: 'AEA_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'AEA_FAILURE',
           payload: e
       });
   }
};

export const getActiveEmergencyAlert = (payload) => async(dispatch) => {
    const userDetails = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')): null;
    const url = `v1/emergency/checkIsActive/${userDetails?.accountId}`;
   try{
       dispatch({
           type: 'AEA_REQUEST',
           payload: null
       })
       const response =await api.getApi(url,payload);
       return dispatch({
           type: 'AEA_DATA_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'AEA_DATA_FAILURE',
           payload: e
       });
   }
}


export const getActiveEmergencyAlertDataOld = (payload) => async(dispatch) => {
    const userDetails = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')): null;
    const url = `v1/emergency/read/${userDetails?.accountId}`;
   try{
       dispatch({
           type: 'AEA_REQUEST',
           payload: null
       })
       const response =await api.getApi(url,payload);
       return dispatch({
           type: 'AEA_DATA_SUCCESS_OBJECT',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'AEA_DATA_FAILURE_OBJECT',
           payload: e
       });
   }
}

export const getActiveEmergencyAlertData = (payload) => async(dispatch) => {
    const userDetails = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')): null;
    const url = `v2/emergency/read/${userDetails?.accountId}`;
   try{
       dispatch({
           type: 'AEA_REQUEST',
           payload: null
       })
       const response =await api.getApi(url,payload);
       return dispatch({
           type: 'AEA_DATA_SUCCESS_OBJECT',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'AEA_DATA_FAILURE_OBJECT',
           payload: e
       });
   }
}

     
export const deActivateEmergencyAlertAPI = (payload) => async(dispatch) => {
    const url = `v1/emergency/delete`;
   try{
       dispatch({
           type: 'AEA_REQUEST',
           payload: null
       })
       const response =await api.deleteapi(url,payload);
       return dispatch({
           type: 'AEA_DELETE_SUCCESS_OBJECT',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'AEA_DATA_FAILURE_OBJECT',
           payload: e
       });
   }
};
     
export const getNotificationUnReadCountData = (payload) => async(dispatch) => {
    const userDetails = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')): null;
    const url = `v1/notification/checkIsUnRead/${userDetails?.id}`;
   try{
       dispatch({
           type: 'NOTIFICATION_UNREAD_COUNT_REQUEST',
           payload: null
       })
       const response =await api.getApi(url,payload);
       if(response.data && response.data.status === 200){
        const input = response.data.data;
        
        return dispatch({
            type: 'NOTIFICATION_UNREAD_COUNT_SUCCESS',
            payload: input.count || 0
        });
       }
      
   }catch(e){
       return dispatch({
           type: 'NOTIFICATION_UNREAD_COUNT_FAILURE',
           payload: e
       });
   }
}

export const getAllNotificationList = (data) => async(dispatch) => {
    const userDetails = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')): null;
    const url = `v1/notification/readAllWithPaging`;
    const payload =  {
        "userId": userDetails?.id,
        "pageNo": data?.pageno || 1,
        "limit": data?.limit ||  10
      }
     
   try{
       dispatch({
           type: 'NOTIFICATION_LIST_REQUEST',
           payload: null
       })
       const response =await api.postApi(url,payload);
       dispatch({
        type: 'NOTIFICATION_LIST_SUCCESS',
        payload: response.data
    });
        return response.data;
        
        
      
   }catch(e){
   }
}

export const readNotificationData = (payload) => async(dispatch) => {
    const userDetails = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')): null;
    const url = `v1/notification/updateRead`;
   try{
       const response =await api.putApi(url,payload);
        return dispatch({
            type: 'NOTIFICATION_READ_STATUS_SUCCESS',
            payload: response.data
        });
      
   }catch(e){
       return dispatch({
           type: 'NOTIFICATION_READ_STATUS_FAILURE',
           payload: e
       });
   }
};

export const updateUserEmergencyStatus = (payload) => async(dispatch) => {
    const url = `v1/emergency/members/updateStatus`;
   try{
       const response =await api.putApi(url,payload);
        return dispatch({
            type: 'AEA_USER_STATUS_SUCCESS',
            payload: response.data
        });
   }catch(e){
       return dispatch({
           type: 'NAEA_USER_STATUS_FAILURE',
           payload: e
       });
   }
}

export const updateMemberCurrentLocation = (payload) => async(dispatch) => {
    const url = `v1/emergency/members/updateLocation`;
    try{
        const response =await api.putApi(url,payload);
        console.log(response,"updateMemberCurrentLocation")
         return dispatch({
             type: 'AEA_USER_LOCATION_SUCCESS',
             payload: response.data
         });
    }catch(e){
        return dispatch({
            type: 'NAEA_USER_LOCATION_FAILURE',
            payload: e
        });
    }
}

export const editEmergencyAlertAPI = (payload) => async(dispatch) => {
    const url = `v1/emergency/update`;
   try{
       const response =await api.putApi(url,payload);
        return dispatch({
            type: 'AEA_SUCCESS',
            payload: response.data
        });
   }catch(e){
       return dispatch({
           type: 'AEA_DATA_FAILURE',
           payload: e
       });
   }
}

export const startTrackingAPI = (payload) => async(dispatch) => {
    const url = `v1/trackMe/create`;
   try{
       dispatch({
           type: 'TRACK_ME_REQUEST',
           payload: null
       })
       const response =await api.postApi(url,payload);
       return dispatch({
           type: 'TRACK_ME_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'TRACK_ME_FAILURE',
           payload: e
       });
   }
};
export const updateTrackingAPI = (payload) => async(dispatch) => {
    const url = `v1/trackMe/update`;
   try{
       dispatch({
           type: 'TRACK_ME_UPDATE_REQUEST',
           payload: null
       })
       const response =await api.putApi(url,payload);
       return dispatch({
           type: 'TRACK_ME_UPDATE_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'TRACK_ME_UPDATE_FAILURE',
           payload: e
       });
   }
};


export const updateLocationAPI = (payload) => async(dispatch) => {
    const url = `v1/trackMe/members/updateLocation`;
   try{
       dispatch({
           type: 'UPDATE_LIVE_REQUEST',
           payload: null
       })
       const response =await api.putApi(url,payload);
       return dispatch({
           type: 'UPDATE_LIVE_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'UPDATE_LIVE_FAILURE',
           payload: e
       });
   }
};

export const stopTrackingAPI = (payload) => async(dispatch) => {
    const url = `v1/trackMe/delete`;
   try{
       dispatch({
           type: 'TRACK_STOP_REQUEST',
           payload: null
       })
       const response =await api.deleteapi(url,payload);
       return dispatch({
           type: 'TRACK_STOP_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'TRACK_STOP_FAILURE',
           payload: e
       });
   }
};

export const checkIsTracking = (payload) => async(dispatch) => {
    const userDetails = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')): null;
    const url = `v1/trackMe/read/${userDetails?.id}`;

   try{
       dispatch({
           type: 'TRACK_ME_READ_REQUEST',
           payload: null
       })
       const response =await api.getApi(url,payload);
       return dispatch({
           type: 'TRACK_ME_READ_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'TRACK_ME_READ_FAILURE',
           payload: e
       });
   }
};

export const getLiveLocation = (payload) => async(dispatch) => {
    const userDetails = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')): null;
    const url = `v1/trackMe/familyLocations/${userDetails?.id}`;
   try{
       dispatch({
           type: 'TRACK_LIVE_REQUEST',
           payload: null
       })
       const response =await api.getApi(url,payload);
       return dispatch({
           type: 'TRACK_LIVE_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'TRACK_LIVE_FAILURE',
           payload: e
       });
   }
};

export const sendRequestAPI = (payload) => async(dispatch) => {
    const url = `v1/trackingRequest/create`;
   try{
       dispatch({
           type: 'CREATE_TRACK_REQUEST',
           payload: null
       })
       const response =await api.postApi(url,payload);
       return dispatch({
           type: 'CREATE_TRACK_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'CREATE_TRACK_FAILURE',
           payload: e
       });
   }
};

export const updateRequestAPI = (payload) => async(dispatch) => {
    const url = `v1/trackingRequest/update`;
   try{
       dispatch({
           type: 'UPDATE_TRACK_REQUEST',
           payload: null
       })
       const response =await api.putApi(url,payload);
       return dispatch({
           type: 'UPDATE_TRACK_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'UPDATE_TRACK_FAILURE',
           payload: e
       });
   }
};
export const checkIsRequested = (payload) => async(dispatch) => {
    const userDetails = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')): null;
    const url = `v1/trackingRequest/read/${userDetails?.id}`;

   try{
       dispatch({
           type: 'TRACK_REQUEST_READ_REQUEST',
           payload: null
       })
       const response =await api.getApi(url,payload);
       return dispatch({
           type: 'TRACK_REQUEST_READ_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'TRACK_REQUEST_READ_FAILURE',
           payload: e
       });
   }
};

export const getLiveSharing = (payload) => async(dispatch) => {
    const userDetails = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')): null;
    const url = `v1/trackMe/familyLocations/${userDetails?.id}`;

   try{
       dispatch({
           type: 'GET_LIVE_REQUEST',
           payload: null
       })
       const response =await api.getApi(url,payload);
       return dispatch({
           type: 'GET_LIVE_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'GET_LIVE_FAILURE',
           payload: e
       });
   }
};

export const singleStopTracking = (payload) => async(dispatch) => {
    const url = `v1/trackMe/members/stopSharingMyLocation`;
   try{
       dispatch({
           type: 'SINGLE_STOP_REQUEST',
           payload: null
       })
       const response =await api.postApi(url,payload);
       return dispatch({
           type: 'SINGLE_STOP_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'SINGLE_STOP_FAILURE',
           payload: e
       });
   }
};




