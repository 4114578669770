import React, { useEffect, useState } from "react";
import {Grid,Card} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import arrow from '../../../../assets/icons/need-help-card-arrow.png';
import data from "./needHelpCardlist";
import"../components/styles/NeedHelp.css";

const StyledCard = styled(Card)({
    width: 230,
    height:191,
    border: "1px solid #D8D8D8",
    borderRadius: 10,
    cursor: "pointer",
    boxShadow: "none",
     "&:hover": {
         border: "1px solid #FF3535",
         boxShadow: "0px 2px 8px #00000033"
     }
})


const TextOverlay = styled("div")({
    textAlign:"center",
    paddingTop:"20px",
})

const ImageOverlay = styled("div")({
    width:"70px",
    height:"70px",
    //display:"block",
    objectFit:"cover",
})

const Image = styled("img")({
    width:"70px",
    height:"70px",
    display:"block",
    //objectFit:"cover"
})


const NeedHelp = () => {
    const [listArray,setListArray] = useState([]);

    const Navigate = useNavigate();

    useEffect(() => {
        setListArray(data);
    },[]);

   const handleNavigate = (item) => {
       console.log(JSON.stringify(item));
       if(item.redirection) {
        window.localStorage.setItem("INeedHelp",item.name)
        Navigate(item.redirection);
       }
   }

   
    return(
       <Grid container sx={{margin: 0,width:"100%"}} >
         {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
         <Grid className="help-container">
         <Grid item xs={12} md={12} lg={12} className="box-container box-container-one">
            <Grid item container  className="need-help-box" style={{display:"flex",alignItems:"center",justifyContent:"center",gap:"20px"}}>
                {listArray.map((item) =>{
                    return (
                        <StyledCard
                           key={item.id}
                           onClick={() => handleNavigate(item)}
                        >
                            <TextOverlay>
                                {item.name}
                            </TextOverlay>
                            
                            <ImageOverlay>
                            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",marginLeft:"30px",marginTop:"40px"}}>
                                <Image src={arrow}
                             alt="arrow" style={{width:"30px",height:"30px",float:"left"}}/>
                                <Image
                                 src={item.image_url}
                                 alt={item.imageAlt}
                                 height={165}
                                 style={{marginLeft:"70px"}}
                                 />
                                 </div>
                            </ImageOverlay>
                            
                        </StyledCard>
                    )
                })}
            </Grid>
         </Grid>
         </Grid>
       </Grid> 
    )
}

export default NeedHelp;