import React, { useEffect, useState,useContext } from "react";
import {
  Grid,
  Typography,
  Divider,
  CardContent,
  Card,
  CardActions,
  Button,
  Dialog,
  DialogContent,Box
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "../../../../../../assets/icons/modal-close-icon.svg";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { Navigate, useNavigate } from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";
import constants from "../../../../../../config/constants";
import Alert from "@mui/material/Alert";
import NEPTA from "../../../../../../assets/images/Nepta.png";
import LiveTrackingMeetingLocation from "../../EmergencyAlert/components/LiveTrackingMeetingLocation";
import { current } from "@reduxjs/toolkit";
import { BoltRounded } from "@mui/icons-material";
import {UserInfoDetails} from "../../../../../../utils/userContext";
import { startTrackingAPI } from "../../../redux/actions";

const StyledCard = styled(Card)({
  position: "relative",
  width: 500,
  height: 314,
  border: "1px solid #D8D8D8",
  borderRadius: 10,
  boxShadow: "none",
  "&:hover": {
    border: "1px solid #FF3535",
    boxShadow: "0px 2px 8px #00000033",
  },
});

const ImageOverlay = styled("div")({
  position: "relative",
  overflow: "hidden",
  border: "1px solid #fff",
});

const Image = styled("img")({
  width: "100%",
  display: "block",
  objectFit: "cover",
});

const TextOverlay = styled("div")({
  position: "absolute",
  bottom: 0,
  left: 0,
  background: "#000000BA 0% 0% no-repeat padding-box",
  opacity: 1,
  width: "100%",
  boxSizing: "border-box",
  textAlign: "center",
  padding: "8px 16px",
  color: "#fff",
  height: "70px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const statusLookup = {
  safe: "I am safe",
  risk: "At risk",
  pending: "Pending",
  critical: "Critical/Emergency",
  "I am safe": "I am safe",
};

const statusLabelLookup = {
  accepted: "Accepted",
  rejected: "Rejected",
  pending: "Pending",
}


const statusLookupBackground = {
  accepted: "#3BC273",
  pending: "#FFF9E5",
  rejected: "#FF3535",
};

const textColor = {
  accepted: "#ffffff",
  pending: "#EAA434",
  rejected: "#ffffff",
};
const SummaryOfRequest = ({
addTrackMe,
  previousData,
  alertData = null,
  startTrackingAPI,
  trackMeCreateResponse,
  requestData
}) => {
  const [currentLocation, setCurrentLocation] = useState(null)
  const {userObject} = useContext(UserInfoDetails)
  const [message,setMessage] = useState("")
  const [successDialouge,setSuccessDialuge] = useState(false)
  const [reqObject,setReqObject] = useState(null)


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  const onSuccess = async location => {
    if(location && location.coords){
      let latitude = location?.coords?.latitude ? location?.coords?.latitude : null;
      let longitude = location?.coords?.longitude ? location?.coords?.longitude : null;
      const user = addTrackMe?.triggeredBy && addTrackMe?.triggeredBy.length > 0 ? addTrackMe?.triggeredBy[0] : {};
      const object = {
        locationLat: latitude,
        locationLong: longitude,
        name: user?.firstName,
        image_PreSignedUrl : user?.image_PreSignedUrl,
        showCustomMarker: true
      }
      setCurrentLocation(object)
    }
     
  };

  const onError = error => {
      console.log("OnError")
  }
 


    useEffect(() => {
        if (addTrackMe) {
          callSetObject(addTrackMe)
        } 
        if (requestData) {
          requestData.familyMembers = requestData.membersList;
          delete requestData.membersList;
          callSetObject(requestData)
        } 
    }, [addTrackMe,requestData]);

  const callSetObject = (obj) => {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onSuccess, onError)
    }
    setReqObject(obj)
  }

  const getLocaionDetails = async (latitude, longitude) => {
    if(latitude && longitude){
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${constants.GOOGLE_MAPS_API_KEY}`
      );
      const json = await response.json();
      console.log("response", json);
      return json.results[0]?.formatted_address;
    }
   
  };

  useEffect(() => {
    if (trackMeCreateResponse) {
      if (trackMeCreateResponse && trackMeCreateResponse.status === 200 && trackMeCreateResponse.message === "Track Me Created Successfully") {
        setMessage("Tracking started successfully");
        dispatch({
          type: "TRACK_ME_SUCCESS",
          payload: {}
        })
        setSuccessDialuge(true);
        setTimeout(() => {
          setSuccessDialuge(false);
          setMessage("");
          navigate('/share-my-location/view')
          
        }, 2000);
      }
    }
  }, [trackMeCreateResponse])



  return (
    <Grid container spacing={2} style={{ width: "100%", margin: 0 }}>
      <Grid item xs={12}>
                        <div  className="box-header" >
                            <Typography className="primary-font-bold-2-heading">
                                Request for tracking
                            </Typography>
                            <Button
                                variant="outlined"
                                className="primary-font-bold-6 action-btn-yes profile-save-btn"
                                onClick={() => navigate("/request-for-tracking",{ state: { requestData: reqObject,isUpdate:true } })}
                            >
                                <EditIcon style={{ fontSize: "19px", marginRight: "2px" }}></EditIcon>
                                Edit
                            </Button>
                        </div>
                    </Grid>
      <Grid item xs={12} md={12} lg={12}>
        {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
          {/* <Typography
            className="primary-font-bold-heading1"
            style={{ paddingLeft: 30 }}
          >
            Track Me
          </Typography> 
          <div
            style={{
              display: "flex",
              alignitems: "flex-end",
              justifycontent: "end",
              paddingRight: 30,
            }}
          >
            <img
              src={CloseIcon}
              alt="close-icon"
              style={{ cursor: "pointer" }}
              onClick={() => {
                cancelActivateAlert();
              }}
              sx={{
                marginRight: "30px",
              }}
            />
          </div>
        </div>
            <Divider /> */}
        <br /> 
        <Grid container spacing={2} style={{ width: "100%", marginBottom: 60, display:"flex",justifyContent:"center" }}>
          <Grid item xs={12} md={6} lg={6}>
          <div id="meeting-lcoation" style={{marginTop:"20px"}}>
              <LiveTrackingMeetingLocation membersList={reqObject?.familyMembers} meetingLocation={currentLocation} />
              </div>
           
          </Grid>
          <Grid item xs={12} md={6} lg={6} id="second-grid-item">
            <div>
              <Grid
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  // width: "95%",
                }}
              >
                {reqObject &&
                  reqObject.familyMembers &&
                  reqObject.familyMembers.map((row) => {
                    return (
                      <>
                        {((row.userId && row.userId !== userDetails?.id) ||
                          (!row.userId && row.id !== userDetails?.id)) && (
                            <>
                              <Grid
                                container
                                key={row.id}
                                sx={{
                                  mr: "12px",
                                  padding: "0px",
                                  // mx: "10px",
                                  // my: "5px",
                                  border: "1px solid #D8D8D8",
                                  borderRadius: "10px",
                                  marginBottom: "10px"
                                }}
                              >
                                <Grid
                                  xs={12}
                                  md={8.5}
                                  lg={8.5}
                                  sx={{
                                    justifyContent: "start",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  <Typography className="primary-font-bold-5" style={{padding:"12px"}}>
                                    {row.name
                                      ? row.name
                                      : row.firstName +
                                      " " +
                                      (row?.middleName && row?.middleName !== null ? row.middleName : "") +
                                      " " +
                                      (row?.lastName && row?.lastName !== null ? row?.lastName : "")}
                                  </Typography>
                                </Grid>
                                {/* <Grid
                           xs={4}
                           md={4}
                           lg={4}
                           sx={{ justifyContent: "start" }}
                         >
                           <Typography className="primary-font-normal-2">
                             {row.relationshipCode}
                           </Typography>
                         </Grid> */}
                                <Grid
                                  xs={12}
                                  md={3.5}
                                  lg={3.5}
                                  sx={{
                                    justifyContent: "start",
                                    textAlign: "center",
                                    borderTopRightRadius: "9px",
                                    borderBottomRightRadius: "9px",
                                    backgroundColor:
                                      row.status
                                          ? statusLookupBackground[row.status]
                                          : statusLookupBackground["pending"],
                                  }}
                                >
                                  <Typography
                                    className="primary-font-normal-2"
                                    style={{
                                      textTransform: "capitalize",
                                      paddingTop:9,
                                      fontWeight:"bold",
                                      color:
                                        row.status
                                            ? textColor[row.status]
                                            : textColor["pending"],
                                    }}
                                  >
                                    { row.status
                                        ? statusLabelLookup[row.status]
                                        : statusLabelLookup["pending"]}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </>
                          )}
                      </>
                    );
                  })}
              </Grid>
            </div>
            <div style={{ marginBottom: "20px", }}>
              {reqObject && reqObject.messageCustom && (
                <Typography className="primary-font-bold-5" sx={{my:2}}>Message</Typography>
              )}
              {/* <ul> */}
              {reqObject && reqObject.messageCustom && (<Grid
                    container
                    sx={{
                      mr: "12px",
                      padding: "12px",
                      border: "1px solid #D8D8D8",
                      borderRadius: "10px",
                      marginBottom: "10px"
                    }}
                  >
                    <Grid
                      xs={12}
                      md={8.5}
                      lg={8.5}
                      sx={{
                        justifyContent: "start",
                        textTransform: "capitalize",
                      }}
                    >
                      <Typography className="">
                       {reqObject.messageCustom}
                      </Typography>
                    </Grid>
                   
                   
                  </Grid>) }
                
              {/* </ul> */}
              {/* <textarea 
              className="font-family-default"
                style={{
                  width: "475px",
                  height: "60px",
                  background: " #FFFFFF 0% 0 % no - repeat padding- box",
                  boxShadow: "0px 3px 6px #00000029",
                  borderRadius: "10px",
                  opacity: 1,
                  outline: "none",
                  border: "none",
                  padding: "18px 16px",
                  margin:"10px 0",
                  resize: "none"
                }} 
                maxLength={500}
                placeholder="Type here..."></textarea> */}
            </div>
            </Grid>
               
            </Grid>
          </Grid>

      {message && (
        <Dialog
          open={successDialouge}
          onClose={() => {
            setSuccessDialuge(false);
          }}
          className="small-dialog dialog-box"
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
        >
          <DialogContent className="content-dialog">
            <Typography className="primary-font-normal-4">
              {message}
            </Typography>
          </DialogContent>
        </Dialog>
      )}
        
    </Grid>
  );
};

function mapStateToProps(state) {
  return {
    familyMembers: state.setting.familyMembers,
    addTrackMe: state.trackMe.addTrackMe,
    data: state.trackMe.data,
    isFetching: state.trackMe.isFetching,
    trackMeCreateResponse: state.trackMe.trackMeCreateResponse 
};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      startTrackingAPI
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryOfRequest);
