import React, { useState, useEffect } from "react";
import { Grid, Typography, Button } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import UploadImage from '../../../../../../assets/icons/upload-icon.svg';
import * as formatters from '../../../../../../utils/util';

const FirearmInfo = ({onBackOrCancelClick, handleNext}) => {
  const initialFormData = {
   brand:'',
   serialNumber:'',
   color:'',
   specialIdentification:''
  };
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialFormData);
  const [imageUrlAvatar1, setImageUrlAvatar1] = useState(null);
  const [imageUrlAvatar, setImageUrlAvatar] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const[image, setImage] = useState(null);
  const[previewUrl, setPreviewUrl] = useState(""); 

  const handleFile = file => {
    //you can carry out any file validations here...
    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    setImageUrlAvatar1(URL.createObjectURL(file))
  }
  const handleOnDrop = event => {
    event.preventDefault(); 
    event.stopPropagation(); 
    //let's grab the image file
    let imageFile = event.dataTransfer.files[0];
    handleFile(imageFile);
  }

  const handleDragOver = event => {
    event.preventDefault();
  }
  useEffect(() => {
    if (selectedImage) {
      setImageUrlAvatar1(URL.createObjectURL(selectedImage));
      setPreviewUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const [showPassword, setShowPassword] = useState(false);
  const [showCode, setShowCode] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowCode = () => setShowCode(!showCode);
  const handleMouseDownCode = () => setShowCode(!showCode);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleDateChange = (date) => {
    setFormData({ ...formData, ['expirationDate']: date });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};
    console.log(formData, 'formDataformDataformData')
    if (formData.brand.trim() === '') {
      newErrors.brand = 'Brand of Firearm is required.';
    }
   if (formData.serialNumber.trim() === '') {
        newErrors.serialNumber = 'Serial No. is required.';
    } else if (!formatters.cleanPhoneNumber(formData.serialNumber)) {
      newErrors.serialNumber = 'Enter valid serial No.';
    } 
    if (formData.color.trim() === '') {
      newErrors.color = 'color is required.';
    } 
    if (formData.specialIdentification.trim() === '') {
      newErrors.specialIdentification = 'special Identification is required.';
    } 

    if (Object.keys(newErrors).length === 0) {
      console.log(formData);
      setErrors({});
      handleNext();
    } else {
      setErrors(newErrors);
      console.log('Validation errors:', newErrors);
    }
  };
return (
    <Grid container spacing={2} style={{margin:0, padding: 32, width: '100%'}}>
        <Grid item xs={12} md={6} sx={{padding: 0}}>
            <div style={{padding: 20}}>
                <div>
                    <Typography className='font-normal-small error-message'>{errors.brand}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Name Brand of Firearm</Typography>
                    <input type="text" name="brand" id="brand"  className={`profile-input ${errors.brand ? 'err-input' : ''}`} aria-label='brand' onChange={handleInputChange}  value={formData.brand}/>
                </div>
               <div style={{display:'flex',justifyContent:'space-between'}}>
               <div>
                    <Typography className='font-normal-small error-message'>{errors.serialNumber}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Serial Number</Typography>
                    <input type="tel" name="serialNumber" id="serialNumber"  className={`profile-input ${errors.serialNumber ? 'err-input' : ''}`} aria-label='serialNumber' onChange={handleInputChange }  value={formData.serialNumber}/>
                </div>
                <div>
                    <Typography className='font-normal-small error-message'>{errors.color}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Color</Typography>
                    <input type="text" name="color" id="color"  className={`profile-input ${errors.color ? 'err-input' : ''}`} aria-label='color' onChange={handleInputChange}  value={formData.color}/>
                </div>
               </div>
                <div style={{marginLeft: 0}}> 
                <div>
                    <Typography className='font-normal-small error-message'>{errors.specialIdentification}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Special Identification</Typography>
                    <input type="text" name="specialIdentification" id="specialIdentification"  className={`profile-input ${errors.specialIdentification ? 'err-input' : ''}`} aria-label='specialIdentification' onChange={handleInputChange}  value={formData.specialIdentification}/>
                </div>
                </div>
            </div>
        </Grid>
        <Grid item xs={12} md={6} sx={{padding: 0}}>
            <div style={{padding: 20}}>
                <input
                    // accept="image/*"
                    type="file"
                    id="select-image"
                    style={{ display: "none" }}
                    onChange={(e) => {setSelectedImage(e.target.files[0]); handleFile(e.target.files[0]);}}
                />
                <label htmlFor="select-image"> 
                    <div className="photo-upload-container drop_zone" style={{height:272, marginBottom: 30}} onDragOver={handleDragOver} onDrop={handleOnDrop}>
                    <img  src={UploadImage} alt={'upload-avatar'} style={{cursor: 'pointer'}} />
                    <Typography className='primary-font-normal-3 profile-input-label' color='#ABAFB3'>Drag and drop a file here <br />or click to <span style={{color:'#4979FF'}}>upload</span></Typography>
                    { previewUrl && <div className="image">
                        {/* <div>{image.name}</div> */}
                      <img src={previewUrl} alt='image' width={100} height={100}/> 
                    </div> }
                  </div>
                </label>
            </div>
        </Grid>
        <div className="action-holder" style={{marginTop: 0, marginBottom: 50}}>
                <Button variant="outlined"  className="primary-font-bold-6 action-btn profile-cancel-btn" onClick={onBackOrCancelClick}>Cancel</Button>
                <Button variant="outlined" className="primary-font-bold-6 action-btn profile-save-btn"  onClick={handleSubmit}>Next</Button>
              </div>
    </Grid>
    )
}
export default FirearmInfo;
