import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { Grid, Typography, Card } from "@mui/material";
import Box from '@mui/material/Box';

import Clock from "../../../../assets/icons/Clock-icon.svg"
import Hand from "../../../../assets/icons/Hand-icon.svg"
import LocationIcon from "../../../../assets/icons/location-icon.svg"
import "./Header.css"
import { readNotificationData, getAllNotificationList } from '../redux/actions';

const StyledCard = styled(Card)({
  variant: "outlined",
  border: '1px solid #D8D8D8',
  borderRadius: 5,
  boxShadow: '0px 2px 8px #00000033',
  width: "300px",
  minHeight: "90px",
  overflow: "hidden",
  "&:hover": {
    border: '1px solid #ff3535 !important',
    cursor: "pointer",
    boxShadow: '0px 2px 8px #00000033',
  }
}
);

const NotificationSystem = ({ data, readNotificationData, getAllNotificationList, currPage, prevPage,setCurrPage,setPrevPage, lastList, setLastList, listInnerRef  }) => {
  const [notificationList, setNotificationList] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const[mobileHeight,setmobileHeight]=useState()

  const alertMessage = {
    headIcon: Hand,
    lastAlert: Clock,
    title: "Emergency Alert",
    name: "By John Doe",
    message: "Jane Doe has received the emergency notification",
  };

   useEffect(()=>{
const innerheight=window.innerHeight
setmobileHeight(innerheight)
   },[window.innerHeight])  
  // useEffect(() => {
  //   if (data && data.notificationsList) {
  //     setNotificationList(data.notificationsList)
  //   }
  // }, [data?.notificationsList])


  const readNotification = (input) => {
    const userDetails = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
    const payload = {
      "userId": userDetails.id,
      "notificationIds": input.id ? input.id.toString() : "",
      "isSelectAll": false
    }
    const newData = [input.id]
    const data = [...selectedIds, ...newData]
    setSelectedIds(data)
    readNotificationData(payload)
  }

  useEffect(() => {
    const fetchData = async () => {
     const payload =  {
      pageno : currPage,
      limit : 17
      }
      const response = await getAllNotificationList(payload)
      if (!response.data?.notificationsList?.length) {
        setLastList(true);
        return;
      }
      setPrevPage(currPage);
      if(response.data && response.data.notificationsList){
        if(currPage === 1){
          setNotificationList([...response.data.notificationsList]);
        }else{
          setNotificationList([...notificationList, ...response.data.notificationsList]);
        }
        
      }
    };
    if (!lastList && prevPage !== currPage) {
      fetchData();
    }
  }, [currPage, lastList, prevPage, notificationList]);

  const onScroll = () => {
   
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if ((scrollTop + clientHeight + 1) >  scrollHeight) {
        setCurrPage(currPage + 1);
      }
    }
  };

  const notificationTypes = {
    emergency: { icon: Hand, title: "Emergency Alert" },
    trackMe: { icon: LocationIcon, title: "Location Sharing" },
    trackingRequest: { icon: LocationIcon, title: "Location Sharing" }
  };


  return (
    <Grid  onScroll={onScroll}
    ref={listInnerRef}
      // className='noti-main-container' 
    sx={{ height: `${mobileHeight - 140}px`, overflowY: "auto", margin:"auto"}}
    >
      {notificationList && notificationList.map((item) => {
        const defaultIcon = Clock;
        const defaultTitle = "";
        const type = item?.notificationTypeCode;
        const { icon, title } = notificationTypes[type] || { icon: defaultIcon, title: defaultTitle };
    
        return (
          <>
            <Box className="laptop-view"  >
              <StyledCard style={{ padding: "10px", marginBottom: "10px" }} onClick={() => { readNotification(item) }}>
                <Grid container spacing={2} >
                  <Grid item xs={2} >
                    <img src={icon} alt="Alert" />
                  </Grid>
                  <Grid item xs={10} >
                    <Typography className="primary-font-bold-5" style={{ display: "flex", alignItems: "center" }}>
                      {title}
                      <span style={{ paddingLeft: "10px" }}> <img src={alertMessage.lastAlert} alt="Alert" /></span>
                      <span style={{ paddingLeft: "10px" }} className='font-normal-small'>{moment(new Date(item.createdAt)).fromNow()}</span>
                      <div style={{display:"flex",marginLeft:'30px' ,alignItems:"center"}}> <span className={((selectedIds && selectedIds.indexOf(item?.id) !== -1) || item?.notificationRead === true) ? "" : 'dot'} ></span></div>
                    </Typography>
                    <Typography className="font-normal-small" style={{ color: "#AEAEAE" }}>
                      By {item?.createdByUserName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className="font-normal-small" sx={{ color: "grey" }}>{item.messages}</Typography>
                  </Grid>
                </Grid>
              </StyledCard>
            </Box>
            <Box className="mobile-view" >
              <Card style={{maxWidth:"270px",marginTop:"10px",padding:"10px" }} onClick={() => { readNotification(item) }}>
                <Grid container spacing={1} sx={{width:"100%"}}  >
                  <Grid item xs={3} sx={{display: "flex", alignItems: "center"}} >
                    <img src={icon} alt="Alert" />
                  </Grid>
                  <Grid item xs={9} >
                    <Typography className="primary-font-bold-5" style={{ display: "flex", alignItems: "center",textAlign:"center"  }}>
                      {title}
                      <span className={((selectedIds && selectedIds.indexOf(item?.id) !== -1) || item?.notificationRead === true) ? "" : 'dot'} style={{ marginLeft: "50px" }}></span>
                      </Typography>
                      <Grid item xs={12} >
                    <Typography className="primary-font-bold-5" style={{ display: "flex", alignItems: "center",textAlign:"center" }}>
                      {/* {title} */}
                      <span style={{ }}> <img style={{ paddingTop:"5px"}} src={alertMessage.lastAlert} alt="Alert" /></span>
                      <span style={{ paddingLeft: "10px" }} className='font-normal-small'>{moment(new Date(item.createdAt)).fromNow()}</span>
                     
                    </Typography>
                    </Grid>
                    <Typography className="primary-font-normal-5" style={{ color: "#AEAEAE" }}>
                      By {item?.createdByUserName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className="primary-font-normal-5" sx={{ color: "grey" }}>{item.messages}</Typography>
                  </Grid>
                </Grid>
              </Card>
            </Box>
          </>
        )
      })}
    </Grid>
  )


}

function mapStateToProps(state) {
  return {
    addEmergencyAlert: state.emergencyAlert.addEmergencyAlert,
    emergencyListData: state.emergencyAlert.emergencyList,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      readNotificationData,
      getAllNotificationList
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationSystem);



