import React, { useState } from 'react'
import ContentWrapper from '../../../../../common/components/ContentWrapper';
import { Grid } from "@mui/material";

import CreditCardList from './CreditCardList.js';

const MainLayout = ({ goback = true }) => {
 
return (
    <Grid container spacing={2} style={{width: '100%', margin: 0}}>
      <Grid item xs={1} md={1} lg={1}></Grid>
      <Grid
        item
        xs={8}
        md={8}
        style={{ display: "flex", flexWrap: 'wrap',padding:'40px 0px 44px 0px',gap: 30}}
      >
            <CreditCardList />
        </Grid>
      <Grid item xs={1} md={1} lg={1}></Grid>
        
    </Grid>
)
}

const MyCreditCards = () => {

  return (
    <ContentWrapper title={"My Credit Cards"} gobackUrl={'/my-credit-cards'} goback={true} img={false} contenview={<MainLayout />} />
  )
}

export default MyCreditCards;