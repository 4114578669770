import React, { useState, useEffect, useContext, useMemo,useRef } from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import ContentWrapper from "../../../../ContentWrapper";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { checkIsRequested } from "../../../redux/actions";
import { UserGeolocationContext, UserInfoDetails } from "../../../../../../utils/userContext";
import LiveTrackingMeetingLocation from "../../EmergencyAlert/components/LiveTrackingMeetingLocation";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import "../styles/TrackMe.css";

const statusLabelLookup = {
    accepted: "Accepted",
    rejected: "Rejected",
    pending: "Pending",
  }


const statusLookupBackground = {
    accepted: "#3BC273",
    pending: "#FFF9E5",
    rejected: "#FF3535",
};

const textColor = {
    accepted: "#ffffff",
    pending: "#EAA434",
    rejected: "#ffffff",
};
const MainLayout = ({ requestView }) => {
    console.log("requestView", requestView);
    const { userObject } = useContext(UserInfoDetails)
    const userLocation = useContext(UserGeolocationContext);
    const currentLocation = { ...userLocation, ...userObject };
  const navigate = useNavigate();



    return (
        <Grid container spacing={2} style={{ margin: 0, width: "100%" }}>
            {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
            <Grid className="track-me-container">
            <Grid
                container
                xs={12}
                md={12}
                lg={12}
                className="box-container"
                 >
                <Grid container spacing={2} style={{ width: "100%", margin: 0 }}>
                    <Grid item xs={12} style={{ paddingLeft: 0, paddingTop: 0 }}>
                        <div  className="box-header" >
                            <Typography className="primary-font-bold-2-heading">
                                Request for tracking
                            </Typography>
                            <Button
                                variant="outlined"
                                className="primary-font-bold-6 action-btn-yes profile-save-btn"
                                onClick={() => navigate("/request-for-tracking",{ state: { requestData: requestView,isUpdate:true } })}
                            >
                                <EditIcon style={{ fontSize: "19px", marginRight: "2px" }}></EditIcon>
                                Edit
                            </Button>
                        </div>
                    </Grid>
                    <Grid item  xs={12} md={12} lg={12}>
                        <br />
                        <Grid container spacing={2} style={{ width: "100%", marginBottom: 60, display: "flex", justifyContent: "center" }}>
                            <Grid item  style={{ paddingTop: 0 }} xs={12} md={6} lg={6} >
                                <div id="meeting-lcoation" style={{ marginTop: "20px" }}>
                                    <LiveTrackingMeetingLocation membersList={requestView?.membersList} meetingLocation={currentLocation} />
                                </div>

                            </Grid>
                            <Grid item xs={12} md={6} lg={6} id="second-grid-item">
                                <div>
                                    <Grid
                                        style={{
                                            marginTop: "20px",
                                            marginBottom: "20px",
                                        }}
                                    >
                                        {requestView &&
                                            requestView.membersList &&
                                            requestView.membersList.map((row) => {
                                                return (
                                                    <>
                                                        <Grid
                                                            container
                                                            key={row.id}
                                                            sx={{
                                                                mr: "12px",
                                                                padding: "0px",
                                                                border: "1px solid #D8D8D8",
                                                                borderRadius: "10px",
                                                                marginBottom: "10px"
                                                            }}
                                                        >
                                                            <Grid
                                                                xs={12}
                                                                md={8.5}
                                                                lg={8.5}
                                                                sx={{
                                                                    justifyContent: "start",
                                                                    textTransform: "capitalize",
                                                                }}
                                                            >
                                                                <Typography className="primary-font-bold-5" style={{ padding: "12px" }}>
                                                                    {row.name
                                                                        ? row.name
                                                                        : row.firstName +
                                                                        " " +
                                                                        (row?.middleName && row?.middleName !== null ? row.middleName : "") +
                                                                        " " +
                                                                        (row?.lastName && row?.lastName !== null ? row?.lastName : "")}
                                                                </Typography>
                                                            </Grid>

                                                            <Grid
                                                                xs={12}
                                                                md={3.5}
                                                                lg={3.5}
                                                                sx={{
                                                                    justifyContent: "start",
                                                                    textAlign: "center",
                                                                    borderTopRightRadius: "9px",
                                                                    borderBottomRightRadius: "9px",
                                                                    backgroundColor:
                                                                        row.status
                                                                            ? statusLookupBackground[row.status]
                                                                            : statusLookupBackground["pending"],
                                                                }}
                                                            >
                                                                <Typography
                                                                    className="primary-font-normal-2"
                                                                    style={{
                                                                        textTransform: "capitalize",
                                                                        paddingTop: 9,
                                                                        fontWeight: "bold",
                                                                        color:
                                                                            row.status
                                                                                ? textColor[row.status]
                                                                                : textColor["pending"],
                                                                    }}
                                                                >
                                                                    {row.status
                                                                        ? statusLabelLookup[row.status]
                                                                        : statusLabelLookup["pending"]}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                );
                                            })}
                                    </Grid>
                                </div>
                                <div style={{ marginBottom: "20px", }}>
                                    {requestView && requestView.messageCustom && (
                                        <Typography className="primary-font-bold-5" sx={{ my: 2 }}>Message</Typography>
                                    )}
                                    {requestView && requestView.messageCustom && (<Grid
                                        container
                                        sx={{
                                            mr: "12px",
                                            padding: "12px",
                                            border: "1px solid #D8D8D8",
                                            borderRadius: "10px",
                                            marginBottom: "10px"
                                        }}
                                    >
                                        <Grid
                                            xs={12}
                                            md={8.5}
                                            lg={8.5}
                                            sx={{
                                                justifyContent: "start",
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            <Typography className="">
                                                {requestView?.messageCustom}
                                            </Typography>
                                        </Grid>
                                    </Grid>)}
                                </div>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            </Grid>
        </Grid>
    );
};

MainLayout.defaultProps = {
    isFetching: null,
    error: null,
};

const RequestView = ({
    checkIsRequested,
    requestReadResponse
}) => {
    const intervalRef = useRef(null);
    const pathName = window.location.pathname;
    const [isLive, setIsLive] = useState(pathName === "/request-for-tracking/view" ? true : false)
    // useEffect(() => {
    //     if (isLive) {
    //         intervalRef.current = setInterval(checkIsRequested, 10000); 
    //     } else {
    //         clearInterval(intervalRef.current);
    //     }

    //     return () => {
    //         clearInterval(intervalRef.current); 
    //     };
    // }, [isLive]);

    useEffect(() => {
        checkIsRequested();
    }, []);


    const requestView = useMemo(() => {
        if (requestReadResponse?.status === 200 && requestReadResponse?.data) {
            return requestReadResponse.data;
        }
        return null;
    }, [requestReadResponse]);


    return (<>
        <ContentWrapper
            title={"REQUEST FOR TRACKING"}
            gobackUrl={"/track-me"}
            goback={true}
            contenview={
                <MainLayout
                    requestView={requestView}
                />
            }
        />
    </>
    )

};

function mapStateToProps(state) {
    return {
        requestReadResponse: state.trackMe.requestReadResponse,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            checkIsRequested
        },
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(RequestView);