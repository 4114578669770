import React from "react";
import ContentWrapper from '../../../../../common/components/ContentWrapper';
import AddFamilyMembers from './AddFamilyMembers';

const AddFamilyMembersComponent = () => {
    return (
      <ContentWrapper title={"My Family Members"} goback={true} gobackUrl={'/my-family-members'} contenview={<AddFamilyMembers />} />
    )
  }
 
  export default AddFamilyMembersComponent;
