import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Card,
  CardActions,
  Typography,
  Divider,
  Button,
  Grid,
  Box,
  Input,
} from "@mui/material";
import Torando from "../../../../../assets/images/settings/tornado.png";
import ActiveShooter from "../../../../../assets/images/settings/active-shoooter.png";
import WinterStorm from "../../../../../assets/images/settings/winter-storm.png";
import Flood from "../../../../../assets/images/settings/flood.png";
import Earthquake from "../../../../../assets/images/settings/earthquake.png";
import WildFire from "../../../../../assets/images/settings/wild-fire.png";
import Hurricane from "../../../../../assets/images/settings/hurricane.png";
import ThunderStorm from "../../../../../assets/images/settings/thunder.png";
import NoPreview from "../../../../../assets/images/No-Preview.png";
import ErrorIcon from "../../../../../assets/icons/error-icon.svg";
import Radio from "@mui/material/Radio";

import RemoveDialog from "../../../common/RemoveDialog";
import EditDisaster from "./EditDisaster.js";

import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import { getDisasterList, deleteDisasterApi } from "../../redux/actions";
import AddDisaster from "./AddDisaster.js";

const StyledCard = styled(Card)(({ theme }) => ({
  position: "relative",
  maxWidth: 216,
  border: "1px solid #D8D8D8",
  borderRadius: 10,
  boxShadow: "none",
  "&:hover": {
    border: "1px solid #FF3535",
    boxShadow: "0px 2px 8px #00000033",
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: "290px",
    width:'250px'
  },
}));

const ImageOverlay = styled("div")({
  position: "relative",
  overflow: "hidden",
  border: "1px solid #fff",
});

const Image = styled("img")({
  width: "100%",
  display: "block",
  objectFit: "cover",
});

const TextOverlay = styled("div")({
  position: "absolute",
  bottom: 0,
  left: 0,
  background: "#000000BA 0% 0% no-repeat padding-box",
  opacity: 1,
  width: "100%",
  boxSizing: "border-box",
  textAlign: "center",
  padding: "8px 16px",
  color: "#fff",
});

const RadioOverlay = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  opacity: 1,
  width: "100%",
  boxSizing: "border-box",
  padding: "8px 16px",
  color: "#fff",
});

const defaultDisasterList = [
  {
    image_PreSignedUrl: Torando,
    imageAlt: "Tornado",
    name: "Tornado",
    id: 1,
  },
  {
    image_PreSignedUrl: ActiveShooter,
    imageAlt: "Active Shooter",
    name: "Active Shooter",
    id: 2,
  },
  {
    image_PreSignedUrl: WinterStorm,
    imageAlt: "Winter Storm",
    name: "Winter Storm",
    id: 3,
  },
  {
    image_PreSignedUrl: Flood,
    imageAlt: "Flood",
    name: "Flood",
    id: 4,
  },
  {
    image_PreSignedUrl: Earthquake,
    imageAlt: "Earthquake",
    name: "Earthquake",
    id: 5,
  },
  {
    image_PreSignedUrl: WildFire,
    imageAlt: "Wild Fire",
    name: "Wild Fire",
    id: 6,
  },
  {
    image_PreSignedUrl: Hurricane,
    imageAlt: "Hurricane",
    name: "Hurricane",
    id: 7,
  },
  {
    image_PreSignedUrl: ThunderStorm,
    imageAlt: "Thunder Storm",
    name: "Thunder Storm",
    id: 8,
  },
];

const DisasterList = ({
  getDisasterList,
  disasterList,
  deleteDisasterApi,
  deletedDisaster,
  Crud = false,
  onBackOrCancelClick,
  handleNext,
  emergencyAlert = false,
  addEmergencyAlert,
}) => {
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [editDisasterDialogOpen, setEditDisasterDialogOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [disasterListArray, setDisasterListArray] = useState([]);
  const [userid, setuserid] = useState(null);
  const [disasterErr, setDisasterErr] = useState("");
  const [addDisasterDialogOpen, setAddDisasterDialogOpen] = useState(false);
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const handleCloseDialog = (value) => {
    setAddDisasterDialogOpen(false);
  };

  const handleDialogOpen = (card) => {
    setSelectedCard(card);
    setRemoveDialogOpen(true);
  };
  const handleEditDialogOpen = (card) => {
    setSelectedCard(card);
    setEditDisasterDialogOpen(true);
  };

  const handleDialogClose = () => {
    setSelectedCard(null);
    setRemoveDialogOpen(false);
  };
  const handleEditDialogClose = () => {
    setSelectedCard(null);
    setEditDisasterDialogOpen(false);
    getDisasterList();
  };

  useEffect(() => {
    getDisasterList();
  }, []);

  useEffect(() => {
    if (disasterList && disasterList.status === 200) {
      const sortedData = disasterList?.data.sort((a, b) => {
        if (a.name === 'Active Shooter') return -1; 
        if (b.name === 'Active Shooter') return 1; 
        return 0; 
    });
      setDisasterListArray(sortedData);
      if(addEmergencyAlert?.disaster){
        setValue(addEmergencyAlert?.disaster?.id);
      }else{
        setValue(sortedData[0].id);
      }
      
      // const newData = { ...addEmergencyAlert };
      // newData.disaster = sortedData[0];
      // dispatch({
      //   type: "ADD_EMERGENCY_ALERT",
      //   payload: newData,
      // });
    }
    if (disasterList && disasterList.status === 404) {
      setDisasterListArray([]);
      handleDialogClose();
    }
    if (disasterList && disasterList.status !== 200) {
    }
  }, [disasterList]);
  useEffect(() => {
    if (deletedDisaster && deletedDisaster.status === 200) {
      getDisasterList();
      handleDialogClose();
    }
  }, [deletedDisaster]);

  const onDeleteSave = () => {
    deleteDisasterApi(userid);
  };
  const handleEmergency = (item) => {
    if (emergencyAlert === true) {
      const newData = { ...addEmergencyAlert };
      newData.disaster = item;
      setDisasterErr("");
      setValue(item.id);
      dispatch({
        type: "ADD_EMERGENCY_ALERT",
        payload: newData,
      });
    }
  };
  const handleAddDisaster = () => {
    if (emergencyAlert) {
      if (addEmergencyAlert && addEmergencyAlert.disaster) {
        handleNext();
      } else if (value) {
        disasterListArray.forEach(item => {
          if (item.id === value) {
            const newData = { ...addEmergencyAlert };
            newData.disaster = item;
            dispatch({
              type: "ADD_EMERGENCY_ALERT",
              payload: newData,
            });
            handleNext();
          }
        })
      } else {
        console.log("Please select the Disaster");
        setDisasterErr("Please select the Disaster");
      }
    }
  };
  useEffect(() => {
    if (emergencyAlert === true) {
      const newData = { ...addEmergencyAlert };
      if (newData.disaster) {
        setValue(newData.disaster.id);
      }
    }
  }, [emergencyAlert]);
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box sx={{ mb: "20px" }} className="box-header">
            <Typography className="primary-font-bold-2-heading">
              Disaster Type
            </Typography>
            <Button
              variant="outlined"
              className="primary-font-bold-6 action-btn-yes profile-save-btn"
              onClick={() => {
                setAddDisasterDialogOpen(true);
              }}
            >
              Add
            </Button>
          </Box>
        </Grid>
        {disasterErr?.trim().length > 0 && (
          <div style={{ display: "flex", paddingLeft: "20px" }}>
            <img src={ErrorIcon} alt="error" href="#" />
            <Typography
              className="primary-font-normal-1"
              style={{ marginLeft: 10, color: "red" }}
            >
              {disasterErr}
            </Typography>
          </div>
        )}
        <Grid
          item
          container
          // sx={{ ml: "25px" }}
          rowGap={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            padding: "40px 0px",
            gap: 30,
          }}
        >
          {disasterListArray.map((item, i) => {
            return (
              <StyledCard
                key={"disaster_" + i}
                onClick={() => {
                  handleEmergency(item);
                }}
              >
                <ImageOverlay>
                  <Image
                    src={item.image_PreSignedUrl ? item.image_PreSignedUrl : NoPreview}
                    alt={item.imageAlt || "No Image Available"}
                    height={167}
                    style={{ width: "100%", objectFit: "cover" }}
                  />
                  {Crud ? (<>
                    <RadioOverlay>
                    <Radio
                      checked={value === item.id}
                      onChange={() => {
                        handleEmergency(item);
                      }}
                      value={i}
                      name="radio-buttons"
                      inputProps={{ "aria-label": "A" }}
                      sx={{
                        "&, &.Mui-checked": {
                          color: "white",
                        },
                        justifyContent: "flex-start",
                        display: "no",
                        padding: "0px",
                        borderColor: "#FFFFFF",
                      }}
                    />
                  </RadioOverlay>
                  </>) : (<></>)}
                  <TextOverlay>
                    <Typography variant="h6" className="primary-font-bold-6">
                      {item.name}
                    </Typography>
                  </TextOverlay>
                </ImageOverlay>
                <Divider />
                {Crud ? (
                  ""
                ) : (
                  <CardActions
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 10,
                    }}
                  >
                    {item.defaultDisaster ? (
                      <div className="inactive-button-dlt"></div>
                    ) : (
                      <div
                        className="action-button-dlt"
                        onClick={() => {
                          setuserid(item.id);
                          handleDialogOpen(item);
                        }}
                      ></div>
                    )}
                    {item.defaultDisaster ? (
                      <div className="inactive-button-edit"></div>
                    ) : (
                      <div
                        className="action-button-edit"
                        onClick={() => {
                          handleEditDialogOpen(item);
                        }}
                      ></div>
                    )}
                  </CardActions>
                )}
              </StyledCard>
            );
          })}
        </Grid>
        {Crud ? (
          ""
        ) : (
          <>
            {editDisasterDialogOpen && (
              <EditDisaster
                selectedCard={selectedCard}
                openDialog={editDisasterDialogOpen}
                onClose={handleEditDialogClose}
                onSave={handleEditDialogClose}
              />
            )}
          </>
        )}

        {removeDialogOpen && (
          <RemoveDialog
            openDialog={removeDialogOpen}
            onClose={handleDialogClose}
            cardContent={"Are you sure to delete?"}
            onSave={onDeleteSave}
          />
        )}

        {Crud ? (
          <div
            className="action-holder action-holder-3"
            style={{ marginTop: 40, marginBottom: 50 }}
          >
            <Button
              variant="outlined"
              className="primary-font-bold-6 action-btn profile-cancel-btn"
              onClick={onBackOrCancelClick}
            >
              Back
            </Button>
            <Button
              variant="outlined"
              className="primary-font-bold-6 action-btn profile-save-btn"
              onClick={() => {
                handleAddDisaster();
              }}
            >
              Next
            </Button>
          </div>
        ) : (
          ""
        )}

        {addDisasterDialogOpen && (
          <AddDisaster
            addDisasterDialogOpen={addDisasterDialogOpen}
            onClose={handleCloseDialog}
          />
        )}
      </Grid>
    </>
  );
};

DisasterList.defaultProps = {
  isFetching: null,
  error: null,
  disasterList: [],
};

function mapStateToProps(state) {
  return {
    isFetching: state.setting.isFetching,
    error: state.setting.error,
    disasterList: state.setting.disasterList,
    deletedDisaster: state.setting.deletedDisaster,
    addEmergencyAlert: state.emergencyAlert.addEmergencyAlert,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getDisasterList,
      deleteDisasterApi,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DisasterList);
