import React, { useEffect, useState } from "react";
import { Button, Box, Typography, Divider, Card, CardContent } from "@mui/material";
import * as formatters from '../../../../utils/util';
import { useNavigate } from "react-router-dom";
import {resetForgotPasswordData, resetPasswordData, checkLinkExpiration, fetchForgotPasswordData, setAdminPassword,setInvitedUserPassword} from '../redux/forgotPasswordAction'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CloseIcon from"../../../../assets/icons/modal-close-icon.svg";
import {useLocation} from "react-router-dom";

const SetPassword = (props) => {
    const {
        resetForgotPasswordData, 
        forgotPasswordData,
        resetPasswordData,
        forgotPasswordError,
        setAdminPassword,
        isFetchingFP,
        fpBackendData,
        checkLinkExpiration,
        setInvitedUserPassword,
        linkExpiryCheck,
        fpBackendError,
        fetchForgotPasswordData
      } = props;
      const search = useLocation().search;
      const uuid = new URLSearchParams(search).get('uuid');

  const navigate = useNavigate();
  const [code, setCode] = useState(null)
  const [newPassword, setNewPassword] = useState(null);
  const [confirmNewPassword, setConfirmNewPassword] = useState(null);
  const [codeErr, setCodeErr] = useState('');
  const [newPasswordErr, setNewPasswordErr] = useState('');
  const [confirmNewPasswordErr, setConfirmNewPasswordErr] = useState('');
  const [hiddenEmail, setHiidenEmail] = useState(window.localStorage.getItem('hiddenEmail'));
  const [isLinkExpired, setIsLinkExpired] = useState(false)
  const [userId, setUserId] = useState(null);
  const [emailId, setEmail] = useState(null);
  const [showCode, setShowCode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowCode = () => setShowCode(!showCode);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const handleMouseDownCode = () => setShowCode(!showCode);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleMouseDownConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
 

 

  useEffect(()=>{
        if(uuid !== ''){
            const data = search.replace('?uuid=','');
            const fd = new FormData();
            fd.set('userId',data)
            checkLinkExpiration(fd)
        }
  },[])

  useEffect(()=>{
    if(linkExpiryCheck && linkExpiryCheck.status === 200 && linkExpiryCheck.data){
        const expiryData = linkExpiryCheck.data;
        if(expiryData.data.isLinkExpired && expiryData.data.isLinkExpired === "true"){
            setIsLinkExpired(true)
            setUserId(null)
        }else{
            setIsLinkExpired(false)
            setUserId(expiryData.data.userId);
            setEmail(expiryData.data.emailId)
        }
    }   
  },[linkExpiryCheck])

  useEffect(()=>{
    if(fpBackendData && fpBackendData.status === 200){
        navigate('/')
    }else if(fpBackendData && fpBackendData.status !== 200){
        setCodeErr(fpBackendData.message)
    }
  },[ fpBackendError, fpBackendData])

  const onChangePassword = (e, type) => {
    if (type === 'code') {
        setCode(e.target.value)
    } else if (type ===  'newPassword') {
        setNewPassword(e.target.value);
    } else if (type ===  'confirmNewPassword') {
        setConfirmNewPassword(e.target.value);
    }
  }
  const onClickSave = () => {
    if (!newPassword?.trim().length) {
      setNewPasswordErr('This is required.');
      return
    } else if (!formatters.isPasswordValid(newPassword)) {
        setNewPasswordErr('Enter a valid New Password.');
        return
    } else {
        setNewPasswordErr('');
    }
    if (!confirmNewPassword?.trim().length) {
        setConfirmNewPasswordErr('This is required.');
        return
    }  else if (newPassword !== confirmNewPassword) {
        setConfirmNewPasswordErr('New Password and Confirm New Password mismatch.');
        return
    } else {
        setConfirmNewPasswordErr('');
    }
    const data = {
        "id": "",
        "emailId": emailId,
        // "password": newPassword,
        "updatedBy": userId,
        "updateVerificationFlag": true
    }
    setInvitedUserPassword(data)
 
    const inputObject = {
        id:"",
        username: emailId,
        password: newPassword,
    }
    setAdminPassword(inputObject)
  }
  const handleCloseIconClick = () => {
    (navigate('/login'))
    setCodeErr('');
    setNewPasswordErr('');
    setConfirmNewPasswordErr('');
  }
  return (
    <Box
    className="loginScreen"
    id='account-layout'
  > 
        {isLinkExpired && (
                <h1>Sorry your sign-in link expired! </h1>
        )}
      {!isLinkExpired && (
         <Card className="setpassword-container" >
            <div style={{ display: 'flex',   justifyContent: 'space-between' }}>
            <Typography className='primary-font-bold-heading2' style={{paddingLeft: 30, paddingTop:0}}>Set Password</Typography>
              <div style={{ display: 'flex',alignitems:'flex-end',justifycontent:'end',paddingRight:30}}>
                <img src={CloseIcon} alt="close-icon" style={{cursor:'pointer',width:"21px"}} onClick={handleCloseIconClick} sx={{
                        marginRight:"30px"
                    }}/>         
                </div>
            </div>
            <Divider />
            <CardContent style={{padding: '15.5px 30px 32px 30px'}}>
              <div>
                  <Typography className='font-normal-small error-message'>{newPasswordErr}</Typography>
                  <Typography className='font-normal-small profile-input-label'>New Password</Typography>
                  <div className="input-holder"> 
                    <input className={`profile-input ${newPasswordErr !== '' ? 'err-input' : ''}`}  aria-label='new-password' type={showPassword ? "text" : "password"} onChange={(e) => {onChangePassword(e, 'newPassword')}}   maxLength={12} value={newPassword}/>
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="password" 
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        className="input-eye-icon pwd-icon1"
                        >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </div>
              </div>
              <div>
                  <Typography className='font-normal-small error-message'>{confirmNewPasswordErr}</Typography>
                  <Typography className='font-normal-small profile-input-label'>Confirm New Password</Typography>
                  <div className="input-holder"> 
                    <input className={`profile-input ${confirmNewPasswordErr !== '' ? 'err-input' : ''}`} aria-label='confirm-new-password' type={showConfirmPassword ? "text" : "password"} onChange={(e) => {onChangePassword(e, 'confirmNewPassword')}}   maxLength={12} value={confirmNewPassword}/>
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="password" 
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                        className="input-eye-icon pwd-icon1"
                        >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </div>
              </div>
              <div className="action-holder setpassword-btn-margin " style={{marginBottom: 0}}>
                <Button variant="outlined" style={{width: '100%'}}  className="primary-font-bold-6 action-btn profile-save-btn"  onClick={onClickSave}>Set Password</Button>
              </div>
            </CardContent>
        </Card>
        )} 
  </Box>
        
  )
}

function mapStateToProps(state){
    return{
        forgotPasswordData: state.forgotPassword.forgotPasswordData,
        forgotPasswordError: state.forgotPassword.forgotPasswordError,
        isFetchingFP : state.forgotPassword.isFetchingFP,
        fpBackendData: state.forgotPassword.fpBackendData,
        fpBackendError: state.forgotPassword.fpBackendError,
        linkExpiryCheck: state.forgotPassword.linkExpiryCheck,
        linkExpiryError:  state.forgotPassword.linkExpiryError
    }
}
function mapDispatchToProps(dispatch){
    return bindActionCreators(
            {
                resetForgotPasswordData,
                resetPasswordData,
                checkLinkExpiration,
                fetchForgotPasswordData,
                setAdminPassword,
                setInvitedUserPassword
            },
            dispatch
        )
}
export default connect(mapStateToProps,mapDispatchToProps)(SetPassword);
