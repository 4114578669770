import React, { useEffect, useState } from "react";
import { Typography, Dialog, DialogTitle, DialogContent, Divider, DialogActions, Button, FormControl, Select, Box, MenuItem } from "@mui/material";
import CloseIcon from "../../../../../assets/icons/modal-close-icon.svg";
import { getAllCategory } from '../../redux/actions';
import "../DisasterManagement/styles/DisasterManagement.css";

import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';

const EditMessage = ({ openDialog, onClose, onDialogClose, onDialogueSave, selectedCard, disableEdit, categoryList, getAllCategory }) => {
  const [message, setMessage] = useState("");
  const [category, setCategory] = useState("");
  const [listCategory, setListCategory] = useState([]);
  useEffect(() => {
    getAllCategory()
  }, [])
  useEffect(() => {
setCategory(selectedCard?.categoryCode)
setMessage(selectedCard?.message)
  },[selectedCard])
  useEffect(() => {
    if (categoryList) {
      setListCategory(categoryList)
    }
  }, [categoryList])

  return (
    <Dialog
      open={openDialog}
      onClose={onClose}
      className="change-pwd-dialog dialog-box predefined-box"
      aria-labelledby="change-pwd-dialog-title"
      aria-describedby="change-pwd-dialog-description"
    >
      <DialogTitle id="change-pwd-dialog-title">
        <Typography className='primary-font-bold-heading2'>Predefined Message</Typography>
        {openDialog ? (
          <img src={CloseIcon} alt="close-icon" style={{ cursor: 'pointer',width:"21px" }} onClick={onDialogClose} sx={{
            marginRight: '-30px',
          }} />
        ) : null}
      </DialogTitle>
      <Divider />
      <DialogContent id="alert-dialog-description">

        <Typography className="font-normal-small profile-input-label">
          {("Category")}<span style={{ color: "red" }}>{" *"}</span>
        </Typography>
        <FormControl style={{ marginRight: 0, marginLeft: 0, marginTop: 0, marginBottom: 20, width: '100%' }}>
         
          <Select id="category" name="category" className="relation-select"style={{fontSize:"14px"}}
            value={category}
            renderValue={(value) => {
              return (
                <Box sx={{ display: "flex", gap: 1 }}>
                  {categoryList[category]}
                </Box>
              );
            }}
            disabled
            // onChange={(e) => { setCategory(e.target.value) }}
            >
            {
              Object.values(listCategory).map((value) => (
                <MenuItem key={value} value={value}>
                  {listCategory[value]} 
                </MenuItem>
              ))
            }

          </Select> 

        </FormControl>

        <Typography className='font-normal-small profile-input-label'>Message <span style={{ color: "red" }}>{" *"}</span></Typography>
        <textarea className="profile-input" aria-label='first name' value={message} onChange={(e) => { setMessage(e.target.value) }} disabled={disableEdit?message:""}
          style={{ height: '150px', fontFamily: "Roboto,Helvetica,Arial,sans-serif",lineHeight:"1.6",fontSize:"14px",color:disableEdit?"rgba(0, 0, 0, 0.6)":"" }} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <div className="action-holder cancel-save-btn" style={{ marginTop: 20, marginBottom: 0 }}>
          <Button variant="outlined" className="primary-font-bold-6 btn-width profile-cancel-btn" onClick={onDialogClose}>Cancel</Button>
          {!disableEdit && <Button variant="outlined" className="primary-font-bold-6 btn-width profile-save-btn" onClick={() => { onDialogueSave(message, category) }} autoFocus>Save</Button>}
        </div>
      </DialogActions>
    </Dialog>
  );

};

function mapStateToProps(state) {
  return {
    isMessageAPIFetching: state.setting.isMessageAPIFetching,
    createMessage: state.setting.createMessage,
    messageError: state.setting.messageError,
    categoryList: state.setting.categoryList
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAllCategory
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditMessage);



