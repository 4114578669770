import React, { useEffect, useState,useContext } from "react";
import {
  Button,
  Typography,
  Alert,
  CircularProgress,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  Grid,
  Link
} from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import {
  PaymentElement,
  useStripe,
  useElements,
  CardElement
} from "@stripe/react-stripe-js";
import { saveCardDetails } from "../redux/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Confetti from 'react-confetti'
import { UserInfoDetails } from "../../../../utils/userContext";
import { savePaymentConfirm } from "../redux/actions";



const CheckoutForm = ({ planDetails,saveCardDetails,isSaveCard,handleNext,paymentIntentId,customerId,savePaymentConfirm }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState('');
  const [isPayProcessing, setIsPayProcessing] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [ispromoApplied, setIsPromoApplied] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [palnType, setPlanType] = useState("");
  const [planTerm, setPlanTerm] = useState("");
  const [grandTotal, setGrandTotal] = useState("");
  const [offerPercent, setOfferPercent] = useState("0.30");
  const [discountValue, setDiscountValue] = useState(0);
  const [promoValue, setPromoValue] = useState(0);
  const [promoError, setPromoError] = useState(false);

  const userObject = localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo"))
          : null;

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

  }, [stripe]);


  const handleSubmit = async (e) => {
    e.preventDefault();


    if (!stripe || !elements) {
      return;
    }
    if (isChecked) {
      const apiReq = {
        "accountId": userObject?.accountId,
        "userId": userObject?.id,
        "isSaveCard": isChecked,
        "paymentIntentId": paymentIntentId
      }
      saveCardDetails(apiReq)
      stripePaymentApi()
    }else{
      stripePaymentApi()
    }
  };


  const stripePaymentApi = async() =>{
    setIsPayProcessing(true);

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {},
        redirect: "if_required",
      });

      if (error) {
        setIsPayProcessing(false);
        console.error('Error confirming payment:', error);
        
        const paymentRequest = {
          "accountId": userObject?.accountId,
          "userId": userObject?.id,
          "customerId": customerId,
          "paymentIntentConfirmId": error?.payment_intent?.id,
          "transactionPromoCode": promoCode
        }
        savePaymentConfirm(paymentRequest)
        if (error.type === "card_error") {
          setErrorMessage(error.message);
        } else {
          // handleNext('failure')

        }
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        setIsPayProcessing(false);
        if (paymentIntent.status === 'succeeded') {
          const paymentRequest = {
            "accountId": userObject?.accountId,
            "userId": userObject?.id,
            "customerId": customerId,
            "paymentIntentConfirmId": paymentIntent?.id,
            "transactionPromoCode": promoCode
          }
          window.localStorage.setItem("paymentMethodId", paymentIntent?.payment_method);
          savePaymentConfirm(paymentRequest)
          handleNext('billing')

        } else {
          setErrorMessage('Unexpected payment status:', paymentIntent.status)
          console.log('Unexpected payment status:', paymentIntent.status);
        }
      }

    } catch (error) {
      console.error('Error:', error);
      handleNext('failure')
    }
  }
  useEffect(() => {
    if (planDetails) {
      setDiscountValue(planDetails.amount * offerPercent)
      setGrandTotal(planDetails.amount)
      //Plan Type
      if (planDetails.transactionPlanDuration === "yearly") {
        setPlanTerm("Annually")
      } else {
        setPlanTerm(planDetails.transactionPlanDuration)
      }

      //Plan Term
      if (planDetails.transactionPlanType === "primary") {
        setPlanType("Premium")
      } else {
        setPlanType(planDetails.transactionPlanType)
      }
    }
  }, [planDetails])

  const paymentElementOptions = {
    layout: "accordion" // "tabs,accordion,auto
  }

  const handlePromoChange = (e) => {
    setPromoError(false)
    let inputValue = e.target.value;
    if (/^[a-zA-Z0-9]*$/.test(inputValue) || inputValue === '') {
      setPromoCode(inputValue);
    }
  };

  const handlePromoApply = () =>{
    if(promoCode === "MYR30"){
      setPromoValue(discountValue.toFixed(2))
      const gTotal = planDetails.amount - discountValue
      setGrandTotal(gTotal.toFixed(2))
      setShowConfetti(true)
      setIsPromoApplied(true)
      setTimeout(() => {
        setShowConfetti(false);
      }, 3100);
    }else{
      setPromoError(true)
    }
    
  }
  const handlePromoRemove = () =>{
    setIsPromoApplied(false)
    setGrandTotal(planDetails.amount)
    setPromoCode("");
    setPromoValue(0)
  }

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
    console.log("Checkbox checked:", event.target.checked);
  };

  
  return (
    <form id="payment-form" >
      {errorMessage !== '' && <Alert className='mb-10' severity="error">{errorMessage}</Alert>}
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <div className="mt-20" style={{display:"none"}}>
        <Typography className="sub-head mt-10 mb-10">Promo Code</Typography>
        <div className='apply-amount mb-10'>
          {!ispromoApplied && (<>
            <input className={`amount-input placeholder-text sub-head ${promoError ? "payment-input" : ""}`}
              type="text"
              placeholder="Enter your promo code"
              style={{ textTransform: 'uppercase'}}
              value={promoCode}
              maxLength={5}
              onChange={handlePromoChange}
            />
            <Button onClick={handlePromoApply} className="normal-text apply-btn custom-button" disabled={promoCode === ""} style={{ textTransform: 'capitalize', borderRadius: 4 }} variant='outlined'>
              Apply
            </Button>
          </>)}

          
          {promoError && <Typography style={{color:"#df1b41"}} className="normal-text mt-10 mb-10">Please enter a valid promo code</Typography>}
          {ispromoApplied && ( <>
            {showConfetti && (<div style={{ position: 'relative', zIndex: 0 }}>
              <Confetti width={330}
                height={60}
                numberOfPieces={200}
                opacity={0.7}
              />
            </div>)}
            <Box
            border={1}
            borderColor="green"
            borderRadius={2}
            sx={{padding:"11px 16px"}}
          >
            <Grid container alignItems="center">
              <Grid item xs={2} display="flex" >
                <TaskAltIcon sx={{ color: 'green' }} />
              </Grid>
              <Grid item xs={6} display="flex">
                <Typography style={{ textTransform: 'uppercase',fontWeight:"bold",fontSize:"13px" }} variant="body1">{`${promoCode}`}</Typography>
                {/* <Typography variant="body1">{` applied`}</Typography> */}
              </Grid>
              <Grid item xs={4} display="flex" justifyContent="end">
                <Typography  onClick={handlePromoRemove} variant="body1" underline="none" sx={{color:"red",fontSize:"13px",cursor:"pointer"}}>
                  Remove
                </Typography>
              </Grid>
            </Grid>
          </Box>
            
          </>)}
         
        </div>
        </div>
        <div>
        <div className='flex-space-btw mt-20'>
          <Typography className="normal-text mb-10" style={{ textTransform: "capitalize" }}>{`Plan Chosen (${planTerm} - ${palnType})`}</Typography>
          <Typography className="normal-text mb-10" style={{whiteSpace:"nowrap"}}>{` $ ${planDetails.amount}`}</Typography>
        </div>
        <div className='flex-space-btw '>
          <Typography className="normal-text mb-10">Promo Applied</Typography>
          <Typography className="normal-text mb-10">{`$ ${promoValue}`}</Typography>
        </div>
        <div className='flex-space-btw mb-20'>
          <Typography className="sub-head mb-10">TOTAL</Typography>
          <Typography className="sub-head mb-10" style={{ textTransform: 'uppercase' }}>{` USD $ ${grandTotal}`}</Typography>
        </div>
      </div>
      <FormControl component="fieldset" className="mb-10">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            value="isSaveCard"
            control={<Checkbox
              checked={isChecked}
              onChange={handleChange}
              sx={{
                color: "red !important",
                '& .MuiSvgIcon-root': {
                  fontSize: 18,
                },
              }} />}
            label="Save this card information for the future"
            labelPlacement="end"
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: 14,
              },
            }}
          />
        </FormGroup>
      </FormControl>
      <Button onClick={handleSubmit} className={`normal-text next-btn ${isPayProcessing ? 'payment-disabled' : ''}`} variant='contained' disabled={!stripe || isPayProcessing}>
        {!stripe || isPayProcessing ? (<><CircularProgress className='progress-color' /><span style={{ color: '#fff' }}>Processing...</span></>) : <span>Confirm Payment</span>}
      </Button>

    </form>
  );
}



function mapStateToProps(state) {
  return{
      isSaveCard: state.profile.isSaveCard
  }
  
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    saveCardDetails,
    savePaymentConfirm
  },dispatch)
  
}

export default connect(mapStateToProps,mapDispatchToProps)(CheckoutForm)