import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CreditScoreIcon from '@mui/icons-material/CreditScore';

const PaymentSuccess = () => {
    return (
        <Container>
            <Grid container justifyContent="center" alignItems="center" >
                <Grid item md={11}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        p={2}
                        borderRadius={1}
                        textAlign="center"
                        className={"_success"}
                    >
                        <CreditScoreIcon fontSize="large" sx={{ color: "green" }} />
                        <Typography variant="h5" component="h5" className='mt-10'>
                            Payment success!
                        </Typography>
                        <Typography className="lg-normal-text mb-30 mt-10" style={{ color: '#111111BF' }}>
                            Your account has been successfully activated.
                        </Typography>
                    </Box>

                    {/* <Box display="flex" justifyContent="center" mt={2}>
                        <Button
                            className="normal-text outline-button"
                            style={{ textTransform: 'capitalize', borderRadius: 4 }}
                            variant='outlined'
                        >
                            Go to Home Page
                        </Button>
                    </Box> */}
                </Grid>
            </Grid>
        </Container>
    );
};

export default PaymentSuccess;
