const initialState = {
  data: null,
  isFetching: false,
  error: null,
  familyMembers: [],
  deleteUserData:null,
  profileDetails: null,
  isAdminRequest: null,
  adminResult: null,
  splInstructions: null,
  splInstructionsData: null,
  selectedUser: null,
  updatedPersonalInfo: null,
  updatedSpecificUserData: null,
  isUpdatingUserInfo: null,
  profilePictureData: null,
  profilePictureError: null,
  isVerifyingPic: null,
  relationship:[],
  demographicInfo : null,
  demographicDetails: null,
  cemeteryUpdate: null,
  cemeteryDetails: null,
  cemeteryError: null,
  userRelativeUpdate: null,
  userRelativeError: null,
  userRelativeList: null,
  updatedUserRelativeList: null,
  stateList: null,
  updateFuneralData: null,
  funeralArrangementDetails: null,
  militaryInfo: null,
  updateMilitaryData: null,
  resendResult: null
};

export const familyReducer = (state = initialState, action) => {
  const {type, payload} = action;
  let currentState = state;
  switch(type) {
    case 'MEMBERINFO_DATA_REQUEST': 
    currentState = {
      ...state,
      isFetching: true,
      error: null,
    };
    break;
    case 'MEMBERINFO_DATA_SUCCESS': 
    currentState = {
      ...state,
      familyMembers: payload,
      isFetching: false,
      error: null,
    };
    break;
    case 'MEMBERINFO_DATA_FAILURE': 
    currentState = {
      ...state,
      isFetching: false,
      error: payload,
    };
    break;
    case'DELETE_DATA_REQUEST':
      currentState={
        ...state,
        isFetching:true,
        error:null,
      };
      break;
      case 'DELETE_DATA_SUCCESS': 
      currentState = {
        ...state,
          deleteUserData: payload,
          isFetching: false,
        error: null,
      };
      break;
      case'DELETE_DATA_FAILURE':
      currentState = {
      ...state,
      isFetching: true,
      error: null,
    };
    break;
  case'ADMIN_DATA_REQUEST':
  currentState={
    ...state,
    isAdminRequest:true,
    error:null,
  };
  break;
  case 'ADMIN_DATA_SUCCESS': 
    currentState = {
      ...state,
        adminResult: payload,
        isAdminRequest: false,
      error: null,
    };
    break;
    case 'ADMIN_DATA_FAILURE': 
    currentState = {
      ...state,
      adminResult: null,
      isAdminRequest: false,
      error: payload,
  };
  break;
  case 'RESEND_DATA_SUCCESS': 
    currentState = {
      ...state,
        resendResult: payload,
      error: null,
    };
    break;
    case 'RESEND_DATA_FAILURE': 
    currentState = {
      ...state,
      resendResult: null,
      error: payload,
  };
  break;
  case'UPDATE_PERSONAL_INFO_REQUEST':
  currentState = {
    ...state,
    isFetching: true,
    error: null,
  };
  break;
  case 'UPDATE_PERSONAL_INFO_SUCCESS': 
    currentState = {
      ...state,
      updatedPersonalInfo: payload,
      isFetching: false,
      error: null,
    };
    break;
    case 'UPDATE_PERSONAL_INFO_FAILURE': 
    currentState = {
      ...state,
      isFetching: true,
      error: payload,
    };
  break;
  case 'RESET_PERSONAL_INFO':
    currentState = {
      ...state,
      isFetching: false,
      updatedPersonalInfo: null,
      error: null,
    };
  break;
  case'GET_SPL_INSTRUCTION_REQUEST':
  currentState = {
    ...state,
    isFetching: true,
    error: null,
  };
  break;
  case 'GET_SPL_INSTRUCTION_SUCCESS': 
    currentState = {
      ...state,
      splInstructionsData: payload,
      isFetching: false,
      error: null,
    };
    break;
    case 'GET_SPL_INSTRUCTION_FAILURE': 
    currentState = {
      ...state,
      isFetching: true,
      error: payload,
    };
  break;
  case'SPL_INSTRUCTION_REQUEST':
  currentState = {
    ...state,
    isFetching: true,
    error: null,
  };
  break;
  case 'SPL_INSTRUCTION_SUCCESS': 
    currentState = {
      ...state,
      splInstructions: payload,
      isFetching: false,
      error: null,
    };
    break;
    case 'SPL_INSTRUCTION_FAILURE': 
    currentState = {
      ...state,
      isFetching: true,
      error: payload,
    };
  break;
  case 'RESET_SPECIAL_INSTRUCTION': 
  currentState = {
    ...state,
    splInstructions: null,
    isFetching: false,
    error: null,
  };
  break;
  case 'SET_SELECTED_USER': 
  currentState = {
    ...state,
    selectedUser: payload
  };
  break;
  case 'RESET_SELECTED_USER': 
  currentState = {
    ...state,
    selectedUser: null
  };
  break;
  case 'USER_DATA_REQUEST': 
  currentState = {
    ...state,
    updatedUserData: null,
    updatedSpecificUserData: true,
    error: null,
  };
  break;
  case 'USER_DATA_SUCCESS': 
  currentState = {
    ...state,
    isUpdatingUserInfo: false,
    updatedSpecificUserData: payload,
  };
  break;
  case 'USER_DATA_FAILURE': 
  currentState = {
    ...state,
    isUpdatingUserInfo: false,
    error: payload,
  };
  break;
  case 'UTILITY_IMAGE_UPDATE_REQUEST': 
  currentState = {
    ...state,
    isVerifyingPic: true,
    profilePictureError: payload,
  };
  break;
  case 'UTILITY_IMAGE_UPDATE_SUCCESS': 
  currentState = {
    ...state,
    profilePictureData: payload,
    isVerifyingPic: false,
    profilePictureError: null,
  };
  break;
  case 'UTILITY_IMAGE_UPDATE_FAILURE': 
  currentState = {
    ...state,
    profilePictureData:null,
    isVerifyingPic: false,
    profilePictureError: payload,
  };
  break;
  case 'RELATIONSHIP_DATA_SUCCESS':
  currentState = {
    ...state,
    relationship: payload
  };
  break;
  case'DEMOGRAPHIC_INFORMATION_REQUEST':
  currentState = {
    ...state,
    isFetching: true,
    error: null,
  };
  break;
  case 'DEMOGRAPHIC_INFORMATION_SUCCESS': 
    currentState = {
      ...state,
      demographicInfo: payload,
      isFetching: false,
      error: null,
    };
    break;
  case 'DEMOGRAPHIC_INFORMATION_FAILURE': 
    currentState = {
      ...state,
      isFetching: true,
      error: payload,
    };
  break;
  case 'RESET_DEMOGRAPHIC_INFORMATION': 
  currentState = {
    ...state,
    demographicInfo: null,
    isFetching: false,
    error: null,
  };
  break;
  case 'GET_DEMOGRAPHIC_INFORMATION_SUCCESS': 
  currentState = {
    ...state,
    demographicDetails: payload,
    isFetching: false,
    error: null,
  };
  break;
case 'GET_DEMOGRAPHIC_INFORMATION_FAILURE': 
  currentState = {
    ...state,
    isFetching: true,
    error: payload,
  };
break;

case'CEMETERY_INFORMATION_REQUEST':
  currentState = {
    ...state,
    isFetching: true,
    error: null,
  };
  break;
case 'CEMETERY_INFORMATION_SUCCESS': 
  currentState = {
    ...state,
    cemeteryUpdate: payload,
    isFetching: false,
    cemeteryError: null,
  };
  break;
case 'CEMETERY_INFORMATION_FAILURE': 
  currentState = {
    ...state,
    isFetching: true,
    cemeteryError: payload,
  };
break;
case 'RESET_CEMETERY_ARRANGEMENT': 
  currentState = {
    ...state,
    cemeteryUpdate: null,
    isFetching: false,
    cemeteryError: null,
  };
  break;

case 'GET_CEMETERY_INFORMATION_SUCCESS': 
currentState = {
  ...state,
  cemeteryDetails: payload,
  isFetching: false,
  error: null,
};
break;
  case 'GET_CEMETERY_INFORMATION_FAILURE': 
  currentState = {
    ...state,
    isFetching: true,
    cemeteryError: payload,
  };
break;
case'UPDATE_USER_RELATIVE_REQUEST':
  currentState = {
    ...state,
    isFetching: true,
    error: null,
  };
  break;
case 'UPDATE_USER_RELATIVE_SUCCESS': 
  currentState = {
    ...state,
    userRelativeUpdate: payload,
    isFetching: false,
    userRelativeError: null,
  };
  break;
case 'UPDATE_USER_RELATIVE_FAILURE': 
  currentState = {
    ...state,
    isFetching: true,
    userRelativeError: payload,
  };
break;
case'USER_RELATIVE_REQUEST':
  currentState = {
    ...state,
    isFetching: true,
    error: null,
  };
  break;
case 'GET_USER_RELATIVE_SUCCESS': 
  currentState = {
    ...state,
    userRelativeList: payload,
    isFetching: false,
    error: null,
  };
  break;
case 'GET_USER_RELATIVE_FAILURE': 
  currentState = {
    ...state,
    isFetching: true,
    userRelativeError: payload,
  };
break;
case'DLT_USER_RELATIVE_REQUEST':
  currentState = {
    ...state,
    isFetching: true,
    error: null,
  };
  break;
case 'DLT_USER_RELATIVE_SUCCESS': 
  currentState = {
    ...state,
    updatedUserRelativeList: payload,
    isFetching: false,
    error: null,
  };
  break;
case 'DLT_USER_RELATIVE_FAILURE': 
  currentState = {
    ...state,
    isFetching: false,
    error: payload,
  };
  break;
  case 'RESET_RELATIVES_TO_NOTIFY': 
  currentState = {
    ...state,
    isFetching: false,
    error: null,
    userRelativeUpdate: null
  };
  break;
  case'STATE_REQUEST':
  currentState = {
    ...state,
    isFetching: true,
    error: null,
  };
  break;
case 'GET_STATE_SUCCESS': 
  currentState = {
    ...state,
    stateList: payload,
    isFetching: false,
    error: null,
  };
  break;
case 'GET_STATE_FAILURE': 
  currentState = {
    ...state,
    isFetching: true,
    error: payload,
  };
break;
case'UPDATE_FUNERAL_ARRANGEMENT_REQUEST':
  currentState = {
    ...state,
    isFetching: true,
    error: null,
  };
  break;
case 'UPDATE_FUNERAL_ARRANGEMENT_SUCCESS': 
  currentState = {
    ...state,
    updateFuneralData: payload,
    isFetching: false,
    error: null,
  };
  break;
  
case 'UPDATE_FUNERAL_ARRANGEMENT_FAILURE': 
  currentState = {
    ...state,
    isFetching: true,
    error: payload,
  };
break;
case 'RESET_FUNERAL_DATA': 
  currentState = {
    ...state,
    updateFuneralData: null,
    isFetching: false,
    error: null,
  };
  break;
case'GET_FUNERAL_ARRANGEMENT_REQUEST':
  currentState = {
    ...state,
    isFetching: true,
    error: null,
  };
  break;
case 'GET_FUNERAL_ARRANGEMENT_SUCCESS': 
  currentState = {
    ...state,
    funeralArrangementDetails: payload,
    isFetching: false,
    error: null,
  };
  break;
case 'GET_FUNERAL_ARRANGEMENT_FAILURE': 
  currentState = {
    ...state,
    isFetching: true,
    error: payload,
  };
break;
case 'GET_MILITARY_REQUEST':
  currentState = {
    ...state,
    isFetching: true,
  };
break; 
case 'GET_MILITARY_SUCCESS':
  currentState = {
    ...state,
    isFetching: true,
    militaryInfo: payload,
  };
break; 
case 'GET_MILITARY_FAILURE':
  currentState = {
    ...state,
    isFetching: true,
    militaryInfo: null,
    error: payload
  };
break; 
case'UPDATE_MILITARY_REQUEST':
  currentState = {
    ...state,
    isFetching: true,
    error: null,
  };
  break;
case 'UPDATE_MILITARY_SUCCESS': 
  currentState = {
    ...state,
    updateMilitaryData: payload,
    isFetching: false,
    error: null,
  };
  break;
case 'UPDATE_MILITARY_FAILURE': 
  currentState = {
    ...state,
    isFetching: false,
    error: payload,
  };
break;
case 'RESET_MILITARY_DATA': 
  currentState = {
    ...state,
    updateMilitaryData: null,
    isFetching: false,
    error: null,
  };
  break;
  case 'RESET_MY_INFO': 
  currentState = {
    ...state,
    cemeteryDetails : null,
    splInstructionsData: null,
    militaryInfo: null,
    userRelativeList: null,
    funeralArrangementDetails: null,
    demographicDetails:null,
    updatedSpecificUserData:null,
  };
  break;
  default:
  }
  return currentState;
}
