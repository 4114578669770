import axios, { defaultParams } from './axios';

export const postBaseWithoutToken =  async (url, body, params) => {
  try {
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    }
    return await axios.post(url, body.jsonData,{headers:headers, mode:'cros'});
  } catch (e) {
    if (!e || !e.response || e.response.status !== 401) throw e;
    localStorage.setItem('url', `${window.location.pathname}${window.location.search}`);
    try {
      return axios.post(url, body, { ...defaultParams(), ...params });
    } catch (err) {
      return null;
    }
  }
};
