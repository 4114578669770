import React, { useDebugValue, useEffect, useState } from "react";
import MeetingLocationMap from "../../../../../../containers/Pages/Settings/components/MeetingLocations/MeetingLocationMap";

const defaultCenter = {
  lat: 37.09024, // Latitude
  lng: -95.712891, // Longitude
};



// 13.023616245409515, 80.17637870940688

const LiveTrackingMeetingLocation = ({ membersList, meetingLocation, trackLive, LiveLocation, panToLiveLocation,selectedLiveLocation }) => {
  const [selectedLocation, setSelectedLocation] = useState({
    position: null,
    zoom: 5, // Default zoom level
    selectedObject: null
  });

  // useEffect(() => {
  //   if (trackLive === true) {
  //     setSelectedLocation(prevState => ({
  //       ...prevState,
  //       zoom: 16,
  //       // position
  //     }));
  //   } else if (trackLive === false) {
  //     setSelectedLocation(prevState => ({
  //       ...prevState,
  //       zoom: 8,
  //       // position
  //     }));
  //   }
  // }, [trackLive]);

  const [meetingList, setMeetingList] = useState([]);

  const panToLocation = (data, zoomLevel) => {
    if (data) {
      let { locationLat, locationLong } = data;
      if (locationLat && locationLong) {
        if (locationLat) {
          locationLat = parseFloat(locationLat);
        }
        if (locationLong) {
          locationLong = parseFloat(locationLong);
        }
        setSelectedLocation({
          position: { lat: locationLat, lng: locationLong },
          zoom: zoomLevel || 16, // Use the provided zoom level or default to 16
          selectedObject: data,
        });
      }
    }
  };

  const calculateZoom = (locations) => {
    if (!locations || locations.length === 0) {
      return 10; // Default zoom level if no locations are provided
    }

    // Initialize min and max values for latitude and longitude
    let minLat = locations[0].locationLat;
    let maxLat = locations[0].locationLat;
    let minLng = locations[0].locationLong;
    let maxLng = locations[0].locationLong;

    // Find the minimum and maximum latitude and longitude values
    locations.forEach((location) => {
      if (location.locationLat < minLat) {
        minLat = location.locationLat;
      }
      if (location.locationLat > maxLat) {
        maxLat = location.locationLat;
      }
      if (location.locationLong < minLng) {
        minLng = location.locationLong;
      }
      if (location.locationLong > maxLng) {
        maxLng = location.locationLong;
      }
    });

    // Calculate the horizontal and vertical distances of the bounding box
    const latDelta = maxLat - minLat;
    const lngDelta = maxLng - minLng;

    // Set a padding factor (you can adjust this based on your preference)
    const padding = 0.4;

    // Calculate the zoom level based on the bounding box size
    const zoomLat = Math.floor(Math.log2(360 * padding / latDelta));
    const zoomLng = Math.floor(Math.log2(360 * padding / lngDelta));

    // Use the smaller zoom level of the two dimensions
    return Math.min(zoomLat, zoomLng);
  };

  useEffect(() => {
    if (membersList && membersList.length > 0) {
      console.log(membersList, "membersList")
      const finalMembersListWithLocation = [];
      if (meetingLocation && meetingLocation.locationLat && meetingLocation.locationLong) {
        meetingLocation.isMeetingLocationMarker = true;
        finalMembersListWithLocation.push(meetingLocation)
      }
      membersList.map((singleMember) => {
        if (singleMember.locationLat && singleMember.locationLong) {
          finalMembersListWithLocation.push(singleMember)
        }
      })

      setMeetingList(finalMembersListWithLocation);
      if(LiveLocation){
        const zoom = calculateZoom(finalMembersListWithLocation);
        panToLocation(selectedLiveLocation, zoom);
      }
      if (finalMembersListWithLocation && !LiveLocation) {
        if (finalMembersListWithLocation.length === 1) {
          // If there's only one object, set zoom to 16
          panToLocation(finalMembersListWithLocation[0], 16);
        } else if (finalMembersListWithLocation.length > 1) {
          // If there are multiple objects, calculate zoom based on coordinates
          const zoom = calculateZoom(finalMembersListWithLocation);
          panToLocation(finalMembersListWithLocation[0], zoom);
        }
      }
    } else if (meetingLocation) {
      const finalMembersListWithLocation = [];
      if (meetingLocation && meetingLocation.locationLat && meetingLocation.locationLong) {

        finalMembersListWithLocation.push(meetingLocation)
      }
      setMeetingList(finalMembersListWithLocation);
      if (finalMembersListWithLocation) {
        panToLocation(finalMembersListWithLocation[0])
      }
    }
  }, [membersList, meetingLocation])
  return (

    <MeetingLocationMap defaultCenterlatlng={ LiveLocation? "":defaultCenter} MarkerLocationList={meetingList} selectedLocation={LiveLocation?selectedLiveLocation.position : selectedLocation.position} zoom={LiveLocation?selectedLiveLocation.zoom :selectedLocation.zoom} onSelectLocation={ LiveLocation ? panToLiveLocation: panToLocation} selectedObject={selectedLocation.selectedObject} showCustomMarker={true} />
  )
};

export default LiveTrackingMeetingLocation;
