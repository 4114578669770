import React, { useEffect, useState, } from 'react'
import { styled } from '@mui/material/styles';
import { Card, CardContent, CardActions, Typography, Button, Dialog, DialogContent, DialogActions, Divider, IconButton } from "@mui/material";
import DeleteFilledIcon from '../../../../../assets/images/filled-delete.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { getBankAccounts, deleteBankInformationAPI, getCommonUtility, getBankList, downloadBankDetails } from '../../redux/actions';
import { bindActionCreators } from "redux";
import * as formatters from '../../../../../utils/util';

const ImageHolder = styled('div')({
    textAlign: 'center',
    height: 120,
    border: '1px solid #E9E9E9',
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});

const BankAccountList = ({ getBankAccounts, bankAccounts,deleteBankInformationAPI , deleteProgess, deleteData, getBankList, getCommonUtility,banksList, utilityList, downloadBankDetails}) => {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const userDetails = window.localStorage.getItem("userInfo") ? JSON.parse(window.localStorage.getItem("userInfo")): null;

    const [removeDialog, setRemoveDialogOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [bankAccountsLists, setbankAccountsLists] = useState([]);
    const [banks, setBanks] = useState([]);
    const [utility, setUtility] = useState([]);

    useEffect(() => {
        getBankAccounts();
        getBankList();
        getCommonUtility();
    }, [])

    useEffect(() => {
        if(deleteData && deleteData.status === 200){
            getBankAccounts();
            getBankList();
            setRemoveDialogOpen(false);
        }
    }, [deleteData])

    useEffect(()=>{
        if(utilityList && utilityList.status === 200){
          const data = utilityList.data;
          const accountType = data['bank-account_type'] ? data['bank-account_type'] : [];
          setUtility(accountType)
        }
      },[utilityList])
    
      useEffect(()=>{
        if(banksList && banksList.status ===200){
          const data = banksList.data;
            setBanks(data);
        }
      },[banksList])
      useEffect(() => {
        if (bankAccounts && bankAccounts.status === 200) {
            setbankAccountsLists(bankAccounts?.data)
        } else if (bankAccounts && bankAccounts.status === 404) {
            setbankAccountsLists([])
        }
       
    }, [bankAccounts])

      const returnSingleBank = (input) =>{
        let bankDetails = {}
        banks.filter(bank=>{
            if(bank.bankCode === input.nameCode){
                bankDetails =  bank
            }
        })
        return bankDetails
      }

    return (
        <>
            {bankAccountsLists.map((item, i) => {
                const bankDetails = returnSingleBank(item)
                return (
                    <Card   className="card-box bank-card" key={'familyMemberCard_' + i}>
                        <ImageHolder   onClick={() => { 
                            window.localStorage.setItem('selectedBankAccount', JSON.stringify({bankId: item?.id}));
                            // window.localStorage.setItem('hideEdit', true);
                            navigate('/add-bank-account',{state:{hideEdit:true}}) 
                            }}>
                            <img src={bankDetails?.bankLogo_PreSignedUrl} alt={"bank logo"} width={180} style={{maxHeight:"120px"}}/>
                        </ImageHolder>
                        <CardContent className="card-content-box" sx={{ alignItems: 'start' }} 
                        onClick={() => { 
                            window.localStorage.setItem('selectedBankAccount', JSON.stringify({bankId: item?.id}));
                            // window.localStorage.setItem('hideEdit', true);
                            navigate('/add-bank-account',{state:{hideEdit:true}}) 
                            }}>
                            <div style={{ marginTop: 23, width: '100%', textAlign:"left", textTransform:"capitalize"}}>
                                <Typography className="primary-font-medium">
                                    {bankDetails?.bankName?.toLowerCase()}
                                </Typography>
                                {/* <img src={LockLight} alt="security lock" /> */}
                            </div>
                            <Typography style={{ marginTop: 10, display: 'flex' }} className="font-normal-small" color='#8D8D8D'>
                                {formatters.formatDebitCardNumber(item.cardNumber).slice(0, -4) + "XXXX"}
                            </Typography>
                            <div className="status-box" style={{ marginTop: 13, textTransform:"capitalize", paddingLeft: 10, paddingRight:10 }} >{t(utility[item.accountTypeCode])}</div>
                            <Divider style={{ borderColor: '#D8D8D8', width: '100%', marginTop: 10, marginBottom: 10 }} />
                          
                        </CardContent>
                        <CardActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0 }}>
                            {userDetails?.id === item.createdBy  && <div title={"Download PDF"} className="action-button-pdf" onClick={()=>{downloadBankDetails(item)}}></div>}
                            {userDetails?.id === item.createdBy ?  <div title={"Delete"} className="action-button-dlt" onClick={() => {setSelectedCard(item); setRemoveDialogOpen(true)}}></div> : ( <div className="inactive-button-dlt"></div>) }
                            {userDetails?.id === item.createdBy ? <div  title={"Edit"} className="action-button-edit" onClick={() => { window.localStorage.setItem('selectedBankAccount', JSON.stringify({bankId: item?.id}));
                            // window.localStorage.removeItem('hideEdit');
                            navigate('/add-bank-account',{state:{hideEdit:false}}) }}></div> :<div className="inactive-button-edit" ></div>}
                        </CardActions>
                    </Card>
                     
                )
            })}
             {removeDialog && (
                        <Dialog
                            open={removeDialog}
                            onClose={() => setRemoveDialogOpen(false)}
                            className="small-dialog dialog-box"
                            aria-labelledby="dialog-title"
                            aria-describedby="dialog-description"
                        >
                            <DialogContent className="content-dialog">
                                <img src={DeleteFilledIcon} alt='phone' style={{ marginRight: 10 }} width='118px' height='118px' />
                                <Typography className="primary-font-normal-4">Are you sure to delete?</Typography>
                            </DialogContent>
                            <Divider />
                            <DialogActions>
                                <div className="action-holder popup-button-gap" style={{ marginTop: 20, marginBottom: 0 }}>
                                    <Button variant="outlined" className="primary-font-bold-5 action-btn-no profile-cancel-btn" disabled={deleteProgess} onClick={() => setRemoveDialogOpen(false)}>No</Button>
                                    <Button variant="outlined" className="primary-font-bold-5 action-btn-yes profile-save-btn"  disabled={deleteProgess} onClick={()=>{deleteBankInformationAPI(selectedCard?.id)}}>Yes</Button>
                                </div>
                            </DialogActions>
                        </Dialog>
                    )}
            <Card  className="add-card-box bank-card" onClick={() => {window.localStorage.removeItem("selectedBankAccount");
            //  window.localStorage.removeItem('hideEdit');
             navigate('/add-bank-account',{state:{hideEdit:false}}); }}>
                <div className="add-icon">
                </div>
                <Typography style={{ textAlign: 'center', marginTop: 41 }} className="primary-font-medium">
                    Add <br /> Bank Account
                </Typography>
            </Card>
        </>

    )
}


BankAccountList.defaultProps = {
    isFetching: null,
    error: null,
    bankAccounts: [],
};

function mapStateToProps(state) {
    return {
        isFetching: state.bank.isFetching,
        error: state.bank.error,
        bankAccounts: state.bank.bankAccounts,
        deleteData: state.bank.deleteData,
        deleteProgess: state.bank.deleteProgess,
        banksList: state.bank.banksList,
        utilityList: state.bank.utilityList,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getBankAccounts,
            deleteBankInformationAPI,
            getCommonUtility,
            getBankList,
            downloadBankDetails
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BankAccountList);
