const initialState = {
  data: null,
  isFetching: false,
  error: null,
  familyMembers: [],
  createuser: [],
  edituser:[],
  editfamilyMembers:[],
  userInfoData: null,
  isUpdatingUserInfo: false,
  profilePictureData: null,
  profilePictureError: null,
  relationship:[],
  deleteUserData:null,
  profileDetails: null,
  editDisasterData:null,
  disasterList: [],
  deletedDisaster: null,
  isAdminRequest: null,
  adminResult: null,
  disasterData: null,
  disasterError: null,
  isMessageAPIFetching : false,
  createMessage: null,
  messageError: null,
  predefinedMesages: null,
  deleteMessage: null,
  updateMesssage: null,
  addMeetingLocation: null,
  errrLocation: null,
  isLocationAPIRequest: false,
  meetingLocationList: [],
  updateLocation: null,
  categoryList: []
};

export const settingReducer = (state = initialState, action) => {
  const {type, payload} = action;
  let currentState = state;
  switch(type) {
      case 'MEMBERINFO_DATA_REQUEST': 
      currentState = {
        ...state,
        isFetching: true,
        error: null,
      };
      break;
      case 'MEMBERINFO_DATA_SUCCESS': 
      currentState = {
        ...initialState,
        familyMembers: payload,
        isFetching: false,
        error: null,
      };
      break;
      case 'MEMBERINFO_DATA_FAILURE': 
      currentState = {
        ...state,
        isFetching: false,
        error: payload,
      };
      break;
      case 'USERDETAILS_UPDATE_SUCCESS': 
      currentState = {
        ...state,
        userInfoData: payload,
        isFetchingUserInfo: false,
        error: null,
      };
      break;
      case 'USERDETAILS_UPDATE_FAILURE': 
      currentState = {
        ...state,
        isFetchingUserInfo: false,
        error: payload,
      };
      break;

      case 'CREATE_USER_REQUEST': 
      currentState = {
        ...state,
        isFetching: true,
        error: null,
      };
      break;
      case 'CREATE_USER_SUCCESS': 
      currentState = {
        ...state,
        createuser: payload,
        isFetching: false,
        error: null,
      };
      break;
      case 'CREATE_USER_FAILURE': 
      currentState = {
        ...state,
        isFetching: false,
        error: payload,
      };
      break;
      case 'EDIT_USER_REQUEST': 
      currentState = {
        ...state,
        isFetching: true,
        error: null,
      };
      break;
      case 'EDIT_USER_SUCCESS': 
      currentState = {
        ...state,
        edituser: payload,
        isFetching: false,
        error: null,
      };
      break;
      case 'EDIT_USER_FAILURE': 
      currentState = {
        ...state,
        isFetching: false,
        error: payload,
      };
      break;

      case 'EDIT_DATA_REQUEST': 
      currentState = {
        ...state,
        isFetching: true,
        error: null,
      };
      break;
      case 'EDIT_DATA_SUCCESS': 
      currentState = {
        ...state,
        editfamilyMembers: payload,
        isFetching: false,
        error: null,
      };
      break;
      case 'EDIT_DATA_FAILURE': 
      currentState = {
        ...state,
        isFetching: false,
        error: payload,
      };
      break;
      case 'USER_DATA_REQUEST': 
      currentState = {
        ...state,
        updatedData: null,
        isFetchingUserInfo: true,
        error: null,
      };
      break;
      case 'USER_DATA_SUCCESS': 
      currentState = {
        ...state,
        userInfoData: payload,
        isFetchingUserInfo: false,
        error: null,
      };
      break;
      case 'USER_DATA_FAILURE': 
      currentState = {
        ...state,
        isFetchingUserInfo: false,
        error: payload,
      };
      break;
      case 'USERINFO_UPDATE_REQUEST': 
      currentState = {
        ...state,
        updatedData: null,
        isUpdatingUserInfo: true,
        error: null,
      };
      break;
      case 'USERINFO_UPDATE_SUCCESS': 
      currentState = {
        ...state,
        isUpdatingUserInfo: false,
        updatedData: payload,
      };
      break;
      case 'USERINFO_UPDATE_FAILURE': 
      currentState = {
        ...state,
        isUpdatingUserInfo: false,
        error: payload,
      };
      break;
      case 'UTILITY_IMAGE_UPDATE_REQUEST': 
      currentState = {
        ...state,
        isVerifyingPic: true,
        profilePictureError: payload,
      };
      break;
      case 'UTILITY_IMAGE_UPDATE_SUCCESS': 
      currentState = {
        ...state,
        profilePictureData: payload,
        isVerifyingPic: false,
        profilePictureError: null,
      };
      break;
      case 'UTILITY_IMAGE_UPDATE_FAILURE': 
      currentState = {
        ...state,
        profilePictureData:null,
        isVerifyingPic: false,
        profilePictureError: payload,
      };
      break;
      case 'RELATIONSHIP_DATA_SUCCESS':
        currentState = {
          ...state,
          relationship: payload
        };
        break;
        case 'SECURITY_LOCK_VERIFY_REQUEST': 
        currentState = {
          ...state,
          isVerifyingLock: true,
          error: payload,
        };
        break;
        case'DELETE_DATA_REQUEST':
        currentState={
          ...state,
          isFetching:true,
          error:null,
        };
        break;
        case 'DELETE_DATA_SUCCESS': 
        currentState = {
          ...state,
           deleteUserData: payload,
           isFetching: false,
          error: null,
       };
       break;
       case'DELETE_DATA_FAILURE':
       currentState = {
        ...state,
        isFetching: true,
        error: null,
      };
      break;
      case'ADMIN_DATA_REQUEST':
      currentState={
        ...initialState,
        isAdminRequest:true,
        error:null,
      };
      break;
      case 'DISASTER_LIST_REQUEST': 
      currentState = {
        ...state,
        isFetching: true,
        error: null,
      };
      break;
      case 'DISASTER_LIST_SUCCESS': 
      currentState = {
        ...initialState,
        disasterList: payload,
        isFetching: false,
        error: null,
      };
      break;
      case 'DISASTER_LIST_FAILURE': 
      currentState = {
        ...state,
        isFetching: false,
        error: payload,
      };
      break;
      case'DELETE_DISASTER_REQUEST':
      currentState={
        ...state,
        isFetching:true,
        error:null,
      };
      break;
      case 'DELETE_DISASTER_SUCCESS': 
      currentState = {
        ...state,
         deletedDisaster: payload,
         isFetching: false,
        error: null,
     };
     break;
     case'DELETE_DISASTER_FAILURE':
     currentState = {
      ...state,
      isFetching: true,
      error: null,
    };
    break;
    case 'ADMIN_DATA_SUCCESS': 
      currentState = {
        ...initialState,
         adminResult: payload,
         isAdminRequest: false,
        error: null,
     };
     break;
     case 'ADMIN_DATA_FAILURE': 
     currentState = {
       ...initialState,
        adminResult: null,
        isAdminRequest: false,
       error: payload,
    };
    break;
    case'CREATE_DISASTER_DATA_REQUEST':
    currentState={
      ...initialState,
      isFetching:true,
      error:null,
    };
    break;
    case 'CREATE_DISASTER_DATA_SUCCESS': 
    currentState = {
      ...state,
       disasterData: payload,
       isFetching: false,
   };
   break;
   case 'CREATE_DISASTER_DATA_FAILURE': 
   currentState = {
     ...state,
     isFetching: false,
     disasterError: payload,
  };
  break;
  case 'RESET_SETTING': 
   currentState = {
     ...initialState,
  };
  break;
  case'EDIT_DISASTER_REQUEST':
        currentState={
          ...state,
          isFetching:true,
          error:null,
        };
        break;
        case 'EDIT_DISASTER_SUCCESS': 
        currentState = {
          ...state,
          editDisasterData:payload,
           isFetching: false,
          error: null,
       };
       break;
       case'EDIT_DISASTER_FAILURE':
       currentState = {
        ...state,
        isFetching: true,
        error: null,
      };
      break;
    case 'CREATE_MESSAGE_REQUEST': 
      currentState = {
        ...state,
        isMessageAPIFetching: true,
        messageError: null,
      };
      break;
      case 'CREATE_MESSAGE_SUCCESS': 
      currentState = {
        ...state,
        createMessage: payload,
        isMessageAPIFetching: false,
        messageError: null,
      };
      break;
      case 'CREATE_MESSAGE_FAILURE': 
      currentState = {
        ...state,
        isMessageAPIFetching: false,
        createMessage: null,
        messageError: payload,
      };
      break;
      case 'RESET_API_DATA': 
      currentState = {
        ...state,
        createMessage: null,
        messageError: null,
      };
      break;
    case 'GETALL_MESSAGE_SUCCESS': 
      currentState = {
        ...state,
        predefinedMesages: payload,
        isMessageAPIFetching: false,
        createMessage: null,
        messageError: null,
        deleteMessage: null
      };
      break;
    case 'DELETE_MESSAGE_REQUEST': 
      currentState = {
        ...state,
        isMessageAPIFetching: true,
        messageError: null,
      };
      break;
  case 'DELETE_MESSAGE_SUCCESS': 
      currentState = {
        ...state,
        deleteMessage: payload,
        isMessageAPIFetching: false,
        messageError: null,
      };
      break;
  case 'UPDATE_MESSAGE_SUCCESS': 
      currentState = {
        ...state,
        updateMesssage: payload,
        isMessageAPIFetching: false,
        messageError: null,
      };
      break;
      case 'CREATE_LOCATION_REQUEST': 
      currentState = {
        ...state,
        isLocationAPIRequest: true,
        errrLocation: null,
      };
      break;
      case 'CREATE_LOCATION_SUCCESS': 
      currentState = {
        ...state,
        addMeetingLocation: payload,
        isLocationAPIRequest: false,
        errrLocation: null,
      };
      break;
      case 'CREATE_LOCATION_FAILURE': 
      currentState = {
        ...state,
        isLocationAPIRequest: false,
        errrLocation: payload,
      };
      break;
      case 'GET_LOCATION_SUCCESS': 
      currentState = {
        ...state,
        meetingLocationList: payload,
        isLocationAPIRequest: false,
        errrLocation: null,
      };
      break;

  case 'DELETE_LOCATION_REQUEST': 
      currentState = {
        ...state,
        isDeleteLocationAPIFetching: true,
        locationError: null,
      };
      break;
  case 'DELETE_LOCATION_SUCCESS': 
      currentState = {
        ...state,
        deleteLocation: payload,
        isDeleteLocationAPIFetching: false,
        locationError: null,
      };
      break;
      case 'UPDATE_LOCATION_REQUEST': 
      currentState = {
        ...state,
        isDeleteLocationAPIFetching: true,
        locationError: null,
      };
      break;
    case 'UPDATE_LOCATION_SUCCESS': 
        currentState = {
          ...state,
          updateLocation: payload,
          isDeleteLocationAPIFetching: false,
          locationError: null,
        };
        break;

      case 'RESET_LOCATION_API_DATA': 
      currentState = {
        ...state,
        locationError: null,
        deleteLocation: null,
        addMeetingLocation: null,
        errrLocation: null,
        updateLocation:null
      };
      break;
      case 'CATEGORY_DATA_SUCCESS': 
      currentState = {
        ...state,
        categoryList: payload,
      };
      break;
  default:
        
        
  }
  return currentState;
}
