const initialState = {
    error: null,
    addTrackMe: null,
    trackMeCreateResponse: null,
    trackMeUpdateResponse: null,
    trackMeStopResponse: null,
    trackMeStop: null,
    trackMeReadResponse: null,
    getLiveLocationResponse: null,
    updateLiveLocation: null,
    requestReadResponse: null,
    requestTrack: null,
    updateTrack: null,
    getLiveLocation: null,
    isFetching: false,
    data: null,
};

export const trackMeReducer = (state = initialState, action) => {
    const { type, payload } = action;
    let currentState = state;
    switch (type) {
        case 'ADD_TRACK_ME':
            currentState = {
                ...state,
                data: null,
                addTrackMe: payload
            };
            break;
        case 'TRACK_ME_REQUEST':
            currentState = {
                ...state,
                isFetching: true,
            };
            break;
        case 'TRACK_ME_SUCCESS':
            currentState = {
                ...state,
                isFetching: false,
                trackMeCreateResponse: payload
            };
            break;
        case 'TRACK_ME_FAILURE':
            currentState = {
                ...state,
                isFetching: false,
                error: payload
            };
            break;
            case 'TRACK_ME_UPDATE_REQUEST':
            currentState = {
                ...state,
                isFetching: true,
            };
            break;
        case 'TRACK_ME_UPDATE_SUCCESS':
            currentState = {
                ...state,
                isFetching: false,
                trackMeUpdateResponse: payload
            };
            break;
        case 'TRACK_ME_UPDATE_FAILURE':
            currentState = {
                ...state,
                isFetching: false,
                error: payload
            };
            break;
        case 'TRACK_STOP_REQUEST':
            currentState = {
                ...state,
                isFetching: true,
            };
            break;
        case 'TRACK_STOP_SUCCESS':
            currentState = {
                ...state,
                isFetching: false,
                trackMeStopResponse: payload
            };
            break;
        case 'TRACK_STOP_FAILURE':
            currentState = {
                ...state,
                isFetching: false,
                error: payload
            };
            break;
        case 'TRACK_ME_READ_REQUEST':
            currentState = {
                ...state,
                isFetching: true,
            };
            break;
        case 'TRACK_ME_READ_SUCCESS':
            currentState = {
                ...state,
                isFetching: false,
                trackMeReadResponse: payload
            };
            break;
        case 'TRACK_ME_READ_FAILURE':
            currentState = {
                ...state,
                isFetching: false,
                error: payload
            };
            break;
        case 'TRACK_REQUEST_READ_REQUEST':
            currentState = {
                ...state,
                isFetching: true,
            };
            break;
        case 'TRACK_REQUEST_READ_SUCCESS':
            currentState = {
                ...state,
                isFetching: false,
                requestReadResponse: payload
            };
            break;
        case 'TRACK_REQUEST_READ_FAILURE':
            currentState = {
                ...state,
                isFetching: false,
                error: payload
            };
            break;
        case 'TRACK_LIVE_REQUEST':
            currentState = {
                ...state,
                isFetching: true,
            };
            break;
        case 'TRACK_LIVE_SUCCESS':
            currentState = {
                ...state,
                isFetching: false,
                getLiveLocationResponse: payload
            };
            break;
        case 'TRACK_LIVE_FAILURE':
            currentState = {
                ...state,
                isFetching: false,
                error: payload
            };
            break;
        case 'UPDATE_LIVE_REQUEST':
            currentState = {
                ...state,
                isFetching: true,
            };
            break;
        case 'UPDATE_LIVE_SUCCESS':
            currentState = {
                ...state,
                isFetching: false,
                updateLiveLocation: payload
            };
            break;
        case 'UPDATE_LIVE_FAILURE':
            currentState = {
                ...state,
                isFetching: false,
                error: payload
            };
            break;
        case 'CREATE_TRACK_REQUEST':
            currentState = {
                ...state,
                isFetching: true,
            };
            break;
        case 'CREATE_TRACK_SUCCESS':
            currentState = {
                ...state,
                isFetching: false,
                requestTrack: payload
            };
            break;
        case 'CREATE_TRACK_FAILURE':
            currentState = {
                ...state,
                isFetching: false,
                error: payload
            };
            break;
            case 'UPDATE_TRACK_REQUEST':
                currentState = {
                    ...state,
                    isFetching: true,
                };
                break;
            case 'UPDATE_TRACK_SUCCESS':
                currentState = {
                    ...state,
                    isFetching: false,
                    updateTrack: payload
                };
                break;
            case 'UPDATE_TRACK_FAILURE':
                currentState = {
                    ...state,
                    isFetching: false,
                    error: payload
                };
                break;
            case 'GET_LIVE_REQUEST':
            currentState = {
                ...state,
                isFetching: true,
            };
            break;
        case 'GET_LIVE_SUCCESS':
            currentState = {
                ...state,
                isFetching: false,
                getLiveLocation: payload
            };
            break;
        case 'GET_LIVE_FAILURE':
            currentState = {
                ...state,
                isFetching: false,
                error: payload
            };
            break;
            case 'SINGLE_STOP_REQUEST':
                currentState = {
                    ...state,
                    isFetching: true,
                };
                break;
            case 'SINGLE_STOP_SUCCESS':
                currentState = {
                    ...state,
                    isFetching: false,
                    trackMeStop: payload
                };
                break;
            case 'SINGLE_STOP_FAILURE':
                currentState = {
                    ...state,
                    isFetching: false,
                    error: payload
                };
                break;
        default:
    }
    return currentState;
};
