import React, { useState, useEffect } from "react";
import "../../../../../../index.css";
import { Grid, TextField, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";


const PurchaseDetails = ({onBackOrCancelClick, handleNext}) => {
    const initialFormData = {
       yearBuilt:'',
       yearOfPurchase:'',
       modelNumber:'',
       purchasePrice:'',
       location:''
    };
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState(initialFormData);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const newErrors = {};

        if (formData.yearBuilt.trim() === '') {
          newErrors.yearBuilt = 'Year Built Price is required.';
        }
        if (formData.yearOfPurchase.trim() === '') {
          newErrors.yearOfPurchase = 'Year Of Purchase is required.';
        }
        if (formData.modelNumber.trim() === '') {
          newErrors.modelNumber = 'Model Number is required.';
        }
        if (formData.purchasePrice.trim() === '') {
          newErrors.purchasePrice = 'Purchase Price is required.';
        }
        if (formData.location.trim() === '') {
          newErrors.location = 'Location is required.';
        }
    
        if (Object.keys(newErrors).length === 0) {
          console.log(formData);
          setErrors({});
          handleNext();
        } else {
          setErrors(newErrors);
          console.log('Validation errors:', newErrors);
        }
      };
  return (
    <Grid container spacing={2} style={{margin:0, padding: 32, paddingTop: 0, width: '100%', justifyContent: 'start'}}>
        <Grid item xs={12} md={12} sx={{padding: 0}}>
            <div style={{padding: 20}}>
             <div className="flex-boxes" style={{display:'flex',justifyContent:'space-between'}}>
                 <div className="full-width">
                    <Typography className='font-normal-small error-message'>{errors.yearBuilt}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Year Built</Typography>
                    <select id="yearBuilt" name="yearBuilt" className={`profile-input ${errors.yearBuilt ? 'err-input' : ''}`} aria-label='yearBuilt' onChange={handleInputChange}  value={formData.yearBuilt}>
                    <option value="" ></option>
                        <option value="2023"> 2023 </option>
                        <option value="2022"> 2022 </option>
                        <option value="2021"> 2021 </option>
                        <option value="2020"> 2020 </option>
                        <option value="2019"> 2019 </option>
                        <option value="2018"> 2018 </option>
                        <option value="2017"> 2017 </option>
                      </select>
                 </div>
                 <div className="full-width">
                        <Typography className='font-normal-small error-message'>{errors.yearOfPurchase}</Typography>
                        <Typography className='font-normal-small profile-input-label'>Year of Purchase </Typography>
                        <select name="yearOfPurchase" id="yearOfPurchase" className={`profile-input ${errors.yearOfPurchase ? 'err-input' : ''}`}  aria-label='yearOfPurchase'  onChange={handleInputChange} value={formData.yearOfPurchase}>
                        <option value="" > </option>
                        <option value="2023"> 2023 </option>
                        <option value="2022"> 2022 </option>
                        <option value="2021"> 2021 </option>
                        <option value="2020"> 2020 </option>
                        <option value="2019"> 2019 </option>
                        <option value="2018"> 2018 </option>
                        <option value="2017"> 2017 </option>
                      </select>
                 </div>
             </div>
             <div className="flex-boxes" style={{display:'flex',justifyContent:'space-between'}}>
                 <div className="full-width">
                    <Typography className='font-normal-small error-message'>{errors.modelNumber}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Model Number</Typography>
                    <input id="modelNumber" name="modelNumber" className={`profile-input ${errors.modelNumber ? 'err-input' : ''}`} aria-label='modelNumber' onChange={handleInputChange}  value={formData.modelNumber}/>
                 </div>
                 <div className="full-width">
                        <Typography className='font-normal-small error-message'>{errors.purchasePrice}</Typography>
                        <Typography className='font-normal-small profile-input-label'>Purchase Price </Typography>
                        <input  name="purchasePrice" id="purchasePrice" className={`profile-input ${errors.purchasePrice ? 'err-input' : ''}`}  aria-label='purchasePrice'  onChange={handleInputChange} value={formData.purchasePrice}/>
                 </div>
             </div>
                <div  className="flex-boxes" style={{marginLeft: 0, display:'flex'}}> 
                   
                    <div style={{width:'500px'}}>
                        <Typography className='font-normal-small error-message'>{errors.location}</Typography>
                        <Typography className='font-normal-small profile-input-label'>Location</Typography>
                        <input  name="location" id="location" className={`profile-input ${errors.location ? 'err-input' : ''}`}  aria-label='location'  onChange={handleInputChange} value={formData.location}/>
                    </div>
                    
                </div>
            </div>
      </Grid>
      <div className="action-holder" style={{marginTop: 0, marginBottom: 50}}>
        <Button variant="outlined"  className="primary-font-bold-6 action-btn profile-cancel-btn" onClick={onBackOrCancelClick}>Back</Button>
        <Button variant="outlined" className="primary-font-bold-6 action-btn profile-save-btn"  onClick={handleSubmit}>Save</Button>
      </div>
    </Grid>
  );
};

export default PurchaseDetails;