import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Typography,
  Grid,
  Checkbox,
  Card,
  DialogContent,
  Dialog
} from "@mui/material";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { UserInfoDetails } from "../../../../../../utils/userContext";
import { sendRequestAPI,updateRequestAPI } from "../../../redux/actions";


const StyledCard = styled(Card)({
  display: "flex",
  justifyContent: "space-between",
  alignContent: "center",
  width: "100%",
  flexDirection: "row",
  borderRadius: "10px",
  height: "60px",
  margin: "10px",
  border: "1px solid #D8D8D8",
  "&:hover": {
    border: "1px solid #FF3535",
    boxShadow: "0px 2px 8px #00000033",
  },
});




const RequestMessage = ({ onBackOrCancelClick, handleNext, addTrackMe, trackData, sendRequestAPI,updateRequestAPI, requestTrack,updateTrack, isUpdate }) => {
  const dispatch = useDispatch()
  const [customMessage, setCustomMessage] = useState("")
  const { userObject } = useContext(UserInfoDetails)
  const [message, setMessage] = useState("")
  const [successDialouge, setSuccessDialouge] = useState(false)


  const handleMessageNext = () => {
    const newdata = { ...addTrackMe };
    newdata.messageCustom = customMessage;
    dispatch({
      type: "ADD_TRACK_ME",
      payload: newdata,
    });
    let payload = {
      "accountId": userObject.accountId,
      "userId": userObject.id,
      "members": newdata.membersList?.map(obj => obj.id).join(','),
      "messages": "",
      "messageCustom": customMessage ? customMessage : "",
      "deviceType": "system",
      "createdBy": userObject.id
    }
    if(isUpdate){
      payload["id"] = trackData?.id
      payload["updatedBy"] = userObject.id
      updateRequestAPI(payload)
    }else{
      sendRequestAPI(payload)

    }

    console.log(payload);

  };

  useEffect(() => {
    setCustomMessage(addTrackMe?.message)
  }, [addTrackMe])

  useEffect(() => {
    setCustomMessage(trackData?.messageCustom)
  }, [trackData])

  useEffect(() => {
    if (requestTrack && requestTrack.status === 200) {
      setMessage("Request send successfully");
      setSuccessDialouge(true);
      dispatch({
        type: "CREATE_TRACK_SUCCESS",
        payload: {}
      })
      setTimeout(() => {
        setSuccessDialouge(false);
        setMessage("");
        handleNext()
      }, 2000);
    } else {

    }
  }, [requestTrack])
  
  useEffect(() => {
    if (updateTrack && updateTrack.status === 200) {
      setMessage("Request updated successfully");
      setSuccessDialouge(true);
      dispatch({
        type: "UPDATE_TRACK_SUCCESS",
        payload: {}
      })
      setTimeout(() => {
        setSuccessDialouge(false);
        setMessage("");
        handleNext()
      }, 2000);
    } else {

    }
  }, [updateTrack])



  return (
    <Grid container spacing={2} style={{ width: "100%", margin: 0 }}>
      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{ justifyContent: "center", alignItems: "center" }}
      >
        {/* <div>
              <Typography className="primary-font-bold-5" sx={{my:2}}>
                  Type in your message
                </Typography>
              </div> */}
        <textarea
          className="font-family-default"
          style={{
            width: "460px",
            height: "160px",
            background: " #FFFFFF 0% 0 % no - repeat padding- box",
            boxShadow: "0px 3px 6px #00000029",
            border: "1px solid #ddd",
            borderRadius: "10px",
            opacity: 1,
            outline: "none",
            // border: "none",
            padding: "18px 16px",
            margin: "20px 0",
            resize: "none"
          }}
          value={customMessage}
          onChange={(e) => { setCustomMessage(e.target.value) }}
          maxLength={500}
          placeholder="Write message here..."></textarea>

        <div className="action-holder track-me-btn">
          <Button
            variant="outlined"
            className="primary-font-bold-6 action-btn profile-cancel-btn"
            onClick={onBackOrCancelClick}
          > Back </Button>
          <Button
              variant="outlined"
              className="primary-font-bold-6 action-btn profile-save-btn"
              onClick={handleMessageNext}
            > {!isUpdate ? "Send Request" : "Update Request"} </Button>
        </div>
        {message && (
          <Dialog
            open={successDialouge}
            onClose={() => {
              setSuccessDialouge(false);
            }}
            className="small-dialog dialog-box"
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
          >
            <DialogContent className="content-dialog">
              <Typography className="primary-font-normal-4">
                {message}
              </Typography>
            </DialogContent>
          </Dialog>
        )}
      </Grid>
    </Grid>
  )
}

function mapStateToProps(state) {
  return {
    addTrackMe: state.trackMe.addTrackMe,
    requestTrack: state.trackMe.requestTrack,
    updateTrack: state.trackMe.updateTrack
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      sendRequestAPI,
      updateRequestAPI
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestMessage);
