import Avatar1 from '../../assets/icons/avatar/avatar1.svg';
import Avatar2 from '../../assets/icons/avatar/avatar2.svg';
import Avatar3 from '../../assets/icons/avatar/avatar3.svg';
import Avatar4 from '../../assets/icons/avatar/avatar4.svg';
import Avatar5 from '../../assets/icons/avatar/avatar5.svg';
import Avatar6 from '../../assets/icons/avatar/avatar6.svg';
import Avatar7 from '../../assets/icons/avatar/avatar7.svg';
import Avatar8 from '../../assets/icons/avatar/avatar8.svg';
import Avatar9 from '../../assets/icons/avatar/avatar9.svg';
import Avatar10 from '../../assets/icons/avatar/avatar10.svg';
import Avatar11 from '../../assets/icons/avatar/avatar11.svg';
import Avatar12 from '../../assets/icons/avatar/avatar12.svg';
import Avatar13 from '../../assets/icons/avatar/avatar13.svg';
import Avatar14 from '../../assets/icons/avatar/avatar14.svg';
import Avatar15 from '../../assets/icons/avatar/avatar15.svg';
import Avatar16 from '../../assets/icons/avatar/avatar16.svg';
import Avatar17 from '../../assets/icons/avatar/avatar17.svg';
import Avatar18 from '../../assets/icons/avatar/avatar18.svg';
import Avatar19 from '../../assets/icons/avatar/avatar19.svg';
import Avatar20 from '../../assets/icons/avatar/avatar20.svg';
import Avatar21 from '../../assets/icons/avatar/avatar21.svg';
import Avatar22 from '../../assets/icons/avatar/avatar22.svg';
import Avatar23 from '../../assets/icons/avatar/avatar23.svg';
import Avatar24 from '../../assets/icons/avatar/avatar24.svg';
import Avatar25 from '../../assets/icons/avatar/avatar25.svg';
import Avatar26 from '../../assets/icons/avatar/avatar26.svg';
import Avatar27 from '../../assets/icons/avatar/avatar27.svg';
import Avatar28 from '../../assets/icons/avatar/avatar28.svg';
import Avatar29 from '../../assets/icons/avatar/avatar29.svg';
import Avatar30 from '../../assets/icons/avatar/avatar30.svg';

const AvatarIcon = [
    {
      imgSrc: Avatar1,
      imgAlt: 'avatar-1',
      imageSrcKey:'Avatar/avatar1.png'
    },
    {
      imgSrc: Avatar2,
      imgAlt: 'avatar-2',
      imageSrcKey:'Avatar/avatar2.png'
    },
    {
      imgSrc: Avatar3,
      imgAlt: 'avatar-3',
      imageSrcKey:'Avatar/avatar3.png'
    },
    {
      imgSrc: Avatar4,
      imgAlt: 'avatar-4',
      imageSrcKey:'Avatar/avatar4.png'
    },
    {
      imgSrc: Avatar5,
      imgAlt: 'avatar-5',
      imageSrcKey:'Avatar/avatar5.png'
    },
    {
      imgSrc: Avatar6,
      imgAlt: 'avatar-6',
      imageSrcKey:'Avatar/avatar6.png'
    },
    {
      imgSrc: Avatar7,
      imgAlt: 'avatar-7',
      imageSrcKey:'Avatar/avatar7.png'
    },
    {
      imgSrc: Avatar8,
      imgAlt: 'avatar-8',
      imageSrcKey:'Avatar/avatar8.png'
    },
    {
      imgSrc: Avatar9,
      imgAlt: 'avatar-9',
      imageSrcKey:'Avatar/avatar9.png'
    },
    {
      imgSrc: Avatar10,
      imgAlt: 'avatar-10',
      imageSrcKey:'Avatar/avatar10.png'
    },
    {
      imgSrc: Avatar11,
      imgAlt: 'avatar-11',
      imageSrcKey:'Avatar/avatar11.png'
    },
    {
      imgSrc: Avatar12,
      imgAlt: 'avatar-12',
      imageSrcKey:'Avatar/avatar12.png'
    },
    {
      imgSrc: Avatar13,
      imgAlt: 'avatar-13',
      imageSrcKey:'Avatar/avatar13.png'
    },
    {
      imgSrc: Avatar14,
      imgAlt: 'avatar-14',
      imageSrcKey:'Avatar/avatar14.png'
    },
    {
      imgSrc: Avatar15,
      imgAlt: 'avatar-15',
      imageSrcKey:'Avatar/avatar15.png'
    },
    {
      imgSrc: Avatar16,
      imgAlt: 'avatar-16',
      imageSrcKey:'Avatar/avatar16.png'
    },
    {
      imgSrc: Avatar17,
      imgAlt: 'avatar-17',
      imageSrcKey:'Avatar/avatar17.png'
    },
    {
      imgSrc: Avatar18,
      imgAlt: 'avatar-18',
      imageSrcKey:'Avatar/avatar18.png'
    },
    {
      imgSrc: Avatar19,
      imgAlt: 'avatar-19',
      imageSrcKey:'Avatar/avatar19.png'
    },
    {
      imgSrc: Avatar20,
      imgAlt: 'avatar-20',
      imageSrcKey:'Avatar/avatar20.png'
    },
    {
      imgSrc: Avatar21,
      imgAlt: 'avatar-21',
      imageSrcKey:'Avatar/avatar21.png'
    },
    {
      imgSrc: Avatar22,
      imgAlt: 'avatar-22',
      imageSrcKey:'Avatar/avatar22.png'
    },
    {
      imgSrc: Avatar23,
      imgAlt: 'avatar-23',
      imageSrcKey:'Avatar/avatar23.png'
    },
    {
      imgSrc: Avatar24,
      imgAlt: 'avatar-24',
      imageSrcKey:'Avatar/avatar24.png'
    },
    {
      imgSrc: Avatar25,
      imgAlt: 'avatar-25',
      imageSrcKey:'Avatar/avatar25.png'
    },
    {
      imgSrc: Avatar26,
      imgAlt: 'avatar-26',
      imageSrcKey:'Avatar/avatar26.png'
    },
    {
      imgSrc: Avatar27,
      imgAlt: 'avatar-27',
      imageSrcKey:'Avatar/avatar27.png'
    },
    {
      imgSrc: Avatar28,
      imgAlt: 'avatar-28',
      imageSrcKey:'Avatar/avatar28.png'
    },
    {
      imgSrc: Avatar29,
      imgAlt: 'avatar-29',
      imageSrcKey:'Avatar/avatar29.png'
    },
    {
      imgSrc: Avatar30,
      imgAlt: 'avatar-30',
      imageSrcKey:'Avatar/avatar30.png'
    }
  ];
  export default AvatarIcon;
