import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import { Grid, Card, CardContent, CardActions, Typography, Button, Dialog, DialogContent, DialogActions, Divider, IconButton } from "@mui/material";
import DeleteFilledIcon from '../../../../../assets/images/filled-delete.svg';
import Card1 from '../../../../../assets/images/my-info/id-driving-license.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import UserAvatar from '../../../../../assets/icons/user-avatar.svg';
import { Box } from '@mui/system';
import AccountDetailsModal from './AccountDetailsModal';
import icon1 from "../../../../../assets/icons/socialMedia-icons/facebook-icon.svg";
import icon2 from "../../../../../assets/icons/socialMedia-icons/youtube-icon.svg";
import icon3 from "../../../../../assets/icons/socialMedia-icons/linkedin-icon.svg";
import icon4 from "../../../../../assets/icons/socialMedia-icons/twitter-x-icon.svg";
import icon5 from "../../../../../assets/icons/socialMedia-icons/google-icon.svg";
import AccountDetailsEditModal from './AccountDetailsEditModal';




const MySocialMediaList = () => {
    const { t } = useTranslation('common');
    const navigate = useNavigate();

    const [removeDialog, setRemoveDialogOpen] = useState(false);
    const [AccountDetailsopen, setAccountDetailsopen] = useState(false);
    const [AccountDetailsEditopen, setAccountDetailsEditopen] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [iconvalue, seticonvalue] = useState("");
    const handleClose = () => {
        setAccountDetailsopen(false)
    }
    const handleCloseEdit = () => {
        setAccountDetailsEditopen(false)
    }

    const socialMediaDetails = [{
        imageSrc: icon1,
        imageAlt: 'socialMedia',
        label:"FaceBook",
    },
    {
        imageSrc: icon2,
        imageAlt: 'socialMedia',
        label:"YouTube",
    },
    {
        imageSrc: icon3,
        imageAlt: 'socialMedia',
        label:"LinkedIn",
    },
    {
        imageSrc: icon4,
        imageAlt: 'socialMedia',
        label:"Twitter",
    },
    {
        imageSrc: icon5,
        imageAlt: 'socialMedia',
        label:"Google",
    }
    ]

    return (
        <>
            <Grid container >
                {socialMediaDetails.map((item, i) => {

                    return (
                        <Grid item xs={4} lg={2}>
                            <Card className='cardSocial-box' key={'familyMemberCard_' + i} >
                                <Box sx={{
                                }}>
                                    <img src={item.imageSrc} alt="socialMedia" height="70px" width='70px' />
                            
                                </Box>
                                <CardContent className="card-content-box" sx={{ alignItems: 'start' }}>

                                    <Divider style={{ borderColor: '#D8D8D8', width: '100%', marginTop: 10, marginBottom: 10 }} />
                                   
                                </CardContent>
                                <CardActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0 }}>
                                    <div className="action-button-dlt" onClick={() => {  setRemoveDialogOpen(true) }}></div>
                                    <div className="action-button-edit" onClick={() => {setAccountDetailsEditopen(!false); console.log(item.label,"1111111111111111111111"); seticonvalue(item.label)} }></div>
                                </CardActions>
                            </Card>
                        </Grid>
                    )

                })}
                 {removeDialog && (
                                        <Dialog
                                            open={removeDialog}
                                            onClose={() => setRemoveDialogOpen(false)}
                                            className="small-dialog dialog-box"
                                            aria-labelledby="dialog-title"
                                            aria-describedby="dialog-description"
                                        >
                                            <DialogContent className="content-dialog">
                                                <img src={DeleteFilledIcon} alt='phone' style={{ marginRight: 10 }} width='118px' height='118px' />
                                                <Typography className="primary-font-normal-4">Are you sure want to delete?</Typography>
                                            </DialogContent>
                                            <Divider />
                                            <DialogActions>
                                                <div className="action-holder" style={{ marginTop: 20, marginBottom: 0, gap: 40 }}>
                                                    <Button variant="outlined" className="primary-font-bold-5 action-btn-no profile-cancel-btn" onClick={() => setRemoveDialogOpen(false)}>No</Button>
                                                    <Button variant="outlined" className="primary-font-bold-5 action-btn-yes profile-save-btn" >Yes</Button>
                                                </div>
                                            </DialogActions>
                                        </Dialog>
                                    )}
                <Grid lg={2}>
                    <Card className="add-socialicon-box" onClick={() => { setAccountDetailsopen(!false) }}>
                        <div className="add-icon">
                        </div>
                        <Typography sx={{ textAlign: 'center',mt:"17px" }} className="primary-font-medium">
                            Add <br />  Social Media
                        </Typography>
                    </Card>
                </Grid>

                < AccountDetailsModal open={AccountDetailsopen} close={handleClose} />
                {console.log(iconvalue,"1234567890")}
                <AccountDetailsEditModal open={AccountDetailsEditopen} close={handleCloseEdit}  label={iconvalue}/>
            </Grid>

        </>

    )
}

export default MySocialMediaList;