import React, { useState, useEffect, useContext } from 'react';
import {Dialog,DialogContent,DialogTitle} from '@mui/material';
import Slide from '@mui/material/Slide';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from './CheckoutForm';
import constants from '../../../../config/constants';
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { createPaymentIntent } from '../redux/actions';
import { UserInfoDetails } from '../../../../utils/userContext';
import BillingInformation from './BillingInformation';
import PaymentSuccess from './PaymentSuccess';
import PaymentFailed from './PaymentFailed';
import { useNavigate } from "react-router-dom";
import { homeScreenAPI } from '../../Login/redux/actions';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const PaymentDialog = ({
    isOpen,
    handleClose,
    chosenPlanDetails,
    createPaymentIntent,
    paymentIntentData,
    homeScreenAPI,
    homeAPI
}) => {
    const [open, setOpen] = useState(false);
    const [clientSecret, setClientSecret] = useState(null);
    const [stripePromise, setStripePromise] = useState(null);
    // const { userObject } = useContext(UserInfoDetails)
    const [currentStep, setCurrentStep] = useState(0);
    const [customerId, setCustomerId] = useState(null);
    const [paymentIntentId, setPaymentIntentId] = useState(null);
    const navigate = useNavigate();

    const userObject = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;



    useEffect(() => {
        setStripePromise(loadStripe(constants.STRIPE_PUBLIC_KEY));
    }, []);

    useEffect(() => {
        setClientSecret(null);
        setCustomerId(null);
        setPaymentIntentId(null);
        const apiReq = {
            "accountId": userObject?.accountId,
            "userId": userObject?.id,
            "amount": chosenPlanDetails?.amount,
            "currency": "usd",
            "description": "myredfolder " + chosenPlanDetails.transactionPlanType + " plan",
            "transactionPlanType": chosenPlanDetails?.transactionPlanType,
            "transactionPlanDuration": chosenPlanDetails?.transactionPlanDuration
        }
        createPaymentIntent(apiReq)
    }, [chosenPlanDetails]);

    useEffect(() => {
        if (paymentIntentData && paymentIntentData?.status === 200) {
            if (paymentIntentData?.data) {
                setClientSecret(paymentIntentData?.data?.clientSecretKey);
                setCustomerId(paymentIntentData?.data?.customerId);
                setPaymentIntentId(paymentIntentData?.data?.paymentIntentId);
            }
        }

    }, [paymentIntentData])




    const appearance = {
        theme: 'stripe',
        variables: {
            colorText: '#111',
            colorDanger: '#df1b41',
            borderRadius: '4px',
            fontSizeBase: '14px'
        },
    };



    const handleCloseDialog = () => {
        handleClose()
    }

    const handleNext = (flag) => {
        if (flag === 'success') {
            setCurrentStep(2)
            // navigate("/home");
          
             setTimeout(() => {
                navigate("/home");
                window.location.reload();
            }, 3000);
        }
        else if (flag === 'billing'){
            setCurrentStep(1)
        }
         else if (flag === 'failure') {
            setCurrentStep(3)
        }
        else {
            setCurrentStep((prevStep) => prevStep + 1);
        }
    };

    // useEffect(() => {
    //     if (homeAPI && homeAPI?.data?.plan) {
    //         window.localStorage.setItem("isSubscribed", homeAPI?.data?.plan?.isSubscribed);
    //         if (homeAPI?.data?.plan?.id !== null) {
    //             navigate("/home");
    //             window.location.reload();
    //         } else {
    //             navigate("/pricing");
    //         }

    //     }
    // })


    return (
        <>
            <Dialog
                open={isOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseDialog}
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{
                    sx: {
                        width: 400,
                        maxHeight: 'calc(100% - 36px)'
                    }
                }}
            >
                  {currentStep === 0 && <DialogTitle>{"Subscribe Now"}</DialogTitle>}
                  {currentStep === 1 && <DialogTitle>{"Billing Information"}</DialogTitle>}
                <DialogContent>
                    {currentStep === 0 && (<>
                        {clientSecret && stripePromise && (
                            <Elements options={{ clientSecret, appearance }} stripe={stripePromise}>
                                <CheckoutForm planDetails={chosenPlanDetails} handleNext={handleNext} customerId={customerId} paymentIntentId={paymentIntentId}/>
                            </Elements>
                        )}
                    </>)}
                    {currentStep === 1 && (<>
                            <BillingInformation handleNext={handleNext} />
                    </>)}
                    {currentStep === 2 && (<>
                       <PaymentSuccess />
                    </>)}
                    {currentStep === 3 && (<>
                       <PaymentFailed text={"Payment"} accessBlock = {false}/>
                    </>)}

                   

                </DialogContent>

            </Dialog>
        </>
    );
}

function mapStateToProps(state) {
    return {
        paymentIntentData: state.profile.paymentIntentData,
        homeAPI: state.login.homeAPI,

    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        createPaymentIntent,
        homeScreenAPI
    }, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDialog)