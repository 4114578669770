import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Button,
  Dialog,
  DialogContent
} from "@mui/material";
import { styled } from "@mui/material/styles";
import title_red_img from "../../../../../assets/images/settings/system-branding/title_red.svg"
import title_red_White_img from "../../../../../assets/images/settings/system-branding/title_red_white.svg"
import title_gray_img from "../../../../../assets/images/settings/system-branding/title_grey.svg"
// import ErrorIcon from "../../../../../assets/icons/error-icon.svg";
import Radio from "@mui/material/Radio";
import { connect } from "react-redux";
import { updateEmergencyTheme } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import "../SystemBranding/styles/SystemBranding.css";

const Image = styled("img")({
  // width: "350px",
  maxHeight: "100%"
});

const ImageOverlay = styled("div")({
  position: "relative",
  overflow: "hidden",
  border: "1px solid #fff",
});

const RadioOverlay = styled("div")({
  position: "absolute",
  top: "-4px",
  left: "-9px",
  opacity: 1,
  width: "100%",
  boxSizing: "border-box",
  padding: "8px 16px",
  color: "#fff",
});

const ButtonThemeSelector = ({ updateEmergencyTheme }) => {
  const [selectedValue, setSelectedValue] = useState();
  const [message,setMessage] = useState("")
  const [successDialouge,setSuccessDialuge] = useState(false)
const navigate = useNavigate();
 //Dynamic Image & Label
  const options = [
    { value: 'Code_A', label: 'Title Red', image: title_red_img },
    { value: 'Code_B', label: 'Title Red White', image: title_red_White_img },
    { value: 'Code_C', label: 'Title Gray', image: title_gray_img }
  ];
  const userDetails = window.localStorage.getItem("userInfo");
  const userDetailsObject = JSON.parse(userDetails);

  useEffect(() => {
    const emergencyTileCode = userDetailsObject?.accountPreferences?.emergencyTile;

    const selectedOption = options.find(option => option.value === emergencyTileCode);
    if (selectedOption) {
      setSelectedValue(selectedOption.value)
    } else {
      console.log("Option not found for the received code");
      setSelectedValue('Code_A')
    }
  }, [userDetails])


  // Radio Button click
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  // Save Button click
  const handleSaveButton = async () => {
    console.log(selectedValue);
    let reqPayload = {
      emergencyTile: selectedValue
    };
    if (selectedValue) {
      try {
        const response = await updateEmergencyTheme(reqPayload);
        // console.log('Response:', response);
        if(response.payload.status === 200){
          setMessage("Emergency Tile updated successfully")
          setSuccessDialuge(true);
          const userDetailsObject = JSON.parse(userDetails);
          const userObject = {
            ...userDetailsObject,
            accountPreferences: {
                ...userDetailsObject.accountPreferences,
                emergencyTile: selectedValue
            }
        };
          window.localStorage.setItem("userInfo", JSON.stringify(userObject))
          setTimeout(() => {
            setSuccessDialuge(false);
            setMessage("");
            navigate('/system-branding')
          }, 2000);
        }
      } catch (error) {
        console.error('Error:', error);
        setMessage("Emergency Tile failed to update. Please try again !!!")
        setSuccessDialuge(true);
        setTimeout(() => {
          setSuccessDialuge(false);
          setMessage("");
        }, 2000);
      }
    }else{
      console.log("Tile Not Selected");

    }
  };

  return (
    <Grid container spacing={2} style={{ width: "100%", margin: 0 }}>
      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        className="box-container"
        sx={{ justifyContent: "center", alignItems: "center" }}
      >
        {/* Heading */}
        <div
          style={{ width: "100%", marginBottom: "10px" }}
          className="box-header"
        >
          <Typography className="primary-font-bold-2-heading">
          Emergency Tile
          </Typography>
        </div>

        {/* Sub_Heading */}
        <Typography className="primary-font-bold-2-heading default-font sub-text" >
        Select Tile
        </Typography>

    
        {/* Title-Card-Button */}
        <Grid 
          item
          container
          rowGap={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            padding: "10px 0px",
            // gap: 20
          }}
        >
          {options.map(option => (
            <ImageOverlay key={option.value}>
              <Image src={option.image} alt={option.label}  className="emergency-img" />
              <RadioOverlay>
                <Radio
                  checked={selectedValue === option.value}
                  onChange={handleChange}
                  value={option.value}
                  name="radio-buttons"
                 
                  inputProps={{ 'aria-label': option.label }}
                  sx={{
                    "&, &.Mui-checked": {
                      color: "red",
                      background: "#fff"
                    },
                    '& .MuiSvgIcon-root': {
                      fontSize: 38,
                    },
                    '&:hover': {
                      backgroundColor: '#fff'
                    },
                    justifyContent: "flex-start",
                    display: "no",
                    padding: "0px",
                    borderColor: "#FFFFFF",
                  }}
                />
              </RadioOverlay>
            </ImageOverlay>
          ))}
          
        </Grid>

        {/* Save Button */}
        <div
          className="action-holder emergency-save-btn"
          style={{ marginTop: 40, marginBottom: 50 }}
        >
          <Button
            variant="outlined"
            className="primary-font-bold-6 action-btn profile-save-btn"
            onClick={handleSaveButton}
          >
            Save
          </Button>
        </div>
      </Grid>
      
      {message && (
         <Dialog
         open={successDialouge}
         onClose={() => {
           setSuccessDialuge(false);
         }}
         className="small-dialog dialog-box branding-box"
         aria-labelledby="dialog-title"
         aria-describedby="dialog-description"
       >
         <DialogContent className="content-dialog">
           <Typography className="primary-font-normal-4">
             {message}
           </Typography>
         </DialogContent>
       </Dialog>
      )}
   
    </Grid>
  );
}

const mapDispatchToProps = (dispatch) => ({
  updateEmergencyTheme: (payload) => dispatch(updateEmergencyTheme(payload))
});

export default connect(null, mapDispatchToProps)(ButtonThemeSelector);
