import { Auth } from 'aws-amplify';
import {api} from '../../../../utils/apiClient';
export const signInAccount = (payload) => async(dispatch) => {
    try{
        dispatch({
            type: 'LOGIN_DATA_REQUEST',
            payload: null
        });
        const signInResponse = await Auth.signIn(payload);
        return dispatch({type: 'LOGIN_DATA_SUCCESS', payload: signInResponse});

    } catch(e){
        dispatch({
            type: 'LOGIN_DATA_FAILURE',
            payload: e
        });
    }
}

export const loginWithCognitoToken = (payload) => async(dispatch) => {
    const url = 'v1/user/login'
     dispatch({
        type: 'USERINFO_DATA_REQUEST',
        payload: null
    });
    if(!payload?.deviceUniqueId){
        payload.deviceUniqueId = ""
    }
    try{
        const response = await api.postApi(url,payload);
        console.log("payload",payload)
        if(payload?.deviceUniqueId){
            console.log("i am storing   ")
            localStorage.setItem('deviceUniqueId', payload?.deviceUniqueId)
        }
        const userData = response.data.data;
        if(userData && userData?.id){
        const profileAPI = await readProfile(userData)
        if(response.data && response.data.status === 200){
            const newObject = {...response.data?.data, ...profileAPI}
            response.data.data = newObject
        }
        return dispatch({
            type: 'USERINFO_DATA_SUCCESS',
            payload: response.data
        });
    }else{
        window.localStorage.clear()
        return dispatch({
            type: 'USERINFO_DATA_SUCCESS',
            payload: response.data
        });
    }
    }catch(e){
        window.localStorage.clear()
        console.log('USERINFO_DATA_FAILURE',e)
    }
}

export const loginWithSocialMedia = (payload) => async(dispatch) => {
    const url = 'v2/user/login/socialSignIn';
    dispatch({
        type: 'USERINFO_DATA_REQUEST',
        payload: null
    });
    try{
        const response = await api.postApi(url,payload);
        if(response.data && response.data.status===200){
            const userData = response.data.data;
            if(userData && userData?.id){
                const profileAPI = await readProfile(userData)
                if(response.data && response.data.status === 200){
                   const newObject = {...response.data?.data, ...profileAPI}
                   response.data.data = newObject
               }
               return dispatch({
                   type: 'USERINFO_DATA_SUCCESS',
                   payload: response.data
               });
            }else{
                window.localStorage.clear()
                return dispatch({
                    type: 'USERINFO_DATA_SUCCESS',
                    payload: response.data
                });
            }
           
        }else{
            window.localStorage.clear()
            return dispatch({
                type: 'USERINFO_DATA_SUCCESS',
                payload: response.data
            });
        }
        
     
    }catch(e){
        console.log('USERINFO_DATA_FAILURE',e)
    }
}
export const signOutAccount = (payload) => async(dispatch) => {
    try{
        dispatch({
            type: 'LOGOUT_DATA_REQUEST',
            payload: null
        });
        const signoutResponse = await Auth.signOut();
        if(signoutResponse){
            localStorage.clear();
        }
        return dispatch({type: 'LOGOUT_DATA_SUCCESS', payload: {signOutAccount:{
            userSignOut: true
        }}});

    } catch(e){
        dispatch({
            type: 'LOGOUT_DATA_FAILURE',
            payload: e
        });
        throw e;
    }
}

export const resetLogin = ()=> async(dispatch) => {
    dispatch({
        type: 'RESET_LOGIN_DATA',
        payload: true
    });
    dispatch({type:"RESET_HOME_DATA", payload: true});
}

export const readProfile = async(payload) => {
    const url = `v1/user/profile/read/${payload.id}`
   try{
       const response = await api.getApi(url,payload);
        const userData = {
            image_PreSignedUrl : null
        }
        if(response.data && response.data.status ===200){
            userData.image_PreSignedUrl = response.data.data?.image_PreSignedUrl
        }
        return userData
   }catch(e){
       console.log('USERINFO_DATA_FAILURE',e)
   }
}


export const homeScreenAPI = (payload) => async(dispatch) => {
    const userDetails = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')): null;
    const url = `v1/common/homeScreen/read${userDetails?.id  ? `/${userDetails?.id}`: ''}`;
   try{
       dispatch({
           type: 'HOME_API_REQUEST',
           payload: null
       })
 
       const response =await api.getApi(url,payload);
       return dispatch({
           type: 'HOME_API_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'HOME_API_FAILURE',
           payload: e
       });
   }
}

export const subscriptionAPI = (payload) => async(dispatch) => {
    const userDetails = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')): null;
    const url = `v1/common/account/planDetails/read${userDetails?.accountId  ? `/${userDetails?.accountId}`: ''}`;
   try{
       dispatch({
           type: 'PLAN_API_REQUEST',
           payload: null
       })
 
       const response =await api.getApi(url,payload);
       return dispatch({
           type: 'PLAN_API_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'PLAN_API_FAILURE',
           payload: e
       });
   }
}