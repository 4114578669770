// Import necessary modules
import { useEffect,useState } from 'react';
import * as firebase from 'firebase/app';
import { getMessaging, onMessage, getToken} from "firebase/messaging";
import * as firebaseconf from 'firebase/messaging';
import { message } from 'antd';

// Replace with your Firebase config
const firebaseConfig = {
  apiKey: "AIzaSyDLM5LRvagU1fwRi2XuGvDcKeoIB1M6qNg",
  authDomain: "myredfolder-b767f.firebaseapp.com",
  projectId: "myredfolder-b767f",
  storageBucket: "myredfolder-b767f.appspot.com",
  messagingSenderId: "974392189540",
  appId: "1:974392189540:web:352f69e57107d1615e7571",
  measurementId: "G-5ZPHEY0JDY"
};

// Initialize Firebase
const fcmapp = firebase.initializeApp(firebaseConfig);
const messaging = getMessaging(fcmapp);

export const getTokenFCM = async (setTokenFound,loginWithCognitoToken) => {
  Notification.requestPermission()
  .then((permission) => {

    if (permission === "granted") {
    console.log("Notification User Permission Granted.");
  return getToken(messaging, {vapidKey: 'BEIBimcPKZSKYe6h7zzBgjFe9644jGoaSateHEMS2jSogdupYTWidPoauntvE2xSX8I_TO24uSUBEY4eAredS2U'})
  .then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      setTokenFound(true);
        setTimeout(()=>{
          const userDetails = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')): null;
  
        const loginObject = {
          email: userDetails?.emailId ? userDetails?.emailId: null,
          deviceUniqueId: currentToken
        };
        const previousToken  = localStorage.getItem('deviceUniqueId');
        console.log(loginObject,"loginObject");
        console.log(previousToken,"previousToken")
        if (loginObject && loginObject.email) {
          if(!previousToken){
            console.log("calling api for token registration")
            loginWithCognitoToken(loginObject);
          }
        }
        }, 3000)
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
} else {

  console.log("User Permission Denied.", permission);
  
  }
})
}

export const onMessageListener = () =>{
  console.log("I am in lisster")
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("I am in  messaginglisster",payload)
      // setTimeout(()=>{
        resolve(payload);
      // },1000)
    });
    
    });
    
}



