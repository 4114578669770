import {api} from '../../../../utils/apiClient';
import { CognitoIdentityProviderClient, AdminCreateUserCommand, AdminDeleteUserCommand, AdminConfirmSignUpCommand } from "@aws-sdk/client-cognito-identity-provider"; // ES Modules import
import awsConfig from '../../../../aws-exports';
import constants from '../../../../config/constants';

const client = new CognitoIdentityProviderClient({ 
    region: "us-east-1",
    credentials: {
        accessKeyId: constants.COGNITO_ACCESS_KEY,
        secretAccessKey: constants.COGNITO_SECRET_KEY,
    }
});
export const getFamilyMembers = (payload) => async(dispatch) => {
    const userDetails = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')): null;

     const url = `v1/user/readAll${userDetails?.accountId  ? `/${userDetails?.accountId}`: ''}`;
    try{
        dispatch({
            type: 'MEMBERINFO_DATA_REQUEST',
            payload: null
        })
        const response =await api.getApi(url);
        if(response.data && response.data.status === 200){
            return dispatch({
                type: 'MEMBERINFO_DATA_SUCCESS',
                payload: response.data
            });
        }else{
            return dispatch({
                type: 'MEMBERINFO_DATA_FAILURE',
                payload: response.data
            });
        }
       
    }catch(e){
        return dispatch({
            type: 'MEMBERINFO_DATA_FAILURE',
            payload: e
        });
    }
}

export const updateProfileInfo = (payload) => async(dispatch) => {
    const url = `v1/user/profile/update`;
   try{
       dispatch({
           type: 'USERDETAILS_UPDATE_REQUEST',
           payload: null
       })
       const response =await api.putApi(url,payload);
       return dispatch({
           type: 'USERDETAILS_UPDATE_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'USERDETAILS_UPDATE_FAILURE',
           payload: e
       });
   }
}

export const addMember = (payload) => async(dispatch) => {
   
    const url = `v1/user/create`;
   try{
       dispatch({
           type: 'CREATE_USER_REQUEST',
           payload: null
       })
       const response =await api.postApi(url,payload);
           return dispatch({
               type: 'CREATE_USER_SUCCESS',
               payload: response.data
               });
   }catch(e){
       return dispatch({
           type: 'CREATE_USER_FAILURE',
           payload: e
       });

   }
}

export const getSpecficUserProfile = (payload) => async(dispatch) => {

     const url = `v1/user/read${payload?.id  ? `/${payload?.id}`: ''}`;
   try{
       dispatch({
           type: 'USER_DATA_REQUEST',
           payload: null
       })
       const response =await api.getApi(url);

       return dispatch({
           type: 'USER_DATA_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'USER_DATA_FAILURE',
           payload: e
       });
   }
}

export const editUser = (payload) => async(dispatch) => {
   const url = `v1/user/update`;
   try{
       dispatch({
           type: 'USERINFO_UPDATE_REQUEST',
           payload: null
       })
       const response =await api.putApi(url,payload);
       return dispatch({
           type: 'USERINFO_UPDATE_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'USERINFO_UPDATE_FAILURE',
           payload: e
       });
   }
}

export const uploadCommonUtilityPicture = (payload) => async(dispatch) => {
console.log(payload, 'uploadCommonUtilityPicture')
    const url = `v1/commonUtility/file/upload`;
   try{
       dispatch({
           type: 'UTILITY_IMAGE_UPDATE_REQUEST',
           payload: null
       })
 
       const response =await api.postApi(url,payload);
       return dispatch({
           type: 'UTILITY_IMAGE_UPDATE_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'UTILITY_IMAGE_UPDATE_FAILURE',
           payload: e
       });
   }
}

export const getRelationshipList = (payload) => async(dispatch) => {
    const url = `v1/commonUtility/listValues/readAll`;
   try{
       dispatch({
           type: 'RELATIONSHIP_DATA_REQUEST',
           payload: null
       })
       const response =await api.getApi(url);
       return dispatch({
           type: 'RELATIONSHIP_DATA_SUCCESS',
           payload: response?.data?.data['users-relationship'] ? response.data.data['users-relationship'] : []
       });
   }catch(e){
       return dispatch({
           type: 'RELATIONSHIP_DATA_FAILURE',
           payload: e
       });
   }
}

export const deleteUserDataApi = (id) => async(dispatch) => {
    const url = `v1/user/delete${id  ? `/${id}`: ''}`;
    try{
        dispatch({
            type: 'DELETE_DATA_REQUEST',
            payload: null
        })
        const response =await api.deleteapi(url);
        return dispatch({
            type: 'DELETE_DATA_SUCCESS',
            payload: response.data
        });
    }catch(e){
        return dispatch({
            type: 'DELETE_DATA_FAILURE',
            payload: e
        });
    }
 };

 export const updateProfileInHeader = (newProfile) =>  async(dispatch) => {
    return dispatch ({
      type: 'UPDATE_PROFILE',
      payload: newProfile,
    });
  };


  export const getDisasterList = (payload) => async(dispatch) => {
    const userDetails = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')): null;

    const url = `v1/disaster/readAll${userDetails?.accountId  ? `/${userDetails?.accountId}`: ''}`;
   try{
       dispatch({
           type: 'DISASTER_LIST_REQUEST',
           payload: null
       })
       const response =await api.getApi(url);
        return dispatch({
            type: 'DISASTER_LIST_SUCCESS',
            payload: response.data
        });
   }catch(e){
       return dispatch({
           type: 'MEMBERINFO_DATA_FAILURE',
           payload: e
       });
   }
}
export const deleteDisasterApi = (id) => async(dispatch) => {
    console.log(id, 'ididid')
    const url = `v1/disaster/delete${id  ? `/${id}`: ''}`;
    try{
        dispatch({
            type: 'DELETE_DISASTER_REQUEST',
            payload: null
        })
        const response =await api.deleteapi(url);
        return dispatch({
            type: 'DELETE_DISASTER_SUCCESS',
            payload: response.data
        });
    }catch(e){
        return dispatch({
            type: 'DELETE_DISASTER_FAILURE',
            payload: e
        });
    }
 }
  
export const markAsAdminAPI = (payload) => async(dispatch) => {
    const url = `v1/user/markAsAdmin`;
    
    try{
        dispatch({
            type: 'ADMIN_DATA_REQUEST',
            payload: null
        })
        const response =await api.putApi(url, payload);
        return dispatch({
            type: 'ADMIN_DATA_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'ADMIN_DATA_FAILURE',
            payload: e
        });
    }
 };

  
export const createNewDisaster = (payload) => async(dispatch) => {
    const url = `v1/disaster/create`;
    
    try{
        dispatch({
            type: 'CREATE_DISASTER_DATA_REQUEST',
            payload: null
        })
        const response =await api.postApi(url, payload);
        return dispatch({
            type: 'CREATE_DISASTER_DATA_SUCCESS',
            payload: response.data
        });
    }catch(e){
        return dispatch({
            type: 'CREATE_DISASTER_DATA_FAILURE',
            payload: e
        });
    }
 };
 export const updateDisasterDataAPI = (payload) => async(dispatch) => {
    const url = `v1/disaster/update`;
    try{
        dispatch({
            type: 'EDIT_DISASTER_REQUEST',
            payload: null
        })
        const response =await api.putApi(url,payload);
        return dispatch({
            type: 'EDIT_DISASTER_SUCCESS',
        payload: response.data
        });
        }catch(e){
        return dispatch({
           type: 'EDIT_DISASTER_FAILURE',
            payload: e
        });
    }
 } 
 
 export const resetSetting = (payload) => async(dispatch) => {
    dispatch({
        type: 'RESET_SETTING',
        payload: null
    })
}

export const createCognitoUser = (payload) =>async(dispatch) => {
    const input = { 
      UserPoolId: awsConfig.aws_user_pools_id, // required
      ClientId:awsConfig.aws_user_pools_web_client_id,
      Username: payload.username, // required
      Password:payload.password,
      UserAttributes: null,
      ValidationData: null,
      MessageAction:  "SUPPRESS",
      DesiredDeliveryMediums: [ 
      ],
    };
    try{
        const command = new AdminCreateUserCommand(input);
        const response = await client.send(command);
        if(response){
            const input = { 
            UserPoolId:awsConfig.aws_user_pools_id, // required // required
            Username: payload.username, // required
            };
            const command = new AdminConfirmSignUpCommand(input);
            const confirmresponse = await client.send(command);
        }
        console.log(response)
    }catch(e){
        console.log(e)
    }
};

export const deleteUser = (email) =>async(dispatch) => {
    const input = { // AdminDeleteUserRequest
        UserPoolId: awsConfig.aws_user_pools_id, // required
        Username: email, // required
      };
    try{
        const command = new AdminDeleteUserCommand(input)
        const response = await client.send(command);
        console.log(response)
    }catch(e){
        console.log(e)
    }
};

export const addPredefinedMessage = (payload) => async(dispatch) => {
    const url = `v1/settings/messages/create`;
    try{
        dispatch({
            type: 'CREATE_MESSAGE_REQUEST',
            payload: null
        })
        const response =await api.postApi(url,payload);
            return dispatch({
                type: 'CREATE_MESSAGE_SUCCESS',
                payload: response.data
                });
    }catch(e){
        return dispatch({
            type: 'CREATE_MESSAGE_FAILURE',
            payload: e
        });
 
    }
};

export const getPredefinedMessages = (payload) => async(dispatch) => {
    const url = `v1/settings/messages/read/${payload.id}`;
    try{
        dispatch({
            type: 'GET_MESSAGE_REQUEST',
            payload: null
        })
        const response =await api.getApi(url,payload);
            return dispatch({
                type: 'GET_MESSAGE_SUCCESS',
                payload: response.data
                });
    }catch(e){
        return dispatch({
            type: 'GET_MESSAGE_FAILURE',
            payload: e
        });
 
    }
};

export const getAllPredefinedMessages = (payload) => async(dispatch) => {
    const userDetails = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')): null;

    const url = `v1/settings/messages/readAll/${userDetails?.accountId}`;

    try{
        dispatch({
            type: 'GETALL_MESSAGE_REQUEST',
            payload: null
        })
        const response =await api.getApi(url,payload);
            return dispatch({
                type: 'GETALL_MESSAGE_SUCCESS',
                payload: response.data
                });
    }catch(e){
        return dispatch({
            type: 'GETALL_MESSAGE_FAILURE',
            payload: e
        });
 
    }
};

export const updatePredefinedMessages = (payload) => async(dispatch) => {
    const url = `v1/settings/messages/update`;
    try{
        dispatch({
            type: 'UPDATE_MESSAGE_REQUEST',
            payload: null
        })
        const response =await api.putApi(url,payload);
            return dispatch({
                type: 'UPDATE_MESSAGE_SUCCESS',
                payload: response.data
                });
    }catch(e){
        return dispatch({
            type: 'UPDATE_MESSAGE_FAILURE',
            payload: e
        });
    }
};

export const deletePredefinedMessages = (payload) => async(dispatch) => {
    const url = `v1/settings/messages/delete/${payload?.id}`;
    try{
        dispatch({
            type: 'DELETE_MESSAGE_REQUEST',
            payload: null
        })
        const response =await api.deleteapi(url,payload);
            return dispatch({
                type: 'DELETE_MESSAGE_SUCCESS',
                payload: response.data
                });
    }catch(e){
        return dispatch({
            type: 'DELETE_MESSAGE_FAILURE',
            payload: e
        });
    }
};


export const addMeetingLocationAPI = (payload) => async(dispatch) => {
    const url = `v1/settings/meetingLocations/create`;
    try{
        dispatch({
            type: 'CREATE_LOCATION_REQUEST',
            payload: null
        })
        const response =await api.postApi(url,payload);
            return dispatch({
                type: 'CREATE_LOCATION_SUCCESS',
                payload: response.data
                });
    }catch(e){
        return dispatch({
            type: 'CREATE_LOCATION_FAILURE',
            payload: e
        });
 
    }
};




export const getAllMeetingLocationAPI = (payload) => async(dispatch) => {
    const userDetails = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')): null;

    const url = `v1/settings/meetingLocations/readAll/${userDetails?.accountId}`;
    try{
        dispatch({
            type: 'GET_LOCATION_REQUEST',
            payload: null
        })
        const response =await api.getApi(url,payload);
            return dispatch({
                type: 'GET_LOCATION_SUCCESS',
                payload: response.data
                });
    }catch(e){
        return dispatch({
            type: 'GET_LOCATION_FAILURE',
            payload: e
        });
 
    }
};


export const updateMeetingLocationAPI = (payload) => async(dispatch) => {
    const url = `v1/settings/meetingLocations/update`;
    try{
        dispatch({
            type: 'UPDATE_LOCATION_REQUEST',
            payload: null
        })
        const response =await api.putApi(url,payload);
        console.log(response.data)
            return dispatch({
                type: 'UPDATE_LOCATION_SUCCESS',
                payload: response.data
                });
    }catch(e){
        return dispatch({
            type: 'UPDATE_LOCATION_FAILURE',
            payload: e
        });
 
    }
};

export const deleteMeetingLocationAPI = (payload) => async(dispatch) => {
    const url = `v1/settings/meetingLocations/delete/${payload.id}`;
    try{
        dispatch({
            type: 'DELETE_LOCATION_REQUEST',
            payload: null
        })
        const response =await api.deleteapi(url,payload);
            return dispatch({
                type: 'DELETE_LOCATION_SUCCESS',
                payload: response.data
                });
    }catch(e){
        return dispatch({
            type: 'DELETE_LOCATION_FAILURE',
            payload: e
        });
 
    }
};

export const getAllCategory = () => async(dispatch) => {
 
    const url = `v1/commonUtility/listValues/readAll`;
    try{
        dispatch({
            type: 'CATEGORY_DATA_REQUEST',
            payload: null
        })
        const response =await api.getApi(url);
        return dispatch({
            type: 'CATEGORY_DATA_SUCCESS',
            payload: response?.data?.data['messages-category'] ? response.data.data['messages-category'] : []
        });
    }catch(e){
        return dispatch({
            type: 'CATEGORY_DATA_FAILURE',
            payload: e
        });
    }

}

export const updateEmergencyTheme = (payload) => async(dispatch) => {

    const url = `v1/settings/accountPreferences/emergencyTile/update`;
    try{
        dispatch({
            type: 'EMERGENCY_THEME_REQUEST',
            payload: null
        })
        const userDetails = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')): null;
        let reqObject = {
            ...payload,
            "accountId": userDetails.accountId,
            "updatedBy": userDetails.id

        }
        const response =await api.putApi(url,reqObject);
        if(response.data && response.data.status === 200){
            return dispatch({
                type: 'EMERGENCY_THEME_SUCCESS',
                payload: response.data
            });
        }else{
            return dispatch({
                type: 'EMERGENCY_THEME_FAILURE',
                payload: response.data
            });
        }
    }catch(e){
        return dispatch({
            type: 'EMERGENCY_THEME_FAILURE',
            payload: e
        });
    }
}