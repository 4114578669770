import React, { useState, useContext, useEffect } from 'react';
import { Grid, Box, Typography, IconButton } from "@mui/material";
import DirectionsIcon from '@mui/icons-material/Directions';
import { useNavigate } from 'react-router-dom';
import constants from '../../../../../config/constants';
import MeetingLocationMap from '../../../../../containers/Pages/Settings/components/MeetingLocations/MeetingLocationMap';
import { GoogleMap, InfoWindowF, Marker } from "@react-google-maps/api";
import NoData from '../../../../../assets/images/emergency-services/no-results.png';
import "./EmergencyServices.css"



function EmergencyServices(props) {
    const navigate = useNavigate()
    const [serviceType, setServiceType] = useState(null);
    const [nearestPlace, setNearestPlace] = useState([]);

    const urlString = window.location.pathname;
    const parts = urlString.split("/");
    const newServiceType = parts[2];
    const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
    const [searchText, setSearchText] = useState("");
    const [locations, setLocations] = useState([]);
    const [activeMarker, setActiveMarker] = useState(null);
    const [map, setMap] = useState(null);
    const [filteredData, setFilteredData] = useState([]); 

    useEffect(() => {
        getLocation();
    }, []);
    useEffect(() => {
        if (newServiceType) {
            if (newServiceType === "hospital") {
                setServiceType("Hospital")
            } else if (newServiceType === "police") {
                setServiceType("Police")
            } else if (newServiceType === "fire_station") {
                setServiceType("Fire Department")
            } else if (newServiceType === "ambulance_station") {
                setServiceType("Ambulance Service")
            }
        }
    }, [newServiceType]);

    const getLocation = () => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    console.log('Got location:', latitude, longitude);
                    setCoordinates({ lat: latitude, lng: longitude });
                    fetchNearestPlace(latitude, longitude); // Call fetchNearestPlace here
                },
                (error) => {
                    console.error('Error getting location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported.');
        }
    };

    const fetchNearestPlace = (latitude, longitude) => {
        const google = window.google;
        if (google) {
            const service = new google.maps.places.PlacesService(document.createElement('div'));
            const request = {
                location: new google.maps.LatLng(latitude, longitude),
                radius: 3218,
                type: newServiceType,
            };

            service.nearbySearch(request, (results, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    console.log("results", results);
                    if (results && results.length > 0) {
                        setNearestPlace(results);
                        const calcDist = results.map(item => ({
                            ...item,
                            distance: calculateDistance(defaultCenter.lat, defaultCenter.lng, item.geometry.location.lat(), item.geometry.location.lng())
                        }));
                        const sortedLocations = calcDist.sort((a, b) => parseFloat(a.distance) - parseFloat(b.distance));
                        setFilteredData(sortedLocations)
                        const parsedLocations = results.map(result => ({
                            locationLat: result.geometry.location.lat(),
                            locationLong: result.geometry.location.lng(),
                            name: result.name,
                            address: result.vicinity,
                            id: result?.place_id
                        }));
                        setLocations(parsedLocations);
                        console.log("parsedLocations", parsedLocations);

                    } else {
                        setNearestPlace(null);
                    }
                } else {
                    console.log('API ERROR', status);

                }
            });
        }
    }

    useEffect(() => {
        const filterData = nearestPlace.filter(res =>
            res.name.toLowerCase().includes(searchText.toLowerCase())
        );
        const calcDist = filterData.map(item => ({
            ...item,
            distance: calculateDistance(defaultCenter.lat, defaultCenter.lng, item.geometry.location.lat(), item.geometry.location.lng())
        }));

        const sortedLocations = calcDist.sort((a, b) => parseFloat(a.distance) - parseFloat(b.distance));
        setFilteredData(sortedLocations);

        const parsedLocations = filterData.map(result => ({
            locationLat: result.geometry.location.lat(),
            locationLong: result.geometry.location.lng(),
            name: result.name,
            address: result.vicinity,
            id: result?.place_id
        }));
        setLocations(parsedLocations);
        if (!searchText && parsedLocations.length > 0) {
            setSelectedLocation({
                position: {
                    lat: parsedLocations[0].locationLat,
                    lng: parsedLocations[0].locationLong
                },
                zoom: 14,
                selectedObject: parsedLocations[0]
            });
        }else if (parsedLocations.length > 0) {
            setSelectedLocation({
                position: {
                    lat: parsedLocations[0].locationLat,
                    lng: parsedLocations[0].locationLong
                },
                zoom: 16,
                selectedObject: parsedLocations[0]
            });
        }
    }, [searchText, nearestPlace]);

    const defaultCenter = {
        lat: coordinates?.lat || 37.09024, // Latitude 
        lng: coordinates?.lng || -95.712891, // Longitude
    };
    const [selectedLocation, setSelectedLocation] = useState({
        position: null,
        zoom: 13, // Default zoom level
        selectedObject: null,
    });
    const panToLocation = (data) => {
        let locationLat = data.geometry.location.lat
        let locationLong = data.geometry.location.lng
        if (locationLat && locationLong) {

            if (locationLat) {
                locationLat = parseFloat(locationLat)
            }
            if (locationLong) {
                locationLong = parseFloat(locationLong)
            }
            setSelectedLocation({
                position: { lat: locationLat, lng: locationLong },
                zoom: 16,
                selectedObject: data
            });
        setActiveMarker(data?.place_id);

        }
    };

    const getMapURL = (data) => {
        return `https://www.google.com/maps/search/?api=1&query=${data?.geometry?.location?.lat()},${data?.geometry?.location?.lng()}`;
    };
    const handleDirectionsClick = (item) => {
        const mapURL = getMapURL(item);
        window.open(mapURL, '_blank');
    };

    const handleActiveMarker = (marker) => {
        setActiveMarker(null);
        if (marker === activeMarker) {
            return;
        }
        if(map){
            map.setZoom(16);
          }
        setActiveMarker(marker);
    };

    const handleOnLoad = (map) => {
        const bounds = new window.google.maps.LatLngBounds();
        locations.forEach((marker) => {
            const position = {
                lat: parseFloat(marker?.locationLat),
                lng: parseFloat(marker?.locationLong)
            };
            bounds.extend(position);
        });

        map.fitBounds(bounds);
        setMap(map);
    };

    function calculateDistance(lat1, lon1, lat2, lon2) {
        const earthRadiusMiles = 3958.8; // Earth's radius in miles
    
        const dLat = toRadians(lat2 - lat1);
        const dLon = toRadians(lon2 - lon1);
    
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
    
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    
        const distance = earthRadiusMiles * c;
        return distance.toFixed(2);
        // return distance.toFixed(2) + " miles";
    }
    
    function toRadians(degrees) {
        return degrees * (Math.PI / 180);
    }
    return (
        <>
            <Grid container spacing={2} style={{ width: "100%", margin: 0 }}>
                {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
                <div className='emergency-navbar-container'>
                <Grid container
                    xs={12}
                    md={12}
                    lg={12}
                    className="box-container"
                >

                    <Grid container  style={{ width: "100%", margin: 12, display: "flex", justifyContent: "center" }}>
                        {/* <Grid item xs={12} md={12} lg={12} sx={{marginBottom:2}}> */}
                            {/* <Box sx={{ m: 0, pb: "15px" }} className="box-header">
                                <Typography className="primary-font-bold-2-heading">
                                    {serviceType}
                                </Typography>
                            </Box> */}
                        {/* </Grid> */}
                        <Grid  xs={12} md={5} lg={5}>
                        <form className="group relative" style={{ position: 'relative',marginBottom:5 }}>
                                    {!searchText && (<svg
                                        width="20"
                                        height="20"
                                        fill="currentColor"
                                        style={{ position: 'absolute', right: '0.75rem', top: '50%', transform: 'translateY(-50%)', color: '#FF3535', pointerEvents: 'none' }}
                                        aria-hidden="true"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                        />
                                    </svg>)}
                                {searchText && (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        fill="currentColor"
                                        style={{
                                            position: 'absolute',
                                            cursor: "pointer",
                                            right: '0.75rem',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            color: '#FF3535',
                                            pointerEvents: 'auto' // Change pointerEvents to 'auto' to enable clicking
                                        }}
                                        aria-hidden="true"
                                        viewBox="0 0 24 24"
                                        id="Clear"
                                        onClick={() => setSearchText('')} // Add onClick event to clear searchText
                                    >
                                        <path fill="none" d="M0 0h24v24H0V0z"></path>
                                        <path
                                            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
                                            fill="#ff3535"
                                            class="color000000 svgShape"
                                        ></path>
                                    </svg>
                                )}

                                    <input
                                        style={{
                                            outline: 'none',
                                            maxWidth: 'fit-content',
                                            width:"100%",
                                            fontSize: '13px',
                                            lineHeight: '1.5rem',
                                            color: '#1F2937',
                                            placeholderColor: '#ddd',
                                            borderRadius: '25px',
                                            paddingTop: '0.5rem',
                                            paddingBottom: '0.5rem',
                                            paddingLeft: '12px',
                                            borderWidth: '1px',
                                            border: "1px solid #FF3535",
                                            boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
                                        }}
                                        placeholder="Search locations"
                                        type="text"
                                        size="100"
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />
                                </form>
                             {filteredData?.length > 0 && (<div id="meeting-lcoation" className='scrollable-container' >
                                {filteredData && filteredData?.map((item, index) => {
                                    return (
                                        <Grid
                                        key={item?.place_id}
                                            style={{
                                                marginTop: "20px",
                                                marginBottom: "20px",
                                                width: "100%",
                                            }}
                                        >
                                            <Grid
                                                container
                                                onClick={() => { panToLocation(item) }}
                                                sx={{
                                                    margin: "0px",
                                                    padding: "12px",
                                                    mr: "10px",
                                                    border: "1px solid #D8D8D8",
                                                    borderRadius: "10px",
                                                    flexDirection: "column",
                                                    cursor: "pointer",
                                                    transition: "background-color 0.3s", // Optional: Adds smooth transition
                                                    "&:hover": {
                                                        border: "1px solid #FF3535",
                                                    },
                                                }}
                                            >
                                                <Grid>
                                                    <Typography
                                                        className="primary-font-bold-5"
                                                        style={{ textTransform: "capitalize", marginBottom: 5 }}
                                                    >
                                                        {item?.name}
                                                    </Typography>
                                                </Grid>
                                                <Grid>
                                                    {" "}
                                                    <Typography sx={{ fontSize: "14px", marginBottom: 2}}>
                                                        {item?.vicinity}
                                                    </Typography>
                                                </Grid>
                                                <Grid>
                                                    {/* {" "} */}
                                                    <Typography sx={{ fontSize: "14px !important", marginBottom: 1 }}>
                                                       {"Distance:"}{item.distance}{" miles"}
                                                    </Typography>
                                                </Grid>

                                                
                                                <Grid container alignItems="center" justifyContent="space-between">


                                                    <Typography
                                                        variant="body1"
                                                        sx={{
                                                            color: item?.opening_hours?.open_now ? 'green' : '#FF3535',
                                                            fontSize: 14,
                                                            fontWeight: 500,
                                                        }}
                                                    >
                                                        {item?.opening_hours?.open_now ? 'Open Now' : 'Closed'}
                                                    </Typography>
                                                    <div>
                                                        <IconButton
                                                            aria-label="directions"
                                                            sx={{
                                                                ml: 1, color: '#FF3535', border: '1px solid #FF3535', "&:hover": {
                                                                    background: "#FF3535", color: "#fff",
                                                                },
                                                            }}
                                                            onClick={() => handleDirectionsClick(item)}

                                                        >
                                                            <DirectionsIcon />
                                                        </IconButton>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )

                                })}

                            </div>)}

                            {filteredData?.length === 0 && (<div className="no-data-container">
                                <img src={NoData} style={{marginLeft:60,width:"150px"}} />
                                <p className="no-data-text">No matching search results</p>
                            </div>)}
                            

                        </Grid>
                        <Grid xs={12} md={7} lg={7} className='emergency-middle'>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {/* <MeetingLocationMap defaultCenterlatlng={defaultCenter} userLocation={locations}  zoom={selectedLocation.zoom}  selectedObject={selectedLocation.selectedObject}  showCustomMarker={false} /> */}
                                <GoogleMap
                                    onLoad={handleOnLoad}
                                    onClick={() => setActiveMarker(null)}
                                    mapContainerStyle={{ width: "100%", height: "450px" }}
                                    center={selectedLocation?.position || defaultCenter}
                                    mapTypeId={"terrain"} // roadmap, satellite, hybrid, terrain
                                    zoom={selectedLocation ? selectedLocation.zoom : 12}
                                    options={{
                                        scrollZoom: { ctrl: true },
                                        zoomControl: true,
                                        draggable: true,
                                        mapTypeControl: true,
                                        scaleControl: true,
                                        streetViewControl: false,
                                        rotateControl: true,
                                        fullscreenControl: true,
                                        scrollwheel: true,
                                    }}
                                >
                                    {locations?.map((marker) => {
                                        const positionData = {
                                            lat: marker?.locationLat ? parseFloat(marker.locationLat) : null,
                                            lng: marker?.locationLong ? parseFloat(marker.locationLong) : null
                                        };
                                        if (positionData.lat !== null && positionData.lng !== null) {
                                            return (
                                                <Marker
                                                key={marker.id}
                                                position={positionData}
                                                onClick={() => handleActiveMarker(marker.id)} // Assuming this sets the active marker ID
                                            >
                                                {activeMarker !== null && activeMarker === marker.id && (
                                                    <InfoWindowF
                                                        position={positionData} // Set the position prop for InfoWindow
                                                        onCloseClick={() => setActiveMarker(null)} // Ensure onCloseClick sets activeMarker to null
                                                    >
                                                        <>
                                                            <div style={{ fontWeight: "bold" }}>
                                                                {marker.name
                                                                    ? marker.name
                                                                    : marker.firstName +
                                                                      " " +
                                                                      (marker?.middleName && marker?.middleName !== null
                                                                          ? marker.middleName
                                                                          : "") +
                                                                      " " +
                                                                      (marker?.lastName && marker?.lastName !== null ? marker.lastName : "")}
                                                            </div>
                                                            <div>{marker.address}</div>
                                                        </>
                                                    </InfoWindowF>
                                                )}
                                            </Marker>
                                            
                                            );
                                        } else {
                                            console.log("Skipping Marker:", marker.name);
                                            return null;
                                        }
                                    })}
                                </GoogleMap>
                            </div>
                        </Grid>

                    </Grid>
                </Grid>
                </div>
            </Grid>

        </>

    );
}

export default EmergencyServices;