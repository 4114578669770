import React from 'react';
import ContentWrapper from '../../../../common/components/ContentWrapper';
import EditProfilePage from './EditProfilePage';
const EditProfile = () => {
  return (
    <>
      <ContentWrapper title={"My Profile"} gobackUrl={''} img={false} goback={true} contenview={<EditProfilePage />} />
    </>
  )
}

export default EditProfile;
