import React, {useEffect, useState } from 'react';
import { Box, Button, Checkbox, Container, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import * as formatters from '../../../../utils/util'
import { saveBillingInformation } from '../redux/actions';
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";


const BillingInformation = ({handleNext,saveBillingInformation,billingInformationData}) => {

    const [firstNameErrMessage, setFirstNameErrMessage] = useState('');
    const [lastNameErrMessage, setLastNameErrMessage] = useState('');
    const [addressOneErrMessage, setAddressOneErrMessage] = useState('');
    // const [addressTwoErrMessage, setAddressTwoErrMessage] = useState('');
    const [cityErrMessage, setCityErrMessage] = useState('');
    const [countryErrMessage, setCountryErrMessage] = useState('');
    const [codeErrMessage, setCodeErrMessage] = useState('');
    const [provinceErrMessage, setProvinceErrMessage] = useState('');

    const userDetails = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

    // const [reciveCheck, setreciveCheck] = useState(false);

    const [billingData, setBillingData] = useState({

       userId: userDetails.id,
        accountId:userDetails.accountId,
        firstName: '',
        lastName: '',
        addressOne: '',
        addressTwo: '',
        city: '',
        country: '',
        postalCode: '',
        // province: '',
        paymentMethodId: window.localStorage.getItem("paymentMethodId"),
        createdBy: userDetails.id
      });
    

    const handleChange = (event) => {
        const { name, value, checked } = event.target;
      
        if (name === "firstName") {
          setFirstNameErrMessage("");
        }
        if (name === "lastName") {
          setLastNameErrMessage("");
        }
        if (name === "addressOne") {
          setAddressOneErrMessage("");
        }
      
        if (name === "city") {
          setCityErrMessage("");
        }
        if (name === "country") {
          setCountryErrMessage("");
        }
        if (name === "postalCode") {
          setCodeErrMessage("");
        }
       
        setBillingData((prevFormData) => ({ ...prevFormData, [name]: value }));
        setBillingData((prevFormData) => ({
          ...prevFormData,
        //   reciveCheck: prevFormData.reciveCheck === "on" ? "true" : "false",
        }));
      };

      const onSubmitBillingInfo = () => {
       
          let validation = true;
          if (billingData.firstName === "") {
            setFirstNameErrMessage("This is Required");
            validation = false;
          }
          if (billingData.lastName === "") {
            setLastNameErrMessage("This is Required");
            validation = false;
          }
          if (billingData.addressOne === "") {
            setAddressOneErrMessage("This is Required");
            validation = false;
          }
         
          if (billingData.city === "") {
            setCityErrMessage("This is Required");
            validation = false;
          }
          if (billingData.country === "") {
            setCountryErrMessage("This is Required");
            validation = false;
          }
          if (billingData.postalCode === "") {
            setCodeErrMessage("This is Required");
            validation = false;
          }
          if (validation) {
            saveBillingInformation(billingData);
          }
      }

      useEffect(() =>{
        if (billingInformationData?.status && billingInformationData?.status === 200) {
          window.localStorage.removeItem("paymentMethodId");
          handleNext("success")
          }
      },[billingInformationData])


    return (
        <Grid>
            <Grid container justifyContent="center" alignItems="center" >
                <Grid  item md={12}>
                    {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox
                                      checked={reciveCheck}
                                    size="small"
                                    sx={{
                                        color: "#D9D9D9", //#5D4294 #D9D9D9
                                        '&.Mui-checked': {
                                            color: "#ff3535",
                                        },
                                    }}
                                    name="reciveCheck"
                                    value={billingData.reciveCheck}
                                    onChange={(e) => {
                                        setreciveCheck(!reciveCheck); 
                                        handleChange(e); 
                                    }}
                                />
                            } label={<Typography className="normal-text">{"I Do Not Wish To Receive a Receipt"}</Typography>} />
                        </FormGroup>
                    </div> */}
                    <div>
        <div className='mb-10' >
          <div className="apply-amount mt-10">
            <div className="flex-space-btw ">
              <div className="w-50" style={{ display: 'flex', flexDirection: 'column' }}>
                <input className={`amount-input placeholder-text  sub-head font-normal ${firstNameErrMessage !== '' ? 'err-input' : ''}`}
                  type="text"
                  placeholder={"First Name"}
                   
                  name="firstName"
                  value={formatters.formatText(billingData.firstName)}
                  onChange={handleChange}
                />
                {firstNameErrMessage !== '' && (<p className="normal-text" id="err-msg" style={{ color: '#FF0000', display: 'flex', alignItems: 'center', margin:'5px 0px' }}>
                 {firstNameErrMessage}</p>)}
              </div>
              <div className="w-50" style={{ display: 'flex', flexDirection: 'column' }}>
                <input className={`amount-input placeholder-text  sub-head font-normal ${lastNameErrMessage !== '' ? 'err-input' : ''}`}
                  type="text"
                  placeholder={"Last Name"}
                   
                  name="lastName"
                  value={formatters.formatText(billingData.lastName)}
                  onChange={handleChange}
                />
                {lastNameErrMessage !== '' && (<p className="normal-text" id="err-msg" style={{ color: '#FF0000', display: 'flex', alignItems: 'center', margin:'5px 0px' }}>
                {lastNameErrMessage}</p>)}
              </div>

            </div>
          </div>
        </div>

        <div >
          <div className="apply-amount mt-10">
            <input className={`amount-input placeholder-text  sub-head font-normal  ${addressOneErrMessage !== '' ? 'err-input' : ''} `}
              type="text"
              placeholder={"Address Line1"}
               
              name="addressOne"
              value={billingData.addressOne}
              onChange={handleChange}
            />
            {addressOneErrMessage !== '' && (<p className="normal-text" id="err-msg" style={{ color: '#FF0000', display: 'flex', alignItems: 'center', margin:'5px 0px' }}>
             {addressOneErrMessage}</p>)}
          </div>

          <div className="apply-amount mt-10">
            <input className='amount-input placeholder-text  sub-head font-normal'
              type="text"
              placeholder={"Address Line2"}
               
              name="addressTwo"
              value={billingData.addressTwo}
              onChange={handleChange}
            />
            {/* {addressTwoErrMessage !== '' && (<p className="normal-text" id="err-msg" style={{ color: '#FF0000', display: 'flex', alignItems: 'center', margin:'5px 0px' }}><img class="" height="15px" width="15px" src={ErrorInfo} /> {addressTwoErrMessage}</p>)} */}

          </div>

          <div className="apply-amount mt-10">
            <div className="flex-space-btw ">
              <div className="w-50" style={{ display: 'flex', flexDirection: 'column' }}>

                <input className={`amount-input placeholder-text sub-head font-normal ${cityErrMessage !== '' ? 'err-input' : ''}`}
                  type="text"
                  placeholder={"City"}
                  name="city"
                  value={billingData.city}
                  onChange={handleChange}
                />
                {cityErrMessage !== '' && (<p className="normal-text" id="err-msg" style={{ color: '#FF0000', display: 'flex', alignItems: 'center', margin:'5px 0px' }}>
                {cityErrMessage}</p>)}
              </div>
              <div className="w-50" style={{ display: 'flex', flexDirection: 'column' }}>
                <input className={`amount-input placeholder-text  sub-head font-normal  ${countryErrMessage !== '' ? 'err-input' : ''} `}
                  type="text"
                  placeholder={"Country"}
                  name="country"
                  value={billingData.country}
                  onChange={handleChange}
                />
                {countryErrMessage !== '' && (<p className="normal-text" id="err-msg" style={{ color: '#FF0000', display: 'flex', alignItems: 'center', margin:'5px 0px' }}>
                 {countryErrMessage}</p>)}

              </div>
            </div>
          </div>

          <div className="apply-amount mt-10">
            <div className="flex-space-btw ">
              <div  style={{ display: 'flex', flexDirection: 'column', width:'100%' }}>
                <input className={`amount-input placeholder-text  sub-head font-normal  ${codeErrMessage !== '' ? 'err-input' : ''}`}
                  type="text"
                  placeholder={"PostalCode"}
                  maxLength={7}
                   
                  name="postalCode"
                  value={formatters.isNumaric(billingData.postalCode)}
                  onChange={handleChange}
                />
                {codeErrMessage !== '' && (<p className="normal-text" id="err-msg" style={{ color: '#FF0000', display: 'flex', alignItems: 'center', margin:'5px 0px' }}>
                 {codeErrMessage}</p>)}
              </div>
              {/* <div className="w-50" style={{ display: 'flex', flexDirection: 'column' }}>
                <input className={`amount-input placeholder-text  sub-head font-normal  ${provinceErrMessage !== '' ? 'err-input' : ''} `}
                  type="text"
                  placeholder={"Province"}
                   
                  name="province"
                  value={billingData.province}
                  onChange={handleChange}
                />
                {provinceErrMessage !== '' && (<p className="normal-text" id="err-msg" style={{ color: '#FF0000', display: 'flex', alignItems: 'center', margin:'5px 0px' }}>
                {provinceErrMessage}</p>)}
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Button  sx={{margin:'15px 0px'}}
      onClick={onSubmitBillingInfo}
       className="normal-text next-btn" variant='contained'>
        <span> save & Continue </span>
      </Button>


                </Grid>
            </Grid>
        </Grid>
    );
};

BillingInformation.defaultProps = {
    isFetching: null,
    error: null,
    // billingInformationData: [],
  };

function mapStateToProps(state) {
    return {
        billingInformationData: state.profile.billingInformationData,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            saveBillingInformation
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BillingInformation);