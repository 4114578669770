import React, { useState, useEffect, useRef } from "react";
import "../../../../../../index.css";
import { Grid, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, Divider,Select, MenuItem, FormControl, Box } from "@mui/material";
import { useNavigate ,useLocation} from "react-router-dom";
import * as formatters from '../../../../../../utils/util';
import UserAvatar from '../../../../../../assets/icons/user-avatar.svg';
import Edit from '../../../../../../assets/icons/edit.svg';
import CloseIcon from '../../../../../../assets/icons/modal-close-icon.svg';
import UploadImage from '../../../../../../assets/icons/upload-icon.svg';
import AvatarIcon from "../../../../../../common/components/Avatar";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import moment from "moment/moment";
import { getProfileInfo } from "../../../../UserProfile/redux/actions";

import { updatePersonalInformation,getRelationshipList, getSpecficUserProfile,updatedSpecificUserData, createCognitoUser, uploadCommonUtilityPicture} from "../../../redux/actions";

import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";


const AddPersonalInfo = ({ onContinue,
    onCancel,
    onView,
    getRelationshipList,
    getSpecficUserProfile, 
    updatePersonalInformation, 
    updatedPersonalInfo, 
    updatedSpecificUserData,
    createCognitoUser,
    selectedUser,
    uploadCommonUtilityPicture,
    profilePictureData,
    profilePictureError, 
    getProfileInfo,
    relationship 

}) => {
const navigate = useNavigate();
const location=useLocation()
const fileInputRef = useRef(null);
// const{onNext,mobileView}=location?.state ||""
const userDetails = window.localStorage.getItem("userInfo");
const userDetailsObject = JSON.parse(userDetails);
const dispatch = useDispatch();


const [user, setUser] = useState(null);
const [userObject,setUserObject] = useState(null);

const [selectedImage, setSelectedImage] = useState(null);
const [imageUrlAvatar1, setImageUrlAvatar1] = useState(null);
const [imageUrlAvatar, setImageUrlAvatar] = useState(null);

const [firstName, setFirstName] = useState(null);
const [middleName, setMiddleName] = useState(null);
const [lastName, setLastName] = useState(null);
const [dob, setDOB] = useState(null);
const [ssn, setSSN] = useState('');
const [relation, setRelation] = useState(null);
const [phoneNumber, setphoneNumber] = useState(null);
const [email, setEmail] = useState(null);
const [s3Key, setS3Key] = useState(null);

const [firstNameErr, setFirstNameErr] = useState('');
const [middleNameErr, setMiddleNameErr] = useState('');
const [lastNameErr, setLastNameErr] = useState('');
const [dobErr, setDOBErr] = useState('');
const [ssnErr, setSSNErr] = useState('');
const [relationErr, setRelationErr] = useState('');
const [phoneNumberErr, setphoneNumberErr] = useState('');
const [emailErr, setEmailErr] = useState('');

const [openPhotoUploadModal, setOpenPhotoUploadModal] = useState(false);
const[image, setImage] = useState(null);
const[previewUrl, setPreviewUrl] = useState(""); 
const [isAvatarSelection, setIsAvatarSelection] = useState(false);
const [selectedAvatarData, setSelectedAvatarData] = useState(null);


const id = selectedUser?.userId || "";

useEffect(()=>{
  getSpecficUserProfile({id: id});
  getRelationshipList();
  // console.log(location?.state,"ajith");
},[])

const filteredDataSelf = {};
const filteredDataNotSelf = {};
for (const key in relationship) {
if (key === "self") {
  filteredDataSelf[key] = relationship[key];
} else {
  filteredDataNotSelf[key] = relationship[key];
}
}

useEffect(()=>{
if(updatedSpecificUserData && updatedSpecificUserData.status === 200){
    setUser(updatedSpecificUserData.data);
    const user = updatedSpecificUserData.data;
    if(user){
      setUserObject(user);
      const userName =( (user?.firstName  && user?.firstName !== null  ? user?.firstName : '')  + 
                        ( user?.middleName && user?.middleName !== null ? (' '+user.middleName)  : "") +
                        (user?.lastName && user?.lastName !== null ? (' '+user.lastName ) : ""))
      
      setEmail(user?.emailId)
      setFirstName(user?.firstName)
      setMiddleName(user?.middleName)
      setLastName(user?.lastName)
      setDOB(user?.dateOfBirth || '');
      setSSN(user?.socialSecurityNo);
      setRelation(user?.relationshipCode);
      setphoneNumber(user?.phoneNo);
      setImageUrlAvatar(user?.image_PreSignedUrl)
      setS3Key(user?.image)
    }
    
}
},[updatedSpecificUserData])

useEffect(() => {
if (updatedPersonalInfo && updatedPersonalInfo.status === 200 && updatedPersonalInfo.data && updatedPersonalInfo.data.id) {
  createCognitoUser({
    "username": email,
    "password": new Date().getTime().toString()+'_test_password',
  })
  const data = {
      userId: updatedPersonalInfo.data.id
  }
  window.localStorage.setItem('selectedUser', JSON.stringify(data))
  getProfileInfo();

    onContinue();
    // mobileView();
 
  dispatch({
    type:"RESET_PERSONAL_INFO",
    payload: null
  })
} else if (updatedPersonalInfo && updatedPersonalInfo.status === 200 ){
  getProfileInfo();

        onContinue();
        // mobileView();
      
     
  dispatch({
    type:"RESET_PERSONAL_INFO",
    payload: null
  })
}else if (updatedPersonalInfo && updatedPersonalInfo.status) {
  setEmailErr(updatedPersonalInfo.message);
}
}, [updatedPersonalInfo]);

useEffect(()=>{
if(profilePictureData && profilePictureData.status === 200){
  setS3Key(profilePictureData?.data?.s3Key);
  setImageUrlAvatar(imageUrlAvatar1);
  setOpenPhotoUploadModal(false);
  setPreviewUrl('');
}
},[profilePictureData, profilePictureError])
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
      setPreviewUrl(URL.createObjectURL(file));
      setImageUrlAvatar1(URL.createObjectURL(file))
      setImageUrlAvatar(URL.createObjectURL(file))
    }
  }


const handleFile = file => {
  if (file){
    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    setImageUrlAvatar1(URL.createObjectURL(file))
    setImageUrlAvatar(URL.createObjectURL(file))
  }

}
const handleOnDrop = event => {

event.preventDefault(); 
event.stopPropagation(); 
setIsAvatarSelection(false);
let imageFile = event.dataTransfer.files[0];
handleFile(imageFile);
}
const triggerFileInput = (e) => {
  e.preventDefault();
  if (fileInputRef.current) {
    fileInputRef.current.click();
  }
};
const handleDragOver = event => {
event.preventDefault();
setIsAvatarSelection(false);
}
const saveAvatarIcon = (data) => {
if(isAvatarSelection){
  setS3Key(selectedAvatarData?.imageSrcKey);
  setOpenPhotoUploadModal(false);
  setPreviewUrl('');
}else{
  let fd = new FormData();
  fd.set("userId", user?.id || userDetailsObject?.id);
  fd.set("file", image);
  fd.set("accountId", user?.accountId || userDetailsObject?.accountId);
  fd.set('fileTag',  'ProfilePicture')
  uploadCommonUtilityPicture(fd);
}
};

const handleClose= () => {
setOpenPhotoUploadModal(false);
setPreviewUrl('');
setImageUrlAvatar1('');
setImageUrlAvatar('');
setIsAvatarSelection(false);
setSelectedAvatarData(null);
setS3Key(userObject?.image)
setImageUrlAvatar(userObject?.image_PreSignedUrl)
};

useEffect(() => {
if (selectedImage) {
  setImageUrlAvatar1(URL.createObjectURL(selectedImage));
  setPreviewUrl(URL.createObjectURL(selectedImage));
  setImageUrlAvatar(URL.createObjectURL(selectedImage));
  setIsAvatarSelection(false);
}
}, [selectedImage]);

const onChangeInput = (e, type) => {
  if(type === 'firstName') {
    setFirstName(e.target.value)
  } else if(type === 'middleName') {
    setMiddleName(e.target.value)
  } else if(type === 'lastName') {
    setLastName(e.target.value)
  } else if(type === 'dob') {
    let date =  e
    if(e){
      date = moment(e).format('MM-DD-YYYY')
    }
    setDOB(date)
  } else if(type === 'ssn') {
    let ssnNum = formatters.isNumaric(e.target.value)
    setSSN(ssnNum);
  } else if(type === 'relation') {
    setRelation(e.target.value)
  } else if(type === 'phoneNumber') {
    setphoneNumber(e.target.value)
  } else if(type === 'email') {
    setEmail(e.target.value)
  }
}
const resetErrorMessage = () => {
  setDOBErr('')
  setphoneNumberErr('');
  setRelationErr('');
  setFirstNameErr('');
  setMiddleNameErr('');
  setLastNameErr('');
  setSSNErr('');
  setEmailErr('');
}
   
const onSave = () => {
    let isInValid = false;
    resetErrorMessage();
    if (!dob) {
      setDOBErr('This is required.');
      isInValid = true;
    }
    if (!phoneNumber?.trim().length) {
      setphoneNumberErr('This is required.');
      isInValid = true;
    } else if (phoneNumber?.length !== 10) {
      setphoneNumberErr('Enter valid Phone number.');
      isInValid = true;
    }
    if (!relation?.trim().length) {
      setRelationErr('This is required.');
      isInValid = true;
    }
    if (!firstName?.trim().length) {
      setFirstNameErr('This is required.');
      isInValid = true;
    }
    if (!lastName?.trim().length) {
      setLastNameErr('This is required.');
      isInValid = true;
    }
      if (ssn?.trim().length > 0 && ssn?.trim().length !== 9) {
      setSSNErr('Enter valid SSN.');
      isInValid = true;
    }
    if (!email?.trim().length) {
      setEmailErr('This is required.');
      isInValid = true;
    } else if (!formatters.isEmailValid(email)) {
      setEmailErr('Enter valid Email.');
      isInValid = true;
    }
    if( isInValid){
      return;
    }
    
  let payload = {
    firstName: firstName,
    middleName: middleName,
    lastName: lastName,
    dateOfBirth: dob,
    socialSecurityNo: ssn,
    relationshipCode: relation,
    phoneNo: phoneNumber,
    emailId: email,
    image: s3Key,
  };
  if(selectedUser?.userId){
    const newObject = {...payload}
     payload = {...userObject, ...newObject}
  }else{
    const userDetails = window.localStorage.getItem("userInfo");
    const userDetailsObject = JSON.parse(userDetails);
    const createdBy = userDetailsObject.id;
    const createdAt = userDetailsObject.createdAt;
    const accountId = userDetailsObject.accountId;
    payload.createdBy =  createdBy;
    payload.createdAt = createdAt;
    payload.updatedBy = userDetailsObject.id;
    payload.userRoleCode = "member";
    payload.statusCode =  "pending";
    payload.accountId = accountId;
  }
  updatePersonalInformation(payload);

};
  
  return (
    <Grid container sx={{width:"100% !important",m:"0px"}}>

        <Grid container spacing={2} sx={{width:"100% !important",margin:0, height: 'auto'}}>
            <Grid item xs={12} md={6} sx={{pl:onView?"0px!important":"16px",pt:onView?"0px !important":"16px",pr:onView?"0px !important":"30px"}} className="left-box pl-0">
                <div style={{position: 'relative'}}>
                    {imageUrlAvatar && (
                      <img src={imageUrlAvatar} alt='UserAvatar' className='avatar-upload-icon' style={{width: '139px', height: '139px'}} />
                    )}
                    {!selectedImage && !imageUrlAvatar &&  (
                        <img src={UserAvatar} alt='UserAvatar'  style={{width: '139px', height: '139px'}}/>
                    )}
                    <input
                    accept="image/*"
                    type="file"
                    id="select-image"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setImage(e.target.files[0]);
                      setSelectedImage(e.target.files[0])
                    }}
                    />
                    {/* <label htmlFor="select-image"> */}
                        <div style={{position: 'absolute',right: 0, top: 20, cursor: 'pointer'}} onClick={()=> {setOpenPhotoUploadModal(true)}}>
                            <img src={Edit} alt='edit' width={26}/>
                        </div>
                    {/* </label> */}
                </div>
                <div className="input-container-btm">
                <div>
                    <Typography className='font-normal-small error-message'>{dobErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Date of Birth<span style={{color:"red"}}>{" *"}</span></Typography>
                    <DatePicker 
                    minDate={new Date(1900, 0, 1)} 
                    className={`profile-input ${dobErr !== '' ? 'err-input' : ''}`} 
                    onChange={(e)=> {onChangeInput(e, 'dob')}} 
                    value={dob}
                    maxDate={new Date()}
                     />
                </div>
                <div>
                <Typography className='font-normal-small error-message'>{phoneNumberErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Phone Number<span style={{color:"red"}}>{" *"}</span></Typography>
                    <input className={`profile-input ${phoneNumberErr !== '' ? 'err-input' : ''}`} aria-label='phone number' onChange={(e)=> {onChangeInput(e, 'phoneNumber')}}  maxLength={10} value={formatters.formatPhoneNumber(phoneNumber)}/>
                </div>
                <div> 
                    <Typography className='font-normal-small error-message'>{relationErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Relationship with Plan Owner<span style={{color:"red"}}>{" *"}</span></Typography>
                    <FormControl style={{marginRight: 0, marginLeft:0, marginTop: 0, marginBottom: 20, width: '100%'}}>
                      <Select
                        labelId="demo-simple-select-label"
                        className="relation-select"
                        label="Relation"
                        value={(value) => {
                          return (
                            <Box sx={{ display: "flex", gap: 1 }}>
                              {relation}
                            </Box>
                          );
                        }}
                        onChange={(e)=> {onChangeInput(e, 'relation')}}
                        renderValue={(value) => {
                          return (
                            <Box sx={{ display: "flex", gap: 1 }}>
                              {relationship[relation]}
                            </Box>
                          );
                        }}
                
                      >
                        {
                          Object.keys(relation === 'self' ? filteredDataSelf : filteredDataNotSelf).map((item) => {
                            return ((relation === item || userDetailsObject.userRoleCode === "admin" ||userDetailsObject.userRoleCode === "owner") &&<MenuItem  value={item}>{relationship[item]}</MenuItem>)
                            })
                        }
                      </Select>
                    </FormControl>
                </div>

                  
                </div>
            </Grid>
            <Grid item xs={12} md={6} sx={{pl:onView?"0px !important":"30px",pt:onView?"0px !important":"16px"}}>
                <div>
                    <Typography className='font-normal-small error-message'>{firstNameErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>First Name<span style={{color:"red"}}>{" *"}</span> </Typography>
                    <input className={`profile-input ${firstNameErr !== '' ? 'err-input' : ''}`}  aria-label='first name'  onChange={(e)=> {onChangeInput(e, 'firstName')}} value={firstName}/>
                </div>
                <div>
                    <Typography className='font-normal-small error-message'>{middleNameErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Middle Name </Typography>
                    <input className={`profile-input ${middleNameErr !== '' ? 'err-input' : ''}`}  aria-label='first name'  onChange={(e)=> {onChangeInput(e, 'middleName')}} value={middleName}/>
                </div>
                <div>
                    <Typography className='font-normal-small error-message'>{lastNameErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Last Name <span style={{color:"red"}}>{" *"}</span></Typography>
                    <input className={`profile-input ${lastNameErr !== '' ? 'err-input' : ''}`}  aria-label='last name'  onChange={(e)=> {onChangeInput(e, 'lastName')}} value={lastName}/>
                </div>
                <div>
                    <Typography className='font-normal-small error-message'>{ssnErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Social Security Number</Typography>
                    <input className={`profile-input ${ssnErr !== '' ? 'err-input' : ''}`}  aria-label='ssn'  onChange={(e)=> {onChangeInput(e, 'ssn')}} maxLength={11} value={ssn ? formatters.formatSSNNumber(ssn): ssn}/>
                </div> 
                <div>
                <Typography className='font-normal-small error-message'>{emailErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Email ID<span style={{color:"red"}}>{" *"}</span></Typography>
                    <input disabled className={`profile-input ${emailErr !== '' ? 'err-input' : ''}`}  aria-label='email'  onChange={(e)=> {onChangeInput(e, 'email')}} value={email}/>
                </div>
            </Grid>
            <div className="action-holder action-bg">
                <Button variant="outlined" className="primary-font-actionbtn action-btn profile-cancel-btn" onClick={()=> {navigate('/my-family'); dispatch({type: "RESET_MY_INFO", action: null})}}>Cancel</Button>
                <Button variant="outlined" className="primary-font-actionbtn action-btn profile-save-btn" onClick={()=> {onSave()}}>Save & Continue</Button>
            </div>
        </Grid>
        {openPhotoUploadModal && (
          <Dialog
          open={openPhotoUploadModal}
          onClose={handleClose}
          className="profile-add-photo-dialog"
          aria-labelledby="photo-dialog-title"
          aria-describedby="photo-dialog-description"
          >
              <DialogTitle id="photo-dialog-title" sx={{display:"flex",justifyContent:"space-between"}}>
                  <Typography className='primary-font-bold1'>Photo upload</Typography>
              {openPhotoUploadModal ? (
                  <img src={CloseIcon} alt="close-icon" style={{cursor: 'pointer',width:"21px"}} onClick={handleClose} sx={{
                      marginRight: '-30px',
                  }}/>
                  ) : null}
              </DialogTitle>
              <Divider />
              <DialogContent className="profile-add-dialog-content">
              <Grid container spacing={2} style={{margin:0, height: '100%',width:"100%"}}>
                <Grid  xs={12} md={!dob ? 7 : dob && (formatters.calculateAge(dob) > 17 ) ? 7 : 12} style={{padding: 0, height: '100%', overflowY: 'scroll'}}>
                  <Typography className='font-normal-small profile-input-label'>Choose Avatar</Typography>
                  <div>
                    {AvatarIcon.map((item, i)=> {
                      return (
                       <div className='avatar-photo-icon'
                       onClick={() => {
                        setImageUrlAvatar1(item.imgSrc); 
                        setPreviewUrl(item.imgSrc);
                        setIsAvatarSelection(true)
                        setSelectedAvatarData(item)
                        setImageUrlAvatar(item.imgSrc)
                      }}
                      >
                        {" "}
                         <Button>
                          <img  src={item.imgSrc} alt={item.imgAlt} /></Button> {" "}
                      </div>
                      )
                    })}
                  </div>
                </Grid>
                {!dob || (dob && (formatters.calculateAge(dob) > 17)) && (
                  <Grid item xs={12} md={5} style={{paddingLeft: 0}}>
                  {/* <label htmlFor="select-image">  
                {onView?
                    <div
                      className="profile-add-photo-upload-container drop_zone"
                      onDragOver={(e) => { e.preventDefault(); setIsAvatarSelection(false); }}
                      onDrop={handleOnDrop}
                      onClick={handleClick}
                      style={{ cursor: 'pointer' }}
                    >
                        <input
                          accept="image/*"
                          type="file"
                          id="select-image"
                          style={{ display: "none" }}
                          ref={fileInputRef}
                          onChange={(e) => { setSelectedImage(e.target.files[0]); handleFile(e.target.files[0]); }}
                        />
                      <img src={UploadImage} alt="upload-avatar" />
                      <Typography className="font-normal-small profile-input-label">
                        Drag and drop a file here or click
                      </Typography>
                      {previewUrl && (
                        <div className="image">
                          <img src={previewUrl} alt="image" width={100} height={100} />
                        </div>
                      )}
                    </div>:<div className="profile-add-photo-upload-container drop_zone" onDragOver={handleDragOver} onDrop={handleOnDrop}>
                    <img  src={UploadImage} alt={'upload-avatar'} style={{cursor: 'pointer'}} />
                    <Typography className='font-normal-small profile-input-label'>Drag and drop a file here or click</Typography>
                    { previewUrl && <div className="image">
                      <img src={previewUrl} alt='image' width={100} height={100}/> 
                    </div> }
                  </div>}                 
                  </label> */}
                  <input
            accept="image/*"
            type="file"
            id="select-image"
            style={{ display: "none" }}
            ref={fileInputRef}
            // disabled={hideEdit} 
            onChange={(e) => { setSelectedImage(e.target.files[0]); handleFile(e.target.files[0]); }}
          />
          {/* <label htmlFor="select-image" > */}
            <div className="photo-upload-container   drop_zone" style={{ height: 272, marginBottom: 30 }} onDragOver={handleDragOver} onDrop={handleOnDrop} onClick={triggerFileInput} >
            <div> <img src={UploadImage} alt={'upload-avatar'} style={{ cursor: 'pointer' }}  />
            <Typography className='primary-font-normal-3 profile-input-label' color='#ABAFB3'>Drag and drop a file here <br />or click to <span style={{ color: '#4979FF' }}>upload</span></Typography> </div> 
            {previewUrl ? <div className="image">
                <img src={previewUrl}alt="image" width={100} height={100} />
              </div> :  <></>}
            </div>
                </Grid>
                )}
                
              </Grid>
              </DialogContent>
              <Divider />
              <DialogActions sx={{p:"0px"}}>
              <div className="action-holder-2" style={{marginTop: 20, marginBottom: 20}}>
                  <Button variant="outlined" className="primary-font-bold-5 action-btn profile-cancel-btn" onClick={handleClose}>Cancel</Button>
                  <Button variant="outlined" className="primary-font-bold-5 action-btn profile-save-btn" onClick={saveAvatarIcon}>Save</Button>
              </div>
              </DialogActions>
          </Dialog>
        )}
      
    </Grid>
  );
};

AddPersonalInfo.defaultProps = {
  isFetching: null,
  error: null,
  updatedPersonalInfo: null
};

function mapStateToProps(state) {
  return {
      isFetching: state.familyInfo.isFetching,
      error: state.familyInfo.error,
      updatedPersonalInfo: state.familyInfo.updatedPersonalInfo,
      relationship: state.setting.relationship,
      updatedSpecificUserData: state.familyInfo.updatedSpecificUserData,
      profilePictureData: state.familyInfo.profilePictureData,
      profilePictureError: state.familyInfo.profilePictureError
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updatePersonalInformation,
      getSpecficUserProfile,
      getRelationshipList,
      createCognitoUser,
      uploadCommonUtilityPicture,
      getProfileInfo
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPersonalInfo);
