import React, { useState } from "react";
import "../../../../../../index.css";
import '../../../styles/info.css';
import { Grid, Typography, Tab, Tabs, Box } from "@mui/material";
import ContentWrapper from '../../../../../../common/components/ContentWrapper';
import { useNavigate } from "react-router-dom";
import AddPersonalInfo from './AddPersonalInfo';
import MilitaryService from './MilitaryService';
import SpecialInstructions from './SpecialInstructions';
import FuneralArrangements from './FuneralArrangements';
import CemeteryArrangements from './CemeteryArrangements';
import DemographicInfo from './DemographicInfo';
import RelativesToNotify from './RelativesToNotify';
import CheckIcon from '../../../../../../assets/images/CheckIcon.svg';
import {useLocation} from 'react-router-dom';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
         </Box>
      )}
    </div>
  );
}


const AddFamilyMembersInfo = ({ goback = true, route }) => {
  const selectedUser = window.localStorage.getItem('selectedUser') ? JSON.parse(window.localStorage.getItem('selectedUser')) : {}
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [completedTabs, setCompletedTabs] = useState([]);
  const [currentTab, setCurrentTab] = useState(null);
  const [mobileview,setmobileView]=useState(true)
  const [mobileviewcontent,setmobileViewcontent]=useState(true)
  const [val, setVal] = useState();

  const a11yProps = (index) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    // Mark the previous tab as completed when switching tabs
    if(selectedUser && selectedUser.userId){
      setValue(newValue);
      const completed = []
     for(let i=0;i<newValue;i++){
       completed.push(i);
     }
       setCompletedTabs(completed);
    }
   
  };
  const handleChange1 = (newValue) => {
    // Mark the previous tab as completed when switching tabs
    if(selectedUser && selectedUser.userId){
      setVal(newValue);
      setmobileView(false)
      const completed = []
     for(let i=0;i<=newValue;i++){
       completed.push(i);
     }
       setCompletedTabs(completed);
    }
   
  };
  const handleContinue = (e) => {
    // Handle navigation to the next tab
    // setCompletedTabs([...completedTabs, value]);
    const previousValue = value;
    const newValue = previousValue+1;
    const completed = []
    for(let i=0;i<newValue;i++){
      completed.push(i);
    }
      setCompletedTabs(completed);
    setValue(newValue);
   
  };
  const handleContinue1 = () => {

    const previousValue = val;
    const newValue = previousValue+1;
    const completed = []
    for(let i=0;i<newValue;i++){
      completed.push(i);
    }
      setCompletedTabs(completed);
      setVal(newValue);
      
    // setValue(newValue);
setmobileView(true)


  };
 
  console.log(val,"val111111111")
  const handleCancel = (e) => {
    // Handle navigation to the previous tab
    // const updatedSteps = completedTabs.filter((step) => step !== value);
    // setCompletedTabs(updatedSteps);
   
    const previousValue = value;
    const newValue = previousValue - 1;
    setValue(newValue);
    const completed = []
    for(let i=0;i<newValue;i++){
      completed.push(i);
    }
      setCompletedTabs(completed);
  };
  const handleCancel1 = (e) => {
    // Handle navigation to the previous tab
    // const updatedSteps = completedTabs.filter((step) => step !== value);
    // setCompletedTabs(updatedSteps);
   
    // const previousValue = val;
    // const newValue = previousValue - 1;
    // setVal(newValue);
    // const completed = []
    // for(let i=0;i<newValue;i++){
    //   completed.push(i);
    // }
    //   setCompletedTabs(completed);
      setmobileView(true)
  };
 
  return (
    <Grid container >
    <div className="info-header-container" >

        <div className="bigsize-device ">
      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        className="box-container"
        style={{boxShadow: 'none', paddingLeft: 0}}
      >
        <Box sx={{ flexGrow: 1, display: 'flex'}}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="info tabs"
          className="vertical-tabs primary-font-medium-small"
        >
          <Tab className={`primary-font-medium-small ${completedTabs.includes(0) ? 'row-reverse' : ''}`} label="Personal Info" {...a11yProps(0)} icon={completedTabs.includes(0) ? <img src={CheckIcon} alt='image'/> : null} />
          <Tab className={`primary-font-medium-small ${completedTabs.includes(1) ? 'row-reverse' : ''}`}  label="Demographic Info" {...a11yProps(1)} icon={completedTabs.includes(1) ? <img src={CheckIcon} alt='image'/> : null} />
          <Tab className={`primary-font-medium-small ${completedTabs.includes(2) ? 'row-reverse' : ''}`}  label="Military Service" {...a11yProps(2)} icon={completedTabs.includes(2) ? <img src={CheckIcon} alt='image'/>  : null} />
          <Tab className={`primary-font-medium-small ${completedTabs.includes(3) ? 'row-reverse' : ''}`}  label="Relatives to Notify" {...a11yProps(3)} icon={completedTabs.includes(3) ? <img src={CheckIcon} alt='image'/>  : null} />
          <Tab className={`primary-font-medium-small ${completedTabs.includes(4) ? 'row-reverse' : ''}`}  label="Funeral Arrangements" {...a11yProps(4)} icon={completedTabs.includes(4) ? <img src={CheckIcon} alt='image'/>  : null} />
          <Tab className={`primary-font-medium-small ${completedTabs.includes(5) ? 'row-reverse' : ''}`}  label="Cemetery Arrangements" {...a11yProps(5)} icon={completedTabs.includes(5) ? <img src={CheckIcon} alt='image'/>  : null} />
          <Tab className={`primary-font-medium-small ${completedTabs.includes(6) ? 'row-reverse' : ''}`}  label="Special Instructions" {...a11yProps(6)} icon={completedTabs.includes(6) ? <img src={CheckIcon} alt='image'/>  : null} />
        </Tabs>
        <TabPanel value={value} index={0} className='info-tabs'>
          <AddPersonalInfo onContinue={handleContinue} onCancel={handleCancel} selectedUser={selectedUser} />
        </TabPanel>
        <TabPanel value={value} index={1} className='info-tabs'>
          <DemographicInfo onContinue={handleContinue} onCancel={handleCancel} selectedUser={selectedUser} />
        </TabPanel>
        <TabPanel value={value} index={2} className='info-tabs'>
          <MilitaryService onContinue={handleContinue} onCancel={handleCancel}  selectedUser={selectedUser}/>
        </TabPanel>
        <TabPanel value={value} index={3} className='info-tabs'>
          <RelativesToNotify onContinue={handleContinue} onCancel={handleCancel}  selectedUser={selectedUser}/>
        </TabPanel>
        <TabPanel value={value} index={4} className='info-tabs'>
          <FuneralArrangements onContinue={handleContinue} onCancel={handleCancel}  selectedUser={selectedUser}/>
        </TabPanel>
        <TabPanel value={value} index={5} className='info-tabs'>
          <CemeteryArrangements onContinue={handleContinue} onCancel={handleCancel} selectedUser={selectedUser} />
        </TabPanel>
        <TabPanel value={value} index={6} className='info-tabs'>
          <SpecialInstructions onContinue={handleContinue} onCancel={handleCancel} selectedUser={selectedUser} />
        </TabPanel>
      </Box>
      </Grid>
      </div>
     
      <div className="smallsize-device">

<Box sx={{ display: 'flex', flexDirection:"column", display:mobileview?"block":"none",mb:"30px"}}>

<Box   sx={{ display:"flex",justifyContent:"space-between",cursor:"pointer",alignItems:"center",borderRadius: "10px",boxShadow:3 ,  height:"50px",alignContent:"center" ,px:"20px",mt:"30px", '&:hover': {
    color:"#FF3535",pl:"20px"
  }}} 
   onClick={() => {
   handleChange1(0);
    
   
  }}
>
  <Typography className="primary-font-medium-small " > Personal Info</Typography>
  {completedTabs.includes(0)? <img src={CheckIcon} alt='image' style={{height:"30px",width:"30px"}}/> : null}
</Box>
<Box   sx={{ display:"flex",justifyContent:"space-between",cursor:"pointer",alignItems:"center",borderRadius: "10px",boxShadow:3 ,  height:"50px",alignContent:"center" ,px:"20px",mt:"30px", '&:hover': {
    color:"#FF3535",pl:"20px"
  }}} 
   onClick={() => {
   handleChange1(1);
    
   
  }}
>
  <Typography className="primary-font-medium-small " > Demographic Info</Typography>
  {completedTabs.includes(1)? <img src={CheckIcon} alt='image' style={{height:"30px",width:"30px"}}/> : null}
</Box>
<Box   sx={{ display:"flex",justifyContent:"space-between",cursor:"pointer",alignItems:"center",borderRadius: "10px",boxShadow:3 ,  height:"50px",alignContent:"center",alignItems:"center" ,px:"20px",mt:"30px", '&:hover': {
    color:"#FF3535",pl:"20px"
  }}} 
   onClick={() => {
   handleChange1(2);
    
   
  }}
>
  <Typography className="primary-font-medium-small " >Military Service</Typography>
  {completedTabs.includes(2)? <img src={CheckIcon} alt='image' style={{height:"30px",width:"30px"}}/> : null}
</Box>
<Box   sx={{ display:"flex",justifyContent:"space-between",cursor:"pointer",alignItems:"center",borderRadius: "10px",boxShadow:3 ,  height:"50px",alignContent:"center" ,px:"20px",mt:"30px", '&:hover': {
    color:"#FF3535",pl:"20px"
  }}} 
   onClick={() => {
   handleChange1(3);
    
   
  }}
>
  <Typography className="primary-font-medium-small " >Relatives to Notify</Typography>
  {completedTabs.includes(3)? <img src={CheckIcon} alt='image' style={{height:"30px",width:"30px"}}/> : null}
</Box>
<Box   sx={{ display:"flex",justifyContent:"space-between",cursor:"pointer",alignItems:"center",borderRadius: "10px",boxShadow:3 ,  height:"50px",alignContent:"center" ,px:"20px",mt:"30px", '&:hover': {
    color:"#FF3535",pl:"20px"
  }}} 
   onClick={() => {
   handleChange1(4);
    
   
  }}
>
  <Typography className="primary-font-medium-small " >Funeral Arrangements</Typography>
  {completedTabs.includes(4)? <img src={CheckIcon} alt='image' style={{height:"30px",width:"30px"}}/> : null}
</Box>
<Box   sx={{ display:"flex",justifyContent:"space-between",cursor:"pointer",alignItems:"center",borderRadius: "10px",boxShadow:3 ,  height:"50px",alignContent:"center" ,px:"20px",mt:"30px", '&:hover': {
    color:"#FF3535",pl:"20px"
  }}} 
   onClick={() => {
   handleChange1(5);
    
   
  }}
>
  <Typography className="primary-font-medium-small " >Cemetery Arrangements</Typography>
  {completedTabs.includes(5)? <img src={CheckIcon} alt='image' style={{height:"30px",width:"30px"}}/> : null}
</Box>
<Box   sx={{ display:"flex",justifyContent:"space-between",cursor:"pointer",alignItems:"center",borderRadius: "10px",boxShadow:3 ,  height:"50px",alignContent:"center" ,px:"20px",mt:"30px", '&:hover': {
    color:"#FF3535",pl:"20px"
  }}} 
   onClick={() => {
   handleChange1(6);
    
   
  }}
>
  <Typography className="primary-font-medium-small " >Special Instructions</Typography>
  {completedTabs.includes(6)? <img src={CheckIcon} alt='image' style={{height:"30px",width:"30px"}}/> : null}
</Box>
</Box >
<Box sx={{display:mobileview?"none":"block",mt:"30px"}}>

{val==0 && <AddPersonalInfo   onContinue={handleContinue1} onCancel={handleCancel1} selectedUser={selectedUser} onView={mobileviewcontent} />}
{val==1 && <DemographicInfo onContinue={handleContinue1} onCancel={handleCancel1} selectedUser={selectedUser} onView={mobileviewcontent} />}
{val==2 && <MilitaryService onContinue={handleContinue1} onCancel={handleCancel1} selectedUser={selectedUser} onView={mobileviewcontent} />}
{val==3 && <RelativesToNotify   onContinue={handleContinue1} onCancel={handleCancel1} selectedUser={selectedUser} onView={mobileviewcontent} />}
{val==4 && <FuneralArrangements onContinue={handleContinue1} onCancel={handleCancel1} selectedUser={selectedUser} onView={mobileviewcontent} />}
{val==5 && <CemeteryArrangements onContinue={handleContinue1} onCancel={handleCancel1} selectedUser={selectedUser} onView={mobileviewcontent} />}
{val==6 && <SpecialInstructions   onContinue={handleContinue1} onCancel={handleCancel1} selectedUser={selectedUser} onView={mobileviewcontent} />}
</Box>


      {/* </Box> */}

      {/* </Grid> */}
      </div>
      
    
      {/* <Grid item xs={1} md={1}></Grid> */}
    
    </div>
  </Grid>
  );
};

const AddInformationComponent = () => {
    return (
      <ContentWrapper title={"My Family"} goback={true} img={false} gobackUrl={'/my-family'} contenview={<AddFamilyMembersInfo />} />
    )
  }

export default AddInformationComponent;
