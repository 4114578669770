import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";
import CloseIcon from "../../../../assets/icons/modal-close-icon.svg";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { sendOtp, setSecurityLock, VerifyOtp } from "../redux/actions";
import { bindActionCreators } from "redux";
import {  connect } from "react-redux";
import * as formatters from "../../../../utils/util"

const ResetSecurityLock = ({setSecurityLock,sendOtp,otpSent,VerifyOtp,isVerifySecurityLockOtp,lockData}) => {
  const navigate = useNavigate();
  const [code, setCode] = useState(null);
  const [newSecurityLock, SetNewSecurityLock] = useState(null);
  const [confirmNewSecurityLock, setConfirmNewSecurityLock] = useState(null);
  const [codeErr, setCodeErr] = useState("");
  const [newSecurityLockErr, SetnewSecurityLockErr] = useState("");
  const [confirmNewSecurityLockErr, setConfirmNewSecurityLockErr] =
    useState("");

  const user = window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem('userInfo')) : null;
  const [showSecurityLock, setShowSecurityLock] = useState(false);
  const [showConfirmSecurityLock, setShowConfirmSecurityLock] = useState(false);
  const [otpVerifed, setOtpVerifed] = useState(false);
  const [openModal, setOpenModal] = useState(true);

  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);
  const[queryObject, setQueryObject] = useState(null)

  const[isQueryParams, setIsQueryParams] = useState(false);
  const urlString = window.location.href;
  useEffect(() => {
    const parts = urlString.split("?");
    if (parts.length > 1) {
      const queryString = parts[1];
      const data = queryString.split("&");
      console.log("queryParams",data);
      const paramsObj = {
        uuid: data[0].split('uuid=')[1],
        otp: data[1].split('otpCode=')[1]
      };
  
      setQueryObject(paramsObj);
      setCode(paramsObj.otp)
      setIsQueryParams(true);
  
    } 
  }, [urlString]);

  useEffect(() => {
    if (isQueryParams && queryObject) {
      const timer = setTimeout(() => {
        VerifySecuritylockOtp();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isQueryParams, queryObject]);

  useEffect(()=>{
    if(lockData && lockData.status === 200){
      setOpenModal(false);
      navigate('/home')
    }else{
      // SetnewSecurityLockErr(lockData?.message);
    }
  },[lockData])

  const VerifySecuritylockOtp = () => {
    const userDetails = window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem('userInfo')) : null
    const payload = {
      "userId": userDetails ? userDetails?.id : null,
      "otpCode": queryObject ? parseInt(queryObject.otp) : parseInt(code),
      "userIdEncrypted": queryObject ? queryObject.uuid : null
    };
    VerifyOtp(payload)
  }



  const resendSecurityLockOTP = () => {
    const userDetails = window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem('userInfo')) : null
    const payload = {
      userId : userDetails?.id
    }
    sendOtp(payload)
  }


  
  useEffect(()=>{
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
  
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(interval);
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, 1000);
  
      return () => {
        clearInterval(interval);
      };
  },[ seconds])

  useEffect(()=>{
    console.log(isVerifySecurityLockOtp )
    if(isVerifySecurityLockOtp && isVerifySecurityLockOtp.status === 200 && isVerifySecurityLockOtp.message === "Otp is not valid"){
      setCodeErr('Invalid OTP')
    }else if(isVerifySecurityLockOtp && isVerifySecurityLockOtp.status === 200){
      setOtpVerifed(true);
    }

  },[isVerifySecurityLockOtp])

  useEffect(() => {
    
  }, [seconds]);


  const handleClickShowSecurityLock = () =>
    setShowSecurityLock(!showSecurityLock);
  const handleClickConfirmShowSecurityLock = () =>
    setShowConfirmSecurityLock(!showConfirmSecurityLock);


  const handleMouseDownSecurityLock = () =>
    setShowSecurityLock(!showSecurityLock);
  const handleMouseDownConfirmSecurityLock = () =>
    setShowConfirmSecurityLock(!showConfirmSecurityLock);

  const onChangeSecurityLock = (e, type) => {
    const code = formatters.formatNumber(e.target.value)
    if (type === "code") {
      setCode(code);
    } else if (type === "newSecurityLock") {
      SetNewSecurityLock(code);
    } else if (type === "confirmNewSecurityLock") {
      setConfirmNewSecurityLock(code);
    }
  };

  const resetErrorMessages = () =>{
    SetnewSecurityLockErr('');
    setConfirmNewSecurityLockErr('');
  }

  const handleSaveSecurityLock = () => {
    let isInValid = false;
    resetErrorMessages();

    if (!newSecurityLock?.trim().length) {
      SetnewSecurityLockErr("This is requried.");
      isInValid = true;
      return;
    } else if (newSecurityLock?.trim().length !== 6){
      SetnewSecurityLockErr("Security Lock should contian 6 digits.")
      isInValid = true;
      return;
    }
     else {
      SetnewSecurityLockErr("");
    }

    if (!confirmNewSecurityLock?.trim().length) {
      setConfirmNewSecurityLockErr("This is requried");
      isInValid = true;
      return;
    } else if (newSecurityLock !== confirmNewSecurityLock) {
      setConfirmNewSecurityLockErr(
        "New Security lock and confirm security lock mismatch."
      );
      isInValid = true;
      return;
    } else {
      setConfirmNewSecurityLockErr("");
    }

    const payload ={
      securityLock : newSecurityLock,
      emailId: user?.emailId,
      id:user?.id,
      updatedBy: user?.id
    }
    // console.log(payload);

    setSecurityLock(payload);
    
  };

 
  

  const handleVerifyOtp = () => {
    if (!code || code?.trim().length === 0) {
      setCodeErr("This is required.");
      return;
    } else if (code?.trim().length !== 4) {
      setCodeErr("Code should contian 4 digits.");
      return;
    } else {
      setCodeErr("");
    }
    VerifySecuritylockOtp()
  };

  const handleClose = () => {
    setOtpVerifed(false);
    navigate('/home')
  };
  const resendOTP = () => {
    setMinutes(1);
    setSeconds(30);
    resendSecurityLockOTP()
  };

  return (
    <>
      <Dialog open={openModal} 
      className={`${otpVerifed ? "reset-dialog dialog-box" : ""}`}
      >
        <DialogTitle>
          {otpVerifed ? (
            <div>
              {/* <div style={{ float: "right" }}>
                <img
                  src={CloseIcon}
                  alt="closeIcon"
                  onClick={handleClose}
                  style={{ cursor: "pointer", marginLeft: "40px" }}
                />
              </div> */}
              <div style={{ float: "left" }}>
                <Typography
                  variant="body1"
                  className="primary-font-bold-heading1"
                  style={{ marginTop: "-20px" }}
                >
                  {" "}
                  Change Security Lock
                </Typography>
              </div>
            </div>
          ) : (
            <div>
              {/* <div style={{ float: "right" }}>
                <img
                  src={CloseIcon}
                  alt="closeIcon"
                  onClick={handleClose}
                  style={{ cursor: "pointer", marginLeft: "40px" }}
                />
              </div> */}
              <div style={{ float: "left" }}>
                <Typography
                  variant="body1"
                  className="primary-font-bold-heading1"
                  style={{ marginTop: "-20px" }}
                >
                  {" "}
                  Forgot Security Lock
                </Typography>
              </div>
            </div>
          )}
        </DialogTitle>
        <Divider />
        <DialogContent style={{padding : otpVerifed ?"32px 26px" : ""}}>
          {otpVerifed ? (
            <>
              <div>
                <Typography className="font-normal-small error-message">
                  {newSecurityLockErr}
                </Typography>
                <Typography className="font-normal-small profile-input-lable">
                  New Security Lock
                </Typography>
                <div className="input-holder">
                <form autocomplete="off">
                  <input
                    className={`profile-input ${
                      newSecurityLockErr !== "" ? "err-input" : ""
                    }`}
                    style={{marginBottom:0}}
                    aria-label="new-security lock"
                    type={showSecurityLock ? "text" : "password"}
                    onChange={(e) => {
                      onChangeSecurityLock(e, "newSecurityLock");
                    }}
                    maxLength={6}
                    value={newSecurityLock}
                    autocomplete="off"
                  />
                  
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="security-lock"
                    onClick={handleClickShowSecurityLock}
                    onMouseDown={handleMouseDownSecurityLock}
                    className="input-eye-icon pwd-icon1"
                  >
                    {showSecurityLock ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                  </form>
                </div>
              </div>
              <br />
              <div>
                <Typography className="font-normal-small error-message">
                  {confirmNewSecurityLockErr}
                </Typography>
                <Typography className="font-normal-small profile-input-lable">
                  Confirm New Security Lock
                </Typography>
                <div className="input-holder">
                <form autocomplete="off">
                  <input
                    className={`profile-input ${
                      confirmNewSecurityLockErr !== "" ? "err-input" : ""
                    }`}
                    aria-label=" confirm new-security lock"
                    type={showConfirmSecurityLock ? "text" : "password"}
                    onChange={(e) => {
                      onChangeSecurityLock(e, "confirmNewSecurityLock");
                    }}
                    maxLength={6}
                    autocomplete="off"
                    value={confirmNewSecurityLock}
                  />
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="security-lock"
                    onClick={handleClickConfirmShowSecurityLock}
                    onMouseDown={handleMouseDownConfirmSecurityLock}
                    className="input-eye-icon pwd-icon1"
                  >
                    {showConfirmSecurityLock ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                  </form>
                </div>
              </div>
              <br />
              <div
                className="action-holder"
                style={{ marginTop: 20, marginBottom: 0 }}
              >
                <Button
                  variant="outlined"
                  style={{ width: "100%" }}
                  className="primary-font-bold-6 action-btn profile-save-btn"
                  onClick={handleSaveSecurityLock}
                >
                  Change Security Lock
                </Button>
              </div>
            </>
          ) : (
            <>
             
              <Typography className="primary-font-normal-2" component="div" style={{marginBottom: 25}}>
                  We have sent a reset security lock code to your email Id<b> {user?.emailId } .</b> Enter it below to reset your security lock.
                </Typography>
              <br />
              <div>
                <Typography>We have emailed you a 6 digit verification code. Please check your email Inbox.</Typography>
                <Typography className="font-normal-small error-message">
                  {codeErr}
                </Typography>
                <Typography className="font-normal-small profile-input-label">
                  Code
                </Typography>
                <div className="input-holder">
                  <input
                    className={`profile-input ${
                      codeErr !== "" ? "err-input" : ""
                    }`}
                    aria-label="new-pin"
                    maxLength={4}
                    onChange={(e) => {
                      onChangeSecurityLock(e, "code");
                    }}
                    value={code}
                    disabled = {isQueryParams}
                    style={{backgroundColor: isQueryParams ? "#eee" : "#fff"}}
                  //  placeholder="Enter the 6 digit OTP"
                  />
                </div>
              </div>
              
              {isQueryParams && <div
                className="action-holder"
                style={{ marginTop: 20, marginBottom: 0 }}
              >
                <Button
                  variant="outlined"
                  style={{ width: "100%",opacity:"0.5" }}
                  className="primary-font-bold-6 action-btn profile-save-btn"
                  // onClick={handleVerifyOtp}
                  disabled
                >
                  verifying...
                </Button>
              </div>}
              {!isQueryParams && <>
                <div
                className="action-holder"
                style={{ marginTop: 20, marginBottom: 0 }}
              >
                <Button
                  variant="outlined"
                  style={{ width: "100%" }}
                  className="primary-font-bold-6 action-btn profile-save-btn"
                  onClick={handleVerifyOtp}
                >
                  Verify OTP
                </Button>
              </div>
                <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {seconds > 0 || minutes > 0 ? (
                  <p>
                    Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </p>
                ) : (
                  <p>Didn't recieve code?</p>
                )}

                <button
                  disabled={seconds > 0 || minutes > 0}
                  style={{
                    color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630",
                    backgroundColor: "#fff",
                    border: "none",
                    fontWeight: 500,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={resendOTP}
                  // className="primary-font-bold-6 action-btn profile-save-btn"
                >
                  Resend OTP
                </button>
              </div></>}
              
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

ResetSecurityLock.defaultProps = {
    lockData: null,
    lockEror: null,
    isverifyingLock: null,
}

function mapStateToProps(state) {
  return{
    lockData: state.home.lockData,
    lockError: state.home.lockError,
    isverifyingLock: state.home.isverifyingLock,
    otpSent: state.home.isOTPSent,
    isVerifySecurityLockOtp: state.home.isVerifySecurityLockOtp
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setSecurityLock,
      sendOtp,
      VerifyOtp
    },
    dispatch
  )
}

export default connect(mapStateToProps,mapDispatchToProps)(ResetSecurityLock);

