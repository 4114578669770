import React from "react";
import ContentWrapper from "../../../../../common/components/ContentWrapper";
import { Grid } from "@mui/material";
import ButtonThemeSelector from "./ButtonThemeSelector";
const MainLayout = ({ goback = true, showEdit = true, showDelete = true }) => {
  return (
    <Grid container sx={{ m: 0, width: "100%" }}>
      {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          backgroundolor: "#fff",
          boxShadow: "0px 1px 12px #00000026",
        }}
      >
        <Grid className="emergency-container">
          <ButtonThemeSelector />
        </Grid>
      </Grid>

      {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
    </Grid>
  );
};

const SystemBranding = () => {
  return (
    <ContentWrapper
      title={"Customize Emergency Tile"}
      gobackUrl={"/system-branding"}
      goback={true}
      contenview={<MainLayout />}
    />
  );
};
export default SystemBranding;
