import accident from '../../../../assets/images/need-help/accident.png';
import activeShooter from '../../../../assets/images/need-help/Active-Shooter.png';
import Emergency from '../../../../assets/images/need-help/Emergency.png';
import HomeInvasion from '../../../../assets/images/need-help/Home-Invasion.png';
import Kidnap from '../../../../assets/images/need-help/Kidnap.png';

const data = [
    {
     image_url: activeShooter,
     imageAlt : "activeShooter",
     name: "Active Shooter",
     redirection: "/need-help/activate",
     toRedirct: true,
     id: 1
    },
    {
     image_url: accident,
     imageAlt: "accident",
     name: "I have an accident",
     redirection:"/need-help/activate",
     toRedirct: true,
     id:2
    },
    {
     image_url: Emergency,
     imageAlt: "Emergency",
     name: "Emergency",
     redirection:"/need-help/activate",
     toRedirct: true,
     id:3
    },
    {
     image_url: HomeInvasion,
     imageAlt: "HomeInvasion",
     name: "Home Invasion",
     redirection:"/need-help/activate",
     toRedirct: true,
     id:4
    },
    {
        image_url: Kidnap,
        imageAlt: "I have been taken",
        name: "I have been taken",
        redirection:"/need-help/activate",
        toRedirct: true,
        id:5
       }
 ]

 export default data;