const initialState = {
    error: null,
    addEmergencyAlert:null,
    isFetching: false,
    data: null,
    emergencyList: null,
    emergencyListData: null,
    deleteEmergecyAlert: null,
    unreadNotificationCount: 0,
    notificationList: [],
    updateStatus: null
};

export const emergencyAlertReducer = (state = initialState, action) => {
    const { type, payload } = action;
    let currentState = state;
    switch (type) {
        case 'ADD_EMERGENCY_ALERT':
            currentState = {
                ...state,
                data: null,
                deleteEmergecyAlert: null,
                addEmergencyAlert: payload
            };
            break;
        case 'SECURITY_Pin_VERIFY_SUCCESS':
            currentState = {
                ...state,
                verifyPinData: payload,
                isVerifyingPin: false,
                verifyPinError: null,
            };
            break;
            case 'AEA_REQUEST':
                currentState = {
                    ...state,
                    isFetching: true,
                };
                break;
            case 'AEA_SUCCESS':
                currentState = {
                    ...state,
                    isFetching: false,
                    data: payload
                };
                break;
            case 'AEA_DATA_SUCCESS':
                currentState = {
                    ...state,
                    isFetching: false,
                    emergencyList: payload
                };
                break;
            case 'AEA_DATA_SUCCESS_OBJECT':
                currentState = {
                    ...state,
                    isFetching: false,
                    emergencyListData: payload
                };
                break;
            case 'AEA_USER_STATUS_SUCCESS':
                currentState = {
                    ...state,
                    isFetching: false,
                    updateStatus: payload
                };
                break;
            case 'AEA_DELETE_SUCCESS_OBJECT':
                currentState = {
                    ...state,
                    isFetching: false,
                    deleteEmergecyAlert: payload
                };
                break;
            case 'NOTIFICATION_UNREAD_COUNT_SUCCESS':
                currentState = {
                    ...state,
                    isFetching: false,
                    unreadNotificationCount: payload
                };
                break;
            case 'NOTIFICATION_LIST_SUCCESS':
                currentState = {
                    ...state,
                    isFetching: false,
                    notificationList: payload
                };
                break;
        default:
    }
    return currentState;
};
