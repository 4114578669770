import React from 'react'
import ContentWrapper from '../../../../../common/components/ContentWrapper';
import EmergencyServices from './EmergencyServices'

const FamilyMembersComponent = () => {
  return (
    <ContentWrapper title={"Emergency Services"} gobackUrl={'/home'} goback={true} img={false} contenview={<EmergencyServices />} />
  )
}

export default FamilyMembersComponent;