const initialState = {
    data: null,
    isFetching: false,
    error: null,
    forgotPasswordData: null,
    forgotPasswordError: null,
    isFetchingFP : false,
    fpBackendData : null,
    fpBackendError: null,
    linkExpiryCheck: null,
    linkExpiryError: null
  };
  
  export const forgotPasswordReducer = (state = initialState, action) => {
    const {type, payload} = action;
    let currentState = state;
    switch(type) {
        case 'FORGOT_PASSWORD_REQUEST': 
        currentState = {
          ...state,
          isFetching: true,
          error: null,
        };
        break;
        case 'FORGOT_PASSWORD_SUCCESS': 
        currentState = {
          ...state,
          data: payload,
          isFetching: false,
          error: null,
        };
        break;
        case 'FORGOT_PASSWORD_FAILURE': 
        currentState = {
          ...state,
          isFetching: false,
          error: payload,
        };
        break;
        case 'FORGOT_PASSWORD_RESET_REQUEST': 
        currentState = {
          ...state,
          isFetchingFP: true,
          error: null,
        };
        break;
        case 'FORGOT_PASSWORD_RESET_SUCCESS': 
        currentState = {
          ...state,
          forgotPasswordData: payload,
          isFetchingFP: false,
          error: null,
        };
        break;
        case 'FORGOT_PASSWORD_RESET_FAILURE': 
        currentState = {
          ...state,
          isFetchingFP: false,
          forgotPasswordError: payload,
        };
        break;
        case 'FORGOT_PASSWORD_SYSTEM_SUCCESS': 
        currentState = {
          ...state,
          fpBackendData: payload,
          fpBackendError: null,
        };
        break;
        case 'FORGOT_PASSWORD_SYSTEM_FAILURE': 
        currentState = {
          ...state,
          fpBackendData: null,
          fpBackendError: payload,
        };
        break;
        case 'RESET_FORGOT_PASSWORD': 
        currentState = {
          data: null,
          isFetching: false,
          error: null,
          forgotPasswordData: null,
          forgotPasswordError: null,
          isFetchingFP : false,
          fpBackendData : null,
          fpBackendError: null 
        }
        break;
        case 'INVITE_LINK_EXPIRERATION_REQUEST': 
        currentState = {
          ...initialState,
          isFetchingFP: true,
        };
        break;
        case 'INVITE_LINK_EXPIRERATION_CHECK_SUCCESS': 
        currentState = {
          ...state,
          linkExpiryCheck: payload,
          isFetchingFP: false,
        };
        break;
        case 'INVITE_LINK_EXPIRERATION_CHECK_FAILURE': 
        currentState = {
          ...state,
          linkExpiryCheck: null,
          linkExpiryError: payload,
        };
        break;
        case 'RESET_FORGOT_PWD': 
        currentState = initialState
        break;
        default:
      }
      return currentState;
   
  }
  