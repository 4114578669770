import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Divider,
  CardContent,
  Card,
  CardActions,
  Button,
  Dialog,
  Switch,
  DialogContent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Flood from "../../../../../../assets/images/settings/flood.png";
import CloseIcon from "../../../../../../assets/icons/modal-close-icon.svg";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { Navigate, useNavigate } from "react-router-dom";
import {
  activateEmergencyAlertAPI,
  updateUserEmergencyStatus,
  updateMemberCurrentLocation,
  editEmergencyAlertAPI,
} from "../../../redux/actions";
import EditIcon from "@mui/icons-material/Edit";
import constants from "../../../../../../config/constants";
import Alert from "@mui/material/Alert";
import NEPTA from "../../../../../../assets/images/Nepta.png";
import LiveTrackingMeetingLocation from "./LiveTrackingMeetingLocation";

const item = {
  image_PreSignedUrl: Flood,
  name: "Flood",
  imageAlt: "Flood",
};

const StyledCard = styled(Card)({
  position: "relative",
  width: 450,
  height: 314,
  border: "1px solid #D8D8D8",
  borderRadius: 10,
  boxShadow: "none",
  "&:hover": {
    border: "1px solid #FF3535",
    boxShadow: "0px 2px 8px #00000033",
  },
});

const MobileStyledCard = styled(Card)({
  position: "relative",
  width: "220px",
  height: "220px",
  border: "1px solid #D8D8D8",
  borderRadius: 10,
  boxShadow: "none",
  "&:hover": {
    border: "1px solid #FF3535",
    boxShadow: "0px 2px 8px #00000033",
  },
});

const ImageOverlay = styled("div")({
  position: "relative",
  overflow: "hidden",
  border: "1px solid #fff",
});

const Image = styled("img")({
  width: "100%",
  display: "block",
  objectFit: "cover",
});

const TextOverlay = styled("div")({
  position: "absolute",
  bottom: 0,
  left: 0,
  background: "#000000BA 0% 0% no-repeat padding-box",
  opacity: 1,
  width: "100%",
  boxSizing: "border-box",
  textAlign: "center",
  padding: "8px 16px",
  color: "#fff",
  height: "70px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const statusLookup = {
  safe: "I am safe",
  risk: "At risk",
  pending: "Pending",
  critical: "Critical/Emergency",
  "I am safe": "I am safe",
};

const statusLabelLookup = {
  safe: "I am safe",
  risk: "I Need Assistance",
  pending: "Pending",
  critical: "Critical/Emergency",
  "I am safe": "I am safe",
}
const statusLookupBackground = {
  safe: "#3BC273",
  risk: "#F5B60B",
  pending: "#FFF5E5",
  critical: "#FF3535",
};

const textColor = {
  safe: "#ffffff",
  risk: "#ffffff",
  pending: "#F5B60B",
  critical: "#ffffff",
};
const SummaryAlert = ({
  addEmergencyAlert,
  onBackOrCancelClick,
  onEditDisaster,
  previousData,
  activateEmergencyAlertAPI,
  isFetching,
  data,
  alertActivatedAPI = false,
  alertData = null,
  updateUserEmergencyStatus,
  updateMemberCurrentLocation,
  updateStatus,
  editEmergencyAlertAPI,
}) => {
  const [emergencyAlert, setEmergencyAlert] = useState(null);
  const [alignment, setAlignment] = useState(null);
  const [alertActivated, setAlertActivated] = useState(alertActivatedAPI);
  const [errorDialogue, setErrorDialouge] = useState(false);
  const [successDialouge, setSuccessDialuge] = useState(false);
  const [message, setMessage] = useState(null);
  const [addMySelf, setAddMySelf] = useState(false);
  const [showToggle, setShowToggle] = useState(false);
  const [participant, setParticipant] = useState(true);
  const [userCurrentUserIdFromAlert, setUserCurrentUserIdFromAlert] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  const handleChange = (event, newAlignment) => {
    setErrorDialouge(false);
    setAlignment(newAlignment);
  };
  const handleButtonClick = (value) => {
    setAlignment(value);
  };

  const ToggleButtonNew = styled(ToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor:
        (alignment && alignment.toLocaleLowerCase()) === "i am safe"
          ? "#3BC273"
          : (alignment && alignment.toLocaleLowerCase()) === "at risk"
            ? "orange"
            : "#FF3535",
    },
  });

  const onSuccess = async location => {
    if(location && location.coords){
      let latitude = location?.coords?.latitude ? location?.coords?.latitude : null;
      let longitude = location?.coords?.longitude ? location?.coords?.longitude : null;

    if(userCurrentUserIdFromAlert){
      console.log("i am here")
      const payload = {
        "id": userCurrentUserIdFromAlert,
        "locationLat": latitude,
        "locationLong": longitude
    }
    updateMemberCurrentLocation(payload);
    }
     
    }
  };

  const onError = error => {
      console.log("OnError")
  }
 

  const cancelActivateAlert = () => {
    dispatch({
      type: "ADD_EMERGENCY_ALERT",
      payload: null,
    });
    navigate(-1);
  };
  useEffect(() => {
    setEmergencyAlert(addEmergencyAlert);
    if (addEmergencyAlert) {
      const userDetails = localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : null;
      const selectedUser =
        addEmergencyAlert &&
        addEmergencyAlert.familyMembers &&
        addEmergencyAlert.familyMembers.filter((row) => {
          return (
            row.userId === userDetails?.id ||
            (!row.userId && row.id === userDetails?.id)
          );
        });

      if (selectedUser && selectedUser.length > 0) {
        setParticipant(true)
        
        if(addEmergencyAlert?.id){
          if (navigator && navigator.geolocation) {
                setUserCurrentUserIdFromAlert(selectedUser[0].id)
                navigator.geolocation.getCurrentPosition(onSuccess, onError)
            }
        }
      } else {
        setParticipant(false)
      }
    }
  }, [addEmergencyAlert]);

  useEffect(() => {
    if (updateStatus && updateStatus.status === 200) {
      setMessage("Emergency status updated successfully");
      setSuccessDialuge(true);
      setTimeout(() => {
        setSuccessDialuge(false);
        setMessage("");
        navigate("/home");
      }, 2000);

      dispatch({
        type: "AEA_USER_STATUS_SUCCESS",
        payload: null,
      });
    }
  }, [updateStatus]);

  const convertObject = async (alertData) => {
    let newObject = {};
    if(alertData?.createdBy){
      newObject.createdBy = alertData?.createdBy
    }
    if(alertData?.id){
      newObject.id = alertData.id
    }
    if (alertData?.messagesList) {
      let message = [];
      let finalMesages = [];
      const messageKeys = Object.keys(alertData?.messagesList);
      messageKeys.map((singleKey) => {
        const data = alertData?.messagesList && alertData?.messagesList[singleKey];
        message = message.concat(data);
      });
      newObject.message = message;
    }
    if (alertData?.membersList) {
      newObject.familyMembers = alertData?.membersList;
    }
    if (alertData?.disastersList) {
      const diaster = alertData?.disastersList[0];
      newObject.disaster = {
        image_PreSignedUrl: diaster?.image_PreSignedUrl
          ? diaster?.image_PreSignedUrl
          : "",
        name: diaster?.name,
        imageAlt: diaster?.name,
        isDefaultDisaster: diaster?.isDefaultDisaster,
        id: diaster?.id,
        messagesCategoryCode: diaster?.messagesCategoryCode,
      };
    }
    let location = {};
    location.isCurrentLocation = alertData.isCurrentLocation;
    location.locationId = alertData.locationId;
    location.locationLat = alertData?.locationLat;
    location.locationLong = alertData?.locationLong;
    if (location.isCurrentLocation) {
      location.name = "Current Location";
      const address = await getLocaionDetails(
        location.locationLat,
        location.locationLong
      );
      location.address = address;
    }
    if (
      alertData?.meetingLocationsList &&
      alertData?.meetingLocationsList.length > 0
    ) {
      location = alertData?.meetingLocationsList[0];
      location.isCurrentLocation = alertData.isCurrentLocation;
    }
    if(location?.locationLat || location.locationLong){
      newObject.location = location;
    }
    if (alertData?.isEditPage) {
      newObject.isEditPage = true;
    }

    const userDetails = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null;
    const selectedUser =
      newObject &&
      newObject.familyMembers &&
      newObject.familyMembers.filter((row) => {
        return (
          row.userId === userDetails?.id ||
          (!row.userId && row.id === userDetails?.id)
        );
      });

    if (selectedUser && selectedUser.length > 0) {
      setParticipant(true)
      if(alertData?.id){
        if (navigator && navigator.geolocation) {
              setUserCurrentUserIdFromAlert(selectedUser[0].id)
              navigator.geolocation.getCurrentPosition(onSuccess, onError)
          }
      }
    } else {
      setParticipant(false)
    }
    if (
      selectedUser &&
      selectedUser[0] &&
      selectedUser[0].userId &&
      previousData
    ) {
      const filterData = previousData?.membersList?.filter(
        (data) => data?.userId === selectedUser && selectedUser[0]?.userId
      );
      const status = statusLookup[filterData[0]?.status]
        ? statusLookup[filterData[0]?.status]
        : statusLookup[selectedUser[0]?.status];

      setAlignment(status);
    } else if (
      selectedUser &&
      selectedUser[0] &&
      selectedUser[0].id &&
      previousData
    ) {
      const filterData = previousData?.membersList?.filter(
        (data) => data?.userId === selectedUser[0]?.id
      );
      const status = statusLookup[filterData[0]?.status]
        ? statusLookup[filterData[0]?.status]
        : statusLookup[selectedUser[0]?.status];
      setAlignment(status);
    } else if (selectedUser && selectedUser[0] && selectedUser[0].id) {
      const status = statusLookup[selectedUser[0]?.status]
        ? statusLookup[selectedUser[0]?.status]
        : selectedUser[0]?.status;
      setAlignment(status);
    } else {
      setShowToggle(true)
    }
    setEmergencyAlert(newObject);
  };

  const convertNewObjectFormat = async (alertData) => {
    let newObject = alertData;
    if(alertData?.createdBy){
      newObject.createdBy = alertData?.createdBy
    }
    if (alertData?.isEditPage) {
      newObject.isEditPage = true;
    }
    if (newObject.location && newObject.location.isCurrentLocation) {
      newObject.location.name = "Current Location";
      const address = await getLocaionDetails(
        newObject.location?.locationLat,
        newObject.location?.locationLong
      );
      newObject.location.address = address;
    }
    const userDetails = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null;
    const selectedUser =
      newObject &&
      newObject.familyMembers &&
      newObject.familyMembers.filter((row) => {
        return (
          row.userId === userDetails?.id ||
          (!row.userId && row.id === userDetails?.id)
        );
      });
    if (selectedUser && selectedUser.length > 0) {
      setParticipant(true);
      if(newObject?.id){
        if (navigator && navigator.geolocation) {
              setUserCurrentUserIdFromAlert(selectedUser[0].id)
              navigator.geolocation.getCurrentPosition(onSuccess, onError)
          }
      }
    } else {
      setParticipant(false)
    }

    if(previousData === null && newObject.triggeredBy && newObject.triggeredBy.length > 0){
      const createdUser  = newObject.triggeredBy[0];
      if(createdUser?.id === userDetails?.id){
        setParticipant(true)
        if(newObject?.id){
          if (navigator && navigator.geolocation) {
                setUserCurrentUserIdFromAlert(selectedUser[0].id)
                navigator.geolocation.getCurrentPosition(onSuccess, onError)
            }
        }
      }

    }
    if (
      selectedUser &&
      selectedUser[0] &&
      selectedUser[0].userId &&
      previousData
    ) {
      const filterData = previousData?.membersList?.filter(
        (data) => data?.userId === selectedUser[0].userId
      );
      const status = statusLookup[filterData[0].status]
        ? statusLookup[filterData[0].status]
        : statusLookup[selectedUser[0]?.status];
      setAlignment(status);
    } else if (
      selectedUser &&
      selectedUser[0] &&
      selectedUser[0].id &&
      previousData
    ) {
      const filterData = previousData?.membersList?.filter(
        (data) => data?.userId === selectedUser[0].id
      );
      const status = statusLookup[filterData[0]?.status]
        ? statusLookup[filterData[0]?.status]
        : statusLookup[selectedUser[0]?.status];
      setAlignment(status);
    } else if (selectedUser && selectedUser[0] && selectedUser[0].id) {
      const status = statusLookup[selectedUser[0]?.status]
        ? statusLookup[selectedUser[0]?.status]
        : selectedUser[0]?.status;
      setAlignment(status);
    } else {
      setShowToggle(true)
    }

    setEmergencyAlert(newObject);
  };
  useEffect(() => {
    console.log(alertData, "alertData");
    if (alertData && alertData.triggeredBy) {
      convertNewObjectFormat(alertData);
    } else if (alertData) {
      convertObject(alertData);
    }
  }, [alertData]);


  useEffect(()=>{
    if(emergencyAlert && emergencyAlert.id){
      console.log(emergencyAlert,"Check active mergency ");
      const userDetails = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null;
    const selectedUser =
    emergencyAlert &&
    emergencyAlert.familyMembers &&
      emergencyAlert.familyMembers.filter((row) => {
        return row.userId === userDetails?.id
      });
      if(selectedUser &&
        selectedUser[0] &&
        selectedUser[0].id 
        ){
          setUserCurrentUserIdFromAlert(selectedUser[0].id)
                setInterval(()=>{
                    if (navigator && navigator.geolocation) {
                      navigator.geolocation.getCurrentPosition(onSuccess, onError)
                    }
                },30000)
        }

    }
    return()=>{
      clearInterval()
    }
  },[emergencyAlert])
  useEffect(() => {
    if (data && data.status === 200) {
      setAlertActivated(true);
      if(data?.message === "Emergency updated Successfully"){
      setMessage("Emergency Alert updated successfully");
        
      }else{
        setMessage("Emergency Alert activated successfully");

      }
      setSuccessDialuge(true);
      setTimeout(() => {
        setSuccessDialuge(false);
        setMessage("");
        navigate("/home");
      }, 2000);
      dispatch({
        type: "ADD_EMERGENCY_ALERT",
        payload: null,
      });
    }
    if (data && data.status === 400) {
      window.location.reload();
      console.log("data");
    }
  }, [data]);

  const updateUserStatus = () => {
    const userDetails = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null;
    const selectedUser =
      emergencyAlert &&
      emergencyAlert.familyMembers &&
      emergencyAlert.familyMembers.filter((row) => {
        return row.userId === userDetails?.id;
      });
      
      console.log(selectedUser)
      console.log("alignment", alignment)
    if (selectedUser && selectedUser[0] && selectedUser[0].id) {
      if(alignment && alignment.toLocaleLowerCase() === "pending" ){
        setErrorDialouge(true);
        return
      }
      const payload = {
        id: selectedUser[0]?.id,
        status:
          (alignment === "I am safe" || alignment === "safe")
            ? "safe"
            : (alignment === "At risk" || alignment === "risk")
              ? "risk"
              : "pending",
      };
      updateUserEmergencyStatus(payload);
    }
  };

  const activateEmergencyAlert = () => {
    if (!alignment) {
      setErrorDialouge(true);
      return;
    }

    let payload = {
      accountId: userDetails.accountId,
      createdBy: userDetails.id,
    };
    if (addEmergencyAlert && addEmergencyAlert.disaster) {
      payload.disaster = addEmergencyAlert.disaster.id;
      payload.isDefaultDisaster = addEmergencyAlert.disaster?.defaultDisaster
        ? addEmergencyAlert.disaster?.defaultDisaster
        : false;
    }
    if (addEmergencyAlert && addEmergencyAlert.location) {
      const location = addEmergencyAlert.location;
      payload.locationId =
        location.id && location.id !== "current-location" ? location.id : null;
      payload.isCurrentLocation =
        location.id && location.id !== "current-location" ? false : true;
      payload.locationLat = location?.locationLat;
      payload.locationLong = location?.locationLong;
    }
    if (addEmergencyAlert && addEmergencyAlert.message) {
      let messages = "";
      addEmergencyAlert.message.map((item, i) => {
        if (i === 0) {
          messages = item.id;
        } else {
          messages = messages + "," + item.id;
        }
      });
      payload.messages = messages;
      payload.messagesList = messages;
    }
    if (addEmergencyAlert && addEmergencyAlert.familyMembers) {
      let familyMembers = [];
      const triggeredBy = addEmergencyAlert?.triggeredBy?.[0];
      triggeredBy.status =
        alignment === "I am safe"
          ? "safe"
          : alignment === "At risk"
            ? "risk"
            : "risk";
      triggeredBy.userId = triggeredBy?.id;
      const firstName = triggeredBy?.firstName
        ? triggeredBy?.firstName + " "
        : "";
      const middleName = triggeredBy?.middleName && triggeredBy?.middleName !== null
        ? triggeredBy?.middleName + " "
        : "";
      const lastName = triggeredBy?.lastName && triggeredBy?.lastName !== null ? triggeredBy?.lastName : "";
      triggeredBy.name = firstName + middleName + lastName;

      if(triggeredBy.userId === userDetails?.id){
        
      }
      familyMembers.push(triggeredBy);


      addEmergencyAlert.familyMembers.map((item, i) => {
        if (item?.id !== userDetails?.id) {
          const firstName = item?.firstName ? item?.firstName + " " : "";
          const middleName = item?.middleName && item.middleName !== null ? item?.middleName + " " : "";
          const lastName = item?.lastName && item?.lastName !== null ? item?.lastName : "";
          const name = firstName + middleName + lastName;

          const members = {
            accountId: item.accountId,
            userId: item.id,
            name: name,
            emailId: item?.emailId,
            phoneNo: item?.phoneNo,
            status: "pending",
          };
          familyMembers.push(members);
        }
      });
      payload.members = familyMembers;
    }
    activateEmergencyAlertAPI(payload);
  };

  const getLocaionDetails = async (latitude, longitude) => {
    if(latitude && longitude){
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${constants.GOOGLE_MAPS_API_KEY}`
      );
      const json = await response.json();
      console.log("response", json);
      return json.results[0]?.formatted_address;
    }
   
  };
  const updateEmergencyAlert = () => {
    console.log("previous Object", previousData);
    console.log("Edit Object", addEmergencyAlert);
    const userDetails = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null;

    const editObject = {
      id: previousData?.id,
      accountId: previousData?.accountId,
      createdAt: previousData?.createdAt,
      createdBy: previousData?.createdBy,
      updatedBy: userDetails?.id
    };
    if (addEmergencyAlert && addEmergencyAlert.disaster) {
      editObject.disaster = addEmergencyAlert.disaster.id;
    }
    if (addEmergencyAlert.disaster?.id == Number(previousData?.disaster)) {
      // tupe is different so i used double equal instead of trible equal
      editObject.isDefaultDisaster = previousData.isDefaultDisaster;
    } else {
      editObject.isDefaultDisaster = addEmergencyAlert.disaster?.defaultDisaster
        ? addEmergencyAlert.disaster?.defaultDisaster
        : false;
    }
    if (addEmergencyAlert && addEmergencyAlert.location) {
      const location = addEmergencyAlert.location;
      editObject.locationId =
        location.id && location.id !== "current-location" ? location.id : null;
      editObject.isCurrentLocation =
        location.id && location.id !== "current-location" ? false : true;
      editObject.locationLat = location?.locationLat;
      editObject.locationLong = location?.locationLong;
    }
    if (addEmergencyAlert && addEmergencyAlert.message) {
      let messages = "";
      addEmergencyAlert.message.map((item, i) => {
        if (i === 0) {
          messages = item.id;
        } else {
          messages = messages + "," + item.id;
        }
      });
      editObject.messages = messages;
    }

    if (addEmergencyAlert && addEmergencyAlert.familyMembers) {
      let familyMembers = [];
      const triggeredBy = addEmergencyAlert?.triggeredBy?.[0];
      triggeredBy.status =
        alignment === "I am safe"
          ? "safe"
          : alignment === "At risk"
            ? "risk"
            : alignment === "Critical/Emergency"
              ? "critical" : null;
      triggeredBy.userId = triggeredBy?.id;
      const firstName = triggeredBy?.firstName
        ? triggeredBy?.firstName + " "
        : "";
      const middleName = triggeredBy?.middleName && triggeredBy?.middleName !== null
        ? triggeredBy?.middleName + " "
        : "";
      const lastName = triggeredBy?.lastName && triggeredBy?.lastName !== null ? triggeredBy?.lastName : "";
      triggeredBy.name = firstName + middleName + lastName;

      if (participant) {

        const checkTheUserIsOld = previousData.membersList && previousData.membersList.filter((user) => {return userDetails.id === user.userId})
        if(checkTheUserIsOld && checkTheUserIsOld.length > 0){
          familyMembers.push(triggeredBy);
        }else{
          delete triggeredBy.id;
          familyMembers.push(triggeredBy)
        }
        
      }
      if (participant && !alignment) {
        setErrorDialouge(true)
        return
      } else {
        setErrorDialouge(false)
      }
      addEmergencyAlert.familyMembers.map((item, i) => {
        if (item?.userId && item?.userId !== userDetails?.id) {
          const firstName = item?.firstName ? item?.firstName + " " : "";
          const middleName = item?.middleName && item?.middleName !== null ? item?.middleName + " " : "";
          const lastName = item?.lastName && item?.lastName !== null ? item?.lastName : "";
          const name = firstName + middleName + lastName;

          const members = {
            accountId: item.accountId,
            userId: item.userId,
            name: name,
            emailId: item?.emailId,
            phoneNo: item?.phoneNo,
            status:
              userDetails.id !== item.userId
                ? item?.status
                : item?.status
                  ? item?.status
                  : alignment === "I am safe"
                    ? "safe"
                    : alignment === "At risk"
                      ? "risk"
                      : "pending",
            id: item?.id,
          };
          familyMembers.push(members);
        } else if (item?.id !== userDetails?.id) {
          const firstName = item?.firstName ? item?.firstName + " " : "";
          const middleName = item?.middleName && item?.middleName !== null ? item?.middleName + " " : "";
          const lastName = item?.lastName && item?.lastName !== null ? item?.lastName : "";
          const name = firstName + middleName + lastName;

          const members = {
            accountId: item.accountId,
            userId: item.id,
            name: name,
            emailId: item?.emailId,
            phoneNo: item?.phoneNo,
            status: "pending",
          };
          familyMembers.push(members);
        }
      });

      familyMembers.map((member) => {
        const filterData = previousData?.membersList?.filter(
          (data) => data?.userId === member.userId
        );
        if (filterData && filterData.length > 0) {
          if(userDetails?.id !== filterData[0]?.userId){
            member.status = filterData[0].status;
          }
          member.id = filterData[0].id;
        }
      });
      editObject.members = familyMembers;
    }
    console.log(alignment)
    console.log(editObject);
    editEmergencyAlertAPI(editObject);
  };

  return (
    <Grid container spacing={2} style={{ width: "100%", margin: 0 }}>
      <Grid item xs={12} md={12} lg={12}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            className="primary-font-bold-heading2"
            style={{ paddingLeft: 10 }}
          >
            Emergency Alert
          </Typography>
          <div
            style={{
              display: "flex",
              alignitems: "flex-end",
              justifycontent: "end",
              paddingRight: 30,
            }}
          >
            <img
              src={CloseIcon}
              alt="close-icon"
              style={{ cursor: "pointer",marginRight: "30px",width:"21px" }}
              onClick={() => {
                cancelActivateAlert();
              }}
             
            />
          </div>
        </div>
        <Divider />
        <br />
        <Grid container spacing={2} style={{ width: "100%", margin: 0 }}>
          <Grid item xs={12} md={6} lg={6}>
            <div>
              {emergencyAlert && emergencyAlert.disaster && (
                <Typography className="primary-font-bold-5">
                  Disaster
                </Typography>
              )}
              <br />
                  <EditIcon
                  style={{
                    marginLeft: "80px",
                    position: "relative",
                    bottom: "44px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    onEditDisaster(emergencyAlert);
                  }}
                  ></EditIcon>
                  <div className="mobile-view">
                 <MobileStyledCard key={"disaster"}>
                <ImageOverlay>
                  <Image
                    src={emergencyAlert?.disaster?.image_PreSignedUrl}
                    alt={emergencyAlert?.disaster?.imageAlt}
                    height={"220px"}
                    style={{ width: "220px", objectFit: "cover" }}
                  />
                  <TextOverlay>
                    <Typography
                      variant="h6"
                      className="primary-font-bold-6"
                      style={{ textAlign: "center" }}
                    >
                      {emergencyAlert?.disaster?.name}
                    </Typography>
                  </TextOverlay>
                </ImageOverlay>
                <Divider />
              </MobileStyledCard>
              </div> 
              <div className="laptop-view">
              <StyledCard key={"disaster"}>
                <ImageOverlay>
                  <Image
                    src={emergencyAlert?.disaster?.image_PreSignedUrl}
                    alt={emergencyAlert?.disaster?.imageAlt}
                    height={314}
                    style={{ width: "450px", objectFit: "cover" }}
                  />
                  <TextOverlay>
                    <Typography
                      variant="h6"
                      className="primary-font-bold-6"
                      style={{ textAlign: "center" }}
                    >
                      {emergencyAlert?.disaster?.name}
                    </Typography>
                  </TextOverlay>
                </ImageOverlay>
                <Divider />
              </StyledCard>
              </div>
            
            </div> 
            {alertActivated && (
              <div id="meeting-lcoation" style={{marginTop:"20px", width:"500px",}}>
              <LiveTrackingMeetingLocation membersList={emergencyAlert?.familyMembers} meetingLocation={emergencyAlert && emergencyAlert.location} />
              </div>
            )}
           
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            {emergencyAlert && emergencyAlert.location && (
              <div>
                <Typography
                  className="primary-font-bold-5"
                  style={{ paddingTop: "20px", marginRight: "5px" }}
                >
                  Meeting Location
                </Typography>
                <Grid
                  style={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    width: "80%",
                  }}
                >
                  <Grid
                    container
                    sx={{
                      margin: "0px",
                      padding: "12px",
                      mr: "10px",
                      border: "1px solid #D8D8D8",
                      borderRadius: "10px",
                      flexDirection: "column",
                    }}
                  >
                    <Grid>
                      <Typography
                        className="primary-font-bold-5"
                        style={{ textTransform: "capitalize" }}
                      >
                        {emergencyAlert?.location?.name}
                      </Typography>
                    </Grid>
                    <Grid>
                      {" "}
                      <Typography className="primary-font-normal-2">
                        {emergencyAlert?.location?.address}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            )}
            <div style={{ marginBottom: "20px", }}>
              {emergencyAlert && emergencyAlert.message && (
                <Typography className="primary-font-bold-5">Message</Typography>
              )}
              {/* <ul> */}
              {emergencyAlert &&
                emergencyAlert.message &&
                emergencyAlert.message.map((item) => (
                  <Typography className="primary-font-normal-2" style={{ marginTop: "20px"}}>
                    {item.message}
                  </Typography>
                ))}
              {/* </ul> */}
            </div>
            <div>
              {emergencyAlert && emergencyAlert.familyMembers && (
                <Typography className="primary-font-bold-5">
                  Family Members
                </Typography>
              )}
              <Grid
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  width: "80%",
                }}
              >
                {emergencyAlert &&
                  emergencyAlert.familyMembers &&
                  emergencyAlert.familyMembers.map((row) => {
                    return (
                      <>
                        {((row.userId && row.userId !== userDetails?.id) ||
                          (!row.userId && row.id !== userDetails?.id)) && (
                            <>
                              <Grid
                                container
                                key={row.id}
                                sx={{
                                  mr: "12px",
                                  padding: "12px",
                                  // mx: "10px",
                                  // my: "5px",
                                  border: "1px solid #D8D8D8",
                                  borderRadius: "10px",
                                  marginBottom: "10px"
                                }}
                              >
                                <Grid
                                  xs={12}
                                  md={7.5}
                                  lg={7.5}
                                  sx={{
                                    justifyContent: "start",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  <Typography className="primary-font-bold-5">
                                    {row.name
                                      ? row.name
                                      : row.firstName +
                                      " " +
                                      (row?.middleName && row?.middleName !== null ? row.middleName : "") +
                                      " " +
                                      (row?.lastName && row?.lastName !== null ? row?.lastName : "")}
                                  </Typography>
                                </Grid>
                                {/* <Grid
                           xs={4}
                           md={4}
                           lg={4}
                           sx={{ justifyContent: "start" }}
                         >
                           <Typography className="primary-font-normal-2">
                             {row.relationshipCode}
                           </Typography>
                         </Grid> */}
                                <Grid
                                  xs={12}
                                  md={4.5}
                                  lg={4.5}
                                  sx={{
                                    justifyContent: "start",
                                    textAlign: "center",
                                    borderRadius: "30px",
                                    backgroundColor:
                                      row.id === userDetails.id
                                        ? statusLookupBackground[alignment]
                                        : row.status
                                          ? statusLookupBackground[row.status]
                                          : statusLookupBackground["pending"],
                                  }}
                                >
                                  <Typography
                                    className="primary-font-normal-2"
                                    style={{
                                      textTransform: "capitalize",
                                      color:
                                        row.id === userDetails.id
                                          ? textColor[alignment]
                                          : row.status
                                            ? textColor[row.status]
                                            : textColor["pending"],
                                    }}
                                  >
                                    {row.id === userDetails.id
                                      ? alignment
                                      : row.status
                                        ? statusLabelLookup[row.status]
                                        : statusLabelLookup["pending"]}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </>
                          )}
                      </>
                    );
                  })}
              </Grid>
            </div>
            {emergencyAlert?.isEditPage && showToggle && (
              <div>
                <Grid container>
                  <Grid item xs={8} sm={10}>
                    <Typography
                      className="primary-font-bold-5"
                      style={{ paddingTop: "20px", marginBottom: "10px" }}>Do you want to add your self as a member?</Typography>
                  </Grid>
                  <Grid item xs={4} sm={2}>
                    <Switch
                      checked={addMySelf}
                      onChange={(e) => {
                        console.log(e)
                        if (e.target.checked) {
                          console.log(addMySelf); 
                          setParticipant(true) 
                        } else{ 
                          setParticipant(false)
                        } 
                        setAddMySelf(!addMySelf)
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </Grid>
                </Grid>
              </div>
            )}

            {/* {((emergencyAlert && emergencyAlert.isEditPage === true && showToggle && !addMySelf) || (!showToggle && !participant) ) ? (<> */}

            {!participant ? (<>
            </>) : (
              <>
                <div>
                  <Typography
                    className="primary-font-bold-5"
                    style={{ paddingTop: "20px",paddingBottom:"20px", marginBottom: "10px" }}
                  >
                    Safety Status
                  </Typography>
                  {errorDialogue && (
                    <Alert
                      sx={{
                        position: "absolute",
                        zIndex: 1000,
                        marginTop: "-100px",
                      }}
                      variant="filled"
                      severity="error"
                      onClose={() => {
                        setErrorDialouge(false);
                      }}
                    >
                      Please select the safety status
                    </Alert>
                  )}
                  {/* {successDialouge && (
                <Alert
                  sx={{
                    position: "absolute",
                    zIndex: 1000,
                    marginTop: "-100px",
                  }}
                  variant="filled"
                  severity="success"
                  onClose={() => {
                    setSuccessDialuge(false);
                  }}
                >
                  {message}
                </Alert> */}
                  <Dialog
                    open={successDialouge}
                    onClose={() => {
                      setSuccessDialuge(false);
                    }}
                    className="small-dialog dialog-box"
                    aria-labelledby="dialog-title"
                    aria-describedby="dialog-description"
                  >
                    <DialogContent className="content-dialog">
                      <Typography className="primary-font-normal-4">
                        {message}
                      </Typography>
                    </DialogContent>
                  </Dialog>
                  <div style={{ gap: "25px", display: "flex", paddingRight:'20px' }}>
                    {/* <Button
                      sx={{
                        width: "150px !important",
                        cursor: "pointer",
                        backgroundColor:
                          alignment === "Critical/Emergency"
                            ? "#FF3535"
                            : "#FFCFCB",
                        border: "1px solid #FF3535",
                        color: alignment === "Critical/Emergency"
                          ? "white"
                          : "black",
                        "&:hover": {
                          backgroundColor: "#FF3535",
                          border: "1px solid #FF3535",
                          boxShadow: "0px 2px 8px #00000033",
                          color: "#ffffff"
                        },
                      }}
                      onClick={() => handleButtonClick("Critical/Emergency")}
                      variant="outlined"
                    >
                      Critical/
                      <br />
                      Emergency
                    </Button> */}

                    <Button
                      sx={{
                        width: "150px !important",
                        cursor: "pointer",
                        backgroundColor:
                          (alignment && alignment.toLocaleLowerCase() === "at risk"  || alignment === "risk" )? "#F5B60B" : "#FFF5E5",
                        border: "1px solid #F5B60B",
                        color: (alignment && alignment.toLocaleLowerCase() === "at risk" || alignment === "risk")
                          ? "white"
                          : "black",
                        "&:hover": {
                          backgroundColor: "#F5B60B",
                          border: "1px solid #EAA434",
                          boxShadow: "0px 2px 8px #00000033",
                          color: "#ffffff"
                        },
                      }}
                      onClick={() => handleButtonClick("At risk")}
                      variant="outlined"
                    >
                     I Need Assistance 
                    </Button>

                    <Button
                      sx={{
                        width: "150px !important",
                        cursor: "pointer",
                        backgroundColor:
                          alignment === "I am safe" ? "#3BC273" : "#E6FFF2",
                        border: "1px solid #3BC273",
                        color: alignment === "I am safe"
                          ? "white"
                          : "black",
                        "&:hover": {
                          backgroundColor: "#3BC273",
                          border: "1px solid #3BC273",
                          boxShadow: "0px 2px 8px #00000033",
                          color: "#ffffff"
                        },
                      }}
                      onClick={() => handleButtonClick("I am safe")}
                      variant="outlined"
                    >
                      I AM SAFE
                    </Button>
                  </div>
                </div>
              </>
            )}

          </Grid>
        </Grid>

        {!alertActivated && (
          <div
            className="action-holder-1"
            style={{ marginTop: 40, marginBottom: 50 }}
          >
            <Button
              variant="outlined"
              className="primary-font-bold-6 action-btn btn-height profile-cancel-btn"
              disabled={isFetching}
              onClick={onBackOrCancelClick}
            >
              Back
            </Button>
            <Button
              variant="outlined"
              className="primary-font-bold-6 action-btn btn-height profile-save-btn"
              disabled={isFetching}
              onClick={() => {
                activateEmergencyAlert();
              }}
            >
              Activate
            </Button>
          </div>
        )}

        {alertActivated && (
          <div
            className="action-holder"
            style={{ marginTop: 40, marginBottom: 50 }}
          >
            {emergencyAlert?.isEditPage ? (
              <>
                <Button
                  variant="outlined"
                  className="primary-font-bold-6 action-btn btn-height profile-save-btn"
                  onClick={() => {
                    updateEmergencyAlert();
                  }}
                >
                  Update Alert
                </Button>
                <Button
                  variant="outlined"
                  className="primary-font-bold-6 action-btn btn-height profile-save-btn"
                  onClick={() => {
                    cancelActivateAlert();
                  }}
                >
                  Cancel
                </Button>
              </>
            ) : (<>
              {
                participant && (
                  <>
                    <Button
                      variant="outlined"
                      className="primary-font-bold-6 action-btn btn-height profile-save-btn"
                      onClick={() => {
                        updateUserStatus();
                      }}
                    >
                      Update status
                    </Button>
                    <Button
                      variant="outlined"
                      className="primary-font-bold-6 action-btn btn-height profile-save-btn"
                      onClick={() => {
                        cancelActivateAlert();
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                )
              }
            </>
            )}
          </div>
        )}
      </Grid>
    </Grid>
  );
};

function mapStateToProps(state) {
  return {
    familyMembers: state.setting.familyMembers,
    addEmergencyAlert: state.emergencyAlert.addEmergencyAlert,
    data: state.emergencyAlert.data,
    isFetching: state.emergencyAlert.isFetching,
    updateStatus: state.emergencyAlert.updateStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      activateEmergencyAlertAPI,
      updateUserEmergencyStatus,
      updateMemberCurrentLocation,
      editEmergencyAlertAPI,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryAlert);
