import { createContext, useEffect, useState } from "react";

// Login User Details
export const UserInfoDetails = createContext({
    userObject: localStorage.getItem("userInfo")? JSON.parse(localStorage.getItem("userInfo")): null
})


// Geo Location
export const UserGeolocationContext = createContext(null);

const useGeolocation = () => {
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    const onSuccess = location => {
      if (location && location.coords) {
        let latitude = location.coords.latitude || null;
        let longitude = location.coords.longitude || null;
        const object = {
          locationLat: latitude,
          locationLong: longitude,
          showCustomMarker: true
        };
        setUserLocation(object);
      }
    };

    const onError = error => {
      console.log("OnError", error);
    };

    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onSuccess, onError);
    }
  }, []);

  return userLocation;
};

export const UserGeolocationProvider = ({ children }) => {
  const userlocation = useGeolocation();

  return (
    <UserGeolocationContext.Provider value={userlocation}>
      {children}
    </UserGeolocationContext.Provider>
  );
};


const MessageList = [
    {
      "code": 101,
      "message": "Hello, I'm going out for the evening"
    },
    {
      "code": 102,
      "message": "Hello, I'm catching an Uber/Taxi"
    },
    {
      "code": 103,
      "message": "Hello, I'm activated the FYI tracking"
    },
    {
      "code": 104,
      "message": "Hello, I'm getting ready to leave work"
    },
    {
      "code": 105,
      "message": "Hello, I'm on my way home"
    },
    {
      "code": 106,
      "message": "Hello, I'm on my way to the store"
    },
    {
      "code": 107,
      "message": "Hello, I'm going on a road trip"
    },{
      "code": 108,
      "message": "Hello, I wish to track you until you reach safely"
    }
  ]

  export const preDefinedMessage = createContext({
    messageList: MessageList
})