import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getFamilyMembers } from "../../../../../../containers/Pages/Settings/redux/actions";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import * as formatters from "../../../../../../utils/util";
import ErrorIcon from "../../../../../../assets/icons/error-icon.svg";
import plusIcon from '../../../../../../assets/icons/plus-icon.svg';
import MinusIcon from '../../../../../../assets/icons/minus-icon.svg';
import "./styles/activateEmergency.css"

const Addfamilymember = ({
  onBackOrCancelClick,
  handleNext,
  familyMembers,
  getFamilyMembers,
  addEmergencyAlert,
}) => {
  const [familyMembersList, setFamilyMembersList] = useState([]);
  const [familyMemberErr, setFamilyMemberErr] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [verifiedMembers, setVerifiedMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [expand,setExpand] = useState(false);
  
  const handleChange = (panel) => (event, isExpanded) => {
    setExpand(isExpanded ? panel : false);
  };


  const userDetails = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

  const dispatch = useDispatch();
  useEffect(() => {
    getFamilyMembers();
  }, []);

  useEffect(() => {
    if (familyMembers && familyMembers.status === 200) {
      setFamilyMembersList(familyMembers?.data);
      let verfiedMember = []
      familyMembersList.filter((row) => {
        if (row.id !== userDetails?.id && row.statusCode === "verified") {
          verfiedMember.push(row);
        }
      });
      setVerifiedMembers(verfiedMember)
    }
    if (familyMembers && familyMembers.status !== 200) {
    }
  }, [familyMembers]);


  // const handleSelectAll = (event) => {
  //   if (event.target.checked) {
  //     const totalMember = []
  //     verifiedMembers.filter((row) => {totalMember.push(row.id)})
  //     setSelectedRows(totalMember);
  //   } else {
  //     setSelectedRows([]);
  //   }
  // };

  const handleSelectAll = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      setSelectedMembers(verifiedMembers.map(member => member.id));
      setSelectedRows(verifiedMembers.map(member => member.id));
    } else {
      setSelectedMembers([]);
      setSelectedRows([]);
    }
  };
  const handleCheckboxChange = (id) => {
    if (selectedMembers.includes(id)) {
      setSelectedMembers(selectedMembers.filter(memberId => memberId !== id));
      setSelectedRows(selectedMembers.filter(memberId => memberId !== id));
    } else {
      setSelectedMembers([...selectedMembers, id]);
      setSelectedRows([...selectedMembers, id]);
    }
  };

  const isAllSelected = verifiedMembers.length > 0 && selectedRows.length === verifiedMembers.length;

  const handleRowClick = (id) => {
    handleCheckboxChange(id);
  };

  const handleSelectRow = (event, id) => {
    const isSelected = selectedRows.includes(id);
  
    if (event) {
      if (event.target.checked) {
        setSelectedRows((prevSelected) => [...prevSelected, id]);
      } else {
        setSelectedRows((prevSelected) =>
          prevSelected.filter((selectedId) => selectedId !== id)
        );
      }
    } else {
      if (isSelected) {
        setSelectedRows((prevSelected) =>
          prevSelected.filter((selectedId) => selectedId !== id)
        );
      } else {
        setSelectedRows((prevSelected) => [...prevSelected, id]);
      }
    }
  };
  

  const getSelectedRowDetails = () => {
    const newData = { ...addEmergencyAlert };
    const selectedRowsDetails = familyMembersList.filter((row) =>
      selectedRows.includes(row.id)
    );

    console.log(selectedRowsDetails,"selectedRowDetails")

    if (selectedRowsDetails && selectedRowsDetails.length > 0) {
      const triggeredBy = familyMembersList.filter(
        (row) => row.id === userDetails?.id
      );

      if(newData.familyMembers){
        newData.familyMembers.map((member)=>{
          selectedRowsDetails.map((selectedUser) => {
            if(selectedUser.id === member.userId){
              selectedUser.status = member.status
            }
          })
        })
      }
      
      newData.familyMembers = selectedRowsDetails;
      newData.triggeredBy = triggeredBy;

      dispatch({
        type: "ADD_EMERGENCY_ALERT",
        payload: newData,
      });
      setFamilyMemberErr("");
      handleNext();
    } else {
      setFamilyMemberErr("Please select the Family Member");
    }
  };
  useEffect(() => {
    if (addEmergencyAlert && addEmergencyAlert.familyMembers) { 
      console.log(addEmergencyAlert.familyMembers,"addEmergencyAlert.familyMembers")
      let totalMembers = []
      addEmergencyAlert.familyMembers.forEach((row) => {
        if (row.userId !== userDetails?.id) {
          totalMembers.push(row);
        }
      });
      setSelectedRows(totalMembers.map((row) => {
        if(row?.userId){
          return row.userId
        }else{
          return row.id
        }
      }));
      setSelectedMembers(totalMembers.map((row) => {
        if(row?.userId){
          return row.userId
        }else{
          return row.id
        }
      }));
    }
  }, [addEmergencyAlert]);
  return (
    <Grid container >
          <Grid
            container
            xs={12}
            md={12}
            lg={12}
            className="check-box-paddingleft"
            sx={{
              display: "flex",
              alignItems: "center",
              gap:'10px',
              maxWidth: "100%",
              textAlign: "center",
              // paddingLeft:"100px"
            }}
          >
            <Grid item xs={3} sm={1} md={1}>
            <Checkbox
          sx={{
            color: "red !important",
          }}
          checked={isAllSelected} 
          onChange={handleSelectAll}
          inputProps={{ "aria-label": "select all rows" }}
        />
            </Grid>
            <Grid
            //  item xs={4} sm={2} md={1} 
             >
              <Typography
                className="primary-font-bold-5"
                style={{textTransform:"capitalize", textAlign:"left"}}
              >
                Select All
              </Typography>
            </Grid>
           
          </Grid>
       
       
      <Grid item sx={{ mx: "10px" }} xs={12} md={12} lg={12}>
        <Divider sx={{ width: "100%", my: "15px" }} />
      </Grid>

      {familyMemberErr.trim().length > 0 && (
        <div style={{ display: "flex", paddingLeft: "20px" }} className="check-box-error-paddingleft">
          <img src={ErrorIcon} alt="error" href="#" />
          <Typography
            className="primary-font-normal-1"
            style={{ marginLeft: 10, color: "red" }}
          >
            {familyMemberErr}
          </Typography>
        </div>
      )}
      <Grid container sx={{mt:"10px"}}>
      {familyMembersList
  .filter((row) => row.statusCode === "verified")
  .map((row) => (
    <>
      {userDetails?.id !== row.id && (
        <Grid
          container
          xs={12}
          md={12}
          lg={12}
          className="check-box-paddingleft check-box-align"
          sx={{
            display: "flex",
            // alignItems: "flex-start",
            // justifyContent: "space-evenly",
            gap:'10px',
            maxWidth: "100%",
            textAlign: "center",
            cursor: "pointer",
            // marginTop:'10px',
            // marginBottom:'10px'
          }}
          onClick={() => handleRowClick(row.id)}
        >
          <Grid item xs={3} sm={1} md={1}>
            <Checkbox
              sx={{
                color: "red !important",
              }}
              className="checkboc-pt0"
              checked={selectedRows.includes(row.id)}
              onChange={(e) => {
                e.stopPropagation();
                handleCheckboxChange(row.id);
              }}
              inputProps={{ "aria-label": `select row ${row.id}` }}
            />
          </Grid>
          <Grid  className="check-box-content" >
          <Grid  className="name-width" >
            <Typography
              className="primary-font-bold-5"
              style={{ textTransform: "capitalize", textAlign: "left" }}
            >
              {`${row.firstName} ${row.middleName ? row.middleName : ""} ${row.lastName ? row.lastName : ""}`}
            </Typography>
          </Grid>
          <Grid  className="name-width" >
            <Typography
              className=".primary-font-normal-3"
              style={{ textTransform: "capitalize", textAlign: "left" }}
            >
              {row.relationshipCode}
            </Typography>
          </Grid>
          <Grid 
          // item  xs={12} md={3} sm={12}
          >
            <Typography
              className=".primary-font-normal-3"
              style={{ textAlign: "left" }}
            >
              {formatters.formatPhoneNumber(row.phoneNo)}
            </Typography>
          </Grid>
          </Grid>
        </Grid>
      )}
    </>
  ))}

      </Grid>

      {/* <Grid container className="mobile-view">
      {familyMembersList.filter((row) => row.statusCode === "verified").map((row) => ( 
        <div className="accordion-holder" >
        {userDetails?.id !== row.id && (
        <div style={{marginBottom : "20px"}}>
        <Accordion defaultExpanded 
                key={row.id}
                expanded={expand === row.id}
                onChange={handleChange(row.id)} >
            <AccordionSummary expandIcon={expand === row.id ? <img src={MinusIcon} alt={"minus icon"}/> :  <img src={plusIcon} alt="plus Icon"/>} onClick={(e) => { }} aria-controls="panel1a-content" id="panel1a-header">
             <div style={{display:'flex', flexDirection:'row'}} onClick={()=>{handleSelectRow(null, row?.id);}}>
             <Checkbox
                    sx={{
                      color: "red !important",
                    }}
                    checked={selectedRows.includes(row.id)}
                    onChange={(event) => handleSelectRow(event, row.id)}
                    inputProps={{ "aria-label": `select row ${row.id}` }}
                  />
                  <Typography
                    className="primary-font-bold-5"
                    style={{textTransform:"capitalize", textAlign:"left", padding:'10px 0px', fontSize:'15px'}}
                    // sx={{ mt: "10px", ml: "5px", mr: "5px" ,padding:'5px'}}
                  >
                    {(row.firstName + " " + (row?.middleName && row?.middleName !== null ? row.middleName : "") +
                          " " + (row?.lastName && row?.lastName !== null ? row?.lastName : ""))}
                  </Typography>
                
             </div>
            </AccordionSummary>
            <AccordionDetails  sx={{pr:'0px',pl:'60px'}}>
              <div style={{display:'flex', flexDirection:'column'}}>
              <Typography
                    className=".primary-font-normal-3"
                    style={{textTransform:"capitalize", textAlign:"left"}}
                    // sx={{ mt: "10px", ml: "5px", mr: "5px" }}
                  >
                    {row.relationshipCode}
                    </Typography>
                    <Typography
                    className=".primary-font-normal-3"
                    // sx={{ mt: "10px", ml: "5px", mr: "5px" }}
                    style={{ textAlign:"left"}}
                  >
                    {formatters.formatPhoneNumber(row.phoneNo)}
                  </Typography>
              </div>
            </AccordionDetails>
            </Accordion>
        </div>
        )}
        </div>
      ))}
          
      </Grid> */}
      

      <div
        className="action-holder action-holder-3"
        style={{ marginTop: 40, marginBottom: 50 }}
      >
        <Button
          variant="outlined"
          className="primary-font-bold-6 action-btn profile-cancel-btn"
          onClick={onBackOrCancelClick}
        >
          Back
        </Button>
        <Button
          variant="outlined"
          className="primary-font-bold-6 action-btn profile-save-btn"
          onClick={getSelectedRowDetails}
        >
          Next
        </Button>
      </div>
    </Grid>
  );
};

Addfamilymember.defaultProps = {
  isFetching: null,
  error: null,
  familyMembers: [],
};

function mapStateToProps(state) {
  return {
    isFetching: state.setting.isFetching,
    error: state.setting.error,
    familyMembers: state.setting.familyMembers,
    addEmergencyAlert: state.emergencyAlert.addEmergencyAlert,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getFamilyMembers,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Addfamilymember);
