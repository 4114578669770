import React, { useState } from "react";
import ContentWrapper from "../../../../common/components/ContentWrapper";
import { Grid, Typography, Button, IconButton } from "@mui/material";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";

const Paymentmethod = () => {
  const [PaymentDetials, setPaymentDetials] = useState({
    PaymentType: "",
    CreditCardName: "",
    ExpirationDate: new Date(),
    SecurityCode: "",
  });

  const [showCode, setShowCode] = useState(false);
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPaymentDetials((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    
  };

  const handleDateChange = (date) => {
    setPaymentDetials((prevState) => ({
      ...prevState,
      ExpirationDate: date,
    }));
  };

  const handleClickShowCode = () => setShowCode(!showCode);

  const handleSave = () => {
    const errors = {};

    if (!PaymentDetials.PaymentType.trim()) {
      errors.PaymentType = "This is required";
    }

    if (!PaymentDetials.CreditCardName.trim()) {
      errors.CreditCardName = "This is required";
    }

    if (!PaymentDetials.ExpirationDate) {
      errors.ExpirationDate = "This is required";
    }

    if (!PaymentDetials.SecurityCode.trim()) {
      errors.SecurityCode = "This is requried";
    } else if (!/^\d{4}$/.test(PaymentDetials.SecurityCode.trim())) {
      errors.SecurityCode = "This must be 4 numbers";
    }

    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      console.log("Form submitted successfully");
      console.log(PaymentDetials.PaymentType);
      console.log(PaymentDetials.CreditCardName);
      console.log(PaymentDetials.ExpirationDate);
      console.log(PaymentDetials.SecurityCode);
    }
  };

  return (
    <Grid container spacing={2} style={{ width: "100%", margin: 0 }}>
      <Grid item xs={1} md={1} lg={1}></Grid>
      <Grid
        item
        xs={10}
        md={10}
        lg={10}
        className="box-container"
        style={{ display: "flex", padding: 12, gap: 3 }}
      >
        <div className="box-header-2">
          <Typography className="primary-font-bold-2-heading">
            Payment Method
          </Typography>
        </div>
        <Grid item xs={10} md={6} lg={6} style={{ paddingLeft: 40 }}>
          <div className="full-width" style={{ width: "80%" }}>
          {errors.PaymentType && (
              <Typography className="font-normal-small error-message">
                {errors.PaymentType}
              </Typography>
            )}
            <Typography className="font-normal-small profile-input-label">
              Payment Type
            </Typography>
            
            <input
              id="PaymentType"
              name="PaymentType"
              className={`profile-input ${!errors.PaymentType == '' ? 'err-input' : ''}`}
              value={PaymentDetials.PaymentType}
              onChange={handleInputChange}
            />
          </div>
          <div className="full-width" style={{ width: "80%" }}>
          {errors.CreditCardName && (
              <Typography className="font-normal-small error-message">
                {errors.CreditCardName}
              </Typography>
            )}
            <Typography className="font-normal-small profile-input-label">
              Credit Card Name
            </Typography>
            <input
              id="CreditCardName"
              name="CreditCardName"
              className={`profile-input ${!errors.CreditCardName == '' ? 'err-input' : ''}`}
              value={PaymentDetials.CreditCardName}
              onChange={handleInputChange}
            />
          </div>
          <Grid style={{ paddingLeft: 10 }}>
            <div className="flex-boxes">
              <div>
              {errors.ExpirationDate && (
                  <Typography className="font-normal-small error-message">
                    {errors.ExpirationDate}
                  </Typography>
                )}
                <Typography className="font-normal-small profile-input-label">
                  Expiration Date
                </Typography>
                <DatePicker
                  minDate={new Date(1900, 0, 1)}
                  name="ExpirationDate"
                  value={PaymentDetials.ExpirationDate}
                  onChange={handleDateChange}
                  className={`profile-input ${!errors.ExpirationDate == '' ? 'err-input' : ''}`}
                />
              </div>
              <div>
              {errors.SecurityCode && (
                  <Typography className="font-normal-small error-message">
                    {errors.SecurityCode}
                  </Typography>
                )}
                <Typography className="font-normal-small profile-input-label">
                  Security Code
                </Typography>
                

                <div className="input-holder" style={{}}>
                  <input
                    name="SecurityCode"
                    id="SecurityCode"
                    className={`profile-input ${!errors.SecurityCode == '' ? 'err-input' : ''}`}
                    aria-label="Security Code"
                    type={showCode ? "text" : "password"}
                    maxLength={4}
                    value={PaymentDetials.SecurityCode}
                    onChange={handleInputChange}
                    style={{width:"100%"}}
                  />

                  <IconButton
                    type="button"
                    sx={{ p: "10px",marginLeft:"100Px" }}
                    aria-label="Toggle Security Code Visibility"
                    className="input-eye-icon pwd-icon1"
                    onClick={handleClickShowCode}
                  >
                    {showCode ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </div>
              </div>
            </div>
          </Grid>
          <Grid
            className="box-container"
            style={{ gap: 20, boxShadow: "none" }}
          >
            <Button
              variant="outlined"
              className="primary-font-bold-5 action-btn profile-cancel-btn"
              onClick={handleSave}
            >
              Confirm
            </Button>
            <Button
              variant="outlined"
              className="primary-font-bold-5 action-btn profile-save-btn"
              onClick={() => {
                navigate("/my-subscription");
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1} md={1} lg={1}></Grid>
    </Grid>
  );
};

const PaymentMethod = () => {
  return (
    <ContentWrapper
      title={"My Subscription"}
      gobackUrl={"/my-subscription"}
      contenview={<Paymentmethod />}
      img={false}
    />
  );
};

export default PaymentMethod;
