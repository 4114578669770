import React from 'react';
import ContentWrapper from '../../../../common/components/ContentWrapper';
import ManagePassword from './ManagePassword';
const ManageProfile = () => {
  return (
    <>
      <ContentWrapper title={"MANAGE PASSWORDS"} gobackUrl={'/'} img={false} goback={false} contenview={<ManagePassword openPopUp={true} />} />
    </>
  )
}

export default ManageProfile;
