import React, { useState, useRef } from "react";
import "../../../../index.css";
import "../styles/home.css";
import {
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import LockIcon from "../../../../assets/images/security-lock.svg";
import { setSecurityLock } from "../redux/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as formatters from "../../../../utils/util";
import CloseIcon from "../../../../assets/icons/modal-close-icon.svg";
import info from "../../../../assets/icons/info-i-icon.svg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#FF8620",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    color: "white",
    // backgroundColor: '#FFE8AA',
    // border: ' 1px solid #FF8620 ',
    borderRadius: "7px",
    minWidth: 405,
    padding: "15px",
    fontSize: "16px",
    fontWeight: 400,
    maxWidth:600,
    [theme.breakpoints.down('sm')]: {
      minWidth: "280px",
      maxWidth:'300px', 
      padding: '8px', 
      marginLeft: '0px', 
    },
  },
}));

const SecurityLockDialog = ({
  openDialog,
  lockData,
  lockError,
  isVerifyingLock,
  closeDialog,
}) => {
  const [openModal, setOpenModal] = useState(openDialog);
  const [pin, setPin] = useState(["", "", "", ""]);
  const [securityLockErr, setSecurityLockErr] = useState("");
  const refs = [useRef(), useRef(), useRef(), useRef()];

  const handleInputChange = (index, value) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newPin = [...pin];
      newPin[index] = formatters.isNumaric(value);
      setPin(newPin);

      if (value.length === 1 && index < refs.length - 1) {
        refs[index + 1].current.focus();
      }
    }
  };
  const resetErrorMessages = () => {
    setSecurityLockErr("");
  };
  const onSubmit = () => {
    const securityLock = pin.join("");
    let isValid = false;
    resetErrorMessages();
    if (securityLock?.trim().length === 0) {
      setSecurityLockErr("This is required.");
      isValid = true;
    } else if (securityLock.length !== 4) {
      setSecurityLockErr("Enter valid Security Lock.");
      isValid = true;
    }
    if (isValid) {
      return;
    }
    const payload = {
      securityLock: securityLock,
      id: user?.id,
      updatedBy: user?.id,
    };
    setSecurityLock(payload);
    setOpenModal(false);
  };
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && pin[index] === "") {
      refs[index - 1].current.focus();
    } else if (e.key === "ArrowLeft" && index > 0) {
      refs[index - 1].current.focus();
    } else if (e.key === "ArrowRight" && index < refs.length - 1) {
      refs[index + 1].current.focus();
    }
  };

  const user = window.localStorage.getItem("userInfo")
    ? JSON.parse(window.localStorage.getItem("userInfo"))
    : null;

  return (
    <Dialog
      open={openModal}
      // onClose={handleCloseDialog}
      className="small-dialog dialog-box"
      aria-labelledby="small-dialog-title"
      aria-describedby="small-dialog-description"
    >
      <DialogContent>
        <div style={{ float: "right" }}>
          <img
            src={CloseIcon}
            alt="close-icon"
            style={{ cursor: "pointer",width:"21px" }}
            onClick={closeDialog}
          />
        </div>

        <div style={{ float: "left" }}>
          <LightTooltip
            enterTouchDelay={0}
            leaveTouchDelay={5000}
            arrow
            placement="bottom"
            title="Security Lock - 6-digit security code to unlock the system"
          >
            <img src={info} alt="" height="25px" width="25px" />
          </LightTooltip>
        </div>

        <div className="content-dialog">
          <img
            src={LockIcon}
            alt="phone"
            style={{ marginRight: 10 }}
            width="87px"
            height="87px"
          />
          <Typography className="primary-font-medium-small">
            <b>Please set your Secure Lock to continue</b>
          </Typography>
          <div>
            <Typography className="font-normal-small error-message">
              {securityLockErr}
            </Typography>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            {pin.map((digit, index) => (
              <TextField
                key={index}
                type="password"
                variant="outlined"
                inputProps={{ maxLength: 1 }}
                value={digit}
                id={`input-${index}`}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onChange={(e) => handleInputChange(index, e.target.value)}
                inputRef={refs[index]}
                className="security-input"
                style={{
                  width: "50px",
                  textAlign: "center",
                  boxShadow: digit ? "0px 2px 8px #00000029" : "none",
                  transition: "box-shadow 0.2s ease-in-out",
                }}
              />
            ))}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <div
          className="action-holder"
          style={{ marginTop: 0, marginBottom: 0 }}
        >
          <Button
            variant="outlined"
            className="primary-font-bold-5 action-btn-yes profile-save-btn"
            style={{ width: "70%" }}
            onClick={onSubmit}
          >
            Submit
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

SecurityLockDialog.defaultProps = {
  lockData: null,
  lockError: null,
  isVerifyingLock: null,
};

function mapStateToProps(state) {
  return {
    pinData: state.home.lockData,
    lockError: state.home.lockError,
    isVerifyingLock: state.home.isVerifyingLock,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setSecurityLock,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SecurityLockDialog);
