import React, {useState} from "react";
import "../../../../../../index.css";
import { Grid, Box} from "@mui/material";
import ContentWrapper from '../../../../../../common/components/ContentWrapper';
import CustomStepper from '../../../../../../common/components/CustomStepper';
import { useNavigate } from "react-router-dom";
import IdentificationDetails from "./IdentificationDetails";
import IssueDetails from "./IssueDetails";


const MainLayout = () => {
    const navigate = useNavigate();

    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());

    const isStepOptional = (step) => {
      return step === 1;
    };

    const isStepSkipped = (step) => {
      return skipped.has(step);
    };

    const handleNext = () => {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    };

    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
      if (!isStepOptional(activeStep)) {
        // You probably want to guard against something like this,
        // it should never occur unless someone's actively trying to break something.
        throw new Error("You can't skip a step that isn't optional.");
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
    };

    const handleReset = () => {
      setActiveStep(0);
    };

    const onCancelClick = () => {
      navigate(-1); // Navigate back one step in the history
    };

    const onBackOrCancelClick = () => {
      if(activeStep === 0 ){
        onCancelClick();
      } else {
        handleBack();
      }
    }

    const steps = [{
      label: "Identification Details",
      component: <IdentificationDetails onBackOrCancelClick={onBackOrCancelClick} handleNext={handleNext}/>
    },
    {
      label: "Issue Details",
      component: <IssueDetails onBackOrCancelClick={onBackOrCancelClick} handleNext={handleNext}/>
    }];
  return (
    <Grid container spacing={2} style={{margin:0, width: '100%'}}>
      <Grid item xs={1} md={1} lg={1}></Grid>
      <Grid
        item
        xs={10}
        md={10}
        lg={10}
        className="box-container"
        style={{padding: 0}}
      >
        <Box sx={{ width: '100%'}}>
          <CustomStepper
           steps={steps} 
           activeStep={activeStep} 
           img={false} 
           />
        </Box>
        
      </Grid>
      <Grid item xs={1} md={1} lg={1}></Grid>
    </Grid>
  );
};

const AddMyIdentificationDetails = () => {
    return (
      <ContentWrapper title={"MY IDENTIFICATIONS"} goback={true} contenview={<MainLayout />} />
    )
  }

export default AddMyIdentificationDetails;