import React, { useState, useEffect } from "react";
import "../../../../../../index.css";
import { Grid, Button, TextField, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { connect , useDispatch} from 'react-redux';
import { bindActionCreators } from "redux";
import { addSpecialInstruction, getSpecialInstruction } from "../../../redux/actions";


const SpecialInstructions = ({ onContinue, onCancel, selectedUser, addSpecialInstruction, splInstructions, isFetching, getSpecialInstruction, splInstructionsData,onView }) => {
  const [specialInstructions, setSpecialInstructions] = useState(null);
  const [specialInstructionsErr, setSpecialInstructionsErr] = useState('');
  const dispatch = useDispatch();
  const id = selectedUser?.userId;
  const navigate = useNavigate();
  useEffect(() => {
    console.log("Special Instaruction ", id)
    if(id){
      getSpecialInstruction(id);
    }
  }, [])

  useEffect(() => {
    if (splInstructionsData && splInstructionsData.status === 200) {
      setSpecialInstructions(splInstructionsData?.data?.specialInstructions)
    }
    if (splInstructionsData && splInstructionsData.status !== 200) {
    }
  }, [splInstructionsData])

  useEffect(() => {
    if (splInstructions && splInstructions.status === 200) {
      dispatch({
        type:"RESET_SPECIAL_INSTRUCTION",
        action: null
      })
      navigate('/my-family')
    }
    if (splInstructions && splInstructions.status !== 200) {
    }
  }, [splInstructions])

  const onSave = () => {
    const payload = {
      userId: selectedUser?.userId,
      specialInstructions: specialInstructions
    }
    addSpecialInstruction(payload);
  }
  
  return (
    <Grid container spacing={2} sx={{margin:0,width:"100% !important"}}>
      
        <Typography className='font-normal-small error-message'>{specialInstructionsErr}</Typography>
        <Grid container spacing={2} sx={{margin:0, height: 'auto',width:"100% !important"}}>
            <Typography className='font-normal-small profile-input-label'>Special Instructions</Typography>
            <TextField
            placeholder=""
            className="txt-area"
            multiline
            value={specialInstructions}
            onChange={(e)=> {setSpecialInstructions(e.target.value)}}
            rows={10}
            maxRows={20}
            style={{width: '100%'}}
            />
            <div className="action-holder action-bg" style={{marginTop:onView?"35px":""}}>
                <Button variant="outlined" className="primary-font-actionbtn action-btn profile-cancel-btn" onClick={onCancel}>Back</Button>
                <Button variant="outlined" disabled={isFetching} className="primary-font-actionbtn action-btn profile-save-btn" onClick={onSave}>Save</Button>
            </div>
        
      </Grid>
    </Grid>
  );
};


SpecialInstructions.defaultProps = {
  isFetching: null,
  error: null,
  splInstructions: null,
  splInstructionsData: null
};

function mapStateToProps(state) {
  return {
    isFetching: state.familyInfo.isFetching,
    error: state.familyInfo.error,
    splInstructions: state.familyInfo.splInstructions,
    splInstructionsData: state.familyInfo.splInstructionsData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addSpecialInstruction,
      getSpecialInstruction
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpecialInstructions);

