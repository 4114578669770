import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Grid,
  Card,
  CardActions,
  Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AddMessages from "./AddMessages";
import EditMessage from "./EditMessages";
import RemoveDialog from "../../../common/RemoveDialog";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import {
  getAllPredefinedMessages,
  deletePredefinedMessages,
  updatePredefinedMessages,
} from "../../redux/actions";
import ErrorIcon from "../../../../../assets/icons/error-icon.svg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Category } from "@mui/icons-material";
import "../PreDefinedMessages/styles/PredefinedMessages.css";

const StyledCard = styled(Card)({
  display: "flex",
  justifyContent: "space-between",
  alignContent: "center",
  width: "100%",
  flexDirection: "row",
  borderRadius: "10px",
  height: "60px",
  // margin: "10px",
  marginBottom:"10px",
  border: "1px solid #D8D8D8",
  "&:hover": {
    border: "1px solid #FF3535",
    boxShadow: "0px 2px 8px #00000033",
  },
});

const disasterMap = {
  // "Active Shooter" : "activeShooter",
  // "Earth Quake": "earthquake",
  // "Flood": "flashFlood",
  // "Hurricane": "hurricane",
  // "Thunder": "severeStorm",
  // "Tornado": "tornado",
  // "Wild Fire": "Others",
  // "Winter Storm": "severeStorm",
  "Active Shooter": "Active Shooter",
  "Earth Quake": "Earthquake",
  Flood: "Flash Flood",
  Hurricane: "Hurricane",
  Thunder: "Severe Storm",
  Tornado: "Tornado",
  "Wild Fire": "Others",
  "Winter Storm": "Severe Storm",
};
const ViewPredfinedMessages = ({
  showDelete,
  showEdit,
  getAllPredefinedMessages,
  predefinedMesages,
  deletePredefinedMessages,
  deleteMessage,
  updateMesssage,
  updatePredefinedMessages,
  onBackOrCancelClick,
  handleNext,
  Crud,
  emergencyAlert = false,
  addEmergencyAlert,
  categoryList,
  trackMe= false,
  addTrackMe
}) => {
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [editMessageDialogOpen, setEditMessageDialogOpen] = useState(false);
  const [addMessagesDialogOpen, setAddMessagesDialogOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [messageList, setMessageList] = useState([]);
  const [disableEdit, setDisableEdit] = useState(false);
  const [messageErr, setMessageErr] = useState("");
  const [totalLength, setTotalLength] = useState(0);
  const [associatedCategory, setAssociatedCategory] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    getAllPredefinedMessages();
  }, []);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      let ids = [];
      const keys = Object.keys(messageList).map((category) => {
        messageList[category].map((item, key) => {
          ids.push(item?.id);
          return item;
        });
      });
      setSelectedRows(ids);
    } else {
      setSelectedRows([]);
    }
  };

  useEffect(() => {
    console.log(predefinedMesages);
    if (predefinedMesages && predefinedMesages.status === 200) {
      setMessageList(predefinedMesages.data);
      let count = 0;
      let flatternArray = [];
      predefinedMesages && predefinedMesages.data && Object.keys(predefinedMesages.data).map((category) => {
        if (
          (emergencyAlert === true &&
            associatedCategory &&
            associatedCategory === category) ||
          (emergencyAlert === true &&
            "others" === category.toLocaleLowerCase()) ||
          emergencyAlert === false
        ) {
          predefinedMesages.data[category].map((item, key) => {
            count = count + 1;
            flatternArray.push(item);
          });
        }
      });
      setTotalLength(count);
    } else if (predefinedMesages && predefinedMesages.status === 404) {
      setMessageList([]);
    }
  }, [predefinedMesages]);

  useEffect(() => {
    if (deleteMessage && deleteMessage.status === 200) {
      handleDialogClose(true);
    }
  }, [deleteMessage]);

  useEffect(() => {
    if (updateMesssage && updateMesssage.status === 200) {
      handleEditDialogClose(true);
    }
  }, [updateMesssage]);

  const handleUpdate = (message, category) => {
    const payload = { ...selectedCard };
    payload.message = message;
    payload.categoryCode = category;
    updatePredefinedMessages(payload);
  };
  const onCloseMessageDialog = (refresh = false) => {
    setAddMessagesDialogOpen(false);
    if (refresh) {
      getAllPredefinedMessages();
    }
  };

  const handleDialogOpen = (item) => {
    setRemoveDialogOpen(true);
    setSelectedCard(item);
  };
  const handleEditDialogOpen = (item) => {
    setEditMessageDialogOpen(true);
    setSelectedCard(item);
  };
  const handleEditDialogClose = (refresh = false) => {
    setEditMessageDialogOpen(false);
    setSelectedCard(null);
    if (refresh) {
      getAllPredefinedMessages();
    }
  };
  const handleDialogClose = (refresh = false) => {
    setRemoveDialogOpen(false);
    setSelectedCard(null);
    if (refresh) {
      getAllPredefinedMessages();
    }
  };
  const handleRemoveMessage = () => {
    deletePredefinedMessages(selectedCard);
  };
  const handleSelectRow = (event, id) => {
    const isSelected = selectedRows.includes(id);
  
    if (event) {
      if (event.target.checked) {
        setSelectedRows((prevSelected) => [...prevSelected, id]);
      } else {
        setSelectedRows((prevSelected) =>
          prevSelected.filter((selectedId) => selectedId !== id)
        );
      }
    } else {
      if (isSelected) {
        setSelectedRows((prevSelected) =>
          prevSelected.filter((selectedId) => selectedId !== id)
        );
      } else {
        setSelectedRows((prevSelected) => [...prevSelected, id]);
      }
    }
  };
  
  const handleMessageNext = () => {
    if (selectedRows.length > 0 && emergencyAlert === true) {
      const newData = { ...addEmergencyAlert };
      console.log(selectedRows, "selectedRows");
      let flaterArray = [];
      predefinedMesages && predefinedMesages.data && Object.keys(predefinedMesages.data).map((category) => {
        if (
          (emergencyAlert === true &&
            associatedCategory &&
            associatedCategory === category) ||
          (emergencyAlert === true &&
            "others" === category.toLocaleLowerCase()) ||
          emergencyAlert === false
        ) {
          predefinedMesages.data[category].map((item, key) => {
            flaterArray.push(item);
          });
        }
      });
      const selectedRowsDetails = flaterArray.filter((row) =>
        selectedRows.includes(row.id)
      );

      console.log(selectedRowsDetails, "selectedRowsDetails from emergecny");
      newData.message = selectedRowsDetails;
      console.log(newData, "fdfsdf");
      setMessageErr("");
      dispatch({
        type: "ADD_EMERGENCY_ALERT",
        payload: newData,
      });
      handleNext();
    } else {
      setMessageErr("Please select atleast one message");
      console.log("");
    }
  };
  const handleTrackMeMessageNext = () => {
    if (selectedRows.length > 0 && trackMe === true) {
      const newData = { ...addTrackMe };
      console.log(selectedRows, "selectedRows");
      console.log(newData)
      let flaterArray = [];
      predefinedMesages && predefinedMesages.data && Object.keys(predefinedMesages.data).map((category) => {
        if (
          (emergencyAlert === true &&
            associatedCategory &&
            associatedCategory === category) ||
          (emergencyAlert === true &&
            "others" === category.toLocaleLowerCase()) ||
          emergencyAlert === false
        ) {
          predefinedMesages.data[category].map((item, key) => {
            flaterArray.push(item);
          });
        }
      });
      const selectedRowsDetails = flaterArray.filter((row) =>
        selectedRows.includes(row.id)
      );

      console.log(selectedRowsDetails, "selectedRowsDetails from emergecny");
      newData.message = selectedRowsDetails;
      console.log(newData, "fdfsdf");
      setMessageErr("");
      dispatch({
        type: "ADD_TRACK_ME",
        payload: newData,
      });
      handleNext();
    } else {
      setMessageErr("Please select atleast one message");
      console.log("");
    }
  };
  useEffect(() => {
    if (addEmergencyAlert?.disaster) {
      if (!categoryList) {
        setAssociatedCategory("Others");
      } else {
        setAssociatedCategory(
          addEmergencyAlert?.disaster?.messagesCategoryCode
            ? categoryList[addEmergencyAlert?.disaster?.messagesCategoryCode]
            : "Others"
        );
      }
    }
    if (addEmergencyAlert && addEmergencyAlert.message) {
      const ids = [];
      let categoryCode = addEmergencyAlert.message[0]?.messagesCategoryCode;
      if (categoryCode && messageList && messageList.length > 0) {
        const keys = Object.keys(messageList[categoryCode]).map((category) => {
          messageList[category].map((item, key) => {
            ids.push(item?.id);
            return item;
          });
        });

        setSelectedRows(ids);
      } else {
        let flaterArray = [];
        predefinedMesages && predefinedMesages.data && Object.keys(predefinedMesages.data).map((category) => {
          if (
            (emergencyAlert === true &&
              associatedCategory &&
              associatedCategory === category) ||
            (emergencyAlert === true &&
              "others" === category.toLocaleLowerCase()) ||
            emergencyAlert === false
          ) {
            predefinedMesages.data[category].map((item, key) => {
              flaterArray.push(item);
            });
          }
        });
        addEmergencyAlert.message.map((item) => {
          const filterData = flaterArray.filter((data) => {
            return data.id === item.id;
          });
          if (filterData && filterData.length > 0) {
            ids.push(item?.id);
            return item;
          }
        });
        setSelectedRows(ids);
      }
    } else if (addEmergencyAlert && addEmergencyAlert.messagesList) {
      const ids = [];
      addEmergencyAlert.messagesList.map((item) => {
        ids.push(item?.id);
        return item;
      });

      setSelectedRows(ids);
    }
  }, [addEmergencyAlert, messageList, categoryList]);
  return (
    <Grid container  sx={{margin: 0}}>
      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        className={emergencyAlert === true ? "" : "box-container"}
        
        // sx={{ justifyContent: "center", alignItems: "center" }}
      >
        <div
          style={{ width: "100%", marginBottom: "10px" }}
          className="box-header"
        >
          <Typography className="primary-font-bold-2-heading">
            Select Message
          </Typography>

          <Button
            variant="outlined"
            className="primary-font-bold-6 action-btn-yes profile-save-btn"
            onClick={() => {
              setAddMessagesDialogOpen(true);
            }}
          >
            Add
          </Button>
        </div>
        <Grid container className='message-card'  style={{ margin: 0, width: '100%' }}>
        {messageErr.trim().length > 0 && (
          <div style={{ display: "flex" }}>
            <img src={ErrorIcon} alt="error" href="#" />
            <Typography
              className="primary-font-normal-1"
              style={{ marginLeft: 10, color: "red" }}
            >
              {messageErr}
            </Typography>
          </div>
        )}
        {emergencyAlert === true && (
          <Grid item lg={12} sx={{ display: "flex", paddingLeft: "10px" }}>
            {/* <Checkbox
              sx={{
                color: "red !important",
              }}
              checked={selectedRows.length === totalLength}
              onChange={handleSelectAll}
              inputProps={{ "aria-label": "select all rows" }}
            />
            <Typography className=".primary-font-normal-2" sx={{ mt: "10px" }}>
              {" "}
              Select All{" "}
            </Typography> */}
          </Grid>
        )}

        {Object.keys(messageList).map((category) => {
          return (
            <>
              {((emergencyAlert === true &&
                associatedCategory &&
                associatedCategory.toLocaleLowerCase() ===
                  category.toLocaleLowerCase()) ||
                (emergencyAlert === true &&
                  "others" === category.toLocaleLowerCase()) ||
                emergencyAlert === false) && (
                <>
               
                  <Accordion
                    style={{ width: "100%",borderTop: "1px solid #e0e0e0" }}
                    // expanded={(emergencyAlert === true)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="h6">{category}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {messageList[category].map((item, key) => {
                        return (
                          <StyledCard key={key + 1}>
                            <Grid
                              onClick={() => {
                                if (emergencyAlert === true) {
                                  handleSelectRow(null, item?.id);
                                } else {
                                  handleEditDialogOpen(item);
                                  setDisableEdit(true);
                                }
                              }}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {emergencyAlert === true && (
                                <Checkbox
                                  sx={{
                                    color: "red !important",
                                  }}
                                  checked={selectedRows.includes(item?.id)}
                                  onChange={(event) =>
                                    handleSelectRow(event, item?.id)
                                  }
                                  inputProps={{
                                    "aria-label": `select row ${item?.id}`,
                                  }}
                                />
                              )}
                              <Typography
                                title={item.message}
                                className="primary-font-medium"
                                style={{
                                  display: "-webkit-box",
                                  overflow: "hidden",
                                  webkitBoxOrient: "vertical",
                                  textOverflow: "ellipsis",
                                  webkitLineClamp: "2",
                                  cursor: "pointer",
                                }}
                                paddingLeft={1}
                              >
                                {item.message}
                              </Typography>
                            </Grid>
                            <CardActions
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "10",
                              }}
                            >
                              {showDelete && (
                                <div
                                  className={
                                    item.isDefault
                                      ? "inactive-button-dlt"
                                      : "action-button-dlt"
                                  }
                                  style={{cursor: !item.isDefault  ? "pointer" : "no-drop",display:item.isDefault?"none":"block"}}
                                  disabled={item.isDefault ? true : false}
                                  onClick={() => {
                                    if (!item.isDefault) {
                                      handleDialogOpen(item);
                                    }
                                  }}
                                ></div>
                                
                              )}
                              {showEdit && (
                                <div
                                  className="action-button-edit"
                                  style={{display:item.isDefault?"none":"block"}}
                                  onClick={() => {
                                    handleEditDialogOpen(item);
                                    setDisableEdit(false);
                                  }}
                                ></div>
                              )}
                            </CardActions>
                          </StyledCard>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                 
                </>
              )}
            </>
          );
        })}

        {messageList.length === 0 && (
         <div
         style={{
           display: "flex",
           justifyContent: "center", 
           alignItems: "center", 
           margin:"0 21px"
         }}
       >
         <Typography variant="h6">No Message Found</Typography>
       </div>
        )}
        <AddMessages
          openDialog={addMessagesDialogOpen}
          onClose={onCloseMessageDialog}
        />
        {editMessageDialogOpen && (
          <EditMessage
            selectedCard={selectedCard}
            openDialog={editMessageDialogOpen}
            onDialogClose={handleEditDialogClose}
            disableEdit={disableEdit}
            onDialogueSave={handleUpdate}
          />
        )}
        {removeDialogOpen && (
          <RemoveDialog
            openDialog={removeDialogOpen}
            onClose={handleDialogClose}
            cardContent={"Are you sure to delete?"}
            onSave={handleRemoveMessage}
          />
        )}
        {Crud ? (
            <>
            {
              trackMe ? (
                <div
                className="action-holder"
                style={{ marginTop: 40, marginBottom: 10 }}
              >
                <Button
                  variant="outlined"
                  className="primary-font-bold-6 action-btn profile-cancel-btn"
                  onClick={onBackOrCancelClick}
                >
                  Back
                </Button>
                <Button
                  variant="outlined"
                  className="primary-font-bold-6 action-btn profile-save-btn"
                  onClick={handleTrackMeMessageNext}
                >
                  Request
                </Button>
              </div>
              ) : (
                <div
                className="action-holder"
                style={{ marginTop: 40, marginBottom: 10 }}
              >
                <Button
                  variant="outlined"
                  className="primary-font-bold-6 action-btn profile-cancel-btn"
                  onClick={onBackOrCancelClick}
                >
                  Back
                </Button>
                <Button
                  variant="outlined"
                  className="primary-font-bold-6 action-btn profile-save-btn"
                  onClick={handleMessageNext}
                >
                  Next
                </Button>
              </div>
              )
            }
            </>
        ) : (
          ""
        )}
        </Grid>
      </Grid>
      {/* </div> */}
    </Grid>
  );
};

function mapStateToProps(state) {
  return {
    predefinedMesages: state.setting.predefinedMesages,
    deleteMessage: state.setting.deleteMessage,
    updateMesssage: state.setting.updateMesssage,
    addEmergencyAlert: state.emergencyAlert.addEmergencyAlert,
    addTrackMe : state.trackMe.addTrackMe,
    categoryList: state.setting.categoryList,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAllPredefinedMessages,
      deletePredefinedMessages,
      updatePredefinedMessages,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewPredfinedMessages);
