import React, { useState, useEffect } from "react";
import "../../../../../index.css";
import "../MyFamily/styles/MyFamilyStyles.css"
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Select, 
  MenuItem, 
  FormControl, 
  Box
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as formatters from "../../../../../utils/util";
import UserAvatar from "../../../../../assets/icons/user-avatar.svg";
import Edit from "../../../../../assets/icons/edit.svg";
import CloseIcon from "../../../../../assets/icons/modal-close-icon.svg";
import UploadImage from "../../../../../assets/icons/upload-icon.svg";
import AvatarIcon from "../../../../../common/components/Avatar";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { addMember,getRelationshipList,uploadCommonUtilityPicture,createCognitoUser, deleteUser } from "../../redux/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment/moment";

const AddFamilyMembers = (props) => {
  const { addMember, createuser,getRelationshipList,relationship,createCognitoUser, deleteUser,signUpAccount, profilePictureData, profilePictureError,uploadCommonUtilityPicture } = props;
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const userDetails = window.localStorage.getItem("userInfo");
  const userDetailsObject = JSON.parse(userDetails);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrlAvatar1, setImageUrlAvatar1] = useState(null);
  const [imageUrlAvatar, setImageUrlAvatar] = useState(null);

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [dob, setDOB] = useState(null);
  const [ssn, setSSN] = useState("");
  const [relation, setRelation] = useState(null);
  const [phoneNumber, setphoneNumber] = useState(null);
  const [email, setEmail] = useState(null);

  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [dobErr, setDOBErr] = useState("");
  const [ssnErr, setSSNErr] = useState("");
  const [relationErr, setRelationErr] = useState("");
  const [phoneNumberErr, setphoneNumberErr] = useState("");
  const [emailErr, setEmailErr] = useState("");

  const [openPhotoUploadModal, setOpenPhotoUploadModal] = useState(false);
  const [image, setImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [isAvatarSelection, setIsAvatarSelection] = useState(false);
  const [selectedAvatarData, setSelectedAvatarData] = useState(null);
  const [s3Key, setS3Key] = useState(null);

  useEffect(()=>{
  getRelationshipList();
  },[])
  const filteredDataSelf = {};
  const filteredDataNotSelf = {};
    for (const key in relationship) {
      if (key === "self") {
        filteredDataSelf[key] = relationship[key];
      } else {
        filteredDataNotSelf[key] = relationship[key];
      }
    }
  useEffect(()=>{
    console.log(profilePictureData)
    if(profilePictureData && profilePictureData.status === 200){
      setS3Key(profilePictureData?.data?.s3Key);
      setImageUrlAvatar(imageUrlAvatar1);
      handleClose();
    }
    // if(profilePictureData && profilePictureData.status !== 200){
    // }
  },[profilePictureData, profilePictureError])
  const handleFile = (file) => {
    //you can carry out any file validations here...
    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    setImageUrlAvatar1(URL.createObjectURL(file));
  };
  const handleOnDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    //let's grab the image file
    let imageFile = event.dataTransfer.files[0];
    handleFile(imageFile);
    setIsAvatarSelection(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsAvatarSelection(false);
  };

  const saveAvatarIcon = () => {
    // setImageUrlAvatar(imageUrlAvatar1);
    
    if(isAvatarSelection){
      setS3Key(selectedAvatarData?.imageSrcKey);
      setOpenPhotoUploadModal(false);
      setPreviewUrl('');
      handleClose();
    }else{
      let fd = new FormData();
      fd.set("userId", userDetailsObject.id);
      fd.set("file", image);
      fd.set("accountId", userDetailsObject.accountId);
      fd.set('fileTag',  'ProfilePicture')
      uploadCommonUtilityPicture(fd);
    }
  };

  const handleClose = () => {
    setOpenPhotoUploadModal(false);
    setPreviewUrl("");
    setIsAvatarSelection(false);
  };

  useEffect(() => {
    if (selectedImage) {
      setImageUrlAvatar1(URL.createObjectURL(selectedImage));
      setPreviewUrl(URL.createObjectURL(selectedImage));
      setIsAvatarSelection(false);
    }
  }, [selectedImage]);

  useEffect(() => {
    if (createuser && createuser.status === 200) {
      createCognitoUser({
        "username": email,
        "password": new Date().getTime().toString()+'_test_password',
      })
      navigate("/my-family-members");
    } else if (createuser && createuser.status) {
      setEmailErr(createuser.message);
    }
  }, [createuser]);

  const onChangeInput = (e, type) => {
    if (type === "firstName") {
      setFirstName(e.target.value);
    } else if (type === "lastName") {
      setLastName(e.target.value);
    } else if (type === "dob") {
      let date = e
      if(e){
        date = moment(e).format('MM-DD-YYYY')
      }
      setDOB(date)
    } else if (type === "ssn") {
      let ssnNum = formatters.isNumaric(e.target.value);
      setSSN(ssnNum);
    } else if (type === "relation") {
      setRelation(e.target.value);
    } else if (type === "phoneNumber") {
      setphoneNumber(e.target.value);
    } else if (type === "email") {
      setEmail(e.target.value);
    }
  };

  const resetErrorMessages = () => {
    setFirstNameErr("");
    setLastNameErr("");
    setDOBErr("");
    setSSNErr("");
    setRelationErr("");
    setphoneNumberErr("");
    setEmailErr("");
  };

  const onSave = () => {
    let isValid = false;
    resetErrorMessages();
    if (!firstName?.trim()?.length) {
      setFirstNameErr("This is required.");
      isValid = true;
    }
    if (!lastName?.trim()?.length) {
      setLastNameErr("This is required.");
      isValid = true;
    }
    if (!dob) {
      setDOBErr("This is required.");
      isValid = true;
    }

    // if (!ssn?.trim().length) {
    //   setSSNErr("This is required.");
    //   isValid = true;
    // } else 
    if (ssn?.trim()?.length && ssn?.length !== 9) {
      setSSNErr("Enter valid SSN.");
      isValid = true;
    }
    if (!relation?.trim()?.length) {
      setRelationErr("This is required.");
      isValid = true;
    }
    if (!phoneNumber?.trim()?.length) {
      setphoneNumberErr("This is required.");
      isValid = true;
    } else if (phoneNumber?.length !== 10) {
      setphoneNumberErr("Enter valid Phone number.");
      isValid = true;
    }
    if (!email?.trim()?.length) {
      setEmailErr("This is required.");
      isValid = true;
    } else if (!formatters.isEmailValid(email)) {
      setEmailErr("Enter valid Email.");
      isValid = true;
    }
    if (isValid) {
      return;
    }
   
    const accountId = userDetailsObject.accountId;
    const createdBy = userDetailsObject.id;
    const createdAt = userDetailsObject.createdAt;
    const payload = {
      accountId: accountId,
      firstName: firstName,
      middleName: "",
      lastName: lastName,
      dateOfBirth: dob,
      socialSecurityNo: ssn,
      relationshipCode: relation,
      phoneNo: phoneNumber,
      emailId: email,
      userRoleCode: "member",
      statusCode: "pending",
      image: s3Key,
      createdBy: createdBy,
      createdAt: createdAt,
    };
    addMember(payload);
  };

  return (
    <Grid container  style={{ margin: 0, width: "100%" }}>
    <div className="myfamily-container family-edit-form-align">
      <Grid item xs={12} md={12} lg={12} className="box-container myfamily-edit-form">
        <Grid container spacing={2} style={{ margin: 0 }}>
          <Grid item xs={12} md={6} className="left-box">
            <div style={{ position: "relative" }}>
              {imageUrlAvatar && (
                <img
                  src={imageUrlAvatar}
                  alt="UserAvatar"
                  className='avatar-upload-icon'
                />
              )}
              {!selectedImage && !imageUrlAvatar && (
                <img src={UserAvatar} alt="UserAvatar" className='avatar-upload-icon' />
              )}
              <input
                accept="image/*"
                type="file"
                id="select-image"
                style={{ display: "none" }}
                onChange={(e) => {setImage(e.target.files[0]);
                  setSelectedImage(e.target.files[0])}}
              />
              {/* <label htmlFor="select-image"> */}
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  top: 20,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenPhotoUploadModal(true);
                }}
              >
                <img src={Edit} alt="edit" />
              </div>
              {/* </label> */}
            </div>
            <div className="input-container-btm">
              <div>
                <Typography className="font-normal-small error-message">
                  {firstNameErr}
                </Typography>
                <Typography className="font-normal-small profile-input-label">
                  {t("First Name")}<span style={{color:"red"}}>{" *"}</span>
                </Typography>
                <input
                  className={`profile-input ${
                    firstNameErr !== "" ? "err-input" : ""
                  }`}
                  aria-label="first name"
                  onChange={(e) => {
                    onChangeInput(e, "firstName");
                  }}
                  value={firstName}
                />
              </div>
              <div>
                <Typography className="font-normal-small error-message">
                  {lastNameErr}
                </Typography>
                <Typography className="font-normal-small profile-input-label">
                  {t("Last Name")}<span style={{color:"red"}}>{" *"}</span>
                </Typography>
                <input
                  className={`profile-input ${
                    lastNameErr !== "" ? "err-input" : ""
                  }`}
                  aria-label="last name"
                  onChange={(e) => {
                    onChangeInput(e, "lastName");
                  }}
                  value={lastName}
                />
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ paddingLeft: 30, paddingRight: 32 }}
          >
            <div>
              <Typography className="font-normal-small error-message">
                {dobErr}
              </Typography>
              <Typography className="font-normal-small profile-input-label">
                {t("Date of Birth")}<span style={{color:"red"}}>{" *"}</span>
              </Typography>
              <DatePicker
                className={`profile-input ${dobErr !== "" ? "err-input" : ""}`}
                value={dob}
                minDate={new Date(1900, 0, 1)}
                onChange={(e) => {
                  onChangeInput(e, "dob");
                }}
                maxDate={new Date()}
              />
            </div>
            <div>
              <Typography className="font-normal-small error-message">
                {ssnErr}
              </Typography>
              <Typography className="font-normal-small profile-input-label">
                {t("Social Security Number")}{" "}
              </Typography>
              <input
                className={`profile-input ${ssnErr !== "" ? "err-input" : ""}`}
                aria-label="ssn"
                onChange={(e) => {
                  onChangeInput(e, "ssn");
                }}
                maxLength={11}
                value={ssn ? formatters.formatSSNNumber(ssn): ssn}
              />
            </div>
            <div>
              <Typography className="font-normal-small error-message">
                {relationErr}
              </Typography>
              <Typography className="font-normal-small profile-input-label">
                {t("Relationship with Plan Owner")}<span style={{color:"red"}}>{" *"}</span>
              </Typography>
              <FormControl style={{marginRight: 0, marginLeft:0, marginTop: 0, marginBottom: 20, width: '100%'}}>
                      <Select
                        labelId="demo-simple-select-label"
                        label="Relation"
                        className="relation-select relationship-dropdown"
                        value={(value) => {
                          return (
                            <Box sx={{ display: "flex", gap: 1 }}>
                              {relation}
                            </Box>
                          );
                        }}
                        onChange={(e)=> {onChangeInput(e, 'relation')}}
                        renderValue={(value) => {
                          return (
                            <Box sx={{ display: "flex", gap: 1 }}>
                              {relationship[relation]}
                            </Box>
                          );
                        }}
                
                      >
                        {
                          Object.keys(relation === 'self' ? filteredDataSelf : filteredDataNotSelf).map((item) => {
                            return (<MenuItem value={item}>{relationship[item]}</MenuItem>)
                            })
                        }
                      </Select>
                    </FormControl>
              {/* <input
                className={`profile-input ${
                  relationErr !== "" ? "err-input" : ""
                }`}
                aria-label="relation"
                onChange={(e) => {
                  onChangeInput(e, "relation");
                }}
                value={relation}
              /> */}
            </div>
            <div>
              <Typography className="font-normal-small error-message">
                {phoneNumberErr}
              </Typography>
              <Typography className="font-normal-small profile-input-label">
                {t("Phone Number")}<span style={{color:"red"}}>{" *"}</span>
              </Typography>
              <input
                className={`profile-input ${
                  phoneNumberErr !== "" ? "err-input" : ""
                }`}
                aria-label="phone number"
                onChange={(e) => {
                  onChangeInput(e, "phoneNumber");
                }}
                maxLength={10}
                value={formatters.formatPhoneNumber(phoneNumber)}
              />
            </div>
            <div>
              <Typography className="font-normal-small error-message">
                {emailErr}
              </Typography>
              <Typography className="font-normal-small profile-input-label">
                {t("Email ID")}<span style={{color:"red"}}>{" *"}</span>
              </Typography>
              <input
                className={`profile-input ${
                  emailErr !== "" ? "err-input" : ""
                }`}
                aria-label="email"
                onChange={(e) => {
                  onChangeInput(e, "email");
                }}
                value={email}
              />
            </div>
          </Grid>
          <div className="action-holder action-holder-3 btn-holder">
            <Button
              variant="outlined"
              className="primary-font-bold-6 btn-width profile-cancel-btn"
              onClick={() => {
                navigate("/my-family-members");
              }}
            >
              {t("Cancel")}
            </Button>
            <Button
              variant="outlined"
              className="primary-font-bold-6 btn-width profile-save-btn"
              onClick={() => {
                onSave();
              }}
            >
              {t("Save")}
            </Button>
          </div>
        </Grid>
        {openPhotoUploadModal && (
          <Dialog
            open={openPhotoUploadModal}
            onClose={handleClose}
            className="photo-dialog dialog-box"
            aria-labelledby="photo-dialog-title"
            aria-describedby="photo-dialog-description"
          >
            <DialogTitle id="photo-dialog-title">
              <Typography className="primary-font-bold-heading2">
                {t("Photo upload")}
              </Typography>
              {openPhotoUploadModal ? (
                <img
                  src={CloseIcon}
                  alt="close-icon"
                  style={{ cursor: "pointer",width:"21px" }}
                  onClick={handleClose}
                  sx={{
                    marginRight: "-30px",
                  }}
                />
              ) : null}
            </DialogTitle>
            <Divider />
            <DialogContent
              style={{ padding: "20px 32px 40px 32px" , overflowX:"hidden", overflowY:"scroll"}}
            >
              <Grid container spacing={2} style={{ margin: 0, height: "100%"}}>
                <Grid
                  item
                  xs={12}
                  md={7}
                  style={{ padding: 0, height: "100%",overflowY:'scroll'}}
                >
                  <Typography
                    className="primary-font-medium profile-input-label"
                    style={{ textAlign: "center" }}
                  >
                    {t("Choose Avatar")}
                  </Typography>
                  <div>
                    {AvatarIcon.map((item, i) => {
                      return (
                        <div
                          className="avatar-photo-icon "
                          onClick={() => {
                            setImageUrlAvatar1(item.imgSrc); 
                            setPreviewUrl(item.imgSrc);
                            setIsAvatarSelection(true)
                            setSelectedAvatarData(item)
                            setImageUrlAvatar(item.imgSrc)
                          }}
                        >
                          {" "}
                          <Button>
                            <img src={item.imgSrc} alt={item.imgAlt} />
                          </Button>{" "}
                        </div>
                      );
                    })}
                  </div>
                </Grid>
                <Grid item xs={12} md={5} style={{ paddingLeft: 0}}>
                  <label htmlFor="select-image">
                    <div
                      className="photo-upload-container drop_zone"
                      onDragOver={handleDragOver}
                      onDrop={handleOnDrop}
                    >
                      <img
                        src={UploadImage}
                        alt={"upload-avatar"}
                        style={{ cursor: "pointer" }}
                      />
                      <Typography className="font-normal-small profile-input-label">
                        {t("Drag and drop a file here or click")}
                      </Typography>
                      {previewUrl && (
                        <div className="image">
                          <img
                            src={previewUrl}
                            alt="image"
                            width={100}
                            height={100}
                          />
                        </div>
                      )}
                    </div>
                  </label>
                </Grid>
              </Grid>
            </DialogContent>
            <Divider />
            <DialogActions>
              <div
                className="action-holder"
                style={{ marginTop: 20, marginBottom: 0 }}
              >
                <Button
                  variant="outlined"
                  className="primary-font-bold-6  btn-width profile-cancel-btn"
                  onClick={handleClose}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  variant="outlined"
                  className="primary-font-bold-6  btn-width profile-save-btn"
                  onClick={saveAvatarIcon}
                >
                  {t("Upload")}
                </Button>
              </div>
            </DialogActions>
          </Dialog>
        )}
      </Grid>
      </div>
    </Grid>
  );
};
function mapStateToProps(state) {
  return {
    createuser: state.setting.createuser,
    isFetching: state.setting.isFetching,
    error: state.setting.error,
    relationship: state.setting.relationship,
    profilePictureData: state.setting.profilePictureData,
    profilePictureError: state.setting.profilePictureError,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addMember,
      getRelationshipList,
      uploadCommonUtilityPicture,
      createCognitoUser,
      deleteUser
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AddFamilyMembers);
