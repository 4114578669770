import React, { useState, useEffect } from "react";
import "../../../../../index.css";
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider,Typography, Button, Switch, FormControl, Select, Box, MenuItem } from "@mui/material";
import CloseIcon from '../../../../../assets/icons/modal-close-icon.svg';
import UploadImage from '../../../../../assets/icons/upload-icon.svg';
import { uploadCommonUtilityPicture, createNewDisaster, getDisasterList, getAllCategory, } from "../../redux/actions";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import FormControlLabel from '@mui/material/FormControlLabel';


const AddDisaster = ({addDisasterDialogOpen, onClose,uploadCommonUtilityPicture,isVerifyingPic,getDisasterList, profilePictureData,profilePictureError,createNewDisaster, disasterData, disasterError, getAllCategory,categoryList}) => {
  // const [openModal, setOpenModal] = useState(openDialog);
  const [fileName, setFileName] = useState(null);
  const [fileNameErr, setFileNameErr] = useState('');
  const [s3Key, setS3Key] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const userObject =  (window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem('userInfo')) : null) 
  const dispatch = useDispatch();
  const[image, setImage] = useState(null);
  const[previewUrl, setPreviewUrl] = useState(""); 
  const [imageErr,setImageErr] = useState('');
  const [defaultMessages, setDefaultMessages] = useState(false);
  const [listCategory, setListCategory] = useState([]);
  const [category, setCategory] = useState(null);
  const [defaultMessagesErr, setDefaultMessagesErr] = useState(false);

  const onChangeInput = (e, type) => {
    if(type === 'fileName') {
      setFileName(e.target.value)
      setFileNameErr("")
    } 
    if(type === "category"){
      setCategory(e);
      setDefaultMessagesErr("")
    }
  }
  useEffect(()=>{
    if(profilePictureData && profilePictureData.status === 200){
      setS3Key(profilePictureData?.data?.s3Key);
      submitApi(profilePictureData?.data?.s3Key)
    }
    if(disasterData && disasterData.status === 200){
      CloseDisasterModal()
    }
    if(disasterData && disasterData.status !== 200){
      setFileNameErr(disasterData?.message)
    }
  },[profilePictureData, disasterData]);

  const submitApi = (imageData) => {
    const payload = {
      "accountId": userObject?.accountId,
      "name": fileName,
      "createdBy": userObject?.id,
      "image": imageData ? imageData : ""
    }
    if (defaultMessages) {
      payload['messagesCategoryCode'] = category;
    }
    createNewDisaster(payload)
  }

  const CloseDisasterModal = () =>{
    onClose();
    setFileName(null);
    setFileNameErr('')
    setS3Key(null);
    setImage(null);
    setSelectedImage(null);
    setImageErr('');
    dispatch({
      type: "RESET_SETTING",
      payload: null
    })
    getDisasterList();
  }
  useEffect(() => {
    if (selectedImage) {
      setPreviewUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);
  const handleFile = file => {
    //you can carry out any file validations here...
    if(file){
    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    }
  }
  const handleOnDrop = event => {
    event.preventDefault(); 
    event.stopPropagation(); 
    //let's grab the image file
    let imageFile = event.dataTransfer.files[0];
    handleFile(imageFile);
  }
  const handleDragOver = event => {
    event.preventDefault();
  } 
  const onSave = () => { 
    setFileNameErr('');
    setImageErr('');

    let isValid = false;
    if (!fileName?.trim().length) {
        setFileNameErr('This is required.');
        isValid = true
    } else  if (fileName.length > 45 ) {
      setFileNameErr('Disatser Name maximum value sould be 45 character');
      isValid = true
    }
     if(!category && defaultMessages){
    setDefaultMessagesErr("This is required")
    isValid = true
    }
    // if(image === null){
    //   setImageErr('This is required.');
    //   isValid = true
    // }

    if(isValid) {
      return
    }

    if (image !== null) {
      let fd = new FormData();
      fd.set("userId", userObject.id);
      fd.set("file", image);
      fd.set("accountId", userObject?.accountId);
      fd.set('fileTag', 'DistatserImage');
      uploadCommonUtilityPicture(fd);
    } else {
      submitApi()

    }
  }
  useEffect(()=>{
    getAllCategory()
  },[])

  useEffect(() => {
    if (categoryList && categoryList.status === 200) {
      setListCategory(categoryList.data);
    }
  }, [categoryList]);

return (
        <Dialog
        open={addDisasterDialogOpen}
        onClose={CloseDisasterModal}
        className="change-pwd-dialog dialog-box"
        aria-labelledby="change-pwd-dialog-title"
        aria-describedby="change-pwd-dialog-description"
        >
            <DialogTitle id="change-pwd-dialog-title">
                <Typography className='primary-font-bold-heading2'>Disaster Type</Typography>
            {addDisasterDialogOpen ? (
                <img src={CloseIcon} alt="close-icon" style={{cursor: 'pointer',width:"21px"}} onClick={CloseDisasterModal} sx={{
                    marginRight: '-30px',
                }}/>
                ) : null}
            </DialogTitle>
            <Divider />
            <DialogContent>
              <div>
              <Typography className='font-normal-small error-message'>{fileNameErr}</Typography>
                <Typography className='font-normal-small profile-input-label' sx={{float:"left"}}>File Name </Typography>
                <span style={{ color: "red" }}>{" *"}</span>
                <input className={`profile-input ${fileNameErr !== '' ? 'err-input' : ''}`}  aria-label='first name'  onChange={(e)=> {onChangeInput(e, 'fileName')}} value={fileName}/>
              </div>
              <div>
                <Typography className='font-normal-small error-message'>{imageErr}</Typography>
                <Typography className='font-normal-small profile-input-label'>Create Document (Upload File) </Typography>
                <input
                    accept="image/*"
                    type="file"
                    id="select-image"
                    style={{ display: "none" }}
                    onChange={(e) => {setSelectedImage(e.target.files[0]); handleFile(e.target.files[0]);}}
                    />
                <label htmlFor="select-image"> 
                    <div className={`drop_zone photo-upload-container photo-upload-container-gap photo-upload-container-height ${imageErr !== '' ? 'error-border' : ''}`} style={{ marginBottom: 30}} onDragOver={handleDragOver} onDrop={handleOnDrop}>
                    <img  src={UploadImage} alt={'upload-avatar'} style={{cursor: 'pointer'}} />
                    <Typography className='primary-font-normal-3 profile-input-label' color='#ABAFB3'>Drag and drop a file here <br />or click to <span style={{color:'#4979FF'}}>upload</span></Typography>
                    { previewUrl ? <div className="image">
                        <div style={{paddingBottom:'10px'}}>{image.name}</div> 
                      <img src={previewUrl} alt='image' width={100} height={100}/> 
                  </div>:""}
                  </div>
                  </label>
              </div>
              <div>
                <FormControlLabel
                  control={<Switch color="primary"  checked={defaultMessages} onChange={() => { setDefaultMessages(!defaultMessages) }}/>}
                  inputProps={{ 'aria-label': 'controlled' }}
                  label="Do you want to associate default messages? "
                  labelPlacement="start"
                  style={{marginLeft:"0px", marginBottom:"10px"}}
                />
              </div>
            {defaultMessages ? <div>
              <Typography className='font-normal-small error-message'>{defaultMessagesErr }</Typography>
              <Typography className="font-normal-small profile-input-label">
          {"Message Category"}
          <span style={{ color: "red" }}>{" *"}</span>
        </Typography>
        <FormControl
          style={{
            marginRight: 0,
            marginLeft: 0,
            marginTop: 0,
            marginBottom: 20,
            width: "100%",
          }}
        >
          <Select
            labelId="demo-simple-select-label"
            label="Relation"
            className="relation-select message-dropdown"
            disabled={!defaultMessages}
            value={(value) => {
              return <Box sx={{ display: "flex", gap: 1 }}>{categoryList[category]}</Box>;
            }}
            renderValue={(value) => {
              return (
                <Box sx={{ display: "flex", gap: 1 }}>
                  {categoryList[category]}
                </Box>
              );
            }}
          >
            {Object.keys(categoryList).map((item) => {
              return <MenuItem value={item} onClick={(e)=>{onChangeInput(item, "category");}}>{categoryList[item]}</MenuItem>;
            })}
          </Select>
        </FormControl>
              </div>:""}
             
            </DialogContent>
            <Divider />
            <DialogActions>
            <div className="action-holder" style={{marginTop: 20, marginBottom: 0}}>
                <Button variant="outlined" className="primary-font-bold-6 btn-width profile-cancel-btn" onClick={CloseDisasterModal}>Cancel</Button>
                <LoadingButton
                  size="small"
                  onClick={onSave}
                  className="primary-font-bold-6 btn-width profile-save-btn" 
                  loading={isVerifyingPic}
                  loadingPosition="start"
                  variant="outlined"
                >
                  <span>Save</span>
                </LoadingButton>
            </div>
            </DialogActions>
        </Dialog>
    )
}
// export default AddDisaster;
function mapStateToProps(state) {
  return {
    profilePictureError: state.setting.profilePictureError,
    isVerifyingPic: state.setting.isVerifyingPic,
    profilePictureData: state.setting.profilePictureData,
    isFetching: state.setting.isFetching,
    disasterData: state.setting.disasterData,
    disasterError: state.setting.disasterError,
    categoryList: state.setting.categoryList
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      uploadCommonUtilityPicture ,
      createNewDisaster,
      getDisasterList,
      getAllCategory,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDisaster);
