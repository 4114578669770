import React, { useEffect, useState } from "react";
import "../../../../../../index.css";
import "../../../styles/myplans.css"
import { Grid, Card, CardContent, CardActions, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, Divider } from "@mui/material";
import UserAvatar from '../../../../../../assets/icons/user-avatar.svg';
import PhoneIcon from '../../../../../../assets/icons/grey-phone-icon.svg';
import AuthorisedUserFilled from '../../../../../../assets/icons/authorisedUser-icon-filled.svg';
import DeleteFilledIcon from '../../../../../../assets/images/filled-delete.svg';
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from "redux";
import { getFamilyMembers,deleteUserDataApi,markAsAdminAPI, deleteUser} from "./redux/actions";

import * as formatters from '../../../../../../utils/util';

const MyFamily = (props) => {
    const {getFamilyMembers, familyMembers, isFetching, error, deleteUser, deleteUserData, deleteUserDataApi,markAsAdminAPI,isAdminRequest, adminResult} = props;

    const navigate = useNavigate();
    const [openAuthorisedUserDialog, setOpenAuthorisedUserDialog] = useState(false);
    const [removeUserDialog, setRemoveUserDialog] = useState(false);
    const userObject = (window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem('userInfo')) : null)
    const[userid,setuserid] = useState(null);
    const [emailId, setEmailId] = useState(null);

    const[familyMembersList, setFamilyMembersList] = useState([]);
    const [markAsAdminError, setMarkAsAdminError] = useState(null);
    const dispatch = useDispatch();

    useEffect(()=>{
        getFamilyMembers()
        dispatch({
          type:"RESET_SELECTED_USER",
          payload:null
        })
    },[])

    useEffect(()=>{
      if(familyMembers && familyMembers.status === 200){
        setFamilyMembersList(familyMembers?.data)
      }
      if(familyMembers && familyMembers.status !== 200){
      }
    },[familyMembers])

    useEffect(()=>{
      if(deleteUserData && deleteUserData.status === 200){
        getFamilyMembers()
        setRemoveUserDialog(false);
        deleteUser(emailId);
        setEmailId(null);
      }
    },[deleteUserData])

    useEffect(()=>{
      if(adminResult && adminResult.status === 200){
        closeMarkAsAdminModal()
        getFamilyMembers()
      }
      if(adminResult && adminResult.status !== 200){
        setMarkAsAdminError(adminResult.message)
      }
    },[adminResult])
    const editFamily= (id) =>{
      dispatch({
        type: "SET_SELECTED_USER",
        payload: {
          userId: id
        }
      })
      navigate('/add-members-info')
    }
    const familymemberdelete = () => {
      deleteUserDataApi(userid); 
    }

    const markAsAdmin = () => {
      const userDetails = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')): null;
      const payload = {
        accountId: userDetails?.accountId ? userDetails.accountId : '',
        userId: userid
      }
       markAsAdminAPI(payload);
    }

    const closeMarkAsAdminModal = () => {
      setMarkAsAdminError(null);
      setOpenAuthorisedUserDialog(false);
    };

  return (
    <Grid container spacing={2}>
      <Grid item xs={1} md={1}></Grid>
      <Grid
        item
        xs={11}
        md={11}
        style={{ display: "flex", flexDirection:'column',padding:'40px 0px'}}
      >
      <Grid>
        <Typography className="heading-font"> MY FAMILY </Typography>
      </Grid>
      <Grid style={{ display: "flex", flexWrap: 'wrap',padding:'40px 0px',gap: 18}} >
        {familyMembersList.map((member, i)=> {
            return (
                <Card sx={{ minWidth: 246 }} className="card-box" key={'familyMemberCard_'+i}>
                <div style={{textAlign: 'center'}}>
                    <img src={member.image_PreSignedUrl ? member.image_PreSignedUrl : UserAvatar}  alt={member.imageAlt} width={87}/>
                </div>
                <CardContent className="card-content-box">
                    <Typography style={{marginTop: 23}} className="primary-font-medium name-wrapper">
                        {member?.firstName} {member?.middleName} {member?.lastName}
                    </Typography>
                    <Typography style={{marginTop: 10, display: 'flex'}} className="font-normal-small" color='#8D8D8D'>
                       <img src={PhoneIcon} alt='phone' style={{marginRight: 10}} /> {member.phoneNo || '-'}
                    </Typography>
                    {member.userRole === 'Inactive' ? (
                        <div className="admin-inactive-box" style={{marginTop: 13, textTransform: 'capitalize'}} >{member.userRoleCode}</div>
                    ): (<div className="admin-box" style={{marginTop: 13, textTransform: 'capitalize'}} >{member.userRoleCode}</div>)}
                    <Divider style={{borderColor: '#D8D8D8', width: '100%', marginTop: 10, marginBottom: 10}}/>
                    {openAuthorisedUserDialog && (
                        <Dialog
                        open={openAuthorisedUserDialog}
                        onClose={()=> closeMarkAsAdminModal()}
                        className="small-dialog dialog-box"
                        aria-labelledby="dialog-title"
                        aria-describedby="dialog-description"
                        >
                            <DialogContent className="content-dialog">
                                <img src={AuthorisedUserFilled} alt='phone' style={{marginRight: 10}} width='118px' height='118px' />
                                <Typography  className="primary-font-normal-4">Are you sure want to make <b>{member.userName}</b> as Authorised User?</Typography>
                            </DialogContent>
                            <Divider />
                            <DialogActions>
                            <div className="action-holder" style={{marginTop: 20, marginBottom: 0, gap: 40}}>
                                <Button variant="outlined" className="primary-font-bold-5 action-btn-no profile-cancel-btn" onClick={()=> closeMarkAsAdminModal()}>No</Button>
                                <Button variant="outlined" className="primary-font-bold-5 action-btn-yes profile-save-btn" disabled={isAdminRequest} onClick={()=> markAsAdmin()}>Yes</Button>
                            </div>
                            </DialogActions>
                        </Dialog>
                    )}
                    {removeUserDialog && (
                        <Dialog
                        open={removeUserDialog}
                        onClose={()=> setRemoveUserDialog(false)}
                        className="small-dialog dialog-box"
                        aria-labelledby="dialog-title"
                        aria-describedby="dialog-description"
                        >
                            <DialogContent className="content-dialog">
                                <img src={DeleteFilledIcon} alt='phone' style={{marginRight: 10}} width='118px' height='118px' />
                                <Typography  className="primary-font-normal-4">Are you sure want to delete?</Typography>
                            </DialogContent>
                            <Divider />
                            <DialogActions>
                            <div className="action-holder" style={{marginTop: 20, marginBottom: 0, gap: 40}}>
                            <Button variant="outlined" className="primary-font-bold-5 action-btn-no profile-cancel-btn" onClick={()=> setRemoveUserDialog(false)}>No</Button>
                                <Button variant="outlined" className="primary-font-bold-5 action-btn-yes profile-save-btn" onClick={ ()=>{familymemberdelete() } }>Yes</Button>
                            </div>
                            </DialogActions>
                        </Dialog>
                    )}
                    </CardContent>
                <CardActions style={{display: 'flex',justifyContent:'center',alignItems:'center', padding: 0}}>
                {member.userRoleCode === "owner" ? <div className="inactive-button-dlt"></div> : <div className="action-button-dlt" onClick={() => { setuserid(member.id); setRemoveUserDialog(true); setEmailId(member.emailId)}}></div>}
                {((member.userRoleCode !== "owner" && member.userRoleCode !== "admin") && member.dateOfBirth && (formatters.calculateAge(member.dateOfBirth) > 17)) && (
                <div className="action-button-user" onClick={() => {
                   setuserid(member.id); setOpenAuthorisedUserDialog(true); setEmailId(member.emailId)
                    }}></div>)}
                <div className="action-button-edit" onClick={() => { editFamily(member?.id) }}></div>
                </CardActions>
            </Card>
            )
        })}
        {familyMembersList.length <= 9 && (
            <Card sx={{ minWidth: 246 }} className="add-card-box" onClick={()=>{navigate('/add-members-info')}}>
                <div className="add-icon">
                </div>
                <Typography style={{textAlign: 'center', marginTop: 41}} className="primary-font-medium">
                    Add <br /> Family Member
                </Typography>
            </Card>
        ) }
        </Grid>
        </Grid>
     
    </Grid>
  );
};


MyFamily.defaultProps = {
    isFetching: null,
    error: null,
    familyMembers: [],
  };
  
  function mapStateToProps(state) {
    return {
        isFetching: state.familyInfo.isFetching,
        error: state.familyInfo.error,
        familyMembers: state.familyInfo.familyMembers,
        deleteUserData:state.familyInfo.deleteUserData,
        isAdminRequest: state.familyInfo.isAdminRequest,
        adminResult: state.familyInfo.adminResult
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        getFamilyMembers,
        deleteUserDataApi,
        markAsAdminAPI,
        deleteUser
      },
      dispatch
    );
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(MyFamily);