import React, { useState, useEffect, useContext,useMemo } from "react";
import { Grid, Typography, Dialog, DialogContent, } from "@mui/material";
import ContentWrapper from "../../../../ContentWrapper";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { checkIsTracking, getLiveLocation, stopTrackingAPI, updateLocationAPI } from "../../../redux/actions";
import MainLayout from "./MainLayout";

const TrackMeView = ({
    trackMeReadResponse,
    checkIsTracking,
    getLiveLocation,
    getLiveLocationResponse,
    stopTrackingAPI,
    trackMeStopResponse,
    updateLocationAPI,
    updateLiveLocation
}) => {
    const pathName = window.location.pathname;

    const [isLive, setIsLive] = useState(pathName !== "/live-location" ? true : false)
    const [message, setMessage] = useState("")
    const [successDialouge, setSuccessDialuge] = useState(false)

    useEffect(() => {
        const fetchData = () => {
            if (window.location.pathname === "/live-location") {
                getLiveLocation();
            } else {
                checkIsTracking();
            }
        };

        fetchData(); // Call initially

        let intervalId = null;

        if (window.location.pathname === "/live-location") {
            intervalId = setInterval(fetchData, 20000); // Call every 20 seconds
        }

        return () => {
            clearInterval(intervalId);
        };
    }, []);
 

    const liveView = useMemo(() => {
        if (getLiveLocationResponse?.status === 200 && getLiveLocationResponse?.data) {
            return getLiveLocationResponse.data;
        }
        return null; 
    }, [getLiveLocationResponse]);

    const trackView = useMemo(() => {
        if (trackMeReadResponse?.status === 200 && trackMeReadResponse?.data) {
            return trackMeReadResponse.data;
        }
        return null; 
    }, [trackMeReadResponse]);


    return (<>
        <ContentWrapper
            title={!isLive ? "LOCATION SHARED WITH ME" : "SHARE MY LOCATION"}
            gobackUrl={"/track-me"}
            goback={true}
            contenview={
                <MainLayout  
                trackView={!isLive ? liveView :trackView}
                isLive={isLive}  
                stopTrackingAPI={stopTrackingAPI}
                trackMeStopResponse = {trackMeStopResponse}
                updateLocationAPI = {updateLocationAPI}
                updateLiveLocation = {updateLiveLocation} 
                setMessage={setMessage}
                setSuccessDialuge={setSuccessDialuge}
                />
            }
        />
        {message && (
            <Dialog
                open={successDialouge}
                onClose={() => {
                    setSuccessDialuge(false);
                }}
                className="small-dialog dialog-box"
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
                <DialogContent className="content-dialog">
                    <Typography className="primary-font-normal-4">
                        {message}
                    </Typography>
                </DialogContent>
            </Dialog>
        )}
    </>
    )

};

function mapStateToProps(state) {
    return {
        trackMeReadResponse: state.trackMe.trackMeReadResponse,
        getLiveLocationResponse: state.trackMe.getLiveLocationResponse,
        trackMeStopResponse: state.trackMe.trackMeStopResponse,
        updateLiveLocation: state.trackMe.updateLiveLocation,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            checkIsTracking,
            getLiveLocation,
            stopTrackingAPI,
            updateLocationAPI
        },
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(TrackMeView);