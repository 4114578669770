import React from 'react';
import ContentWrapper from '../../../../../common/components/ContentWrapper';
import EditFamilyMembers from './EditFamilyMembers';
const EditFamilyMembersComponent = () => {
  return (
    <>
      <ContentWrapper title={"MY FAMILY MEMBERS"} gobackUrl={''} goback={true} contenview={<EditFamilyMembers />} />
    </>
  )
}

export default EditFamilyMembersComponent;
