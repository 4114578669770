import React, { useState, useEffect } from "react";
import "../../../../../../index.css";
import { Grid, TextField, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { List } from "antd";
import usePlacesAutocomplete from "use-places-autocomplete";


const BillingAddress = ({onBackOrCancelClick, handleNext}) => {
    const initialFormData = {
        address: '',
        zip: '',
        city: '',
        state: ''
    };
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState(initialFormData);

    const [city, setCity] = useState();
    const [Zip, setZip] = useState();
    const [state, setState] = useState();
    const [address, setAddress] = useState();

    const Navigate = useNavigate()

    const {
      setValue: setValuePlacesAutocomplete,
      suggestions: {status, data},
      clearSuggestions
    } = usePlacesAutocomplete();
  
    const handleSelect = async (item) => {
      setCity("");
      setState("");
      setAddress("");
      setValuePlacesAutocomplete({value: item.description});
      if (item && item.terms && item.terms.length >3) {
        const length = item.terms.length;
        setState(item.terms[length - 2]?.value);
        setCity(item.terms[length - 3]?.value)
        let street = '';
        for (let i=0; i < length - 3; i++) {
          street = street + (i !== 0 ? ',' : '') + item.terms[i]?.value; 
        }
        setAddress(street)
      }
      else if (item?.terms && item.terms.length === 3) {
        const length = item.terms.length;
        setCity(item.terms[length - 2]?.value)
        let street = '';
        for (let i=0; i < length - 2; i++) {
          street = street + (i !== 0 ? ',' : '') + item.terms[i]?.value;
        }
        setAddress(street)
      }
      else if (item?.terms && item.terms.length === 2) {
        const  length = item.terms.length;
        let street = '';
        for (let i = 0; i < length - 1; i++) {
          street = street + (i !== 0 ? ',' : '') + item.terms[i]?.value;
        }
        setAddress(street)
      }
      else if (item?.terms && item.terms.length === 1) {
        setAddress(item.terms[0]?.value)
      } else {
        setAddress(item.description)
      }
      clearSuggestions();
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const newErrors = {};

        if (address === '') {
          newErrors.address = 'This is required.';
        }
        if (Zip === '') {
          newErrors.zip = 'This is required.';
        }
        if (city === '') {
          newErrors.city = 'This is required.';
        }
        if (state === '') {
          newErrors.state = 'This is required.';
        }
       
    
        if (Object.keys(newErrors).length === 0) {
          setErrors({});
          Navigate('/my-credit-cards')
          alert("The credit card detials collected sucessfully");
         // handleNext();
        } else {
          setErrors(newErrors);
          console.log('Validation errors:', newErrors);
        }
        //console.log(formData,'formData');
        console.log(city,'city');
        console.log(Zip,'zip');
        console.log(state,'state');
        console.log(address,'address')
      };
  return (
    <Grid container spacing={2} style={{margin:0, padding: 32, paddingTop: 0, width: '100%', justifyContent: 'center'}}>
        <Grid item xs={12} md={5} sx={{padding: 0}}>
            <div style={{padding: 20}}>
                <div>
                    <Typography className='font-normal-small error-message'>{errors.address}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Address</Typography>
                    <input id="adderess" name="address" className={`profile-input ${errors.address ? 'err-input' : ''}`} aria-label='address' onChange={(e) => {setValuePlacesAutocomplete(e.target.value); setAddress(e.target.value)}}  value={address}/>
                    {status === 'OK' && (
                      <List
                        dataSource={data}
                        style={{overflow:"auto", maxHeight: "200px"}}
                        bordered={data && data.length > 0 ? true : false}
                        renderItem={(item) => (
                          <List.Item 
                            onClick={() => {handleSelect(item)}}
                          >
                            <List.Item.Meta title={item.description} />
                          </List.Item>
                        )}
                        />
                    )}
                </div>
                <div className="flex-boxes" style={{marginLeft: 0}}> 
                    <div className="full-width">
                        <Typography className='font-normal-small error-message'>{errors.zip}</Typography>
                        <Typography className='font-normal-small profile-input-label'>Zip   </Typography>
                        <input  name="zip" id="zip" className={`profile-input ${errors.zip ? 'err-input' : ''}`}  aria-label='zip'  onChange={(e) =>{setZip(e.target.value)}} value={Zip}/>
                    </div>
                    <div className="full-width">
                        <Typography className='font-normal-small error-message'>{errors.city}</Typography>
                        <Typography className='font-normal-small profile-input-label'>City</Typography>
                        <input  name="city" id="city" className={`profile-input ${errors.city ? 'err-input' : ''}`}  aria-label='city'  onChange={(e) =>{setCity(e.target.value)}} value={city}/>
                    </div>
                    <div className="full-width">
                        <Typography className='font-normal-small error-message'>{errors.state}</Typography>
                        <Typography className='font-normal-small profile-input-label'>State</Typography>
                        <input  name="state" id="state" className={`profile-input ${errors.state ? 'err-input' : ''}`}  aria-label='state'  onChange={(e) => {setState(e.target.value)}} value={state}/>
                    </div>
                </div>
            </div>
      </Grid>
      <div className="action-holder" style={{marginTop: 0, marginBottom: 50}}>
        <Button variant="outlined"  className="primary-font-bold-6 action-btn profile-cancel-btn" onClick={onBackOrCancelClick}>Back</Button>
        <Button variant="outlined" className="primary-font-bold-6 action-btn profile-save-btn"  onClick={handleSubmit}>Save</Button>
      </div>
    </Grid>
  );
};

export default BillingAddress;