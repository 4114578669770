const initialState = {
  error:null,
  verifyPinData: null,
  isVerifyingPin: false,
  verifyPinError: null,
  banksList: null,
  utilityList:null,
  bankAccountData: null,
  addBankAccount:null,
  addBankAccountError: null,
  isAPIProcessing: false,
  isFetching:false,
  bankAccounts:[],
  deleteProgess:false,
  deleteData: null

};

export const bankReducer = (state = initialState, action) => {
  const { type, payload } = action;
  let currentState = state;
  switch (type) {
    case 'SECURITY_Pin_VERIFY_REQUEST':
      currentState = {
        ...state,
        isVerifyingPin: true,
        error: payload,
      };
      break;
    case 'SECURITY_Pin_VERIFY_SUCCESS':
      currentState = {
        ...state,
        verifyPinData: payload,
        isVerifyingPin: false,
        verifyPinError: null,
      };
      break;
    case 'SECURITY_Pin_VERIFY_FAILURE':
      currentState = {
        ...state,
        verifyPinData: null,
        isVerifyingPin: false,
        verifyPinError: payload,
      };
      break;
      case 'BANKINFO_DATA_REQUEST': 
      currentState = {
        ...state,
        isFetching: true,
        error: null,
      };
      break;
      case 'BANKINFO_DATA_SUCCESS': 
      currentState = {
        ...state,
       bankAccounts: payload,
        isFetching: false,
        error: null,
        bankAccountData: null,
        addBankAccount: null,
        addBankAccountError: null
      };
      break;
      case 'BANKINFO_DATA_FAILURE': 
      currentState = {
        ...state,
        isFetching: false,
        error: payload,
      };
      break;

    case 'GET_BANK_SUCCESS':
      currentState = {
        ...state,
        banksList: payload,
        error:null,
      };
      break;
      case 'GET_BANK_FAILURE':
        currentState = {
          ...state,
          banksList: null,
          error: payload,
        };
        break;
      case 'UTILITY_DATA_SUCCESS':
        currentState = {
          ...state,
          utilityList: payload,
        };
        break;
      case 'ADD_BANK_DETAILS':
          currentState = {
            ...state,
            bankAccountData: payload,
          };
          break;
      case 'RESET_BANK_DETAILS':
        currentState = {
          ...state,
          bankAccountData: null,
          addBankAccount: null,
          addBankAccountError: null
        };
        break;
      case 'ADD_BANK_ACCOUNT_REQUEST':
        currentState = {
          ...state,
          isAPIProcessing: true,
          addBankAccountError: null,
        };
        break;
      case 'ADD_BANK_ACCOUNT_SUCCESS':
        currentState = {
          ...state,
          addBankAccount: payload,
          isAPIProcessing: false,
          addBankAccountError: null,
          
        };
        
        break;
      case 'ADD_BANK_ACCOUNT_FAILURE':
        currentState = {
          ...state,
          addBankAccount: null,
          isAPIProcessing: false,
          addBankAccountError: payload,
        };
          break; 
      case 'DELETE_BANK_ACCOUNT_REQUEST':
        currentState = {
          ...state,
          deleteProgess: true,
          deleteData: null,
        };
        break;
      case 'DELETE_BANK_ACCOUNT_SUCCESS':
        currentState = {
          ...state,
          deleteData: payload,
          deleteProgess: false,
        };
        break; 
      default:
  }
  return currentState;
}
