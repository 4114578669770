import React, { useState,useEffect } from "react";
import { Grid, Typography, Button, Select, MenuItem, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { connect , useDispatch} from "react-redux";
import {  List } from "antd";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import plusIcon from '../../../../../../assets/icons/plus-icon.svg';
import MinusIcon from '../../../../../../assets/icons/minus-icon.svg';
import constants from "../../../../../../config/constants";
import * as formatters from '../../../../../../utils/util';
import RoomIcon from '@mui/icons-material/Room';
import { getBankList, getCommonUtility } from "../../../redux/actions";
import { bindActionCreators } from "redux";
import "../styles/bankAccounts.css"
import { useLocation } from "react-router";

const AccountInfo = ({onBackOrCancelClick, handleNext, banksList, getBankList,utilityList, getCommonUtility, bankAccountData}) => {
  
  const initialFormData = {
    bankName: '',
    accountType: '',
    bankAddress: '',
    phoneNumber: '',
    accountNumber: '',
    routingNumber: '',
    bankerName: '',
    bankerPhoneNo:'',
    bankerEmailAddress:'',
    bankerWebsite:''
  };
  // const hideEdit = window.localStorage.getItem("hideEdit") ? window.localStorage.getItem("hideEdit") : false;
  const location = useLocation();
  const hideEdit = location?.state?.hideEdit;
  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: constants.GOOGLE_MAPS_API_KEY,
  });
  const [value, setValue] = useState("");
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialFormData);
  const [banks, setBanks] = useState([]);
  const [utility, setUtility] = useState([]);
  const [expand,setExpand] = useState(false);
  const [expand2,setExpand2] = useState(false);
  const dispatch = useDispatch()
  useEffect(()=>{
    getBankList();
    getCommonUtility();
  },[]);

  useEffect(()=>{
    if(bankAccountData){
      console.log(bankAccountData)
      const accountInfo = {
        "accountType": bankAccountData?.accountTypeCode,
        "bankAddress": bankAccountData?.address,
        "phoneNumber": bankAccountData?.phoneNo,
        "accountNumber":bankAccountData?.accountNo,
        "routingNumber": bankAccountData?.routingNo,
        "bankName": bankAccountData?.nameCode,
        "bankerName": bankAccountData?.bankerName,
        "bankerPhoneNo":bankAccountData?.bankerPhoneNo,
        "bankerEmailAddress": bankAccountData?.bankerEmailAddress,
        "bankerWebsite": bankAccountData?.bankerWebsite
      } 
      setFormData(accountInfo)
      setValue(bankAccountData?.address)
      
    }
  },[bankAccountData]);

  useEffect(()=>{
    if(utilityList && utilityList.status === 200){
      const data = utilityList.data;
      const accountType = data['bank-account_type'] ? data['bank-account_type'] : [];
      setUtility(accountType)
    }
  },[utilityList])

  useEffect(()=>{
    if(banksList && banksList.status ===200){
      const data = banksList.data;
        setBanks(data);
        
    }
  },[banksList])
  const handleInputChange = (e) => {
  
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
   
    
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!formData.bankName) {
      newErrors.bankName = 'This is required.';
    }
    if (!formData.accountType) {
      newErrors.accountType = 'This is required.';
    }
    if (!value || value?.trim() === '') {
      newErrors.bankAddress = 'This is required.';
    }
    if (formData.phoneNumber.trim() === '') {
      newErrors.phoneNumber = 'This is required.';
    } else if (formData.phoneNumber?.length !== 10) {
      newErrors.phoneNumber = 'Enter valid Phone number.';
    } 
    if (formData?.accountNumber.trim() === '') {
      newErrors.accountNumber = 'This is required.';
    }else if (formData?.accountNumber?.trim().length < 5 || formData?.accountNumber?.trim().length > 17) {
      newErrors.accountNumber = 'Account number should be 5-17 digits.';
    }
    if (formData.routingNumber.trim() === '') {
      newErrors.routingNumber = 'This is required.';
    }else if (formData?.routingNumber?.trim().length !== 9 ) {
      newErrors.routingNumber = 'Routing number should be 9 digits.';
    }

    if (Object.keys(newErrors).length === 0) {
      setErrors({});
      const accountInfo = {
        "nameCode": formData?.bankName,
        "accountTypeCode": formData?.accountType,
        "address": value,
        "phoneNo": formData?.phoneNumber,
        "accountNo":formData?.accountNumber,
        "routingNo": formData?.routingNumber,
        "bankName": formData.bankName,
        "bankName": bankAccountData?.nameCode,
        "bankerName": formData?.bankerName,
        "bankerPhoneNo":formData?.bankerPhoneNo,
        "bankerEmailAddress": formData?.bankerEmailAddress,
        "bankerWebsite": formData?.bankerWebsite
      }
      const accountDetails = {...bankAccountData, ...accountInfo}
      console.log(bankAccountData)
      console.log(accountDetails)
      dispatch({
        type:"ADD_BANK_DETAILS",
        payload: accountDetails
      })
      handleNext();
    } else {
      setErrors(newErrors);
    }

    console.log(formData, 'formData')

  };

return (
    <Grid container xs={12} className="account-info-container">
       <Grid item  xs={12} md={12} style={{ padding: 0}}  className="accordion-holder">
        <div className={(expand || expand2) ? 'h500' : ''}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={expand ? <img src={plusIcon} alt="Plus Icon"/> :  <img src={MinusIcon} alt="Minus Icon"/>} onClick={(e) => {e.stopPropagation(); e.preventDefault(); setExpand(!expand)}} aria-controls="panel1a-content" id="panel1a-header">
             <Typography className="primary-font-bold-6"> Account Info</Typography>
            </AccordionSummary>
            <AccordionDetails className="pl-0 account-info-align" >
            <Grid item xs={12} md={6} sx={{padding: 0}}>
            <div className="account-info-padding">
                <div>
                    <Typography className='font-normal-small error-message'>{errors.bankName}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Bank Name <span style={{color:"red"}}>{" *"}</span></Typography>
                    <Select id="bankName" name="bankName" className={`profile-input state-input font-normal-small ${errors.bankName ? 'err-input' : ''} `} value={formData.bankName}  onChange={(e)=> {handleInputChange(e,"bankName")}} disabled={hideEdit}>
                                    {banks?.map((key) => (
                                      <MenuItem key={key} value={key.bankCode}>
                                        {key.bankName}
                                      </MenuItem>
                                    ))}
                                  </Select>
                </div>
                <div>
                    <Typography className='font-normal-small error-message'>{errors.accountType}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Type of Account <span style={{color:"red"}}>{" *"}</span></Typography>
                    <Select id="accountType" name="accountType" className={`profile-input state-input font-normal-small ${errors.accountType ? 'err-input' : ''} `} value={formData.accountType}  onChange={(e)=> {handleInputChange(e)}} disabled={hideEdit}>
                                        {
                                          Object.keys(utility).map((item) => {
                                            return (<MenuItem  value={item}>{utility[item]}</MenuItem>)
                                            })
                                        }
                                  </Select>
                </div>
                <div>
                    <Typography className='font-normal-small error-message'>{errors.bankAddress}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Bank Address <span style={{color:"red"}}>{" *"}</span></Typography>
                    <input type="text" name="bankAddress" id="bankAddress" className={`profile-input ${errors.bankAddress ? 'err-input' : ''}`} aria-label='Bank Address' disabled={hideEdit} style={{marginBottom:"0px"}} value={value} 
                        onChange={(evt) => {
                          getPlacePredictions({ input: evt.target.value });
                          setValue(evt.target.value);
                        }}
                        loading={isPlacePredictionsLoading}/>
                        <div
                        style={{
                          display: "flex",
                          flex: "1",
                          flexDirection: "column",
                        }}
                      >
                        {!isPlacePredictionsLoading && (
                          <List
                            dataSource={placePredictions}
                            bordered = {placePredictions && placePredictions.length > 0 ? true : false}
                            style={{overflowY:"auto",  maxHeight: "200px",}}
                            renderItem={(item) => (
                              <List.Item style={{display:"flex"}} onClick={() => {setValue(item.description);getPlacePredictions({ input: "" })}}>
                               <RoomIcon className="icon-room"  /> <List.Item.Meta title={item.description} />
                              </List.Item>
                            )}
                          />
                        )}
                      </div>
                </div>
                <div>
                </div>
            </div>
        </Grid>
        <Grid item xs={12} md={6} sx={{padding: 0}}>
            <div className="account-info-padding" >
                <div> 
                    <Typography className='font-normal-small error-message'>{errors.phoneNumber}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Phone Number <span style={{color:"red"}}>{" *"}</span></Typography>
                    <input type="tel" name="phoneNumber" id="phoneNumber" disabled={hideEdit} className={`profile-input ${errors.phoneNumber ? 'err-input' : ''}`}  aria-label='relation'  onChange={handleInputChange}  maxLength={10} value={formatters.formatPhoneNumber(formData.phoneNumber)}/>
                </div>
                <div> 
                    <Typography className='font-normal-small error-message'>{errors.accountNumber}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Account Number <span style={{color:"red"}}>{" *"}</span></Typography>
                    <input type="tel" name="accountNumber" id="accountNumber" disabled={hideEdit} className={`profile-input ${errors.accountNumber ? 'err-input' : ''}`}  aria-label='relation'  onChange={handleInputChange} minLength={5} maxLength={17} value={formatters.formatNumber(formData.accountNumber)}/>
                </div>
                <div>
                    <Typography className='font-normal-small error-message'>{errors.routingNumber}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Routing Number <span style={{color:"red"}}>{" *"}</span></Typography>
                    <input type="tel" name="routingNumber" id="routingNumber" disabled={hideEdit} className={`profile-input ${errors.routingNumber ? 'err-input' : ''}`}  aria-label='first name'  onChange={handleInputChange}  maxLength={9} value={formatters.formatNumber(formData.routingNumber)}/>
                </div>
            </div>
        </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={expand2 ? <img src={plusIcon} alt="plus Icon"/> :<img src={MinusIcon} alt={"minus icon"}/>} onClick={(e) => {e.stopPropagation(); e.preventDefault(); setExpand2(!expand2);}} aria-controls="panel1a-content" id="panel1a-header">
              <Typography className="primary-font-bold-6">My Banker</Typography>
            </AccordionSummary>
            <AccordionDetails className="pl-0 account-info-align">
             <Grid item xs={12} md={6} sx={{padding: 0}} >
              <div className="account-info-padding" >
               <div>
               <Typography className="font-normal-small profile-input-label">Name of Banker/Agent</Typography>
               <input type="text" name="bankerName" id="bankerName" disabled={hideEdit} className="profile-input" aria-label="bnakerName" onChange={handleInputChange} 
               value={formData.bankerName} 
               />
              </div>
              <div>
                <Typography className="font-normal-small profile-input-label">Phone Number</Typography>
                <input type="tel" name="bankerPhoneNo" id="bankerPhoneNo" disabled={hideEdit} className={`profile-input`}  aria-label='relation'  onChange={handleInputChange}  maxLength={10} value={formatters.formatPhoneNumber(formData.bankerPhoneNo)}/>
              </div>
              </div>
             </Grid>
             <Grid item xs={12} md={6} sx={{padding: 0}}>
              <div className="account-info-padding">
                <div>
                  <Typography className="font-normal-small profile-input-label">Email Address</Typography>
                  <input type="text" name="bankerEmailAddress" disabled={hideEdit} className={`profile-input`} aria-label="bankerEmailAddress" onChange={handleInputChange} 
                   value={formData.bankerEmailAddress} 
                   />
                </div>
                <div>
                  <Typography className="font-normal-small profile-input-label">Bank website</Typography>
                  <input type="text" name="bankerWebsite" disabled={hideEdit} className={`profile-input`} aria-label="bankerWebsite" onChange={handleInputChange} 
                  value={formData.bankerWebsite} 
                  />
                </div>
              </div>
            </Grid> 
            </AccordionDetails>
          </Accordion>
        </div>
       </Grid>
     
        <div className="action-holder action-holder-3" style={{marginTop: 10, marginBottom: 50}}>
          <Button variant="outlined"  className="primary-font-bold-6 btn-width profile-cancel-btn" onClick={onBackOrCancelClick}>Cancel</Button>
          <Button variant="outlined" className="primary-font-bold-6 btn-width profile-save-btn"  onClick={handleSubmit}>Next</Button>
        </div>
    </Grid>
    )
}


AccountInfo.defaultProps = {
  isFetching: null,
  error: null,
  familyMembers: [],
};

function mapStateToProps(state) {
  return {
    banksList: state.bank.banksList,
    error: state.bank.error,
    utilityList: state.bank.utilityList,
    bankAccountData : state.bank.bankAccountData
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getBankList,
      getCommonUtility
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountInfo);

