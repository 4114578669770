import React from 'react';
import { Grid,  Box, Typography, Button} from "@mui/material";
import { useNavigate } from 'react-router-dom';

// import EmergencyServicesImage from '../../../../../assets/images/emergency-services/emergency-services.png';
import FireDepartment from '../../../../../assets/images/emergency-services/fire.png';
import Hospital from '../../../../../assets/images/emergency-services/hospital.png';
import Police from '../../../../../assets/images/emergency-services/police-station.png';
import Ambulace from '../../../../../assets/images/emergency-services/Ambulance.png';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import "./EmergencyServices.css"

function EmergencyServices(props) {
  const navigate = useNavigate()
    return (
        <Grid container>
            {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
            <div className='emergency-navbar-container'>
        {/* <Grid item xs={12}>
          <Box sx={{ mb: "20px" }} className="box-header-emergency">
            <Typography className="primary-font-bold-2-heading">
              Emergency Services
            </Typography>
          </Box>
        </Grid> */}
        {/* <Grid container spacing={2} > */}
        {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
        <Grid
          container
          xs={12}
          md={12}
          lg={12}
         sx={{width:"100%",m:0}}
          // grid-template-columns: repeat(3,350px);
          //     grid-auto-rows: auto;
          //     grid-gap: 1rem;
          // style={{ display: "flex", flexWrap: 'wrap',padding:'40px 0px', justifyContent:'space-between', gap: 15}}
        >
            <div style={{marginTop:"20px"}}>
              <Typography  className='primary-font-normal-1'>
                Emergency services nearest location and contact information.
                Please select tile below for information or assistance
                
              </Typography>
           </div>
           {/* <div  style={{
            display: "grid",
            gridAutoRows: "auto",
            gridGap: "1rem",
            gridTemplateColumns: "repeat(auto-fill, 200px)",
            justifyContent: "space-between",
            padding: "20px 0px 40px 0",
          }}    > */}
          <Grid container className='emg-Image-services-container' rowSpacing={2} columnSpacing={2} >
          <Grid 
          // item xs={12} sm={6} md={4} lg={3}
           >
          <img src={Hospital} className='emg-Image-services'  onClick={() => navigate("/emergency-services/hospital")} />

          </Grid>
          <Grid  
            >
          <img src={Police} className='emg-Image-services' onClick={() => navigate("/emergency-services/police")}/>

          </Grid>
          <Grid 
           >
          <img src={FireDepartment} className='emg-Image-services'  onClick={() => navigate("/emergency-services/fire_station")} />

          </Grid>
          <Grid 
           >
          <img src={Ambulace} className='emg-Image-services' onClick={() => navigate("/emergency-services/ambulance_station")}  />

          </Grid>
          </Grid>
           {/* </div> */}
           {/* <div className='action-holder'>
            <Button
          variant="outlined"
          sx={{ backgroundColor: '#FF3535 !important', alignItems:"center",display:"flex",
            color: '#fff !important', width:"250px", borderRadius:"30px"}}
          className="primary-font-bold-6 action-btn"
          onClick={()=>{}}
          
        >
          <WifiCalling3Icon sx={{marginRight: "10px"}}/><span >Call 911</span>
        </Button>
        </div> */}
          
        </Grid>
        {/* </Grid> */}
        </div>
        </Grid>
    );
}

export default EmergencyServices;