import React, { useState, useEffect } from "react";
import "../../../../../index.css";
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider,Typography, Button, Switch, FormControl, Select, Box, MenuItem  } from "@mui/material";
import CloseIcon from '../../../../../assets/icons/modal-close-icon.svg';
import UploadImage from '../../../../../assets/icons/upload-icon.svg';
import { connect} from 'react-redux';
import { bindActionCreators } from "redux";
import { updateDisasterDataAPI, uploadCommonUtilityPicture,resetSetting, getAllCategory } from "../../redux/actions";
import { LoadingButton } from "@mui/lab";
import FormControlLabel from '@mui/material/FormControlLabel';


const EditDisaster =(props)=> {
  const {editdisasterlist,isFetching,updateDisasterDataAPI,openDialog,uploadCommonUtilityPicture,resetSetting, onClose, profilePictureData,editDisasterData, profilePictureError,onSave, selectedCard, categoryList, getAllCategory} = props;
  const userObject =  (window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem('userInfo')) : null) 
  const [fileName, setFileName] = useState(null);
  const [fileNameErr, setFileNameErr] = useState('');
  const [s3Key, setS3Key] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [defaultMessages, setDefaultMessages] = useState(selectedCard?.messagesCategoryCode ?  true : false);
  const [listCategory, setListCategory] = useState([]);
  const [category, setCategory] = useState(selectedCard?.messagesCategoryCode);
  const [defaultMessagesErr, setDefaultMessagesErr] = useState(false);

  const[image, setImage] = useState(null);
  const[previewUrl, setPreviewUrl] = useState(""); 

  const onChangeInput = (e, type) => {
    if(type === 'fileName') {
      setFileName(e.target.value)
      setFileNameErr("")
    }
    if(type === "category"){
      console.log("category",e)
      setCategory(e);
      setDefaultMessagesErr("")
    } 
  }
  useEffect(()=>{
    if(profilePictureData && profilePictureData.status === 200){
      setS3Key(profilePictureData?.data?.s3Key);
      console.log(category,"s3Key")
      const payload = {
          "id": selectedCard.id,
          "accountId":userObject.accountId,
          "name":fileName,
          "updateBy": userObject.id,
          "image":profilePictureData?.data?.s3Key
        }
        if(defaultMessages){
          payload.messagesCategoryCode = category
        }else{
          payload.messagesCategoryCode = null
        }
        console.log(category,"category");
        updateDisasterDataAPI(payload)
    }
    if(editDisasterData && editDisasterData.status === 200){
      onClose()
      resetSetting()
    }
    if(editDisasterData && editDisasterData.status !== 200){
      setFileNameErr(editDisasterData?.message)
    }
  },[profilePictureData, editDisasterData]);

  useEffect(() => {
    if (selectedImage) {
      setPreviewUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);
  useEffect(() => {
    if (selectedCard && selectedCard.name)  {
      setPreviewUrl((selectedCard?.image_PreSignedUrl));
      setFileName(selectedCard?.name);
      
      // else if(selectedCard?.messagesCategoryCode){
        // setDefaultMessages(true);
        // setCategory(selectedCard?.messagesCategoryCode)
      // }
      
    }
  }, [selectedCard]);

  useEffect(()=>{
    getAllCategory()
  },[])

  const handleFile = file => {
    //you can carry out any file validations here...
    if(file){
    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    }
  }
  const handleOnDrop = event => {
    event.preventDefault(); 
    event.stopPropagation(); 
    //let's grab the image file
    let imageFile = event.dataTransfer.files[0];
    handleFile(imageFile);
  }
  const handleDragOver = event => {
    event.preventDefault();
  }

  const onAddClick = () => { 
    let isValid = false;
    if (!fileName?.trim().length) {
        setFileNameErr('This is required.');
        isValid = true
    } 
     if(!category && defaultMessages){
    setDefaultMessagesErr("This is required")
    isValid = true
    }

    if(isValid) {
      return
    }
    let dbData = {...selectedCard}
    dbData['name'] = fileName
    if(defaultMessages){
      dbData.messagesCategoryCode = category
    }else{
      dbData.messagesCategoryCode = null
    }
    if(selectedImage){
      let fd = new FormData();

      fd.set("userId", userObject.id);
      fd.set("file", image);
      fd.set("accountId", userObject?.accountId);
      fd.set('fileTag',  'DisasterImage')
      uploadCommonUtilityPicture(fd)
    }else{
      updateDisasterDataAPI(dbData);
    }
  }

  useEffect(() => {
    if (categoryList) {
      setListCategory(categoryList);
    }
  }, [categoryList]);

  const handleSwitch = () => {
    if(defaultMessages){
      setCategory(null)
    }
    setDefaultMessages(!defaultMessages); 
  }

return (
        <Dialog
        open={openDialog}
        onClose={onClose}
        className="change-pwd-dialog dialog-box"
        aria-labelledby="change-pwd-dialog-title"
        aria-describedby="change-pwd-dialog-description"
        >
            <DialogTitle id="change-pwd-dialog-title">
                <Typography className='primary-font-bold-heading2'>Disaster Type</Typography>
            {openDialog ? (
                <img src={CloseIcon} alt="close-icon" style={{cursor: 'pointer',width:"21px"}} onClick={onClose} sx={{
                    marginRight: '-30px',
                }}/>
                ) : null}
            </DialogTitle>
            <Divider />
            <DialogContent>
              <div>
                <Typography className='font-normal-small error-message'>{fileNameErr}</Typography>
                <Typography className='font-normal-small profile-input-label'>File Name </Typography>
                <input className={`profile-input ${fileNameErr !== '' ? 'err-input' : ''}`}  aria-label='first name'  onChange={(e)=> {onChangeInput(e, 'fileName')}} value={fileName}/>
              </div>
              <div>
                {/* <Typography className='font-normal-small error-message'>{fileNameErr}</Typography> */}
                <Typography className='font-normal-small profile-input-label'>Create Document (Upload File) </Typography>
                <input
                    // accept="image/*"
                    type="file"
                    id="select-image"
                    style={{ display: "none" }}
                    onChange={(e) => {setSelectedImage(e.target.files[0]); handleFile(e.target.files[0]);}}
                    />
                <label htmlFor="select-image"> 
                    <div className="photo-upload-container drop_zone  photo-upload-container-gap photo-upload-container-height" style={{ marginBottom: 30}} onDragOver={handleDragOver} onDrop={handleOnDrop}>
                    <img  src={UploadImage} alt={'upload-avatar'} style={{cursor: 'pointer'}} />
                    <Typography className='primary-font-normal-3 profile-input-label' color='#ABAFB3'>Drag and drop a file here <br />or click to <span style={{color:'#4979FF'}}>upload</span></Typography>
                    { previewUrl && <div className="image">
                      <img src={previewUrl} alt='image' width={100} height={100}/> 
                    </div> }
                    {/* <div>{selectedCard?.image}</div> */}
                  </div>
                  </label>
              </div>
              <div>
                <FormControlLabel
                  control={<Switch color="primary"  checked={defaultMessages} onChange={handleSwitch} />}
                  inputProps={{ 'aria-label': 'controlled' }}
                  label="Do you want to associate default messages? "
                  labelPlacement="start"
                  style={{marginLeft:"0px", marginBottom:"10px"}}
                />
              </div>
              {defaultMessages?
              <div>
              <Typography className='font-normal-small error-message'>{defaultMessagesErr }</Typography>
              <Typography className="font-normal-small profile-input-label">
          {"Message Category"}
          <span style={{ color: "red" }}>{" *"}</span>
        </Typography>
        <FormControl
          style={{
            marginRight: 0,
            marginLeft: 0,
            marginTop: 0,
            marginBottom: 20,
            width: "100%",
          }}
        >
          <Select
            labelId="demo-simple-select-label"
            label="Relation"
            className="relation-select message-dropdown"
            disabled={!defaultMessages}
            value={(value) => {
              return <Box sx={{ display: "flex", gap: 1 }}>{listCategory[category]}</Box>;
            }}
            renderValue={(value) => {
              return (
                <Box sx={{ display: "flex", gap: 1 }}>
                  {listCategory[category]}
                </Box>
              );
            }}
          >
            {listCategory && Object.keys(listCategory).map((item) => {
              return <MenuItem value={item} onClick={(e)=>{onChangeInput(item, "category");}}>{listCategory[item]}</MenuItem>;
            })}
          </Select>
        </FormControl>
              </div>:""}
            </DialogContent>
            <Divider />
            <DialogActions>
            <div className="action-holder" style={{marginTop: 20, marginBottom: 0}}>
                <Button variant="outlined" className="primary-font-bold-6 btn-width profile-cancel-btn" onClick={onClose}>Cancel</Button>
                <LoadingButton
                  size="small"
                  onClick={onAddClick}
                  className="primary-font-bold-6 btn-width profile-save-btn" 
                  loading={isFetching}
                  loadingPosition="start"
                  variant="outlined"
                >
                  <span>Save</span>
                </LoadingButton>
            </div>
            </DialogActions>
        </Dialog>
    )
}
EditDisaster.defaultProps = {
  isFetching: null,
 
};

function mapStateToProps(state) {
  return {
    editDisasterData:state.setting.editDisasterData,
    profilePictureData: state.setting.profilePictureData,
    isFetching: state.setting.isFetching,
    categoryList: state.setting.categoryList

  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateDisasterDataAPI,
      resetSetting,
      uploadCommonUtilityPicture,
      getAllCategory
    },
    dispatch
  );
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
) (EditDisaster);
