import React,{useState,useEffect} from 'react'
import ContentWrapper from '../../../ContentWrapper';
import ServiceTemplate from './serviceTemplate'

const FamilyMembersComponent = () => {

  const urlString = window.location.pathname;
  const parts = urlString.split("/");
  const newServiceType = parts[2];
  const [serviceType, setServiceType] = useState(null);

  useEffect(() => {
    if (newServiceType) {
        if (newServiceType === "hospital") {
            setServiceType("Hospital")
        } else if (newServiceType === "police") {
            setServiceType("Police")
        } else if (newServiceType === "fire_station") {
            setServiceType("Fire Department")
        } else if (newServiceType === "ambulance_station") {
            setServiceType("Ambulance Service")
        }
    }
}, [newServiceType]);
  return (
    <ContentWrapper title={serviceType} gobackUrl={'/emergency-services'} goback={true} contenview={<ServiceTemplate/>} />
  )
}

export default FamilyMembersComponent;