import React, { useState, useEffect } from "react";
import "../../../../../../index.css";
import { Grid, TextField, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";


const Location = ({onBackOrCancelClick, handleNext}) => {
    const initialFormData = {
        location:'',
        key:''
    };
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState(initialFormData);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const newErrors = {};

        if (formData.location.trim() === '') {
          newErrors.location = 'Location is required.';
        }
        if (formData.key.trim() === '') {
          newErrors.key = 'key  is required.';
        }
       
        if (Object.keys(newErrors).length === 0) {
          console.log(formData);
          setErrors({});
          handleNext();
        } else {
          setErrors(newErrors);
          console.log('Validation errors:', newErrors);
        }
      };
  return (
    <Grid container spacing={2} style={{margin:0, padding: 32, paddingTop: 0, width: '100%', justifyContent: 'start'}}>
        <Grid item xs={12} md={12} sx={{padding: 0}}>
            <div style={{padding: 20}}>
             <div className="flex-boxes" style={{display:'flex',justifyContent:'space-between'}}>
                 <div className="full-width">
                    <Typography className='font-normal-small error-message'>{errors.location}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Location of Firearm</Typography>
                    <input id="location" name="location" className={`profile-input ${errors.location ? 'err-input' : ''}`} aria-label='location' onChange={handleInputChange}  value={formData.location}/>
                 </div>
                 <div className="full-width">
                        <Typography className='font-normal-small error-message'>{errors.key}</Typography>
                        <Typography className='font-normal-small profile-input-label'>Safe Combination or Location of Key  </Typography>
                        <input  name="key" id="key" className={`profile-input ${errors.key ? 'err-input' : ''}`}  aria-label='key'  onChange={handleInputChange} value={formData.key}/>
                 </div>
             </div>
            </div>
      </Grid>
      <div className="action-holder" style={{marginTop: 0, marginBottom: 50}}>
        <Button variant="outlined"  className="primary-font-bold-6 action-btn profile-cancel-btn" onClick={onBackOrCancelClick}>Back</Button>
        <Button variant="outlined" className="primary-font-bold-6 action-btn profile-save-btn"  onClick={handleSubmit}>Save</Button>
      </div>
    </Grid>
  );
};

export default Location;