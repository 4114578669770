import React, { useEffect, useState, useContext } from "react";
import { Grid, Typography, Button, Dialog,DialogActions, DialogContent,DialogContentText,DialogTitle } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import TrackMeNowImage from '../../../../../../assets/images/trackme.png';
import RequestForTracking from '../../../../../../assets/images/Request_tracking.png';
import TrackMyFamily from '../../../../../../assets/images/track_my_family.png';
import { useTranslation } from 'react-i18next';
import RemoveDialog from "../../../../../../containers/Pages/common/RemoveDialog";
import { checkIsTracking, getLiveLocation, stopTrackingAPI,checkIsRequested } from "../../../redux/actions";

import shareMyLocation from '../../../../../../assets/images/track-me/Tile1.png';
import LocationShared from '../../../../../../assets/images/track-me/Tile2.png';
import RequestLocation from '../../../../../../assets/images/track-me/Tile3.png';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import { UserInfoDetails } from "../../../../../../utils/userContext";
import Slide from '@mui/material/Slide';
import "../styles/TrackMe.css";
import AlertMessage from "../../../../../../containers/Pages/UserProfile/components/AlertMessage";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#FF3535',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#FF3535',
      border: '6px solid #fff',
    },

  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TrackMe = ({
  trackMeReadResponse,
  checkIsTracking,
  getLiveLocationResponse,
  getLiveLocation,
  stopTrackingAPI,
  trackMeStopResponse,
  requestReadResponse,
  checkIsRequested
}) => {
  const [trackMeNowDialogOpen, setTrackMeDialogOpen] = useState(false);
  const [requestForTrackingOpen, setRequestForTrackingOpen] = useState(false);
  const [isFamily, setIsFamily] = useState(true);
  const [isTracking, setIsTracking] = useState(false);
  const [isRequest, setIsRequest] = useState(false);
  const [showToggle, setShowToggle] = useState(false);
  const [message, setMessage] = useState("")
  const [successDialouge, setSuccessDialuge] = useState(false)
  const [stopTracking, setStopTracking] = useState(false)
  const [isLocationDenied, setIsLocationDenied] = useState(false)
  const [trackId, setTrackId] = useState("")
  const Navigate = useNavigate()
  const { t } = useTranslation('common');
  const dispatch = useDispatch()
  const { userObject } = useContext(UserInfoDetails)
  const [showDialog,setShowDialog] = useState(false)



  useEffect(() => {
    const checkLocationPermission = async () => {
      const isLocAllowed = await findGeoLocation();
      if (!isLocAllowed) {
        setIsLocationDenied(true);
      }
    };

    checkLocationPermission();
  }, []);

const findGeoLocation = async () => {
  try {
    const result = await navigator.permissions.query({ name: 'geolocation' });
    console.log('state', result);
    if (result.state === 'granted' || result.state === 'prompt') {
      return true;
    } else if (result.state === 'denied') {
      return false;
    }
  } catch (error) {
    console.error('Error checking geolocation permission:', error);
    return false;
  }
};

  useEffect(() => {
    getLiveLocation();
    checkIsTracking();
    checkIsRequested();
    dispatch({
      type: "ADD_TRACK_ME",
      payload: {}
    })
  }, [])


  useEffect(() => {
    if (getLiveLocationResponse) {
      if (getLiveLocationResponse && getLiveLocationResponse?.data?.membersList && getLiveLocationResponse?.data?.membersList.length < 1) {
        setIsFamily(false)
      }
      else if (getLiveLocationResponse && getLiveLocationResponse?.datalength < 1) {
        setIsFamily(false)
      }
    }
  }, [getLiveLocationResponse])

  useEffect(() => {
    if (trackMeReadResponse) {
      if (trackMeReadResponse && trackMeReadResponse?.status === 404) {
        setIsTracking(false)
      } else if (trackMeReadResponse && trackMeReadResponse?.status === 200) {
        setIsTracking(true)
        setTrackId(trackMeReadResponse?.data?.id)
      }
     setTimeout(() => {
      setShowToggle(true)
     }, 500);
    }
  }, [trackMeReadResponse])


  // Request Location for others
  useEffect(() => {
    if (requestReadResponse) {
      if (requestReadResponse && requestReadResponse?.status === 404) {
        setIsRequest(false)
      } else if (requestReadResponse && requestReadResponse?.status === 200) {
        setIsRequest(true)
      }
    }
  }, [requestReadResponse])
  useEffect(() => {
    if (trackMeStopResponse) {
      if (trackMeStopResponse && trackMeStopResponse?.status === 200) {
        localStorage.setItem("isTrackingEnabled", false);
        setStopTracking(false)
        setMessage("Tracking stopped successfully");
        setSuccessDialuge(true);
        setIsTracking(false)
        dispatch({
          type: "TRACK_STOP_SUCCESS",
          payload: {}
        })
        setTimeout(() => {
          setSuccessDialuge(false);
          setMessage("");
          Navigate('/track-me')

        }, 1000);
      }
    }

  }, [trackMeStopResponse])

  const handleTrackMeNowDialog = async () => {
    const isPermissionAllowed = await findGeoLocation()
    if (isPermissionAllowed) {
      if (isTracking) {
        Navigate('/share-my-location/view');
      } else {
        setTrackMeDialogOpen(true);
      }
    } else {
      setIsLocationDenied(true)
    }
  }

  const handleRequestForTrackingDialog = async(action) => {
    if(action){
      const isPermissionAllowed = await findGeoLocation()
      if(!isPermissionAllowed){
        setIsLocationDenied(true)
      }else{
        if(isRequest){
          Navigate('/request-for-tracking/view');
        }else{
          setRequestForTrackingOpen(true);
        }
      }
    }else{
      setShowDialog(true);
      setTimeout(() => {
        setShowDialog(false);
      }, 2000);
    }
   
  }

  const handleTrackMeNow = async() => {
    const isPermissionAllowed = await findGeoLocation()
    if(!isPermissionAllowed){
      setIsLocationDenied(true)
    }else{
      Navigate('/share-my-location');
    }
  }

  const handleCloseDialog = () => {
    setTrackMeDialogOpen(false);
    setRequestForTrackingOpen(false);
  }

  const handleOpenRequest = () => {
    Navigate('/request-for-tracking');

  }

  const handleTrackMyFamily = async() => {
    const isPermissionAllowed = await findGeoLocation()
    if(!isPermissionAllowed){
      setIsLocationDenied(true)
    }else{
      if (isFamily) {
        Navigate('/live-location')
      } else {
        setMessage("Members have not activated tracking");
        setSuccessDialuge(true);
        setTimeout(() => {
          setSuccessDialuge(false);
          setMessage("");
        }, 1000);
      }
    }
   
  }

  const handleSwitchChange = async () => {
    if (isTracking) {
      setStopTracking(true)
    } else {
      const isPermissionAllowed = await findGeoLocation()
      if (isPermissionAllowed) {
        setTrackMeDialogOpen(true);
      } else {
        setIsLocationDenied(true)
      }
    }
  };
  const handleApi = () => {
    let payload = {
      "userId": userObject.id,
      "trackMeId": trackId,
    }
    stopTrackingAPI(payload);
  }

  const handleCloseTracking = () => {
    setStopTracking(false)
  }

  const handleClose = () => {
    setIsLocationDenied(false);
  };

  return (
    <Grid container style={{ margin: 0, width: '100%' }}>
      {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
      < Grid className="track-me-container">
      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        className="box-container"
        style={{
          // display: "grid",
          // gridAutoRows: "auto",
          // gridTemplateColumns: "repeat(auto-fill, 290px)",
          // gridGap: "3rem",
          justifyContent: "center",
          //  padding: "52px 20px 55px 20px"
        }}
      >
        <div style={{ position: "relative",margin:"1rem"}}>
          <img src={shareMyLocation} className='track-img me-image' onClick={() => handleTrackMeNowDialog()} />
          {showToggle && <FormGroup style={{ position: "absolute", bottom: 14, right: 16 }}>
            <FormControlLabel className="track-me-label"
              control={<IOSSwitch sx={{ m: 1 }} checked={isTracking} onChange={handleSwitchChange} />}
              label=""
            />
          </FormGroup>}
        </div>
        <div style={{ position: "relative",margin:"1rem"}}>
        <img src={LocationShared} className='track-img me-image' onClick={() => handleTrackMyFamily()} />
        </div>
        <div style={{ position: "relative",margin:"1rem"}}>
        <img src={RequestLocation} className='track-img me-image'onClick={() => handleRequestForTrackingDialog(true)} />
        </div>
        {trackMeNowDialogOpen && (
          <RemoveDialog
            openDialog={trackMeNowDialogOpen}
            onClose={handleCloseDialog}
            cardContent={"Share My Location offers your live location to the admin or any member of your plan, pending their approval. This feature enhances safety and connectivity by sharing real-time updates with your chosen contacts. Do you wish to continue?"}
            onSave={handleTrackMeNow}
            noImage={true}
          />

        )}

        {requestForTrackingOpen && (
          <RemoveDialog
            openDialog={requestForTrackingOpen}
            onClose={handleCloseDialog}
            cardContent={"Request for Location is to ask for someone’s live location. They will receive your request, and upon acceptance, you will access their real-time whereabouts, ensuring privacy and consent.Do you wish to continue?"}
            onSave={handleOpenRequest}
            noImage={true}
          />
        )}
        {stopTracking && (
          <RemoveDialog
            openDialog={stopTracking}
            onClose={handleCloseTracking}
            cardContent={"Are you sure you want to stop tracking?"}
            onSave={handleApi}
            noImage={true}
          />
        )}

      </Grid>

      {isLocationDenied && (
         <Dialog
         open={isLocationDenied}
         TransitionComponent={Transition}
         keepMounted
         onClose={handleClose}
         aria-describedby="alert-dialog-slide-description"
       >
         <DialogTitle>{"Location services"}</DialogTitle>
         <DialogContent>
           <DialogContentText id="alert-dialog-slide-description">
           Please enable location services in your browser for tracking
           </DialogContentText>
         </DialogContent>
         <DialogActions>
           <Button variant="outlined" style={{margin:"0 11px 15px 0"}} className="action-btn-no" onClick={handleClose}>Ok</Button>
           {/* <Button variant="outlined" className="primary-font-bold-5 action-btn-no profile-cancel-btn" onClick={handleClose}>Close</Button> */}
         </DialogActions>
       </Dialog>
      )}

      {message && (
        <Dialog
          open={successDialouge}
          onClose={() => {
            setSuccessDialuge(false);
          }}
          className="small-dialog dialog-box"
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
        >
          <DialogContent className="content-dialog">
            <Typography className="primary-font-normal-4">
              {message}
            </Typography>
          </DialogContent>
        </Dialog>
      )}
      {showDialog && <AlertMessage isOpen={true} onClose={false} message={"This function is under construction"} />}
    </Grid>
    </Grid>
  )
}

function mapStateToProps(state) {
  return {
    trackMeReadResponse: state.trackMe.trackMeReadResponse,
    getLiveLocationResponse: state.trackMe.getLiveLocationResponse,
    trackMeStopResponse: state.trackMe.trackMeStopResponse,
    requestReadResponse: state.trackMe.requestReadResponse,

  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { checkIsTracking, getLiveLocation, stopTrackingAPI,checkIsRequested },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrackMe);