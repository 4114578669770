import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import {  Card, CardActions, Typography, Divider, Grid } from "@mui/material";
import RemoveDialog from '../../../common/RemoveDialog';
import EditMessage from './EditMessage.js';

const StyledGrid = styled(Grid)({
  width: '100%',
  display:'flex',
  flexDirection:'row',
  justifyContent:'space-between',
  alignItems:'center',
  border: '1px solid #D8D8D8',
  borderRadius: 10,
  boxShadow: 'none',
  '&:hover': {
    border: '1px solid #FF3535',
    boxShadow: '0px 2px 8px #00000033'
}
});


const MessageList = () => {
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false)
    const [editMessageDialogOpen, setEdiMessageDialogOpen] = useState(false)
    const [selectedCard, setSelectedCard] = useState(null);
    const handleDialogOpen = (card) => {
        setSelectedCard(card);
        setRemoveDialogOpen(true);
      };
      const handleEditDialogOpen = (card) => {
        setSelectedCard(card);
        setEdiMessageDialogOpen(true);
        console.log(card,"HIII");
      };
    
      const handleDialogClose = () => {
        setSelectedCard(null);
        setRemoveDialogOpen(false);
      };
      const handleEditDialogClose = () => {
        setSelectedCard(null);
        setEdiMessageDialogOpen(false);
      };
    
    const messageList = [
    { 
        message: 'There is natural disaster emergency',
    },
    {
        message: 'There is emergency'
    },
    {
        message: 'ET phone home',
    },
    {
        message: 'Be safe and stay inside '
    },
    {
        message: 'Code Black ',
    },
    {
        message: 'Code Blue'
    },
    {
        message: 'Code Yellow ',
    },
    {
        message: 'Seek Shelter'
    }
  ]

return (
    <>
    {messageList.map((item, i)=> {
        return(  <div style={{display:'flex', flexDirection:'row', width:'100%'}} key={'disaster_'+i}>
          <StyledGrid>
       
            <div style={{padding:10}} >{item.message}</div>
            <div style={{display: 'flex', padding: 10,justifyContent:'space-between',width:'8%'}}>
                    <div className="action-button-dlt" onClick={()=>{handleDialogOpen(item)}}></div>
                    <div className="action-button-edit" onClick={()=>{handleEditDialogOpen(item)}}></div>
            </div>
        </StyledGrid>
        </div>
        )
    })}
    {editMessageDialogOpen && (
        <EditMessage openDialog={editMessageDialogOpen} selectedMessage={selectedCard} onClose={handleEditDialogClose}
        onSave={handleEditDialogClose}/>
    )}
    {removeDialogOpen && (
        <RemoveDialog 
        openDialog={removeDialogOpen}
        onClose={handleDialogClose}
        cardContent={"Are you sure want to delete?"}
        onSave={handleDialogClose}
        />
    )}
    </>

)}

export default MessageList;