import React from "react";
// import "../../../../index.css";
// import "../styles/home.css";
import "../../../Info/styles/info.css"
import { Grid } from "@mui/material";
import Property from '../../../../../assets/images/my-info/property-inventory.png';
import MainCard from "../../../common/MainCard";
import ContentWrapper from "../../../../../common/components/ContentWrapper";

const PropertyList = () => {
  const cardList =[
    
    {
      image: Property,
      redText: 'MY',
      cardText:'Property Inventory',
      redirection:'/my-property-inventory',
      toRedirect: true,
      securityPin: false,

    }
  ]
 
  return (
    <Grid container sx={{m:0}} >
      {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
      <div className="info-header-container">
      <Grid
      // container
       item
        xs={12}
        md={12}
        lg={12}
        // style={{
        //   display: "grid",
        //   gridAutoRows: "auto",
        //   gridGap: "3rem",
        //   gridTemplateColumns: "repeat(auto-fill, 330px)",
        //   justifyContent: "space-between",
        //   padding: "63px 10px 0px 20px"
        // }}
      >
        <MainCard cardList={cardList} />
      </Grid>
      {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
      </div>
    </Grid>
  );
};

const MyPropertys = () => {
    return (
      <ContentWrapper title={"My Property(s)"} goback={true} gobackUrl={'/my-assets'} img={false} contenview={<PropertyList />} />
    )
  }

export default MyPropertys;
