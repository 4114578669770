import React, { useState, useEffect } from "react";
import { Grid, Typography, Button } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import UploadImage from '../../../../../../assets/icons/upload-icon.svg';
import * as formatters from '../../../../../../utils/util'
import moment from "moment";

const CreditCardDetails = ({onBackOrCancelClick, handleNext, creditCardDetials}) => {
  const initialFormData = {
    creditCardName: '',
    creditCardNumber: '',
    expirationDate: '',
    estimatedLimit: '',
    fileDoc: '',
    code: '',
    password: ''
  };
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialFormData);
  
  const [selectedImage, setSelectedImage] = useState(null);
  const[image, setImage] = useState(null);
  const[previewUrl, setPreviewUrl] = useState("");

  const [selectedDate, setSelectedDate] = useState(new Date());

  const renderMonthContent = (month, shortMonth, longMonth, day) => {
    const fullYear = new Date(day).getFullYear();
    const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;
    return <span title={tooltipText}>{shortMonth}</span>;
  };

  const handleFile = file => {
    //you can carry out any file validations here...
    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    // setImageUrlAvatar1(URL.createObjectURL(file))
  }
  const handleOnDrop = event => {
    event.preventDefault(); 
    event.stopPropagation(); 
    //let's grab the image file
    let imageFile = event.dataTransfer.files[0];
    handleFile(imageFile);
  }

  const handleDragOver = event => {
    event.preventDefault();
  }
  useEffect(() => {
    if (selectedImage) {
    //   setImageUrlAvatar1(URL.createObjectURL(selectedImage));
      setPreviewUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const [showPassword, setShowPassword] = useState(false);
  const [showCode, setShowCode] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowCode = () => setShowCode(!showCode);
  const handleMouseDownCode = () => setShowCode(!showCode);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setFormData({
      ...formData,
      expirationDate: moment(date).format('DD/MM/YYYY')
    })
   console.log(date, 'date');
  };
 
  useEffect(() => {
    if(creditCardDetials) {
      const Data  = {
        creditCardName: creditCardDetials.creditCardName,
        creditCardNumber : creditCardDetials.creditCardNumber,
        expirationDate: creditCardDetials.expirationDate,
        estimatedLimit: creditCardDetials.estimatedLimit,
        fileDoc: creditCardDetials.fileDoc,
        code: creditCardDetials.code,
        password: creditCardDetials.password
      }
      setFormData(Data)
      console.log(formData, 'formData')
    }
  },[creditCardDetials])

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};
    if (formData.creditCardName.trim() === '') {
      newErrors.creditCardName = 'This is required.';
    }
    if (formData.creditCardNumber.trim() === '') {
        newErrors.creditCardNumber = 'This is required.';
    }
    if (formData.creditCardNumber.trim().length !== 16){
      newErrors.creditCardNumber = 'card number should be 16 digits length.';
    }
    if (formData.expirationDate === '' || !formData.expirationDate) {
        newErrors.expirationDate = 'This is required.';
    }
    if (formData.estimatedLimit.trim() === '') {
        newErrors.estimatedLimit = 'This is required.';
    }
    if (formData.password.trim() === '') {
      newErrors.password = 'This is required.';
    }else if (formData.password.trim().length !== 12) {
     // newErrors.password = 'Enter a valid Password.';
       newErrors.password = 'The password length must be 12 character';
    } 
    if (formData.code.trim() === '') {
      newErrors.code = 'This is required.';
    } else if (formData.code.trim().length !== 4) {
      newErrors.code = 'Enter a valid Code.';
    } 

    if (Object.keys(newErrors).length === 0) {
      setErrors({});
      handleNext();
    } else {
      setErrors(newErrors);
      console.log('Validation errors:', newErrors);
    }
    console.log(formData,'formData')
    console.log(selectedDate, 'selectedDate')
  };
return (
    <Grid container spacing={2} style={{margin:0, padding: 32, width: '100%'}}>
        <Grid item xs={12} md={6} sx={{padding: 0}}>
            <div style={{padding: 20}}>
                <div>
                    <Typography className='font-normal-small error-message'>{errors.creditCardName}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Credit Card Name (Required)</Typography>
                    <input type="text" name="creditCardName" id="creditCardName"  className={`profile-input ${errors.creditCardName ? 'err-input' : ''}`} aria-label='creditCardName' onChange={handleInputChange}  value={formatters.formatText(formData.creditCardName)}/>
                </div>
                <div>
                    <Typography className='font-normal-small error-message'>{errors.creditCardName}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Credit Card Number (Required)</Typography>
                    <input type="text" name="creditCardNumber" id="creditCardNumber"  className={`profile-input ${errors.creditCardName ? 'err-input' : ''}`} aria-label='creditCardNumber' onChange={handleInputChange} minLength={15} maxLength={16}  value={formatters.formatCreditCardNumber(formData.creditCardNumber)}/>
                </div>
                <div className="flex-boxes"> 
                    <div className="full-width">
                        <Typography className='font-normal-small error-message'>{errors.expirationDate}</Typography>
                        <Typography className='font-normal-small profile-input-label'>Expiration Date  </Typography>
                        <DatePicker minDate={new Date(1900, 0, 1)} showWeekNumbers name="expirationDate" id="expirationDate" className={`profile-input ${errors.expirationDate  ? 'err-input' : ''}`}  aria-label='expirationDate'  onChange={handleDateChange} value={formData.expirationDate}/>
                        {/* <DatePicker renderMonthContent={renderMonthContent} selected={selectedDate} onChange={handleDateChange} name="expirationDate" id="expirationDate" className={`profile-input ${errors.expirationDate ? 'err-input' : ''}`} aria-label='expirationDate' showMonthYearPicker dateFormat="MM/yyyy" value= {formData.expirationDate} /> */}

                    </div>
                    <div className="full-width">
                        <Typography className='font-normal-small error-message'>{errors.estimatedLimit}</Typography>
                        <Typography className='font-normal-small profile-input-label'>Estimated Limit</Typography>
                        <input  name="estimatedLimit" id="estimatedLimit" className={`profile-input ${errors.estimatedLimit ? 'err-input' : ''}`}  aria-label='estimatedLimit'  onChange={handleInputChange} value={formData.estimatedLimit}/>
                    </div>
               </div>
               <div>
                    <Typography className='font-normal-small error-message'>{errors.password}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Password (Required)</Typography>
                    <div className="input-holder"> 
                        <input name="password" id="password"  className={`profile-input ${errors.password ? 'err-input' : ''}`} aria-label='password' type={showPassword ? "text" : "password"}  onChange={handleInputChange}  maxLength={12} value={formData.password}/>
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="password" 
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        className="input-eye-icon pwd-icon1"
                        >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </div>
                </div>
            </div>
        </Grid>
        <Grid item xs={12} md={6} sx={{padding: 0}}>
            <div style={{padding: 20}}>
                <input
                    // accept="image/*"
                    type="file"
                    id="select-image"
                    style={{ display: "none" }}
                    onChange={(e) => {setSelectedImage(e.target.files[0]); handleFile(e.target.files[0]);}}
                />
                <label htmlFor="select-image"> 
                    <div className="photo-upload-container drop_zone" style={{height:272, marginBottom: 30}} onDragOver={handleDragOver} onDrop={handleOnDrop}>
                    <img  src={UploadImage} alt={'upload-avatar'} style={{cursor: 'pointer'}} />
                    <Typography className='primary-font-normal-3 profile-input-label' color='#ABAFB3'>Drag and drop a file here <br />or click to <span style={{color:'#4979FF'}}>upload</span></Typography>
                    { previewUrl && <div className="image">
                        <div>{image.name}</div>
                      {/* <img src={previewUrl} alt='image' width={100} height={100}/>  */}
                    </div> }
                  </div>
                </label>
                <div>
                    <Typography className='font-normal-small error-message'>{errors.code}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Security Code (Required)</Typography>
                    <div className="input-holder"> 
                        <input name="code" id="code"  className={`profile-input ${errors.code ? 'err-input' : ''}`}  aria-label='Code' type={showCode ? "text" : "password"} onChange={handleInputChange}   maxLength={4} value={formData.code}/>
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="code" 
                        onClick={handleClickShowCode}
                        onMouseDown={handleMouseDownCode}
                        className="input-eye-icon pwd-icon1"
                        >
                        {showCode ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </div>
                </div>
            </div>
        </Grid>
        <div className="action-holder" style={{marginTop: 0, marginBottom: 50}}>
                <Button variant="outlined"  className="primary-font-bold-6 action-btn profile-cancel-btn" onClick={onBackOrCancelClick}>Cancel</Button>
                <Button variant="outlined" className="primary-font-bold-6 action-btn profile-save-btn"  onClick={handleSubmit}>Next</Button>
              </div>
    </Grid>
    )
}
export default CreditCardDetails;
