import React, { useEffect, useState } from "react";
import "../../../../index.css";
import {
    Typography,
    Divider,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { changeUserPassword } from '../redux/actions';

import * as formatters from '../../../../utils/util';
import CloseIcon from '../../../../assets/icons/modal-close-icon.svg';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const UpdatePassword = (props) => {
    const {
        openDialog,
        closeDialog,
        isVerifyingPassword,
        passwordData,
        passwordError,
        changeUserPassword
    } = props;

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

    const handleClickShowOldPassword = () => setShowOldPassword(!showOldPassword);
    const handleMouseDownOldPassword = () => setShowOldPassword(!showOldPassword);
    const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
    const handleMouseDownNewPassword = () => setShowNewPassword(!showNewPassword);
    const handleClickConfirmShowNewPassword = () => setShowConfirmNewPassword(!showConfirmNewPassword);
    const handleMouseDownConfirmNewPassword = () => setShowConfirmNewPassword(!showConfirmNewPassword);

    const [oldPassword, setOldPassword] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [confirmNewPassword, setConfirmNewPassword] = useState(null);
    const [oldPasswordErr, setOldPasswordErr] = useState('');
    const [newPasswordErr, setNewPasswordErr] = useState('');
    const [confirmNewPasswordErr, setConfirmNewPasswordErr] = useState('');
    const user = window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem('userInfo')) : null;

    useEffect(() => {
        if (passwordData && passwordData.status === 200) {
            resetValue();
            resetPwdErrorMessage();
            closeDialog();
        } else if (passwordData && passwordData.status === 400) {
            setOldPasswordErr(passwordData.message)
            setNewPasswordErr(passwordData.message)
        }
        if (passwordError && passwordError.code ==='NotAuthorizedException') {
            setOldPasswordErr("Incorrect password")
        }else if(passwordError){
            setOldPasswordErr(passwordError.type === "String"? passwordError : JSON.stringify(passwordError))
        }
    }, [passwordData, passwordError])

    const onChangePassword = (e, type) => {
        const inputPwd = e.target.value;
        const trimmedValue = inputPwd.replace(/^\s+/, '');
        if (type === 'oldPassword') {
            setOldPassword(trimmedValue);
        } else if (type === 'newPassword') {
            setNewPassword(trimmedValue);
        } else if (type === 'confirmNewPassword') {
            setConfirmNewPassword(trimmedValue);
        }
    }

    const resetValue = (value) => {
        setOldPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
    };

    const resetPwdErrorMessage = () => {
        setOldPasswordErr('');
        setNewPasswordErr('');
        setConfirmNewPasswordErr('');
    }

    const onClosingDialog = () => {
        resetValue();
        resetPwdErrorMessage();
        closeDialog();
    }

    const onUpdatePassword = () => {
        resetPwdErrorMessage();
        if (!oldPassword?.trim().length) {
            setOldPasswordErr('This is required.');
            return
        }

        if (oldPassword?.trim() === newPassword?.trim()) {
            setOldPasswordErr('Old Password and New Password should not be the same.');
            return
        }

        if (!newPassword?.trim().length) {
            setNewPasswordErr('This is required.');
            return
        }
        if (!confirmNewPassword?.trim().length) {
            setConfirmNewPasswordErr('This is required.');
            return
        }else if (newPassword !== confirmNewPassword) {
            setConfirmNewPasswordErr('New Password and Confirm New Password mismatch.');
            return
        } else if (!formatters.isPasswordValid(newPassword)) {
            setNewPasswordErr('Minimum 8 and maximum 128 characters, at least one uppercase letter, one lowercase letter, one number and one special character');
            return
        }
       
        const payload = {
            oldPassword: oldPassword,
            password: newPassword,
            // id: user.id,
            userId: user.id,
            // updatedBy: user.id
        }
        changeUserPassword(payload)
    }

    return (

        <Dialog
            open={openDialog}
            onClose={onClosingDialog}
            className="change-pwd-dialog dialog-box"
            aria-labelledby="change-pwd-dialog-title"
            aria-describedby="change-pwd-dialog-description"
        >
            <DialogTitle id="change-pwd-dialog-title">
                <Typography className='primary-font-bold-heading2'>Change Password</Typography>
                {openDialog ? (
                    <img src={CloseIcon} alt="close-icon" style={{ cursor: 'pointer',width:"21px" }} onClick={onClosingDialog} sx={{
                        marginRight: '-30px',
                    }} />
                ) : null}
            </DialogTitle>
            <Divider />
            <DialogContent>
                <form autoComplete="off">
                    <div>
                        <Typography className='font-normal-small error-message'>{oldPasswordErr}</Typography>
                        <Typography className='font-normal-small profile-input-label'>Old Password</Typography>
                        <div className="input-holder">
                            <input autoComplete="off" className={`profile-input ${oldPasswordErr !== '' ? 'err-input' : ''}`} aria-label='old-password' type={showOldPassword ? "text" : "password"} onChange={(e) => { onChangePassword(e, 'oldPassword') }} maxLength={12} value={oldPassword} />
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="old-password"
                                onClick={handleClickShowOldPassword}
                                onMouseDown={handleMouseDownOldPassword}
                                className="input-eye-icon pwd-icon1"
                            >
                                {showOldPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </div>
                    </div>
                    <div>
                        <Typography className='font-normal-small error-message'>{newPasswordErr}</Typography>
                        <Typography className='font-normal-small profile-input-label'>New Password</Typography>
                        <div className="input-holder">
                            <input autoComplete="off" style={{padding:"10px 72px 10px 15px"}}  className={`profile-input ${newPasswordErr !== '' ? 'err-input' : ''}`} aria-label='new-password' type={showNewPassword ? "text" : "password"} onChange={(e) => { onChangePassword(e, 'newPassword') }} maxLength={128} value={newPassword} />
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="new-password"
                                onClick={handleClickShowNewPassword}
                                onMouseDown={handleMouseDownNewPassword}
                                className="input-eye-icon pwd-icon1"
                            >
                                {showNewPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </div>
                    </div>
                    <div>
                        <Typography className='font-normal-small error-message'>{confirmNewPasswordErr}</Typography>
                        <Typography className='font-normal-small profile-input-label'>Confirm New Password</Typography>
                        <div className="input-holder">
                            <input autoComplete="off"
                            style={{padding:"10px 72px 10px 15px"}} className={`profile-input ${confirmNewPasswordErr !== '' ? 'err-input' : ''}`} aria-label='confirm-new-password' type={showConfirmNewPassword ? "text" : "password"} onChange={(e) => { onChangePassword(e, 'confirmNewPassword') }} maxLength={128} value={confirmNewPassword} />
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="new-password"
                                onClick={handleClickConfirmShowNewPassword}
                                onMouseDown={handleMouseDownConfirmNewPassword}
                                className="input-eye-icon pwd-icon1"
                            >
                                {showConfirmNewPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </div>
                    </div>
               </form>
            </DialogContent>
            <Divider />
            <DialogActions>
                <div className="action-holder-1" style={{ marginTop: 20, marginBottom: 0 }}>
                    <Button variant="outlined" className="primary-font-bold-6 action-btn profile-cancel-btn" onClick={onClosingDialog}>Cancel</Button>
                    <Button variant="outlined" className="primary-font-bold-6 action-btn profile-save-btn" disabled={isVerifyingPassword} onClick={onUpdatePassword}>{isVerifyingPassword ? 'Saving...' : 'Save'}</Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};


UpdatePassword.defaultProps = {
    isFetchingLogin: null,
    isFetchingRegister: null,
    isFetchingUserInfo: null,
};

function mapStateToProps(state) {
    return {
        isVerifyingPassword: state.profile.isVerifyingPassword,
        passwordData: state.profile.passwordData,
        passwordError: state.profile.passwordError,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            changeUserPassword
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdatePassword);

