import React, { useEffect, useState,useContext } from "react";
import {
  Grid,
  Typography,
  Divider,
  CardContent,
  Card,
  CardActions,
  Button,
  Dialog,
  DialogContent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "../../../../../../assets/icons/modal-close-icon.svg";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { Navigate, useNavigate } from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";
import constants from "../../../../../../config/constants";
import Alert from "@mui/material/Alert";
import NEPTA from "../../../../../../assets/images/Nepta.png";
import LiveTrackingMeetingLocation from "../../EmergencyAlert/components/LiveTrackingMeetingLocation";
import { current } from "@reduxjs/toolkit";
import { BoltRounded } from "@mui/icons-material";
import {UserInfoDetails} from "../../../../../../utils/userContext";
import { startTrackingAPI,updateTrackingAPI } from "../../../redux/actions";

const StyledCard = styled(Card)({
  position: "relative",
  width: 500,
  height: 314,
  border: "1px solid #D8D8D8",
  borderRadius: 10,
  boxShadow: "none",
  "&:hover": {
    border: "1px solid #FF3535",
    boxShadow: "0px 2px 8px #00000033",
  },
});

const ImageOverlay = styled("div")({
  position: "relative",
  overflow: "hidden",
  border: "1px solid #fff",
});

const Image = styled("img")({
  width: "100%",
  display: "block",
  objectFit: "cover",
});

const TextOverlay = styled("div")({
  position: "absolute",
  bottom: 0,
  left: 0,
  background: "#000000BA 0% 0% no-repeat padding-box",
  opacity: 1,
  width: "100%",
  boxSizing: "border-box",
  textAlign: "center",
  padding: "8px 16px",
  color: "#fff",
  height: "70px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const statusLookup = {
  safe: "I am safe",
  risk: "At risk",
  pending: "Pending",
  critical: "Critical/Emergency",
  "I am safe": "I am safe",
};

const statusLabelLookup = {
  safe: "I am safe",
  risk: "I Need Assistance",
  pending: "Pending",
  critical: "Critical/Emergency",
  "I am safe": "I am safe",
}
const statusLookupBackground = {
  safe: "#3BC273",
  risk: "#F5B60B",
  pending: "#FFF9E5",
  critical: "#FF3535",
};

const textColor = {
  safe: "#ffffff",
  risk: "#ffffff",
  pending: "#EAA434",
  critical: "#ffffff",
};
const SummaryOfTrackMe = ({
  addEmergencyAlert,
  onBackOrCancelClick,
  onEditDisaster,
  previousData,
  activateEmergencyAlertAPI,
  isFetching,
  data,
  alertActivatedAPI = false,
  alertData = null,
  updateUserEmergencyStatus,
  startTrackingAPI,
  updateTrackingAPI,
  trackMeCreateResponse,
  trackMeUpdateResponse,
  isUpdate,
  trackData
}) => {
  const [emergencyAlert, setEmergencyAlert] = useState(null);
  const [participant, setParticipant] = useState(null);
  const [userCurrentUserIdFromAlert, setUserCurrentUserIdFromAlert] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null)
  const [activationStatus, setActivationStatus] = useState(false)
  const {userObject} = useContext(UserInfoDetails)
  const [message,setMessage] = useState("")
  const [successDialouge,setSuccessDialuge] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = localStorage.getItem("userInfo")? JSON.parse(localStorage.getItem("userInfo")): null;

  useEffect(() => {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onSuccess, onError)
  }
  }, [])


  const onSuccess = async location => {
    if(location && location.coords){
      let latitude = location?.coords?.latitude ? location?.coords?.latitude : null;
      let longitude = location?.coords?.longitude ? location?.coords?.longitude : null;
      const user = addEmergencyAlert?.triggeredBy && addEmergencyAlert?.triggeredBy.length > 0 ? addEmergencyAlert?.triggeredBy[0] : {};
      console.log(user)
      const object = {
        locationLat: latitude,
        locationLong: longitude,
        name: user?.firstName,
        image_PreSignedUrl : user?.image_PreSignedUrl,
        showCustomMarker: true
      }
      console.log("object",object)
      setCurrentLocation(object)
    }
     
  };

  const onError = error => {
      console.log("OnError")
  }

  useEffect(() => {
    console.log("addEmergencyAlert",addEmergencyAlert);
    setEmergencyAlert(addEmergencyAlert); 
  }, [addEmergencyAlert]);


  const convertObject = async (alertData) => {
    let newObject = {};
    if(alertData?.createdBy){
      newObject.createdBy = alertData?.createdBy
    }
    if(alertData?.id){
      newObject.id = alertData.id
    }
    if (alertData?.messagesList) {
      let message = [];
      let finalMesages = [];
      const messageKeys = Object.keys(alertData?.messagesList);
      messageKeys.map((singleKey) => {
        const data = alertData?.messagesList && alertData?.messagesList[singleKey];
        message = message.concat(data);
      });
      newObject.message = message;
    }
    if (alertData?.membersList) {
      newObject.familyMembers = alertData?.membersList;
    }
    
    let location = {};
    location.isCurrentLocation = alertData.isCurrentLocation;
    location.locationId = alertData.locationId;
    location.locationLat = alertData?.locationLat;
    location.locationLong = alertData?.locationLong;
    if (location.isCurrentLocation) {
      location.name = "Current Location";
      const address = await getLocaionDetails(
        location.locationLat,
        location.locationLong
      );
      location.address = address;
    }
    if (
      alertData?.meetingLocationsList &&
      alertData?.meetingLocationsList.length > 0
    ) {
      location = alertData?.meetingLocationsList[0];
      location.isCurrentLocation = alertData.isCurrentLocation;
    }
    if(location?.locationLat || location.locationLong){
      newObject.location = location;
    }
    if (alertData?.isEditPage) {
      newObject.isEditPage = true;
    }

    const userDetails = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null;
    const selectedUser =
      newObject &&
      newObject.familyMembers &&
      newObject.familyMembers.filter((row) => {
        return (
          row.userId === userDetails?.id ||
          (!row.userId && row.id === userDetails?.id)
        );
      });

    if (selectedUser && selectedUser.length > 0) {
      setParticipant(true)
      if(alertData?.id){
        if (navigator && navigator.geolocation) {
              setUserCurrentUserIdFromAlert(selectedUser[0].id)
              navigator.geolocation.getCurrentPosition(onSuccess, onError)
          }
      }
    } else {
      setParticipant(false)
    }
   
    setEmergencyAlert(newObject);
  };

  const convertNewObjectFormat = async (alertData) => {
    let newObject = alertData;
    if(alertData?.createdBy){
      newObject.createdBy = alertData?.createdBy
    }
    if (alertData?.isEditPage) {
      newObject.isEditPage = true;
    }
    if (newObject.location && newObject.location.isCurrentLocation) {
      newObject.location.name = "Current Location";
      const address = await getLocaionDetails(
        newObject.location?.locationLat,
        newObject.location?.locationLong
      );
      newObject.location.address = address;
    }
    const userDetails = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null;
    const selectedUser =
      newObject &&
      newObject.familyMembers &&
      newObject.familyMembers.filter((row) => {
        return (
          row.userId === userDetails?.id ||
          (!row.userId && row.id === userDetails?.id)
        );
      });
    if (selectedUser && selectedUser.length > 0) {
      setParticipant(true);
      if(newObject?.id){
        if (navigator && navigator.geolocation) {
              setUserCurrentUserIdFromAlert(selectedUser[0].id)
              navigator.geolocation.getCurrentPosition(onSuccess, onError)
          }
      }
    } else {
      setParticipant(false)
    }

    if(previousData === null && newObject.triggeredBy && newObject.triggeredBy.length > 0){
      const createdUser  = newObject.triggeredBy[0];
      if(createdUser?.id === userDetails?.id){
        setParticipant(true)
        if(newObject?.id){
          if (navigator && navigator.geolocation) {
                setUserCurrentUserIdFromAlert(selectedUser[0].id)
                navigator.geolocation.getCurrentPosition(onSuccess, onError)
            }
        }
      }

    }
    setEmergencyAlert(newObject);
  };
  useEffect(() => {
    if (alertData && alertData.triggeredBy) {
      convertNewObjectFormat(alertData);
    } else if (alertData) {
      convertObject(alertData);
    }
  }, [alertData]);

  const getLocaionDetails = async (latitude, longitude) => {
    if(latitude && longitude){
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${constants.GOOGLE_MAPS_API_KEY}`
      );
      const json = await response.json();
      console.log("response", json);
      return json.results[0]?.formatted_address;
    }
   
  };



 
  const startTrackingMe = () => {
    let payload = {
      "accountId":  userObject.accountId,
      "userId":  userObject.id,
      "members": emergencyAlert.familyMembers.map(obj => obj.id).join(','),
      "messages": emergencyAlert?.message ? emergencyAlert?.message.map(obj => obj.code).join(",") : "",
      "messageCustom": "",
      "locationLat": currentLocation?.locationLat,
      "locationLong": currentLocation?.locationLong,
      // "locationLat": 10.968350914765573,
      // "locationLong": 78.67397996373062,
      "deviceType": "system",
      "createdBy": userObject.id
    }
  startTrackingAPI(payload)

  }

  const shareLocationUpdate = () => {
    let payload = {
      "accountId": userObject.accountId,
      "userId": userObject.id,
      "members": emergencyAlert.familyMembers.map(obj => obj.id).join(','),
      "messages": emergencyAlert?.message ? emergencyAlert?.message.map(obj => obj.code).join(",") : "",
      "messageCustom": "",
      "locationLat": currentLocation?.locationLat,
      "locationLong": currentLocation?.locationLong,
      "deviceType": "system",
      "createdBy": userObject.id,
      "id": trackData?.id,
      "updatedBy": userObject.id
    }

    updateTrackingAPI(payload)

  }

  

  useEffect(() => {
    if (trackMeCreateResponse) {
      if (trackMeCreateResponse && trackMeCreateResponse.status === 200) {
        setMessage("Tracking started successfully");
        dispatch({
          type: "TRACK_ME_SUCCESS",
          payload: {}
        })
        setSuccessDialuge(true);
        setTimeout(() => {
          setSuccessDialuge(false);
          setMessage("");
          navigateToView()
          
        }, 2000);
      }
    }
  }, [trackMeCreateResponse])

  useEffect(() =>{
    if (trackMeUpdateResponse) {
      if (trackMeUpdateResponse && trackMeUpdateResponse.status === 200) {
        setMessage("Tracking updated successfully");
        dispatch({
          type: "TRACK_ME_UPDATE_SUCCESS",
          payload: {}
        })
        setSuccessDialuge(true);
        setTimeout(() => {
          setSuccessDialuge(false);
          setMessage("");
          navigateToView()
          
        }, 2000);
      }
    }
  },[trackMeUpdateResponse])


  const navigateToView = () => {
    // navigate('/share-my-location/view')
    navigate('/Track-me')
  }

  return (
    <Grid container style={{ width: "100%", margin: 0 }}>
        <Grid className="track-me-container" sx={{marginBottom:"10px",marginTop:"10px"}}>
      <Grid   xs={12} md={12} lg={12}>
        {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
          {/* <Typography
            className="primary-font-bold-heading1"
            style={{ paddingLeft: 30 }}
          >
            Track Me
          </Typography> 
          <div
            style={{
              display: "flex",
              alignitems: "flex-end",
              justifycontent: "end",
              paddingRight: 30,
            }}
          >
            <img
              src={CloseIcon}
              alt="close-icon"
              style={{ cursor: "pointer" }}
              onClick={() => {
                cancelActivateAlert();
              }}
              sx={{
                marginRight: "30px",
              }}
            />
          </div>
        </div>
            <Divider /> */}
        <br /> 
        <Grid container spacing={2} style={{ width: "100%", marginBottom: 30, display:"flex",justifyContent:"center" }}>
          <Grid item xs={12} md={6} lg={6} className="track-me-now-map" sx={{pt:"0px !important", }}>
          <div id="meeting-lcoation" style={{marginTop:"20px",height:"500px"}}>
              <LiveTrackingMeetingLocation membersList={emergencyAlert?.familyMembers} meetingLocation={currentLocation} />
              </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} id="second-grid-item" className="track-me-now-map">
            {emergencyAlert && emergencyAlert.location && (
              <div>
                <Typography
                  className="primary-font-bold-5"
                  style={{ paddingTop: "20px", marginRight: "5px" }}
                >
                  Meeting Location
                </Typography>
                <Grid
                  style={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    width: "80%",
                  }}
                >
                  <Grid
                    container
                    sx={{
                      margin: "0px",
                      padding: "12px",
                      mr: "10px",

                      border: "1px solid #D8D8D8",
                      borderRadius: "10px",
                      flexDirection: "column",
                    }}
                  >
                    <Grid>
                      <Typography
                        className="primary-font-bold-5"
                        style={{ textTransform: "capitalize" }}
                      >
                        {emergencyAlert?.location?.name}
                      </Typography>
                    </Grid>
                    <Grid>
                      {" "}
                      <Typography className="primary-font-normal-2">
                        {emergencyAlert?.location?.address}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            )}
            <div>
              {/* {emergencyAlert && emergencyAlert.familyMembers && (
                <Typography className="primary-font-bold-5" sx={{my:2}}>
                  Family Members
                </Typography>
              )} */}
              <Grid
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  // width: "95%",
                }}
              >
                {emergencyAlert &&
                  emergencyAlert.familyMembers &&
                  emergencyAlert.familyMembers.map((row) => {
                    return (
                      <>
                        {((row.userId && row.userId !== userDetails?.id) ||
                          (!row.userId && row.id !== userDetails?.id)) && (
                            <>
                              <Grid
                                container
                                key={row.id}
                                sx={{
                                  mr: "12px",
                                  padding: "12px",
                                  // mx: "10px",
                                  // my: "5px",
                                  border: "1px solid #D8D8D8",
                                  borderRadius: "10px",
                                  marginBottom: "10px"
                                }}
                              >
                                <Grid
                                  xs={12}
                                  md={8.5}
                                  lg={8.5}
                                  sx={{
                                    justifyContent: "start",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  <Typography className="primary-font-bold-5">
                                    {row.name
                                      ? row.name
                                      : row.firstName +
                                      " " +
                                      (row?.middleName && row?.middleName !== null ? row.middleName : "") +
                                      " " +
                                      (row?.lastName && row?.lastName !== null ? row?.lastName : "")}
                                  </Typography>
                                </Grid>
                                {/* <Grid
                           xs={4}
                           md={4}
                           lg={4}
                           sx={{ justifyContent: "start" }}
                         >
                           <Typography className="primary-font-normal-2">
                             {row.relationshipCode}
                           </Typography>
                         </Grid> */}
                                {/* <Grid
                                  xs={12}
                                  md={3.5}
                                  lg={3.5}
                                  sx={{
                                    justifyContent: "start",
                                    textAlign: "center",
                                    borderTopRightRadius: "9px",
                                    borderBottomRightRadius: "9px",
                                    backgroundColor:
                                      row.status
                                          ? statusLookupBackground[row.status]
                                          : statusLookupBackground["pending"],
                                  }}
                                >
                                  <Typography
                                    className="primary-font-normal-2"
                                    style={{
                                      textTransform: "capitalize",
                                      paddingTop:9,
                                      fontWeight:"bold",
                                      color:
                                        row.status
                                            ? textColor[row.status]
                                            : textColor["pending"],
                                    }}
                                  >
                                    { row.status
                                        ? statusLabelLookup[row.status]
                                        : statusLabelLookup["pending"]}
                                  </Typography>
                                </Grid> */}
                              </Grid>
                            </>
                          )}
                      </>
                    );
                  })}
              </Grid>
            </div>
            <div style={{ marginBottom: "20px", }}>
              {emergencyAlert && emergencyAlert.message && (
                <Typography className="primary-font-bold-5" sx={{my:2}}>Message</Typography>
              )}
              {/* <ul> */}
              {emergencyAlert &&
                emergencyAlert.message &&
                emergencyAlert.message.map((item,index) => {
                  return(
                  //   <Typography className="primary-font-normal-2">
                  //   {item}
                  // </Typography>
                  <>
                  <Grid
                    container
                    key={index}
                    sx={{
                      mr: "12px",
                      padding: "12px",
                      border: "1px solid #D8D8D8",
                      borderRadius: "10px",
                      marginBottom: "10px"
                    }}
                  >
                    <Grid
                      xs={12}
                      md={8.5}
                      lg={8.5}
                      sx={{
                        justifyContent: "start",
                        textTransform: "capitalize",
                      }}
                    >
                      <Typography className="">
                       {item.message}
                      </Typography>
                    </Grid>
                   
                   
                  </Grid>
                </>
                  )
                 
                  })}

             
              {/* </ul> */}
              {/* <textarea 
              className="font-family-default"
                style={{
                  width: "475px",
                  height: "60px",
                  background: " #FFFFFF 0% 0 % no - repeat padding- box",
                  boxShadow: "0px 3px 6px #00000029",
                  borderRadius: "10px",
                  opacity: 1,
                  outline: "none",
                  border: "none",
                  padding: "18px 16px",
                  margin:"10px 0",
                  resize: "none"
                }} 
                maxLength={500}
                placeholder="Type here..."></textarea> */}
            </div>

            {!isUpdate ?  <div className="stop-tracking-btn-box">
              <Button
                style={{ width: '100%', marginTop: "20px" }}
                variant="outlined"
                className="primary-font-bold-6 action-btn profile-save-btn"
                onClick={() => { startTrackingMe() }}
              >
                {"Start Tracking"}

              </Button>
            </div> : <div className="action-holder" style={{ marginTop: 40, marginBottom: 50,gap:"20px" }}>
              <Button variant="outlined" className="primary-font-bold-6 action-btn profile-cancel-btn" onClick={() => navigateToView()} >Cancel</Button>
              <Button variant="outlined" className="primary-font-bold-6 action-btn profile-save-btn" onClick={() => { shareLocationUpdate(); }}>Update</Button>
            </div>}
            </Grid>
               
            </Grid>
          </Grid>

      {message && (
        <Dialog
          open={successDialouge}
          onClose={() => {
            setSuccessDialuge(false);
          }}
          className="small-dialog dialog-box"
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
        >
          <DialogContent className="content-dialog">
            <Typography className="primary-font-normal-4">
              {message}
            </Typography>
          </DialogContent>
        </Dialog>
      )}
        </Grid>
    
    </Grid>
  );
};

function mapStateToProps(state) {
  return {
    familyMembers: state.setting.familyMembers,
    addEmergencyAlert: state.trackMe.addTrackMe,
    data: state.trackMe.data,
    isFetching: state.trackMe.isFetching,
    trackMeCreateResponse: state.trackMe.trackMeCreateResponse,
    trackMeUpdateResponse: state.trackMe.trackMeUpdateResponse,
   };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      startTrackingAPI,
      updateTrackingAPI
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryOfTrackMe);
