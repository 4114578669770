import React, { useState, useEffect } from "react";
import "../../../../../../index.css";
import { Grid, Typography, Button} from "@mui/material";
import { useNavigate } from "react-router-dom";
import UploadImage from '../../../../../../assets/icons/upload-icon.svg';
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { getMilitaryServices,updateMilitaryServices,uploadCommonUtilityPicture } from "../../../redux/actions";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import moment from "moment/moment";

const MilitaryService = ({ onContinue, onCancel,onView, selectedUser, getMilitaryServices, militaryInfo, updateMilitaryServices, updateMilitaryData, uploadCommonUtilityPicture, profilePictureData, profilePictureError }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const userDetails = window.localStorage.getItem("userInfo");
    const userDetailsObject = JSON.parse(userDetails);

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrlAvatar1, setImageUrlAvatar1] = useState(null);
  const [imageUrlAvatar, setImageUrlAvatar] = useState(null);
  const [militeryServices, setMiliteryServices] = useState(null)
  const [branch, setBranch] = useState(null);
  const [dateOfEnlistment, setDateOfEnlistment] = useState(null);
  const [dateOfDischarge, setDateOfDischarge] = useState('');
  const [typeOfDischarge, setTypeOfDischarge] = useState(null);
  const [locationOfDischarge, setLocationOfDischarge] = useState(null);
  const [serialNumber, setSerialNumber] = useState(null);
  const [memberships, setMemberships] = useState(null);
  const [specialRecognitions, setSpecialRecognitions] = useState(null);
  const [s3Key, setS3Key] = useState(null);

  const [branchErr, setBranchErr] = useState('');
  const [dateOfEnlistmentErr, setDateOfEnlistmentErr] = useState('');
  const [dateOfDischargeErr, setDateOfDischargeErr] = useState('');
  const [typeOfDischargeErr, setTypefDischargeErr] = useState('');
  const [locationOfDischargeErr, setLocationOfDischargeErr] = useState('');
  const [serialNumberErr, setSerialNumberErr] = useState('');
  const [membershipsErr, setMembershipsErr] = useState('');
  const [specialRecognitionsErr, setSpecialRecognitionsErr] = useState('');

  const [openPhotoUploadModal, setOpenPhotoUploadModal] = useState(false);
  const[image, setImage] = useState(null);
  const[previewUrl, setPreviewUrl] = useState(""); 


  useEffect(()=>{
    if(selectedUser?.userId){
      getMilitaryServices(selectedUser.userId)
    }
  },[])

  useEffect(()=>{
    if(militaryInfo && militaryInfo.status === 200){
        const data = militaryInfo.data;
        setMiliteryServices(data)
        setBranch(data.branchRank);
        setDateOfEnlistment(data.dateOfEnlistment);
        setDateOfDischarge(data.dateOfDischarge);
        setTypeOfDischarge(data.typeOfDischarge);
        setLocationOfDischarge(data.locationOfDischarge);
        setSerialNumber(data.serialNo);
        setMemberships(data.memberships)
        setSpecialRecognitions(data.specialRecognitions);
        setPreviewUrl(data.image_PreSignedUrl);
        setS3Key(data?.image)
    }else{
      setMiliteryServices(null)
    }
  },[militaryInfo])

  useEffect(()=>{
    if(profilePictureData && profilePictureData.status === 200){
      setS3Key(profilePictureData?.data?.s3Key);
      setImageUrlAvatar(imageUrlAvatar1);
      setOpenPhotoUploadModal(false);
      setPreviewUrl(imageUrlAvatar1);
    }
    },[profilePictureData, profilePictureError])

  useEffect(()=>{
    if(updateMilitaryData && updateMilitaryData.status === 200){
      dispatch({
        type: "RESET_MILITARY_DATA",
        payload:null
      })
      onContinue()
    }
  },[updateMilitaryData])

  const handleFile = file => {
    //you can carry out any file validations here...
    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    setImageUrlAvatar1(URL.createObjectURL(file))
    let fd = new FormData();
    fd.set("userId", selectedUser?.userId);
    fd.set("file", file);
    fd.set("accountId", userDetailsObject?.accountId);
    fd.set('fileTag',  'MilitaryService')
    uploadCommonUtilityPicture(fd);
  }
  const handleOnDrop = event => {
    event.preventDefault(); 
    event.stopPropagation(); 
    //let's grab the image file
    let imageFile = event.dataTransfer.files[0];
    handleFile(imageFile);
  }

  const handleDragOver = event => {
    event.preventDefault();
  }
  const saveAvatarIcon = () => {
    setImageUrlAvatar(imageUrlAvatar1);
    handleClose();
  };

  const handleClose= () => {
    setOpenPhotoUploadModal(false);
    setPreviewUrl('')
  };

  useEffect(() => {
      if (selectedImage) {
        setImageUrlAvatar1(URL.createObjectURL(selectedImage));
        setPreviewUrl(URL.createObjectURL(selectedImage));
      }
    }, [selectedImage]);

    const onChangeInput = (e, type) => {
      if(type === 'branch') {
        setBranch(e.target.value)
      } else if(type === 'dateOfEnlistment') {
        let date =  e
        if(e){
          date = moment(e).format('MM-DD-YYYY')
        }
        setDateOfEnlistment(date)
      } else if(type === 'dateOfDischarge') {
        let date =  e
        if(e){
          date = moment(e).format('MM-DD-YYYY')
        }
        setDateOfDischarge(date)
      }else if(type === 'typeOfDischarge') {
        setTypeOfDischarge(e.target.value)
      } else if(type === 'locationOfDischarge') {
        setLocationOfDischarge(e.target.value)
      } else if(type === 'serialNumber') {
        setSerialNumber(e.target.value)
      } else if(type === 'memberships') {
        setMemberships(e.target.value);
      } else if(type === 'specialRecognitions') {
        setSpecialRecognitions(e.target.value)
      }
    }
   
    const onSave = () => {
      // // if (!branch?.trim().length) {
      // //   setBranchErr('This is required.');
      // //     return
      // // } else {
      // //   setBranchErr('');
      // // }
      // if (!dateOfEnlistment) {
      //   setDateOfEnlistmentErr('This is required.');
      //   return
      // } else {
      //   setDateOfEnlistmentErr('');
      // }
      // if (!dateOfDischarge) {
      //   setDateOfDischargeErr('This is required.');
      //   return
      // } else {
      //   setDateOfDischargeErr('');
      // }
      // if (!locationOfDischarge?.trim().length) {
      //   setLocationOfDischargeErr('This is required.');
      //   return
      // } else {
      //   setLocationOfDischargeErr('');
      // }
      // if (!serialNumber?.trim().length) {
      //   setSerialNumberErr('This is required.');
      //   return
      // } else if (serialNumber.length !== 10) {
      //   setSerialNumberErr('Enter valid Serial Number.');
      //   return
      // } else {
      //   setSerialNumberErr('');
      // }
      // if (!memberships?.trim().length) {
      //   setMembershipsErr('This is required.');
      //   return
      // } else {
      //   setMembershipsErr('');
      // }
      // if (!specialRecognitions?.trim().length) {
      //   setSpecialRecognitionsErr('This is required.');
      //   return
      // } else {
      //   setSpecialRecognitionsErr('');
      // }
      console.log(typeOfDischarge, 'typeOfDischarge')
    
      let payload = {
        id: null,
        branchRank: branch,
        dateOfEnlistment: dateOfEnlistment,
        dateOfDischarge: dateOfDischarge,
        typeOfDischarge: typeOfDischarge,
        locationOfDischarge: locationOfDischarge,
        serialNo: serialNumber,
        memberships: memberships,
        specialRecognitions: specialRecognitions,
        userId: selectedUser?.userId,
        image: s3Key,
        createdBy:userDetailsObject?.id,
        updatedBy: userDetailsObject?.id
      }
      if(militeryServices){
        payload.id = militeryServices.id
      }
      updateMilitaryServices(payload)
    };
  
  return (
    <Grid container  style={{margin:0}}>
      {/* <Grid item xs={2} md={2}></Grid> */}
    
        <Grid container spacing={2} style={{margin:0, height: 'auto'}}>
            <Grid item xs={12} md={6}  sx={{pl:onView?"0px !important":"16px",pt:onView?"0px !important":"16px"}} className="pl-0">
                <div>
                <Typography className='font-normal-small error-message'>{branchErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Branch / Rank</Typography>
                    <input className={`profile-input ${branchErr !== '' ? 'err-input' : ''}`} aria-label='branch / rank' onChange={(e)=> {onChangeInput(e, 'branch')}}  maxLength={10} value={branch}/>
                </div>
                <div> 
                    <Typography className='font-normal-small error-message'>{dateOfEnlistmentErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Date of Enlistment</Typography>
                    <DatePicker 
                        minDate={new Date(1900, 0, 1)} 
                        className={`profile-input ${dateOfEnlistmentErr !== '' ? 'err-input' : ''}`} 
                        onChange={(e)=> {onChangeInput(e, 'dateOfEnlistment')}} 
                        value={dateOfEnlistment}
                        maxDate={new Date()}
                        />
                </div>
                <div className="flex-boxes" style={{marginLeft: 0}}> 
                    <div className="full-width">
                      <div>
                        <Typography className='font-normal-small error-message'>{dateOfDischargeErr}</Typography>
                        <Typography className='font-normal-small profile-input-label'>Date of Discharge<span style={{color:"red"}}>{" *"}</span></Typography>
                        <DatePicker 
                        minDate={new Date(1900, 0, 1)} 
                        className={`profile-input ${dateOfDischargeErr !== '' ? 'err-input' : ''}`} 
                        onChange={(e)=> {onChangeInput(e, 'dateOfDischarge')}} 
                        value={dateOfDischarge}
                        maxDate={new Date()}
                        />
                      </div>
                    </div>
                    <div className="full-width">
                      <Typography className='font-normal-small error-message'>{typeOfDischargeErr}</Typography>
                      <Typography className='font-normal-small profile-input-label'>Type of Discharge</Typography>
                      <input className={`profile-input ${typeOfDischargeErr !== '' ? 'err-input' : ''}`}  aria-label='Type Of Discharge'  onChange={(e)=> {onChangeInput(e, 'typeOfDischarge')}} value={typeOfDischarge}/>
                    </div>
                </div>
                {/* <div> 
                    <Typography className='font-normal-small error-message'>{dateOfDischargeErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Date of Discharge / Type of Discharge</Typography>
                    <input className={`profile-input ${dateOfDischargeErr !== '' ? 'err-input' : ''}`}  aria-label='Date Of Discharge'  onChange={(e)=> {onChangeInput(e, 'dateOfDischarge')}} value={dateOfDischarge}/>
                </div> */}
                <div> 
                    <Typography className='font-normal-small error-message'>{locationOfDischargeErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Location of Discharge</Typography>
                    <input className={`profile-input ${locationOfDischargeErr !== '' ? 'err-input' : ''}`}  aria-label='Location Of Discharge'  onChange={(e)=> {onChangeInput(e, 'locationOfDischarge')}} value={locationOfDischarge}/>
                </div>
                <div> 
                    <Typography className='font-normal-small error-message'>{serialNumberErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Serial Number</Typography>
                    <input className={`profile-input ${serialNumberErr !== '' ? 'err-input' : ''}`}  aria-label='Serial Number' maxLength={10}  onChange={(e)=> {onChangeInput(e, 'serialNumber')}} value={serialNumber}/>
                </div>
            </Grid>
            <Grid item xs={12} md={6}  sx={{pl:onView?"0px !important":"30px",pt:onView?"0px !important":"16px"}} className="flex-btm">
                <input
                    // accept="image/*"
                    type="file"
                    id="select-image"
                    style={{ display: "none" }}
                    onChange={(e) => {setSelectedImage(e.target.files[0]); handleFile(e.target.files[0]);}}
                    />
                <label htmlFor="select-image"> 
                    <div className="photo-upload-container drop_zone" style={{height:272, marginBottom: 30}} onDragOver={handleDragOver} onDrop={handleOnDrop}>
                    <img  src={UploadImage} alt={'upload-avatar'} style={{cursor: 'pointer'}} />
                    <Typography className='primary-font-normal-3 profile-input-label' color='#ABAFB3'>Drag and drop a file here <br />or click to <span style={{color:'#4979FF'}}>upload</span></Typography>
                    { previewUrl && <div className="image">
                      <img src={previewUrl} alt='image' width={100} height={100}/> 
                    </div> }
                  </div>
                  </label>
                <div>
                    <Typography className='font-normal-small error-message'>{membershipsErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Fraternal, Service, and Union Memberships </Typography>
                    <input className={`profile-input ${membershipsErr !== '' ? 'err-input' : ''}`}  aria-label='memberships'  onChange={(e)=> {onChangeInput(e, 'memberships')}} value={memberships}/>
                </div>
                <div>
                    <Typography className='font-normal-small error-message'>{specialRecognitionsErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Special Recognitions </Typography>
                    <input className={`profile-input ${specialRecognitionsErr !== '' ? 'err-input' : ''}`}  aria-label='Special Recognitions'  onChange={(e)=> {onChangeInput(e, 'specialRecognitions')}} value={specialRecognitions}/>
                </div>
            </Grid>
            <div className="action-holder action-bg">
                <Button variant="outlined" className="primary-font-actionbtn action-btn profile-cancel-btn" onClick={onCancel}>Back</Button>
                <Button variant="outlined" className="primary-font-actionbtn action-btn profile-save-btn" onClick={onSave}>Save & Continue</Button>
            </div>
        </Grid>
     
    </Grid>
  );
};



MilitaryService.defaultProps = {
};

function mapStateToProps(state) {
  return {
    stateList: state.familyInfo.stateList,
    militaryInfo: state.familyInfo.militaryInfo,
    updateMilitaryData: state.familyInfo.updateMilitaryData,
    profilePictureData: state.familyInfo.profilePictureData,
    profilePictureError: state.familyInfo.profilePictureError
    
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getMilitaryServices,
      updateMilitaryServices,
      uploadCommonUtilityPicture
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MilitaryService);


