const initialState = {
  data: null,
  isFetching: false,
  error: null,
  userInfoData: null,
  isFetchingUserInfo: false,
  homeAPI: null,
  planAPI: null,
};

export const loginReducer = (state = initialState, action) => {
  const {type, payload} = action;
  let currentState = state;
  switch(type) {
      case 'LOGIN_DATA_REQUEST': 
      currentState = {
        ...state,
        isFetching: true,
        error: null,
      };
      break;
      case 'LOGIN_DATA_SUCCESS': 
      currentState = {
        ...state,
        data: payload,
        isFetching: false,
        error: null,
      };
      break;
      case 'LOGIN_DATA_FAILURE': 
      currentState = {
        ...state,
        isFetching: false,
        error: payload,
      };
      break;
      case 'LOGOUT_DATA_REQUEST': 
      currentState = {
        ...state,
        error: null,
      };
      break;
      case 'LOGOUT_DATA_SUCCESS': 
      currentState = {
        ...state,
        data: payload,
        userInfoData: null,
        isFetching: false,
        error: null,
      };
      break;
      case 'LOGOUT_DATA_FAILURE': 
      currentState = {
        ...state,
        isFetching: false,
        error: payload,
      };
      break;
      case 'USERINFO_DATA_REQUEST': 
      currentState = {
        ...state,
        isFetchingUserInfo: true,
        error: null,
      };
      break;
      case 'USERINFO_DATA_SUCCESS': 
      currentState = {
        ...initialState,
        userInfoData: payload,
      };
      break;
      case 'USERINFO_DATA_FAILURE': 
      currentState = {
        ...state,
        isFetchingUserInfo: false,
        error: payload,
      };
      break;
      case 'RESET_LOGIN_DATA': 
      currentState = {
        ...state,
        data: null,
        isFetching: false,
        error: null,
        userInfoData: null,
        isFetchingUserInfo: false,
      };
      break;
      case 'HOME_API_REQUEST':
        currentState = {
          ...state,
          isFetching: true,
          error: null,
        };
        break;
      case 'HOME_API_SUCCESS':
        currentState = {
          ...state,
          homeAPI: payload,
          isFetching: false,
          error: null,
        };
        break;
    case 'HOME_API_FAILURE':
      currentState = {
        ...state,
        isFetching: true,
        error: payload,
      };
      break;
      case 'PLAN_API_REQUEST':
        currentState = {
          ...state,
          isFetching: true,
          error: null,
        };
        break;
      case 'PLAN_API_SUCCESS':
        currentState = {
          ...state,
          planAPI: payload,
          isFetching: false,
          error: null,
        };
        break;
    case 'PLAN_API_FAILURE':
      currentState = {
        ...state,
        isFetching: true,
        error: payload,
      };
      break;
      default:
        
  }
  return currentState;
}
