import React from "react";
import "../../../index.css";
import "./styles/card.css";
import { Typography, Button, Dialog, DialogContent, DialogActions, Divider } from "@mui/material";
import DeleteFilledIcon from '../../../assets/images/filled-delete.svg';

const RemoveDialog = ({ openDialog, onClose, cardContent, onSave, noImage }) => {
  return (
    <>
       <Dialog
        open={openDialog}
        onClose={onClose}
        className="small-dialog dialog-box"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        >
          
            <DialogContent className="content-dialog">
            {!noImage && <img src={DeleteFilledIcon} alt='phone' style={{marginRight: 10}} width='118px' height='118px' /> }
                
                <Typography  className="primary-font-normal-4">{cardContent}</Typography>
            </DialogContent>
            <Divider />
            <DialogActions>
            <div className="action-holder-1  btn-box" style={{marginTop: 20, marginBottom: 0}}>
                <Button variant="outlined" className="primary-font-bold-5 action-btn-no profile-cancel-btn" onClick={onClose}>No</Button>
                <Button variant="outlined" className="primary-font-bold-5 action-btn-yes profile-save-btn" onClick={onSave}>Yes</Button>
            </div>
            </DialogActions>
        </Dialog>
    </>
       
  );
};

export default RemoveDialog;
