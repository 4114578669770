import React, { useState } from "react";
import "../../../../index.css";
import { 
Grid,
Typography, 
Button, 
} from "@mui/material";
import UpdatePassword from './UpdatePassword';
import UpdateSecurityPin from './UpdateSecurityPin';
import UpdateSecurityLock from './UpdateSecurityLock';
import EnterSecurityLockDialog from './EnterSecurityLockDialog';


const ManagePassword = (props) => {

    const [openPassword, setOpenPassword] = useState(false);
    const [openSecurityPin, setOpenSecurityPin] = useState(false);
    const [openSecurityLock, setOpenSecurityLock] = useState(false);

    const handleClickOpen = () => {
        setOpenPassword(true);
    };
    const handleClickOpenSecurityPin = () => {
        setOpenSecurityPin(true);
      };
      const handleClickOpenSecurityLock = () => {
        setOpenSecurityLock(true);
      };
  return (
    <Grid container spacing={2} style={{margin:0, width: '100%'}}>
      {/* <Grid item xs={2} md={2}></Grid> */}
      <div className="manage-password-container manage-password-margin">
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        className="box-container"
      >
        <Grid container spacing={2}  className="manage-password-padding-bottom" style={{margin:0}}>
            <Grid item xs={12} md={6} className="password-container-padding" >
                <Typography className='primary-font-bold-7 profile-input-label'>Password</Typography>
                <input 
                    type='password' 
                    className="profile-input" 
                    aria-label='password'    
                    maxLength={10} 
                    disabled
                    value={'********'}
                    />
                
                <Button variant="outlined" className="primary-font-bold-6 action-btn profile-save-btn" style={{width: '100%'}} onClick={handleClickOpen}>Change Password</Button>
            </Grid>
            <Grid item xs={12} md={6} className="securitypin-container-padding" >
                <Typography className='primary-font-bold-7 profile-input-label'>Security Pin</Typography>
                <input type='password' className="profile-input" aria-label='security-pin'  disabled  maxLength={4} value={'****'}/>
                <Button variant="outlined" className="primary-font-bold-6 action-btn profile-save-btn" style={{width: '100%'}} onClick={handleClickOpenSecurityPin}>Change Security Pin</Button>
            </Grid>
            <Grid item xs={12} md={6} className="securitylock-container-padding" >
                <Typography className='primary-font-bold-7 profile-input-label'>Security Lock</Typography>
                <input type='password' className="profile-input" aria-label='security-lock'  disabled  maxLength={6} value={'******'}/>
                <Button variant="outlined" className="primary-font-bold-6 action-btn profile-save-btn" style={{width: '100%'}} onClick={handleClickOpenSecurityLock}>Change Security Lock</Button>
            </Grid>
        </Grid>
        <UpdatePassword openDialog={openPassword} closeDialog={()=>{setOpenPassword(false)}} />
        <UpdateSecurityPin openDialog={openSecurityPin} closeDialog={()=>{setOpenSecurityPin(false)}} />
        <UpdateSecurityLock openDialog={openSecurityLock} closeDialog={()=>{setOpenSecurityLock(false)}} />
        {props.openPopUp && <EnterSecurityLockDialog isCloseIconNeeded={true} /> }
      </Grid>
      </div>
      {/* <Grid item xs={2} md={2}></Grid> */}
    </Grid>
  );
};

  export default ManagePassword;

