const initialState = {
  passwordData: null,
  passwordError: null,
  isVerifyingPassword: false,
  pinData: null,
  pinError: null,
  isVerifyingPin: false,
  lockData: null,
  lockError: null,
  isVerifyingLock: false,
  isOTPSent: null,
  isVerifySecurityLockOtp: null,
  isSecurityPinOTPSent: null,
  VerifyPinResponseData: null
};

export const homeReducer = (state = initialState, action) => {
  const {type, payload} = action;
  let currentState = state;
  switch(type) {
      case 'PASSWORD_UPDATE_REQUEST': 
      currentState = {
        ...state,
        isVerifyingPassword: true,
        error: payload,
      };
      break;
      case 'PASSWORD_UPDATE_SUCCESS': 
      currentState = {
        ...state,
        passwordData: payload,
        isVerifyingPassword: false,
        passwordError: null,
      };
      break;
      case 'PASSWORD_UPDATE_FAILURE': 
      currentState = {
        ...state,
        passwordData:null,
        isVerifyingPassword: false,
        passwordError: payload,
      };
      break;
      case 'SECURITY_PIN_UPDATE_REQUEST': 
      currentState = {
        ...state,
        isVerifyingPin: true,
        error: payload,
      };
      break;
      case 'SECURITY_PIN_UPDATE_SUCCESS': 
      currentState = {
        ...state,
        pinData: payload,
        isVerifyingPassword: false,
        pinError: null,
      };
      break;
      case 'SECURITY_PIN_UPDATE_FAILURE': 
      currentState = {
        ...state,
        pinData:null,
        isVerifyingPin: false,
        pinError: payload,
      };
      break;
      case 'SECURITY_LOCK_UPDATE_REQUEST': 
      currentState = {
        ...state,
        isVerifyingLock: true,
        lockError: payload,
      };
      break;
      case 'SECURITY_LOCK_UPDATE_SUCCESS': 
      currentState = {
        ...state,
        lockData: payload,
        isVerifyingLock: false,
        lockError: null,
      };
      break;
      case 'RESET_HOME_DATA':
        currentState = {
          passwordData: null,
          passwordError: null,
          isVerifyingPassword: false,
          pinData: null,
          pinError: null,
          isVerifyingPin: false,
          lockData: null,
          lockError: null,
          isVerifyingLock: false,
        };
      break;
      case 'SECURITY_LOCK_UPDATE_FAILURE': 
      currentState = {
        ...state,
        lockData:null,
        isVerifyingLock: false,
        lockError: payload,
      };
      break;
    
        case 'SECURITY_LOCK_CHANGE_OTP_SUCCESS': 
        currentState = {
          ...state,
          isOTPSent:payload,
        };
        break;
        case 'SECURITY_LOCK_CHANGE_OTP_FAILURE': 
        currentState = {
          ...state,
          isOTPSent:payload,
        };
        break;
        case 'SECURITY_LOCK_VERIFY_OTP_SUCCESS':
        currentState = {
          ...state,
          isVerifySecurityLockOtp:payload,
        }
        break;
        case 'SECURITY_LOCK_VERIFY_OTP_FAILURE':
        currentState = {
          ...state,
          isVerifySecurityLockOtp:payload,
        }
        break;
        case 'SECURITY_PIN_CHANGE_OTP_SUCCESS':
        currentState = {
          ...state,
          isSecurityPinOTPSent:payload,
        }
        break;
        case 'SECURITY_PIN_CHANGE_OTP_FAILURE':
        currentState = {
          ...state,
          isSecurityPinOTPSent: payload,
        }
        break;
        case 'SECURITY_PIN_VERIFY_OTP_SUCCESS':
        currentState = {
          ...state,
          VerifyPinResponseData:payload,
        }
        break;
        case 'SECURITY_PIN_VERIFY_OTP_FAILURE':
        currentState = {
          ...state,
          VerifyPinResponseData:payload,
        }
        default:
  }
  return currentState;
}
