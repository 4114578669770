import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import {
  Button,
  Box,
  Typography,
  Divider,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  confirmOTPAccount,
  verifyRegisteredUser,
  resendVerificationCode,
  clearRegistrationData
} from "../redux/actions";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CloseIcon from "../../../../assets/icons/modal-close-icon.svg";
import CheckIcon from "../../../../assets/images/CheckIcon.svg";

const AccountVerification = (props) => {
  const {
    confirmOTPAccount,
    verifyRegisteredUser,
    otpData,
    otpError,
    verificationData,
    verificationError,
    resendVerificationLoading,
    resendVerificationSuccess,
    resendVerificationCode,
    clearRegistrationData
  } = props;
  const navigate = useNavigate();
  const [code, setCode] = useState(null);
  const [codeErr, setCodeErr] = useState("");
  const [showCode, setShowCode] = useState(false);
  const handleClickShowCode = () => setShowCode(!showCode);
  const handleMouseDownCode = () => setShowCode(!showCode);

  const email = window.localStorage.getItem("registeremail");
  useEffect(() => {
    if (otpData) {
      verifyRegisteredUser({ email: email });
    }
    if (otpError) {
      setCodeErr("Invalid verification code provided, please try again");
    }
    if (verificationData && verificationData.status === 200) {
      window.localStorage.setItem(
        "userInfo",
        JSON.stringify(verificationData.data)
      );
      navigate("/home");
    }
  }, [otpData, otpError, verificationData]);
  const onChangeCode = (e) => {
    setCode(e.target.value);
  };
  const onClickSave = () => {
    if (!code?.trim().length) {
      setCodeErr("This is required.");
      return;
    } else if (code?.trim().length !== 6) {
      setCodeErr("Verification Code should contain 6 digits.");
      return;
    } else {
      setCodeErr("");
    }
    const email = window.localStorage.getItem("registeremail");
    confirmOTPAccount({ username: email, code: code });
  };
  const onResend = () => {
    resendVerificationCode();
  };
  const handleCloseIconClick = () => {
    clearRegistrationData();
    navigate("/login");
    setCodeErr("")
  };
  return (
    <Box className="loginScreen" id="account-layout">
      <Card sx={{ maxWidth: 556 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            className="primary-font-bold-heading1"
            style={{ paddingLeft: 30 }}
          >
            Verification
          </Typography>
          <div
            style={{
              display: "flex",
              alignitems: "flex-end",
              justifycontent: "end",
              paddingRight: 30,
            }}
          >
            <img
              src={CloseIcon}
              alt="close-icon"
              style={{ cursor: "pointer",width:"21px" }}
              onClick={handleCloseIconClick}
              sx={{
                marginRight: "30px",
              }}
            />
          </div>
        </div>
        <Divider />
        <CardContent style={{ padding: "15.5px 42px 32px 42px" }}>
          <Typography
            className="primary-font-normal-2"
            component="div"
            style={{ marginBottom: 25 }}
          >
            We have sent a code email to <b>{email}</b> enter it below to
            confirm your account.
          </Typography>
          <div>
            <div>
              <Typography className="font-normal-small error-message">
                {codeErr}
              </Typography>
              <Typography className="font-normal-small profile-input-label">
                Verification Code
              </Typography>
              <div className="input-holder">
                <input
                  type={showCode ? "text" : "password"}
                  className={`profile-input ${
                    codeErr !== "" ? "err-input" : ""
                  }`}
                  aria-label="new-pin"
                  maxLength={6}
                  onChange={(e) => {
                    onChangeCode(e);
                  }}
                  value={code}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="password"
                  onClick={handleClickShowCode}
                  onMouseDown={handleMouseDownCode}
                  className="input-eye-icon pwd-icon1"
                >
                  {showCode ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </div>
            </div>
          </div>
          <div
            className="action-holder"
            style={{ marginTop: 20, marginBottom: 0 }}
          >
            <Button
              variant="outlined"
              style={{ width: "100%" }}
              className="primary-font-bold-6 action-btn profile-save-btn"
              onClick={onClickSave}
            >
              Confirm Account
            </Button>
          </div>
          {resendVerificationSuccess && (
            <Typography
              className="primary-font-normal-2"
              component="div"
              style={{ marginTop: 45, display: "flex", alignItems: "center" }}
            >
              <img src={CheckIcon} width={20} style={{ marginRight: 10 }} />{" "}
              Verification code sent to your registered Email!
            </Typography>
          )}
          <div style={{ textAlign: "center" }}>
            <Typography
              className="primary-font-normal-2"
              component="div"
              style={{ marginTop: 25 }}
            >
              Didn't receive a code?
            </Typography>
          </div>
          <div
            className="action-holder"
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            {resendVerificationLoading ? (
              <Button
                variant="outlined"
                style={{ width: "50%" }}
                className="primary-font-bold-6 action-btn profile-save-btn"
                onClick={onResend}
                disabled={resendVerificationLoading}
                startIcon={
                  <CircularProgress
                    style={{ width: 20, height: 20, color: "#ff3535" }}
                  />
                }
              >
                Resend it
              </Button>
            ) : (
              <Button
                variant="outlined"
                style={{ width: "50%" }}
                className="primary-font-bold-6 action-btn profile-save-btn"
                onClick={onResend}
              >
                Resend it
              </Button>
            )}
          </div>
        </CardContent>
      </Card>
    </Box>
  );
};
// export default LogIn;
AccountVerification.propTypes = {
  confirmOTPAccount: PropTypes.func.isRequired,
  otpData: PropTypes.shape(),
  otpError: PropTypes.shape(),
  isOTPFetching: PropTypes.bool,
};

AccountVerification.defaultProps = {
  otpData: null,
  otpError: null,
  isOTPFetching: null,
};

function mapStateToProps(state) {
  return {
    otpData: state.signup.otpData,
    otpError: state.signup.otpError,
    isOTPFetching: state.signup.isOTPFetching,
    registerError: state.signup.registerError,
    verificationData: state.signup.verificationData,
    verificationError: state.signup.verificationError,
    resendVerificationLoading: state.signup.resendVerificationLoading,
    resendVerificationSuccess: state.signup.resendVerificationSuccess,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      confirmOTPAccount,
      verifyRegisteredUser,
      resendVerificationCode,
      clearRegistrationData
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountVerification);
