import React, { useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';


const IssueDetails = ({onBackOrCancelClick, handleNext}) => {
    const initialFormData = {
        dateIssued:"",
        expirationDate:"",
        country:"",
        placeOfIssue:""

      };
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialFormData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, ['expirationDate']: date });
  };
  const handleDateChange1 = (date) => {
    setFormData({ ...formData, ['dateIssued']: date });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};
    if (formData.dateIssued === '' || !formData.dateIssued) {
        newErrors.dateIssued = 'This is required.';
    }
    if (formData.expirationDate === '' || !formData.expirationDate) {
        newErrors.expirationDate = 'This is required.';
    }
    if (formData.country.trim() === '') {
      newErrors.country = 'This is required.';
    }
    if (formData.placeOfIssue.trim() === '') {
      newErrors.placeOfIssue = 'This is required.';
    }
   
    

    if (Object.keys(newErrors).length === 0) {
      console.log(formData);
      setErrors({});
      handleNext();
    } else {
      setErrors(newErrors);
      console.log('Validation errors:', newErrors);
    }
  };

return (
    <Grid container spacing={2} style={{margin:0, padding: 32, width: '100%'}}>
        <Grid item xs={12} md={6} sx={{padding: 0}}>
            <div style={{padding: 20}}>
                <div>
                <Typography className='font-normal-small error-message'>{errors.dateIssued}</Typography>
                        <Typography className='font-normal-small profile-input-label'>Date Issued  </Typography>
                        <DatePicker name="dateIssued" id="dateIssued" className={`profile-input ${errors.dateIssued  ? 'err-input' : ''}`}  aria-label='dateIssued'  onChange={handleDateChange1} value={formData.dateIssued}/>
                </div>
                <div>
                <Typography className='font-normal-small error-message'>{errors.expirationDate}</Typography>
                        <Typography className='font-normal-small profile-input-label'>Expiration Date  </Typography>
                        <DatePicker name="expirationDate" id="expirationDate" className={`profile-input ${errors.expirationDate  ? 'err-input' : ''}`}  aria-label='expirationDate'  onChange={handleDateChange} value={formData.expirationDate}/>
                </div>
             
            </div>
        </Grid>
        <Grid item xs={12} md={6} sx={{padding: 0}}>
            <div style={{padding: 20}}>
                   
               <div>
                    <Typography className='font-normal-small error-message'>{errors.country}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Country</Typography>
                    <select type="text" name="country" id="country"  className={`profile-input ${errors.country ? 'err-input' : ''}`} aria-label='country' onChange={handleInputChange}  value={formData.country}>
                        <option value="" ></option>
                        <option value="country1">country 1</option>
                        <option value="country2">country 2</option>
                        <option value="country3">country 3</option>
                        <option value="country4">country 4</option>
                      </select>
                </div>
                 
               <div>
                    <Typography className='font-normal-small error-message'>{errors.placeOfIssue}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Place of Issue</Typography>
                    <select type="text" name="placeOfIssue" id="placeOfIssue"  className={`profile-input ${errors.placeOfIssue ? 'err-input' : ''}`} aria-label='placeOfIssue' onChange={handleInputChange}  value={formData.placeOfIssue}>
                        <option value="" ></option>
                        <option value="placeofissue1">place of issue 1</option>
                        <option value="placeofissue2">place of issue 2</option>
                        <option value="placeofissue3">place of issue 3</option>
                        <option value="placeofissue4">place of issue 4</option>
                      </select>
                </div>
               
            </div>
        </Grid>
        <div className="action-holder" style={{marginTop: 0, marginBottom: 50}}>
          <Button variant="outlined"  className="primary-font-bold-6 action-btn profile-cancel-btn" onClick={onBackOrCancelClick}>Cancel</Button>
          <Button variant="outlined" className="primary-font-bold-6 action-btn profile-save-btn"  onClick={handleSubmit}>Next</Button>
        </div>
    </Grid>
    )
}
export default IssueDetails;
