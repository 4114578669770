import React, { useState } from "react";
import "../../../../index.css";
import "../styles/home.css";
import { Grid } from "@mui/material";
import Family from "../../../../assets/images/my-info/my-family.png";
import CC from "../../../../assets/images/my-info/creditcard.png";
import BackAccount from "../../../../assets/images/my-info/bankaccount.png";
import Identification from "../../../../assets/images/my-info/identification.png";
import SocialMedia from "../../../../assets/images/my-info/socialmedia.png";
import MyBusiness from "../../../../assets/images/my-info/My_Business.png";
import MyPets from "../../../../assets/images/my-info/myPets.png";
import Assets from "../../../../assets/images/my-info/assets.png";
import SecurityLock from "../../../../assets/images/securityLock.svg";
import MainCard from "../../common/MainCard";
import SecurityPinDialog from "./SecurityPinDialog";
import Passworddialog from "./PasswordDialog";
import SecurityLockDialog from "./SecurityLockDialog";
import CreateSecurityLockDialog from "./CreateSecurityLockDialog";
import { Repeat } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const HomePage = () => {
  const { t } = useTranslation("common");
  const cardList = [
    {
      image: Family,
      redText: "MY",
      cardText: "Family",
      redirection: "/my-family",
      iconOverImageUrl: "",
      toRedirect: true,
      securityPin: false,
    },
    {
      image: BackAccount,
      redText: "MY",
      cardText: "Bank Accounts",
      redirection: "/my-bank-accounts",
      iconOverImageUrl: SecurityLock,
      toRedirect: true,
      securityPin: true,
    },
    {
      image: CC,
      redText: "MY",
      cardText: "Credit Cards",
      redirection: "/my-credit-cards",
      iconOverImageUrl: SecurityLock,
      toRedirect: false,
      securityPin: false,
    },
    {
      image: Assets,
      redText: "MY",
      cardText: "Assets",
      redirection: "/my-assets",
      iconOverImageUrl: "",
      toRedirect: true,
      securityPin: false,
    },
    {
      image: Identification,
      redText: "MY",
      cardText: "Identification",
      redirection: "/my-identifications",
      iconOverImageUrl: "",
      toRedirect: false,
      securityPin: false,
    },
    
    // {
    //   image: Vehicle,
    //   redText: 'MY',
    //   cardText:'Vehicles',
    //   redirection:'#'
    // },
    // {
    //   image: Property,
    //   redText: 'MY',
    //   cardText:'Property Inventory',
    //   redirection:'#'
    // },
    {
      image: SocialMedia,
      redText: "MY",
      cardText: "Social Media",
      redirection: "#",
      iconOverImageUrl: "",
      toRedirect: false,
      securityPin: false,
      // redirection:'/my-socialmedia',
    },
    {
      image: MyBusiness,
      redText: 'MY',
      cardText:'Business',
      redirection:'#',
      iconOverImageUrl: "",
      toRedirect: false,
      securityPin: false,
    },  
    {
      image: MyPets,
      redText: 'MY',
      cardText:'Pets',
      redirection:'#',
      iconOverImageUrl: "",
      toRedirect: false,
      securityPin: false,
    }
  ];
  const userDetails = window.localStorage.getItem("userInfo")
    ? JSON.parse(window.localStorage.getItem("userInfo"))
    : null;

  const [openSecurityPin, setOpenSecurityPin] = useState(userDetails ? (userDetails?.securityPin ? false : true) : false);
  const [openPassword, setOpenPassword] = useState(userDetails?.password ?  false : false);
  const [openCreateSecurityLock, setOpenreateSecurityLock] = useState(userDetails ? (userDetails?.securityLock ?  false : true) : false);
  const [openSecurityLock, setOpenSecurityLock] = useState(false);

  return (
    <Grid container  >
      {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
      <div className="MyInfo-header-container">
      <Grid
     container 
       
        style={{
          // display: "grid",
          // gridAutoRows: "auto",
          // // gridGap: "1rem",
          // gridTemplateColumns: "repeat(auto-fill, 330px)",
          // justifyContent: "space-between",
          // padding: "63px 10px 0px 20px"
        }}
        // grid-template-columns: repeat(3,350px);
        //     grid-auto-rows: auto;
        //     grid-gap: 1rem;
        // style={{ display: "flex", flexWrap: 'wrap',padding:'40px 0px', justifyContent:'space-between', gap: 15}}
      >
        <MainCard cardList={cardList}  />
        {openSecurityPin && (
          <SecurityPinDialog
            openDialog={openSecurityPin}
            closeDialog={() => {
              setOpenSecurityPin(false);
            }}
          />
        )}
        {openCreateSecurityLock && (
          <CreateSecurityLockDialog
            openDialog={openCreateSecurityLock}
            closeDialog={() => {
              setOpenreateSecurityLock(false);
            }}
          />
        )}
        {openSecurityLock && (
          <SecurityLockDialog
            openDialog={openSecurityLock}
            closeDialog={() => {
              setOpenSecurityLock(false);
            }}
          />
        )}
        {openPassword && (
          <Passworddialog
            openDialog={openPassword}
            closeDialog={() => {
              setOpenPassword(false);
            }}
          />
        )}
      </Grid>
      </div>
      {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
    </Grid>
  );
};

export default HomePage;
