import React, { useState, useEffect } from "react";
import { Grid, Typography, Button } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import UploadImage from '../../../../../../assets/icons/upload-icon.svg';
import * as formatters from '../../../../../../utils/util';

const VechileDetails = ({onBackOrCancelClick, handleNext}) => {
  const initialFormData = {
    identificationNumber:'',
    vechileType:'',
    description:''
  };
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialFormData);
  const [imageUrlAvatar1, setImageUrlAvatar1] = useState(null);
  const [imageUrlAvatar, setImageUrlAvatar] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const[image, setImage] = useState(null);
  const[previewUrl, setPreviewUrl] = useState(""); 

  const handleFile = file => {
    //you can carry out any file validations here...
    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    setImageUrlAvatar1(URL.createObjectURL(file))
  }
  const handleOnDrop = event => {
    event.preventDefault(); 
    event.stopPropagation(); 
    //let's grab the image file
    let imageFile = event.dataTransfer.files[0];
    handleFile(imageFile);
  }

  const handleDragOver = event => {
    event.preventDefault();
  }
  useEffect(() => {
    if (selectedImage) {
      setImageUrlAvatar1(URL.createObjectURL(selectedImage));
      setPreviewUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const [showPassword, setShowPassword] = useState(false);
  const [showCode, setShowCode] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowCode = () => setShowCode(!showCode);
  const handleMouseDownCode = () => setShowCode(!showCode);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleDateChange = (date) => {
    setFormData({ ...formData, ['expirationDate']: date });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};
    console.log(formData, 'formDataformDataformData')
    if (formData.identificationNumber.trim() === '') {
      newErrors.identificationNumber = 'Identification Number(VIN) is required.';
    }else if (! formatters.isNumaric(formData.identificationNumber)){
      newErrors.identificationNumber = 'Enter Valid Identification Number(VIN).';
    }
    if (formData.vechileType.trim() === '') {
        newErrors.vechileType = 'Vechile Type is required.';
    }
    if (formData.description.trim() === '') {
      newErrors.description = 'Description is required.';
    } 

    if (Object.keys(newErrors).length === 0) {
      console.log(formData);
      setErrors({});
      handleNext();
    } else {
      setErrors(newErrors);
      console.log('Validation errors:', newErrors);
    }
  };
return (
    <Grid container spacing={2} style={{margin:0, padding: 32, width: '100%'}}>
        <Grid item xs={12} md={6} sx={{padding: 0}}>
            <div style={{padding: 20}}>
                <div>
                    <Typography className='font-normal-small error-message'>{errors.identificationNumber}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Vehicle Identification Number (VIN)</Typography>
                    <input type="text" name="identificationNumber" id="identificationNumber"  className={`profile-input ${errors.identificationNumber ? 'err-input' : ''}`} aria-label='identificationNumber' onChange={handleInputChange}  value={formData.identificationNumber}/>
                </div>
              
               <div>
                    <Typography className='font-normal-small error-message'>{errors.vechileType}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Vehicle Type</Typography>
                    <select type="text" name="vechileType" id="vechileType"  className={`profile-input ${errors.vechileType ? 'err-input' : ''}`} aria-label='vechileType' onChange={handleInputChange}  value={formData.vechileType}>
                        <option value="" ></option>
                        <option value="vechile1">vechile 1</option>
                        <option value="vechile2">vechile 2</option>
                        <option value="vechile3">vechile 3</option>
                        <option value="vechile4">vechile 4</option>
                      </select>
                </div>
               
                <div style={{marginLeft: 0}}> 
                <div>
                    <Typography className='font-normal-small error-message'>{errors.description}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Description</Typography>
                    <textarea type="textarea" name="description" id="description"  className={`profile-input ${errors.description ? 'err-input' : ''}`} aria-label='description' onChange={handleInputChange}  value={formData.description}/>
                </div>
                </div>
            </div>
        </Grid>
        <Grid item xs={12} md={6} sx={{padding: 0}}>
            <div style={{padding: 20}}>
                <input
                    // accept="image/*"
                    type="file"
                    id="select-image"
                    style={{ display: "none" }}
                    onChange={(e) => {setSelectedImage(e.target.files[0]); handleFile(e.target.files[0]);}}
                />
                <label htmlFor="select-image"> 
                    <div className="photo-upload-container drop_zone" style={{height:272, marginBottom: 30}} onDragOver={handleDragOver} onDrop={handleOnDrop}>
                    <img  src={UploadImage} alt={'upload-avatar'} style={{cursor: 'pointer'}} />
                    <Typography className='primary-font-normal-3 profile-input-label' color='#ABAFB3'>Drag and drop a file here <br />or click to <span style={{color:'#4979FF'}}>upload</span></Typography>
                    { previewUrl && <div className="image">
                        {/* <div>{image.name}</div> */}
                      <img src={previewUrl} alt='image' width={100} height={100}/> 
                    </div> }
                  </div>
                </label>
            </div>
        </Grid>
        <div className="action-holder" style={{marginTop: 0, marginBottom: 50}}>
                <Button variant="outlined"  className="primary-font-bold-6 action-btn profile-cancel-btn" onClick={onBackOrCancelClick}>Cancel</Button>
                <Button variant="outlined" className="primary-font-bold-6 action-btn profile-save-btn"  onClick={handleSubmit}>Next</Button>
              </div>
    </Grid>
    )
}
export default VechileDetails;
