import React, { useState, useEffect,useContext } from 'react'
import { styled } from '@mui/material/styles';
import { Grid, Card, CardContent, CardActions, Typography, Button, Dialog, DialogContent, DialogActions, Divider, IconButton, Box } from "@mui/material";
import Home_template from '../../../../../../assets/images/my-assets/home_template.svg';
import DeleteFilledIcon from '../../../../../../assets/images/filled-delete.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {UserInfoDetails} from '../../../../../../utils/userContext.js';
import { getAllPropertyList, deletePropertyInventory, copyPropertyInventoryDetails , downloadPropertyInventoryDetails} from '../../../redux/actions.js';

const ImageHolder = styled('div')({
    textAlign: 'center',
    height: 140, 
    width: '100%',
    border: '1px solid #E9E9E9', 
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  });

const PropertyInventory = ({InventoryDetails, getAllPropertyList, deletePropertyInventory, deleteSpecificInventory, downloadPropertyInventory, copyPropertyInventory, copyPropertyInventoryDetails, downloadPropertyInventoryDetails}) => {
    const { t } = useTranslation('common');
    const navigate = useNavigate();

    const [removeDialog, setRemoveDialogOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [selectedObject, setSelectedObject] = useState(null)
    const {userObject} = useContext(UserInfoDetails)

    
    useEffect(()=>{
        if(deleteSpecificInventory && deleteSpecificInventory.status === 200){
                getAllPropertyList();
                setRemoveDialogOpen(false);
        }
    },[deleteSpecificInventory])

    useEffect(()=>{
        if(copyPropertyInventory && copyPropertyInventory.status === 200){
                getAllPropertyList();
        }
    },[copyPropertyInventory]);

const copyProperty = (id) => {
    const input = {
        "propertyId": id,
        "updatedBy": userObject.id,
    }

    copyPropertyInventoryDetails(input)
}

const downloadProperty = (item) => {
    setSelectedObject(item);
    const input = {
        "id": item?.propertyId,
        "owner_name": item?.propertyOwnerInfo?.owner,
        "home_name": item?.propertyOwnerInfo?.itemName,
    }
    downloadPropertyInventoryDetails(input)
}
const deleteProperty = () =>{
    const userDetails = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')): null;
    const input = {
        "id": selectedObject?.propertyId,
        "updatedBy": userDetails?.id
    }
    deletePropertyInventory(input)
}

return (
    <>
    <Grid container columnSpacing={3} rowGap={3}>
        {InventoryDetails.map((item, i) => {
            return (
                <Grid item xs={12} sm={6} md={4}lg={3} >
                <Card className="card-box property-card" key={'familyMemberCard_' + i}>
                    <ImageHolder style={{ height: 160 }}
                     onClick={()=>{
                        // window.localStorage.setItem('selectedProperty', JSON.stringify({propertyId: item?.id}));
                        // window.localStorage.setItem('hideEdit', true);
                        navigate(`/edit-property-details/${item.propertyId}`,{state:{hideEdit:true}}) 
                   }}
                    >
                        <img src={item?.propertyOwnerInfo?.image_PreSignedUrl ? item?.propertyOwnerInfo?.image_PreSignedUrl : Home_template} alt={item.imageAlt} style={{ width: "100%", height: "160px", borderRadius: "10px" }} />
                    </ImageHolder>
                    <CardContent className="card-content-box" sx={{ alignItems: 'start' }}
                    onClick={()=>{
                        // window.localStorage.setItem('selectedProperty', JSON.stringify({propertyId: item?.id}));
                        // window.localStorage.setItem('hideEdit', true);
                        navigate(`/edit-property-details/${item.propertyId}`,{state:{hideEdit:true}}) 
                   }}
                   >
                        <Box sx={{ display: 'flex', flexDirection: 'column',width:"100%" }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center', marginTop: 23, width: '100%' }}>
                                <Typography className="primary-font-medium">
                                    {item?.propertyOwnerInfo?.owner}
                                </Typography>
                                <Typography style={{fontSize:"15px"}} className=''>Quantity: {item.propertyInfo?.quantity ? item.propertyInfo?.quantity : "-"}</Typography>
                            </div>
                            <div style={{  display: 'flex', justifyContent: 'space-between',alignItems: 'center', marginTop: 10, width: '100%' }}>
                                <Typography style={{fontSize:"15px"}} className="">
                                    {item.propertyOwnerInfo?.itemName}
                                </Typography>
                                <Typography style={{ fontSize: "15px" }} className=''>
                                    Total Cost: {item.propertyInfo?.unitCost ? ('$' + (item.propertyInfo?.unitCost * (item.propertyInfo?.quantity ? item.propertyInfo?.quantity : 1))) : "-"}
                                </Typography>

                            </div>
                        </Box>
                        <Divider style={{ borderColor: '#D8D8D8', width: '100%', marginTop: 10, marginBottom: 10 }} />

                    </CardContent>
                    <CardActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 0 }}>
                        <div title={"Download-PDF"} className="action-button-pdf" onClick={() => { downloadProperty(item) }}></div>
                        <div title={"Delete"} className="action-button-dlt" onClick={() => { setRemoveDialogOpen(true); setSelectedObject(item); }}></div>
                        <div title={"Make-Copy"} className="action-button-copy" onClick={() => {  copyProperty(item.propertyId) }}></div>
                        <div title={"Edit"} className="action-button-edit" onClick={() => {  navigate(`/edit-property-details/${item.propertyId}`,{state:{hideEdit:false}})}}></div>
                    </CardActions>
                </Card>
                </Grid>
            )
        })}
        <Grid item xs={12} sm={6} md={4}lg={3} >
        <Card className="add-card-box property-card" onClick={() => { window.location.href = '/add-property-details' }}>
            <div className="add-icon">
            </div>
            <Typography style={{ textAlign: 'center', marginTop: 41 }} className="primary-font-medium">
                Add <br /> My Property
            </Typography>
        </Card>
        </Grid>
        {removeDialog && (
            <Dialog
                open={removeDialog}
                onClose={() => setRemoveDialogOpen(false)}
                className="small-dialog dialog-box"
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
                <DialogContent className="content-dialog" >
                    <img src={DeleteFilledIcon} alt='phone' style={{ marginRight: 10 }} width='118px' height='118px' />
                    <Typography className="primary-font-normal-4">Are you sure want to delete?</Typography>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <div className="action-holder" style={{ marginTop: 20, marginBottom: 0}}>
                        <Button variant="outlined" className="primary-font-bold-5 action-btn-no profile-cancel-btn" onClick={() => setRemoveDialogOpen(false)}>No</Button>
                        <Button variant="outlined" className="primary-font-bold-5 action-btn-yes profile-save-btn" onClick={deleteProperty} >Yes</Button>
                    </div>
                </DialogActions>
            </Dialog>
        )}
        </Grid>
    </>

)}


function mapStateToProps(state){
    return{
        propertyInventoryList : state.propertyInventory.propertyInventoryList,
        deleteSpecificInventory : state.propertyInventory.deleteSpecificInventory,
        copyPropertyInventory: state.propertyInventory.copyPropertyInventory,
        downloadPropertyInventory: state.propertyInventory.downloadPropertyInventory
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        getAllPropertyList,
        deletePropertyInventory,
        copyPropertyInventoryDetails,
        downloadPropertyInventoryDetails
      },
      dispatch
    );
  }
  export default connect(mapStateToProps, mapDispatchToProps)(PropertyInventory);