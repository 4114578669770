import React, { useEffect, useState } from "react";
import "../../../../index.css";
import {
    Typography,
    Divider,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { changeSecurityLock } from '../redux/actions';

import CloseIcon from '../../../../assets/icons/modal-close-icon.svg';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import * as formatters from '../../../../utils/util';

const UpdateSecurityLock = (props) => {
    const {
        openDialog,
        closeDialog,
        isVerifyingLock,
        changeSecurityLock,
        lockData,
        lockError } = props;

    const [showOldPassword2, setShowOldPassword2] = useState(false);
    const [showNewPassword2, setShowNewPassword2] = useState(false);
    const [showConfirmNewPassword2, setShowConfirmNewPassword2] = useState(false);

    const handleClickShowOldPassword2 = () => setShowOldPassword2(!showOldPassword2);
    const handleMouseDownOldPassword2 = () => setShowOldPassword2(!showOldPassword2);
    const handleClickShowNewPassword2 = () => setShowNewPassword2(!showNewPassword2);
    const handleMouseDownNewPassword2 = () => setShowNewPassword2(!showNewPassword2);
    const handleClickConfirmShowNewPassword2 = () => setShowConfirmNewPassword2(!showConfirmNewPassword2);
    const handleMouseDownConfirmNewPassword2 = () => setShowConfirmNewPassword2(!showConfirmNewPassword2);

    const [enterPassword, setEnterPassword] = useState(null);
    const [newSecurityLock, setNewSecurityLock] = useState(null);
    const [confirmNewSecurityLock, setConfirmNewSecurityLock] = useState(null);
    const [enterPasswordErr, setEnterPasswordErr] = useState('');
    const [newSecurityLockErr, setNewSecurityLockErr] = useState('');
    const [confirmNewSecurityLockErr, setConfirmNewSecurityLockErr] = useState('');

    const user = window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem('userInfo')) : null;

    useEffect(() => {
        if (lockData && lockData.status === 200) {
            resetValue();
            resetLockErrorMessage();
            closeDialog();
        }
        if (lockData && lockData.status !== 200) {
            setEnterPasswordErr(lockData?.message || "");
        }
    }, [lockData, lockError])

    const onChangeLock = (e, type) => {
        if (type === 'enterPassword') {
            setEnterPassword(formatters.isNumaric(e.target.value));
        } else if (type === 'newSecurityLock') {
            setNewSecurityLock(formatters.isNumaric(e.target.value));
        } else if (type === 'confirmNewSecurityLock') {
            setConfirmNewSecurityLock(formatters.isNumaric(e.target.value));
        }
    }
    const resetValue = (value) => {
        setEnterPassword('');
        setNewSecurityLock('');
        setConfirmNewSecurityLock('');
    };

    const resetLockErrorMessage = () => {
        setEnterPasswordErr('');
        setNewSecurityLockErr('');
        setConfirmNewSecurityLockErr('');
    }
    const onClosingDialog = () => {
        resetValue();
        resetLockErrorMessage();
        closeDialog();
    }

    const onSaveChangeLock = () => {
        let isInValid = false;
        resetLockErrorMessage();
        if (!enterPassword?.trim().length) {
            setEnterPasswordErr('This is required.');
            isInValid = true;
        }
        if (!newSecurityLock?.trim().length) {
            setNewSecurityLockErr('This is required.');
            isInValid = true;
        } else if (newSecurityLock?.trim().length !== 6) {
            setNewSecurityLockErr('New Security Lock should contain 6 digits.');
            isInValid = true;
        }
        if (!confirmNewSecurityLock?.trim().length) {
            setConfirmNewSecurityLockErr('This is required.');
            isInValid = true;
        }
        if (newSecurityLock !== confirmNewSecurityLock) {
            setConfirmNewSecurityLockErr('New Security Lock and Confirm New Security Lock mismatch.');
            isInValid = true;
        }
        if (newSecurityLock?.trim() === enterPassword?.trim()) {
            setConfirmNewSecurityLockErr('New Security Lock and Old Security Lock should not be same');
            isInValid = true;
        }
        if (isInValid) {
            return;
        }
        const payload = {
            oldSecurityLock: enterPassword,
            securityLock: newSecurityLock,
            id: user.id,
            updatedBy: user.id
        }
        changeSecurityLock(payload)
    }

    return (
        <Dialog
            open={openDialog}
            onClose={onClosingDialog}
            className="change-pin-dialog dialog-box"
            aria-labelledby="change-pin-dialog-title"
            aria-describedby="change-pin-dialog-description"
        >
            <DialogTitle id="change-pin-dialog-title">
                <Typography className='primary-font-bold-heading2'>Change Security Lock</Typography>
                {openDialog ? (
                    <img src={CloseIcon} alt="close-icon" style={{ cursor: 'pointer',width:"21px" }} onClick={onClosingDialog} sx={{
                        marginRight: '-30px',
                    }} />
                ) : null}
            </DialogTitle>
            <Divider />
            <DialogContent>
                <div>
                    <Typography className='font-normal-small error-message'>{enterPasswordErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Old Security Lock</Typography>
                    <div className="input-holder">
                        <input className={`profile-input ${enterPasswordErr !== '' ? 'err-input' : ''}`} aria-label='enter-password' type={showOldPassword2 ? "text" : "password"} onChange={(e) => { onChangeLock(e, 'enterPassword') }} maxLength={6} value={enterPassword} />
                        {/* <input className={`primary-font-normal-1 login-input box-spacing`} type={showPassword ? "text" : "password"} aria-label='password' onChange={onChangePassword} value={password} placeholder="Password"/> */}
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="old-password"
                            onClick={handleClickShowOldPassword2}
                            onMouseDown={handleMouseDownOldPassword2}
                            className="input-eye-icon pwd-icon1"
                        >
                            {showOldPassword2 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </div>
                </div>
                <div>
                    <Typography className='font-normal-small error-message'>{newSecurityLockErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>New Security Lock</Typography>
                    <div className="input-holder">
                        <input className={`profile-input ${newSecurityLockErr !== '' ? 'err-input' : ''}`} aria-label='new-lock' type={showNewPassword2 ? "text" : "password"} onChange={(e) => { onChangeLock(e, 'newSecurityLock') }} maxLength={6} value={newSecurityLock} />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="new-lock"
                            onClick={handleClickShowNewPassword2}
                            onMouseDown={handleMouseDownNewPassword2}
                            className="input-eye-icon pwd-icon1"
                        >
                            {showNewPassword2 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </div>
                </div>
                <div>
                    <Typography className='font-normal-small error-message'>{confirmNewSecurityLockErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Confirm New Security Lock</Typography>
                    <div className="input-holder">
                        <input className={`profile-input ${confirmNewSecurityLockErr !== '' ? 'err-input' : ''}`} aria-label='confirm-new-lock' type={showConfirmNewPassword2 ? "text" : "password"} onChange={(e) => { onChangeLock(e, 'confirmNewSecurityLock') }} maxLength={6} value={confirmNewSecurityLock} />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="new-lock"
                            onClick={handleClickConfirmShowNewPassword2}
                            onMouseDown={handleMouseDownConfirmNewPassword2}
                            className="input-eye-icon pwd-icon1"
                        >
                            {showConfirmNewPassword2 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </div>
                </div>
            </DialogContent>
            <Divider />
            <DialogActions>
                <div className="action-holder-1" style={{ marginTop: 20, marginBottom: 0 }}>
                    <Button variant="outlined" className="primary-font-bold-6 action-btn profile-cancel-btn" onClick={onClosingDialog}>Cancel</Button>
                    <Button variant="outlined" className="primary-font-bold-6 action-btn profile-save-btn" onClick={onSaveChangeLock} disabled={isVerifyingLock}>{isVerifyingLock ? 'Saving...' : 'Save'}</Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};


UpdateSecurityLock.defaultProps = {
    isFetchingLogin: null,
    isFetchingRegister: null,
    isFetchingUserInfo: null,
};

function mapStateToProps(state) {
    return {
        isVerifyingLock: state.profile.isVerifyingLock,
        lockData: state.home.lockData,
        lockError: state.home.lockError,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            changeSecurityLock
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdateSecurityLock);

