import React, { useState, useEffect } from 'react';
import { Grid, Typography, Card } from "@mui/material";
import Box from '@mui/material/Box';
import toast, { Toaster } from 'react-hot-toast';
import MYREDFOLDER from "../../../assets/images/Nepta.png";
import { useNavigate } from "react-router-dom";

import { getTokenFCM, onMessageListener } from '../../../config/FirebaseSetup';
import { loginWithCognitoToken } from '../../../containers/Pages/Login/redux/actions';
import { connect,useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

const PushNotification = ({loginWithCognitoToken}) => {

    const [notification, setNotification] = useState({ title: '', body: '' });
    const [isTokenFound, setTokenFound] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {

        getTokenFCM(setTokenFound, loginWithCognitoToken);

        onMessageListener()

            .then((payload) => {
                console.log(payload, "payload")
                setNotification({
                    title: payload?.data?.title,
                    body: payload?.data?.bodyText,
                });

            }, [])

            .catch((err) => console.log('failed: ', err));

    });

    useEffect(() => {

        if (notification?.title) {

            notify();
            console.log("I am from notification")
            dispatch({
                type: "TRACK_STOP_SUCCESS",
                payload: {}
              })
            // navigate('/emergency-alert')
            // navigate('/home')
        }

    }, [notification]);

    const notify = () => toast(<ToastDisplay notification={notification} />, {
        style: {
            background: '#333',
            color: '#fff',
        },
    });

    return <Toaster position="bottom-right" />; // The <Toaster /> should be inside the return block.

};

function ToastDisplay({ notification }) {

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={2} >
                    <img src={MYREDFOLDER} alt="Alert" width={50} height={50} />
                </Grid>
                <Grid item xs={10} style={{ display: "flex"}}>
                    <Typography className="primary-font-bold-5" style={{ display: "flex", alignItems: "center" }}>
                        {notification?.title}
                        <span style={{ paddingLeft: "10px" }} className='font-normal-small'>{'Now'}</span>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className="font-normal-small">{notification?.body}</Typography>
                </Grid>
            </Grid>
        </>

    )

}




function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            loginWithCognitoToken,
        },
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(PushNotification);
