import React from 'react';
import Router from "./Routes/Router";
import { BrowserRouter} from "react-router-dom";
import store from './store';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import { config as i18nextConfig } from '../../translations';
import PushNotification from '../../common/components/PushNotification/PushNotification';
// import FirebaseSetup from '../../config/FirebaseSetup';

i18n.init(i18nextConfig);


const App = () => {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <Router />
          <PushNotification />
        </BrowserRouter>
      </I18nextProvider>
    </Provider>
  )
}

export default App
