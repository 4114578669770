import { Navigate } from "react-router-dom";

const UserRoute = (props) => {
        const user = window.localStorage.getItem('tokendetails');
        if (user) {
            // user is not authenticated
            return <Navigate to="/home" />;
          }else{
            return props.children
        }
}

export default UserRoute    ;