import { combineReducers,createStore,  applyMiddleware } from 'redux';

import thunk from 'redux-thunk';

import { signupReducer } from '../Pages/Registration/redux/signupReducer';
import {loginReducer} from '../Pages/Login/redux/loginReducer';
import { forgotPasswordReducer } from '../Pages/ForgotPassword/redux/forgotPasswordReducer';
import { profileReducer } from '../Pages/UserProfile/redux/profileReducer';
import { settingReducer } from '../Pages/Settings/redux/settingReducer';
import { homeReducer } from '../Pages/Home/redux/homeReducer';
import {familyReducer} from '../Pages/Info/redux/familyReducer';
import { bankReducer } from '../Pages/Info/redux/bankReducer';
import {emergencyAlertReducer} from '../../common/components/Layout/redux/emergencyAlertReducer';
import { trackMeReducer } from '../../common/components/Layout/redux/trackmeReducer';
import { propertyReducer } from '../Pages/Info/redux/propertyReducer';

const reducer = combineReducers({
    signup: signupReducer,
    login: loginReducer,
    forgotPassword: forgotPasswordReducer,
    profile: profileReducer,
    setting: settingReducer,
    home:homeReducer,
    familyInfo: familyReducer,
    bank:bankReducer,
    emergencyAlert : emergencyAlertReducer,
    trackMe : trackMeReducer,
    propertyInventory:propertyReducer
})

const store = createStore(reducer, applyMiddleware(thunk));

export default store;