import React, { useState, useEffect } from "react";
import "../../../../../../index.css";
import { Grid, Typography, Button, Radio, RadioGroup, FormControlLabel, FormControl, Select, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { connect , useDispatch} from 'react-redux';
import { bindActionCreators } from "redux";
import { updateFuneralArrangements, getFuneralArrangementDetails, getStatesList } from "../../../redux/actions";

import * as formatters from '../../../../../../utils/util';

const FuneralArrangements = ({ onContinue, onCancel,onView, selectedUser, updateFuneralArrangements,updateFuneralData, getFuneralArrangementDetails, funeralArrangementDetails, getStatesList, stateList }) => {
  const dispatch = useDispatch();
  const userDetails = window.localStorage.getItem("userInfo");
  const userDetailsObject = JSON.parse(userDetails);

  const [funeralArrangement, setFuneralArrangement] = useState(null);
  const [homeContactName, setHomeContactName] = useState(null);
  const [address, setAddress] = useState(null);
  const [zip, setZip] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [stateArray, setStateArray] = useState(null);
  const [telephone, setTelephone] = useState(null);
  const [funeralDirectorName, setFuneralDirectorName] = useState(null);
  const [contactPerson, setContactPerson] = useState(null);
  const [funeralDirectorTelephone, setFuneralDirectorTelephone] = useState(null);
  const [pallbearers, setPallbearers] = useState(null);
  const [music, setMusic] = useState(null);
  const [flowers, setFlowers] = useState(null);
  const [preArrangementLocationContract, setPreArrangementLocationContract] = useState(null);
  const [preservedServiceLocation, setPreservedServiceLocation] = useState(null);
  const [religiousServices, setReligiousServices] = useState(null);
  const [officiatingClergyman, setOfficiatingClergyman] = useState(null);
  const [officiatingClergymanPhone, setOfficiatingClergymanPhone] = useState(null);
  const [fraternalServicesContactPerson, setFraternalServicesContactPerson] = useState(null);
  const [fraternalServicesPhone, setFraternalServicesPhone] = useState(null);
  const [honoraryPallbearers, setHonoraryPallbearers] = useState(null);
  const [militaryServices, setMilitaryServices] = useState(null);
  const [fraternalServices, setFraternalServices] = useState(null);
  const [memorials, setMemorials] = useState(null);
  const [otherRequests, setOtherRequests] = useState(null);

  useEffect(()=>{
    getStatesList();
    if(selectedUser?.userId){
      getFuneralArrangementDetails(selectedUser.userId)
    }
  },[])

  useEffect(()=>{
    if(funeralArrangementDetails && funeralArrangementDetails.status === 200){
        const data = funeralArrangementDetails.data;
        setFuneralArrangement(data)
        setHomeContactName(data.funeralHome);
        setAddress(data.address);
        setZip(data.zip);
        setCity(data.city);
        setState(data.state);
        setTelephone(data.telephoneNo)
        setFuneralDirectorName(data.funeralDirector);
        setContactPerson(data.militaryContactPerson);
        setFuneralDirectorTelephone(data?.militaryTelephoneNo)
        setPallbearers(data?.pallbearers)
        setMusic(data?.music)
        setFlowers(data?.flowers)
        setPreArrangementLocationContract(data?.locationOfcontract)
        setPreservedServiceLocation(data?.locationOfServices)
        setOfficiatingClergyman(data?.clergyman)
        setOfficiatingClergymanPhone(data?.clergymanTelephoneNo)
        setFraternalServicesContactPerson(data?.fraternalContactPerson)
        setFraternalServicesPhone(data?.fraternalTelephoneNo)
        setHonoraryPallbearers(data?.pallbearersHonorary)
        setMilitaryServices(data?.isMilitary)
        setFraternalServices(data?.isFraternal)
        setMemorials(data?.memorials)
        setOtherRequests(data?.otherRequests)
    }else{
      setFuneralArrangement(null)
    }
  },[funeralArrangementDetails])

  useEffect(()=>{
    console.log("updateFuneralData",updateFuneralData)
    if(updateFuneralData && updateFuneralData.status === 200){
      dispatch({
        type: "RESET_FUNERAL_DATA",
        payload:null
      })
      onContinue();
    }
  },[updateFuneralData])

  useEffect(()=>{
    if(stateList && stateList.status === 200){
      setStateArray(Object.keys(stateList.data))
    }
  },[stateList])

  const onChangeInput = (e, type) => {
    let value =  e.target.value
    switch(type) {
      case 'homeContactName':
        setHomeContactName(value)
        break;
      case 'zip':
        setZip(value)
        break;
      case 'city':
        setCity(value)
        break;
      case 'state':
        setState(value)
        break;
      case 'telephone':
        setTelephone(value)
        break;
      case 'funeralDirectorName':
        setFuneralDirectorName(value)
        break;
      case 'contactPerson':
        setContactPerson(value)
        break;
      case 'funeralDirectorTelephone':
        setFuneralDirectorTelephone(value)
        break;
      case 'pallbearers':
        setPallbearers(value)
        break;
      case 'music':
        setMusic(value)
        break;
      case 'flowers':
        setFlowers(value)
        break;
      case 'preArrangementLocationContract':
        setPreArrangementLocationContract(value)
        break;
      case 'preservedServiceLocation':
        setPreservedServiceLocation(value)
        break;
      case 'religiousServices':
        setReligiousServices(value)
      break;
      case 'officiatingClergyman':
        setOfficiatingClergyman(value)
        break;
      case 'officiatingClergymanPhone':
        setOfficiatingClergymanPhone(value)
        break;
      case 'fraternalServicesContactPerson':
        setFraternalServicesContactPerson(value)
        break;
      case 'fraternalServicesPhone':
        setFraternalServicesPhone(value)
        break;
      case 'honoraryPallbearers':
        setHonoraryPallbearers(value)
        break;
      case 'memorials':
        setMemorials(value)
        break;
      case 'militaryServices':
        setMilitaryServices(value)
        const isMilitaryService = e.target.value === 'true'; 
        setMilitaryServices(isMilitaryService)
        break;
      case 'fraternalServices':
        const isFraternalService = e.target.value === 'true'; 
        setFraternalServices(isFraternalService)
        break;
      case 'otherRequests':
        setOtherRequests(value)
        break;
      default:
    }
  }

  const onSave = () => {
    const payload = {
      "id": null,
      "userId": selectedUser?.userId,
      "funeralHome": homeContactName,
      "locationOfcontract": preArrangementLocationContract,
      "locationOfServices": preservedServiceLocation,
      "telephoneNo": telephone,
      "address": address,
      "zip": zip,
      "city": city,
      "stateCode": state,
      "clergyman": officiatingClergyman,
      "clergymanTelephoneNo": officiatingClergymanPhone,
      "funeralDirector": funeralDirectorName,
      "isMilitary": militaryServices,
      "militaryContactPerson": contactPerson,
      "militaryTelephoneNo": funeralDirectorTelephone,
      "isFraternal": fraternalServices,
      "fraternalContactPerson": fraternalServicesContactPerson,
      "fraternalTelephoneNo": fraternalServicesPhone,
      "pallbearers": pallbearers,
      "pallbearersHonorary": honoraryPallbearers,
      "music": music,
      "memorials": memorials,
      "flowers": flowers,
      "otherRequests": otherRequests || "NIL",
      "createdBy": selectedUser?.userId,
      "updatedBy":selectedUser?.userId
    }
    if(funeralArrangement){
      payload.id = funeralArrangement.id;
      payload.createdBy = funeralArrangement.createdBy
    }
    updateFuneralArrangements(payload)
  };
  
  return (
    <Grid container spacing={2} sx={{margin:0, padding: 0,width:"100% !important"}}>
      <Grid
       
        xs={12}
        md={12}
        style={{paddingTop: 0}}
        className="accordion-holder"
      >
        <Grid container spacing={2} className="h500" sx={{width: "100% !important",margin:0}}>
                    <Grid item xs={12} md={6} sx={{pl:onView?"0px !important":"0px",pt:onView?"0px !important":"16px"}} className="pl-0">
                        <div>
                            <Typography className='font-normal-small profile-input-label'>Funeral Home to Contact (Name)</Typography>
                            <input className={`profile-input`} aria-label='homeContactName' onChange={(e)=> {onChangeInput(e, 'homeContactName')}}  maxLength={10} value={homeContactName}/>
                        </div>
                        <div>
                            {/* <Typography className='font-normal-small error-message'>{phoneNumberErr}</Typography> */}
                            <Typography className='font-normal-small profile-input-label'>Telephone</Typography>
                            <input className={`profile-input`} aria-label='phone number' onChange={(e)=> {onChangeInput(e, 'telephone')}}  maxLength={10} value={formatters.formatPhoneNumber(telephone)}/>
                        </div>
                        <div>
                            <Typography className='font-normal-small profile-input-label'>Address</Typography>
                            <input className={`profile-input`} aria-label='address' onChange={(e)=> {onChangeInput(e, 'address')}}  maxLength={10} value={address}/>
                        </div>
                        <div className="flex-boxes" style={{marginLeft: 0}}> 
                            <div className="full-width">
                                <Typography className='font-normal-small profile-input-label'>Zip</Typography>
                                <input className={`profile-input`}  aria-label='zip'  onChange={(e)=> {onChangeInput(e, 'zip')}} value={zip}/>
                            </div>
                            <div className="full-width">
                                <Typography className='font-normal-small profile-input-label'>City</Typography>
                                <input className={`profile-input`}  aria-label='city'  onChange={(e)=> {onChangeInput(e, 'city')}} value={city}/>
                            </div>
                            <div className="full-width">
                                <Typography className='font-normal-small profile-input-label'>State</Typography>
                                <Select className="profile-input state-input font-normal-small" value={state}  onChange={(e)=> {onChangeInput(e, 'state')}}>
                                    {stateArray?.map((key) => (
                                      <MenuItem key={key} value={key}>
                                        {key}
                                      </MenuItem>
                                    ))}
                                  </Select>
                            </div>
                        </div>
                        <div> 
                            <Typography className='font-normal-small profile-input-label'>Name of Funeral Director</Typography>
                            <input className={`profile-input`}  aria-label='funeralDirectorName'  onChange={(e)=> {onChangeInput(e, 'funeralDirectorName')}} value={funeralDirectorName}/>
                        </div>
                        <div className="flex-boxes" style={{marginLeft: 0, marginBottom: 25}}> 
                          <FormControl style={{width: '100%'}}>
                          <Typography className='font-normal-small profile-input-label'>Military Services</Typography>
                            <RadioGroup
                              row
                              aria-labelledby="military-service"
                              name="military-service"
                              sx={{gap: onView ?"100px":"200px", justifyContent:onView?"":"" ,pl:onView?"0px":""}}
                              value={militaryServices ? 'true' : 'false'} 
                              onChange={(e)=> {onChangeInput(e, 'militaryServices')}}
                            >
                              <FormControlLabel style={{margin: 0}} className="radio-input" value="true" control={<Radio />} label="Yes" />
                              <FormControlLabel style={{margin: 0}}  className="radio-input" value="false" control={<Radio />} label="No" />
                            </RadioGroup>
                            </FormControl>
                        </div>
                        <div className="flex-boxes" style={{marginLeft: 0}}> 
                            <div className="full-width">
                                <Typography className='font-normal-small profile-input-label'>Contact Person   </Typography>
                                <input className={`profile-input`}  aria-label='contactPerson'  onChange={(e)=> {onChangeInput(e, 'contactPerson')}} value={contactPerson}/>
                            </div>
                            <div className="full-width">
                                <Typography className='font-normal-small profile-input-label'>Telephone Number</Typography>
                                <input className={`profile-input`}  aria-label='funeralDirectorTelephone'  onChange={(e)=> {onChangeInput(e, 'militeryTelephone')}} value={funeralDirectorTelephone}/>
                            </div>
                        </div>
                        <div> 
                            <Typography className='font-normal-small profile-input-label'>Pallbearers</Typography>
                            <input className={`profile-input`}  aria-label='Pallbearers'  onChange={(e)=> {onChangeInput(e, 'pallbearers')}} value={pallbearers}/>
                        </div>
                        <div> 
                            <Typography className='font-normal-small profile-input-label'>Music</Typography>
                            <input className={`profile-input`}  aria-label='music'  onChange={(e)=> {onChangeInput(e, 'music')}} value={music}/>
                        </div>
                        <div> 
                            <Typography className='font-normal-small profile-input-label'>Flowers</Typography>
                            <input className={`profile-input`}  aria-label='flowers'  onChange={(e)=> {onChangeInput(e, 'flowers')}} value={flowers}/>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{pl:onView?"0px !important":"30px",pt:onView?"0px !important":"16px"}}>
                        <div> 
                            <Typography className='font-normal-small profile-input-label'>Location of pre-arrangement contract</Typography>
                            <input className={`profile-input`}  aria-label='location of pre arrangement contract'  onChange={(e)=> {onChangeInput(e, 'preArrangementLocationContract')}} value={preArrangementLocationContract}/>
                        </div>
                        <div> 
                            <Typography className='font-normal-small profile-input-label'>Location(s) Preferred Services</Typography>
                            <input className={`profile-input`}  aria-label='preserved services location'  onChange={(e)=> {onChangeInput(e, 'preservedServiceLocation')}} value={preservedServiceLocation}/>
                        </div>
                        <div>
                            <Typography className='font-normal-small profile-input-label'>Religious Services </Typography>
                            <input className={`profile-input`}  aria-label='Religious Services'  onChange={(e)=> {onChangeInput(e, 'religiousServices')}} value={religiousServices}/>
                        </div>
                        <div>
                            <Typography className='font-normal-small profile-input-label'>Officiating Clergyman </Typography>
                            <input className={`profile-input`}  aria-label='Officiating Clergyman'  onChange={(e)=> {onChangeInput(e, 'officiatingClergyman')}} value={officiatingClergyman}/>
                        </div>
                        <div>
                            <Typography className='font-normal-small profile-input-label'>Officiating Clergyman (Telephone)</Typography>
                            <input className={`profile-input`}  aria-label='Officiating Clergyman Telephone'  onChange={(e)=> {onChangeInput(e, 'officiatingClergymanPhone')}} value={officiatingClergymanPhone}/>
                        </div>
                        <div className="flex-boxes" style={{marginLeft: 0, marginBottom: 25}}> 
                          <FormControl style={{width: '100%'}}>
                          <Typography className='font-normal-small profile-input-label'>Fraternal Services</Typography>
                            <RadioGroup
                              row
                              aria-labelledby="fraternal-service"
                              name="fraternal-service"
                              sx={{gap: onView ?"100px":"200px", justifyContent:onView?"": '',pl:onView?"0px":""}}
                              value={fraternalServices ? 'true' : 'false'} 
                              onChange={(e)=> {onChangeInput(e, 'fraternalServices')}}
                            >
                              <FormControlLabel style={{margin: 0}} className="radio-input" value="true" control={<Radio />} label="Yes" />
                              <FormControlLabel style={{margin: 0}}  className="radio-input" value="false" control={<Radio />} label="No" />
                            </RadioGroup>
                            </FormControl>
                        </div>
                        <div className="flex-boxes"> 
                            <div className="full-width">
                                <Typography className='font-normal-small profile-input-label'>Contact Person   </Typography>
                                <input className={`profile-input`}  aria-label='fraternalServicesContactPerson'  onChange={(e)=> {onChangeInput(e, 'fraternalServicesContactPerson')}} value={fraternalServicesContactPerson}/>
                            </div>
                            <div className="full-width">
                                <Typography className='font-normal-small profile-input-label'>Telephone Number</Typography>
                                <input className={`profile-input`}  aria-label='fraternalServicesPhone'  onChange={(e)=> {onChangeInput(e, 'fraternalServicesPhone')}} value={fraternalServicesPhone}/>
                            </div>
                        </div>
                        <div>
                            <Typography className='font-normal-small profile-input-label'>Honorary Pallbearers </Typography>
                            <input className={`profile-input`}  aria-label='Honorary Pallbearers'  onChange={(e)=> {onChangeInput(e, 'honoraryPallbearers')}} value={honoraryPallbearers}/>
                        </div>
                        <div>
                            <Typography className='font-normal-small profile-input-label'>Memorials </Typography>
                            <input className={`profile-input`}  aria-label='Memorials'  onChange={(e)=> {onChangeInput(e, 'memorials')}} value={memorials}/>
                        </div>
                        <div>
                            <Typography className='font-normal-small profile-input-label'>Other Requests </Typography>
                            <input className={`profile-input`}  aria-label='Other Requests'  onChange={(e)=> {onChangeInput(e, 'otherRequests')}} value={otherRequests}/>
                        </div>
                    </Grid>
                </Grid>
       
      <div className="action-holder action-bg">
        <Button variant="outlined" className="primary-font-actionbtn action-btn profile-cancel-btn" onClick={onCancel}>Back</Button>
        <Button variant="outlined" className="primary-font-actionbtn action-btn profile-save-btn" onClick={onSave}>Save & Continue</Button>
    </div>
      </Grid>
    </Grid>
  );
};

FuneralArrangements.defaultProps = {
};

function mapStateToProps(state) {
  return {
    stateList: state.familyInfo.stateList,
    funeralArrangementDetails: state.familyInfo.funeralArrangementDetails,
    updateFuneralData: state.familyInfo.updateFuneralData
    
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getFuneralArrangementDetails,
      updateFuneralArrangements,
      getStatesList
    },
    dispatch
  ); 
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FuneralArrangements);
