import React, { useDebugValue, useEffect, useState, useMemo } from 'react'
import { Box, Grid, Typography } from "@mui/material";
import PropertyInventory from './PropertyInventory.js';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAllPropertyList } from '../../../redux/actions.js';
import "../styles/myProperty.css"

const PropertInventoryList = ({ goback = true , getAllPropertyList,propertyInventoryList, deletePropertyInventory}) => {
    const [securityLockDialogOpen, setSecurityLockDialogOpen] = useState(true);
    const [propertyData, setPropertyData] = useState([])

    useEffect(()=>{
        if(propertyInventoryList && propertyInventoryList.status === 200){
            setPropertyData(propertyInventoryList.data)
        }else if(propertyInventoryList && propertyInventoryList.status === 404){
            setPropertyData([])
        }
    },[propertyInventoryList])
    const initialValue = 0;
    const sumWithInitial = useMemo(()=>{
        const grandTotal = propertyData?.reduce(
        (accumulator, currentValue) => {console.log((currentValue?.propertyInfo?.quantity , currentValue.propertyInfo?.unitCost ) ); return ((currentValue?.propertyInfo?.quantity && currentValue.propertyInfo?.unitCost ) ? accumulator + (currentValue.propertyInfo?.quantity * currentValue.propertyInfo?.unitCost) : accumulator )},
        initialValue,
      );
      return grandTotal.toLocaleString();
        },[propertyData])


        // console.log(sumWithInitial,"sumWithInitial")
  
    useEffect(()=>{
        getAllPropertyList()
    },[])
return (

    <Grid container spacing={2} sx={{width: '100%', margin: 0}}>
      {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
   
      
      {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
    
      <Grid 
      container
      className='property-container-top'
      
        xs={12}
        md={12}
        lg={12}
        // gap={4}
        // style={{
        //   display: "grid",
        //   gridAutoRows: "auto",
        //   gridGap: "1rem",
        //   gridTemplateColumns: "repeat(auto-fill, 338px)",
        //   justifyContent: "space-between",
        //   padding: "40px 10px 40px 20px"
        // }}
      >
      <Grid  className='my-property-container'  > 
      
      <PropertyInventory InventoryDetails={propertyData} />
   
     
      
      {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
      
    {propertyData && propertyData.length > 0 && (
        
      
        <Grid container sx={{ backgroundColor:'rgba(255,53,53,.5)',textAlign:"center",height:'50px',borderRadius:'10px',marginTop:'30px'}}>
            <Grid  sx={{marginTop:'15px',mx:"auto"}}> 
            <Typography className='primary-font-bold-7'>{`Grand Total: $ ${sumWithInitial}`}</Typography>
            </Grid>
        </Grid>
      
     
    )}
     </Grid>  
    </Grid>
   
  
    </Grid>
)
}


function mapStateToProps(state){
    return{
        propertyInventoryList : state.propertyInventory.propertyInventoryList,
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        getAllPropertyList,
      },
      dispatch
    );
  }


export default connect(mapStateToProps, mapDispatchToProps)(PropertInventoryList);