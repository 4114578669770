import React from "react";
import ContentWrapper from "../../../../../common/components/ContentWrapper";
import NeedHelp from "./NeedHelpCard";

const NeedHelpCards = () => {
    return(
        <ContentWrapper title={"I Need Help"} goback={true} gobackUrl={"/need-help"} image = {false} img={false} contenview={<NeedHelp/>}/>
    )
}

export default NeedHelpCards;