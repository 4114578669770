import {api} from '../../../../utils/apiClient';
import { Auth } from 'aws-amplify';

export const setUserPassword = (payload) => async(dispatch) => {
    //const url = `v1/user/password/reset`;
    const url = 'v1/user/updateStatusVerified';
   try{
       dispatch({
           type: 'PASSWORD_UPDATE_REQUEST',
           payload: null
       })
       
    const user = await Auth.currentAuthenticatedUser();
    const data = await Auth.changePassword(user, payload.oldPassword, payload.password);
       if(data === 'SUCCESS'){
            const response = await api.putApi(url,payload);
                return dispatch({
                    type: 'PASSWORD_UPDATE_SUCCESS',
                    payload: response
                });
       }else{
        return dispatch({
            type: 'PASSWORD_UPDATE_FAILURE',
            payload: data?.message 
        });
       }
  
   }catch(e){
       return dispatch({
           type: 'PASSWORD_UPDATE_FAILURE',
           payload: e
       });
   }
}

export const setSecurityPin = (payload) => async(dispatch) => {
    const url = `v1/user/securityPin/reset`;
   try{
       dispatch({
           type: 'SECURITY_PIN_UPDATE_REQUEST',
           payload: null
       })
       const response =await api.putApi(url,payload);
       return dispatch({
           type: 'SECURITY_PIN_UPDATE_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'SECURITY_PIN_UPDATE_FAILURE',
           payload: e
       });
   }
}
export const setSecurityLock = (payload) => async(dispatch) => {
    const url = `v1/user/securityLock/reset`;
    
   try{
        dispatch({
            type:"RESET_REGISTRATION",
            payload: null
        })
       dispatch({
           type: 'SECURITY_LOCK_UPDATE_REQUEST',
           payload: null
       })
       const response =await api.putApi(url,payload);
       return dispatch({
           type: 'SECURITY_LOCK_UPDATE_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'SECURITY_LOCK_UPDATE_FAILURE',
           payload: e
       });
   }
}

export const sendOtp = (payload) =>  async(dispatch) =>{
    const url = `v1/user/securityLock/sendOtp`;

    try{
        const response = await api.postApi(url,payload);
        return dispatch({
            type: 'SECURITY_LOCK_CHANGE_OTP_SUCCESS',
            payload: response.data
        });
    }catch(e){
        return dispatch({
            type: 'SECURITY_LOCK_CHANGE_OTP_FAILURE',
            payload: e
        });
    }
} 

export const VerifyOtp = (payload) => async(dispatch) =>{
    const url = 'v1/user/securityLock/verifyOtp';
    try{
        const response = await api.postApi(url,payload);
        return dispatch({
            type:'SECURITY_LOCK_VERIFY_OTP_SUCCESS',
            payload:response.data
        });
    }catch(e){
        return dispatch({
            type:'SECURITY_LOCK_VERIFY_OTP_FAILURE',
            payload: e 
        });
    }
}

export const sendSecurityPinOtp = (payload) => async(dispatch) =>{
    const url = 'v1/user/securityPin/sendOtp';
    try{
        const response = await api.postApi(url,payload);
        return dispatch({
            type:'SECURITY_PIN_CHANGE_OTP_SUCCESS',
            payload:response.data
        });
    }
    catch(e){
        return dispatch({
            type:'SECURITY_PIN_CHANGE_OTP_FAILURE',
            payload:e
        });
    }
}

export const isVerifySecurityPinOtp = (payload) => async(dispatch) =>{
    const url = 'v1/user/securityPin/verifyOtp';
    try{
        const response = await api.postApi(url,payload);
        return dispatch({
            type:'SECURITY_PIN_VERIFY_OTP_SUCCESS',
            payload:response.data
        });
    }
    catch(e){
        return dispatch({
            type:'SECURITY_PIN_VERIFY_OTP_FAILURE',
            payload:e
        });
    }
}