/* eslint-disable camelcase */
import common_fr from './fr/common.json';
import common_en from './en/common.json';
import common_sp from './sp/common.json';

import errors_fr from './fr/errors.json';
import errors_en from './en/errors.json';
import errors_sp from './sp/errors.json';

const resources =  {
  en: {
    common: common_en,
    errors: errors_en,
  },
  fr: {
    common: common_fr,
    errors: errors_fr,
  },
  sp: {
    common: common_sp,
    errors: errors_sp,
  },
};

export {
  resources
}
