import React from 'react'
import ContentWrapper from '../../../../../common/components/ContentWrapper';
import MyFamilyMembers from './MyFamilyMembers';
import { useTranslation } from 'react-i18next';

const FamilyMembersComponent = () => {
  const { t } = useTranslation('common');

  return (
    <ContentWrapper title={t("My Family Members")} gobackUrl={'/settings'} goback={true} contenview={<MyFamilyMembers />} />
  )
}

export default FamilyMembersComponent;