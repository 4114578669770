import {appConfigApi} from './api/appConfigApi';
import {getMethod} from './api/getApi';
import {postMethod} from './api/postApi';
import {putMethod} from './api/putApi';
import {deleteMethod} from './api/deleteapi';
import {postWithoutTokenMethod} from './api/postNoTokenapi';

export const api = {
  appConfig: appConfigApi,
  getApi: getMethod,
  postApi: postMethod,
  putApi: putMethod,
  deleteapi: deleteMethod,
  postNoToken: postWithoutTokenMethod,
};
