import React from "react";
import { Dialog,DialogContent, Typography } from "@mui/material";


const AlertMessage = ({ isOpen, onClose, message }) => {
    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        className="small-dialog dialog-box"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogContent className="content-dialog">
          <Typography className="primary-font-normal-4">
            {message}
          </Typography>
        </DialogContent>
      </Dialog>
    );
  };
  
  export default AlertMessage;