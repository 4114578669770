import React from "react";
import {Grid, Typography, Card} from "@mui/material";
import {styled} from "@mui/material/styles";

const StyledCard = styled(Card)({
    position: "relative",
    width: 500,
    height: 40,
    border: "1px solid #D8D8D8",
    borderRadius: 5,
    boxShadow:"none",
    "&:hover": {
        border: "1px solid #FF3535",
        boxShadow: "0px 2px 8px #00000033",
    },
});

const TrackMyFamily = () => {
    return(
       <Grid container spacing={2} style={{width:"100%", margin: 0}}>
          {/* <Grid item xs={12} md={12} lg={12}> */}
          <Grid item xs={12} md={6} lg={6}>
            <textarea style={{height:"500px",width:"100%"}}/>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <StyledCard style={{display:"flex", justifyContent:"space-between",alignItems:"center",paddingLeft:"10px",paddingRight:"10px"}}>
                <Typography className="primary-font-bold-5">Jhon Doe</Typography>
                <Typography className="primary-font-bold-5">Son</Typography>
            </StyledCard>
          </Grid>
          {/* </Grid> */}
         

       </Grid>
    )

}

export default TrackMyFamily;