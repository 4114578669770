import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Grid, Typography, Button, Select, MenuItem } from "@mui/material";
import { getStatesList } from "../../Info/redux/actions";
// import { getStatesList } from '../redux/actions';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const BillingAddresspage = ({ getStatesList, stateList }) => {
  const navigate = useNavigate();
  const [address, setAddress] = useState(null);
  const [zip, setZip] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [firstname, setfirstname] = useState(null);
  const [lastname, setlastname] = useState(null);
  const [stateArray, setStateArray] = useState();
  const [companyName, setcompanyName] = useState(null);
  const [firstNameError, setFirstNameError] = useState();
  const [lastNameError, setLastNameError] = useState();
  const [companyNameError, setCompanyNameError] = useState();
  const [addressError, setAddressError] = useState();
  const [cityError, setcityError] = useState();
  const [zipError, setZipError] = useState();

  useEffect(() => {
    getStatesList();
  }, []);

  useEffect(() => {
    if (stateList && stateList?.status === 200) {
      setStateArray(Object.keys(stateList?.data));
    }
  }, [stateList]);
  useEffect(() => {
    if (stateList && stateList?.status === 200) {
      const data = stateList?.data;

      setfirstname(data.firstname);
      setlastname(data.lastname);
      setcompanyName(data.companyname);
      setAddress(data.address);
      setZip(data.zip);
      setCity(data.city);
      setState(data.stateCode);
    }
  }, [getStatesList]);
  const onChangeInput = (e, type) => {
    let value = e.target.value;

    console.log(value);
    switch (type) {
      case "address":
        setAddress(value);
        break;
      case "zip":
        setZip(value);
        break;
      case "city":
        setCity(value);
        break;
      case "state":
        setState(value);
        break;
      case "firstname":
        setfirstname(value);
        break;
      case "lastname":
        setlastname(value);
        break;
      case "companyName":
        setcompanyName(value);
        break;
      default:
    }
  };

  const handleSave = () =>{
    //console.log(firstname, lastname)
    if(!firstname){
      setFirstNameError("This must be filled");
    } else {
      setFirstNameError("");
    }  
    
    if(!lastname){
      setLastNameError("This must be filled");
    } else {
      setLastNameError("");
    } 

    if(!companyName){
      setCompanyNameError("This must be filled");
    } else {
      setCompanyNameError("");
    }

    if(!address){
      setAddressError("This must be filled");
    }else {
      setAddressError("")
    }

    if(!city){
      setcityError("This must be filled");
    }else {
      setcityError("")
    }

    if(!zip){
      setZipError("This must be filled");
    }else {
      setZipError("")
    }
    const payload = {
      FirstName : firstname,
      LastName : lastname,
      CompanyName : companyName,
      City : city,
      ZipCode: zip,
      State: state
    }

    console.log(payload);
  }


  return (
    <Grid container spacing={2} style={{ width: "100%", margin: 0 }}>
      <Grid item xs={1} md={1} lg={1}></Grid>
      <Grid
        item
        xs={10}
        md={10}
        lg={10}
        className="box-container"
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <div className="box-header-2">
          <Typography className="primary-font-bold-2-heading">
            Billing Address
          </Typography>
        </div>
        <Grid container spacing={2} style={{ margin: 0, width: "100%" }}>
          <Grid item xs={12} md={6} lg={6} sx={{ padding: 0 }}>
            <div style={{ padding: 20 }}>
              <div className="flex-boxes" style={{ marginLeft: 0 }}>
                <div className="full-width">
                  <Typography className="font-normal-small error-message">{firstNameError}</Typography>
                  <Typography className="font-normal-small profile-input-label">
                    First Name<span style={{color:"red"}}>*</span>
                  </Typography>
                  <input name="firstname" id="firstname" className={`profile-input ${!firstNameError == '' ? 'err-input' : ''}`} onChange={(e)=>{onChangeInput(e, "firstname");}} value={firstname}/>
                </div>
                <div className="full-width">
                  <Typography className="font-normal-small error-message">{lastNameError}</Typography>
                  <Typography className="font-normal-small profile-input-label">
                    Last Name<span style={{color:"red"}}>*</span>
                  </Typography>
                  <input name="lastName" id="lastName" className={`profile-input ${!lastNameError =='' ? 'err-input' : ''}`} onChange={(e)=>{onChangeInput(e, "lastname");}} value={lastname} />
                </div>
              </div>{" "}
              <div>
                <Typography className="font-normal-small error-message">{companyNameError}</Typography>
                <Typography className="font-normal-small profile-input-label">
                  Company Name<span style={{color:"red"}}>*</span>
                </Typography>
                {/* {error && (<Typography className="error-message">{error}</Typography>)} */}
                <input id="companyname" name="comapanyname" className={`profile-input ${!companyNameError =='' ? 'err-input' : ''}`} onChange={(e)=>{onChangeInput(e, "companyName");}} value={companyName} />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sx={{ padding: 0, marginLeft: 0 }}>
            <div style={{ padding: 20 }}>
              <div>
                <Typography className="font-normal-small error-message">{addressError}</Typography>
                <Typography className="font-normal-small profile-input-label">
                  Address<span style={{color:"red"}}>*</span>
                </Typography>
                <input id="adderess" name="address" className={`profile-input ${!addressError == '' ? 'err-input' : ''}`} onChange={(e) => {onChangeInput(e, "address");}} value={address} />
              </div>
              <div className="flex-boxes">
                <div className="full-width">
                  <Typography className="font-normal-small error-message">{zipError}</Typography>
                  <Typography className="font-normal-small profile-input-label">
                    Zip{" "}<span style={{color:"red"}}>*</span>
                  </Typography>
                  <input name="zip" id="zip" className={`profile-input ${!zipError == '' ? 'err-input' : ''}`} onChange={(e) => {onChangeInput(e, "zip")}} value={zip} />
                </div>
                <div className="full-width">
                  <Typography className="font-normal-small error-message">{cityError}</Typography>
                  <Typography className="font-normal-small profile-input-label">
                    City<span style={{color:"red"}}>*</span>
                  </Typography>
                  <input name="city" id="city" className={`profile-input ${!cityError == '' ? 'err-input' : ''}`} onChange={(e) => {onChangeInput(e, "city")}} vlaue={city}/>
                </div>
                <div className="full-width">
                  <Typography className="font-normal-small profile-input-label">
                    State<span style={{color:"red"}}>*</span>
                  </Typography>
                  <Select
                    className="profile-input state-input font-normal-small"
                    value={state}
                    onChange={(e) => {
                      onChangeInput(e, "state");
                    }}
                  >
                    {stateArray?.map((key) => (
                      <MenuItem key={key} value={key}>
                        {key}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid
          className="box-container"
          style={{
            justifyContent: "center",
            alignItems: "center",
            gap: 20,
            boxShadow: "none",
          }}
        >
          <Button
            variant="outlined"
            className="primary-font-bold-5 action-btn profile-cancel-btn"
            onClick={() => handleSave()}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            className="primary-font-bold-5 action-btn profile-save-btn"
            onClick={() => {
              navigate("/my-subscription");
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

function mapStateToProps(state) {
  return {
    stateList: state.familyInfo.stateList,
    // stateList:state.profile.stateList
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getStatesList,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(BillingAddresspage);
