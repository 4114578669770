import React, { useState, useEffect } from "react";
import { Grid, Box, Divider } from "@mui/material";
import ContentWrapper from "../../../../ContentWrapper";
import CustomStepper from "../../../../CustomStepper";
import { useNavigate } from "react-router-dom";
import Addfamilymember from "./Addfamilymember";
import SummaryAlert from "./SummaryAlert";
import DisasterList from "../../../../../../containers/Pages/Settings/components/DisasterManagement/DisasterList";
import MeetingLocationsList from "../../../../../../containers/Pages/Settings/components/MeetingLocations/MeetingLocations";
import ViewPredefinedMessages from "../../../../../../containers/Pages/Settings/components/PreDefinedMessages/ViewPredefinedMessages";
import { bindActionCreators } from "redux";
import {
  getActiveEmergencyAlert,
  getActiveEmergencyAlertData,
} from "../../../redux/actions";
import { connect, useDispatch } from "react-redux";
import "./styles/activateEmergency.css";

const MainLayout = ({
  emergencyList,
  emergencyListDataObject,
  getActiveEmergencyAlertData,
  addEmergencyAlert
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [skipped, setSkipped] = useState(new Set());
  const [summaryPage, setSummaryPage] = useState(false);
  const [isActiveEmergency, setActiveEmergency] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [alertActivated, setAlertActivated] = useState(false);
  const [alertData, setAlertData] = useState(null);

  useEffect(() => {
    if (emergencyList && emergencyList.status === 200) {
      if (emergencyList.data && emergencyList.data.isActive === "true") {
        setActiveEmergency(true);
        setSummaryPage(true);
        setAlertActivated(true);
        getActiveEmergencyAlertData();
      }
    }
  }, [emergencyList]);

  useEffect(() => {
    if (emergencyListDataObject && emergencyListDataObject.status === 200) {
      setAlertData(emergencyListDataObject.data);
    }
  }, [emergencyListDataObject]);




  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    window.scrollTo(0, 0);
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep === 3) {
      setSummaryPage(true);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
      setSummaryPage(false);
    }
  };

  const handleBack = () => {
    console.log("handleBack",activeStep)

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const onCancelClick = () => {
    dispatch({
      type: "ADD_EMERGENCY_ALERT",
      payload: null,
    });
    navigate(-1); // Navigate back one step in the history
  };

  const onBackOrCancelClick = () => {
    if (activeStep === 0) {
      onCancelClick();
    } else {
      window.scrollTo(0, 0);
      handleBack();
    }
  };

  const steps = [
    {
      label: "Disaster Type",
      component: (
        <DisasterList
          onBackOrCancelClick={onBackOrCancelClick}
          handleNext={handleNext}
          Crud={true}
          emergencyAlert={true}
        />
      ),
    },
    {
      label: "Select Message",
      component: (
        <ViewPredefinedMessages
          onBackOrCancelClick={onBackOrCancelClick}
          handleNext={handleNext}
          Crud={true}
          emergencyAlert={true}
        />
      ),
    },
    {
      label: "Add Family Members",
      component: (
        <Addfamilymember
          onBackOrCancelClick={onBackOrCancelClick}
          handleNext={handleNext}
          emergencyAlert={true}
        />
      ),
    },
    {
      label: "Meeting Location",
      component: (
        <MeetingLocationsList
          onBackOrCancelClick={onBackOrCancelClick}
          handleNext={handleNext}
          Crud={true}
          emergencyAlert={true}
        />
      ),
    },
  ];
  return (
    <Grid container spacing={2} style={{ margin: 0, width: "100%" }}>
      {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
      <div className="emergency-alert-container">
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        className="box-container emergency-alert-form"
        style={{ padding: 0 }}
      >
        {summaryPage ? (
          <SummaryAlert
            onBackOrCancelClick={() => {
              setActiveStep(3);
              setSummaryPage(false);
            }}
            onEditDisaster={(data) => {
              setActiveStep(0);
              setSummaryPage(false);
              data["isEditPage"] = true;
              dispatch({
                type: "ADD_EMERGENCY_ALERT",
                payload: data,
              })
            }}
            alertActivatedAPI={alertActivated}
            alertData={addEmergencyAlert ? addEmergencyAlert : alertData}
            previousData={alertData}
          />
        ) : (
          <>
            {" "}
            <Box sx={{ width: "100%" }}>
              <CustomStepper steps={steps} activeStep={activeStep} />
            </Box>
            <Divider />{" "}
          </>
        )}
      </Grid>
      </div>
      {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
    </Grid>
  );
};

MainLayout.defaultProps = {
  isFetching: null,
  error: null,
  familyMembers: [],
};

const EmergencyAlert = ({
  getActiveEmergencyAlert,
  emergencyListData,
  getActiveEmergencyAlertData,
  emergencyListDataObject,
  addEmergencyAlert
}) => {
  const [emergencyData, setEmergencyData] = useState(null);
  useEffect(() => {
    getActiveEmergencyAlert();
  }, []);
  useEffect(() => {
    if (emergencyListData && emergencyListData.status === 200) {
      setEmergencyData(emergencyListData);
    }
  }, [emergencyListData]);
  return (
    <ContentWrapper
      title={"ACTIVATE EMERGENCY ALERT"}
      gobackUrl={"/home"}
      goback={true}
      img={false}
      contenview={
        <MainLayout
          emergencyList={emergencyData}
          getActiveEmergencyAlertData={getActiveEmergencyAlertData}
          emergencyListDataObject={emergencyListDataObject}
          addEmergencyAlert={addEmergencyAlert}
        />
      }
    />
  );
};

function mapStateToProps(state) {
  return {
    addEmergencyAlert: state.emergencyAlert.addEmergencyAlert,
    emergencyListData: state.emergencyAlert.emergencyList,
    emergencyListDataObject: state.emergencyAlert.emergencyListData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getActiveEmergencyAlert,
      getActiveEmergencyAlertData,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(EmergencyAlert);
