import React, { useEffect, useState } from "react";
import { Button, Box, Typography, Divider } from "@mui/material";
import { Amplify, Auth, Hub } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
//Internal Files
import MyRedFolderLogo from "../../../../assets/images/my-red-folder.svg";
import NEPTALOGO from "../../../../assets/images/Nepta.png";
import Twitter from "../../../../assets/icons/twitter.svg";
import FaceBook from "../../../../assets/icons/facebook-f.svg";
import Insta from "../../../../assets/icons/instagram-i.svg";
import Google from "../../../../assets/icons/google-g.svg";
import Microsoft from "../../../../assets/icons/microsoft.svg";
import User from "../../../../assets/icons/user.svg";
import Password from "../../../../assets/icons/password.svg";
import ErrorIcon from "../../../../assets/icons/error-icon.svg";
import ErrorClose from "../../../../assets/icons/error-close.svg";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import HomeComponent from "../../Home/components/HomeComponent";
import "../styles/login.css";
import {
  signInAccount,
  loginWithCognitoToken,
  loginWithSocialMedia,
  resetLogin,
  homeScreenAPI
} from "../redux/actions";
import Loading from "../../common/Loading";
import awsConfig from "../../../../aws-exports";
import constants from "../../../../config/constants";
import PaymentFailed from "../../UserProfile/components/PaymentFailed";
const isLocalhost = constants.COGNITO_ENVIRONMENT;
const redirectSigninUrl =
  isLocalhost === "LOCAL"
    ? constants.CONGNITO_LOCAL_SIGNIN
    : isLocalhost === "DEV"
    ? constants.CONGNITO_DEV_SIGNIN
    : constants.CONGNITO_STAGING_SIGNIN;
const redirectSignOutUrl =
  isLocalhost === "LOCAL"
    ? constants.CONGNITO_LOCAL_SIGNOUT
    : isLocalhost === "DEV"
    ? constants.CONGNITO_DEV_SIGNOUT
    : constants.CONGNITO_STAGING_SIGNOUT;
const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: redirectSigninUrl,
    redirectSignOut: redirectSignOutUrl,
  },
};
Amplify.configure(updatedAwsConfig);

const IndexComponent = (props) => {
  const {
    signInAccount,
    isFetchingLogin,
    loginWithCognitoToken,
    loginData,
    errorData,
    userInfoData,
    isFetchingUserInfo,
    loginWithSocialMedia,
    resetLogin,
    verificationData,
    homeScreenAPI,
    homeAPI
  } = props;
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginErr, setLoginErr] = useState("");
  // const[payloaddata,setpayloaddata]=useState("")
const [socialMediaRedirect, setSocialMediaReirect] = useState(props?.socialMediaRedirect ? props?.socialMediaRedirect : false )
  
useEffect(() => {
    Hub.listen("auth", async (data) => {
      const { payload } = data;
      console.log("A new auth event has happened: ", data);
      if (payload.event === "cognitoHostedUI") {
        getUserDataUsingSocialSignin({ payload: payload.data });
      }
      if (payload.event === "signOut") {
        console.log("a user has signed out!");
      }
    });
  }, []);

  useEffect(() => {
    if (errorData) {
      if(errorData.message && errorData.message.length > 100){
        setLoginErr("Something Went Wrong");
      }
      setLoginErr(errorData?.message || "Something Went Wrong");
      // }else  if(errorData?.message === "User is not confirmed."){
      //   window.localStorage.setItem('registeremail',userName)
      //   navigate("/account-verification")
      // }else{
      //   setLoginErr(errorData?.message || "Something Went Wrong");
      // }
    }
    if (loginData && loginData.signInUserSession) {
      getUserData({ payload: loginData });
    }
  }, [loginData?.attributes?.email, errorData]);

  useEffect(() => {
    if (userInfoData && userInfoData.status === 200) {
      window.localStorage.setItem(
        "userInfo",
        JSON.stringify(userInfoData.data)
      );
      // navigate("/home");
      // window.location.reload();
      homeScreenAPI()

    }if (userInfoData && userInfoData.status === 404) {
      setSocialMediaReirect(false)
      setLoginErr(userInfoData.message)
      window.localStorage.removeItem(
        "tokendetails"
      );
    }
  }, [userInfoData]);

  useEffect(() => {
    // console.log("homeAPI",homeAPI);
    if (homeAPI && homeAPI?.data) {
      const resObj = homeAPI.data;
      const userData = homeAPI.data?.user;
      const planData = homeAPI.data?.plan;
      window.localStorage.setItem("planDetails",JSON.stringify(planData));
      window.localStorage.setItem("isSubscribed", planData?.isSubscribed);
      if (userData.userRoleCode === "owner") {
        if (planData.isSubscribed) {
          navigate("/home");
        } else {
          navigate("/pricing");
        }
      } else {
        if (planData.isSubscribed) {
          navigate("/home");
        } else {
          navigate("/contact",{ state: { ownerInfo: resObj?.ownerDetails?.ownerEmail } });

        }
      }
    }
  }, [homeAPI])

  const onChangeUsername = (event) => {
    const inputValue = event.target.value;
    if (!inputValue.includes(' ')) {
      setUsername(inputValue);
    }
  };
  const onChangePassword = (event) => {
    const inputPwd = event.target.value;
    if (!inputPwd.includes(' ')) {
      setPassword(inputPwd);
    }
  };
  // const handlePress = (e) => {
  //   if (e.key === 'Enter') {
  //     onLogin();
  //   }
  // };

  // useEffect(()=>{
  //   // setTimeout(()=>{
  //     const payload = {
  //       username: userName,
  //       password: password,
  //     };
  //     // setpayloaddata(payload
  //     console.log(payloaddata,"payloaddata");
  //   // },3000)
    
  // },[userName,password])
  // useEffect(() => {
  //   const handleKeyDownEvent = (e) => handlePress(e);

  //   document.addEventListener('keydown', handleKeyDownEvent);
  //   return () => {
  //     document.removeEventListener('keydown', handleKeyDownEvent);
  //   };
  // }, [payloaddata]);


  const onLogin = async () => {
    if (!userName?.trim().length) {
      setLoginErr("This is required.");
      return;
    } else {
      setLoginErr("");
    }
    if (!password?.trim().length) {
      setLoginErr("This is required.");
      return;
    } else {
      setLoginErr("");
    }
    const payload = {
      username: userName,
      password: password,
    };
    signInAccount(payload);
  };

  const getUserData = (cognitoObj) => {
    localStorage.setItem("propertytoken", null);
    if (cognitoObj?.payload?.signInUserSession) {
      window.localStorage.setItem(
        "tokendetails",
        JSON.stringify(cognitoObj.payload.signInUserSession)
      );
      const loginObject = {
        email:
          cognitoObj?.payload?.signInUserSession?.idToken?.payload?.email.trim() ||
          null,
      };
      if (loginObject && loginObject.email) {
        loginWithCognitoToken(loginObject);
      }
    }
  };

  const getUserDataUsingSocialSignin = (cognitoObj) => {
    localStorage.setItem("propertytoken", null);
    if (cognitoObj?.payload?.signInUserSession) {
      window.localStorage.setItem(
        "tokendetails",
        JSON.stringify(cognitoObj.payload.signInUserSession)
      );
      const loginObject = {
        email:
          cognitoObj?.payload?.signInUserSession?.idToken?.payload?.email.trim() ||
          null,
      };
      loginWithSocialMedia(loginObject);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    onLogin(); // Call the onLogin function
  };
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <Box className="loginScreen" id="login-layout">
      {
        (isFetchingLogin || isFetchingUserInfo || socialMediaRedirect || verificationData  ) && (
          <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress style={{ color: "#FF3535" }} />
        </Backdrop>
        )
      }
      <div id="login-container">
        <div id="login-title">
          <img src={MyRedFolderLogo} alt="myRedFolderLogo" href="#" className="login-title-img" />
          <img src={NEPTALOGO} alt="Nepta-logo" href="#" width={172} />
        </div>
        <div>
          {loginErr.trim().length > 0 && (
            <div className="error-msg-holder">
              <div style={{ display: "flex" }}>
                <img src={ErrorIcon} alt="error" href="#" />
                <Typography
                  className="primary-font-normal-1"
                  style={{ marginLeft: 10 }}
                >
                  {loginErr}
                </Typography>
              </div>
              <img
                src={ErrorClose}
                href="#"
                alt="error"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setLoginErr("");
                }}
              />
            </div>
          )}
          <div id="login-content">
            <form onSubmit={handleSubmit} autocomplete="off ">

              <div className="input-holder">
                <div
                  sx={{ p: "10px" }}
                  aria-label="userName"
                  className="input-icon-holder"
                >
                  <img src={User} alt="userName" href="#" />
                </div>
                <input
                  className={`primary-font-normal-1 login-input`}
                  aria-label="userName"
                  onChangeCapture={onChangeUsername}
                  value={userName}
                  autocomplete="new-User"
                  placeholder="Email"
                />
              </div>
              <div className="input-holder">
                <div
                  sx={{ p: "10px" }}
                  aria-label="userName"
                  className="input-icon-holder"
                >
                  <img src={Password} alt="password" href="#" />
                </div>
                <input
                  style={{ padding: "15px 68px 15px 60px" }}
                  className={`primary-font-normal-1 login-input`}
                  type={showPassword ? "text" : "password"}
                  onChange={onChangePassword}
                  value={password}
                  autocomplete="new-password"
                  aria-label="password"
                  placeholder="Password"
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="password"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  className="input-eye-icon"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </div>
              <div className="primary-font-normal-2 forgot-pwd">
                <Typography
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/forgot-password");
                    resetLogin();
                  }}
                >
                  Forgot Password
                </Typography>
              </div>
              <Button
                variant="contained"
                className="primary-font-bold1 signin-btn"
                type="submit"
                style={{ color: "#fff !important" }}
              >
                Login
              </Button>
            </form>
            <div className="or-divider-holder">
              <Divider className="divider2" />
              <div className="or-style">Or</div>
              <Divider className="divider2" />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "32px",
                alignItems: "center",
                padding: 20,
              }}
            >
              <div
                onClick={() =>{
                  resetLogin();
                  Auth.federatedSignIn({
                    provider: CognitoHostedUIIdentityProvider.Google,
                  })
                }
                }
              >
                <img src={Google} alt="google" className="image-hover" />
              </div>
              {/* <div>
            <img src={Microsoft} alt="windows" className="image-hover" />
        </div> */}
              <div
                onClick={() =>{
                  Auth.federatedSignIn({
                    provider: CognitoHostedUIIdentityProvider.Facebook,
                  })
                  resetLogin();
                }
                }
              >
                <img src={FaceBook} alt="facebook" className="image-hover" />
              </div>
              {/* <div>
            <img src={Twitter} alt="twitter" className="image-hover"/>
        </div>
        <div>
            <img src={Insta} alt="instagram" className="image-hover"/>
        </div> */}
            </div>
            <Divider className="divider1" />
            <Typography
              className="primary-font-medium"
              textAlign={"center"}
              color="#fff"
            >
              New to MYREDFOLDER®?
            </Typography>
            <Button
              variant="contained"
              className="primary-font-bold-5 create-acc-btn"
              onClick={() => {navigate("/create-account"); resetLogin()}}
            >
              Create Account
            </Button>
          </div>
        </div>
      </div>
    </Box>
  );
};

IndexComponent.defaultProps = {
  loginData: null,
  errorData: null,
  isFetchingLogin: null,
  isFetchingRegister: null,
  isFetchingUserInfo: null,
};

function mapStateToProps(state) {
  return {
    loginData: state.login.data,
    errorData: state.login.error,
    userInfoData: state.login.userInfoData,
    isFetchingLogin: state.login.isFetching,
    verificationData: state.signup.verificationData,
    isFetchingUserInfo: state.login.isFetchingUserInfo,
    homeAPI: state.login.homeAPI,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      signInAccount,
      loginWithCognitoToken,
      loginWithSocialMedia,
      resetLogin,
      homeScreenAPI,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(IndexComponent);
