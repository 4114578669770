import React from 'react';
import ContentWrapper from '../../../../ContentWrapper';
import TrackMyFamily from './TrackMyFamily';

const TrackMyFamilyComponent = () =>{
    return(
        <ContentWrapper title={"Track My Family"} gobackUrl={'/track-me'} goback={true} contenview={<TrackMyFamily/>}/>
    )
}

export default TrackMyFamilyComponent;