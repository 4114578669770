import React, { useState } from 'react'
import ContentWrapper from '../../../../../common/components/ContentWrapper';
import { Grid, Typography, Button } from "@mui/material";
import DisasterList from './DisasterList.js';
import "../DisasterManagement/styles/DisasterManagement.css";

const MainLayout = ({ goback = true }) => {
    
return (
    <Grid container spacing={2} style={{width: '100%', margin: 0}}>
      {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
      <div className='disaster-card-container disaster-container-margin'>
      <Grid
       item
        xs={12}
        md={12}
        lg={12}
       sx={{ display: "flex",
        flexWrap:"wrap",
        backgroundolor: "#fff",
       
        boxShadow: "0px 1px 12px #00000026"}}
       
      >
           
            <DisasterList Curd={false}/>
        </Grid>
      {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
      </div>
    </Grid>
)
}

const DisasterManagement = () => {

  return (
    <ContentWrapper title={"Disaster Management"} gobackUrl={'/disaster-management'} goback={true} contenview={<MainLayout />} />
  )
}

export default DisasterManagement;