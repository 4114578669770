import React from 'react';
import ContentWrapper from '../../../../common/components/ContentWrapper';
import ProfilePage from './ProfilePage';
import {useLocation} from 'react-router-dom';

const MyProfile = (props) => {
  const location = useLocation();
  const openPopUp = location?.state?.dontShowSecurityLock ? false: true
  return (
    <>
      <ContentWrapper title={"My Profile"} gobackUrl={''} img={false} goback={false} contenview={<ProfilePage openPopUp={openPopUp} />} />
    </>
  )
}

export default MyProfile;
