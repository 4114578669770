import React from 'react'
import ContentWrapper from '../../../../common/components/ContentWrapper';
import SettingPage from './SettingPage';
import { useTranslation } from 'react-i18next';

const SettingComponent = () => {
  const { t } = useTranslation('common');

  return (
    <ContentWrapper title={t("Settings")} gobackUrl={'#'} img={false} contenview={<SettingPage />} />
  )
}

export default SettingComponent