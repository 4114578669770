import React, { useState, useEffect } from "react";
import "../../../../index.css";
import "../styles/home.css";
import "../../Info/styles/info.css"
import { Grid } from "@mui/material";
import Vehicle from '../../../../assets/images/my-info/vehicle.png';
import Property from '../../../../assets/images/my-info/property.png';
import FireArms from '../../../../assets/images/my-info/firearms.png';
import MainCard from "../../common/MainCard";
import ContentWrapper from '../../../../common/components/ContentWrapper';

const AssetsList = () => {
  const cardList = [

    {
      image: Property,
      redText: 'MY',
      cardText: 'Property(s)',
      redirection: '/my-property',
      toRedirect: true,
      securityPin: false,

    },
    {
      image: FireArms,
      redText: 'MY',
      cardText: 'Firearms',
      redirection: '/my-fire-arms',
      toRedirect: false,
      securityPin: false,
    },
    {
      image: Vehicle,
      redText: 'MY',
      cardText: 'Vehicles',
      redirection: '/my-vechiles',
      toRedirect: false,
      securityPin: false,
    }
  ]

  return (
    <Grid container sx={{ m: 0 }}>
      <div className="MyInfo-header-container ">
        <Grid
          container
          xs={12}
          md={12}
          lg={12}
        >
          <MainCard cardList={cardList} />
        </Grid>
      </div>
    </Grid>
  );
};

const MyAssetsComponent = () => {
  return (
    <ContentWrapper title={"My Assets"} goback={true} gobackUrl={'/home'} img={false} contenview={<AssetsList />} />
  )
}

export default MyAssetsComponent;
