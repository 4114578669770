import React, { useState } from 'react'
import ContentWrapper from '../../../../../common/components/ContentWrapper';
import MeetingLocations from "../MeetingLocations/MeetingLocations";
import {Grid,Typography,Checkbox,CardContent,Card, CardActions,Button} from "@mui/material";
import "../MeetingLocations/styles/MeetingLocations.css";

const MeetingLocation =() => {

  const MeetingLocationWrapper = () =>{
    return (
      <Grid container>
      {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
      <Grid   item
      xs={12}
      md={12}
      lg={12}
      >
      <MeetingLocations />
      </Grid>
      {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
      
      </Grid>
    )
  }

  return (
    <ContentWrapper title={"Meeting Location"} 
    gobackUrl={'/meeting-locations'} 
    goback={true} 
    contenview={<MeetingLocationWrapper />} />
  )
}

export default MeetingLocation;