import React, { useState, useRef, useEffect } from "react";
import "../../../../../index.css";
import { Dialog, DialogActions, DialogContent, Typography, Button, TextField, Box, useMediaQuery } from "@mui/material";
import LockIcon from '../../../../../assets/images/security-lock.svg';
import CloseIcon from "../../../../../assets/icons/modal-close-icon.svg";
import checkIcon from "../../../../../assets/images/CheckIcon.svg";

import { verifySecurityPin } from "../../redux/actions";
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import info from "../../../../../assets/icons/info-i-icon.svg"
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { sendSecurityPinOtp } from "../../../Home/redux/actions";
import { use } from "i18next"
import "../../../Login/styles/login.css"



const SecurityPinModal = ({ openDialog, onClose, Url, verifyPinData, verifyPinError, verifySecurityPin, BgColor, sendSecurityPinOtp }) => {

  const [pin, setPin] = useState(['', '', '', '']);
  const refs = [useRef(), useRef(), useRef(), useRef()];
  const [securityPinErr, setSecurityPinErr] = useState('');

  const Navigate = useNavigate()
  
  const [OtpSent, setOtpSent] = useState(false);

  const resendSecurityPinOTP = () => {
    const userdetials = window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem('userInfo')) : null 
    const payload = {
      userId : userdetials?.id
    }
    sendSecurityPinOtp(payload);
    setTimeout(() => {
      setOtpSent(true);
    },1200);
  }
  const isMobile = useMediaQuery('(max-width: 800px)');
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#FF8620',
    },
    [`& .${tooltipClasses.tooltip}`]: {
      color:"white",
      // backgroundColor: '#FFE8AA',
      // border: ' 1px solid #FF8620 ',
      borderRadius:"7px",
      maxWidth: isMobile ? 240 : 400,
      padding:"15px",
      fontSize:"16px",
      fontWeight:400
  
    },
  }));
  
  const handleInputChange = (index, value) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newPin = [...pin];
      newPin[index] = value;
      setPin(newPin);

      if (value.length === 1 && index < refs.length - 1) {
        refs[index + 1].current.focus();
      }
      const enteredPin = newPin.join('')
      if (enteredPin.length === 4) {
        const payload = {
          id: user?.id,
          securityPin: enteredPin.trim(),

        }
        verifySecurityPin(payload);
      }
    }
  };

  useEffect(() => {
    if (Url) {
      if (verifyPinData && verifyPinData.status === 200) {
        Navigate(Url, { state: "false" })
        setPin(['', '', '', '']);
        setSecurityPinErr('');
      }
      if (verifyPinData && verifyPinData.status !== 200) {
        setSecurityPinErr(verifyPinData?.message || "");
      }
    }
    else if (!Url) {
      if (verifyPinData && verifyPinData.status === 200) {
        onClose()
      }
      if (verifyPinData && verifyPinData.status !== 200) {
        setSecurityPinErr(verifyPinData?.message || "");
      }

    }
  }, [verifyPinData, verifyPinError])

  useEffect(() => {
    const timer = setTimeout(() => {
      setOtpSent(false);
    }, 5000)
    return () => clearInterval(timer);
  },[OtpSent])


  const onSubmit = () => {
    const securityPin = pin.join('');

    let isValid = false;

    if (securityPin?.trim().length === 0) {
      setSecurityPinErr('Security Pin is required.');
      isValid = true;
    } else if (securityPin.length !== 4) {
      setSecurityPinErr('Enter valid Security Pin.');
      isValid = true;
    }
    if (isValid) {
      return;
    }
    const payload = {
      id: user?.id,
      securityPin: securityPin,

    }
    verifySecurityPin(payload);
  }
  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && pin[index] === '') {
      refs[index - 1].current.focus();
    } else if (e.key === 'ArrowLeft' && index > 0) {
      refs[index - 1].current.focus();
    } else if (e.key === 'ArrowRight' && index < refs.length - 1) {
      refs[index + 1].current.focus();
    }
  };
  const onClosingDialog = () => {
    setPin(['', '', '', '']);
    setSecurityPinErr('');
    onClose()
  }
  const onCloseDialog = () => {
    setPin(['', '', '', '']);
    setSecurityPinErr('');
    Navigate("/home")
    onClose()
  }

  const user = window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem('userInfo')) : null;

  return (
    <Dialog
      open={openDialog}
      sx={{ bgcolor: BgColor ? "#F5F5F5" : "" }}
      className="small-dialog dialog-box-pin"
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      {OtpSent ? (
        (
          <div>
            <DialogContent>
              <div className="content-dialog">
                <img src={checkIcon} alt="success" style={{justifyContent:"center", width:"30%"}}/>
                <Typography>
                  We have emailed you a 4 digit verification code. Please check your email Inbox.
                </Typography>
              </div>
            </DialogContent>
          </div>
        )
      ):(
        <>
             <DialogContent  >
      <Box sx={{cursor: 'pointer', float: "right"  }}>
        {
          BgColor ? <img src={CloseIcon} alt="close-icon" style={{ }} onClick={onCloseDialog} /> :

            <img src={CloseIcon} alt="close-icon" style={{width:"21px" }} onClick={onClosingDialog} />}
      </Box >
      <Box sx={{cursor: 'pointer', float: "left"}}>
      <LightTooltip  enterTouchDelay={0}  leaveTouchDelay={5000} arrow placement="bottom"  title="Security Pin - 4-digit security code to unlock any sensitive information for member access.">
                      <img src={info} alt="" height="25px" width="25px" style={{float:"right"}} />
                      </LightTooltip>
                </Box>
                <Box className="content-dialog-lock">
     
    
        <img src={LockIcon} alt='phone' style={{ marginRight: 10,marginTop: '30px' }} width='87px' height='87px' />
        
        <Typography className="primary-font-medium-small"><b>Please Enter your Secure PIN to continue</b></Typography>
        <div>
          <Typography className='font-normal-small error-message'>{securityPinErr}</Typography>
        </div>
        </Box>
        <div style={{ display: 'flex', gap: '10px',justifyContent:"center" }}>
          {pin.map((digit, index) => (
            <TextField
              key={index}
              type="password"
              variant="outlined"
              id={`input-${index}`}
              onKeyDown={(e) => handleKeyDown(e, index)}
              inputProps={{ maxLength: 1 }}
              value={digit}
              onChange={(e) => handleInputChange(index, e.target.value)}
              inputRef={refs[index]}
              className='security-input'
              style={{
                width: '40px',
                textAlign: 'center',
                boxShadow: digit ? '0px 2px 8px #00000029' : 'none',
                transition: 'box-shadow 0.2s ease-in-out',
              }}
            />
          ))}
        </div>
       
         <div>
          <Typography  className="forgot-security-pin" onClick={() => {
            resendSecurityPinOTP()
          }}>Forgot security Pin?</Typography>
         </div>

      </DialogContent>
      {/* <DialogActions>
        <div className="action-holder" style={{ marginTop: 0, marginBottom: 0 }}>
          <Button variant="outlined" className="primary-font-bold-5 action-btn-yes profile-save-btn" style={{ width: '70%' }} onClick={onSubmit}>Submit</Button>
        </div>
      </DialogActions> */}
        </>
 
  )}
  </Dialog>
  )
  }
          
SecurityPinModal.defaultProps = {
  verifyPinData: null,
  verifyPinError: null,
};

function mapStateToProps(state) {
  return {
    verifyPinData: state.bank.verifyPinData,
    verifyPinError: state.bank.verifyPinError,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      verifySecurityPin,
      sendSecurityPinOtp
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecurityPinModal);

