import React, { useState, useEffect, useContext } from "react";
import "../../../../../../index.css";
import { Grid, Typography, Button, Card, CardActions, CardContent, Dialog,IconButton,
  DialogContent} from "@mui/material";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import {UserInfoDetails} from "../../../../../../utils/userContext";
import { uploadPropertyFile, uploadVideoFile,uploadMultipleFile,getSinglePropertyList} from "../../../redux/actions";
import { updateProperty } from "../../../redux/actions";
import defaultImage from '../../../../../../assets/images/my-info/default-Image.png';
import excelImage from '../../../../../../assets/images/my-info/ms-excel.svg';
import pdfImage from '../../../../../../assets/images/my-info/pdf-image.png';
import { LoadingButton } from "@mui/lab";
import { useLocation, useParams } from "react-router-dom";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Edit as EditIcon, CheckCircleOutline as CheckIcon } from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';





const Documents = ({ onBackOrCancelClick,
  handleNext,
  propertyInventoryData,
  uploadPropertyFile,
  uploadMultipleFile,
  uploadVideoFile,
  updateProperty,
  propertyUpdateResponse,
  multipleImageData,
  propertyVideoData,
  getSinglePropertyList,
  propertyListData
}) => {
  const { userObject } = useContext(UserInfoDetails)

  const initialFormData = {
    youtubeLink: '',
    vimeoLink: '',
    youtubeLinkName: '',
    vimeoLinkName: ''
  };
  // const hideEdit = window.localStorage.getItem("hideEdit") ? window.localStorage.getItem("hideEdit") : false;
  const location = useLocation();
  const hideEdit = location?.state?.hideEdit;

  const [formData, setFormData] = useState(initialFormData);
  const [isApiLoad, setIsApiLoad] = useState(false)
  const [documentInfo, setDocumentInfo] = useState({});
  const [videoUploadError, setVideoUploadError] = useState('');
  const [documentList, setDocumentList] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const dispatch = useDispatch()
  const {propertyId} = useParams();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  const [ytTop, setYtTop] = useState('20px');
  const [vmTop, setVmTop] = useState('20px');
  const [editModes, setEditModes] = useState({});
  const [editedNames, setEditedNames] = useState({});
  const [videoEditModes,setVideoEditModes] = useState({});
  const [videoEditedNames, setVideoEditedNames] = useState({});
  const [isDocumentUpload, setIsDocumentUpload] = useState(false);
  const [successDialouge, setSuccessDialuge] = useState(false)
  const [message, setMessage] = useState("");
  const [isbuttonClicked, setIsbuttonClicked] = useState(false);


  useEffect(() => {
    getSinglePropertyList(propertyId)
  },[propertyId])

  useEffect(() => {
    if (propertyListData && propertyListData.status === 200) {
      dispatch({
        type: "ADD_PROPERTY_INVENTORY",
        payload: propertyListData.data
      })
      if (propertyListData && Object.keys(propertyListData).length > 0) {
        const { propertyDocuments } = propertyListData.data;
        if(propertyDocuments){
          setFormData(propertyDocuments);
          setSelectedFiles(propertyDocuments?.documentList)
          setDocumentList(propertyDocuments?.documentList)
          setVideoList(propertyDocuments?.videoList)
          setVideoFiles(propertyDocuments?.videoList)
        }
     

      }
    } else if (propertyListData && propertyListData.status === 404) {
      // setPropertyData([])
    }
  }, [propertyListData])

  useEffect(() => {
    if (selectedFiles?.length === 0 && videoFiles?.length === 0) {
      setYtTop('20px');
      setVmTop('20px');
    }else if (selectedFiles?.length > 0 && videoFiles?.length === 0) {
      setYtTop('20px');
      setVmTop('220px');
    }else if (selectedFiles?.length === 0 && videoFiles?.length > 0) {
      setYtTop('220px');
      setVmTop('20px');
    }else if (selectedFiles?.length > 0 && videoFiles?.length > 0) {
      setYtTop('20px');
      setVmTop('20px');
    }
  }, [selectedFiles, videoFiles]);



  useEffect(() => {
    if(propertyInventoryData?.propertyId){
      getSinglePropertyList(propertyInventoryData?.propertyId)

    }
  }, [propertyInventoryData?.propertyId]);




  useEffect(() => {
    if (propertyUpdateResponse && propertyUpdateResponse.status === 200) {
      if(Object.keys(documentInfo).length > 0){
        setIsApiLoad(false)
        dispatch({
          type: "PROPERTY_UPDATE_SUCCESS",
          payload: {}
        })
  
        dispatch({
          type: "ADD_PROPERTY_INVENTORY",
          payload: documentInfo
        })
  
        handleNext();
      }
    }
  }, [propertyUpdateResponse])

  useEffect(() => {
    if(multipleImageData?.data && multipleImageData?.data.length > 0){
      setIsDocumentUpload(false)
      multipleImageData?.data.forEach((obj,index) => {
        obj.propertyId = propertyInventoryData?.propertyId;
      
      });
    let newFiles = [...multipleImageData?.data, ...documentList]
      setDocumentList(newFiles)
    }
  }, [multipleImageData])

  useEffect(() => {
    if (propertyVideoData?.data && propertyVideoData?.data.length > 0) {
      setIsDocumentUpload(false)
      propertyVideoData?.data.forEach(obj => {
        obj.propertyId = propertyInventoryData?.propertyId;
      });
      let newFiles = [...propertyVideoData?.data, ...videoList]
      setVideoList(newFiles)
      if (isbuttonClicked) {
        updateAPi(newFiles)
      }
    }
  }, [propertyVideoData])

  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

 

  const handleFileChange = (e) => {
    const files = e.target.files;
    const newFiles = Array.from(files);
    const formData = new FormData();
    formData.set("userId", userObject.id);
    formData.set("accountId", userObject.accountId);
    formData.set('fileTag', 'PropertyDocuments')

    const fileData = [...selectedFiles, ...newFiles];

    fileData?.forEach((file, index) => {
      formData.append('files', file);
    });

    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setIsDocumentUpload(true)
    uploadMultipleFile(formData)

  };

  
 
  const handleVideoFile = (e) => {
    const files = e.target.files;
    const newFiles = Array.from(files);
    const formData = new FormData();
    formData.set('fileTag', 'PropertyDocuments')
    const videoExtensions = ['mp4', 'webm', 'ogg', 'mkv', 'avi'];

    newFiles.some(file => {
      const fileExtension = getFileExtension(file?.name);
      if (videoExtensions.includes(fileExtension)) {
        const alertErr = "Please upload file size less than 250MB."
        if (file.size > 250 * 1000 * 1000) {
          setVideoUploadError(alertErr);
          return;
        }
      } else {
        setVideoUploadError("Please upload file with extenstions mp4, webm, ogg, mkv, avi and mov");
        return;
      }

    });

    formData.set("userId", userObject.id);
    formData.set("accountId", userObject.accountId);
    formData.set('fileTag', 'PropertyDocuments')

    const fileData = [...videoFiles, ...newFiles];

    fileData?.forEach((file, index) => {
      formData.append('files', file);
    });

    setVideoFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setIsDocumentUpload(true)
    uploadVideoFile(formData)

  };

 

  const getFileExtension = (fileName) => {
    const file_name = fileName?.toLowerCase();
    return file_name?.slice((file_name.lastIndexOf('.') - 1 >>> 0) + 2);
  };

  const nameWithoutExtension = (fileName) => {
    if (fileName.includes('.')) {
      return fileName.split('.').slice(0, -1).join('.');
    } else {
      return fileName;
    }
  }
  
  
  const handleDelete = (fileToDelete) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
    let filteredData = documentList.filter(item => item.s3Key !== fileToDelete.s3Key);
    if (filteredData.length === documentList.length) {
      const fileName = fileToDelete.name;
      const nameWithoutExtension = fileName.split('.').slice(0, -1).join('.');
      filteredData = documentList.filter(item => item.name !== nameWithoutExtension);
    }
    setDocumentList(filteredData)
  };
  const handleVideoDelete = (fileToDelete) => {
    setVideoFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
    let filteredData = videoList.filter(item => item.s3Key !== fileToDelete.s3Key);
    if (filteredData.length === videoList.length) {
      const fileName = fileToDelete.name;
      const nameWithoutExtension = fileName.split('.').slice(0, -1).join('.');
      filteredData = videoList.filter(item => item.name !== nameWithoutExtension);
    }
    setVideoList(filteredData)
  };

  const handleSubmit = async (e) => {
      e.preventDefault();
    if(!isDocumentUpload){
      setIsApiLoad(true)
      try {
        const documentDetails = {
          "propertyDocuments": {
            ...formData,
            'documentList':documentList,
            'videoList':videoList,
            "propertyId": propertyInventoryData?.propertyId,
            "accountId": userObject.accountId,
            "updatedBy": userObject.id,
            "id":propertyInventoryData ? propertyInventoryData?.propertyDocuments?.id : ""
          }
        };
        const docInfo = { ...propertyInventoryData, ...documentDetails }
        setDocumentInfo(docInfo)
        await updateProperty(documentDetails);
  
      } catch (error) {
      setIsApiLoad(false)
        console.error('An error occurred:', error);
      }
    }else{
      setIsApiLoad(true);
      setIsbuttonClicked(true)
      // showInfoMessage()
    }
   
  };

  const updateAPi = (newFiles) => {
    const documentDetails = {
      "propertyDocuments": {
        ...formData,
        'documentList':documentList,
        'videoList':newFiles,
        "propertyId": propertyInventoryData?.propertyId,
        "accountId": userObject.accountId,
        "updatedBy": userObject.id,
        "id":propertyInventoryData ? propertyInventoryData?.propertyDocuments?.id : ""
      }
    };
    const docInfo = { ...propertyInventoryData, ...documentDetails }
    setDocumentInfo(docInfo)
    updateProperty(documentDetails);
  }
 
  //Documents
  const handleEdit = (fileIndex) => {
    if(!isDocumentUpload){
      setEditModes({ ...editModes, [fileIndex]: true });
      setEditedNames({ ...editedNames, [fileIndex]: selectedFiles[fileIndex]?.name });
    }else{
      showInfoMessage()
    }
  };

  const handleSave = (fileIndex,oldName,newName) => {
    if(newName){
    if(!isDocumentUpload){
      setEditModes({ ...editModes, [fileIndex]: false });
      const fName = nameWithoutExtension(oldName)
      const updatedSelectedFiles = documentList.map((file, index) => {
        const woName = nameWithoutExtension(file.name)
        if (woName === fName) {
          return { ...file, name: newName };
        }else{
          console.log('no match found');
        }
        return file;
      });
      setDocumentList(updatedSelectedFiles);
    }else{
      showInfoMessage()
    }
    }
   
  };

  function showInfoMessage() {
    setSuccessDialuge(true)
      setMessage('Please wait uploading inprogress.')
      setTimeout(() => {
        setSuccessDialuge(false);
        setMessage("");
      }, 2000);
  }


  const handleNameChange = (event, fileIndex,name) => {
    const newName = event.target.value;
    setEditedNames({ ...editedNames, [fileIndex]: newName }); 
  };

  //Video
  const handleVideoEdit = (fileIndex) => {
    if(!isDocumentUpload){
      setVideoEditModes({ ...videoEditModes, [fileIndex]: true });
      setVideoEditedNames({ ...videoEditedNames, [fileIndex]: videoFiles[fileIndex]?.name });
    }else{
      showInfoMessage()
    }
    
  };

  const handleVideoSave = (fileIndex,oldName,newName) => {
    if(newName){
    if(!isDocumentUpload){
      setVideoEditModes({ ...videoEditModes, [fileIndex]: false });
      const fName = nameWithoutExtension(oldName)
      const updatedSelectedFiles = videoList.map((file, index) => {
        if (file.name === fName) {
          return { ...file, name: newName };
        }
        return file;
      });
      setVideoList(updatedSelectedFiles);
    }else{
      showInfoMessage()
    }
  }
  };

  const handleVideoNameChange = (event, fileIndex,name) => {
    const newName = event.target.value;
    setVideoEditedNames({ ...videoEditedNames, [fileIndex]: newName }); 
  };


  return (

    <Grid container spacing={6} columns={16} className="property-form-padding " style={{ margin: 0,  paddingTop: 0, width: '100%', justifyContent: 'start' }}>
      <Grid item xs={12} sm={12} md={8} lg={8} className='item-padding'>
        <div className="flex-boxes" style={{ display: 'flex' }}>
          <div className="full-width">
            <Typography className='font-normal-small profile-input-label'>Upload Document</Typography>
            <div>

              <Button
                variant="oulined"
                component="label"
                startIcon={<CloudUploadIcon />}
                disabled={hideEdit}
                className="profile-input"
                sx={{
                  border: "1px solid #ddd",
                  textTransform: "inherit",
                  color: "#FF3535",
                  fontSize:"16px",
                  "&:hover": {
                  color: "#FFF",
                  backgroundColor: "#FF3535 !important",
                  border: "1px solid #FF3535",
                  },
                }}
              >
                Upload
              
                <input accept="image/*, .pdf, .ppt, .pptx, .doc, .docx, .xls, .xlsx, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/msword" type="file" hidden multiple onChange={handleFileChange}/>
              </Button>
            </div>
            {selectedFiles.length > 0 && (<Grid container spacing={2} className="scrollable-element" style={{marginTop:"3px"}}>
            {selectedFiles &&
                selectedFiles.map((file, index) => (
                  <Grid item key={index} xs={12} sm={6} md={6} lg={4}>
                    <Card style={{ padding: '3px', position: 'relative', boxShadow: 'none', border: '1px dashed #D6D6D6' }}>
                      {file?.preSignedUrl && (
                        <CardContent sx={{ maxWidth: '100%', width: '400px', padding: "0 !important" }}>
                        {["xlsx", "pdf"].includes(getFileExtension(file?.s3Key)) && (
                         <>
                          <img
                            src={getFileExtension(file?.s3Key) === "xlsx" ? excelImage : pdfImage}
                            alt={`Image ${index + 1}`}
                            className="image-multi-container"
                          />
                              <div  className="image-name">
                              {editModes[index] ? (
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                      type="text"
                                      value={editedNames[index]}
                                      onChange={(event) => handleNameChange(event, index,file?.name)}
                                      style={{borderBottom: editedNames[index] === "" ? "1px solid #FF3535" : ""}}
                                    />
                                    <div>
                                      <IconButton size="small"  style={{ fontSize: '12px', width: '24px', height: '24px' }}  onClick={() => handleSave(index,file?.name,editedNames[index])}>
                                        <DoneIcon style={{width:"19px"}} />
                                        </IconButton>
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span title={editedNames[index] || file?.name} style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',paddingRight:"16px" }}>
                                          {editedNames[index] || file?.name}
                                        </span>
                                        <div>
                                          <IconButton size="small"  style={{ fontSize: '12px', width: '24px', height: '24px' }}  onClick={() =>  handleEdit(index)}>
                                        <EditIcon style={{width:"19px"}} />
                                      </IconButton>
                                        </div>
                                      </div>
                                  </>
                                )}
                              </div >
                         </>
                        )}
                        {["png", "jpg", "jpeg"].includes(getFileExtension(file?.s3Key)) && (
                         <>
                          <img
                            src={file?.preSignedUrl}
                            alt={`Image ${index + 1}`}
                            className="image-multi-container"
                          />
                            <div className="image-name">
                            {editModes[index] ? (
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                      type="text"
                                      value={editedNames[index]}
                                      onChange={(event) => handleNameChange(event, index,file?.name)}
                                      style={{borderBottom: editedNames[index] === "" ? "1px solid #FF3535" : ""}}
                                    />
                                    <div>
                                      <IconButton size="small"  style={{ fontSize: '12px', width: '24px', height: '24px' }}  onClick={() => handleSave(index,file?.name,editedNames[index])}>
                                        <DoneIcon style={{width:"19px"}} />
                                        </IconButton>
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span title={editedNames[index] || file?.name} style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',paddingRight:"16px" }}>
                                          {editedNames[index] || file?.name}
                                        </span>
                                        <div>
                                          <IconButton size="small"  style={{ fontSize: '12px', width: '24px', height: '24px' }}  onClick={() =>  handleEdit(index)}>
                                        <EditIcon style={{width:"19px"}} />
                                      </IconButton>
                                        </div>
                                      </div>
                                  </>
                                )}
                            </div >
                         </>
                        )}
                        {!["xlsx", "pdf", "png", "jpg", "jpeg"].includes(getFileExtension(file?.s3Key)) && (
                         <>
                          <img
                            src={defaultImage}
                            alt={`Image ${index + 1}`}
                            className="image-multi-container"
                          />
                            <div className="image-name">
                            {editModes[index] ? (
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                      type="text"
                                      value={editedNames[index]}
                                      onChange={(event) => handleNameChange(event, index,file?.name)}
                                      style={{borderBottom: editedNames[index] === "" ? "1px solid #FF3535" : ""}}
                                    />
                                    <div>
                                      <IconButton size="small"  style={{ fontSize: '12px', width: '24px', height: '24px' }}  onClick={() => handleSave(index,file?.name,editedNames[index])}>
                                        <DoneIcon style={{width:"19px"}} />
                                        </IconButton>
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span title={editedNames[index] || file?.name} style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',paddingRight:"16px" }}>
                                          {editedNames[index] || file?.name}
                                        </span>
                                        <div>
                                          <IconButton size="small"  style={{ fontSize: '12px', width: '24px', height: '24px' }}  onClick={() =>  handleEdit(index)}>
                                        <EditIcon style={{width:"19px"}} />
                                      </IconButton>
                                        </div>
                                      </div>
                                  </>
                                )}
                            </div >
                         </>
                        )}
                      </CardContent>
                      )}

                      {!file.s3Key && (<CardContent sx={{ maxWidth: '100%', width: '400px', padding: 0 }}>
                     
                        <CardContent sx={{ maxWidth: '100%', width: '400px', padding: 0 }}>
                          {["xlsx", "pdf"].includes(getFileExtension(file?.name)) && (
                            <>
                              <img
                                src={getFileExtension(file?.name) === "xlsx" ? excelImage : pdfImage}
                                alt={`Image ${index + 1}`}
                                className="image-multi-container"
                              />
                              <div className="image-name">
                              {editModes[index] ? (
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                      type="text"
                                      value={nameWithoutExtension(editedNames[index])}
                                      onChange={(event) => handleNameChange(event, index,file?.name)}
                                      style={{borderBottom: editedNames[index] === "" ? "1px solid #FF3535" : ""}}
                                    />
                                    <div>
                                      <IconButton size="small"  style={{ fontSize: '12px', width: '24px', height: '24px' }}  onClick={() => handleSave(index,file?.name,editedNames[index])}>
                                        <DoneIcon style={{width:"19px"}} />
                                        </IconButton>
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span title={editedNames[index] || file?.name} style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',paddingRight:"16px" }}>
                                          {editedNames[index] || file?.name}
                                        </span>
                                        <div>
                                          <IconButton size="small"  style={{ fontSize: '12px', width: '24px', height: '24px' }}  onClick={() =>  handleEdit(index)}>
                                        <EditIcon style={{width:"19px"}} />
                                      </IconButton>
                                        </div>
                                      </div>
                                  </>
                                )}
                              </div >
                            </>
                          )}
                          {["png", "jpg", "jpeg"].includes(getFileExtension(file?.name)) && (
                           <>
                            <img
                              src={URL.createObjectURL(file)}
                              alt={`Image ${index + 1}`}
                              className="image-multi-container"
                            />
                              <div  className="image-name">
                              {editModes[index] ? (
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                      type="text"
                                      value={nameWithoutExtension(editedNames[index])}
                                      onChange={(event) => handleNameChange(event, index,file?.name)}
                                      style={{borderBottom: editedNames[index] === "" ? "1px solid #FF3535" : ""}}
                                    />
                                    <div>
                                      <IconButton size="small"  style={{ fontSize: '12px', width: '24px', height: '24px' }}  onClick={() => handleSave(index,file?.name,editedNames[index])}>
                                        <DoneIcon style={{width:"19px"}} />
                                        </IconButton>
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span title={editedNames[index] || file?.name} style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',paddingRight:"16px" }}>
                                          {editedNames[index] || file?.name}
                                        </span>
                                        <div>
                                          <IconButton size="small"  style={{ fontSize: '12px', width: '24px', height: '24px' }}  onClick={() =>  handleEdit(index)}>
                                        <EditIcon style={{width:"19px"}} />
                                      </IconButton>
                                        </div>
                                      </div>
                                  </>
                                )}
                              </div >
                           </>
                          )}
                          {!["xlsx", "pdf", "png", "jpg", "jpeg"].includes(getFileExtension(file?.name)) && (
                           <>
                            <img
                              src={defaultImage}
                              alt={`Image ${index + 1}`}
                              className="image-multi-container"
                            />
                             <div className="image-name">
                             {editModes[index] ? (
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                      type="text"
                                      value={nameWithoutExtension(editedNames[index])}
                                      onChange={(event) => handleNameChange(event, index,file?.name)}
                                      style={{borderBottom: editedNames[index] === "" ? "1px solid #FF3535" : ""}}
                                    />
                                    <div>
                                      <IconButton size="small"  style={{ fontSize: '12px', width: '24px', height: '24px' }}  onClick={() => handleSave(index,file?.name,editedNames[index])}>
                                        <DoneIcon style={{width:"19px"}} />
                                        </IconButton>
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span title={editedNames[index] || file?.name} style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',paddingRight:"16px" }}>
                                          {editedNames[index] || file?.name}
                                        </span>
                                        <div>
                                          <IconButton size="small"  style={{ fontSize: '12px', width: '24px', height: '24px' }}  onClick={() =>  handleEdit(index)}>
                                        <EditIcon style={{width:"19px"}} />
                                      </IconButton>
                                        </div>
                                      </div>
                                  </>
                                )}
                              </div >
                           </>
                          )}
                        </CardContent>

                      </CardContent>)}

                      <CardActions sx={{ padding: 0 }}>
                        {!hideEdit && (
                          <div className="action-button-dlt" style={{ position: "absolute", top: "6px", right: "7px" }} onClick={(event) => {
                            event.stopPropagation();
                            handleDelete(file);
                          }}></div>

                        )}
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
            </Grid>)}

          </div>
        </div>
        <div className="flex-boxes" style={{ display: 'flex' }}>
          <div className="full-width" style={{marginTop : ytTop}}>
            {/* {isValid && <Typography className='font-normal-small error-message'>Enter Valid link</Typography>} */}
            <Typography className='font-normal-small profile-input-label'>YouTube Link   </Typography>
            <input name="youtubeLink"  disabled={hideEdit} id="youtubeLink" className={`profile-input`}  aria-label='youtubeLink' onChange={handleInputChange} value={formData.youtubeLink} />
            <Typography className="font-normal-small profile-input-label">YouTube Link Name</Typography>
            <input name="youtubeLinkName" maxLength={200} disabled={hideEdit} id="youtubeLinkName" className={`profile-input`}  aria-label="youtubeLinkName" onChange={handleInputChange} value={formData.youtubeLinkName} />
          </div>
        </div>

       
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={8} className='item-padding'>

      <div className="flex-boxes" style={{ display: 'flex' }}>
          <div className="full-width">
            <Typography className='font-normal-small profile-input-label'>Upload Video</Typography>
            <div>
              <Button
                variant="oulined"
                component="label"
                startIcon={<CloudUploadIcon />}
                disabled={hideEdit}
                className="profile-input"
                sx={{
                  border: "1px solid #ddd",
                  textTransform: "inherit",
                  color: "#FF3535",
                  fontSize:"16px",
                  "&:hover": {
                  color: "#FFF",
                  backgroundColor: "#FF3535 !important",
                  border: "1px solid #FF3535",

                  },
                }}
              >
                Upload
                <input accept="video/*" type="file" hidden multiple onChange={handleVideoFile}/>
              </Button>
              {videoUploadError && (<Typography style={{color:"red", marginBottom : "20px"}}>{videoUploadError}</Typography>)}
            </div>
            {videoFiles?.length > 0 && (<Grid container spacing={2}  className="scrollable-element" style={{marginTop:"3px"}}>
              {videoFiles &&
                videoFiles.map((file, index) => (
                  <Grid item key={index} xs={12} sm={6} md={6} lg={4}>
                    <Card style={{ padding: '3px 3px ', position: 'relative', boxShadow: 'none', border: '1px dashed #D6D6D6' }}>
                      <video
                        className="VideoInput_video"
                        width="180px"
                        height="135px"
                        disabled={hideEdit}
                        controls
                        src={file?.preSignedUrl || URL.createObjectURL(file)}
                        style={{
                          width: "100%",
                          height: "140px",
                          objectFit: "cover",
                          borderRadius: "5px",
                          cursor: "pointer"
                        }}
                      />
                      <div className="image-name">
                      {videoEditModes[index] ? (
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                      type="text"
                                      value={videoEditedNames[index]}
                                      onChange={(event) => handleVideoNameChange(event, index,file?.name)}
                                      style={{borderBottom: videoEditedNames[index] === "" ? "1px solid #FF3535" : ""}}
                                    />
                                    <div>
                                      <IconButton size="small"  style={{ fontSize: '12px', width: '24px', height: '24px' }}  onClick={() => handleVideoSave(index,file?.name,videoEditedNames[index])}>
                                        <DoneIcon style={{width:"19px"}} />
                                        </IconButton>
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span title={videoEditedNames[index] || file?.name} style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',paddingRight:"16px" }}>
                                          {videoEditedNames[index] || file?.name}
                                        </span>
                                        <div>
                                          <IconButton size="small"  style={{ fontSize: '12px', width: '24px', height: '24px' }}  onClick={() =>  handleVideoEdit(index)}>
                                        <EditIcon style={{width:"19px"}} />
                                      </IconButton>
                                        </div>
                                      </div>
                                  </>
                                )}

                      </div >

                      <CardActions sx={{ padding: 0 }}>
                        {!hideEdit && (
                          <div className="action-button-dlt" style={{ position: "absolute", top: "6px", right: "4px" }} onClick={(event) => {
                            event.stopPropagation();
                            handleVideoDelete(file);
                          }}></div>
                        )}
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
            </Grid>)}
            
            
          </div>
        </div>
       
        <div className="flex-boxes" style={{ marginLeft: 0, display: 'flex', }}>
          <div className="full-width" style={{marginTop : vmTop}}>
            <Typography className='font-normal-small profile-input-label'>Vimeo Link</Typography>
            <input name="vimeoLink"  disabled={hideEdit} id="vimeoLink" className={`profile-input`} aria-label='vimeoLink' onChange={handleInputChange} value={formData.vimeoLink} />
            <Typography className="font-normal-small profile-input-label">Vimeo Link Name</Typography>
            <input name="vimeoLinkName" maxLength={200} disabled={hideEdit} id="vimeoLinkName" className={`profile-input`} aria-label="vimeoLinkName" onChange={handleInputChange} value={formData.vimeoLinkName}/>
          </div>
        </div>
      </Grid>
      <div className="action-holder" >
        <Button variant="outlined" className="primary-font-bold-6 action-btn btn-height profile-cancel-btn" onClick={onBackOrCancelClick}>Back</Button>
        {
          !isApiLoad && !hideEdit && <Button variant="outlined" className="primary-font-bold-6 action-btn btn-height profile-save-btn" onClick={handleSubmit}>Save & Continue</Button>
        }
          {
          hideEdit && <Button variant="outlined" className="primary-font-bold-6 action-btn btn-height profile-save-btn" onClick={()=>{handleNext()}}>Next</Button>
        }
        
        {isApiLoad && <LoadingButton
          size="small"
          className="primary-font-bold-6 action-btn btn-height profile-save-btn"
          loading={true}
          loadingPosition="start"
          variant="outlined"
          disabled
          style={{ opacity: '0.5' }}
        >
          <span>Saving</span>
        </LoadingButton>}
      </div>
      {message && (
        <Dialog
          open={successDialouge}
          onClose={() => {
            setSuccessDialuge(false);
          }}
          className="small-dialog dialog-box"
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
        >
          <DialogContent className="content-dialog">
            <Typography className="primary-font-normal-4">
              {message}
            </Typography>
          </DialogContent>
        </Dialog>
      )}
    </Grid>
  );
};

function mapStateToProps(state) {
  return {
    propertyInventoryData: state.propertyInventory.propertyInventoryData,
    propertyUpdateResponse: state.propertyInventory.propertyUpdateResponse,
    multipleImageData: state.propertyInventory.multipleImageData,
    propertyVideoData: state.propertyInventory.propertyVideoData,
    propertyListData: state.propertyInventory.propertyListData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      uploadPropertyFile,
      uploadMultipleFile,
      uploadVideoFile,
      updateProperty,
      getSinglePropertyList
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Documents);
