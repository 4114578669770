import React, { useState, useEffect } from "react";
import "../../../../../index.css";
import { Grid, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, Divider, Select, MenuItem, Menu, FormControl, Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import * as formatters from '../../../../../utils/util';
import UserAvatar from '../../../../../assets/icons/user-avatar.svg';
import Edit from '../../../../../assets/icons/edit.svg';
import CloseIcon from '../../../../../assets/icons/modal-close-icon.svg';
import UploadImage from '../../../../../assets/icons/upload-icon.svg';
import AvatarIcon from "../../../../../common/components/Avatar";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { connect, useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from "redux";
import { getProfileInfo } from "../../../UserProfile/redux/actions";
import { getSpecficUserProfile,getRelationshipList, editUser, uploadCommonUtilityPicture, updateProfileInHeader } from '../../redux/actions';
import Loading from "../../../common/Loading";
import moment from "moment/moment";
import "../MyFamily/styles/MyFamilyStyles.css";


const EditProfilePage = (props) => {
    const { getProfileInfo, getSpecficUserProfile,getRelationshipList,relationship, userInfoData, editUser, updatedData, uploadCommonUtilityPicture, profilePictureData, profilePictureError, isFetchingUserInfo } = props;

  const navigate = useNavigate();
  const { id } = useParams();
  
  const userDetails = window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem('userInfo')) : null;

  const { t } = useTranslation('common');
  const [user, setUser] = useState(null);
  const [userObject,setUserObject] = useState(null);
  const [name, setName] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrlAvatar1, setImageUrlAvatar1] = useState(null);
  const [imageUrlAvatar, setImageUrlAvatar] = useState(null);

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [dob, setDOB] = useState(null);
  const [ssn, setSSN] = useState('');
  const [relation, setRelation] = useState(null);
  const [phoneNumber, setphoneNumber] = useState(null);
  const [altPhoneNumber, setAltPhoneNumber] = useState(null);
  const [email, setEmail] = useState(null);
  const [altEmail, setAltEmail] = useState(null);
  const [emergencyContactName, setEmergencyContactName] = useState(null);
  const [emergencyContactNumber, setEmergencyContactNumber] = useState(null);
  const [s3Key, setS3Key] = useState(null);
  const [firstNameErr, setFirstNameErr] = useState('');
  const [lastNameErr, setLastNameErr] = useState('');
  const [dobErr, setDOBErr] = useState('');
  const [ssnErr, setSSNErr] = useState('');
  const [relationErr, setRelationErr] = useState('');
  const [phoneNumberErr, setphoneNumberErr] = useState('');
  const [altPhoneNumberErr, setAltPhoneNumberErr] = useState('');
  const [emailErr, setEmailErr] = useState('');
  const [altEmailErr, setAltEmailErr] = useState('');
  const [emergencyContactNameErr, setEmergencyContactNameErr] = useState('');
  const [emergencyContactNumberErr, setEmergencyContactNumberErr] = useState('');

  const [openPhotoUploadModal, setOpenPhotoUploadModal] = useState(false);
  const[image, setImage] = useState(null);
  const[previewUrl, setPreviewUrl] = useState(""); 
  const [isAvatarSelection, setIsAvatarSelection] = useState(false);
  const [selectedAvatarData, setSelectedAvatarData] = useState(null);
  useEffect(()=>{
    getSpecficUserProfile({id: id});
    getRelationshipList();
  },[])

  const filteredDataSelf = {};
  const filteredDataNotSelf = {};
    for (const key in relationship) {
      if (key === "self") {
        filteredDataSelf[key] = relationship[key];
      } else {
        filteredDataNotSelf[key] = relationship[key];
      }
    }

  useEffect(()=>{
    if(userInfoData && userInfoData.status === 200){
        setUser(userInfoData.data);
        const user = userInfoData.data;
        if(user){
          setUserObject(user);
          const userName =( (user?.firstName  && user?.firstName !== null  ? user?.firstName : '')  + 
                           ( user?.middleName && user?.middleName !== null ? (' '+user.middleName)  : "") +
                           (user?.lastName && user?.lastName !== null ? (' '+user.lastName ) : ""))
          
          setEmail(user?.emailId)
          setName(userName)
          setFirstName(user?.firstName)
          setLastName(user?.lastName)
          setDOB(user?.dateOfBirth || '');
          setSSN(user?.socialSecurityNo);
          setRelation(user?.relationshipCode);
          setphoneNumber(user?.phoneNo);
          setAltPhoneNumber(user?.altPhoneNo);
          setAltEmail(user?.altEmailId);
          setEmergencyContactName(user?.emergencyContactName);
          setEmergencyContactNumber(user?.emergencyContactNo);
          setImageUrlAvatar(user?.image_PreSignedUrl)
          setS3Key(user?.image)
        }
        
    }
  },[userInfoData])

  useEffect(()=>{
    if(updatedData){
      getProfileInfo();
        navigate('/my-family-members');
    }
  },[updatedData, navigate])
  useEffect(()=>{
    if(profilePictureData && profilePictureData.status === 200){
      setS3Key(profilePictureData?.data?.s3Key);
      setImageUrlAvatar(imageUrlAvatar1);
      setOpenPhotoUploadModal(false);
      setPreviewUrl('');
    }
    // if(profilePictureData && profilePictureData.status !== 200){
    // }
  },[profilePictureData, profilePictureError])

  const handleFile = file => {
    //you can carry out any file validations here...
    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    setImageUrlAvatar1(URL.createObjectURL(file))
    setImageUrlAvatar(URL.createObjectURL(file))
}
const handleOnDrop = event => {
  event.preventDefault(); 
  event.stopPropagation(); 
  //let's grab the image file
  setIsAvatarSelection(false);
  let imageFile = event.dataTransfer.files[0];
  handleFile(imageFile);
}

const handleDragOver = event => {
  event.preventDefault();
}
  const saveAvatarIcon = (data) => {
    if(isAvatarSelection){
      setS3Key(selectedAvatarData?.imageSrcKey);
      setOpenPhotoUploadModal(false);
      setPreviewUrl('');
    }else{
      let fd = new FormData();
      fd.set("userId", user.id);
      fd.set("file", image);
      fd.set("accountId", user.accountId);
      fd.set('fileTag',  'ProfilePicture')
      uploadCommonUtilityPicture(fd);
    }
  };

  const handleClose= () => {
    setOpenPhotoUploadModal(false);
    setPreviewUrl('');
    setImageUrlAvatar1('');
    setImageUrlAvatar('');
    setIsAvatarSelection(false);
    setSelectedAvatarData(null);
    setS3Key(userObject?.image)
    setImageUrlAvatar(userObject?.image_PreSignedUrl)
  };

  useEffect(() => {
      if (selectedImage) {
        setImageUrlAvatar1(URL.createObjectURL(selectedImage));
        setPreviewUrl(URL.createObjectURL(selectedImage));
        setImageUrlAvatar(URL.createObjectURL(selectedImage));
        setIsAvatarSelection(false);
      }
    }, [selectedImage]);

    const onChangeInput = (e, type) => {
      if(type === 'firstName') {
        setFirstName(e.target.value)
      } else if(type === 'lastName') {
        setLastName(e.target.value)
      } else if(type === 'dob') {
        let date = e
        if(e){
          date = moment(e).format('MM-DD-YYYY')
        }
        setDOB(date)
        setStartDate(date)
      } else if(type === 'ssn') {
        let ssnNum = formatters.isNumaric(e.target.value)
        setSSN(ssnNum);
      } else if(type === 'relation') {
        setRelation(e.target.value)
      } else if(type === 'phoneNumber') {
        setphoneNumber(e.target.value)
      } else if(type === 'altPhoneNumber') {
        setAltPhoneNumber(e.target.value)
      } else if(type === 'email') {
        setEmail(e.target.value)
      }else if(type === 'altEmail') {
        setAltEmail(e.target.value)
      } else if(type === 'emergencyContactName') {
        setEmergencyContactName(e.target.value)
      } else if(type === 'emergencyContactNumber') {
        setEmergencyContactNumber(e.target.value)
      } 
    }
    const resetErrorMessage = () => {
        setFirstNameErr('');
        setLastNameErr('');
        setDOBErr('');
        setSSNErr('');
        setRelationErr('');
        setphoneNumberErr('');
        setAltPhoneNumberErr('');
        setEmailErr('');
        setAltEmailErr('');
        setEmergencyContactNameErr('');
        setEmergencyContactNumberErr('');
    }
   
    const onSave = () => {
      let isInValid = false;
      resetErrorMessage();
    
      if (!firstName?.trim().length) {
        setFirstNameErr('This is required.');
        isInValid = true;
      }
      if (!lastName?.trim().length) {
        setLastNameErr('This is required.');
        isInValid = true;
      }
      if (!dob) {
        setDOBErr('This is required.');
        isInValid = true;
      }
      if (ssn?.length && ssn?.length !== 9) {
        setSSNErr('Enter valid SSN.');
        isInValid = true;
      }
      if (!(relation?.trim().length)) {
        setRelationErr('This is required.');
        isInValid = true;
      }
      if (!phoneNumber?.trim().length) {
          setphoneNumberErr('This is required.');
          isInValid = true;
      }  else if (phoneNumber.length !== 10) {
          setphoneNumberErr('Enter valid Phone Number.');
          isInValid = true;
      }
      // if (!altPhoneNumber?.trim().length) {
      //     setAltPhoneNumberErr('This is required.');
      //     isInValid = true;
      // }  else if (altPhoneNumber.length !== 10) {
      //   setAltPhoneNumberErr('Enter valid Alternate Phone Number.');
      //   isInValid = true;
      // }
      if (!email?.trim().length) {
          setEmailErr('This is required.');
          isInValid = true;
      } else if (!formatters.isEmailValid(email)) {
          setEmailErr('Enter valid Email.');
          isInValid = true;
      }
      // if (!altEmail?.trim().length) {
      //   setAltEmailErr('This is required.');
      //   isInValid = true;
      // } else if (!formatters.isEmailValid(altEmail)) {
      //     setAltEmailErr('Enter valid Alternate Email.');
      //     isInValid = true;
      // }
      // if (!emergencyContactName?.trim().length) {
      //   setEmergencyContactNameErr('This is required.');
      //   isInValid = true;
      // }
      // if (!emergencyContactNumber?.trim().length) {
      //   setEmergencyContactNumberErr('This is required.');
      //   isInValid = true;
      // }  else if (emergencyContactNumber.length !== 10) {
      //   setEmergencyContactNumberErr('Enter valid Emergency Contact Number.');
      //   isInValid = true;
      // }
      if(isInValid) {
        return;
      }
      const payload = {
        firstName: firstName,
        lastName: lastName,
        dateOfBirth: dob,
        socialSecurityNo: ssn, 
        relationshipCode: relation,
        phoneNo: phoneNumber,
        // altPhoneNo: altPhoneNumber,
        email: email,
        // altEmailId: altEmail,
        image: s3Key,
        // emergencyContactName: emergencyContactName,
        // emergencyContactNo: emergencyContactNumber,
      }
      const finalObject = {...user,...payload};
      editUser(finalObject)
    };
  if(isFetchingUserInfo) {
    return;
  }

  return (
    <Grid container  style={{margin:0, width: '100%'}}>
      {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
      <div className="myfamily-container family-edit-form-align">
      <Grid container
        item 
        xs={12}
        md={12}
        lg={12}
        className="box-container myfamily-edit-form "
      >
        <Grid container  style={{margin:0}}>
            <Grid item xs={12} md={6} className="left-box">
                <div style={{position: 'relative'}}>
                    {imageUrlAvatar ? (
                      <img src={imageUrlAvatar} alt='UserAvatar' className='avatar-upload-icon' />
                    ) :(
                      <>
                      {!selectedImage && !imageUrlAvatar &&  (
                         <img src={UserAvatar} alt='UserAvatar' className='avatar-upload-icon' />
                     )}
                     </>
                    )}
                   
                    <input
                    accept="image/*"
                    type="file"
                    id="select-image"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      setImage(e.target.files[0]);
                      setSelectedImage(e.target.files[0])
                    }}
                    />
                    {/* <label htmlFor="select-image"> */}
                        <div style={{position: 'absolute',right: 0, top: 20, cursor: 'pointer'}} onClick={()=> {setOpenPhotoUploadModal(true)}}>
                            <img src={Edit} alt='edit'/>
                        </div>
                    {/* </label> */}
                </div>
                <div className="input-container-btm">
                  <div>
                      <Typography className='font-normal-small error-message'>{firstNameErr}</Typography>
                      <Typography className='font-normal-small profile-input-label'>{t("First Name")}<span style={{color:"red"}}>{" *"}</span> </Typography>
                      <input className={`profile-input ${firstNameErr !== '' ? 'err-input' : ''}`}  aria-label='first name'  onChange={(e)=> {onChangeInput(e, 'firstName')}} value={firstName}/>
                  </div>
                  <div>
                      <Typography className='font-normal-small error-message'>{lastNameErr}</Typography>
                      <Typography className='font-normal-small profile-input-label'>{t("Last Name")}<span style={{color:"red"}}>{" *"}</span> </Typography>
                      <input className={`profile-input ${lastNameErr !== '' ? 'err-input' : ''}`}  aria-label='last name'  onChange={(e)=> {onChangeInput(e, 'lastName')}} value={lastName}/>
                  </div>
                </div>
            </Grid>
            <Grid item xs={12} md={6} style={{paddingLeft: 30, paddingRight: 32}}>
                <div>
                    <Typography className='font-normal-small error-message'>{dobErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>{t("Date of Birth")}<span style={{color:"red"}}>{" *"}</span></Typography>
                    <DatePicker  minDate={new Date(1900, 0, 1)} className={`profile-input ${dobErr !== '' ? 'err-input' : ''}`}  onChange={(e)=> {onChangeInput(e, 'dob')}} value={dob} maxDate={new Date()} />
                </div>
                <div>
                    <Typography className='font-normal-small error-message'>{ssnErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>{t("Social Security Number")} </Typography>
                    <input className={`profile-input ${ssnErr !== '' ? 'err-input' : ''}`}  aria-label='ssn'  onChange={(e)=> {onChangeInput(e, 'ssn')}} maxLength={11} value={ssn ? formatters.formatSSNNumber(ssn): ssn}/>
                </div> 
                <div> 
                    <Typography className='font-normal-small error-message'>{relationErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>{t("Relationship with Plan Owner")}<span style={{color:"red"}}>{" *"}</span></Typography>
                    <FormControl style={{marginRight: 0, marginLeft:0, marginTop: 0, marginBottom: 20, width: '100%'}}>
                      <Select
                        labelId="demo-simple-select-label"
                        className="relation-select relationship-dropdown"
                        label="Relation"
                        value={(value) => {
                          return (
                            <Box sx={{ display: "flex", gap: 1 }}>
                              {relation}
                            </Box>
                          );
                        }}
                        onChange={(e)=> {onChangeInput(e, 'relation')}}
                        renderValue={(value) => {
                          return (
                            <Box sx={{ display: "flex", gap: 1 }}>
                              {relationship[relation]}
                            </Box>
                          );
                        }}
                
                      >
                        {
                          Object.keys(relation === 'self' ? filteredDataSelf : filteredDataNotSelf).map((item) => {
                            return ((relation === item || userDetails.userRoleCode === "admin" ||userDetails.userRoleCode === "owner") && <MenuItem  value={item}>{relationship[item]}</MenuItem>)
                            })
                        }
                      </Select>
                    </FormControl>
                    {/* <input className={`profile-input ${relationErr !== '' ? 'err-input' : ''}`}  aria-label='relation'  onChange={(e)=> {onChangeInput(e, 'relation')}} value={relation}/> */}
                </div>
                <div>
                <Typography className='font-normal-small error-message'>{phoneNumberErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>{t("Phone Number")}<span style={{color:"red"}}>{" *"}</span></Typography>
                    <input className={`profile-input ${phoneNumberErr !== '' ? 'err-input' : ''}`} aria-label='phone number' onChange={(e)=> {onChangeInput(e, 'phoneNumber')}}  maxLength={10} value={formatters.formatPhoneNumber(phoneNumber)}/>
                </div>
                {/* <div>
                <Typography className='font-normal-small error-message'>{altPhoneNumberErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>{t("Alternate Phone Number")}</Typography>
                    <input className={`profile-input ${altPhoneNumberErr !== '' ? 'err-input' : ''}`} aria-label='alternate phone number' onChange={(e)=> {onChangeInput(e, 'altPhoneNumber')}}  maxLength={10} value={formatters.formatPhoneNumber(altPhoneNumber)}/>
                </div> */}
                <div>
                <Typography className='font-normal-small error-message'>{emailErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>{t("Email ID")}<span style={{color:"red"}}>{" *"}</span></Typography>
                    <input className={`profile-input ${emailErr !== '' ? 'err-input' : ''}`}  aria-label='email'  onChange={(e)=> {onChangeInput(e, 'email')}} value={email} disabled/>
                </div>
                {/* <div>
                <Typography className='font-normal-small error-message'>{altEmailErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>{t("Alternate Email ID")}</Typography>
                    <input className={`profile-input ${altEmailErr !== '' ? 'err-input' : ''}`}  aria-label='alternate email'  onChange={(e)=> {onChangeInput(e, 'altEmail')}} value={altEmail}/>
                </div> */}
                {/* <div>
                <Typography className='font-normal-small error-message'>{emergencyContactNameErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>{t("Emergency Contact Name")}</Typography>
                    <input className={`profile-input ${emergencyContactNameErr !== '' ? 'err-input' : ''}`} aria-label='Emergency contact name' onChange={(e)=> {onChangeInput(e, 'emergencyContactName')}}  maxLength={10} value={emergencyContactName}/>
                </div>
                <div>
                <Typography className='font-normal-small error-message'>{emergencyContactNumberErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>{t("Emergency Contact Number")}</Typography>
                    <input className={`profile-input ${emergencyContactNumberErr !== '' ? 'err-input' : ''}`} aria-label='Emergency contact number' onChange={(e)=> {onChangeInput(e, 'emergencyContactNumber')}}  maxLength={10} value={formatters.formatPhoneNumber(emergencyContactNumber)}/>
                </div> */}
                
            </Grid>
            <div className="action-holder action-holder-3 btn-holder">
                <Button variant="outlined" className="primary-font-bold-6  profile-cancel-btn btn-width" onClick={()=> {navigate('/my-family-members')}}>{t("Cancel")}</Button>
                <Button variant="outlined" className="primary-font-bold-6  profile-save-btn btn-width" onClick={()=> {onSave()}}>{t("Save")}</Button>
            </div>
        </Grid>
        {openPhotoUploadModal && (
          <Dialog
          open={openPhotoUploadModal}
          onClose={handleClose}
          className="photo-dialog dialog-box"
          aria-labelledby="photo-dialog-title"
          aria-describedby="photo-dialog-description"
          >
              <DialogTitle id="photo-dialog-title">
                  <Typography className='primary-font-bold-heading2'>{t("Photo upload")}</Typography>
              {openPhotoUploadModal ? (
                  <img src={CloseIcon} alt="close-icon" style={{cursor: 'pointer',width:"21px"}} onClick={handleClose} sx={{
                      marginRight: '-30px',
                  }}/>
                  ) : null}
              </DialogTitle>
              <Divider />
              <DialogContent className="settings-profile-add-dialog-content" >
              <Grid container spacing={2} style={{margin:0, height: '100%' ,width:'100%'}}  >
                <Grid item xs={12} md={!dob ? 7 : dob && (formatters.calculateAge(dob) > 17 ) ? 7 : 12} style={{padding: 0, height: '100%', overflowY: 'scroll'}}>
                  <Typography className='primary-font-medium profile-input-label' style={{textAlign:"center"}}>{t("Choose Avatar")}</Typography>
                  <div>
                    {AvatarIcon.map((item, i)=> {
                      return (
                       <div className='avatar-photo-icon' 
                       onClick={(e)=> {
                        setImageUrlAvatar1(item.imgSrc); 
                        setPreviewUrl(item.imgSrc);
                        setIsAvatarSelection(true)
                        setSelectedAvatarData(item)
                        setImageUrlAvatar(item.imgSrc)
                      }}> 
                        <Button><img  src={item.imgSrc} alt={item.imgAlt} /></Button> 
                      </div>
                      )
                    })}
                  </div>
                </Grid>
                {!dob || (dob && (formatters.calculateAge(dob) > 17)) && (
                <Grid item xs={12} md={5} style={{paddingLeft: 0}}>
                  <label htmlFor="select-image"> 
                    <div className="photo-upload-container drop_zone" onDragOver={handleDragOver} onDrop={handleOnDrop}>
                    <img  src={UploadImage} alt={'upload-avatar'} style={{cursor: 'pointer'}} />
                    <Typography className='font-normal-small profile-input-label'>{t("Drag and drop a file here or click")}</Typography>
                    { previewUrl && <div className="image">
                      <img src={previewUrl} alt='image' width={100} height={100}/> 
                    </div> }
                  </div>
                  </label>
                </Grid>
                )}
              </Grid>
              </DialogContent>
              <Divider />
              <DialogActions>
              <div className="action-holder" style={{marginTop: 20, marginBottom: 0}}>
                  <Button variant="outlined" className="primary-font-bold-6 btn-width profile-cancel-btn" onClick={handleClose}>{t("Cancel")}</Button>
                  <Button variant="outlined" className="primary-font-bold-6 btn-width profile-save-btn" onClick={saveAvatarIcon}>{t("Upload")}</Button>
              </div>
              </DialogActions>
          </Dialog>
        )}
      </Grid>
      </div>
      {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
    </Grid>
  );
};


EditProfilePage.defaultProps = {
    isFetchingLogin: null,
    isFetchingRegister: null,
    isFetchingUserInfo: null,
    isUpdatingUserInfo:  null
  };
  
  function mapStateToProps(state) {
    return {
    userInfoData: state.setting.userInfoData,
    isFetchingLogin: state.login.isFetching,
    isFetchingUserInfo: state.setting.isFetchingUserInfo,
    updatedData: state.setting.updatedData,
    isUpdatingUserInfo: state.setting.isUpdatingUserInfo,
    profilePictureData: state.setting.profilePictureData,
    profilePictureError: state.setting.profilePictureError,
    relationship: state.setting.relationship
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        getSpecficUserProfile,
        getProfileInfo,
        getRelationshipList,
        editUser,
        uploadCommonUtilityPicture
      },
      dispatch
    );
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditProfilePage);