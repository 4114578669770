import React, { useState, useEffect } from "react";
import "../../../../../../index.css";
import { Grid, TextField, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';


const PurchaseInfo = ({onBackOrCancelClick, handleNext}) => {
    const initialFormData = {
        purchasePrice:'',
        placeOfPurchase:'',
        dateOfPurchase:''
    };
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState(initialFormData);
    const [startDate, setStartDate] = useState(new Date());

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
    };
    
    const handleDateChange = (date) => {
      setFormData({ ...formData, ['dateOfPurchase']: date });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const newErrors = {};

        if (formData.purchasePrice.trim() === '') {
          newErrors.purchasePrice = 'Purchase Price is required.';
        }
        if (formData.placeOfPurchase.trim() === '') {
          newErrors.placeOfPurchase = 'Place of Purchase is required.';
        }
        if (formData.dateOfPurchase === '' || !formData.dateOfPurchase) {
          newErrors.dateOfPurchase = 'Date of Purchase is required.';
        }
    
        if (Object.keys(newErrors).length === 0) {
          console.log(formData);
          setErrors({});
          handleNext();
        } else {
          setErrors(newErrors);
          console.log('Validation errors:', newErrors);
        }
      };
  return (
    <Grid container spacing={2} style={{margin:0, padding: 32, paddingTop: 0, width: '100%', justifyContent: 'start'}}>
        <Grid item xs={12} md={12} sx={{padding: 0}}>
            <div style={{padding: 20}}>
             <div className="flex-boxes" style={{display:'flex',justifyContent:'space-between'}}>
                 <div className="full-width">
                    <Typography className='font-normal-small error-message'>{errors.purchasePrice}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Purchase Price</Typography>
                    <input id="purchasePrice" name="purchasePrice" className={`profile-input ${errors.purchasePrice ? 'err-input' : ''}`} aria-label='purchasePrice' onChange={handleInputChange}  value={formData.purchasePrice}/>
                 </div>
                 <div className="full-width" >
                        <Typography className='font-normal-small error-message'>{errors.placeOfPurchase}</Typography>
                        <Typography className='font-normal-small profile-input-label'>Place of Purchase </Typography>
                        <input  name="placeOfPurchase" id="placeOfPurchase" className={`profile-input ${errors.placeOfPurchase ? 'err-input' : ''}`}  aria-label='placeOfPurchase'  onChange={handleInputChange} value={formData.placeOfPurchase}/>
                 </div>
             </div>
                <div  className="flex-boxes" style={{marginLeft: 0, display:'flex'}}> 
                   
                    <div style={{width:'500px'}}>
                        <Typography className='font-normal-small error-message'>{errors.dateOfPurchase}</Typography>
                        <Typography className='font-normal-small profile-input-label'>Date of Purchase</Typography>
                        {/* <input  name="dateOfPurchase" id="dateOfPurchase" className={`profile-input ${errors.dateOfPurchase ? 'err-input' : ''}`} aria-label='dateOfPurchase' onChange={handleInputChange} value={formData.dateOfPurchase}/> */}
                        <DatePicker name="dateOfPurchase" id="dateOfPurchase" className={`profile-input ${errors.dateOfPurchase  ? 'err-input' : ''}`}  aria-label='dateOfPurchase'  onChange={handleDateChange} value={formData.dateOfPurchase}/>

                    </div>
                    
                </div>
            </div>
      </Grid>
      <div className="action-holder" style={{marginTop: 0, marginBottom: 50}}>
        <Button variant="outlined"  className="primary-font-bold-6 action-btn profile-cancel-btn" onClick={onBackOrCancelClick}>Back</Button>
        <Button variant="outlined" className="primary-font-bold-6 action-btn profile-save-btn"  onClick={handleSubmit}>Save</Button>
      </div>
    </Grid>
  );
};

export default PurchaseInfo;