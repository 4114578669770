
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import { Grid, Typography, Checkbox, CardContent, Card, CardActions, Button } from "@mui/material";
import RemoveDialog from "../../../common/RemoveDialog";
import MeetingLocationMap from "../MeetingLocations/MeetingLocationMap";
import Radio from '@mui/material/Radio';


import { getAllMeetingLocationAPI, deleteMeetingLocationAPI } from '../../redux/actions';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import EditMeetingLocation from './EditMeetingLocation';
import constants from '../../../../../config/constants';
import AddMeetingLocation from './AddMeetingLocation';

const StyledCard = styled(Card)({
  variant: "outlined",
  // maxWidth: 450,
  backgroundColor: '#F6F6F6',
  height: 75,
  // border: '1px solid #D8D8D8',
  borderRadius: 12,
  boxShadow: 'none',
  padding: 0,
}
);

const defaultLocations = [
  { name: "In Town Meeting Location", isAdd: true, locationLat: 0, locationLong: 0 },
  { name: "Out of Town Meeting Location", isAdd: true, locationLat: 0, locationLong: 0 }
]

const MeetingLocations = ({ showDelete = true, showEdit = true, onBackOrCancelClick, handleNext, Crud, getAllMeetingLocationAPI, meetingLocationList, deleteLocation, deleteMeetingLocationAPI, emergencyAlert = false ,addEmergencyAlert}) => {
  const [selectedMeetingId, setSelectedMeetingId] = useState(null);
  const [meetingList, setMeetingList] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [editMeetingLocation, setEditMeetingLocation] = useState(false);
  const dispatch = useDispatch();
  const [addLocationDialogOpen, setAddLocationDialogOpen] = useState(false);
  const [value, setValue] = React.useState();
  const [meetingLocation, setMeetingLocation] = useState(null);

  const handleChange = (event) => {
    if(event.target.value === "current-location"){
      if(event.target.value === value){
        setValue()
        resetLocation()
      }else{
        setValue((event.target.value));
        setLocation(event.target.value)
      }
    }else{
      if(Number(event.target.value) === value){
        setValue()
        resetLocation()
      }else{
        setValue(Number(event.target.value));
        setLocation(Number(event.target.value))
      }
    }
  };

  const setLocation = (location) => {
    const newData = {...addEmergencyAlert}
    const selectedRowsDetails = meetingList.filter((row,i) =>
        row.id === location
    );
    console.log("selectedRowsDetails",selectedRowsDetails,"location",location)
    newData.location = selectedRowsDetails[0];
    dispatch({
      type: "ADD_EMERGENCY_ALERT",
      payload: newData
    })
  }

  const resetLocation = () => {
       let newData = {...addEmergencyAlert}
       if(newData.location){
        delete newData.location
       }
          dispatch({
            type: "ADD_EMERGENCY_ALERT",
            payload: newData
          })
  }
  
  const [currentLocation, setCurrentLocation] = useState({
    loaded: false,
    position: { lat: "", lng: "" }
  })

  const onSuccess = async location => {
    if(location && location.coords){

      let latitude = location?.coords?.latitude ? location?.coords?.latitude : null;
      let longitude = location?.coords?.longitude ? location?.coords?.longitude : null;

      if(latitude && typeof latitude !== Number){
        latitude =  parseFloat(latitude)
      }
      if(longitude && typeof longitude !== Number){
        longitude =  parseFloat(longitude)
      }
      if(currentLocation && !currentLocation.loaded){
        const newData = {
          loaded: true,
          name: "Current Location",
          locationLat: location?.coords?.latitude,
          locationLong: location?.coords?.longitude,
          position: { lat: location?.coords?.latitude, lng: location?.coords?.longitude },
          id: "current-location"
        }
        // setCurrentLocation(newData)
        try {
          const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${location?.coords?.latitude},${location?.coords?.longitude}&key=${constants.GOOGLE_MAPS_API_KEY}`);
          console.log(response,"response")
          const json = await response.json();
          console.log("response", json)
          newData.address = json.results[0]?.formatted_address;
    
          if(meetingList && meetingList.length > 0){
            const locationData = meetingList[0];
            if(locationData.id !== 'current-location'){
              const newLocation = [];
              newLocation.push(newData);
              panToLocation(newData);
              console.log('I am in state1')
              const unique = [...meetingList]
              const meetLocation = newLocation.concat(unique)
              setMeetingList(meetLocation);
            }
          }
          setCurrentLocation(newData)
        } catch (error) {
          console.error(error);
        }
      }
    
    }

    // const result = await getGeocode({lat:location?.coords?.latitude, lng:location?.coords?.longitude})
    // console.log(result,"on success")
  };

  const onError = error => {
    setCurrentLocation({
      loaded: false,
      error
    })
  }
  useEffect(() => {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onSuccess, onError)
    }
  })

  const [selectedLocation, setSelectedLocation] = useState({
    position: null,
    zoom: 18, // Default zoom level
    selectedObject: null
  });
  const panToLocation = (data) => {
    let { locationLat, locationLong, zoom } = data;
    if(locationLat && locationLong){
      
    if (locationLat) {
      locationLat = parseFloat(locationLat)
    }
    if (locationLong) {
      locationLong = parseFloat(locationLong)
    }
    setSelectedLocation({
      position: { lat: locationLat, lng: locationLong },
      zoom: 16 || 16, // Use the provided zoom level or default to 16
      selectedObject: data
    });
    }
  };

  const defaultCenter = {
    lat: 37.09024, // Latitude
    lng: -95.712891, // Longitude
  };

  useEffect(() => {
    console.log("I am here for calling api")
    getAllMeetingLocationAPI();
  }, [])

  const onCloseMessageDialog = (refresh) => {
    setAddLocationDialogOpen(false);
    if (refresh && refresh === true) {
      getAllMeetingLocationAPI()
    }
  };

  
  useEffect(()=>{
    if(addEmergencyAlert && addEmergencyAlert?.location){
      console.log("addEmergencyAlert Location", addEmergencyAlert?.location)
      if(addEmergencyAlert?.location?.id && addEmergencyAlert?.location?.id !== 'current-location'){
        setValue(Number(addEmergencyAlert?.location?.id))
        const location  = addEmergencyAlert?.location;
        setMeetingLocation(location)
      }else if(addEmergencyAlert?.location?.id ==='current-location' || addEmergencyAlert?.location?.isCurrentLocation){
        // setValue('current-location');
        const location  = addEmergencyAlert?.location;
        setMeetingLocation(location)
      }else{
        setMeetingLocation(null)
      }
    }else{
      setMeetingLocation(null)
    }

  },[addEmergencyAlert])

  useEffect(() => {

    let finalObject = [];
    let newArray = [];
    if (meetingLocationList && meetingLocationList.status === 200) {
      finalObject = meetingLocationList.data;
      if(emergencyAlert === true){
        newArray = [...finalObject]
      }else{
        newArray = [...finalObject, ...defaultLocations]
      }
      
      const unique = newArray.filter((obj, index) => {
        return index === newArray.findIndex(o => obj.name === o.name);
      });
      console.log(newArray.length)
      console.log(unique.length)
      if (currentLocation.loaded) {
        const newData = [
          currentLocation
        ]
        panToLocation(currentLocation)
        console.log('I am in state1')
        const meetLocation = newData.concat(unique)
        setMeetingList(meetLocation);
      } else {
        console.log([...unique], 'I am in state11')
        setMeetingList(unique);
        panToLocation(meetingLocationList.data[0])
      }
      dispatch({
        type: "RESET_LOCATION_API_DATA",
        payload: null
      })
    } else if (meetingLocationList && meetingLocationList.status === 404) {
      if (currentLocation.loaded) {
        const newData = [
          currentLocation
        ]
        panToLocation(currentLocation)
        if(emergencyAlert){
          const meetLocation = newData;
          setMeetingList(meetLocation);
        }else{
          const meetLocation = newData.concat(defaultLocations)
          setMeetingList(meetLocation);
        }
        
      } else {
        if(emergencyAlert){
          setMeetingList([])
        }else{
          setMeetingList([...defaultLocations]);
        }
        
      }
      dispatch({
        type: "RESET_LOCATION_API_DATA",
        payload: null
      })
    }
  }, [meetingLocationList])

  useEffect(() => {
    if (deleteLocation && deleteLocation.status === 200) {
      handleDialogClose(true);
      dispatch({
        type: "RESET_LOCATION_API_DATA",
        payload: null
      })
    }
  }, [deleteLocation])

  const handleRemoveMessage = () => {
    deleteMeetingLocationAPI(selectedCard)
  }

  const handleEditDialogClose = (refresh) => {
    setEditMeetingLocation(false);
    setSelectedCard(null);
    if (refresh && refresh === true) {
      getAllMeetingLocationAPI()
    }
  };

  const handleDialogClose = (refresh) => {
    setRemoveDialogOpen(false);
    setSelectedCard(null);
    if (refresh && refresh === true) {
      getAllMeetingLocationAPI()
    }
  };

  const handleAlertNext = () => {
    // if(value === 0 || value){
    //   const newData = {...addEmergencyAlert}
    //   const selectedRowsDetails = meetingList.filter((row,i) =>
    //      row.id === value
    //   );
    //   newData.location = selectedRowsDetails[0];
    //   console.log("location data",newData)
    //   dispatch({
    //     type: "ADD_EMERGENCY_ALERT",
    //     payload: newData
    //   })
    //   handleNext()
    // }else{
    //   const newData = {...addEmergencyAlert}
    //   newData.location = null;
    //   dispatch({
    //     type: "ADD_EMERGENCY_ALERT",
    //     payload: newData
    //   })
    //   handleNext()
    // }
    handleNext()
  }
  return (


    <Grid container spacing={2} style={{ width: '100%', margin: 0 }}>
<div  className={ emergencyAlert === true ? "location-container-2": "location-container"  }>
      <Grid
        container
        xs={12}
        md={12}
        lg={12}
       className={emergencyAlert === true ? "" : 'box-container'}
        // style={{ justifyContent: 'center', alignItems: 'center'}}
      >
        <div style={{ width: "100%" }} className='box-header'>
          <Typography className='primary-font-bold-2-heading'>Meeting Location</Typography>
          <Button variant="outlined" className="primary-font-bold-6 action-btn-yes profile-save-btn" onClick={() => { setAddLocationDialogOpen(true) }}>Add</Button>
        </div>
        <Grid container className='location-card'  style={{ margin: 0, width: '100%' }}>
          <Grid className='location-card-content' xs={12} md={5} >
             
            {meetingLocation && (
                <div  style={{ marginBottom: '15px',}}>
                  <StyledCard   id='card-location-meeting' style={{ height: "90px", backgroundColor: "#ff00003d"  }}>
                    <CardContent style={{ display: 'flex', padding: "5px",justifyContent:"space-between" }} marginBottom={3} >
                      <div className="location-meeting-content"onClick={() => { panToLocation(meetingLocation) }} >
                        <Grid item container
                          // sx={{ padding: '10px', mx: "10px", my: "5px", border: "1px solid #D8D8D8", borderRadius: "10px" }}
                        >
                          <>
                      
                            <Typography
                              className='primary-font-medium'
                              style={{
                                display: '-webkit-box',
                                overflow: "hidden",
                                webkitBoxOrient: "vertical",
                                textOverflow: "ellipsis",
                                webkitLineClamp: "1",
                                cursor: "pointer",
                                paddingLeft:"40px",
                                paddingBottom:"5px"
                              }}>
                              {"Meeting Location"}
                            </Typography>
                            <Typography className="font-normal-small profile-input-label" style={{
                              display: '-webkit-box',
                              overflow: "hidden",
                              webkitBoxOrient: "vertical",
                              textOverflow: "ellipsis",
                              webkitLineClamp: "2",
                              cursor: "pointer",
                              paddingLeft:emergencyAlert === true ? "40px": "15px",
                              paddingBottom:"5px"
                            }}>
                              {((meetingLocation.address ? meetingLocation.address + ', ' : "") + (meetingLocation.city ? meetingLocation.city + ', ' : '') + (meetingLocation.stateCode ? meetingLocation.stateCode + ', ' : '') + (meetingLocation.country ? meetingLocation.country : ''))}
                            </Typography>
                          </>
                        </Grid>
                      </div>
                    </CardContent>

                  </StyledCard>
                </div>
            )}
            {meetingList && meetingList.map((meeting, i) => (
              ((i === 0 || i) ? (
                <div key={i} style={{ marginBottom: '15px' }}>
                  <StyledCard style={{ height: emergencyAlert === true ? "115px" :"90px" }}>
                    <CardContent style={{ display: 'flex', padding: "5px" }} marginBottom={3} >
                      <div onClick={() => { panToLocation(meeting) }} style={{ width: "75%" }}>
                        <Grid item container
                          key={i}
                          // sx={{ padding: '10px', mx: "10px", my: "5px", border: "1px solid #D8D8D8", borderRadius: "10px" }}
                        >
                          <>
                          <div  className={emergencyAlert === true ? "emergency-alert-location-header" : ""} >
                            {emergencyAlert === true && (

                              <Radio
                                checked={value === meeting?.id}
                                onClick={handleChange}
                                value={meeting?.id}
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'A' }}
                                sx={{  '&, &.Mui-checked': {
                                  color: 'red',
                                }, justifyContent: "flex-start", display: "block", padding:"0px" }}
                              />)}
                            <Typography
                              className='primary-font-medium'
                              style={{
                                display: '-webkit-box',
                                overflow: "hidden",
                                webkitBoxOrient: "vertical",
                                textOverflow: "ellipsis",
                                webkitLineClamp: "1",
                                cursor: "pointer",
                                paddingLeft:"15px",
                                paddingBottom:"5px"
                              }}>
                              {meeting.name}
                            </Typography>
                            </div>
                            <Typography className="font-normal-small profile-input-label" style={{
                              display: '-webkit-box',
                              overflow: "hidden",
                              webkitBoxOrient: "vertical",
                              textOverflow: "ellipsis",
                              webkitLineClamp: "2",
                              cursor: "pointer",
                              paddingLeft: emergencyAlert === true ? "40px" :"15px"
                            }}>
                              {((meeting.address ? meeting.address + ', ' : "") + (meeting.city ? meeting.city + ', ' : '') + (meeting.stateCode ? meeting.stateCode + ', ' : '') + (meeting.country ? meeting.country : ''))}
                            </Typography>
                          </>
                        </Grid>
                      </div>
                      <CardActions style={{ display: 'flex', padding: '10', alignItems: "flex-start" }}>
                        {((i === 0 && meeting.name === "Current Location") || meeting.isAdd === true || emergencyAlert === true) ? <div className="inactive-button-dlt"></div> : <div className="action-button-dlt" onClick={() => { setSelectedCard(meeting); setRemoveDialogOpen(true); }}></div>}
                        {(i === 0 && meeting.name === "Current Location" || emergencyAlert === true) ? <div className="inactive-button-edit" ></div> : <div className="action-button-edit" onClick={() => { console.log(meeting); setSelectedCard(meeting); setEditMeetingLocation(true); }} ></div>}
                      </CardActions>
                    </CardContent>

                  </StyledCard>
                </div>) : null)
            ))}
          </Grid>
          <Grid className='meeting-location' xs={12} md={7}>
            <div>
              <MeetingLocationMap defaultCenterlatlng={defaultCenter} MarkerLocationList={meetingList} selectedLocation={selectedLocation.position} zoom={selectedLocation.zoom} onSelectLocation={panToLocation} selectedObject={selectedLocation.selectedObject} showCustomMarker={false} />
            </div>
          </Grid>
          {Crud ? <div className="action-holder" style={{ marginTop: 40, marginBottom:emergencyAlert === true ? 0: 50 }}>
            <Button variant="outlined" className="primary-font-bold-6 action-btn profile-cancel-btn" onClick={onBackOrCancelClick}>Back</Button>
            <Button variant="outlined" className="primary-font-bold-6 action-btn profile-save-btn" onClick={handleAlertNext}>Next</Button>
          </div> : ""}
          {removeDialogOpen && (
            <RemoveDialog
              openDialog={removeDialogOpen}
              onClose={handleDialogClose}
              cardContent={"Are you sure to delete?"}
              onSave={handleRemoveMessage}
            />
          )}
          {editMeetingLocation && (
            <EditMeetingLocation
              openDialog={editMeetingLocation}
              onClose={handleEditDialogClose}
              selectedCard={selectedCard}
            />
          )}
        </Grid>
      </Grid>
      </div>
      {addLocationDialogOpen && <AddMeetingLocation openDialog={addLocationDialogOpen} onClose={onCloseMessageDialog} />}
    </Grid>

  );
};


function mapStateToProps(state) {
  return {
    meetingLocationList: state.setting.meetingLocationList,
    deleteLocation: state.setting.deleteLocation,
    addEmergencyAlert: state.emergencyAlert.addEmergencyAlert

  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAllMeetingLocationAPI,
      deleteMeetingLocationAPI
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MeetingLocations);
