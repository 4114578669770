import React from 'react';
import ContentWrapper from '../../../../common/components/ContentWrapper';
import BillingAddressPage from './BillingAddressPage';
const BillingAddress = () => {
  return (
    <>
      <ContentWrapper title={"My Subscription"} gobackUrl={''} img={false} contenview={<BillingAddressPage/>} />
    </>
  )
}

export default BillingAddress;