import { Auth } from 'aws-amplify';
import axios from './axios';

 export const deleteBase = async (url,payload) => {
  try {
    const session = window.localStorage.getItem('tokendetails');
    const tokenData = JSON.parse(session);
    const headers = {
      Authorization: `Bearer ${tokenData?.idToken?.jwtToken}`,
      "Access-Control-Allow-Headers" : "*",
      "Access-Control-Allow-Origin": "*",
      "Accept": "application/json"
    };

    const config = {
      method : "delete",
      url :  url,
      headers: headers
    }
    if(payload){
      config.data = payload
    }
    return await axios.request(config);
  } catch (e) {
    if (!e || !e.response || e.response.status !== 401) throw e;
    localStorage.setItem('url', `${window.location.pathname}${window.location.search}`);
    try {
      const { config } = e;
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();

      return new Promise((resolve, reject) => {
        authenticatedUser.refreshSession(currentSession.refreshToken, (err, session) => {
          if (session) {

            window.localStorage.setItem('tokendetails', JSON.stringify(session));

            const headers = {
              Authorization: `Bearer ${session?.idToken?.jwtToken}`,
              "Access-Control-Allow-Headers" : "*",
              "Access-Control-Allow-Origin": "*",
              "Accept": "application/json"
            };
            config.headers = headers;

            console.log(e.config,"e.config")
            axios.request(e.config).then((response) => {
              resolve(response);
            }).catch((error) => {
              reject(error);
            });
          }
        });
      });

    } catch (err) {
      return null;
    }
  }
};
