import React, { useState, useEffect, useContext, useMemo,useRef } from "react";
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails,Button, Dialog,
    DialogContent } from "@mui/material";
import ContentWrapper from "../../../../ContentWrapper";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import { getLiveSharing, singleStopTracking } from "../../../redux/actions";
import { UserGeolocationContext, UserInfoDetails } from "../../../../../../utils/userContext";
import LiveTrackingMeetingLocation from "../../EmergencyAlert/components/LiveTrackingMeetingLocation";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import PlusIcon from "../../../../../../assets/icons/plus-icon.svg"
import MinusIcon from "../../../../../../assets/icons/minus-icon.svg"
import"../styles/TrackMe.css";


const MainLayout = ({ requestView,singleStopTracking,isRefreshed }) => {
    console.log("requestView", requestView?.myLocationSharedWithOthers.length);
    const { userObject } = useContext(UserInfoDetails)
    const userLocation = useContext(UserGeolocationContext);
    const currentLocation = { ...userLocation, ...userObject };
    const [expanded, setExpanded] = useState(true)
    const [expanded2, setExpanded2] = useState(true);
    const navigate = useNavigate();
    const [selectedLiveLocation, setSelectedLiveLocation] = useState({
        position: null,
        zoom: 5, // Default zoom level
        selectedObject: null
      });

    useEffect(() => {
        if (requestView?.myLocationSharedWithOthers && requestView?.myLocationSharedWithOthers.length === 0) {
            localStorage.setItem("isTrackingEnabled", false);
            if(isRefreshed){
                navigate("/Track-me")
            }
        }
    }, [requestView])

    const handleStop = (stopObject) => {
        const payload = {
            "trackingType":"trackMe",
            "userId": stopObject.userId,
            "trackMeId": stopObject.trackMeId,
            "updatedBy":userObject.id
          }
          singleStopTracking(payload)

    }
        
  const panToLiveLocation = (data, zoomLevel) => {
    if (data) {
      let { locationLat, locationLong } = data;
      if (locationLat && locationLong) {
        if (locationLat) {
          locationLat = parseFloat(locationLat);
        }
        if (locationLong) {
          locationLong = parseFloat(locationLong);
        }
        setSelectedLiveLocation({
          position: { lat: locationLat, lng: locationLong },
          zoom: zoomLevel || 16, // Use the provided zoom level or default to 16
          selectedObject: data,
        });
      }
    }
  };



    return (
        <Grid container spacing={2} style={{ margin: 0, width: "100%" }}>
            {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
            <Grid className="track-me-container">
            <Grid
                container
                xs={12}
                md={12}
                lg={12}
                className="box-container"
                
            >
                <Grid container style={{ width: "100%", margin: 0 }}>
                    <Grid item xs={12} md={12} lg={12}>

                        <br />
                        <Grid container  style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <Grid item xs={12} md={6} lg={6} className="map-container">
                                <div id="meeting-lcoation" style={{ marginTop: "10px", height:"500px" }}>
                                    <LiveTrackingMeetingLocation membersList={requestView?.locationsSharedWithMe} meetingLocation={currentLocation} selectedLiveLocation={selectedLiveLocation}  onSelectLocation={panToLiveLocation} LiveLocation={true} />
                                </div>

                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                lg={6}
                                // style={{display:"none" }}
                                className="accordion-holder accordion-holder2"
                            >
                                <div className={`scrollable-element ${(expanded || expanded2) ? 'h500' : ''}` }>
                                    <Accordion defaultExpanded style={{margin:0,marginBottom:20}}>
                                        <AccordionSummary
                                            style={{padding:"0px 12px"}}
                                            expandIcon={expanded ? <img src={MinusIcon} alt="Minus Icon" /> : <img src={PlusIcon} alt="PlusIcon" />}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                event.preventDefault();
                                                setExpanded(!expanded);
                                            }}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className="primary-font-bold-6">Locations shared with me</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="pl-0 live-location-accordion">
                                            {requestView?.locationsSharedWithMe.length > 0 ? <Grid>
                                                {requestView &&
                                                    requestView.locationsSharedWithMe &&
                                                    requestView.locationsSharedWithMe.map((row) => {
                                                        return (
                                                            <>
                                                                <Grid
                                                                onClick={()=>{
                                                                    panToLiveLocation(row);
                                                                    console.log(row.locationLat,"print", row.id)
                                                                }}
                                                                    container
                                                                    key={row.id}
                                                                    sx={{
                                                                        mr: "12px",
                                                                        padding: "0px",
                                                                        border: "1px solid #D8D8D8",
                                                                        borderRadius: "10px",
                                                                        marginBottom: "20px"
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        xs={12}
                                                                        md={8.5}
                                                                        lg={8.5}
                                                                        sx={{
                                                                            justifyContent: "start",
                                                                            textTransform: "capitalize",
                                                                        }}
                                                                    >
                                                                        <Typography className="primary-font-bold-5" style={{ padding: "12px" , cursor:'pointer'}}>
                                                                            {row.name}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        );
                                                    })}
                                            </Grid> : <div style={{padding:"13px",color:"#999"}}>Members have not shared their locations with you yet.</div>}
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion defaultExpanded style={{margin:0}}>
                                        <AccordionSummary
                                            style={{padding:"0px 12px"}}
                                            expandIcon={expanded2 ? <img src={MinusIcon} alt="Minus Icon" /> : <img src={PlusIcon} alt="PlusIcon" />}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                event.preventDefault();
                                                setExpanded2(!expanded2);
                                            }}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className="primary-font-bold-6">My Locations shared with others</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className="pl-0 live-location-accordion">
                                        {requestView?.myLocationSharedWithOthers.length > 0 ? <Grid>
                                                {requestView &&
                                                    requestView.myLocationSharedWithOthers &&
                                                    requestView.myLocationSharedWithOthers.map((row) => {
                                                        return (
                                                            <>
                                                                <Grid
                                                                    container
                                                                    key={row.id}
                                                                    sx={{
                                                                        mr: "12px",
                                                                        padding: "0px",
                                                                        border: "1px solid #D8D8D8",
                                                                        borderRadius: "10px",
                                                                        marginBottom: "20px",
                                                                    }}
                                                                >
                                                                    <Grid
                                                                        xs={12}
                                                                        md={8.5}
                                                                        lg={8.5}
                                                                        sx={{
                                                                            justifyContent: "start",
                                                                            textTransform: "capitalize",
                                                                        }}
                                                                    >
                                                                        <Typography className="primary-font-bold-5" style={{ padding: "12px" }}>
                                                                            {row.name}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid xs={12} md={3} lg={3} style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                                                                        <Button variant="outlined" onClick={() => handleStop(row)} className="primary-font-bold-6 save-btn profile-save-btn">Stop</Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        );
                                                    })}
                                            </Grid> :  <div style={{padding:"13px",color:"#999"}}>You haven't shared your location yet.</div>}

                                        </AccordionDetails>

                                    </Accordion>
                                </div>
                                
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            </Grid>
        </Grid>
    );
};

MainLayout.defaultProps = {
    isFetching: null,
    error: null,
};

const LiveLocation = ({
    getLiveSharing,
    getLiveLocation,
    singleStopTracking,
    trackMeStop
}) => {
    const dispatch = useDispatch()
    const [message,setMessage] = useState("")
    const [successDialouge,setSuccessDialuge] = useState(false)
    const [hasRefreshed, setHasRefreshed] = useState(false);
    const intervalRef = useRef(null);
    const pathName = window.location.pathname;
    const [isLive, setIsLive] = useState(pathName === "/live-location" ? true : false)
    // useEffect(() => {

    //     if (isLive) {
    //         intervalRef.current = setInterval(getLiveSharing, 10000); 
    //     } else {
    //         clearInterval(intervalRef.current);
    //     }

    //     return () => {
    //         clearInterval(intervalRef.current); 
    //     };
    // }, [isLive]);


    useEffect(() => {
        getLiveSharing();
    }, []);

    useEffect(() =>{
        if(trackMeStop && trackMeStop?.status === 200){
            dispatch({
                type:"SINGLE_STOP_SUCCESS",
                payload:{}
            })
            setMessage("Stopped successfully");
            setSuccessDialuge(true);
            getLiveSharing();
            setTimeout(() => {
                setSuccessDialuge(false);
                setMessage("");
                setHasRefreshed(true)
            }, 2000);
            
        }
    },[trackMeStop])


    const requestView = useMemo(() => {
        if (getLiveLocation?.status === 200 && getLiveLocation?.data) {
            return getLiveLocation.data;
        }
        return null;
    }, [getLiveLocation]);


    return (<>
        <ContentWrapper
            title={"Location shared with me"}
            gobackUrl={"/track-me"}
            goback={true}
            contenview={
                <MainLayout
                    requestView={requestView}
                    singleStopTracking={singleStopTracking}
                    isRefreshed={hasRefreshed}
                />
            }
        />
           {message && (
                <Dialog
                    open={successDialouge}
                    onClose={() => {
                        setSuccessDialuge(false);
                    }}
                    className="small-dialog dialog-box"
                    aria-labelledby="dialog-title"
                    aria-describedby="dialog-description"
                >
                    <DialogContent className="content-dialog">
                        <Typography className="primary-font-normal-4">
                            {message}
                        </Typography>
                    </DialogContent>
                </Dialog>
            )}
    </>
    )

};

function mapStateToProps(state) {
    return {
        getLiveLocation: state.trackMe.getLiveLocation,
        trackMeStop: state.trackMe.trackMeStop,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getLiveSharing,
            singleStopTracking
        },
        dispatch
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(LiveLocation);