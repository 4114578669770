import React, { useState, useEffect } from "react";
import { Grid, Typography, Button } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import UploadImage from '../../../../../../assets/icons/upload-icon.svg';
import * as formatters from '../../../../../../utils/util';

const IdentificationDetails = ({onBackOrCancelClick, handleNext}) => {
  const initialFormData = {
    name:'',
   identificationType:'',
    number:''
  };
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialFormData);
  const [imageUrlAvatar1, setImageUrlAvatar1] = useState(null);
  const [imageUrlAvatar, setImageUrlAvatar] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const[image, setImage] = useState(null);
  const[previewUrl, setPreviewUrl] = useState(""); 

  const handleFile = file => {
    //you can carry out any file validations here...
    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    setImageUrlAvatar1(URL.createObjectURL(file))
  }
  const handleOnDrop = event => {
    event.preventDefault(); 
    event.stopPropagation(); 
    // let's grab the image file
    let imageFile = event.dataTransfer.files[0];
    handleFile(imageFile);
  }

  const handleDragOver = event => {
    event.preventDefault();
  }
  useEffect(() => {
    if (selectedImage) {
      setImageUrlAvatar1(URL.createObjectURL(selectedImage));
      setPreviewUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);





  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};
    console.log(formData, 'formDataformDataformData')
    if (formData.name.trim() === '') {
        newErrors.name = 'This is required.';
      } 
    if (formData.identificationType.trim() === '') {
        newErrors.identificationType = 'This is required.';
    }
 
    if (formData.number.trim() === '') {
        newErrors.number = 'This is required.';
      }else if (! formatters.isNumaric(formData.number)){
        newErrors.number = 'Enter Valid Identification number';
      }

    if (Object.keys(newErrors).length === 0) {
      console.log(formData);
      setErrors({});
      handleNext();
    } else {
      setErrors(newErrors);
      console.log('Validation errors:', newErrors);
    }
  };
return (
    <Grid container spacing={2} style={{margin:0, padding: 32, width: '100%'}}>
        <Grid item xs={12} md={6} sx={{padding: 0}}>
            <div style={{padding: 20}}>
            <div>
                    <Typography className='font-normal-small error-message'>{errors.name}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Name</Typography>
                    <input type="text" name="name" id="name"  className={`profile-input ${errors.name ? 'err-input' : ''}`} aria-label='name' onChange={handleInputChange}  value={formData.name}/>
                </div>
              
               <div>
                    <Typography className='font-normal-small error-message'>{errors.identificationType}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Identification Type</Typography>
                    <select type="text" name="identificationType" id="identificationType"  className={`profile-input ${errors.identificationType ? 'err-input' : ''}`} aria-label='identificationType' onChange={handleInputChange}  value={formData.identificationType}>
                        <option value="" ></option>
                        <option value="id1">Id 1</option>
                        <option value="id2">Id 2</option>
                        <option value="id3">Id 3</option>
                        <option value="id4">Id 4</option>
                      </select>
                </div>
               
                <div style={{marginLeft: 0}}> 
                
                <div>
                    <Typography className='font-normal-small error-message'>{errors.number}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Number</Typography>
                    <input type="text" name="number" id="number"  className={`profile-input ${errors.number ? 'err-input' : ''}`} aria-label='number' onChange={handleInputChange}  value={formData.number}/>
                </div>
                </div>
            </div>
        </Grid>
        <Grid item xs={12} md={6} sx={{padding: 0}}>
            <div style={{padding: 20}}>
                <input
                    // accept="image/*"
                    type="file"
                    id="select-image"
                    style={{ display: "none" }}
                    onChange={(e) => {setSelectedImage(e.target.files[0]); handleFile(e.target.files[0]);}}
                />
                <label htmlFor="select-image"> 
                    <div className="photo-upload-container drop_zone" style={{height:272, marginBottom: 30}} onDragOver={handleDragOver} onDrop={handleOnDrop}>
                    <img  src={UploadImage} alt={'upload-avatar'} style={{cursor: 'pointer'}} />
                    <Typography className='primary-font-normal-3 profile-input-label' color='#ABAFB3'>Drag and drop a file here <br />or click to <span style={{color:'#4979FF'}}>upload</span></Typography>
                    { previewUrl && <div className="image">
                        {/* <div>{image.name}</div> */}
                      <img src={previewUrl} alt='image' width={100} height={100}/> 
                    </div> }
                  </div>
                </label>
            </div>
        </Grid>
        <div className="action-holder" style={{marginTop: 0, marginBottom: 50}}>
                <Button variant="outlined"  className="primary-font-bold-6 action-btn profile-cancel-btn" onClick={onBackOrCancelClick}>Cancel</Button>
                <Button variant="outlined" className="primary-font-bold-6 action-btn profile-save-btn"  onClick={handleSubmit}>Next</Button>
              </div>
    </Grid>
    )
}
export default IdentificationDetails;
