import React, { useEffect, useState } from "react";
import "../../../../index.css";
import {
    Typography,
    Divider,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { changeSecurityPin } from '../redux/actions';

import CloseIcon from '../../../../assets/icons/modal-close-icon.svg';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import * as formatters from '../../../../utils/util';

const UpdateSecurityPin = (props) => {
    const {
        openDialog,
        closeDialog,
        isVerifyingPassword,
        changeSecurityPin,
        passwordData,
        passwordError
    } = props;

    const [showOldPassword1, setShowOldPassword1] = useState(false);
    const [showNewPassword1, setShowNewPassword1] = useState(false);
    const [showConfirmNewPassword1, setShowConfirmNewPassword1] = useState(false);

    const handleClickShowOldPassword1 = () => setShowOldPassword1(!showOldPassword1);
    const handleMouseDownOldPassword1 = () => setShowOldPassword1(!showOldPassword1);
    const handleClickShowNewPassword1 = () => setShowNewPassword1(!showNewPassword1);
    const handleMouseDownNewPassword1 = () => setShowNewPassword1(!showNewPassword1);
    const handleClickConfirmShowNewPassword1 = () => setShowConfirmNewPassword1(!showConfirmNewPassword1);
    const handleMouseDownConfirmNewPassword1 = () => setShowConfirmNewPassword1(!showConfirmNewPassword1);

    const [enterPassword, setEnterPassword] = useState(null);
    const [newSecurityPin, setNewSecurityPin] = useState(null);
    const [confirmNewSecurityPin, setConfirmNewSecurityPin] = useState(null);
    const [enterPasswordErr, setEnterPasswordErr] = useState('');
    const [newSecurityPinErr, setNewSecurityPinErr] = useState('');
    const [confirmNewSecurityPinErr, setConfirmNewSecurityPinErr] = useState('');

    const user = window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem('userInfo')) : null;

    useEffect(() => {
        if (passwordData && passwordData.status === 200) {
            resetValue();
            resetPinErrorMessage();
            closeDialog();
        }
        if (passwordData && passwordData.status !== 200) {
            setEnterPasswordErr(passwordData?.message || "");
        }
    }, [passwordData, passwordError])

    const onChangePin = (e, type) => {
        if (type === 'enterPassword') {
            setEnterPassword(formatters.isNumaric(e.target.value));
        } else if (type === 'newSecurityPin') {
            setNewSecurityPin(formatters.isNumaric(e.target.value));
        } else if (type === 'confirmNewSecurityPin') {
            setConfirmNewSecurityPin(formatters.isNumaric(e.target.value));
        }
    }

    const resetValue = (value) => {
        setEnterPassword('');
        setNewSecurityPin('');
        setConfirmNewSecurityPin('');
    };

    const resetPinErrorMessage = () => {
        setEnterPasswordErr('');
        setNewSecurityPinErr('');
        setConfirmNewSecurityPinErr('');
    }
    const onClosingDialog = () => {
        resetValue();
        resetPinErrorMessage();
        closeDialog();
    }

    const onSaveChangePin = () => {
        let isInValid = false;
        resetPinErrorMessage();
        if (!enterPassword?.trim().length) {
            setEnterPasswordErr('This is required.');
            isInValid = true;
        }
        if (!newSecurityPin?.trim().length) {
            setNewSecurityPinErr('This is required.');
            isInValid = true;
        } else if (newSecurityPin?.trim().length !== 4) {
            setNewSecurityPinErr('New Security Pin should contain 4 digits.');
            isInValid = true;
        }
        if (!confirmNewSecurityPin?.trim().length) {
            setConfirmNewSecurityPinErr('This is required.');
            isInValid = true;
        }
        if (newSecurityPin?.trim() === enterPassword?.trim()) {
            setConfirmNewSecurityPinErr('New Security Pin and Old Security Pin should not be same');
            isInValid = true;
        }
        if (newSecurityPin !== confirmNewSecurityPin) {
            setConfirmNewSecurityPinErr('New Security Pin and Confirm New Security Pin mismatch.');
            isInValid = true;
        }
        if (isInValid) {
            return;
        }
        const payload = {
            oldSecurityPin: enterPassword,
            securityPin: newSecurityPin,
            id: user.id,
            updatedBy: user.id
        }
        changeSecurityPin(payload)
    }

    return (
        <Dialog
            open={openDialog}
            onClose={onClosingDialog}
            className="change-pin-dialog dialog-box"
            aria-labelledby="change-pin-dialog-title"
            aria-describedby="change-pin-dialog-description"
        >
            <DialogTitle id="change-pin-dialog-title">
                <Typography className='primary-font-bold-heading2'>Change Security Pin</Typography>
                {openDialog ? (
                    <img src={CloseIcon} alt="close-icon" style={{ cursor: 'pointer',width:"21px" }} onClick={onClosingDialog} sx={{
                        marginRight: '-30px',
                    }} />
                ) : null}
            </DialogTitle>
            <Divider />
            <DialogContent>
                <div>
                    <Typography className='font-normal-small error-message'>{enterPasswordErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Old Security Pin</Typography>
                    <div className="input-holder">
                        <input className={`profile-input ${enterPasswordErr !== '' ? 'err-input' : ''}`} aria-label='enter-password' type={showOldPassword1 ? "text" : "password"} onChange={(e) => { onChangePin(e, 'enterPassword') }} maxLength={4} value={enterPassword} />
                        {/* <input className={`primary-font-normal-1 login-input box-spacing`} type={showPassword ? "text" : "password"} aria-label='password' onChange={onChangePassword} value={password} placeholder="Password"/> */}
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="old-password"
                            onClick={handleClickShowOldPassword1}
                            onMouseDown={handleMouseDownOldPassword1}
                            className="input-eye-icon pwd-icon1"
                        >
                            {showOldPassword1 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </div>
                </div>
                <div>
                    <Typography className='font-normal-small error-message'>{newSecurityPinErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>New Security Pin</Typography>
                    <div className="input-holder">
                        <input className={`profile-input ${newSecurityPinErr !== '' ? 'err-input' : ''}`} aria-label='new-pin' type={showNewPassword1 ? "text" : "password"} onChange={(e) => { onChangePin(e, 'newSecurityPin') }} maxLength={4} value={newSecurityPin} />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="new-pin"
                            onClick={handleClickShowNewPassword1}
                            onMouseDown={handleMouseDownNewPassword1}
                            className="input-eye-icon pwd-icon1"
                        >
                            {showNewPassword1 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </div>
                </div>
                <div>
                    <Typography className='font-normal-small error-message'>{confirmNewSecurityPinErr}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Confirm New Security Pin</Typography>
                    <div className="input-holder">
                        <input className={`profile-input ${confirmNewSecurityPinErr !== '' ? 'err-input' : ''}`} aria-label='confirm-new-pin' type={showConfirmNewPassword1 ? "text" : "password"} onChange={(e) => { onChangePin(e, 'confirmNewSecurityPin') }} maxLength={4} value={confirmNewSecurityPin} />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="new-password"
                            onClick={handleClickConfirmShowNewPassword1}
                            onMouseDown={handleMouseDownConfirmNewPassword1}
                            className="input-eye-icon pwd-icon1"
                        >
                            {showConfirmNewPassword1 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </div>
                </div>
            </DialogContent>
            <Divider />
            <DialogActions>
                <div className="action-holder-1" style={{ marginTop: 20, marginBottom: 0 }}>
                    <Button variant="outlined" className="primary-font-bold-6 action-btn profile-cancel-btn" onClick={onClosingDialog}>Cancel</Button>
                    <Button variant="outlined" className="primary-font-bold-6 action-btn profile-save-btn" onClick={onSaveChangePin} disabled={isVerifyingPassword}>{isVerifyingPassword ? 'Saving...' : 'Save'}</Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};


UpdateSecurityPin.defaultProps = {
    isFetchingLogin: null,
    isFetchingRegister: null,
    isFetchingUserInfo: null,
};

function mapStateToProps(state) {
    return {
        isVerifyingPassword: state.profile.isVerifyingPassword,
        passwordData: state.profile.passwordData,
        passwordError: state.profile.passwordError,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            changeSecurityPin
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdateSecurityPin);

