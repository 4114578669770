import React, {useEffect, useState} from "react";
import "../../../../../../index.css";
import { Grid, Box} from "@mui/material";
import ContentWrapper from '../../../../../../common/components/ContentWrapper';
import CustomStepper from '../../../../../../common/components/CustomStepper';
import AccountInfo from "./AccountInfo";
import DebitCardDetails from "./DebitCardDetails";
import OnlineCredentials from "./OnlineCredentials";
import SpecialNotes from "./SpecialNotes";
import { useNavigate } from "react-router-dom";
import { getSingleBankDetailsAPI , resetDetails} from "../../../redux/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "../styles/bankAccounts.css";

const MainLayout = () => {
    const navigate = useNavigate();

    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());

    const isStepSkipped = (step) => {
      return skipped.has(step);
    };

    const handleNext = () => {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    };

    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const onCancelClick = () => {
      navigate(-1); // Navigate back one step in the history
      resetDetails();
    };

    const onBackOrCancelClick = () => {
      if(activeStep === 0 ){
        onCancelClick();
      } else {
        handleBack();
      }
    }

    const steps = [{
      label: "Account Info",
      component: <AccountInfo onBackOrCancelClick={onBackOrCancelClick} handleNext={handleNext} />
    },
    {
      label: "Online Credentials",
      component: <OnlineCredentials onBackOrCancelClick={onBackOrCancelClick} handleNext={handleNext}/>
    },
    {
      label: "Debit Card Details",
      component: <DebitCardDetails onBackOrCancelClick={onBackOrCancelClick} handleNext={handleNext}/>
    },
    {
      label: "Special Notes",
      component: <SpecialNotes onBackOrCancelClick={onBackOrCancelClick} handleNext={handleNext}/>
    }];
  return (
    <Grid container xs={12} md={12} >
      <div className="bank-accounts-container bank-account-margin">
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        className="box-container bank-account-form"
        style={{padding: 0}}
      >
        <Box sx={{ width: '100%'}}>
          <CustomStepper
           steps={steps} 
           activeStep={activeStep}
           isLockRequired={true}
           />
        </Box>
        
      </Grid>
      </div>
    </Grid>
  );
};

const AddBankAccount = ({getSingleBankDetailsAPI}) => {
    const selectedBankAccount = window.localStorage.getItem("selectedBankAccount") ? JSON.parse(window.localStorage.getItem("selectedBankAccount")): null;
    useEffect(()=>{
      if(selectedBankAccount){
        getSingleBankDetailsAPI(selectedBankAccount)
      }
    },[])
    return (
      <ContentWrapper title={"My Bank Accounts"} goback={true} contenview={<MainLayout />} />
    )
  }

  
  AddBankAccount.defaultProps = {
  isFetching: null,
  error: null,
  familyMembers: [],
};

function mapStateToProps(state) {
  return {
    bankAccountData : state.bank.bankAccountData
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getSingleBankDetailsAPI
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddBankAccount);

