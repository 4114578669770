import React,{useEffect, useState} from "react";
import {
    Button,
    Typography,
    Grid,
    Checkbox,
    Card
  } from "@mui/material";
  import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";

const StyledCard = styled(Card)({
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    width: "100%",
    flexDirection: "row",
    borderRadius: "10px",
    height: "60px",
    margin: "10px",
    border: "1px solid #D8D8D8",
    "&:hover": {
      border: "1px solid #FF3535",
      boxShadow: "0px 2px 8px #00000033",
    },
  });

    const MessageList = [
      {
        "code": 101,
        "message": "Hello, I'm going out for the evening"
      },
      {
        "code": 102,
        "message": "Hello, I'm catching an Uber/Taxi"
      },
      {
        "code": 103,
        "message": "Hello, I'm activated the FYI tracking"
      },
      {
        "code": 104,
        "message": "Hello, I'm getting ready to leave work"
      },
      {
        "code": 105,
        "message": "Hello, I'm on my way home"
      },
      {
        "code": 106,
        "message": "Hello, I'm on my way to the store"
      },
      {
        "code": 107,
        "message": "Hello, I'm going on a road trip"
      },{
        "code": 108,
        "message": "Hello, I wish to track you until you reach safely"
      }
    ]
    

const TrackMessage = ({onBackOrCancelClick,handleNext,addEmergencyAlert,trackData}) => {

  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch()


  const handleSelectRow = (event, id) => {
    const isSelected = selectedRows.includes(id);
  
    if (event) {
      if (event.target.checked) {
        setSelectedRows((prevSelected) => [...prevSelected, id]);
      } else {
        setSelectedRows((prevSelected) =>
          prevSelected.filter((selectedId) => selectedId !== id)
        );
      }
    } else {
      if (isSelected) {
        setSelectedRows((prevSelected) =>
          prevSelected.filter((selectedId) => selectedId !== id)
        );
      } else {
        setSelectedRows((prevSelected) => [...prevSelected, id]);
      }
    }
  };

  const handleMessageNext = () => {
    const newdata = { ...addEmergencyAlert };
  
    if (selectedRows.length > 0) {
      const filteredMessages = MessageList.filter(item => selectedRows.includes(item.code));
      newdata.message = filteredMessages;
    }
    dispatch({
      type: "ADD_TRACK_ME",
      payload: newdata,
    });
  
    handleNext();
  };
  
    useEffect(() => {
        console.log("addEmergencyAlert", addEmergencyAlert);
    }, [addEmergencyAlert])

    useEffect(() =>{
      if(trackData){
        const messageArray = trackData?.messages?.split(",").map(Number);
        const filteredMessages = MessageList.filter(item => messageArray.includes(item.code));
        setSelectedRows(filteredMessages.map((row) => {
          return row.code
        }));
      }
    },[trackData])

    return(
        <Grid container spacing={2} style={{ width: "100%", margin: 0 }}>
          <Grid className="track-me-container">
            <Grid
                container
                xs={12}
                md={12}
                lg={12}
                // className="box-container"
                sx={{ justifyContent: "center", alignItems: "center" }}
            >
               <Grid container className='location-card-1'  style={{ margin: 0, width: '100%' }}>
                {MessageList.map((item, key) => {
                    return (
                        <StyledCard key={key + 1} onClick={() => {
                          handleSelectRow(null, item?.code);
                        }} style={{"cursor":"pointer"}}>
                            <Grid style={{ display: "flex", alignItems: "center",}}>
                                <Checkbox
                                    sx={{
                                        color: "red !important",
                                    }}
                                    checked={selectedRows.includes(item.code)}
                                    onChange={(event) =>
                                        handleSelectRow(event, item)
                                    }
                                  inputProps={{
                                    "aria-label": `select row ${item.code}`,
                                  }}
                                />

                                <Typography
                                    className="primary-font-medium"
                                    style={{
                                        display: "-webkit-box",
                                        overflow: "hidden",
                                        webkitBoxOrient: "vertical",
                                        textOverflow: "ellipsis",
                                        webkitLineClamp: "2",
                                        cursor: "pointer",
                                    }}
                                   
                                >
                                    {item.message}
                                </Typography>
                            </Grid>

                        </StyledCard>
                    )

                })
                }

                <div
                className="action-holder"
                style={{ marginTop: 40, marginBottom: 40 }}
              >
                <Button
                  variant="outlined"
                  className="primary-font-bold-6 action-btn profile-cancel-btn"
                  onClick={onBackOrCancelClick}
                >
                  Back
                </Button>
                <Button
                  variant="outlined"
                  className="primary-font-bold-6 action-btn profile-save-btn"
                  onClick={handleMessageNext}
                >
                  Next
                </Button>

                </div>
                </Grid>   
            </Grid>
            </Grid>
        </Grid>
    )
}

function mapStateToProps(state) {
    return {
      addEmergencyAlert: state.trackMe.addTrackMe,
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return bindActionCreators(
      { },
      dispatch
    );
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(TrackMessage);
