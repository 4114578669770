import React, { Fragment } from "react";
import "../../index.css";
import { Stepper, Step, StepLabel, Grid, Button, Typography, Box,Divider} from "@mui/material";
import StepperActive from '../../assets/images/stepper-active.svg';
import StepperNormal from '../../assets/images/stepper-normal.svg';
import CheckIcon from '../../assets/images/CheckIcon.svg';
import LockIcon from '../../assets/images/lock-icon.svg';


const CustomStepper = ({steps, activeStep, isLockRequired}) => {
return (
        <>
    <Grid className="custom-stepper-mobile">
      <Grid sx={{ overflowX: 'scroll' }} >
        <Stepper activeStep={activeStep} alternativeLabel sx={{ padding: '20px 5px' }}>
          {steps.map((item, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={item.label} {...stepProps} style={{ position: 'relative' }}>
                <StepLabel  {...labelProps}
                  connectorStateClasses={{
                    alternativeLabel: `connector ${activeStep > index
                      ? 'completed-connector'
                      : activeStep === index
                        ? 'active-connector'
                        : ''
                      } ${activeStep === index - 1
                        ? 'active-connector-before'
                        : ''
                      }`,
                  }}
                  icon={
                    activeStep === index
                      ?<img src={StepperActive} width={15} alt='active-step-img' />  
                      : activeStep > index
                        ?<img src={CheckIcon} width={15} />  
                        :<img src={StepperNormal} width={15} alt='step-img' />  
                  }
                >
                  {/* <Typography variant="span" className="primary-font-bold-7" sx={{ fontSize: '12px !important', lineHeight:'10px !important' }}>
                    {index+1}
                  </Typography> */}
                </StepLabel>
                {(isLockRequired && (index < (steps.length - 1))) && <img src={LockIcon} width={10} className="lock-icon" style={{ position: 'absolute', top: '-3px', right: '-8px', zIndex: '1' }} />}
              </Step>
            );
          })}
        </Stepper>
      </Grid>
      <Grid sx={{ p: "10px" }}>
      <Grid sx={{display:'flex', justifyContent:'center'}}>
      <Box sx={{ borderRadius:"5px", boxShadow:3, height:'50px',width:'85%', alignContent:'center' }}>
        <Typography variant="span" className="primary-font-bold-7" sx={{ display: 'flex', justifyContent: 'center', margin: '15px 0px',color:'#ff3535' }}>
          {steps[activeStep].label}
        </Typography>
        </Box>
      </Grid>
        <Divider  sx={{margin:'24px 0px 0px'}}/>
        <Grid sx={{ padding: "5px" }} >
          <div>
            {steps[activeStep].component}
          </div>
        </Grid>
      </Grid>
    </Grid>
        {/* stepper for laptop view */}
       <div  className="custom-stepper-laptop">
            <Stepper activeStep={activeStep}  alternativeLabel sx={{ padding: '24px 0', borderBottom: '1px solid #D8D8D8'}}>
              {steps.map((item, index) => {
                const stepProps = {};
                const labelProps = {};
              return (
                  <Step key={item.label} {...stepProps} style={{ position: 'relative' }}>
                      <StepLabel  {...labelProps} 
                      connectorStateClasses={{
                        alternativeLabel: `connector ${
                          activeStep > index
                            ? 'completed-connector'
                            : activeStep === index
                            ? 'active-connector'
                            : ''
                        } ${
                          activeStep === index - 1
                            ? 'active-connector-before'
                            : ''
                        }`,
                      }}
                      icon={
                        activeStep === index
                          ? <img src={StepperActive} width={30} alt='active-step-img'/>
                          : activeStep > index
                          ? <img src={CheckIcon}  width={30}/>
                          : <img src={StepperNormal}  width={30} alt='step-img'/>
                      }
                      >
                        <Typography variant="span" className="primary-font-bold-7">
                          {item.label}
                        </Typography>
                      </StepLabel>
                      { (isLockRequired && (index < (steps.length - 1))) && <img src={LockIcon} width={16} className="lock-icon" style={{ position: 'absolute', top: '-3px', right: '-8px', zIndex: '1'}}/>}
                  </Step>
              );
            })}
        </Stepper>
            <Fragment>
              <div>
                {steps[activeStep].component}
              </div>
            </Fragment>
            </div>
        </>
    )
}
export default CustomStepper;
