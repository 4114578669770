import React, { useEffect, useState } from "react";
import { Grid, Card, Typography, Button, styled } from "@mui/material";
import data from "../needHelpCardlist";
import arrow from "../../../../../assets/icons/need-help-card-arrow.png";
import recorderon from "../../../../../assets/icons/recording/Recording-On.png";
import recorderoff from "../../../../../assets/icons/recording/Recording-off.png";
import "../../../../../../src/index.css";
import { useNavigate } from "react-router-dom";
import "../styles/NeedHelp.css";

const StyledCard = styled(Card)({
  width: 230,
  height: 200,
  border: "1px solid #D8D8D8",
  borderRadius: 8,
  boxShadow: "0px 2px 15px #00000026",
});

const TextOverlay = styled("div")({
  textAlign: "center",
  paddingTop: "20px",
  objectFit: "cover",
});

const Image = styled("img")({
  width: "40px",
  height: "55px",
});

const ImageOverlay = styled("div")({
  width: "40px",
  height: "55px",
});

const NeedHelpCard = () => {
  const [isRecording, setIsRecording] = useState();
  const [item, setItem] = useState([]);
  const [minutes, setMinutes] = useState('00');
  const [seconds, setSeconds] = useState('00');
  const [counter, setCounter]  = useState(false);
  const Navigate = useNavigate();
  const buttonresponse = window.localStorage.getItem("isHelpActivated");

  useEffect(() => {
    const Name = window.localStorage.getItem("INeedHelp")

    const result = data.filter((word) => {
      return word.name === Name;
    });
    setItem(result[0]);
  }, []);

  useEffect(() => {
    let intervalId;

    if (buttonresponse) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter/60);

        const computedSecond = String(secondCounter).length === 1 ? `0${secondCounter}` : secondCounter;
        const computedMinute = String(minuteCounter).length === 1 ? `0${minuteCounter}` : minuteCounter;

        setSeconds(computedSecond);
        setMinutes(computedMinute);

        setCounter(counter => counter+1);
      },1000)
    }
    return () => clearInterval(intervalId);
  },[buttonresponse, counter]);

  const handleButtonActivateClick = () => {
      window.localStorage.setItem("isHelpActivated", true)
      setIsRecording((track) => !track);
       Navigate("/home")
  };

  const handleButtonStopClick = () => {
    window.localStorage.removeItem("isHelpActivated")
    setIsRecording((track) => !track);
    Navigate("/home");
  }

  return (
    <Grid container sx={{margin: 0,width:"100%"}}>
      {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
      <Grid className="help-container">
      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        className="box-container"
        style={{justifyContent: "center"}}
      >
        <Grid container className='location-card'  style={{ margin: 0, width: '100%',justifyContent: "center"}}>
          <Card className="need-help-card"
            style={{
              alignContent: "center",
              // width: "600px",
              height: "81px",
              paddingLeft: "25px",
              backgroundColor: "#F6F6F6",
              // marginTop: "30px",
            }}
          >
            <Typography>My Location</Typography>
            <Typography>231 w 543 Th st Bronx NY 443-455,USA</Typography>
          </Card>
            {
              buttonresponse ? (<Button  className="need-help-btn"
                variant="contained"
                style={{
                  // width: "630px",
                  alignItems: "center",
                  marginTop: "30px",
                  backgroundColor: "#FF3535",
                  borderRadius: "10px",
                  textTransform:"capitalize"
                }}
                
                onClick={handleButtonStopClick}
              >
                Deactivate
          </Button>) : (<Button className="need-help-btn"
                variant="contained"
                style={{
                  // width: "630px",
                  alignItems: "center",
                  marginTop: "30px",
                  backgroundColor: "#FF3535",
                  borderRadius: "10px",
                  textTransform:"capitalize"
                }}
                onClick={handleButtonActivateClick}
              >
                Activate
          </Button>)}
          <Grid className="activate-record"
            style={{
              // marginTop: "20px",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              gap:"20px",
            }}
          >
            <StyledCard
              key={item.id === 1}
              style={{ marginTop: "30px" }}
            >
              <TextOverlay>{item.name}</TextOverlay>
              <ImageOverlay>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginLeft: "37px",
                    marginTop: "50px",
                  }}
                >
                  <Image
                    src={arrow}
                    alt="arrow"
                    style={{ width: "30px", height: "30px", float: "left" }}
                  />
                  <Image
                    src={item.image_url}
                    alt={item.imageAlt}
                    height={165}
                    style={{ marginLeft: "80px" }}
                  />
                </div>
              </ImageOverlay>
            </StyledCard>
            <Grid>
            <div className="recorder-circle">
              <img
                src={buttonresponse ? recorderon : recorderoff}
                style={{ alignContent: "center", marginTop: "32px", marginLeft: "22px" }}
                alt="recorder"
              />
            </div>
            <br/>
            <span style={{marginLeft:"35px"}}>{minutes}:{seconds}</span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      </Grid>
    </Grid>
  );
};

export default NeedHelpCard;
