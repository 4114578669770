import {api} from '../../../../../../../utils/apiClient';
import { CognitoIdentityProviderClient, AdminCreateUserCommand, AdminDeleteUserCommand, AdminConfirmSignUpCommand } from "@aws-sdk/client-cognito-identity-provider"; // ES Modules import
import awsConfig from '../../../../../../../aws-exports';
import constants from '../../../../../../../config/constants';


const client = new CognitoIdentityProviderClient({ 
    region: "us-east-1",
    credentials: {
        accessKeyId: constants.COGNITO_ACCESS_KEY,
        secretAccessKey: constants.COGNITO_SECRET_KEY,
    }
});

export const getFamilyMembers = (payload) => async(dispatch) => {
     const userDetails = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')): null;
     const url = `v1/user/readAll${userDetails?.accountId  ? `/${userDetails?.accountId}`: ''}`;
    try{
        dispatch({
            type: 'MEMBERINFO_DATA_REQUEST',
            payload: null
        })
        const response =await api.getApi(url);
        if(response.data && response.data.status === 200){
            return dispatch({
                type: 'MEMBERINFO_DATA_SUCCESS',
                payload: response.data
            });
        }else{
            return dispatch({
                type: 'MEMBERINFO_DATA_FAILURE',
                payload: response.data
            });
        }
       
    }catch(e){
        return dispatch({
            type: 'MEMBERINFO_DATA_FAILURE',
            payload: e
        });
    }
}

export const deleteUserDataApi = (id) => async(dispatch) => {
    const url = `v1/user/delete${id  ? `/${id}`: ''}`;
    try{
        dispatch({
            type: 'DELETE_DATA_REQUEST',
            payload: null
        })
        const response =await api.deleteapi(url);
        return dispatch({
            type: 'DELETE_DATA_SUCCESS',
            payload: response.data
        });
    }catch(e){
        return dispatch({
            type: 'DELETE_DATA_FAILURE',
            payload: e
        });
    }
 };
  
export const markAsAdminAPI = (payload) => async(dispatch) => {
    const url = `v1/user/markAsAdmin`;
    
    try{
        dispatch({
            type: 'ADMIN_DATA_REQUEST',
            payload: null
        })
        const response =await api.putApi(url, payload);
        return dispatch({
            type: 'ADMIN_DATA_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'ADMIN_DATA_FAILURE',
            payload: e
        });
    }
 };
 
 export const getSpecialInstruction = (payload) => async(dispatch) => {
    const url = `v1/userInfo/specialInstructions/read${payload  ? `/${payload}`: ''}`;
    
    try{
        dispatch({
            type: 'GET_SPL_INSTRUCTION_REQUEST',
            payload: null
        })
        const response =await api.getApi(url, payload);
        return dispatch({
            type: 'GET_SPL_INSTRUCTION_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'GET_SPL_INSTRUCTION_FAILURE',
            payload: e
        });
    }
 };

 export const addSpecialInstruction = (payload) => async(dispatch) => {

    const url = `v1/userInfo/specialInstructions/update`;
    
    try{
        dispatch({
            type: 'SPL_INSTRUCTION_REQUEST',
            payload: null
        })
        const response =await api.putApi(url, payload);
        return dispatch({
            type: 'SPL_INSTRUCTION_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'SPL_INSTRUCTION_FAILURE',
            payload: e
        });
    }
 };

 export const createCognitoUser = (payload) =>async(dispatch) => {
    
    const input = { 
      UserPoolId: awsConfig.aws_user_pools_id, // required
      ClientId:awsConfig.aws_user_pools_web_client_id,
      Username: payload.username, // required
      Password:payload.password,
      UserAttributes: null,
      ValidationData: null,
      MessageAction:  "SUPPRESS",
      DesiredDeliveryMediums: [ 
      ],
    };
   
    try{
        const command = new AdminCreateUserCommand(input);
        const response = await client.send(command);
        if(response){
            const input = { 
            UserPoolId:awsConfig.aws_user_pools_id, // required // required
            Username: payload.username, // required
            };
            const command = new AdminConfirmSignUpCommand(input);
            const confirmresponse = await client.send(command);
        }
    }catch(e){
        console.log(e)
    }
    
}
export const updatePersonalInformation = (payload) => async(dispatch) => {
    const url = `v1/user/personalInfo/update`;
    
    try{
        dispatch({
            type: 'UPDATE_PERSONAL_INFO_REQUEST',
            payload: null
        })
        const response =await api.putApi(url, payload);
        return dispatch({
            type: 'UPDATE_PERSONAL_INFO_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'UPDATE_PERSONAL_INFO_FAILURE',
            payload: e
        });
    }
 };

export const deleteUser = (email) =>async(dispatch) => {
    const input = { // AdminDeleteUserRequest
        UserPoolId: awsConfig.aws_user_pools_id, // required
        Username: email, // required
      };
try{
    const command = new AdminDeleteUserCommand(input)
    const response = await client.send(command);
}catch(e){
    console.log(e)
}
}
export const getSpecficUserProfile = (payload) => async(dispatch) => {

    const url = `v1/user/read${payload?.id  ? `/${payload?.id}`: ''}`;
  try{
      dispatch({
          type: 'USER_DATA_REQUEST',
          payload: null
      })
      const response =await api.getApi(url);

      return dispatch({
          type: 'USER_DATA_SUCCESS',
          payload: response.data
      });
  }catch(e){
      return dispatch({
          type: 'USER_DATA_FAILURE',
          payload: e
      });
  }
}

export const getRelationshipList = (payload) => async(dispatch) => {
    const url = `v1/commonUtility/listValues/readAll`;
   try{
       dispatch({
           type: 'RELATIONSHIP_DATA_REQUEST',
           payload: null
       })
       const response =await api.getApi(url);
       return dispatch({
           type: 'RELATIONSHIP_DATA_SUCCESS',
           payload: response?.data?.data['users-relationship'] ? response.data.data['users-relationship'] : []
       });
   }catch(e){
       return dispatch({
           type: 'RELATIONSHIP_DATA_FAILURE',
           payload: e
       });
   }
}
export const uploadCommonUtilityPicture = (payload) => async(dispatch) => {
        const url = `v1/commonUtility/file/upload`;
       try{
           dispatch({
               type: 'UTILITY_IMAGE_UPDATE_REQUEST',
               payload: null
           })
     
           const response =await api.postApi(url,payload);
           return dispatch({
               type: 'UTILITY_IMAGE_UPDATE_SUCCESS',
               payload: response.data
           });
       }catch(e){
           return dispatch({
               type: 'UTILITY_IMAGE_UPDATE_FAILURE',
               payload: e
           });
       }
    }

export const addDemographicInformation = (payload) => async(dispatch) => {

const url = `v1/userInfo/userDemographic/create`;

try{
    dispatch({
        type: 'DEMOGRAPHIC_INFORMATION_REQUEST',
        payload: null
    })
    const response =await api.postApi(url, payload);
    return dispatch({
        type: 'DEMOGRAPHIC_INFORMATION_SUCCESS',
        payload: response.data
    });
    }catch(e){
    return dispatch({
        type: 'DEMOGRAPHIC_INFORMATION_FAILURE',
        payload: e
    });
}
};
 export const getDemographicInformation = (payload) => async(dispatch) => {

    const url = `v1/userInfo/userDemographic/read/${payload.userId}`;
    
    try{
        dispatch({
            type: 'DEMOGRAPHIC_INFORMATION_REQUEST',
            payload: null
        })
        const response =await api.getApi(url, payload);
        return dispatch({
            type: 'GET_DEMOGRAPHIC_INFORMATION_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'GET_DEMOGRAPHIC_INFORMATION_FAILURE',
            payload: e
        });
    }
 };


 
 export const updateCemeteryData = (payload) => async(dispatch) => {

    const url = `v1/userInfo/userCemetery/update`;
    
    try{
        dispatch({
            type: 'CEMETERY_INFORMATION_REQUEST',
            payload: null
        })
        const response =await api.putApi(url, payload);
        return dispatch({
            type: 'CEMETERY_INFORMATION_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'CEMETERY_INFORMATION_FAILURE',
            payload: e
        });
    }
 };

 export const getCemeteryData = (payload) => async(dispatch) => {

    const url = `v1/userInfo/userCemetery/read/${payload.userId}`;
    
    try{
        dispatch({
            type: 'CEMETERY_INFORMATION_REQUEST',
            payload: null
        })
        const response =await api.getApi(url, payload);
        return dispatch({
            type: 'GET_CEMETERY_INFORMATION_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'GET_CEMETERY_INFORMATION_FAILURE',
            payload: e
        });
    }
 };

 export const updateRelativesToNotify = (payload) => async(dispatch) => {

    const url = `v1/userInfo/userRelative/update`;
    
    try{
        dispatch({
            type: 'UPDATE_USER_RELATIVE_REQUEST',
            payload: null
        })
        const response =await api.putApi(url, payload);
        return dispatch({
            type: 'UPDATE_USER_RELATIVE_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'UPDATE_USER_RELATIVE_FAILURE',
            payload: e
        });
    }
 };
 export const getRelativesToNotify = (payload) => async(dispatch) => {
    const url = `v1/userInfo/userRelative/read/${payload}`;
    
    try{
        dispatch({
            type: 'USER_RELATIVE_REQUEST',
            payload: null
        })
        const response =await api.getApi(url, payload);
        return dispatch({
            type: 'GET_USER_RELATIVE_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'GET_USER_RELATIVE_FAILURE',
            payload: e
        });
    }
 };
 export const deleteRelativesToNotify = (payload) => async(dispatch) => {
    const url = `v1/userInfo/userRelative/delete/${payload}`;
    try{
        dispatch({
            type: 'DLT_USER_RELATIVE_REQUEST',
            payload: null
        })
        const response =await api.deleteapi(url, payload);
        return dispatch({
            type: 'DLT_USER_RELATIVE_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'DLT_USER_RELATIVE_FAILURE',
            payload: e
        });
    }
 };

 export const getStatesList = (payload) => async(dispatch) => {
    const url = `v1/commonUtility/state/read`;
    
    try{
        dispatch({
            type: 'STATE_REQUEST',
            payload: null
        })
        const response =await api.getApi(url, payload);
        return dispatch({
            type: 'GET_STATE_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'GET_STATE_FAILURE',
            payload: e
        });
    }
 };

 export const updateFuneralArrangements = (payload) => async(dispatch) => {

    const url = `v1/userInfo/userFuneral/update`;
    try{
        dispatch({
            type: 'UPDATE_FUNERAL_ARRANGEMENT_REQUEST',
            payload: null
        })
        const response =await api.putApi(url, payload);
        return dispatch({
            type: 'UPDATE_FUNERAL_ARRANGEMENT_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'UPDATE_FUNERAL_ARRANGEMENT_FAILURE',
            payload: e
        });
    }
 };
 export const getFuneralArrangementDetails = (payload) => async(dispatch) => {
    const url = `v1/userInfo/userFuneral/read/${payload}`;
    try{
        dispatch({
            type: 'GET_FUNERAL_ARRANGEMENT_REQUEST',
            payload: null
        })
        const response =await api.getApi(url, payload);
        return dispatch({
            type: 'GET_FUNERAL_ARRANGEMENT_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'GET_FUNERAL_ARRANGEMENT_FAILURE',
            payload: e
        });
    }
 };