import React,{useState} from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider,Typography, Button } from "@mui/material";
import * as formatters from '../../../../utils/util';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


const Passworddialog = ({openDialog}) => {
    const [openModal, setOpenModal] = useState(openDialog);
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [newPasswordErr, setNewPasswordErr] = useState('');
    const [confirmNewPasswordErr, setConfirmNewPasswordErr] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleMouseDownConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

    const onChangePassword = (e, type) => {
        if (type === 'newPassword') {
            setNewPassword(e.target.value);
        } else if (type === 'confirmNewPassword') {
            setConfirmNewPassword(e.target.value);
        }
      }
 const resetErrorMessages = () => { 
    setNewPasswordErr('');
    setConfirmNewPasswordErr('');

  }

  const onSaveChangePassword = () => {
    let isInValid = false;
    resetErrorMessages();
   
    
    if (!newPassword?.trim().length) {
        setNewPasswordErr('This is required.');
        isInValid = true;
    }
    if (!confirmNewPassword?.trim().length) {
        setConfirmNewPasswordErr('This is required.');
        isInValid = true;
    }
    if (newPassword !== confirmNewPassword) {
        setConfirmNewPasswordErr('New Password and Confirm New Password mismatch.');
        isInValid = true;
    }
    if (!formatters.isPasswordValid(newPassword)) {
        setNewPasswordErr('Enter a strong password.');
        isInValid = true;
    } 
    if( isInValid){
        return;
    }
}
    return (
        <Dialog
        open={openModal}
        // onClose={handleCloseDialog}
        className="change-pwd-dialog dialog-box"
        aria-labelledby="change-pwd-dialog-title"
        aria-describedby="change-pwd-dialog-description"
        >
            <DialogTitle id="change-pwd-dialog-title">
                <Typography className='primary-font-bold-heading1'>Enter Password </Typography>
            {/* {openModal ? (
                <img src={CloseIcon} alt="close-icon" style={{cursor: 'pointer'}} onClick={handleCloseDialog} sx={{
                    marginRight: '-30px',
                }}/>
                ) : null} */}
            </DialogTitle>
            <Divider />
            <DialogContent>
              <div>
                  <Typography className='font-normal-small error-message'>{newPasswordErr}</Typography>
                  <Typography className='font-normal-small profile-input-label'>New Password</Typography>
                  <div className="input-holder"> 
                    <input className={`profile-input ${newPasswordErr !== '' ? 'err-input' : ''}`}  aria-label='new-password' type={showPassword ? "text" : "password"} onChange={(e) => {onChangePassword(e, 'newPassword')}}   maxLength={12} value={newPassword}/>
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="password" 
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        className="input-eye-icon pwd-icon1"
                        >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </div>
              </div>
              <div>
                  <Typography className='font-normal-small error-message'>{confirmNewPasswordErr}</Typography>
                  <Typography className='font-normal-small profile-input-label'>Confirm New Password</Typography>
                   <div className="input-holder"> 
                    <input className={`profile-input ${confirmNewPasswordErr !== '' ? 'err-input' : ''}`} aria-label='confirm-new-password' type={showConfirmPassword ? "text" : "password"} onChange={(e) => {onChangePassword(e, 'confirmNewPassword')}}   maxLength={12} value={confirmNewPassword}/>
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="password" 
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                        className="input-eye-icon pwd-icon1"
                        >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </div>
              </div>
            </DialogContent>
            <Divider />
            <DialogActions>
            <div className="action-holder" style={{marginTop: 20, marginBottom: 0}}>
                {/* <Button variant="outlined" className="primary-font-bold-6 action-btn profile-cancel-btn" onClick={handleCloseDialog}>Cancel</Button> */}
                <Button variant="outlined" className="primary-font-bold-6 action-btn profile-save-btn"  onClick={onSaveChangePassword}>Save</Button>
            </div>
            </DialogActions>
        </Dialog>
    )

}
  export default Passworddialog ;