import React from 'react';
import { Button, Box, Container, Grid, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useLocation } from 'react-router-dom';

const PaymentFailed = ({accessBlock}) => {
    const location = useLocation();
    const ownerInfo = location.state?.ownerInfo;
    console.log("ownerInfo",ownerInfo);
    return (
        <Container>
            <Grid container justifyContent="center" alignItems="center" >
                <Grid item md={11}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        p={2}
                        borderRadius={1}
                        textAlign="center"
                        className={`_success _failed ${accessBlock ? "error_wrap" : ""}`}
                    >
                        <CancelIcon fontSize="large" sx={{ color: "red",fontSize:"50px" }} />
                        {accessBlock && (<>
                            <Typography  className='mt-10'>
                            To continue using your account, please subscribe. For more details please contact <span style={{fontWeight:"bold"}}>{ownerInfo}</span>.
                            </Typography>
                        </>)}
                        {!accessBlock && (<>
                            <Typography variant="h5" component="h5" className='mt-10'>
                                Payment Failed!
                            </Typography>
                            <Typography className="lg-normal-text mb-30 mt-10" style={{ color: '#111111BF' }}>
                                Try again later
                            </Typography>
                        </>)}
                        
                    </Box>

                    {/* <Box display="flex" justifyContent="center" mt={2}>
                        <Button
                            className="normal-text outline-button"
                            style={{ textTransform: 'capitalize', borderRadius: 4 }}
                            variant='outlined'
                        >
                            Retry
                        </Button>
                    </Box> */}
                </Grid>
            </Grid>
        </Container>
    );
};

export default PaymentFailed;
