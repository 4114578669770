import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

import { bindActionCreators } from "redux";
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import { Button, Box, Typography, Divider } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import DatePicker from 'react-date-picker';
import moment from "moment/moment";
import '../../../../customise-style.css';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
//Internal Files
import MyRedFolderLogo from '../../../../assets/images/my-red-folder.svg';
import NEPTALOGO from '../../../../assets/images/Nepta.png';
import Twitter from "../../../../assets/icons/twitter.svg";
import FaceBook from "../../../../assets/icons/facebook-f.svg";
import Insta from "../../../../assets/icons/instagram-i.svg";
import Google from "../../../../assets/icons/google-g.svg";
import Microsoft from "../../../../assets/icons/microsoft.svg";
import User from "../../../../assets/icons/user.svg";
import Password from "../../../../assets/icons/password.svg";
import ErrorIcon from "../../../../assets/icons/error-icon.svg";
import ErrorClose from "../../../../assets/icons/error-close.svg";
import "../../Login/styles/login.css";
import * as formatters from '../../../../utils/util';
import { signUpAccount, fetchRegisterPostData,  } from "../redux/actions";
import {loginWithSocialMedia} from '../../Login/redux/actions';
import { styled } from "@mui/material/styles";

import awsConfig from '../../../../aws-exports';
import constants from '../../../../config/constants';
import { grey } from "@mui/material/colors";
const isLocalhost = constants.COGNITO_ENVIRONMENT;
const redirectSigninUrl = isLocalhost === 'LOCAL' ? constants.CONGNITO_LOCAL_SIGNIN : (isLocalhost === 'DEV' ? constants.CONGNITO_DEV_SIGNIN : constants.CONGNITO_STAGING_SIGNIN)
const redirectSignOutUrl = isLocalhost === 'LOCAL' ? constants.CONGNITO_LOCAL_SIGNOUT : (isLocalhost === 'DEV' ? constants.CONGNITO_DEV_SIGNOUT : constants.CONGNITO_STAGING_SIGNOUT)
const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: redirectSigninUrl,
    redirectSignOut: redirectSignOutUrl,
  }
}
Amplify.configure(updatedAwsConfig);

const DatePickerStyled = styled(DatePicker)({
  backgroundColor: "white",
 
})
const CreateAccount = ({signUpAccount,fetchRegisterPostData, signupData, errorData, registerData,registerError, loginWithSocialMedia,userInfoData}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userName, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [dobErr, setDOBErr] = useState('');
  const [dob, setDOB] = useState(null);


  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickConfirmShowPassword = () => setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  const navigate = useNavigate();

  useEffect(() => {
    Hub.listen('auth', async (data) => {
      const { payload } = data
      console.log('A new auth event has happened: ', data)
       if (payload.event === "cognitoHostedUI") {
        getUserDataUsingSocialSignin({payload:payload.data})
       }
       else if (payload.event === 'signOut') {
         console.log('a user has signed out!')
       } else if (payload.event === 'autoSignIn') {
        window.localStorage.setItem('tokendetails', JSON.stringify(payload.data.signInUserSession));
        navigate("/home");
      }
    })
  }, [])

  const getUserDataUsingSocialSignin = (cognitoObj) => {
    localStorage.setItem('propertytoken', null);
    if(cognitoObj?.payload?.signInUserSession){
      window.localStorage.setItem('tokendetails', JSON.stringify(cognitoObj.payload.signInUserSession));
        const loginObject = {
          email: cognitoObj?.payload?.signInUserSession?.idToken?.payload?.email.trim() || null
        }
          loginWithSocialMedia(loginObject)
    }
  }

  const onChangeUsername = (event) => {
    const inputValue = event.target.value;
    const trimmedValue = inputValue.replace(/^\s+/, '');
    setUsername(trimmedValue);
  };
  
  const onChangeEmail = (event) => {
    const inputValue = event.target.value;
    if (!inputValue.includes(' ')) {
      setEmail(inputValue);
    }
  };
  const onChangePassword = (e) => {
    const inputPwd = e.target.value;
    const trimmedValue = inputPwd.replace(/^\s+/, '');
    setPassword(trimmedValue);
  }
  const onChangeConfirmPassword = (e) => {
    const inputPwd = e.target.value;
    const trimmedValue = inputPwd.replace(/^\s+/, '');
    setConfirmPassword(trimmedValue);
  }
  const onChangeInput = (e) => {
    console.log(e);
    if(e){
      const date =  moment(e).format('MM-DD-YYYY') 
      setDOB(date);
    }else{
      setDOB('');
    }
    
  }
  useEffect(() => {
    if (errorData) {
      setErrorMessage('Email already exists')
    }
    if(signupData){
      if(signupData?.payload?.signInUserSession){
        window.localStorage.setItem('tokendetails', JSON.stringify(signupData.payload.signInUserSession));
      }
      // window.localStorage.setItem('cognitoObject',signupData)
      const payload = {
      name: userName,
      firstName: userName,
      dateOfBirth: dob,
      emailId: email,
      password: password,
      planCode :"basic",
    } 
      fetchRegisterPostData(payload)
    }
    if(registerData && registerData.status === 403){
      navigate('/account-verification')
    } 
  }, [errorData, signupData,registerData]);

  useEffect(()=>{
    if(userInfoData && userInfoData.status === 200 ){
      window.localStorage.setItem('userInfo', JSON.stringify(userInfoData.data));
      navigate('/home');
    }
  },[userInfoData])

  const onCreateAccount = async () => {
    if (!userName?.trim().length) {
      setErrorMessage('This is required.');
      return
    } else {
      setErrorMessage('');
    }
    if (!email?.trim().length) {
      setErrorMessage('This is required.');
      return
    }  else if (!formatters.isEmailValid(email)) {
      setErrorMessage('Enter valid Email.');
      return
    } else {
      setErrorMessage('');
    }
    if(!dob){
      setErrorMessage('This is required.');
      return
    }else if(dob && formatters.calculateAge(dob) < 17){
      setErrorMessage('Minimum age should be 18 or above');
      return
    }
    if (!password?.trim().length) {
      setErrorMessage('This is required.');
      return
    } else if (!formatters.isPasswordValid(password)) {
      setErrorMessage('Minimum 8 and maximum 128 characters, at least one uppercase letter, one lowercase letter, one number and one special character');
      return
    } else {
      setErrorMessage('');
    }
    if (!confirmPassword?.trim().length) {
      setErrorMessage('This is required.');
      return
    }  else if (password !== confirmPassword) {
      setErrorMessage('Password and Confirm Password mismatch.');
      return
    } else {
      setErrorMessage('');
    }
    window.localStorage.setItem('registeremail', email);
    signUpAccount({
      "username": email,
      "password": password,
      attributes: { 
        email: email,
        // name: userName
      },
      autoSignIn: { // optional - enables auto sign in after user is confirmed
        enabled: true,
      }

    })
     
      
  }
 
  return (
    <Box
    className="loginScreen"
    id='login-layout'
  >
    <div id='login-container'>
      <div id="login-title">
        <img src={MyRedFolderLogo} alt='myRedFolderLogo' href="#" className="login-title-img" />
        <img src={NEPTALOGO} alt='Nepta-logo' href="#"  width={172}/>
      </div>
      <div>
        {errorMessage.trim().length > 0 && (
          <div className="error-msg-holder">
            <div style={{display: 'flex'}}>
              <img src={ErrorIcon} alt='error' href="#"  />
              <Typography className="primary-font-normal-4" style={{marginLeft: 10, letterSpacing:'normal'}}>{errorMessage}</Typography>
            </div>
            <img src={ErrorClose} alt='error' href="#"  style={{cursor: 'pointer'}} onClick={()=> {setErrorMessage('')}} />
          </div>
        )}
        <div id="login-content">
        <div className="input-holder"> 
            <div sx={{ p: '10px' }} aria-label="email" className="input-icon-holder">
              <img src={User} alt='userName' />
            </div>
            <input className={`primary-font-normal-1 login-input box-spacing`}  aria-label='userName'onChange={onChangeUsername} value={userName}  placeholder="Username"/>
        </div>
        <div className="input-holder"> 
            <div sx={{ p: '10px' }} aria-label="email" className="input-icon-holder">
              <img src={User} alt='email' />
            </div>
            <input className={`primary-font-normal-1 login-input box-spacing`}  aria-label='email' onChange={onChangeEmail} value={email} placeholder="Email"/>
        </div>
        <div className="input-holder"> 
            <div sx={{ p: '10px' }} aria-label="email" className="input-icon-holder-external-icon">
             <CalendarMonthIcon style={{color:"#c7cbcf"}}/>
            </div>
            <div className="create-account">
            <DatePickerStyled monthPlaceholder={'DOB --'}  className={`primary-font-normal-1 login-input box-spacing`} onChange={(e)=> {onChangeInput(e, 'dob')}} value={dob} maxDate={new Date()}  placeholder="Confirm Password"/>
            </div>
        </div>
        <div className="input-holder"> 
            <div sx={{ p: '10px' }} aria-label="email" className="input-icon-holder">
              <img src={Password} alt='password' />
            </div>
            <input 
            style={{padding:"15px 68px 15px 60px"}}
            className={`primary-font-normal-1 login-input box-spacing`} type={showPassword ? "text" : "password"} aria-label='password' onChange={onChangePassword} value={password} placeholder="Password"/>
            <IconButton type="button" sx={{ p: '10px' }} aria-label="password" 
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            className="input-eye-icon pwd-icon"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
        </div>
        <div className="input-holder"> 
            <div sx={{ p: '10px' }} aria-label="email" className="input-icon-holder">
              <img src={Password} alt='password' />
            </div>
            <input 
            style={{padding:"15px 68px 15px 60px"}}
            className={`primary-font-normal-1 login-input box-spacing`} type={showConfirmPassword ? "text" : "password"} aria-label='cronfirm password' onChange={onChangeConfirmPassword} value={confirmPassword} placeholder="Confirm Password"/>
            <IconButton type="button" sx={{ p: '10px' }} aria-label="confirm password" 
            onClick={handleClickConfirmShowPassword}
            onMouseDown={handleMouseDownConfirmPassword}
            className="input-eye-icon pwd-icon"
            >
              {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
        </div>
    
    
        <Button variant="contained" className="primary-font-bold1 signin-btn" onClick={onCreateAccount}>Create Account</Button>
        <div className='or-divider-holder'>
          <Divider className="divider2"/>
          <div className="or-style">Or</div>
          <Divider className="divider2"/>
        </div>
        <div style={{display: 'flex',justifyContent:'center',gap: '32px',alignItems:'center',padding:20}}>
        <div onClick={() => Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google })}>
            <img src={Google} alt="google" className="image-hover" />
        </div>
        <div onClick={() => Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Facebook })}>
            <img src={FaceBook} alt="facebook" className="image-hover" />
        </div>
        </div>
        <Divider className="divider1"/>
        <Typography className="primary-font-medium" textAlign={'center'} color='#fff'>Already have an account</Typography>
        <Button variant="contained" className="primary-font-bold-5 create-acc-btn" onClick={()=> (navigate('/login'))}>Login</Button>
        </div>
      </div>
    </div>
  </Box>
        
  )
}

// export default LogIn;
CreateAccount.propTypes = {
  signUpAccount: PropTypes.func.isRequired,
  signupData: PropTypes.shape(),
  errorData: PropTypes.shape(),
  isFetchingSignup: PropTypes.bool,
};

CreateAccount.defaultProps = {
  signupData: null,
  errorData: null,
  isFetchingSignup: null,
  registrationData: null,
  registrationErrorData: null
};

function mapStateToProps(state) {
  return {
    signupData: state.signup.data,
    errorData: state.signup.error,
    isFetchingSignup: state.signup.isFetching,
    registerData: state.signup.registerData,
    registerError: state.signup.registerError,
    userInfoData: state.login.userInfoData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      signUpAccount,
      fetchRegisterPostData,
      loginWithSocialMedia
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAccount);

