import React, { useEffect, useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as formatters from '../../../../../../utils/util';
import { useLocation } from "react-router";

const OnlineCredentials = ({onBackOrCancelClick, handleNext, bankAccountData}) => {
  const initialFormData = {
    userName: '',
    code: '',
    password: ''
  };
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialFormData);
 // const [errors, setErrors] = useState(initialFormData);
  // const hideEdit = window.localStorage.getItem("hideEdit") ? window.localStorage.getItem("hideEdit") : false;
  const location = useLocation();
  const hideEdit = location?.state?.hideEdit;

  const [showPassword, setShowPassword] = useState(false);
  const [showCode, setShowCode] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowCode = () => setShowCode(!showCode);
  const handleMouseDownCode = () => setShowCode(!showCode);

  useEffect(()=>{
    if(bankAccountData){
      console.log(bankAccountData)
      const initialFormData = {
        userName: bankAccountData?.onlineUserName,
        code: bankAccountData?.onlineCode,
        password: bankAccountData?.onlinePassword
      };
      setFormData(initialFormData)
    }
    
  },[])

  const onChangePassword = (e) => {
    const inputPassword = e.target.value;
    const trimmedValue = inputPassword.replace(/^\s+/,'');
    setFormData({
      password : trimmedValue,
    })
  }
 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // const newErrors = {};
    // if (!formData.userName || formData?.userName.trim() === '') {
    //   newErrors.userName = 'This is required.';
    // }
    // if (!formData.password || formData?.password.trim() === '') {
    //   newErrors.password = 'This is required.';
    // }
    // if (!formData.code || formData?.code.trim() === '') {
    //   newErrors.code = 'This is required.';
    // } else if (formData?.code.trim().length < 8 || formData?.code.trim().length > 11){
    //   newErrors.code = 'Code should be 8-11 characters';
    // }

   // if (Object.length === 0) {
      // setErrors({});
   
      const accountInfo = {
        "onlineUserName": formData.userName,
        "onlinePassword": formData.password,
        "onlineCode": formData.code,
      }
      const accountDetails = {...bankAccountData, ...accountInfo}
      dispatch({
        type:"ADD_BANK_DETAILS",
        payload: accountDetails
      })
      handleNext();
    // } else {
    //   setErrors(newErrors);
    //   console.log('Validation errors:', newErrors);
   // }
  };
return (
    <Grid container  className="account-info-container">
        <Grid item xs={12} md={6} sx={{padding: 0}}>
            <div className="account-info-padding" >
                <form>
                <div>
                    {/* <Typography className='font-normal-small error-message'>{errors.userName}</Typography> */}
                    <Typography className='font-normal-small profile-input-label'>Username 
                    {/* <span style={{color:"red"}}>{" *"}</span> */}
                    </Typography>
                    <input autoComplete="off" type="text" name="userName" id="username" disabled={hideEdit}  className={`profile-input`} aria-label='username' onChange={handleInputChange}  maxLength={25} value={formData.userName}/>
                </div>
                </form>
                <form>
               <div>
                    {/* <Typography className='font-normal-small error-message'>{errors.password}</Typography> */}
                    <Typography className='font-normal-small profile-input-label'>Password 
                    {/* <span style={{color:"red"}}>{" *"}</span> */}
                    </Typography>
                    <div className="input-holder"> 
                        <input autoComplete="off" name="password" id="password"  className={`profile-input`} aria-label='password'disabled={hideEdit}  type={showPassword ? "text" : "password"}  onChange={onChangePassword}  maxLength={25} value={formData.password}/>
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="password" 
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        className="input-eye-icon pwd-icon1"
                        >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </div>
                </div>
                </form>
            </div>
        </Grid>
        <Grid item xs={12} md={6} sx={{padding: 0}}>
            <div className="account-info-padding">
              <form>
              <div>
                    {/* <Typography className='font-normal-small error-message'>{errors.code}</Typography> */}
                    <Typography className='font-normal-small profile-input-label'>Code 
                    {/* <span style={{color:"red"}}>{" *"}</span> */}
                    </Typography>
                    <div className="input-holder"> 
                        <input autoComplete="off" name="code" id="code" disabled={hideEdit} className={`profile-input`}  aria-label='Code' type={showCode ? "text" : "password"} onChange={handleInputChange} value={formatters.formatCharacters(formData.code)}/>
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="code" 
                        onClick={handleClickShowCode}
                        onMouseDown={handleMouseDownCode}
                        className="input-eye-icon pwd-icon1"
                        >
                        {showCode ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </div>
                </div>
              </form>
               
            </div>
        </Grid>
        <div className="action-holder action-holder-3 btn-holder-margin" >
                <Button variant="outlined"  className="primary-font-bold-6 btn-width profile-cancel-btn" onClick={onBackOrCancelClick}>Back</Button>
                <Button variant="outlined" className="primary-font-bold-6 btn-width profile-save-btn"  onClick={handleSubmit}>Next</Button>
              </div>
    </Grid>
    )
}


OnlineCredentials.defaultProps = {
  isFetching: null,
  error: null,
  familyMembers: [],
};

function mapStateToProps(state) {
  return {
    bankAccountData : state.bank.bankAccountData
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnlineCredentials);

