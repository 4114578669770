const initialState = {
  error: null,
  isAPIProcessing: false,
  isFetching: false,
  propertyInventoryData: null,
  propertyPictureData: null,
  multipleImageData: null,
  uploadCompanyImage: null,
  propertyPictureError: null,
  multipleImageError: null,
  uploadCompanyImageErr : null,
  propertyVideoData: null,
  propertyVideoError: null,
  propertyUpdateResponse: null,
  propertyInventoryList:[],
  homeType: null,
  deleteSpecificInventory: null,
  copyPropertyInventory: null,
  downloadPropertyInventory: null,
  propertyListData:[],
  //propertyFinancingdata:null,
  propertyType:null,
};

export const propertyReducer = (state = initialState, action) => {
  const { type, payload } = action;
  let currentState = state;
  switch (type) {
    case 'ADD_PROPERTY_INVENTORY':
      currentState = {
        ...state,
        propertyInventoryData: payload,
      };
      break;
    case 'PROPERTY_UPDATE_SUCCESS':
      currentState = {
        ...state,
        propertyUpdateResponse: payload,
      };
      break;
    case 'PROPERTY_UPDATE_FAILURE':
      currentState = {
        ...state,
        propertyUpdateResponse: payload,
      };
      break;
    case 'PROPERTY_IMAGE_UPDATE_REQUEST':
      currentState = {
        ...state,
        propertyPictureError: payload,
      };
      break;
    case 'PROPERTY_IMAGE_UPDATE_SUCCESS':
      currentState = {
        ...state,
        propertyPictureData: payload,
        propertyPictureError: null,
      };
      break;
    case 'PROPERTY_IMAGE_UPDATE_FAILURE':
      currentState = {
        ...state,
        propertyPictureData: null,
        propertyPictureError: payload,
      };
      break;
      case 'MULTIPLE_IMAGE_UPDATE_REQUEST':
        currentState = {
          ...state,
          multipleImageError: payload,
        };
        break;
      case 'MULTIPLE_IMAGE_UPDATE_SUCCESS':
        currentState = {
          ...state,
          multipleImageData: payload,
          multipleImageError: null,
        };
        break;
      case 'MULTIPLE_IMAGE_UPDATE_FAILURE':
        currentState = {
          ...state,
          multipleImageData: null,
          multipleImageError: payload,
        };
        break;
    case 'PROPERTY_VIDEO_UPDATE_SUCCESS':
      currentState = {
        ...state,
        propertyVideoData: payload,
        propertyVideoError: null,
      };
      break;
    case 'PROPERTY_VIDEO_UPDATE_FAILURE':
      currentState = {
        ...state,
        propertyVideoData: null,
        propertyVideoError: payload,
      };
      break;
    case 'HOME_TYPE_REQUEST':
      currentState = {
        ...state,
        isFetching: true,
        error: null,
      };
      break;
    case 'HOME_TYPE_SUCCESS':
      currentState = {
        ...state,
        homeType: payload,
        isFetching: false,
        error: null,
      };
      break;
    case 'HOME_TYPE_FAILURE':
      currentState = {
        ...state,
        isFetching: false,
        error: payload,
      };
      break;
    case 'PROPERTY_DATA_SUCCESS':
        currentState = {
          ...state,
          propertyInventoryList: payload,
        };
        break;
      case 'DELETE_PROPERTY_SUCCESS_OBJECT':
        currentState = {
          ...state,
          deleteSpecificInventory: payload,
        };
        break;
      case 'DELETE_PROPERTY_FAILURE_OBJECT':
        currentState = {
          ...state,
          deleteSpecificInventory: payload,
        };
        break;
      case 'COPY_PROPERTY_SUCCESS_OBJECT':
        currentState = {
          ...state,
          copyPropertyInventory: payload,
        };
        break;
        case 'DOWNLOAD_PROPERTY_DATA_SUCCESS':
          currentState = {
            ...state,
            downloadPropertyInventory: payload,
          };
          break;
          case 'PROPERTY_FETCH_SUCCESS':
            currentState = {
              ...state,
              propertyListData: payload,
            }
          break;

          case 'PROPERTY_TYPE_REQUEST':
            currentState = {
              ...state,
              isFetching:true,
              error:null,
            }
          break;

          case 'PROPERTY_TYPE_SUCCESS':
            currentState ={
              ...state,
              propertyType:payload,
              error:null
            }
          break

          case 'PROPERTY_TYPE_FAILURE':
            currentState = {
              ...state,
              isFetching:false,
              error:payload
            }
          break
    case 'PROPERTY_IMAGE_UPLOAD_SUCCESS':
      currentState = {
        ...state,
        uploadCompanyImage: payload,
        uploadCompanyImageErr: null,
      };
      break;
    case 'PROPERTY_IMAGE_UPLOAD_FAILURE':
      currentState = {
        ...state,
        uploadCompanyImage: null,
        uploadCompanyImageErr: payload,
      };
      break;

          
    default:
  }
  return currentState;
}


