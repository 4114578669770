import { Auth } from 'aws-amplify';
import axios from './axios';

export const postBase =  async (url, body, params) => {
  try {
    // const tokenData = await token();
    const session = window.localStorage.getItem('tokendetails');
    const tokenData = JSON.parse(session);
    const headers = {
      Authorization: `Bearer ${tokenData?.idToken?.jwtToken}`,
      "Access-Control-Allow-Headers" : "*",
      "Access-Control-Allow-Origin": "*",
      "Accept": "application/json"
    };
    return await axios.post(url, body.jsonData, { headers, ...params });
  } catch (e) {
    if (!e || !e.response || e.response.status !== 401) throw e;
    localStorage.setItem('url', `${window.location.pathname}${window.location.search}`);
    try {
      // return axios.post(url, body, { ...defaultParams(), ...params });
      const { config } = e;

      const authenticatedUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();

      return new Promise((resolve, reject) => {
        authenticatedUser.refreshSession(currentSession.refreshToken, (err, session) => {
          if (session) {

            window.localStorage.setItem('tokendetails', JSON.stringify(session));

            const headers = {
              Authorization: `Bearer ${session?.idToken?.jwtToken}`,
            };
            config.headers = headers;
            config.data = body.jsonData;

            // config.headers.Authorization = `bearer ${session.accessToken.jwtToken}`;

            axios.request(e.config).then((response) => {
              resolve(response);
            }).catch((error) => {
              reject(error);
            });
          }
        });
      });

    } catch (err) {
      return null;
    }
  }
};
