import React, { useState, useEffect } from "react";
import "../../../../../../index.css";
import { Grid, Button, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteIcon from '../../../../../../assets/icons/delete-field-icon.svg'
import { connect , useDispatch} from 'react-redux';
import { bindActionCreators } from "redux";
import { updateRelativesToNotify, getRelativesToNotify, deleteRelativesToNotify } from "../../../redux/actions";
import * as formatters from '../../../../../../utils/util';

const RelativesToNotify = ({ onContinue, onCancel,onView,selectedUser, getRelativesToNotify, updateRelativesToNotify, userRelativeList, userRelativeUpdate, deleteRelativesToNotify, updatedUserRelativeList }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [relation, setRelation] = useState(null);
  const [relativesLists, setRelativesLists] = useState([ ]);
 const relationErr = "";
 const id = selectedUser?.userId;

 const onChangeInput = (e, property, id) => {
    const updatedRelatives = relativesLists.map((relative) => {
      if (relative.id === id) {
        return { ...relative, [property]: e.target.value };
      }
      return relative;
    });

    setRelativesLists(updatedRelatives);
  }
const handleAddClick = () => {
    const newRow = { id: new Date(), name: "", relationship: "", phoneNo: "" , createdBy: id, updatedBy: id};
    setRelativesLists(prevData => [...prevData, newRow]);
};

const handleRemoveClick = (idToRemove) => {
  console.log("idToRemove",idToRemove)
  if(typeof idToRemove !== "number") {
    setRelativesLists(prevData => prevData.filter(row => row.id !== idToRemove));
  } else {
    setRelativesLists(prevData => prevData.filter(row => row.id !== idToRemove));
    deleteRelativesToNotify(idToRemove)
  }
};
useEffect(() => {
  if(selectedUser?.userId){
    getRelativesToNotify(selectedUser?.userId);
  }
}, [selectedUser?.userId])

useEffect(()=>{
  if(userRelativeList && userRelativeList.status === 200){
    const data = userRelativeList.data;
   setRelativesLists(data)
  }
},[userRelativeList])

// useEffect(()=>{
//   if(updatedUserRelativeList && updatedUserRelativeList.status === 200){
//     getRelativesToNotify(selectedUser?.userId);
//     setRelativesLists(updatedUserRelativeList?.data || [])
//   }
// },[updatedUserRelativeList])

useEffect(() => {
  if (userRelativeUpdate && userRelativeUpdate.status === 200) {
      dispatch({
        type:"RESET_RELATIVES_TO_NOTIFY",
        action: null
      })
      onContinue()
  } else {
  }
}, [userRelativeUpdate])


const onSave = () => {
  const updatedData = [...relativesLists];
  const newUpdatedData = []
    updatedData.forEach((item) => {
      if (typeof item.id !== 'number') {
        item.id = null;
      }
      if(item.name.trim() === "" && item.relationship.trim()=== "" && item.phoneNo.trim()===""){
        console.log("empty array")
      }else{
        newUpdatedData.push(item)
      }
    });

  const payload = {
    userId: selectedUser?.userId || "",
    userRelativeList: newUpdatedData
  }
  updateRelativesToNotify(payload);
}

  return (
    <Grid container spacing={2} sx={{margin:0,width:"100% !important"}}>
      
        
        <Grid container spacing={2} className="bigsize-device" style={{margin:0, height: 'auto'}}>
            <Grid item container spacing={2} className="pl-0">
                <Grid item xs={3.5} md={4} className="font-bold-small">Name</Grid>
                <Grid item xs={3.5} md={4} className="font-bold-small">Relationship</Grid>
                <Grid item xs={5} md={3} className="font-bold-small">Phone Number</Grid>
                <Grid item xs={0} md={1} className="font-bold-small"></Grid>
            </Grid>
            {relativesLists.map((obj, index) => (
                        <Grid item container spacing={2} className="pl-0" key={obj.id}>
                          <Grid item xs={3.3} md={4}><input className={`profile-input mb-0`} aria-label='name'  onChange={(e) => onChangeInput(e, 'name', obj.id)}  defaultValue={obj.name}/></Grid>
                          <Grid item xs={3.3} md={4}><input className={`profile-input mb-0`}  aria-label='relation'  onChange={(e) => onChangeInput(e, 'relationship', obj.id)} defaultValue={obj.relationship}/></Grid>
                          <Grid item xs={3.4} md={3}><input className={`profile-input mb-0`}  aria-label='phoneNo'  onChange={(e) => onChangeInput(e, 'phoneNo', obj.id)}  defaultValue={obj.phoneNo} maxLength={10} value={formatters.formatPhoneNumber(obj.phoneNo)}/></Grid>
                          <Grid item xs={2} md={1} style={{display: 'flex', justifyContent: 'end'}}>
                          <div className="delete-icon-btn" onClick={() => handleRemoveClick(obj.id)}><img src={DeleteIcon}/></div>
                          </Grid>
                        </Grid>
            ))}
            <Button variant="outlined" onClick={handleAddClick} className="primary-font-bold-5 action-btn profile-save-btn full-width add-field-btn">
                <span className="add-field-icon"></span>Add Field
            </Button>
            <div className="action-holder action-bg">
                <Button variant="outlined" className="primary-font-actionbtn action-btn profile-cancel-btn" onClick={onCancel}>Back</Button>
                <Button variant="outlined" className="primary-font-actionbtn action-btn profile-save-btn" onClick={onSave}>Save & Continue</Button>
            </div>
        </Grid>
        <Grid container spacing={2} className="smallsize-device" sx={{margin:0, height: 'auto', width:"100% !important"}}>
            {/* <Grid item container spacing={2} className="pl-0">
                <Grid item xs={3.5} md={4} className="font-bold-small">Name</Grid>
                <Grid item xs={3.5} md={4} className="font-bold-small">Relationship</Grid>
                <Grid item xs={5} md={3} className="font-bold-small">Phone Number</Grid>
                <Grid item xs={0} md={1} className="font-bold-small"></Grid>
            </Grid> */}
            {relativesLists.map((obj, index) => (
                        <Grid  container className="pl-0"sx={{m:0,width:"100% !important"}} key={obj.id}>
                          <Grid sx={{pt:"16px"}} xs={12} md={4} className="font-bold-small">Name</Grid>
                          <Grid sx={{pt:"16px"}} xs={12} md={4}><input className={`profile-input mb-0`} aria-label='name'  onChange={(e) => onChangeInput(e, 'name', obj.id)}  defaultValue={obj.name}/></Grid>
                          <Grid sx={{pt:"16px"}} xs={12} md={4} className="font-bold-small">Relationship</Grid>
                          <Grid sx={{pt:"16px"}} xs={12} md={4}><input className={`profile-input mb-0`}  aria-label='relation'  onChange={(e) => onChangeInput(e, 'relationship', obj.id)} defaultValue={obj.relationship}/></Grid>
                          <Grid sx={{pt:"16px"}} xs={12} md={3} className="font-bold-small">Phone Number</Grid>
                          <Grid sx={{pt:"16px",mr:"10px"}} xs={9.5} md={3}><input className={`profile-input mb-0`}  aria-label='phoneNo'  onChange={(e) => onChangeInput(e, 'phoneNo', obj.id)}  defaultValue={obj.phoneNo} maxLength={10} value={formatters.formatPhoneNumber(obj.phoneNo)}/></Grid>
                          <Grid sx={{pt:"16px",pl:"10px"}}xs={2} md={1} style={{display: 'flex', justifyContent: 'end'}}>
                          <div className="delete-icon-btn" onClick={() => handleRemoveClick(obj.id)}><img src={DeleteIcon}/></div>
                          </Grid>
                        </Grid>
            ))}
            <Button variant="outlined" onClick={handleAddClick} className="primary-font-bold-5 action-btn profile-save-btn full-width add-field-btn">
                <span className="add-field-icon"></span>Add Field
            </Button>
            <div style={{marginTop: onView?"20px":"40px",marginBottom:onView?"20px":"40px"}} className="action-holder action-bg">
                <Button variant="outlined" className="primary-font-actionbtn action-btn profile-cancel-btn" onClick={onCancel}>Back</Button>
                <Button variant="outlined" className="primary-font-actionbtn action-btn profile-save-btn" onClick={onSave}>Save & Continue</Button>
            </div>
        </Grid>
     
    </Grid>
  );
};

RelativesToNotify.defaultProps = {
  isFetching: null,
  error: null,
};

function mapStateToProps(state) {
  return {
    isFetching: state.familyInfo.isFetching,
    error: state.familyInfo.error,
    userRelativeUpdate: state.familyInfo.userRelativeUpdate,
    userRelativeList: state.familyInfo.userRelativeList,
    updatedUserRelativeList: state.familyInfo.updatedUserRelativeList
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateRelativesToNotify,
      getRelativesToNotify,
      deleteRelativesToNotify
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RelativesToNotify);
