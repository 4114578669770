import React, { useEffect, useState } from "react";
// import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Typography, Button, Dialog, DialogTitle, Divider, DialogContent, DialogActions } from "@mui/material";
import CloseIcon from "../../../../../assets/icons/modal-close-icon.svg";
import {  List } from "antd";
import constants from "../../../../../config/constants";
import { updateMeetingLocationAPI, addMeetingLocationAPI} from "../../redux/actions";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as formatters from '../../../../../utils/util';
import "../MeetingLocations/styles/MeetingLocations.css";
import"../DisasterManagement/styles/DisasterManagement.css";

const EditMeetingLocation = ({ openDialog, onClose,selectedCard, isLocationAPIRequest, updateLocation, errrLocation, updateMeetingLocationAPI, addMeetingLocationAPI, addMeetingLocation }) => {
      const userDetails = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')): null;
      const {ready,  setValue, suggestions: {status, data}, clearSuggestions} = usePlacesAutocomplete();

      const [streetAddress, setStreetAddress] = useState("");
      const [city, setCity] = useState("");
      const [state, setState] = useState("");
      const [zip, setZip] = useState("");
      const [country, setCountry] = useState("");
      const [location, setLocation] = useState({lat:null, lng:null});
      const [locationName,setLocationName] = useState("");
      const [error,setErrors] = useState({
        locationNameErr: '',
        streetAddressErr: '',
        cityErr : '',
        stateErr: '',
        countryErr:'',
      })

      useEffect(()=>{
        console.log(updateLocation)
        if(updateLocation && updateLocation.status === 200){
          onClose(true);
        
        }
        if(updateLocation && updateLocation.status === 500){
          const errorState = {...error}
          errorState['locationNameErr'] = 'Something went wrong';
          setErrors(errorState)
        }

      },[updateLocation]);

      useEffect(()=>{
        if(addMeetingLocation && addMeetingLocation.status === 200){
          onClose(true);
        }
        if(addMeetingLocation && addMeetingLocation.status === 500){
          const errorState = {...error}
          errorState['locationNameErr'] = 'Something went wrong';
          setErrors(errorState)
        }

      },[addMeetingLocation]);

      useEffect(()=>{
        if(errrLocation && errrLocation.status === 500){
          const errorState = {...error}
          errorState['locationNameErr'] = 'Something went wrong';
          setErrors(errorState)
        }
      },[errrLocation]);

      useEffect(()=>{
        if(selectedCard?.id){
          setStreetAddress(selectedCard.address);
          setCity(selectedCard?.city);
          setCountry(selectedCard?.country);
          setZip(selectedCard?.zip);
          setLocation({lat:selectedCard.locationLat,lng: selectedCard?.locationLong});
          setLocationName(selectedCard?.name);
          setState(selectedCard?.state);
        } else if(selectedCard.isAdd){
          setLocationName(selectedCard?.name);
        }
      },[selectedCard])

      const handleSelect =  async (item) => {
        setCity("");
        setState("");
        setCountry("");
        setStreetAddress("");
        setValue(item.description,false);
        if(item && item.terms && item.terms.length > 3){
          const length = item.terms.length;
          console.log("I am stage 1", length)
          setCountry(item.terms[length-1]?.value);
          setState(item.terms[length-2]?.value);
          setCity(item.terms[length-3]?.value)
          let street = '';
          for(let i=0;i<length-3;i++){
            street = street + (i !==0 ? ', ': "" )+ item.terms[i]?.value;
          }
          setStreetAddress(street)
        }
        else if(item?.terms && item.terms.length === 3){
          const length = item.terms.length;
          console.log("I am stage 2", length)
          setCountry(item.terms[length-1]?.value);
          setCity(item.terms[length-2]?.value)
          let street = '';
          for(let i=0;i<length-2;i++){
            street = street + (i !==0 ? ', ': "" )+ item.terms[i]?.value;
          }
          setStreetAddress(street)
        }
        else if(item?.terms && item.terms.length === 2){
          const length = item.terms.length;
          console.log("I am stage 3", length)
          setCountry(item.terms[length-1]?.value);
          let street = '';
          for(let i=0;i<length-1;i++){
            street = street + (i !==0 ? ', ': "" )+ item.terms[i]?.value;
          }
          setStreetAddress(street)
        }
        else if(item?.terms && item.terms.length === 1){
          console.log("I am stage 4", item.terms.length)
          setStreetAddress(item.terms[0]?.value)
        }else{
          console.log("I am stage 5", item.terms.length)
          setStreetAddress(item.description)
        }
        clearSuggestions();
          const result = await getGeocode({placeId : item.place_id})
                const {lat, lng} = await getLatLng(result[0])
          setLocation({lat:lat, lng:lng})
      };

      const updateLocationData = () => {
        const errorState = {...error}
        let isInvalid = false;
        if(!locationName || locationName === '' || locationName?.trim().length === 0){
          errorState['locationNameErr'] = 'This is required';
          isInvalid = true;
        }  if(!streetAddress  || streetAddress === ''  || streetAddress?.trim().length === 0){
          errorState['streetAddressErr'] = 'This is required';
          isInvalid = true;
        }  if(!city  || city === '' || city?.trim().length === 0){
          errorState['cityErr'] = 'This is required';
          isInvalid = true;
        }  if(!state  || state === '' || state?.trim().length === 0){
          errorState['stateErr'] = 'This is required';
          isInvalid = true;
        }  if(!country || country === ''  || country?.trim().length === 0){
          errorState['countryErr'] = 'This is required';
          isInvalid = true;
        }
        if(isInvalid){
          setErrors(errorState)
          return
        }

        if(selectedCard?.id){
          const payload = {
            name: locationName,
            address: streetAddress,
            city: city,
            country: country,
            locationLat : location.lat?.toString(),
            locationLong : location.lng?.toString(),
            zip: zip,
            state: state,
            id: selectedCard.id,
            accountId: selectedCard?.accountId
        }
        payload['updatedBy'] =  userDetails?.id;
        updateMeetingLocationAPI(payload)
        }else if(selectedCard.isAdd){
          const payload = {
            name: locationName,
            address: streetAddress,
            city: city,
            country: country,
            locationLat : location.lat?.toString(),
            locationLong : location.lng?.toString(),
            zip: zip,
            state: state,
            accountId: userDetails?.accountId
        }
        payload['createdBy'] =  userDetails?.id;
        addMeetingLocationAPI(payload)
        }
       

        
      }

    return (
        <Dialog
            open={openDialog}
            onClose={onClose}
            className="change-pwd-dialog dialog-box location-box"
            aria-labelledby="change-pwd-dialog-title"
            aria-describedby="change-pwd-dialog-description"
        >
            <DialogTitle id="change-pwd-dialog-title">
                <Typography className='primary-font-bold-heading2'>Meeting Locations</Typography>
                {openDialog ? (
                    <img src={CloseIcon} alt="close-icon" style={{ cursor: 'pointer',width:"21px" }} onClick={onClose} sx={{
                        marginRight: '-30px',
                    }} />
                ) : null}
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Typography className='font-normal-small error-message'>{error.locationNameErr}</Typography>
                <Typography className='font-normal-small profile-input-label'>Location Name<span style={{color:"red"}}>{" *"}</span></Typography>
                <input className={`profile-input  ${error.locationNameErr !== '' ? 'err-input' : ''}`} aria-label='location name' value={locationName} onChange={(e)=>{setLocationName(e.target.value)}} disabled={selectedCard.isAdd}/>

                <Typography className='font-normal-small error-message'>{error.streetAddressErr}</Typography>
                <Typography className='font-normal-small profile-input-label'>Street Address<span style={{color:"red"}}>{" *"}</span></Typography>
                <input type="text" className={`profile-input ${error.streetAddressErr !== '' ? 'err-input' : ''}`} name="streetAddress" id="streetAddress" aria-label='streetAddress' value={streetAddress} onChange={(e)=>{setValue(e.target.value); setStreetAddress(e.target.value)}} disabled={!ready}/>
                  {status === 'OK' && (
                                        <List
                                          dataSource={data}
                                          style={{overflowY:"auto",  maxHeight: "200px",}}
                                          bordered = {data && data.length > 0 ? true : false}
                                          renderItem={(item) => (
                                            <List.Item
                                            onClick={()=>{handleSelect(item)}}
                                          >
                                              <List.Item.Meta title={item.description} />
                                            </List.Item>
                                          )}
                                        />
                                      )
                }
                <div>
                    <div>
                       <Typography className='font-normal-small error-message'>{error.cityErr}</Typography>
                        <Typography className='font-normal-small profile-input-label'>City<span style={{color:"red"}}>{" *"}</span></Typography>
                        <input className={`profile-input ${error.cityErr !== '' ? 'err-input' : ''}`} aria-label='city' value={city} onChange={(e)=>{setCity(e.target.value)}}/>
                    </div>
                    <div>

                        <Typography className='font-normal-small error-message'>{error.stateErr}</Typography>
                        <Typography className='font-normal-small profile-input-label'>State<span style={{color:"red"}}>{" *"}</span></Typography>
                        <input className={`profile-input ${error.stateErr !== '' ? 'err-input' : ''}`} aria-label='state' value={state} onChange={(e)=>{setState(e.target.value)}}/>
                    </div>
                </div>
                <div>
                    <div>
                        <Typography className='font-normal-small profile-input-label'>Zip</Typography>
                        <input className={`profile-input`} aria-label='zip code' maxLength={5}  value={formatters.formatNumber(zip)} onChange={(e)=>{setZip(e.target.value)}} />
                    </div>
                    <div>
                        <Typography className='font-normal-small error-message'>{error.countryErr}</Typography>
                        <Typography className='font-normal-small profile-input-label'>Country<span style={{color:"red"}}>{" *"}</span></Typography>
                        <input className={`profile-input ${error.countryErr !== '' ? 'err-input' : ''}`} aria-label='Country' value={country} onChange={(e)=>{setCountry(e.target.value)}}/>
                    </div>
                </div>
            </DialogContent>
            <Divider />
            <DialogActions>
                <div className="action-holder cancel-save-btn" style={{ marginTop: 20, marginBottom: 0 }}>
                    <Button variant="outlined" className="primary-font-bold-6 btn-width profile-cancel-btn" disabled={isLocationAPIRequest} onClick={onClose}>Cancel</Button>
                    <Button variant="outlined" className="primary-font-bold-6 btn-width profile-save-btn" disabled={isLocationAPIRequest}  onClick={updateLocationData} >Save</Button>
                </div>
            </DialogActions>
        </Dialog>
    )
};


function mapStateToProps(state) {
  return {
    isLocationAPIRequest: state.setting.isLocationAPIRequest,
    updateLocation: state.setting.updateLocation,
    errrLocation: state.setting.errrLocation,
    addMeetingLocation: state.setting.addMeetingLocation
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMeetingLocationAPI,
      addMeetingLocationAPI
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditMeetingLocation);


