import React, { useState, useEffect } from "react";
import ContentWrapper from "../../../../common/components/ContentWrapper";
import { styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { Navigate } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Checkbox,
  Dialog,
  Alert,
  Collapse,
  IconButton,
  Tab,
  Tabs,
  Box
} from "@mui/material";
import "../styles/MyProfile.css"
// import { Box, Grid, TextField, Divider, useMediaQuery,Input,FormControl} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { before } from "lodash";
import { Scrollbars } from "react-custom-scrollbars-2";
import PaymentDialog from "./PaymentDialog";
import { use } from "i18next";
import SecurityLockDialog from "../../Home/components/SecurityLockDialog";
import CreateSecurityLockDialog from "../../Home/components/CreateSecurityLockDialog";
import SecurityPinDialog from "../../Home/components/SecurityPinDialog";




function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        borderTop: 'none !important',
      }}
    >
      {value === index && (
        <Box sx={{ mt: '60px' }}>
          <Grid >{children}</Grid>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const StyledCard = styled(Card)({
  variant: "outlined",
  border: "2px solid #D8D8D8",
  borderRadius: "20px",
  boxShadow: "8px 8px 8px #ccc ",
  padding: 10,
  // width:'350px',
  overflow:'visible',
  '&:hover': {
    border: "2px solid #ff3535" ,
    boxShadow: "12px 12px 12px #ccc ",
  }
});
const Plantype = [
  {
    title: "Premium",
    content: "Subscription renewal date",
    date: "September 29, 2023 (in 17 days)",
  },

  // Add more data as needed
];
const BillingInformation = [
  {
    title: "Billing Information",
    name: "John Doe",
    address: "15703 Pines Blvd",
    location: "Pembroke Pines, FL - 33027",
  },
];
const PaymentMethod = [
  {
    title: "Payment Method",
    card: "credit card",
  },
];

const MainLayout = () => {
  const [promoCode, setPromoCode] = useState();
  const [openAlert, setOpenAlert] = useState();
  const [selectPlan, setSelectPlan] = useState(null);
  const [value, setValue] = useState(0);
  const [paymentDialog,setPaymentDialog] = useState(false)
  const [userChosenPlan,setUserChosenPlan] = useState({})
  const [isLockSet,setIsLockSet] = useState(false)
  const [isPinSet,setIsPinSet] = useState(false)


  const handleChange = (event, newValue) => {
    setValue(newValue);

  };


  const handleClick = (plan,planType, price) =>{
  
    const PlanData =  
    { 
      "transactionPlanType" :plan , 
      "transactionPlanDuration" :planType , 
      "amount":price
    } ;
    setUserChosenPlan(PlanData)
    setPaymentDialog(true)
  } 

  const handleClose  =() =>{
    setPaymentDialog(false)

  }

  const navigate = useNavigate();

  const BasicFeatures = [
    "iOS/Android MYREDFOLDER® APP.",
    "MYREDFOLDER® Family Chat Room (Non-Emergency/Emergency)."
  ];

  const premiumFeatures = [
    "Access critical documents",
    // "MYREDFOLDER® pdf plan",
    // "MYREDFOLDER® pdf home inventory",
    "MYREDFOLDER® Damage Assessment Forms",
    "MYREDFOLDER® Structural Analysis Report",
    "MYREDFOLDER® Access to MY FILES",
    "MYREDFOLDER® MY Business",
    "MYREDFOLDER® MY PET Information",
    "MYREDFOLDER® MY Property(s) Inventory",
    "MYREDFOLDER® “Neighbor Helps Neighbor” Agreement",
    "MYREDFOLDER® MY Family Medications/Medical History",
    "MYREDFOLDER® MY Tax Documents",
    "MYREDFOLDER® data storage device (discounted additional purchase)",
    "MYREDFOLDER® Emergency Response eBook",
    "MYREDFOLDER® Certification (Family Preparedness Certificate)",
    "MYREDFOLDER® Advance HealthCare Directive",
    "MYREDFOLDER® MY Family Trusts Documents",
    "MYREDFOLDER® Personal Email Reminders",
    "MYREDFOLDER® Monthly Newsletter",
    "MYREDFOLDER® Family Chat Room (Non-Emergency/Emergency)",
    "MYREDFOLDER® Dedicated Help Desk",
    "MYREDFOLDER® Tutorial Video"
  ];
  const userDetails = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : null;


  useEffect(() => {
    

    if(userDetails && userDetails?.securityPin){
      setIsPinSet(false)
    }else{
      setIsPinSet(true)
    }

    if(userDetails && userDetails?.securityLock){
      setIsLockSet(false)
    }else{
      setIsLockSet(true)
    }


  },[userDetails])
  const PlanCard = ({ planType, Basicprice, premiumPrice }) => {
    return (
      <>
        <Grid className="pricing-card-alignment">
          <StyledCard className="pricing-card-width">
            <CardContent sx={{ pt: "5px" }}>
              <Grid>
                <Typography className="primary-font-bold1" sx={{ textAlign: 'center' }}>
                  Basic Plan
                </Typography>
                <Typography className="primary-font-bold1 pricing-plan-font" sx={{ textAlign: 'center' }}>
                  {planType} pay: <span style={{ color: '#ff3535' }}>  ${Basicprice} </span>
                </Typography>
              </Grid>
              <Divider sx={{ margin: '15px 0px' }} />
              <Grid sx={{ mt: '10px' }}>
                <Typography className='primary-font-bold-5'>
                  Features:
                </Typography>
                <Scrollbars style={{ height: '250px' }}>
                  <div style={{ paddingRight: '15px' }}>
                    <ul className='bullet-list'>
                      {BasicFeatures.map((item) =>
                        <div style={{ display: 'flex' }}>
                          <div> <li className='list' /> </div>
                          <div style={{ marginBottom: '15px' }} className='primary-font-medium-1 pricing-features-font'>{item}</div>
                        </div>
                      )}
                    </ul>
                  </div>
                </Scrollbars>
              </Grid>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center' }}>
              <Button
                sx={{ borderRadius: '15px', minWidth: '80% !important' }}
                variant="outlined"
                className="primary-font-bold-5 action-btn-1 profile-cancel-btn"
                onClick={() => {
                  handleClick("basic",planType , Basicprice)
                }}
              >
                Get Started
              </Button>
            </CardActions>
          </StyledCard>

          <StyledCard className="pricing-card-width" sx={{ border: '2px solid #ff3535' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ width: '180px', marginTop: '-20px', zIndex: 1, borderRadius: '30px', border: "1px solid #ff3535", backgroundColor: '#ff3535' }}>
                <p className='primary-font-bold-5' style={{ margin: 0, textAlign: 'center', color: 'white' }}>Most Recommended</p>
              </div>
            </div>
            <div>
              <CardContent sx={{ pt: "5px" }}>
                <Grid>
                  <Typography className="primary-font-bold1" sx={{ textAlign: 'center' }}>
                    Premium Plan
                  </Typography>
                  <Typography className="primary-font-bold1 pricing-plan-font" sx={{ textAlign: 'center' }}>
                    {planType} Pay : <span style={{ color: '#ff3535' }}> ${premiumPrice} </span>
                  </Typography>
                </Grid>
                <Divider sx={{ margin: '15px 0px' }} />
                <Grid sx={{ mt: '10px' }}>
                  <Typography className='primary-font-bold-5'>
                    Features:
                  </Typography>
                  <Scrollbars style={{ height: '250px' }}>
                    <div style={{ paddingRight: '15px' }}>
                      {/* style={{height:'250px', overflowY:'scroll',scrollbarWidth: 'thin'}}>  */}
                      <ul className='bullet-list'>
                        {premiumFeatures.map((item) =>
                          <div style={{ display: 'flex' }}>
                            <div> <li className='list' /> </div>
                            <div style={{ marginBottom: '15px' }} className='primary-font-medium-1 pricing-features-font'>{item}</div>
                          </div>
                        )}
                      </ul>
                    </div>
                  </Scrollbars>
                </Grid>
              </CardContent>
              <CardActions sx={{ justifyContent: 'center' }}>
                <Button
                  sx={{ borderRadius: '15px', minWidth: '80% !important' }}
                  variant="outlined"
                  className="primary-font-bold-5 action-btn-1 profile-cancel-btn"
                  onClick={() => {
                    handleClick("primary", planType, premiumPrice)
                  }}
                >
                  Get Started
                </Button>
              </CardActions>
            </div>
          </StyledCard>
        </Grid>
       

        {isPinSet && (
          <SecurityPinDialog
            openDialog={isPinSet}
            closeDialog={() => {
              setIsPinSet(false);
            }}
          />
        )}
         {isLockSet && (
          <CreateSecurityLockDialog
            openDialog={isLockSet}
            closeDialog={() => {
              setIsLockSet(false);
            }}
          />
        )}

      </>
    )
  };

  return (
    <Grid container style={{ width: "100%", margin: 0, display: "flex" }}>
      {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
      <div className="subscription-plan-container">
      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        className="box-container "
        style={{ display: "flex", justifyContent: 'center', margin:'50px auto' }}
      >
        <Grid sx={{mb:"40px"}}>
        <Grid sx={{mt:'20px'}}>
          <Typography className="primary-font-bold-heading pricing-heading-font" sx={{textAlign:'center'}}>Choose Your Plan</Typography> 
          <Typography className="primary-font-medium-2-heading" sx={{textAlign:'center', fontFamily: "Roboto !important"}}>Choose your ideal plan and elevate Your experience.</Typography>
         </Grid>
          <div>
            <Grid container xs={12} md={12} lg={12} sx={{ display: 'flex', flexDirection: 'column', mt: '20px' }} >
              <Grid sx={{display:'flex', justifyContent:'center'}}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  // centered
                  variant="fullWidth"
                  TabIndicatorProps={{
                    style: { display: 'none' }
                  }}
                  className="plan-tabs"
                 
                  aria-label="basic tabs"
                >
                  <Tab
                    sx={{
                      width: "20%",
                      height: "20px",
                      color: value === 0 ? "#fff !important" : '#FF3535 !important',
                      backgroundColor: value === 0 ? '#ff3535 !important' : 'white ',
                      borderRadius: value ===0 ? "20px" :'20px 0px 0px 20px',
                      borderRight:value === 0 ? "0px" : ""
                    }}
                    label={<Typography className='primary-font-bold-5' sx={{ textAlign: "center", }} textTransform="none"> Monthly </Typography>} />
                  <Tab
                    sx={{
                      width: "20%",
                      height: "20px",
                      color: value === 1 ? "#fff !important" : '#FF3535 !important',
                      backgroundColor: value === 1 ? '#ff3535 !important' : 'white',
                      borderRadius:value === 1 ? '20px': '0px 20px 20px 0px',
                      borderLeft:value === 1 ? "0px" : ""
                    }}
                    label={<Typography className='primary-font-bold-5' sx={{ textAlign: "center", }} textTransform="none">Annually </Typography>} />
                </Tabs>
              </Grid>
              <Grid>
                <CustomTabPanel value={value} index={0}>
                  <Grid style={{  display: 'flex',justifyContent:'center' }}>
                      <PlanCard Basicprice={10.99} premiumPrice={29} planType={"monthly"} />
                  </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <Grid style={{  display: 'flex', justifyContent:'center' }}>
                      <PlanCard Basicprice={119} premiumPrice={329} planType={"yearly"} />
                  </Grid>
                </CustomTabPanel>
              </Grid>
            </Grid>
          </div>
        </Grid>

        {paymentDialog && (<PaymentDialog  isOpen={paymentDialog} handleClose ={handleClose} chosenPlanDetails={userChosenPlan}/>)}

      </Grid>
      </div>
    </Grid>
  );
};
const MySubscriptionPlan = () => {
    const user = window.localStorage.getItem('tokendetails');
    if (!user) {
        return <Navigate to="/login" />;
      }
  
  return (
    <ContentWrapper
      title={"Pricing"}
      gobackUrl={"/my-subscription"}
      goback={true}
      contenview={<MainLayout />}
    />
  );
};
export default MySubscriptionPlan;
