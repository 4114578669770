import React, { useState } from 'react'
import ContentWrapper from '../../../../../common/components/ContentWrapper';
import { Grid } from "@mui/material";
// import SecurityLockModal from "./SecurityLockModal.js";
import MySocialMediaList from './MySocialMediaList';

const MainLayout = ({ goback = true }) => {
    // const [securityLockDialogOpen, setSecurityLockDialogOpen] = useState(true);
return (
    <Grid container spacing={2} style={{width: '100%', margin: 0}}>
      <Grid item xs={1} md={1} lg={1}></Grid>
      <Grid
        item
        xs={10}
        md={10}
        lg={10}
        style={{ display: "flex", flexWrap: 'wrap',padding:'40px 0px', justifyContent:'center', gap: 30}}
      >
            <MySocialMediaList/>
        </Grid>
      <Grid item xs={1} md={1} lg={1}></Grid>
        {/* {securityLockDialogOpen && (
            <SecurityLockModal openDialog={securityLockDialogOpen}/>
        )} */}
    </Grid>
)
}

const MySocialMedia = () => {

  return (
    <ContentWrapper title={"Social Media"} gobackUrl={'/my-socialmedia'} goback={true} img={false} contenview={<MainLayout />} />
  )
}

export default MySocialMedia;