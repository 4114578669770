import React from 'react'
import ContentWrapper from '../../../../../../common/components/ContentWrapper.js';
import PropertyInventoryList from './PropertyInventoryList.js';

const PropertInventory = () => {

  return (
    <ContentWrapper title={"MY PROPERTY INVENTORY"} gobackUrl={'/my-propertys'} goback={true} img={false} contenview={<PropertyInventoryList />} />
  )
}

export default PropertInventory;