import React, { useState, useEffect } from "react";
import "../../../../../../index.css";
import { Grid, TextField, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";


const BillingAddress = ({onBackOrCancelClick, handleNext}) => {
    const initialFormData = {
        address: '',
        zip: '',
        city: '',
        state: ''
    };
    const [formData, setFormData] = useState(initialFormData);
    const [errors, setErrors] = useState(initialFormData);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const newErrors = {};

        if (formData.address.trim() === '') {
          newErrors.address = 'This is required.';
        }
        if (formData.zip.trim() === '') {
          newErrors.zip = 'This is required.';
        }
        if (formData.city.trim() === '') {
          newErrors.city = 'This is required.';
        }
        if (formData.state.trim() === '') {
          newErrors.state = 'This is required.s';
        }
       
    
        if (Object.keys(newErrors).length === 0) {
          setErrors({});
          handleNext();
        } else {
          setErrors(newErrors);
          console.log('Validation errors:', newErrors);
        }
      };
  return (
    <Grid container spacing={2} style={{margin:0, padding: 32, paddingTop: 0, width: '100%', justifyContent: 'center'}}>
        <Grid item xs={12} md={5} sx={{padding: 0}}>
            <div style={{padding: 20}}>
                <div>
                    <Typography className='font-normal-small error-message'>{errors.address}</Typography>
                    <Typography className='font-normal-small profile-input-label'>Address</Typography>
                    <input id="adderess" name="address" className={`profile-input ${errors.address ? 'err-input' : ''}`} aria-label='address' onChange={handleInputChange}  value={formData.address}/>
                </div>
                <div className="flex-boxes" style={{marginLeft: 0}}> 
                    <div className="full-width">
                        <Typography className='font-normal-small error-message'>{errors.zip}</Typography>
                        <Typography className='font-normal-small profile-input-label'>Zip   </Typography>
                        <input  name="zip" id="zip" className={`profile-input ${errors.zip ? 'err-input' : ''}`}  aria-label='zip'  onChange={handleInputChange} value={formData.zip}/>
                    </div>
                    <div className="full-width">
                        <Typography className='font-normal-small error-message'>{errors.city}</Typography>
                        <Typography className='font-normal-small profile-input-label'>City</Typography>
                        <input  name="city" id="city" className={`profile-input ${errors.city ? 'err-input' : ''}`}  aria-label='city'  onChange={handleInputChange} value={formData.city}/>
                    </div>
                    <div className="full-width">
                        <Typography className='font-normal-small error-message'>{errors.state}</Typography>
                        <Typography className='font-normal-small profile-input-label'>State</Typography>
                        <input  name="state" id="state" className={`profile-input ${errors.state ? 'err-input' : ''}`}  aria-label='state'  onChange={handleInputChange} value={formData.state}/>
                    </div>
                </div>
            </div>
      </Grid>
      <div className="action-holder" style={{marginTop: 0, marginBottom: 50}}>
        <Button variant="outlined"  className="primary-font-bold-6 action-btn profile-cancel-btn" onClick={onBackOrCancelClick}>Back</Button>
        <Button variant="outlined" className="primary-font-bold-6 action-btn profile-save-btn"  onClick={handleSubmit}>Save</Button>
      </div>
    </Grid>
  );
};

export default BillingAddress;