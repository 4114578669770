import React, { useState } from "react";
import "../../../index.css";
import "./styles/card.css";
import { Typography, Card, CardActions, CardMedia, Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import SecurityPinModal from "../Info/components/MyBankAccounts/SecurityPinModal";
import AlertMessage from "../UserProfile/components/AlertMessage";

const MainCard = ({ cardList }) => {
  const [securityPinOpen, setSecurityPinOpen] = useState(false);
  const [url,seturl]=useState("");
  // const [redirect,setredirect]=useState("")
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const [showDialog,setShowDialog] = useState(false)
  const handleclose = () => {
    setSecurityPinOpen(false)
  }
  

  const redirectionUrl = (url, isRedirect, securityPin) => {
    if (securityPin ) {
      if(isRedirect){
      setSecurityPinOpen(true);
       seturl(url)
      }
    }
    else if (!securityPin ) {
      if (isRedirect) {
        navigate(url)
      }else{
        setShowDialog(true);
        setTimeout(() => {
          setShowDialog(false);
        }, 2000);
      }
    }
  }

  const styledImage = {
    width:"70% !important",
    margin:"0 auto !important",
    objectFit:"none",
  }

  const styledCard = {
    border: "1px solid #eee",
    bordeRradius: "2px",
  }
  
  return (
    <>
    <Grid className="card-main-container" container spacing={4} > 
   
      {cardList.map((singlecard, i) => {

        return (
         <Grid item xs={12}  sm={6} md={4} lg={3} >
          <Card  sx={{...(singlecard.inLineStyle ? styledCard : {})}}
           className="general-card" key={singlecard.cardText + '_' + i} 
           onClick={() => { redirectionUrl(singlecard.redirection, singlecard?.toRedirect, singlecard?.securityPin) }}
           >
            <div className="general-card-image" style={singlecard.inLineStyle ? {background:"#ededed"} : {} }>
              <CardMedia
                component="img"
                className="general-card-image-main"
                alt="green iguana"
                image={singlecard.image}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  ...(singlecard.inLineStyle ? styledImage : {}),
                  // cursor:singlecard.toRedirect ? "pointer" : "no-drop"
                }}
                
              />
              {singlecard.iconOverImageUrl ? <img alt="card" className="general-card-small-icon" src={singlecard.iconOverImageUrl} /> : ''}

            </div>
            <CardActions className="general-card-action" sx={{...(singlecard.inLineStyle ? {borderTop:"1px solid #ddd"} : {})}}>
              <Typography className="primary-font-medium-5" style={{ display: 'flex' }} >
                {singlecard.redText && (
                  <Typography className="primary-font-medium-5" color={"#FF3535"}>{t(singlecard.redText)}&nbsp;</Typography>
                )}
                {t(singlecard.cardText)}
              </Typography>
            </CardActions>
          </Card>
        </Grid>
    
        )
      })}
   <div>
            <SecurityPinModal openDialog={securityPinOpen} onClose={handleclose} Url={url} />
      
            {showDialog && <AlertMessage isOpen={true} onClose={false} message={"This function is under construction"} />}
  
   </div>
   </Grid>
    </>

  );
};

export default MainCard;
