import React, { useState, useEffect } from "react";
import "../../../../../../index.css";
import { Grid, Typography, Button, Select, MenuItem  } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as formatters from '../../../../../../utils/util';
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import { getCemeteryData, updateCemeteryData, getStatesList } from "../../../redux/actions";
// import PlusIcon from '../../../../../assets/icons/plus-icon.svg';
// import MinusIcon from '../../../../../assets/icons/minus-icon.svg'; 
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CemeteryArrangements = ({ onContinue,  onCancel,onView, selectedUser,getCemeteryData, updateCemeteryData, cemeteryUpdate, cemeteryDetails, cemeteryError, getStatesList, stateList}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cemeteryName, setCemeteryName] = useState(null);
  const [address, setAddress] = useState(null);
  const [zip, setZip] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [stateArray, setStateArray] = useState(null);
  const [telephone, setTelephone] = useState(null);
  const [locationOfDeed, setLocationOfDeed] = useState(null);
  const [plotName, setPlotName] = useState(null);
  const [plotNo, setPlotNo] = useState(null);
  const [section, setSection] = useState(null);
  const [block, setBlock] = useState(null);

  const [phoneNumberErr, setphoneNumberErr] = useState('');

  useEffect(()=>{
    getStatesList();
    if(selectedUser?.userId){
      getCemeteryData(selectedUser)
    }
  },[selectedUser])
  useEffect(()=>{
    if(stateList && stateList.status === 200){
      setStateArray(Object.keys(stateList.data))
    }
  },[stateList])
  useEffect(()=>{
    console.log(cemeteryDetails)
    if(cemeteryDetails && cemeteryDetails.status === 200){
      const data = cemeteryDetails.data;
      console.log("CEmertery Details", data)
      setCemeteryName(data.cemeteryName);
      setTelephone(data.telephoneNo);
      setAddress(data.address);
      setBlock(data.block);
      setZip(data.zip);
      setCity(data.city);
      setState(data.stateCode);
      setLocationOfDeed(data.locationOfDeed);
      setPlotName(data.plotName);
      setSection(data.section);
      setPlotNo(data.plotNo);
    }
  },[cemeteryDetails])

  useEffect(()=>{
    console.log("cemeteryUpdate", cemeteryUpdate)
    if(cemeteryUpdate && cemeteryUpdate.status === 200){
      dispatch({
        type:"RESET_CEMETERY_ARRANGEMENT",
        action: null
      })
      onContinue()
    }
  },[cemeteryUpdate])
    const onChangeInput = (e, type) => {
      let value = "";
      if(type !== "motherDob" && type !== "fatherDob"){
        value = e.target.value
      }
      switch(type) {
        case 'address':
          setAddress(value)
          break;
        case 'zip':
          setZip(value)
          break;
        case 'city':
          setCity(value)
          break;
        case 'state':
          setState(value)
          break;
        case 'telephone':
          setTelephone(value)
          break;
        case 'cemeteryName':
          setCemeteryName(value)
          break;
        case 'locationOfDeed':
          setLocationOfDeed(value)
          break;
        case 'plotName':
          setPlotName(value)
          break;
        case 'plotNo':
          setPlotNo(value)
          break;
        case 'section':
          setSection(value)
          break;
        case 'block':
          setBlock(value)
          break;
        default:
      }
    }
   
    const onSave = () => {
      const payload = {
        "userId" :selectedUser?.userId,
        "createdBy":selectedUser?.userId,
        "updatedBy":selectedUser?.userId,
        "cemeteryName": cemeteryName,
        "locationOfDeed": locationOfDeed,
        "telephoneNo":telephone,
        "plotName": plotName,
        "plotNo": plotNo,
        "address": address,
        "section": section,
        "block": block,
        "zip": zip,
        "city": city,
        "stateCode": state,
      }
      console.log(payload)
      updateCemeteryData(payload)
    };
  
  return (
    <Grid container spacing={2} sx={{margin:0, padding: 0,width:"100% !important"}}>
      <Grid
        // item
        xs={12}
        md={12}
        style={{paddingTop: 0}}
        className="accordion-holder"
      >
        <Grid container spacing={2} sx={{margin:0, height: 'auto',width:"100% !important"}}>
                    <Grid item xs={12} md={6} sx={{pl:onView?"0px !important":"0px",pt:onView?"0px !important":"16px"}} className="pl-0">
                        <div>
                            <Typography className='font-normal-small error-message'>{phoneNumberErr}</Typography>
                            <Typography className='font-normal-small profile-input-label'>Name of Cemetery</Typography>
                            <input className={`profile-input ${phoneNumberErr !== '' ? 'err-input' : ''}`} aria-label='cemeteryName' onChange={(e)=> {onChangeInput(e, 'cemeteryName')}}  value={cemeteryName}/>
                        </div>
                        <div>
                            <Typography className='font-normal-small error-message'>{phoneNumberErr}</Typography>
                            <Typography className='font-normal-small profile-input-label'>Telephone</Typography>
                            <input className={`profile-input ${phoneNumberErr !== '' ? 'err-input' : ''}`} aria-label='phone number' onChange={(e)=> {onChangeInput(e, 'telephone')}}  maxLength={10} value={formatters.formatPhoneNumber(telephone)}/>
                        </div>
                        <div>
                            <Typography className='font-normal-small error-message'>{phoneNumberErr}</Typography>
                            <Typography className='font-normal-small profile-input-label'>Address</Typography>
                            <input className={`profile-input ${phoneNumberErr !== '' ? 'err-input' : ''}`} aria-label='address' onChange={(e)=> {onChangeInput(e, 'address')}}   value={(address)}/>
                        </div>
                        <div className="flex-boxes"> 
                           
                            <div className="full-width">
                            <Typography className='font-normal-small error-message'>{phoneNumberErr}</Typography>
                                <Typography className='font-normal-small profile-input-label'>Zip   </Typography>
                                <input className={`profile-input ${phoneNumberErr !== '' ? 'err-input' : ''}`}  aria-label='zip'  onChange={(e)=> {onChangeInput(e, 'zip')}} value={zip}/>
                            </div>
                            <div className="full-width">
                                <Typography className='font-normal-small profile-input-label'>City</Typography>
                                <input className={`profile-input ${phoneNumberErr !== '' ? 'err-input' : ''}`}  aria-label='city'  onChange={(e)=> {onChangeInput(e, 'city')}} value={city}/>
                            </div>
                            <div className="full-width">
                                <Typography className='font-normal-small profile-input-label'>State</Typography>
                                <Select className="profile-input state-input font-normal-small" value={state}  onChange={(e)=> {onChangeInput(e, 'state')}}>
                                    {stateArray?.map((key) => (
                                      <MenuItem key={key} value={key}>
                                        {key}
                                      </MenuItem>
                                    ))}
                                  </Select>                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{pl:onView?"0px !important":"30px",pt:onView?"0px !important":"16px"}} >
                        <div> 
                            <Typography className='font-normal-small error-message'>{phoneNumberErr}</Typography>
                            <Typography className='font-normal-small profile-input-label'>Location of Deed</Typography>
                            <input className={`profile-input ${phoneNumberErr !== '' ? 'err-input' : ''}`}  aria-label='locationOfDeed'  onChange={(e)=> {onChangeInput(e, 'locationOfDeed')}} value={locationOfDeed}/>
                        </div>
                        <div> 
                            <Typography className='font-normal-small error-message'>{phoneNumberErr}</Typography>
                            <Typography className='font-normal-small profile-input-label'>Plot in Name of</Typography>
                            <input className={`profile-input ${phoneNumberErr !== '' ? 'err-input' : ''}`}  aria-label='plotName'  onChange={(e)=> {onChangeInput(e, 'plotName')}} value={plotName}/>
                        </div>
                        <div>
                            <Typography className='font-normal-small error-message'>{phoneNumberErr}</Typography>
                            <Typography className='font-normal-small profile-input-label'>Section </Typography>
                            <input className={`profile-input ${phoneNumberErr !== '' ? 'err-input' : ''}`}  aria-label='section'  onChange={(e)=> {onChangeInput(e, 'section')}} value={section}/>
                        </div>
                        <div className="flex-boxes"> 
                            
                            <div className="full-width">
                            <Typography className='font-normal-small error-message'>{phoneNumberErr}</Typography>
                                <Typography className='font-normal-small profile-input-label'>Plot Number   </Typography>
                                <input className={`profile-input ${phoneNumberErr !== '' ? 'err-input' : ''}`}  aria-label='plotNo'  onChange={(e)=> {onChangeInput(e, 'plotNo')}} value={plotNo}/>
                            </div>
                            <div className="full-width">
                                <Typography className='font-normal-small profile-input-label'>Block</Typography>
                                <input className={`profile-input ${phoneNumberErr !== '' ? 'err-input' : ''}`}  aria-label='block'  onChange={(e)=> {onChangeInput(e, 'block')}} value={block}/>
                            </div>
                        </div>
                    </Grid>
                </Grid>
       
      <div className="action-holder action-bg">
        <Button variant="outlined" className="primary-font-actionbtn action-btn profile-cancel-btn" onClick={onCancel}>Back</Button>
        <Button variant="outlined" className="primary-font-actionbtn action-btn profile-save-btn" onClick={onSave}>Save & Continue</Button>
    </div>
      </Grid>
    </Grid>
  );
};


CemeteryArrangements.defaultProps = {
};

function mapStateToProps(state) {
  return {
    cemeteryUpdate: state.familyInfo.cemeteryUpdate,
    cemeteryDetails: state.familyInfo.cemeteryDetails,
    cemeteryError: state.familyInfo.cemeteryError,
    stateList: state.familyInfo.stateList
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCemeteryData, 
      updateCemeteryData,
      getStatesList
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CemeteryArrangements);


