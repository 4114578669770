import { CardContent, Grid, Typography, Card, useMediaQuery,Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import ContentWrapper from "../../../../common/components/ContentWrapper";
import moment from "moment";
import { bindActionCreators } from "redux";
import { subscriptionAPI } from "../../Login/redux/actions";
import { connect} from "react-redux";
import { useNavigate } from "react-router-dom";


const StyledCard = styled(Card)({
    border: '1px solid #D8D8D8',
    borderRadius: 5,
    boxShadow: 'none',
    padding: 10,
    height: 'auto',
});


const MainLayout= ({plan,isActive,billingInfoData}) => {
    const isMobile = useMediaQuery('(max-width: 800px)');
    const navigate = useNavigate()

    const  calculateRemainingDays = (endDate) => {
        const today = moment();
        const end = moment(endDate);
        const diffDays = end.diff(today, 'days');
        return diffDays;
    }



    return (
        <Grid container spacing={2} style={{ width: '100%', margin: 0 }}>
            <Grid item xs={1} md={1} lg={1}></Grid>
            <Grid item xs={10} md={10} lg={10} className="box-container" style={{ justifyContent: 'center', alignItems: 'center' }}>
               {isActive ? <Grid container  justifyContent="left" spacing={2}>
               <Grid sx={{display:'flex', flexDirection:isMobile? 'column' :'row'}} > 
                    <Grid item xs={12} md={12} style={{
                        marginLeft: isMobile? '10px' : '50px', 
                        marginTop: '20px'}}>
                        <Typography  style={{fontSize: '22px',fontWeight:"bold",marginTop:"10px"}}>
                            Current Plan
                        </Typography>
                        <div style={{marginBottom:'10px'}}></div>
                            <StyledCard sx={{ width: '100%', marginBottom:'50px' }}>
                                <CardContent>
                                    <Typography variant="primary-font-bold" style={{fontSize: '20px', fontWeight: 'bold',textTransform:"capitalize"}}>
                                         {plan?.typeCode} ({plan?.planDuration})
                                    </Typography>
                                    <Typography variant="body2" style={{color: 'red', marginTop:'20px'}}>
                                         Subscription renewal date
                                    </Typography>
                                    <Typography variant="body2" style={{marginTop:'10px'}}>
                                        {moment(plan?.endDate).format("MMMM DD,YYYY")} (in {calculateRemainingDays(plan?.endDate)} days)
                                    </Typography>
                                </CardContent>
                            </StyledCard>
                            <StyledCard sx={{ width: '90%', marginBottom: '50px',display:'none' }}>
                                <CardContent>
                                    <Typography variant="primary-font-bold" style={{ fontSize: '20px', fontWeight: 'bold', textTransform: "capitalize" }}>
                                        Switch to annual payments and save 80% $149/year ($99.00/month)
                                    </Typography>
                                    <Typography variant="body2" style={{ margin: '20px 0px' }}>
                                        We will adjust the amount we charge you according to the remaining days on your current subscription.
                                    </Typography>
                                    <Button
                                        sx={{ borderRadius: '5px', minWidth: '40% !important' }}
                                        variant="outlined"
                                        className="primary-font-bold-5 action-btn profile-cancel-btn"
                                        onClick={() => { }}
                                    >
                                        Switch to annual
                                    </Button>

                                </CardContent>
                            </StyledCard>
                    </Grid>
                    <Grid item xs={12} md={12} style={{
                        marginLeft: isMobile? '10px' : '50px', 
                        marginTop: isMobile?'0px': '65px'}}>
                        <div style={{ marginBottom:'8px'}}> </div>
                            <StyledCard sx={{ width: '100%', marginBottom:'50px' }}>
                                <CardContent>
                                    <Typography variant="primary-font-bold" style={{fontSize: '20px', fontWeight: 'bold',textTransform:"capitalize"}}>
                                         Billing Information
                                    </Typography>
                                    <Typography variant="body2" style={{margin:'10px 0px 0px'}}>
                                    {billingInfoData?.firstName} {billingInfoData?.lastName} <span><br/></span>
                                    {billingInfoData?.addressOne} <span><br/></span>
                                    {billingInfoData?.addressTwo ? billingInfoData?.addressTwo:""} {billingInfoData?.city} {billingInfoData?.country} {"-"+billingInfoData?.postalCode} <span><br/></span>
                                    </Typography>
                                </CardContent>
                            </StyledCard>
                           
                    </Grid>
              </Grid>
                </Grid> : <Typography style={{ margin: "70px 0" }}>
                    No subscription available yet. <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} onClick={() => navigate('/pricing')}>Click here to subscribe.</span>
                </Typography>}
                
            </Grid>
            <Grid item xs={1} md={1} lg={1}></Grid>
        </Grid>
    );
};

const MySubscription = ({subscriptionAPI,planAPI}) => {
  const [isActive,setIsActive] = useState(false)
  const [billingInfoData, setBillingInfoData] = useState();

  useEffect(() => {
    subscriptionAPI()
  }, [])

  useEffect(() => {
   if(planAPI && planAPI?.data && planAPI.data?.plan?.isSubscribed){
    setIsActive(false)
    setBillingInfoData(planAPI?.data?.billingInformation)
   }else{
    setIsActive(false)

   }
  }, [planAPI])

    return (
        <ContentWrapper title={'My Subscription'} goback={true} gobackUrl={"/home"} contenview={<MainLayout plan={planAPI?.data?.plan} isActive={isActive} billingInfoData={billingInfoData}/>} img={false}/>    
     )
}
function mapStateToProps(state) {
  return{
    planAPI : state.login.planAPI
  }
  
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    subscriptionAPI
  },dispatch)
}
export default connect(mapStateToProps,mapDispatchToProps)(MySubscription);
