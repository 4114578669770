import {api} from '../../../../utils/apiClient';
import { CognitoIdentityProviderClient, AdminCreateUserCommand, AdminDeleteUserCommand, AdminConfirmSignUpCommand } from "@aws-sdk/client-cognito-identity-provider"; // ES Modules import
import awsConfig from '../../../../aws-exports';
import constants from '../../../../config/constants';
const fileDownload = require('js-file-download');


const client = new CognitoIdentityProviderClient({ 
    region: "us-east-1",
    credentials: {
        accessKeyId: constants.COGNITO_ACCESS_KEY,
        secretAccessKey: constants.COGNITO_SECRET_KEY,
    }
});

export const getFamilyMembers = (payload) => async(dispatch) => {
     const userDetails = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')): null;
     const url = `v1/user/readAll${userDetails?.accountId  ? `/${userDetails?.accountId}`: ''}`;
    try{
        dispatch({
            type: 'MEMBERINFO_DATA_REQUEST',
            payload: null
        })
        const response =await api.getApi(url);
        if(response.data && response.data.status === 200){
            return dispatch({
                type: 'MEMBERINFO_DATA_SUCCESS',
                payload: response.data
            });
        }else{
            return dispatch({
                type: 'MEMBERINFO_DATA_FAILURE',
                payload: response.data
            });
        }
       
    }catch(e){
        return dispatch({
            type: 'MEMBERINFO_DATA_FAILURE',
            payload: e
        });
    }
}

export const deleteUserDataApi = (id) => async(dispatch) => {
    const url = `v1/user/delete${id  ? `/${id}`: ''}`;
    try{
        dispatch({
            type: 'DELETE_DATA_REQUEST',
            payload: null
        })
        const response =await api.deleteapi(url);
        return dispatch({
            type: 'DELETE_DATA_SUCCESS',
            payload: response.data
        });
    }catch(e){
        return dispatch({
            type: 'DELETE_DATA_FAILURE',
            payload: e
        });
    }
 };
  
export const markAsAdminAPI = (payload) => async(dispatch) => {
    const url = `v1/user/markAsAdmin`;
    
    try{
        dispatch({
            type: 'ADMIN_DATA_REQUEST',
            payload: null
        })
        const response =await api.putApi(url, payload);
        return dispatch({
            type: 'ADMIN_DATA_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'ADMIN_DATA_FAILURE',
            payload: e
        });
    }
 };
 
 export const getSpecialInstruction = (payload) => async(dispatch) => {
    const url = `v1/userInfo/specialInstructions/read${payload  ? `/${payload}`: ''}`;
    
    try{
        dispatch({
            type: 'GET_SPL_INSTRUCTION_REQUEST',
            payload: null
        })
        const response =await api.getApi(url, payload);
        return dispatch({
            type: 'GET_SPL_INSTRUCTION_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'GET_SPL_INSTRUCTION_FAILURE',
            payload: e
        });
    }
 };

 export const addSpecialInstruction = (payload) => async(dispatch) => {

    const url = `v1/userInfo/specialInstructions/update`;
    
    try{
        dispatch({
            type: 'SPL_INSTRUCTION_REQUEST',
            payload: null
        })
        const response =await api.putApi(url, payload);
        return dispatch({
            type: 'SPL_INSTRUCTION_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'SPL_INSTRUCTION_FAILURE',
            payload: e
        });
    }
 };

 export const createCognitoUser = (payload) =>async(dispatch) => {
    
    const input = { 
      UserPoolId: awsConfig.aws_user_pools_id, // required
      ClientId:awsConfig.aws_user_pools_web_client_id,
      Username: payload.username, // required
      Password:payload.password,
      UserAttributes: null,
      ValidationData: null,
      MessageAction:  "SUPPRESS",
      DesiredDeliveryMediums: [ 
      ],
    };
   
    try{
        const command = new AdminCreateUserCommand(input);
        const response = await client.send(command);
        if(response){
            const input = { 
            UserPoolId:awsConfig.aws_user_pools_id, // required // required
            Username: payload.username, // required
            };
            const command = new AdminConfirmSignUpCommand(input);
            const confirmresponse = await client.send(command);
        }
    }catch(e){
        console.log(e)
    }
    
}
export const updatePersonalInformation = (payload) => async(dispatch) => {
    const url = `v1/user/personalInfo/update`;
    
    try{
        dispatch({
            type: 'UPDATE_PERSONAL_INFO_REQUEST',
            payload: null
        })
        const response =await api.putApi(url, payload);
        return dispatch({
            type: 'UPDATE_PERSONAL_INFO_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'UPDATE_PERSONAL_INFO_FAILURE',
            payload: e
        });
    }
 };

export const deleteUser = (email) =>async(dispatch) => {
    const input = { // AdminDeleteUserRequest
        UserPoolId: awsConfig.aws_user_pools_id, // required
        Username: email, // required
      };
try{
    const command = new AdminDeleteUserCommand(input)
    const response = await client.send(command);
}catch(e){
    console.log(e)
}
}
export const getSpecficUserProfile = (payload) => async(dispatch) => {

    const url = `v1/user/read${payload?.id  ? `/${payload?.id}`: ''}`;
  try{
      dispatch({
          type: 'USER_DATA_REQUEST',
          payload: null
      })
      const response =await api.getApi(url);

      return dispatch({
          type: 'USER_DATA_SUCCESS',
          payload: response.data
      });
  }catch(e){
      return dispatch({
          type: 'USER_DATA_FAILURE',
          payload: e
      });
  }
}

export const getRelationshipList = (payload) => async(dispatch) => {
    const url = `v1/commonUtility/listValues/readAll`;
   try{
       dispatch({
           type: 'RELATIONSHIP_DATA_REQUEST',
           payload: null
       })
       const response =await api.getApi(url);
       return dispatch({
           type: 'RELATIONSHIP_DATA_SUCCESS',
           payload: response?.data?.data['users-relationship'] ? response.data.data['users-relationship'] : []
       });
   }catch(e){
       return dispatch({
           type: 'RELATIONSHIP_DATA_FAILURE',
           payload: e
       });
   }
}
export const uploadCommonUtilityPicture = (payload) => async(dispatch) => {
        const url = `v1/commonUtility/file/upload`;
       try{
           dispatch({
               type: 'UTILITY_IMAGE_UPDATE_REQUEST',
               payload: null
           })
     
           const response =await api.postApi(url,payload);
           return dispatch({
               type: 'UTILITY_IMAGE_UPDATE_SUCCESS',
               payload: response.data
           });
       }catch(e){
           return dispatch({
               type: 'UTILITY_IMAGE_UPDATE_FAILURE',
               payload: e
           });
       }
    }

export const addDemographicInformation = (payload) => async(dispatch) => {

const url = `v1/userInfo/userDemographic/create`;

try{
    dispatch({
        type: 'DEMOGRAPHIC_INFORMATION_REQUEST',
        payload: null
    })
    const response =await api.postApi(url, payload);
    return dispatch({
        type: 'DEMOGRAPHIC_INFORMATION_SUCCESS',
        payload: response.data
    });
    }catch(e){
    return dispatch({
        type: 'DEMOGRAPHIC_INFORMATION_FAILURE',
        payload: e
    });
}
};
 export const getDemographicInformation = (payload) => async(dispatch) => {

    const url = `v1/userInfo/userDemographic/read/${payload.userId}`;
    
    try{
        dispatch({
            type: 'DEMOGRAPHIC_INFORMATION_REQUEST',
            payload: null
        })
        const response =await api.getApi(url, payload);
        return dispatch({
            type: 'GET_DEMOGRAPHIC_INFORMATION_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'GET_DEMOGRAPHIC_INFORMATION_FAILURE',
            payload: e
        });
    }
 };


 
 export const updateCemeteryData = (payload) => async(dispatch) => {

    const url = `v1/userInfo/userCemetery/update`;
    
    try{
        dispatch({
            type: 'CEMETERY_INFORMATION_REQUEST',
            payload: null
        })
        const response =await api.putApi(url, payload);
        return dispatch({
            type: 'CEMETERY_INFORMATION_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'CEMETERY_INFORMATION_FAILURE',
            payload: e
        });
    }
 };

 export const getCemeteryData = (payload) => async(dispatch) => {

    const url = `v1/userInfo/userCemetery/read/${payload.userId}`;
    
    try{
        dispatch({
            type: 'CEMETERY_INFORMATION_REQUEST',
            payload: null
        })
        const response =await api.getApi(url, payload);
        return dispatch({
            type: 'GET_CEMETERY_INFORMATION_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'GET_CEMETERY_INFORMATION_FAILURE',
            payload: e
        });
    }
 };

 export const updateRelativesToNotify = (payload) => async(dispatch) => {

    const url = `v1/userInfo/userRelative/update`;
    
    try{
        dispatch({
            type: 'UPDATE_USER_RELATIVE_REQUEST',
            payload: null
        })
        const response =await api.putApi(url, payload);
        return dispatch({
            type: 'UPDATE_USER_RELATIVE_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'UPDATE_USER_RELATIVE_FAILURE',
            payload: e
        });
    }
 };
 export const getRelativesToNotify = (payload) => async(dispatch) => {
    const url = `v1/userInfo/userRelative/read/${payload}`;
    
    try{
        dispatch({
            type: 'USER_RELATIVE_REQUEST',
            payload: null
        })
        const response =await api.getApi(url, payload);
        return dispatch({
            type: 'GET_USER_RELATIVE_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'GET_USER_RELATIVE_FAILURE',
            payload: e
        });
    }
 };
 export const deleteRelativesToNotify = (payload) => async(dispatch) => {
    const url = `v1/userInfo/userRelative/delete/${payload}`;
    try{
        dispatch({
            type: 'DLT_USER_RELATIVE_REQUEST',
            payload: null
        })
        const response =await api.deleteapi(url, payload);
        return dispatch({
            type: 'DLT_USER_RELATIVE_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'DLT_USER_RELATIVE_FAILURE',
            payload: e
        });
    }
 };

 export const getStatesList = (payload) => async(dispatch) => {
    const url = `v1/commonUtility/state/read`;
    
    try{
        dispatch({
            type: 'STATE_REQUEST',
            payload: null
        })
        const response =await api.getApi(url, payload);
        return dispatch({
            type: 'GET_STATE_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'GET_STATE_FAILURE',
            payload: e
        });
    }
 };

 export const updateFuneralArrangements = (payload) => async(dispatch) => {

    const url = `v1/userInfo/userFuneral/update`;
    try{
        dispatch({
            type: 'UPDATE_FUNERAL_ARRANGEMENT_REQUEST',
            payload: null
        })
        const response =await api.putApi(url, payload);
        return dispatch({
            type: 'UPDATE_FUNERAL_ARRANGEMENT_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'UPDATE_FUNERAL_ARRANGEMENT_FAILURE',
            payload: e
        });
    }
 };
 export const getFuneralArrangementDetails = (payload) => async(dispatch) => {
    const url = `v1/userInfo/userFuneral/read/${payload}`;
    try{
        dispatch({
            type: 'GET_FUNERAL_ARRANGEMENT_REQUEST',
            payload: null
        })
        const response =await api.getApi(url, payload);
        return dispatch({
            type: 'GET_FUNERAL_ARRANGEMENT_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'GET_FUNERAL_ARRANGEMENT_FAILURE',
            payload: e
        });
    }
 };

 export const getMilitaryServices = (payload) => async(dispatch) => {
    const url = `v1/userInfo/userMilitary/read/${payload}`;
    try{
        dispatch({
            type: 'GET_MILITARY_REQUEST',
            payload: null
        })
        const response =await api.getApi(url, payload);
        return dispatch({
            type: 'GET_MILITARY_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'GET_MILITARY_FAILURE',
            payload: e
        });
    }
 };

 export const updateMilitaryServices = (payload) => async(dispatch) => {
    const url = `v1/userInfo/userMilitary/update`;
    try{
        dispatch({
            type: 'UPDATE_MILITARY_REQUEST',
            payload: null
        })
        const response =await api.putApi(url, payload);
        return dispatch({
            type: 'UPDATE_MILITARY_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'UPDATE_MILITARY_FAILURE',
            payload: e
        });
    }
 };

 export const resendInvitationAPI = (payload) => async(dispatch) => {
    const url = `v1/user/resendVerifyLink`;
    
    try{
        dispatch({
            type: 'RESEND_DATA_REQUEST',
            payload: null
        })
        const response =await api.postApi(url, payload);
        return dispatch({
            type: 'RESEND_DATA_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'RESEND_DATA_FAILURE',
            payload: e
        });
    }
 };
 
 export const verifySecurityPin = (payload) => async(dispatch) => {
    const url = `v1/user/securityPin/verify`;
   try{
       dispatch({
           type: 'SECURITY_Pin_VERIFY_REQUEST',
           payload: null
       })
       const response =await api.postApi(url,payload);
       return dispatch({
           type: 'SECURITY_Pin_VERIFY_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'SECURITY_Pin_VERIFY_FAILURE',
           payload: e
       });
   }
}

export const getBankList = (payload) => async(dispatch) => {
    const url = `v1/commonUtility/bankList/read`;
    
    try{
        dispatch({
            type: 'BANK_REQUEST',
            payload: null
        })
        const response =await api.getApi(url, payload);
        return dispatch({
            type: 'GET_BANK_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'GET_BANK_FAILURE',
            payload: e
        });
    }
 };


export const getCommonUtility = (payload) => async(dispatch) => {
    const url = `v1/commonUtility/listValues/readAll`;
   try{
       dispatch({
           type: 'UTILITY_DATA_REQUEST',
           payload: null
       })
       const response =await api.getApi(url);
       console.log("response::response",response.data)
       return dispatch({
           type: 'UTILITY_DATA_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'UTILITY_DATA_FAILURE',
           payload: e
       });
   }
};

export const addBankInformationAPI = (payload) => async(dispatch) => {

    const url = `v1/myInfo/bank/create`;
    
    try{
        dispatch({
            type: 'ADD_BANK_ACCOUNT_REQUEST',
            payload: null
        })
        const response =await api.postApi(url, payload);
        return dispatch({
            type: 'ADD_BANK_ACCOUNT_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'ADD_BANK_ACCOUNT_FAILURE',
            payload: e
        });
    }
 };

 export const getSingleBankDetailsAPI = (payload) => async(dispatch) => {

    const url = `v1/myInfo/bank/read/${payload.bankId}`;
    
    try{
        const response =await api.getApi(url, payload);
        const apiResponse = response.data;
        console.log(apiResponse)
        if(apiResponse && apiResponse.status === 200){
            return dispatch({
                type:"ADD_BANK_DETAILS",
                payload: apiResponse.data
            });
        }
     }catch(e){
        return dispatch({
            type: 'GET_BANK_ACCOUNT_FAILURE',
            payload: e
        });
    }
 };
export const getBankAccounts = (payload) => async(dispatch) => {
    const userDetails = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')): null;
    const url = `v1/myInfo/bank/readAll${userDetails?.accountId  ? `/${userDetails?.accountId}`: ''}`;
    console.log("getBankAccounts",url)
   try{
       dispatch({
           type: 'BANKINFO_DATA_REQUEST',
           payload: null
       })
       const response =await api.getApi(url);
       if(response.data && (response.data.status === 200 || response.data.status === 404)){
        console.log("getBankAccounts",response.data)
           return dispatch({
               type: 'BANKINFO_DATA_SUCCESS',
               payload: response.data
           });
       }else{
           return dispatch({
               type: 'BANKINFO_DATA_FAILURE',
               payload: response.data
           });
       }
      
   }catch(e){
       return dispatch({
           type: 'BANKINFO_DATA_FAILURE',
           payload: e
       });
   }
};

export const updatedBankInformation = (payload) => async(dispatch) => {

    const url = `v1/myInfo/bank/update`;
    
    try{
        dispatch({
            type: 'ADD_BANK_ACCOUNT_REQUEST',
            payload: null
        })
        const response =await api.putApi(url, payload);
        return dispatch({
            type: 'ADD_BANK_ACCOUNT_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'ADD_BANK_ACCOUNT_FAILURE',
            payload: e
        });
    }
 };

 
export const deleteBankInformationAPI = (bankid) => async(dispatch) => {

    const url = `v1/myInfo/bank/delete/${bankid}`;
    
    try{
        dispatch({
            type: 'DELETE_BANK_ACCOUNT_REQUEST',
            payload: null
        })
        const response =await api.deleteapi(url);
        console.log("actions deleted bank acocunt",response.data)
        return dispatch({
            type: 'DELETE_BANK_ACCOUNT_SUCCESS',
            payload: response.data
        });
     }catch(e){
        return dispatch({
            type: 'DELETE_BANK_ACCOUNT_FAILURE',
            payload: e
        });
    }
 };

 
export const downloadBankDetails = (payload) => async(dispatch) => {
    const url = `v1/myInfo/bank/exportAsPdf/${payload?.id}`;
   try{
       dispatch({
           type: 'DOWNLOAD_DATA_REQUEST',
           payload: null
       })
       const response =await api.getApi(url, {responseType: "blob"});
       const fileName = 'Bank-'+payload.nameCode+'-'+new Date().getDate()+'.pdf'
      if(response && response.data){
        const blob = new Blob([response.data])
        fileDownload(blob, fileName )
      }
       if(response.data && response.data.status === 200){
           return dispatch({
               type: 'DOWNLOAD_DATA_SUCCESS',
               payload: true
           });
       }else{
           return dispatch({
               type: 'DOWNLOAD_DATA_FAILURE',
               payload:"Somthing went wronge"
           });
       }
   }catch(e){
    return dispatch({
        type: 'DOWNLOAD_DATA_FAILURE',
        payload:"Somthing went wronge"
    });
   }
}


export const resetDetails = () => async(dispatch) => {
    window.localStorage.removeItem('selectedBankAccount');
    window.localStorage.removeItem('hideEdit');
    return dispatch({
        type:"RESET_BANK_DETAILS",
        payload: null
    })
}


export const uploadPropertyFile = (payload) => async(dispatch) => {
    const url = `v1/commonUtility/file/upload`;
   try{
       dispatch({
           type: 'PROPERTY_IMAGE_UPDATE_REQUEST',
           payload: null
       })
 
       const response =await api.postApi(url,payload);
       return dispatch({
           type: 'PROPERTY_IMAGE_UPDATE_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'PROPERTY_IMAGE_UPDATE_FAILURE',
           payload: e
       });
   }
}
export const uploadMultipleFile = (payload) => async(dispatch) => {
    const url = `v1/commonUtility/file/uploadMultipleFiles`;
   try{
       dispatch({
           type: 'MULTIPLE_IMAGE_UPDATE_REQUEST',
           payload: null
       })
 
       const response =await api.postApi(url,payload);
       return dispatch({
           type: 'MULTIPLE_IMAGE_UPDATE_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'MULTIPLE_IMAGE_UPDATE_FAILURE',
           payload: e
       });
   }
}
export const uploadTileFile = (payload) => async(dispatch) => {
    const url = `v1/commonUtility/file/upload`;
   try{
       dispatch({
           type: 'PROPERTY_IMAGE_UPLOAD_REQUEST',
           payload: null
       })
 
       const response =await api.postApi(url,payload);
       return dispatch({
           type: 'PROPERTY_IMAGE_UPLOAD_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'PROPERTY_IMAGE_UPLOAD_FAILURE',
           payload: e
       });
   }
}



export const uploadVideoFile = (payload) => async(dispatch) => {
    const url = `v1/commonUtility/file/uploadMultipleFiles`;
   try{
       dispatch({
           type: 'PROPERTY_VIDEO_UPDATE_REQUEST',
           payload: null
       })
 
       const response =await api.postApi(url,payload);
       return dispatch({
           type: 'PROPERTY_VIDEO_UPDATE_SUCCESS',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'PROPERTY_VIDEO_UPDATE_FAILURE',
           payload: e
       });
   }
}


export const updateProperty = (payload) => async(dispatch) => {
    // console.log("payload",payload);

    const url = 'v1/myInfo/property/update';
    try{
        dispatch({
            type: 'PROPERTY_UPDATE_REQUEST',
            payload: null
        })
     
        const response =await api.putApi(url,payload);
        if(response.data && response.data.status === 200){
            return dispatch({
                type: 'PROPERTY_UPDATE_SUCCESS',
                payload: response.data
            });
        }else{
            return dispatch({
                type: 'PROPERTY_UPDATE_FAILURE',
                payload: response.data
            });
        }
    }catch(e){
        return dispatch({
            type: 'PROPERTY_UPDATE_FAILURE',
            payload: e
        });
    }
}


export const getAllPropertyList = (payload) => async(dispatch) => {
    const userDetails = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')): null;

    const url = `v1/myInfo/property/readAll/${userDetails?.accountId}`;
   try{
       dispatch({
           type: 'PROPERTY_DATA_REQUEST',
           payload: null
       })
       const response =await api.getApi(url);
       return dispatch({
           type: 'PROPERTY_DATA_SUCCESS',
           payload: response.data || []
       });
   }catch(e){
       return dispatch({
           type: 'PROPERTY_DATA_FAILURE',
           payload: e
       });
   }
}
export const getHomeType = () => async(dispatch) => {
    const url = 'v1/commonUtility/listValues/readByCategory/property_info-home_type';
   try{
       dispatch({
           type: 'HOME_TYPE_REQUEST',
           payload: null
       })
       const response =await api.getApi(url);
       if(response.data && response.data.status === 200){
           return dispatch({
               type: 'HOME_TYPE_SUCCESS',
               payload: response.data
           });
       }else{
           return dispatch({
               type: 'HOME_TYPE_FAILURE',
               payload: response.data
           });
       }
      
   }catch(e){
       return dispatch({
           type: 'HOME_TYPE_FAILURE',
           payload: e
       });
   }
}

export const getPropertyType = (payload) => async(dispatch) => {
    const url = 'v1/commonUtility/listValues/readByCategory/property_info-type';
    try{
        dispatch({
            type: 'PROPERTY_TYPE_REQUEST',
            payload: null 
        })
        const response = await api.getApi(url);
        if(response.data && response.data.status === 200) {
            return dispatch({
                type: 'PROPERTY_TYPE_SUCCESS',
                payload: response.data
            })
        } else {
            return dispatch({
                type: 'PROPERTY_TYPE_FAILURE',
                payload: response.data
            })
        }
    }
    catch(e) {
        return dispatch({
            type: 'PROPERTY_TYPE_FAILURE',
            payload: e
        })
    }
}

export const deletePropertyInventory = (payload) => async(dispatch) => {
    const url = `v1/myInfo/property/delete`;
   try{
       dispatch({
           type: 'DELETE_PROPERTY_REQUEST',
           payload: null
       })
       const response =await api.deleteapi(url,payload);
       return dispatch({
           type: 'DELETE_PROPERTY_SUCCESS_OBJECT',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'DELETE_PROPERTY_FAILURE_OBJECT',
           payload: e
       });
   }
};
export const getSinglePropertyList = (propertyId) => async(dispatch) => {

    const url = `v1/myInfo/property/read/${propertyId}`;

   try{
       dispatch({
           type: 'PROPERTY_FETCH_REQUEST',
           payload: null
       })
       const response =await api.getApi(url);
       return dispatch({
           type: 'PROPERTY_FETCH_SUCCESS',
           payload: response.data || []
       });
   }catch(e){
       return dispatch({
           type: 'PROPERTY_FETCH_FAILURE',
           payload: e
       });
   }

}

export const copyPropertyInventoryDetails = (payload) => async(dispatch) => {
    const url = `v1/myInfo/property/duplicate`;
   try{
       dispatch({
           type: 'COPY_PROPERTY_REQUEST',
           payload: null
       })
       const response =await api.postApi(url,payload);
       return dispatch({
           type: 'COPY_PROPERTY_SUCCESS_OBJECT',
           payload: response.data
       });
   }catch(e){
       return dispatch({
           type: 'COPY_PROPERTY_FAILURE_OBJECT',
           payload: e
       });
   }
};


export const downloadPropertyInventoryDetails = (payload) => async(dispatch) => {
    const url = `v1/myInfo/property/exportAsPdf/${payload?.id}`;
   try{
       dispatch({
           type: 'DOWNLOAD_PROPERTY_DATA_REQUEST',
           payload: null
       })
       const response =await api.getApi(url, {responseType: "blob"});
       const fileName = payload?.owner_name+'-'+payload?.home_name+'-'+new Date().getDate()+'.pdf'
      if(response && response.data){
        const blob = new Blob([response.data])
        fileDownload(blob, fileName )
      }
       if(response.data && response.data.status === 200){
           return dispatch({
               type: 'DOWNLOAD_PROPERTY_DATA_SUCCESS',
               payload: true
           });
       }else{
           return dispatch({
               type: 'DOWNLOAD_PROPERTY_DATA_FAILURE',
               payload:"Somthing went wronge"
           });
       }
   }catch(e){
    return dispatch({
        type: 'DOWNLOAD_DATA_FAILURE',
        payload:"Somthing went wronge"
    });
   }
}
