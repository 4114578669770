import React, { useEffect, useState } from 'react'
import ContentWrapper from '../../../../../common/components/ContentWrapper';
import { Grid } from "@mui/material";
import SecurityPinModal from "./SecurityPinModal.js";
import BankAccountList from './BankAccountList.js';
import { useLocation } from 'react-router-dom';
import redLock from '../../../../../assets/images/My Bank Account Stepper/red-lock.png';
import "./styles/bankAccounts.css"

const MainLayout = ({}) => {
  const [securityLockDialogOpen, setSecurityLockDialogOpen] = useState();
  const location = useLocation();
  const data = location.state
  useEffect(() => {
    setSecurityLockDialogOpen(data?.data === "false" ? false : true);
  }, [])

  const handleclose = () => {
    setSecurityLockDialogOpen(false)
  }
  return (
    <Grid container style={{ width: '100%', margin: 0 }}>
      <div className='bank-accounts-container'>
      <Grid container
        item
        xs={12}
        md={12}
        style={{ display: "flex", flexWrap: 'wrap',padding:'40px 0px 44px 0px',gap: 30, justifyContent:'flex-start'}}
      >
        <BankAccountList />
      </Grid>
      </div>
      {securityLockDialogOpen ? (
        <SecurityPinModal openDialog={securityLockDialogOpen} onClose={handleclose} BgColor={true} />
      ) : null}
    </Grid>
  )
}

const MyBankAccounts = () => {

  return (
    <ContentWrapper title={"My Bank Accounts"} img={false}  gobackUrl={'/home'} goback={true} contenview={<MainLayout />} />
  )
}

export default MyBankAccounts;