import React from 'react';
import ContentWrapper from '../../../../common/components/ContentWrapper';
import NeedHelp from './needHelp';

const NeedHelpComponent = () =>{
    return(
        <ContentWrapper title={"I NEED HELP"} gobackUrl={'/home'} goback={true} image={false} img={false} contenview={<NeedHelp/>}/> 
    )
}

export default NeedHelpComponent;