import React, { useState, useRef, useEffect } from "react";
import "../../../../index.css";
// import "../styles/home.css";
import { Dialog, DialogActions, Box,DialogContent, TextField, Typography, Button, Collapse, Alert, Divider, DialogTitle, useMediaQuery } from "@mui/material";
import LockIcon from '../../../../assets/images/security-lock.svg';
import { verifySecurityLock, resetVerifyLock, } from "../redux/actions";
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import * as formatters from '../../../../utils/util';
import CloseIcon from '../../../../assets/icons/modal-close-icon.svg';
import { useNavigate } from "react-router-dom";
import info from "../../../../assets/icons/info-i-icon.svg"
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { sendOtp } from "../../Home/redux/actions";
import { styled } from '@mui/material/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckIcon from "../../../../assets/images/CheckIcon.svg";
import { LoadingButton } from "@mui/lab";
// import CloseIcon from "../../../../assets/icons/modal-close-icon.svg";
import { use } from "i18next";
import  "../../Login/styles/login.css"



let count = 0;
const EnterSecurityLockDialog = ({ verifyLockData, verifyLockError, verifySecurityLock, resetVerifyLock, setState, from = null, setIsPopUp, sendOtp, otpSent,isCloseIconNeeded }) => {
  const [openDialog, setOpenDialog] = useState(true)
  const navigate = useNavigate();
  const [pin, setPin] = useState(['', '', '', '', '', '']);
  const [securityLockErr, setSecurityLockErr] = useState('');
  const refs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];
  const [openAlert, setOpenAlert] = useState(false);
  const [OtpSent, setOtpSent] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const resendSecurityLockOTP = () => {
    const userDetails = window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem('userInfo')) : null
    const payload = {
      userId: userDetails?.id
    }
    sendOtp(payload);
    setTimeout(() => {
      setOpenAlert(true);
      setOtpSent(true)
    }, 1200);
  }
const isMobile = useMediaQuery('(max-width: 800px)');
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#FF8620',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    color: "white",
    backgroundColor: '',
    // border: ' 1px solid #FF8620 ',
    borderRadius: "7px",
    // minWidth: 400,
    padding: "15px",
    fontSize: "16px",
    fontWeight: 400,
    maxWidth: isMobile ?240:400,
    // [theme.breakpoints.down('sm')]: {
    //   minWidth: "280px",
    //   maxWidth:'300px', 
    //   padding: '8px', 
    //   marginLeft: '0px', 
    // },

  },
}));
  const handleInputChange = (index, value) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newPin = [...pin];
      newPin[index] = formatters.isNumaric(value);
      setPin(newPin);

      if (value.length === 1 && index < refs.length - 1) {
        refs[index + 1].current.focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Enter') {
     onSubmit();
    }
   else if (e.key === 'Backspace' && index > 0 && pin[index] === '') {
      refs[index - 1].current.focus();
    } else if (e.key === 'ArrowLeft' && index > 0) {
      refs[index - 1].current.focus();
    } else if (e.key === 'ArrowRight' && index < refs.length - 1) {
      refs[index + 1].current.focus();
    }
  };
 
  useEffect(() => {
    if (verifyLockData && verifyLockData.status === 200) {
    setIsDisabled(false)
      setOpenDialog(false);
      if (from) {
        setIsPopUp(false);
        setState("Active");
        window.localStorage.removeItem('securityPopup')
        window.sessionStorage.removeItem('isExistingTab')
      }
      resetVerifyLock(from);
    }
    if (verifyLockData && verifyLockData.status !== 200) {
    setIsDisabled(false)
      setSecurityLockErr(verifyLockData?.message || "");
      count = count + 1;
      if (count >= 3 && from) {
        window.sessionStorage.clear();
        window.localStorage.clear();
        window.location.href = '/login'
      }

    }
  }, [verifyLockData, verifyLockError])

  useEffect(() => {
    const timer = setTimeout(() => {
      setOtpSent(false);
    }, 5000)
    return () => clearTimeout(timer);
  }, [OtpSent])

  const resetErrorMessages = () => {
    setSecurityLockErr('')
  }
  const onSubmit = () => {
    const securityLock = pin.join('');
    setSecurityLockErr('')
    if (securityLock?.trim().length === 0) {
      setSecurityLockErr('Security Lock is required.');
      return;
    } else if (securityLock.length !== 6) {
      setSecurityLockErr('Enter valid Security Lock.');
      return;
    }else{
      const payload = {
        securityLock: securityLock,
        id: user.id,
      }
      setSecurityLockErr('')
      setIsDisabled(true)
      verifySecurityLock(payload);
    }
    
  }

  const onClosingDialog = () => {
    setPin(['', '', '', '', '', '']);
    setSecurityLockErr('');
    handleGoBack();
    resetVerifyLock();
  }
  const handleGoBack = () => {
    navigate('/home'); // Navigate back one step in the history
  };

  const user = window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem('userInfo')) : null;


  return (
    <Dialog
      open={openDialog}
      // onClose={handleCloseDialog}
      className="small-dialog-lock dialog-box-lock"
      aria-labelledby="small-dialog-title"
      aria-describedby="small-dialog-description"
    >
      {OtpSent ? (
        (
          <div>
            <DialogTitle>

            </DialogTitle>

            <DialogContent>
              <div className="content-dialog">
                <img src={CheckIcon} alt="success" style={{ justifyItems: "center", width: "30%" }} />
                <Typography>
                  We have emailed you a 6 digit verification code. Please check your email inbox.
                </Typography>
              </div>
            </DialogContent>

          </div>
        )
      ) : (
        <>
          <DialogContent   >

              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ float: "left" }}>
                  <LightTooltip
                    enterTouchDelay={0}
                    leaveTouchDelay={5000} arrow placement="bottom" title="Security Lock - 6-digit security code to unlock the system">
                    <img src={info} alt="" height="25px" width="25px" />
                  </LightTooltip>
                </div>
                {isCloseIconNeeded && <div style={{cursor:"pointer"}}> 
                  <img src={CloseIcon} alt=""  onClick={()=>{
                    navigate(-1)
                  }} className="close-icon-lock-popup" />
                </div>}
                
              </Box>
            <div className="content-dialog-lock"  >
              <img src={LockIcon} alt='phone'  className="lock-icon-lock-popup" width='87px' height='87px' />

              <Typography className="primary-font-medium-small"><b>Please enter your <br />Security Lock to continue</b></Typography>
              <div>
                <Typography className='font-normal-small error-message'>{securityLockErr}</Typography>
              </div>
            </div>
            <div style={{ display: 'flex', gap: '10px' }}>
              {pin.map((digit, index) => (
                <TextField
                autoComplete="off"
                  key={index}
                  type="password"
                  variant="outlined"
                  inputProps={{ maxLength: 1 }}
                  id={`input-${index}`}
                  value={digit}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  inputRef={refs[index]}
                  className='security-input-lock'
                  style={{
                    width: '50px',
                    textAlign: 'center',
                    boxShadow: digit ? '0px 2px 8px #00000029' : 'none',
                    transition: 'box-shadow 0.2s ease-in-out',
                  }}
                />
              ))}
            </div>
            <div>
              <Typography
                style={{ cursor: "pointer", color: "red", textAlign: "center", paddingTop: "20px", paddingBottom: "15px", textDecoration: "underline" }}
                onClick={() => {
                  // navigate("/reset-security-lock");
                  resendSecurityLockOTP()
                }}
              >
                Forgot Security Lock?
              </Typography>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="action-holder-1" style={{ marginTop: 0, marginBottom: 20 }}>
              {!isDisabled && (<Button style={{width: '70%'}} disabled={isDisabled} variant="outlined" className="primary-font-bold-5 action-btn-yes profile-save-btn"  onClick={onSubmit}>Submit</Button>)}
              {isDisabled && <LoadingButton
                    size="small"
                    className="primary-font-bold-6 action-btn profile-save-btn"
                    loading={true}
                    loadingPosition="start"
                    variant="outlined"
                    disabled
                    style={{ opacity: '0.5',width: '70%' }}
                >
                    <span>Verifying</span>
                </LoadingButton>}
            </div>
            
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}



EnterSecurityLockDialog.defaultProps = {
  verifyLockData: null,
  verifyLockError: null,
};

function mapStateToProps(state) {
  return {
    verifyLockData: state.profile.verifyLockData,
    verifyLockError: state.profile.verifyLockError,
    otpSent: state.home.isOTPSent,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      verifySecurityLock,
      resetVerifyLock,
      sendOtp
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnterSecurityLockDialog);

