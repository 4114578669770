import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { Select, MenuItem, FormControl, Box, Drawer, List, Card, CardContent, CardActions, Typography, } from "@mui/material";
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from "redux";
import Notification from "./NotificationSystem";
import ListItem from '@mui/material/ListItem';
import Badge from '@mui/material/Badge';
import { Avatar } from '@mui/material';

//Internal Files
import twitter from "../../../../assets/icons/twitter-icon.svg";
import facebook from "../../../../assets/icons/facebook-icon.svg";
import instagram from "../../../../assets/icons/instagram-icon.svg";
import languageicon from "../../../../assets/icons/internet-icon.svg";
import CircleUser from "../../../../assets/icons/circle-user.svg";
import notification from "../../../../assets/icons/notification.svg";
import close from "../../../../assets/icons/close-icon.svg";

// Css Files
import "./Header.css";
import TopBar from "./TopBar";
import {signOutAccount} from '../../../../containers/Pages/Login/redux/actions';
import {getNotificationUnReadCountData, getAllNotificationList} from '../redux/actions'

const Header = ({signOutAccount, logoutData, unreadNotificationCount, getNotificationUnReadCountData, getAllNotificationList, notificationList}) => {
  
  const { i18n } = useTranslation('common');
  const navigate = useNavigate();
  const profile = useSelector((state) => state.profile);
  const [open, setOpen] = React.useState(false);
 
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  let listInnerRef = useRef();
  const [currPage, setCurrPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [lastList, setLastList] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [language, setLanguage] = useState( window.innerWidth <=767?"En":"English");
  const userDetails = profile?.userInfoData?.data ? profile.userInfoData?.data : (window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem('userInfo')) : null) ;
  const userName =( (userDetails?.firstName  && userDetails?.firstName !== null  ? userDetails?.firstName : '')  + 
                               ( userDetails?.middleName && userDetails?.middleName !== null ? (' '+userDetails.middleName)  : "") +
                               (userDetails?.lastName && userDetails?.lastName !== null ? (' '+userDetails.lastName ) : ""))

  const langs = {
    "en": "English",
    "fr": "French",
    "sp": "Spanish"
  }
  const langs1 = {
    "en": "En",
    "fr": "Fr",
    "sp": "Sp"
  }
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    marginLeft: "16px"
  }));

  const handleChange = (e) => {
    i18n.changeLanguage(e.target.value)
    setLanguage(screenWidth <= 767 ? langs1[e.target.value] : langs[e.target.value]);

  };
  const handleNotificationClick = () => {
    toggleDrawer();
    listInnerRef = 0;
    setCurrPage(1);
    setPrevPage(0);
    setLastList(false)
    // getAllNotificationList();
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  }

  const handlecloseDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    setOpen(false)
  };
  const handleMenuClick = (url) => {
    navigate(url)
  };
  const logout = () => {
    signOutAccount()
  }
  useEffect(()=>{
    if(logoutData && logoutData.signOutAccount){
      try{
        window.localStorage.removeItem('userInfo');
        window.localStorage.removeItem('tokendetails');
        window.localStorage.removeItem('registeremail');
        window.localStorage.removeItem('selectedUser');
        window.localStorage.removeItem('forgotpassEmail');
        window.localStorage.removeItem('hiddenEmail');
        window.localStorage.removeItem('menuTitle');
        window.localStorage.clear()
      }catch(e){
        console.log("remove cahce error")
      }
      navigate('/')
    }
  },[logoutData?.signOutAccount]);

  useEffect(()=>{
    getNotificationUnReadCountData()
  },[])

  useEffect(()=>{
    if(notificationList &&notificationList.status === 200){
      setNotifications(notificationList?.data);
    }
  },[notificationList])

  return (
    <React.Fragment>
      <div style={{ position: 'sticky', top: '0', zIndex: 1200 }}>
        <div className="header-navbar-1">
          
            <Box className="header-navbar-container">
            <Grid container> 
            <Grid  item xs={12} md={12} lg={12} className="header-nav-bar">
              {/* <Grid item xs={4.5} md={6} lg={6}> */}
              <Box Box sx={{ display: "flex", justifyContent: "start",  alignItems: "center", pt: "6px", }}>
                
                <div className="social-icon">
                  <a
                    href="https://twitter.com/neptanow"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img src={twitter} alt="twitter"  className="social-icon-logo"/>
                  </a>
                </div>
                <div className="social-icon">
                  <a
                    href="https://www.facebook.com/nepta.us"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img src={facebook} alt="facebook"  className="social-icon-logo"/>
                  </a>
                </div>
                <div className="social-icon">
                  <a
                    href="https://www.instagram.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img src={instagram} alt="instagram" className="social-icon-logo" />
                  </a>{" "}
                </div>
              </Box>
              {/* </Grid> */}
              {/* <Grid item xs={7.5} md={6} lg={6}> */}
              <Box className="header-last-containar">
                <FormControl  sx={{ my:"auto"}}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="language-select"
                    label="Language"
                    value={(value) => {
                      return (
                        <Box className="language-container primary-font-medium-8 "  >
                          <img src={languageicon} alt="language"  className="user-profile-icon"  />
                          {language}
                        </Box>
                      );
                    }}
                    onChange={handleChange}
                    renderValue={(value) => {
                      return (
                        <Box className="language-container primary-font-medium-8 " >
                          <img src={languageicon} alt="language" className="user-profile-icon" />
                          {language}
                        </Box>
                      );
                    }}

                  >
                    <MenuItem className="language-dropdown" value={"en"}>English</MenuItem>
                    <MenuItem disabled className="language-dropdown" value={"sp"}>Spanish</MenuItem>
                    <MenuItem disabled className="language-dropdown" value={"fr"}>French</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ my:"auto"  }} className="user-profile">
                  <Select
                    labelId="userProfile"
                    id="userProfile"
                    label="profile"
                    value={(value) => {
                      return (
                        <Box  className=" language-container primary-font-medium-8" >
                            <img   className="user-profile-icon" src={userDetails?.image_PreSignedUrl ? userDetails?.image_PreSignedUrl : CircleUser} alt="user-profile"  />
                            {userDetails?.firstName+' '+ userDetails?.middleName+' '+ userDetails?.lastName}
                            { screenWidth <=767 ?userName.charAt(0):userName}
                        </Box>
                      );
                    }}
                    onChange={handleChange}
                    renderValue={(value) => {
                      return (
                        <Box  className=" language-container primary-font-medium-8" >
                            <img className="user-profile-icon" src={userDetails?.image_PreSignedUrl ? userDetails?.image_PreSignedUrl : CircleUser} alt="user-profile" />
                             { screenWidth <=767 ?userName.charAt(0):userName}
                            {/* {userDetails?.firstName+' '+ userDetails?.middleName+' '+ userDetails?.lastName} */}
                        </Box>
                      );
                    }}
                  >
                    <div className="user-profile-list">
                      <MenuItem value={"My Profile"} className="font-bold-small" onClick={(e) => { handleMenuClick('/profile'); }}>My Profile</MenuItem>
                      {/* <MenuItem value={"My Subscription"} className="font-bold-small" onClick={(e) => { handleMenuClick('/my-subscription'); }}>My Subscription</MenuItem> */}
                      <MenuItem value={"Manage Password"} className="font-bold-small" onClick={(e) => { handleMenuClick('/manage-password'); }}>Manage Password</MenuItem>
                      <MenuItem value={"Logout"} className="font-bold-small" onClick={(e) => { logout(); }}>Logout</MenuItem>
                    </div>
                  </Select>
                </FormControl>
              
             
                <Badge color="secondary" className="badge-icon" badgeContent={unreadNotificationCount} >
                  <img
                    src={notification}
                    alt="Notification"
                    // style={{
                    //   // display: 'flex',
                    //   // justifyContent: 'flex-start',
                    //   // pt: '6px',
                    //   height:"64px",
                    //   width:"64px",
                    //   cursor:"pointer"
                    // }}
                    className="notificaion-img"
                    onClick={handleNotificationClick}
                  />
                  </Badge>

            
              </Box>
              </Grid>
              <div>
                  <Drawer
                    variant="persistent"
                    anchor="right"
                    className="drawer-container"
                    sx={{
                      // '&.MuiPaper-root.MuiDrawer-paper':{backgroundColor:"white"},
                      // '&.MuiDrawer-root .MuiDrawer-paper': { marginTop: '80px',backgroundColor:"white" },
                      // "& .MuiPaper-root": { 
                      //   Height: "100%",
                      //   width: "320px",
                      //   overflowY: "auto",
                      // }
                    }}
                    open={isDrawerOpen} onClose={toggleDrawer}
                  >
                    
                    <DrawerHeader className="noti-title-container" sx={{display:"flex",justifyContent:"space-between" ,mr:"20px",borderBottom:"2px solid #D8D8D8"}} >
                    <Typography className="primary-font-bold-read-more">Notification</Typography>
                      <img src={close} onClick={handlecloseDrawer} alt='close' height={20} width={20} style={{cursor:"pointer"}} />
                     
                    </DrawerHeader>
                      <ListItem sx={{marginTop: "0px", p:"0px"}}>
                      <Notification  data={isDrawerOpen} currPage={currPage} prevPage={prevPage} setCurrPage={setCurrPage} setPrevPage={setPrevPage} lastList={lastList} setLastList={setLastList} listInnerRef={listInnerRef} />
                    </ListItem>
                  </Drawer> 

                </div>
              {/* </Grid> */}

              </Grid>
            </Box>
           
         
        </div>
        <div 

        >
        <TopBar />
        </div>
      </div>
    </React.Fragment>
  );
};


function mapStateToProps(state) {
  return {
  logoutData: state.login.data,
  errorData: state.login.error,
  unreadNotificationCount: state.emergencyAlert.unreadNotificationCount,
  notificationList: state.emergencyAlert.notificationList
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      signOutAccount,
      getNotificationUnReadCountData,
      getAllNotificationList
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);

