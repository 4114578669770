import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import { Grid, Card, CardContent, CardActions, Typography, Button, Dialog, DialogContent, DialogActions, Divider, IconButton } from "@mui/material";
import Card1 from '../../../../../assets/images/my-assets/firearm.png';
import Card2 from '../../../../../assets/images/my-info/ccard2.png';
import LockLight from '../../../../../assets/images/lock-light.svg';
import DeleteFilledIcon from '../../../../../assets/images/filled-delete.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

const ImageHolder = styled('div')({
    textAlign: 'center',
    height: 120, 
    border: '1px solid #E9E9E9', 
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  });

const FireArm = () => {
    const { t } = useTranslation('common');
    const navigate = useNavigate();

    const [removeDialog, setRemoveDialogOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    
    
      const FireArmsDetails = [{
        imageSrc: Card1,
        imageAlt: 'Primary Card',
        cardName: 'Primary Card',
        model:'P229R DAK',
        color:'Black'
    }]

return (
    <>
    {FireArmsDetails.map((item, i)=> {
            return (
                <Card style={{ minWidth: 338, padding: 10 }} className="card-box" key={'familyMemberCard_'+i}>
                <ImageHolder style={{height: 185}}>
                    <img src={item.imageSrc}  alt={item.imageAlt}/>
                </ImageHolder>
                <CardContent className="card-content-box" sx={{alignItems: 'start'}}>
                  
                    <div style={{justifyContent:'space-between', display: 'flex',flexDirection:'column', marginTop: 23, width: '100%'}}>
                        <Typography className="primary-font-medium">
                            {item.model}
                        </Typography>
                        <Typography className=''>{item.color}</Typography>
                    </div>

                    <Divider style={{borderColor: '#D8D8D8', width: '100%', marginTop: 10, marginBottom: 10}}/>
                    {removeDialog && (
                        <Dialog
                        open={removeDialog}
                        onClose={()=> setRemoveDialogOpen(false)}
                        className="small-dialog dialog-box"
                        aria-labelledby="dialog-title"
                        aria-describedby="dialog-description"
                        >
                            <DialogContent className="content-dialog">
                                <img src={DeleteFilledIcon} alt='phone' style={{marginRight: 10}} width='118px' height='118px' />
                                <Typography  className="primary-font-normal-4">Are you sure want to delete?</Typography>
                            </DialogContent>
                            <Divider />
                            <DialogActions>
                            <div className="action-holder" style={{marginTop: 20, marginBottom: 0, gap: 40}}>
                                <Button variant="outlined" className="primary-font-bold-5 action-btn-no profile-cancel-btn" onClick={()=> setRemoveDialogOpen(false)}>No</Button>
                                <Button variant="outlined" className="primary-font-bold-5 action-btn-yes profile-save-btn" >Yes</Button>
                            </div>
                            </DialogActions>
                        </Dialog>
                    )}
                    </CardContent>
                <CardActions style={{display: 'flex',justifyContent:'center',alignItems:'center', padding: 0}}>
                    <div className="action-button-dlt" onClick={()=> setRemoveDialogOpen(true)}></div>
                    <div className="action-button-edit" onClick={()=>{navigate('/edit-fire-arms')}}></div>
                </CardActions>
            </Card>
            )
        })}
        <Card sx={{ minWidth: 338 }} className="add-card-box" onClick={() => { navigate('/add-fire-arms'); } }>
            <div className="add-icon">
            </div>
            <Typography style={{ textAlign: 'center', marginTop: 41 }} className="primary-font-medium">
                Add <br /> My Firearm
            </Typography>
        </Card>
    </>

)}

export default FireArm;