import React, { useEffect, useState } from "react";
import "../../../../index.css";
import "../styles/home.css";
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider,Typography, Button } from "@mui/material";
import { setSecurityPin } from "../redux/actions";
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import * as formatters from '../../../../utils/util';


const SecurityPinDialog = ({openDialog, pinData, pinError, isVerifyingPin, setSecurityPin, closeDialog}) => {
  const [newSecurityPin, setNewSecurityPin] = useState('');
  const [confirmNewSecurityPin, setConfirmNewSecurityPin] = useState('');
  const [newSecurityPinErr, setNewSecurityPinErr] = useState('');
  const [confirmNewSecurityPinErr, setConfirmNewSecurityPinErr] = useState('');

  const [showSecurityPin, setShowSecurityPin] = useState(false);
  const [showConfirmSecurityPin, setShowConfirmSecurityPin] = useState(false);

  const handleClickShowSecurityPin = () => setShowSecurityPin(!showSecurityPin);
  const handleClickShowConfirmSecurityPin = () => setShowConfirmSecurityPin(!showConfirmSecurityPin);

  const handleMouseDownSecurityPin = () => setShowSecurityPin(!showSecurityPin);
  const handleMouseDownConfirmSecurityPin = () => setShowConfirmSecurityPin(!showConfirmSecurityPin);

  const user = window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem('userInfo')) : null;

  const onSecurityPinChange = (e, type) => {
    if (type === 'newSecurityPin') {
        setNewSecurityPin(formatters.isNumaric(e.target.value));
    } else if (type === 'confirmNewSecurityPin') {
        setConfirmNewSecurityPin(formatters.isNumaric(e.target.value));
    }
  }

  useEffect(()=>{
    if(pinData && pinData.status === 200){
      const userDetails = window.localStorage.getItem("userInfo")
      ? JSON.parse(window.localStorage.getItem("userInfo"))
      : null;
      userDetails.securityPin = true;
      window.localStorage.setItem("userInfo", JSON.stringify(userDetails))
      
        handleCloseDialog()
    }
    if(pinData && pinData.status !== 200){
        setNewSecurityPinErr(pinData?.message || "")
    }
  },[pinData, pinError])
  
  const handleCloseDialog = (value) => {
    closeDialog()
    setNewSecurityPin('');
    setConfirmNewSecurityPin('');
    setNewSecurityPinErr('');
    setConfirmNewSecurityPinErr('');
  };    
  const resetErrorMessages = () => { 
    setNewSecurityPinErr('');
    setConfirmNewSecurityPinErr('');
  }
  const onSaveChangePin = () => { 
    let isValid = false;
    resetErrorMessages();
    if (!newSecurityPin?.trim().length) {
        setNewSecurityPinErr('This is required.');
        isValid = true;
    } else if (newSecurityPin?.trim().length !== 4) {
        setNewSecurityPinErr('Security Pin should contain 4 digits.');
        isValid = true;
    }
    if (!confirmNewSecurityPin?.trim().length) {
        setConfirmNewSecurityPinErr('This is required.');
        isValid = true;
    } else if (newSecurityPin !== confirmNewSecurityPin) {
        setConfirmNewSecurityPinErr('Security Pin and Confirm Security Pin mismatch.');
        isValid = true;
    } 
    if(isValid) {
        return;
    }
    const payload = {
        securityPin: newSecurityPin,
        id:user?.id,
        updatedBy: user?.id
    }
    setSecurityPin(payload);
  }
return (
        <Dialog
        open={openDialog}
        // onClose={handleCloseDialog}
        className="change-pwd-dialog dialog-box"
        aria-labelledby="change-pwd-dialog-title"
        aria-describedby="change-pwd-dialog-description"
        >
            <DialogTitle id="change-pwd-dialog-title">
                <Typography className='primary-font-bold-heading1'>Enter Security Pin</Typography>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <div>
                  <Typography className='font-normal-small error-message'>{newSecurityPinErr}</Typography>
                  <Typography className='font-normal-small profile-input-label'>Security Pin</Typography>
                  <div className="input-holder"> 
                    <input className={`profile-input ${newSecurityPinErr !== '' ? 'err-input' : ''}`}  aria-label='new-SecurityPin' maxLength={4}  type={showSecurityPin ? "text" : "password"} onChange={(e) => {onSecurityPinChange(e, 'newSecurityPin')}}  value={newSecurityPin}/>
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="password" 
                        onClick={handleClickShowSecurityPin}
                        onMouseDown={handleMouseDownSecurityPin}
                        className="input-eye-icon pwd-icon1"
                        >
                        {showSecurityPin ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                  </div>
              </div>
              <div>
                  <Typography className='font-normal-small error-message'>{confirmNewSecurityPinErr}</Typography>
                  <Typography className='font-normal-small profile-input-label'>Confirm Security Pin</Typography>
                  <div className="input-holder"> 
                    <input className={`profile-input ${confirmNewSecurityPinErr !== '' ? 'err-input' : ''}`}  aria-label='confirm-new-SecurityPin' maxLength={4}  type={showConfirmSecurityPin ? "text" : "password"} onChange={(e) => {onSecurityPinChange(e, 'confirmNewSecurityPin')}}  value={confirmNewSecurityPin}/>
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="password" 
                        onClick={handleClickShowConfirmSecurityPin}
                        onMouseDown={handleMouseDownConfirmSecurityPin}
                        className="input-eye-icon pwd-icon1"
                        >
                        {showConfirmSecurityPin ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                  </div>
              </div>
            </DialogContent>
            <Divider />
            <DialogActions>
            <div className="action-holder" style={{marginTop: 20, marginBottom: 0}}>
                {/* <Button variant="outlined" className="primary-font-bold-6 action-btn profile-cancel-btn" onClick={handleCloseDialog}>Cancel</Button> */}
                <Button variant="outlined" className="primary-font-bold-6 action-btn profile-save-btn"  onClick={onSaveChangePin}>Save</Button>
            </div>
            </DialogActions>
        </Dialog>
    )
}



SecurityPinDialog.defaultProps = {
    pinData: null,
    pinError: null,
    isVerifyingPin: null,
  };
  
  function mapStateToProps(state) {
    return {
        pinData: state.home.pinData,
        pinError: state.home.pinError,
        isVerifyingPin: state.home.isVerifyingPin,
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        setSecurityPin
      },
      dispatch
    );
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(SecurityPinDialog);

