import React from 'react'
import ContentWrapper from '../../../../../common/components/ContentWrapper';
import ViewPredfinedMessages from './ViewPredefinedMessages';
import { Grid } from '@mui/material';
import "../PreDefinedMessages/styles/PredefinedMessages.css";
const MainLayout = ({ goback = true,showEdit = true, showDelete = true }) => {
    
  return (
      <Grid container sx={{m:0,width:"100%"}} >
        {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
        <Grid
        container
          xs={12}
          md={12}
          lg={12}
            
         sx={{ display: "flex",
          flexWrap:"wrap",
          backgroundolor: "#fff",
          boxShadow: "0px 1px 12px #00000026"}}
        >
          <Grid  className='message-container'>
            <ViewPredfinedMessages showEdit={showEdit} showDelete={showDelete}/>
           </Grid>
          </Grid>
        {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
         
      </Grid>
  )
  }

const PredefinedMessages = () => {
  return (
    <ContentWrapper title={"Manage Predefined Messages"}
      gobackUrl={'/manage-predefined-messages'}
      goback={true}
      contenview={<MainLayout />}
    />
  );
}
export default PredefinedMessages;
