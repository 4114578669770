import React, { useEffect, useState } from "react";
import "../../../index.css";
import "../../styles/layout.css";
import "../Layout/Header/Header.css"
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import HelpAlert from "../../../assets/icons/need-alert.png";
import redLock from "../../../assets/images/My Bank Account Stepper/red-lock.png";
import ActivatedHelp from "../../../assets/icons/help-activated.png";
import ArrowLeft from "../../../assets/icons/arrow-left.svg";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const ActivateAlert = ({ goback = false, title, gobackUrl, image = true }) => {
  const [helpImage, setHelpImage] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const ishelp = window.localStorage.getItem("isHelpActivated");

  const handleGoBack = () => {
    dispatch({
      type: "TRACK_ME_READ_SUCCESS",
      payload: {}
    })
    if (window.location.pathname === "/edit-profile") {
      navigate("/profile", { state: { dontShowSecurityLock: true } });
    } else if (window.location.pathname === "/share-my-location/view" || window.location.pathname === "/share-my-location") {
      navigate("/track-me");
    } else if (window.location.pathname === "/track-me") {
      navigate("/home");
    } else if (window.location.pathname === "/emergency-alert") {
      dispatch({
        type: "ADD_GENCY_ALERT",
        payload: null,
      });
      navigate("/home");
    } else {
      navigate(-1); // Navigate back one step in the history
    }
  };

  useEffect(() =>{
    if(ishelp){
      setHelpImage(ActivatedHelp);
    }else{
      setHelpImage(HelpAlert)
    }
  },[ishelp])


  const handleHelp = () =>{
    if(helpImage === ActivatedHelp){
      navigate("/need-help/activate")
    }else if(helpImage === HelpAlert){
      navigate("/need-help")
    }
    // navigate("/need-help")
  }

  return (
  
      
   
      <Grid className="header-panel" container  style={{ marginTop: "0px" }}>
      <div className="header-navbar-container">
        {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
      
        <Grid
          container
          xs={12}
          md={12}
          lg={12}
          className="activate-alert-container"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            height: "100%",
            width:"100%",
            flexWrap: "wrap",
            my: "auto"

          }}
        >
          <Grid  xs={6} sx={{my:"auto"}}>
          {!goback && (
            <Box sx={{ my:"auto !important" }}>
              <Typography
                textTransform="uppercase"
                className="primary-font-bold-main-heading"
                style={{
                  fontFamily: "Trajan ProTrajan Pro",
                  paddingLeft: "8px"
                }}
              >
                {title}
              </Typography>
            </Box>
          )}
          {goback && (
            <Box sx={{my:"auto !important" }}>
              <Typography
                textTransform="uppercase"
                className="primary-font-bold-main-heading"
                style={{ fontFamily: "Trajan ProTrajan Pro" }}
              >
                {title}
                {window.location.pathname === "/add-bank-account" && (
                  <>
                    <img src={redLock} alt="lock" style={{ marginLeft: 15, width: "18px" }} />
                  </>
                )}
              </Typography>
              <Typography
             
                style={{ marginTop: "25px", cursor: "pointer", marginLeft:"2px" }}
                className="primary-font-medium-2-heading go-back-title"
                onClick={handleGoBack}
              >
                <img
                  src={ArrowLeft}
                  alt="arrow-left"
                  style={{ marginRight: 10 }}
                />{" "}
                <span className="go-back">{t("Go Back")}</span>
              </Typography>
            </Box>
          )}
          </Grid>
          <Grid  xs={6} sx={{my:"auto"}}>
          {image && (
            <>
             {/* <div id="alert-position-static"> */}
             <Box className="help-alert1"  sx={{display:"flex",justifyContent:"end" }}> 
             <img src={helpImage} alt="emergency" style={{ marginTop: "14px", cursor:"pointer" }} className="help-alert" onClick={handleHelp} />
             {/* </div> */}
             </Box>
             <img src={helpImage} alt="emergency" className="help-alert-mobile help-alert" style={{ cursor:"pointer"}} onClick={handleHelp} />
            </>
             
          )}
          </Grid>
          
        </Grid>
        </div>
        {/* <Grid item xs={1} md={1}></Grid> */}
      </Grid>
  
  
  );
};

export default ActivateAlert;
