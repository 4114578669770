import { Auth } from 'aws-amplify';
import {api} from '../../../../utils/apiClient';
import constants from '../../../../config/constants';
import awsConfig from '../../../../aws-exports';
import { CognitoIdentityProviderClient, AdminSetUserPasswordCommand } from "@aws-sdk/client-cognito-identity-provider"; // ES Modules import

const client = new CognitoIdentityProviderClient({ 
    region: "us-east-1",
    credentials: {
        accessKeyId: constants.COGNITO_ACCESS_KEY,
        secretAccessKey: constants.COGNITO_SECRET_KEY,
    }
});

export const fetchForgotPasswordData = (data) => async(dispatch) => {
    try{
        dispatch({
            type: 'FORGOT_PASSWORD_REQUEST',
            payload: null
        });
        const forgotPasswordResponse = await Auth.forgotPassword(data.username);
        return dispatch({type: 'FORGOT_PASSWORD_SUCCESS', payload: forgotPasswordResponse});

    } catch(e){
        dispatch({
            type: 'FORGOT_PASSWORD_FAILURE',
            payload: e
        });
    }
}

export const resetForgotPasswordData = (data) => async(dispatch) => {
    try{
        dispatch({
            type: 'FORGOT_PASSWORD_RESET_REQUEST',
            payload: null
        });
        const forgotPasswordResponse = await Auth.forgotPasswordSubmit(data.username, data.code,data.newPassword);
        return dispatch({type: 'FORGOT_PASSWORD_RESET_SUCCESS', payload: forgotPasswordResponse});

    } catch(e){
        dispatch({
            type: 'FORGOT_PASSWORD_RESET_FAILURE',
            payload: e
        });
    }
}


export const resetPasswordData = (data) => async(dispatch) => {
    //const endPoint = 'v1/user/password/reset';
  const endPoint = 'v1/user/updateStatusVerified';
    try{
        const forgotPasswordResponse = await api.putApi(endPoint,data);
      
        return dispatch({type: 'FORGOT_PASSWORD_SYSTEM_SUCCESS', payload: forgotPasswordResponse});

    } catch(e){
        dispatch({
            type: 'FORGOT_PASSWORD_SYSTEM_FAILURE',
            payload: e
        });
    }
}

export const checkLinkExpiration = (payload) => async(dispatch) => {
    const endPoint = 'v1/user/verifyLinkExpired';
    dispatch({type: 'INVITE_LINK_EXPIRERATION_REQUEST', payload: true});
    try{
        const response = await api.postNoToken(endPoint,payload);
        return dispatch({type: 'INVITE_LINK_EXPIRERATION_CHECK_SUCCESS', payload: response});

    } catch(e){
        dispatch({
            type: 'INVITE_LINK_EXPIRERATION_CHECK_FAILURE',
            payload: e
        });
    }
 }

export const resetForgotPwd = (payload) => async(dispatch) => {
   return dispatch({
        type: 'RESET_FORGOT_PWD',
        payload: null
    })
}

export const setAdminPassword = (payload) => async(dispatch) => {
    const input = { // AdminSetUserPasswordRequest
        UserPoolId: awsConfig.aws_user_pools_id, // required
        ClientId:awsConfig.aws_user_pools_web_client_id,
        Username: payload.username, // required
        Password:payload.password,
        Permanent: true || false,
      };
      try{
        const command = new AdminSetUserPasswordCommand(input);
        const response = await client.send(command);
      }catch(e){
      console.log(e)
    }
      
 } 


 export const setInvitedUserPassword = (payload) => async(dispatch) => {
    // const url = `v1/user/password/reset`;
    const url = 'v1/user/updateStatusVerified';

   try{
       dispatch({
           type: 'PASSWORD_UPDATE_REQUEST',
           payload: null
       })
        const response = await api.putApi(url,payload);
      
            return dispatch({
                type: 'FORGOT_PASSWORD_SYSTEM_SUCCESS',
                payload: response
            });
   }catch(e){
       return dispatch({
           type: 'FORGOT_PASSWORD_SYSTEM_FAILURE',
           payload: e
       });
   }
}
