import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './containers/App/App';
import {Amplify} from 'aws-amplify'

import awsConfig from './aws-exports';
import constants from './config/constants';
const isLocalhost = constants.COGNITO_ENVIRONMENT;
const redirectSigninUrl = isLocalhost === 'LOCAL' ? constants.CONGNITO_LOCAL_SIGNIN : (isLocalhost === 'DEV' ? constants.CONGNITO_DEV_SIGNIN : constants.CONGNITO_STAGING_SIGNIN)
const redirectSignOutUrl = isLocalhost === 'LOCAL' ? constants.CONGNITO_LOCAL_SIGNOUT : (isLocalhost === 'DEV' ? constants.CONGNITO_DEV_SIGNOUT : constants.CONGNITO_STAGING_SIGNOUT)
const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: redirectSigninUrl,
    redirectSignOut: redirectSignOutUrl,
  }
}
Amplify.configure(updatedAwsConfig);



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


