import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel, Divider, Modal, Box, color } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import close from "../../../../../assets/icons/close-icon.svg"
import { FormatUnderlinedRounded } from "@mui/icons-material";
const AccountDetailsEditModal = (props) => {
  console.log(props, "AccountDetailsEditModal value");
 
  const style = {
    position: 'absolute',
    top: '53%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    maxHeight: 550,
    bgcolor: 'background.paper',
    border: "none",
    borderRadius: "5px",
    p: 2,
  };
  const initialFormData = {
    socialMediaAccount:props.label,
    deleteAccount: "",
    userName: '',
    password: ''
  };
  const [formData, setFormData] = useState(initialFormData);
  // console.log(initialFormData,"33333333333333");
  // console.log(formData,"aaaaaaa");
  const [errors, setErrors] = useState(initialFormData);

  const [showPassword, setShowPassword] = useState(false);
  const [showCode, setShowCode] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleRadioChange = (event) => {
    setFormData({ ...formData, ['deleteAccount']: event.target.value });
  };



  const handleSubmit = (e) => {

    e.preventDefault();
    setFormData({...formData,["socialMediaAccount"]:props.label})
    const newErrors = {};
    // if (formData.socialMediaAccount.trim() === '') {
    //   newErrors.socialMediaAccount = 'This is required.';
    // }
    if (formData.userName.trim() === '') {
      newErrors.userName = 'This is required.';
    }
    if (formData.deleteAccount === '' || !formData.deleteAccount) {
      newErrors.deleteAccount = 'This is required.';
    }

    if (formData.password.trim() === '') {
      newErrors.password = 'This is required.';
    }


    if (Object.keys(newErrors).length === 0) {
      console.log(formData,"formdata aaaaaaaaaaaaaaaaa");
      setErrors({});
      props.close();

    } else {
      setErrors(newErrors);
      console.log('Validation errors:', newErrors);
    }
  
  };
  return (
    <Modal
      open={props.open}
      onClose={props.close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ zIndex: 10000 }} >

      <Box sx={style}>
        <Grid container >
          <Grid item xs={12} md={12} >
            <Box sx={{ paddingBottom: "20px" }} >
              <div style={{ float: "right", cursor: "pointer" }} onClick={props.close}>
                <img
                  src={close}
                  height={20}
                  width={20}
                  alt="close"

                />
              </div>
              <Typography className="primary-font-medium-7" sx={{ }}> Social Media</Typography>
            </Box>

           
            <Divider />
            <Grid item sx={{ padding: 0 }}>
              <div style={{ padding: 20 }}>
                <div>

                  <Typography className='font-normal-small profile-input-label'>Social Media Account</Typography>
                  <input type="text" name="socialMediaAccount" id="socialMediaAccount"  aria-label='socialMediaAccount' className="profile-input" disabled value={props.label} defaultValue={props.label}/>
                  
                </div>

                <FormControl variant="standard">
                  <div style={{ width: "100%" }}>
                    <Typography className='font-normal-small error-message'>{errors.deleteAccount} </Typography>

                    <Typography className='font-normal-small profile-input-label'>Delete Account? (in case of death)</Typography>
                  </div>
                  <RadioGroup sx={{ display: "flex", flexDirection: "row", pl: "10px", width: "100%" }}

                    name="deleteAccount"
                    id="deleteAccount"
                    //   className={`profile-input ${errors.deleteAccount ? 'err-input' : ''}`} 
                    value={formData.deleteAccount}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel value="yes" sx={{ border: "1px solid #DDDFE1", minWidth: "225px", borderRadius: "8px" }} className={errors.deleteAccount ? 'err-input' : ''} control={<Radio sx={{ "&.MuiRadio-root.Mui-checked ": { color: "red" } }} />} label="Yes" />
                    <FormControlLabel sx={{ border: "1px solid #DDDFE1", minWidth: "255px", ml: "30px", borderRadius: "8px" }} className={errors.deleteAccount ? 'err-input' : ''} value="no" control={<Radio sx={{ "&.MuiRadio-root.Mui-checked ": { color: "red" } }} />} label="No" />
                  </RadioGroup>

                </FormControl>

              </div>
            </Grid>
            <Grid item sx={{ padding: 0 }}>
              <div style={{ padding: 20 }}>
                <div>
                  <Typography className='font-normal-small error-message'>{errors.userName}</Typography>
                  <Typography className='font-normal-small profile-input-label'>User Name</Typography>
                  <input type="text" name="userName" id="userName" className={`profile-input ${errors.userName ? 'err-input' : ''}`} aria-label='userName' onChange={handleInputChange} value={formData.userName} />
                </div>
                <div>
                  <Typography className='font-normal-small error-message'>{errors.password}</Typography>
                  <Typography className='font-normal-small profile-input-label'>Password (Required)</Typography>
                  <div className="input-holder">
                    <input name="password" id="password" className={`profile-input ${errors.password ? 'err-input' : ''}`} aria-label='password' type={showPassword ? "text" : "password"} onChange={handleInputChange} maxLength={12} value={formData.password} />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="password"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      className="input-eye-icon pwd-icon1"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </div>
                </div>
              </div>
            </Grid>
            <div className="action-holder" style={{ marginTop: 0, marginBottom: 50 }}>
              <Button variant="outlined" className="primary-font-bold-6 action-btn profile-cancel-btn" onClick={props.close}>Cancel</Button>
              <Button variant="outlined" className="primary-font-bold-6 action-btn profile-save-btn" onClick={handleSubmit}>Save</Button>
            </div>
          </Grid>




        </Grid>
      </Box>
    </Modal>
  )
}
export default AccountDetailsEditModal;

