import React,{ useState, useEffect } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider,Typography, Button } from "@mui/material";
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { setSecurityLock } from "../redux/actions";
import * as formatters from '../../../../utils/util';


const CreateSecurityLockDialog = ({openDialog, closeDialog, setSecurityLock, lockData, lockError}) => {
    const [newSecurityLock, setNewSecurityLock] = useState('');
    const [confirmNewSecurityLock, setConfirmNewSecurityLock] = useState('');
    const [newSecurityLockErr, setNewSecurityLockErr] = useState('');
    const [confirmNewPasswordErr, setConfirmSecurityLockErr] = useState('');

    const user = window.localStorage.getItem('userInfo') ? JSON.parse(window.localStorage.getItem('userInfo')) : null;


    const [showSecurityLock, setShowSecurityLock] = useState(false);
    const [showConfirmSecurityLock, setShowConfirmSecurityLock] = useState(false);
  
    const handleClickShowSecurityLock = () => setShowSecurityLock(!showSecurityLock);
    const handleClickShowConfirmSecurityLock = () => setShowConfirmSecurityLock(!showConfirmSecurityLock);
  
    const handleMouseDownSecurityLock = () => setShowSecurityLock(!showSecurityLock);
    const handleMouseDownConfirmSecurityLock = () => setShowConfirmSecurityLock(!showConfirmSecurityLock);
    useEffect(()=>{
        if(lockData && lockData.status === 200){
          const userDetails = window.localStorage.getItem("userInfo")
          ? JSON.parse(window.localStorage.getItem("userInfo"))
          : null;
          userDetails.securityLock = true;
          window.localStorage.setItem("userInfo", JSON.stringify(userDetails))
            closeDialog()
        }
        if(lockData && lockData.status !== 200){
            setNewSecurityLockErr(lockData?.message || "")
        }
      },[lockData, lockError])

    const onSecurityLockChange = (e, type) => {
        if (type === 'newSecurityLock') {
            setNewSecurityLock(formatters.isNumaric(e.target.value));
        } else if (type === 'confirmNewSecurityLock') {
            setConfirmNewSecurityLock(formatters.isNumaric(e.target.value));
        }
      }
 const resetErrorMessages = () => { 
    setNewSecurityLockErr('');
    setConfirmSecurityLockErr('');

  }

  const onSaveChangeSecurityLock = () => {
    let isInValid = false;
    resetErrorMessages();
    
    if (!newSecurityLock?.trim().length) {
        setNewSecurityLockErr('This is required.');
        isInValid = true;
    } else if (newSecurityLock?.trim().length !== 6) {
      setNewSecurityLockErr('Security Lock should contain 6 digits.');
      isInValid = true; 
    }
    if (!confirmNewSecurityLock?.trim().length) {
        setConfirmSecurityLockErr('This is required.');
        isInValid = true;
    } else if (newSecurityLock !== confirmNewSecurityLock) {
        setConfirmSecurityLockErr('Security Lock and Confirm Security Lock mismatch.');
        isInValid = true;
    }
    else if (confirmNewSecurityLock?.trim().length !== 6) {
      setConfirmSecurityLockErr('Confirm Security Lock should contain 6 digits.');
      isInValid = true;
    }
    if( isInValid){
        return;
    }
    const payload = {
        securityLock: newSecurityLock,
        emailId: user?.emailId,
        id:user?.id,
        updatedBy: user?.id
    }
    setSecurityLock(payload);
}
    return (
        <Dialog
        open={openDialog}
        // onClose={handleCloseDialog}
        className="change-pwd-dialog dialog-box"
        aria-labelledby="change-pwd-dialog-title"
        aria-describedby="change-pwd-dialog-description"
        >
            <DialogTitle id="change-pwd-dialog-title">
                <Typography className='primary-font-bold-heading1'>Enter Security Lock </Typography>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <div>
                  <Typography className='font-normal-small error-message'>{newSecurityLockErr}</Typography>
                  <Typography className='font-normal-small profile-input-label'>Security Lock</Typography>
                  <div className="input-holder"> 
                    <input className={`profile-input ${newSecurityLockErr !== '' ? 'err-input' : ''}`}  aria-label='new-SecurityLock' maxLength={6}  type={showSecurityLock ? "text" : "password"} onChange={(e) => {onSecurityLockChange(e, 'newSecurityLock')}}  value={newSecurityLock}/>
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="password" 
                        onClick={handleClickShowSecurityLock}
                        onMouseDown={handleMouseDownSecurityLock}
                        className="input-eye-icon pwd-icon1"
                        >
                        {showSecurityLock ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                  </div>
              </div>
              <div>
                  <Typography className='font-normal-small error-message'>{confirmNewPasswordErr}</Typography>
                  <Typography className='font-normal-small profile-input-label'>Confirm Security Lock</Typography>
                  <div className="input-holder"> 
                    <input className={`profile-input ${confirmNewPasswordErr !== '' ? 'err-input' : ''}`}  aria-label='confirm-new-SecurityLock' maxLength={6}  type={showConfirmSecurityLock ? "text" : "password"} onChange={(e) => {onSecurityLockChange(e, 'confirmNewSecurityLock')}}  value={confirmNewSecurityLock}/>
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="password" 
                        onClick={handleClickShowConfirmSecurityLock}
                        onMouseDown={handleMouseDownConfirmSecurityLock}
                        className="input-eye-icon pwd-icon1"
                        >
                        {showConfirmSecurityLock ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                  </div>
              </div>
            </DialogContent>
            <Divider />
            <DialogActions>
            <div className="action-holder" style={{marginTop: 20, marginBottom: 0}}>
                {/* <Button variant="outlined" className="primary-font-bold-6 action-btn profile-cancel-btn" onClick={handleCloseDialog}>Cancel</Button> */}
                <Button variant="outlined" className="primary-font-bold-6 action-btn profile-save-btn"  onClick={onSaveChangeSecurityLock}>Save</Button>
            </div>
            </DialogActions>
        </Dialog>
    )

}



CreateSecurityLockDialog.defaultProps = {
    lockData: null,
    lockError: null,
    isVerifyingPin: null,
  };
  
  function mapStateToProps(state) {
    return {
        lockData: state.home.lockData,
        lockError: state.home.lockError,
        isVerifyingPin: state.home.isVerifyingPin,
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        setSecurityLock
      },
      dispatch
    );
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreateSecurityLockDialog);



//   export default CreateSecurityLockDialog;